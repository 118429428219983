export const country_states=[
    {
        "id": 1,
        "name": "Afghanistan",
        "iso3": "AFG",
        "iso2": "AF",
        "phone_code": "93",
        "capital": "Kabul",
        "currency": "AFN",
        "states": {
            "Badakhshan": [
                "Eshkashem",
                "Fayzabad",
                "Jurm",
                "Khandud",
                "Qal\\'eh-ye Panjeh"
            ],
            "Badgis": [
                "Bala Morghab",
                "Qal\\'eh-ye Naw"
            ],
            "Baglan": [
                "Andarab",
                "Baghlan",
                "Dahaneh-ye Ghawri",
                "Nahrin",
                "Pol-e Khumri"
            ],
            "Balkh": [
                "Balkh",
                "Dawlatabad",
                "Mazar-e Sharif",
                "Qarchi Gak",
                "Shulgara",
                "Tash Gozar"
            ],
            "Bamiyan": [
                "Bamiyan",
                "Panjab",
                "Qil Qal\\'eh"
            ],
            "Farah": [
                "Anar Darreh",
                "Farah",
                "Shindand"
            ],
            "Faryab": [
                "Andkhvoy",
                "Darzi Ab",
                "Dawlatabad",
                "Maymanah"
            ],
            "Gawr": [
                "Chaghcharan",
                "Shahrak",
                "Taywarah"
            ],
            "Gazni": [
                "Ghazni"
            ],
            "Herat": [
                "Awbeh",
                "Eslam Qal\\'eh",
                "Ghurian",
                "Herat",
                "Karukh",
                "Kuhestan",
                "Kushk",
                "Qarabagh",
                "Tawraghudi",
                "Tir Pol",
                "Zendejan"
            ],
            "Hilmand": [
                "Baghran",
                "Darwishan",
                "Deh Shu",
                "Gereshk",
                "Lashkar Gah",
                "Sangin"
            ],
            "Jawzjan": [
                "Aqchah",
                "Qarqin",
                "Sang-e Charak",
                "Shibarghan"
            ],
            "Kabul": [
                "Baghrami",
                "Kabul",
                "Mir Bachchekut",
                "Paghman",
                "Qarabagh",
                "Sarawbi"
            ],
            "Kapisa": [
                "Mahmud-e Raqi",
                "Taghab"
            ],
            "Khawst": [
                "Khawst"
            ],
            "Kunar": [
                "Asadabad",
                "Asmar"
            ],
            "Lagman": [
                "Mehtar Lam"
            ],
            "Lawghar": [
                "Azraw",
                "Baraki Barak",
                "Pol-e Alam"
            ],
            "Nangarhar": [
                "Achin",
                "Batsawul",
                "Hugyani",
                "Jalalabad",
                "Nader Shah Kawt"
            ],
            "Nimruz": [
                "Chahar Burjak",
                "Chakhansur",
                "Khash",
                "Mirabad",
                "Rudbar",
                "Zaranj"
            ],
            "Nuristan": [
                "Nuristan"
            ],
            "Paktika": [
                "Orgun",
                "Zareh Sharan",
                "Zarghun Shahr"
            ],
            "Paktiya": [
                "Ghardez",
                "\\'Ali Khayl"
            ],
            "Parwan": [
                "Charikar",
                "Jabal-os-Saraj"
            ],
            "Qandahar": [
                "Qandahar"
            ],
            "Qunduz": [
                "Dasht-e Archa",
                "Emam Saheb",
                "Hazart Imam",
                "Khanabad",
                "Qal\\'eh-ye Zal",
                "Qunduz"
            ],
            "Samangan": [
                "Aybak",
                "Kholm"
            ],
            "Sar-e Pul": [
                "Aybak",
                "Kholm"
            ],
            "Takhar": [
                "Chah Ab",
                "Eshkamesh",
                "Farkhar",
                "Khwajeh Ghar",
                "Rostaq",
                "Taloqan",
                "Yangi Qal\\'eh"
            ],
            "Uruzgan": [
                "Deh Rawud",
                "Gaz Ab",
                "Tarin Kawt",
                "Uruzgan"
            ],
            "Wardag": [
                "Gardan Diwal",
                "Maydanshahr"
            ],
            "Zabul": [
                "Qalat-e Ghilzay"
            ]
        }
    },
    {
        "id": 2,
        "name": "Aland Islands",
        "iso3": "ALA",
        "iso2": "AX",
        "phone_code": "+358-18",
        "capital": "Mariehamn",
        "currency": "EUR"
    },
    {
        "id": 3,
        "name": "Albania",
        "iso3": "ALB",
        "iso2": "AL",
        "phone_code": "355",
        "capital": "Tirana",
        "currency": "ALL",
        "states": {
            "Berat": [
                "Berat",
                "Polican",
                "Ure Vajgurore"
            ],
            "Bulqize": [
                "Bulqize"
            ],
            "Delvine": [
                "Delvine"
            ],
            "Devoll": [
                "Bilisht"
            ],
            "Dibre": [
                "Maqellare",
                "Peshkopi"
            ],
            "Durres": [
                "Durres",
                "Shijak"
            ],
            "Elbasan": [
                "Cerrik",
                "Elbasan",
                "Kerrabe"
            ],
            "Fier": [
                "Fier",
                "Patos",
                "Roskovec"
            ],
            "Gjirokaster": [
                "Gjirokaster",
                "Libohove"
            ],
            "Gramsh": [
                "Gramsh"
            ],
            "Has": [
                "Krume"
            ],
            "Kavaje": [
                "Kavaje"
            ],
            "Kolonje": [
                "Erseke",
                "Leskovik"
            ],
            "Korce": [
                "Korce",
                "Maliq"
            ],
            "Kruje": [
                "Fushe-Kruje",
                "Kruje",
                "Mamurras",
                "Milot"
            ],
            "Kucove": [
                "Kucove"
            ],
            "Kukes": [
                "Kukes"
            ],
            "Kurbin": [
                "Lac"
            ],
            "Lezhe": [
                "Lezhe",
                "Shengjin"
            ],
            "Librazhd": [
                "Librazhd",
                "Perrenjas"
            ],
            "Lushnje": [
                "Lushnje",
                "Rrogozhine"
            ],
            "Mallakaster": [
                "Ballsh"
            ],
            "Malsi e Madhe": [
                "Ballsh"
            ],
            "Mat": [
                "Burrel",
                "Klos",
                "Kraste",
                "Ulze"
            ],
            "Mirdite": [
                "Kurbnesh",
                "Rreshen",
                "Rubik"
            ],
            "Peqin": [
                "Peqin"
            ],
            "Permet": [
                "Kelcyre",
                "Permet"
            ],
            "Pogradec": [
                "Pogradec"
            ],
            "Puke": [
                "Fierze",
                "Fushe-Arrez",
                "Puke"
            ],
            "Sarande": [
                "Konispol",
                "Sarande"
            ],
            "Shkoder": [
                "Shkoder"
            ],
            "Skrapar": [
                "Corovode"
            ],
            "Tepelene": [
                "Memaliaj",
                "Tepelene"
            ],
            "Tirane": [
                "Kamze",
                "Paskuqan",
                "Tirane",
                "Vore"
            ],
            "Tropoje": [
                "Bajram Curri"
            ],
            "Vlore": [
                "Himare",
                "Orikum",
                "Selenice",
                "Vlore"
            ]
        }
    },
    {
        "id": 4,
        "name": "Algeria",
        "iso3": "DZA",
        "iso2": "DZ",
        "phone_code": "213",
        "capital": "Algiers",
        "currency": "DZD",
        "states": {
            "Adrar": [
                "Adrar",
                "Awlaf",
                "Rijan",
                "Timimun"
            ],
            "al-Aghwat": [
                "Aflu",
                "al-Aghwat",
                "Hassi al-Raml"
            ],
            "al-Bayadh": [
                "al-Abyad Sidi Shaykh",
                "al-Bayadh",
                "Brizyanah"
            ],
            "al-Jaza\\'ir": [
                "al-Jaza\\'ir",
                "Bab Azwar",
                "Baraki",
                "Bir Murad Rais",
                "Birkhadam",
                "Burj-al-Kiffan",
                "Dar-al-Bayda"
            ],
            "al-Wad": [
                "al-Mighair",
                "al-Wad",
                "Bayadha",
                "Dabilah",
                "Hassan \\'Abd-al-Karim",
                "Hassi Halifa",
                "Jama\\'a",
                "Maqran",
                "Qamar",
                "Raqiba",
                "Rubbah",
                "Sidi Amran"
            ],
            "Algiers": [
                "Hydra",
                "Kouba"
            ],
            "Annabah": [
                "al-Buni",
                "al-Hajar",
                "Annabah",
                "Birrahhal",
                "Saraydih",
                "Sidi Amar"
            ],
            "ash-Shalif": [
                "Abu al-Hassan",
                "ash-Shalif",
                "ash-Shattiyah",
                "Bani Hawa",
                "Bu Qadir",
                "Sidi Ukaskah",
                "Tanas",
                "Wadi al-Fiddah",
                "Wadi Sali",
                "\\'Ayn Maran"
            ],
            "at-Tarif": [
                "al-Qal\\'ah",
                "at-Tarif",
                "Ban Mahdi",
                "Bani Amar",
                "Basbas",
                "Dariyan",
                "Saba\\'ita Muk"
            ],
            "Bashshar": [
                "Bani Wanif",
                "Bashshar",
                "Qanadsan",
                "Taghit",
                "\\'Abadlah"
            ],
            "Batnah": [
                "Barikah",
                "Batnah",
                "Marwanah",
                "Naghaus",
                "Ra\\'s-al-\\'Ayun",
                "Tazult",
                "\\'Aris",
                "\\'Ayn Tutah"
            ],
            "Bijayah": [
                "Akbu",
                "al-Qasr",
                "Amizur",
                "Barbasha",
                "Bijayah",
                "Farrawn",
                "Ighram",
                "Sadduk",
                "Shamini",
                "Sidi \\'Aysh",
                "Taskaryut",
                "Tazmalt",
                "Timazrit",
                "Uz-al-Laqin",
                "\\'Ayt Rizin"
            ],
            "Biskrah": [
                "Awlad Jallal",
                "Biskrah",
                "Sidi Khalid",
                "Sidi Ukbah",
                "Tulja",
                "Um\\'ash",
                "Zaribat-al-Wad"
            ],
            "Blidah": [
                "al-Arba\\'a",
                "al-\\'Afrun",
                "Awlad Salam",
                "Awlad Yaysh",
                "Bani Khalil",
                "Bani Marad",
                "Bani Tamu",
                "Blidah",
                "Bu Arfa",
                "Bufarik",
                "Buinan",
                "Buqara",
                "Maftah",
                "Muzayah",
                "Shabli",
                "Shari\\'ah",
                "Shiffa",
                "Sidi Mussa",
                "Suma",
                "Wadi al-Allagh"
            ],
            "Buirah": [
                "Aghbalu",
                "Bi\\'r Ghabalu",
                "Buirah",
                "Lakhdariyah",
                "Shurfa",
                "Sur-al-Ghuzlan",
                "\\'Ayn Bissim"
            ],
            "Bumardas": [
                "al-Arba\\'a Tash",
                "ar-Ruwibah",
                "Awlad Haddaj",
                "Awlad Mussa",
                "Bani Amran",
                "Budwawu",
                "Budwawu al-Bahri",
                "Bumardas",
                "Burj Minayal",
                "Dalis",
                "Hammadi",
                "Issar",
                "Khamis-al-Khashnah",
                "Nasiriyah",
                "Raghayah",
                "Sa\\'abat",
                "Tinyah",
                "\\'Ayn Tayah"
            ],
            "Burj Bu Arririj": [
                "al-Arba\\'a Tash",
                "ar-Ruwibah",
                "Awlad Haddaj",
                "Awlad Mussa",
                "Bani Amran",
                "Budwawu",
                "Budwawu al-Bahri",
                "Bumardas",
                "Burj Minayal",
                "Dalis",
                "Hammadi",
                "Issar",
                "Khamis-al-Khashnah",
                "Nasiriyah",
                "Raghayah",
                "Sa\\'abat",
                "Tinyah",
                "\\'Ayn Tayah"
            ],
            "Ghalizan": [
                "Ammi Mussa",
                "Ghalizan",
                "Jidiwiyah",
                "Mazunah",
                "Sidi Muhammad Ban \\'Ali",
                "Wadi Rahiyu",
                "Zammurah"
            ],
            "Ghardayah": [
                "al-Ghuli\\'ah",
                "al-Qararah",
                "Biryan",
                "Bu Nura",
                "Ghardaia",
                "Ghardayah",
                "Matlili"
            ],
            "Ilizi": [
                "Ilizi"
            ],
            "Jijili": [
                "al-Miliyah",
                "Amir \\'Abd-al-Qadar",
                "Jijili",
                "Shifka",
                "Tahar"
            ],
            "Jilfah": [
                "al-Idrisiyah",
                "Birin",
                "Dar Shiyukh",
                "Hassi Bahbah",
                "Jilfah",
                "Mis\\'ad",
                "Sharif",
                "\\'Ayn Wissarah",
                "\\'Ayn-al-Ibil"
            ],
            "Khanshalah": [
                "al-Mahmal",
                "Khanshalah",
                "Sharshar",
                "Tawziyanat"
            ],
            "Masilah": [
                "Bu Sa\\'adah",
                "Hammam Dhala\\'a",
                "Maghra",
                "Masilah",
                "Ma\\'adid",
                "Sidi \\'Aysa",
                "Wanugha",
                "\\'Ayn-al-Hajal",
                "\\'Ayn-al-Milh"
            ],
            "Midyah": [
                "Birwaghiyah",
                "Midyah",
                "Qasr-al-Bukhari",
                "Shillalah",
                "Tablat",
                "\\'Ayn Bu Sif"
            ],
            "Milah": [
                "Farjiwah",
                "Milah",
                "Qararam Quqa",
                "Ruwashad",
                "Salghum-al-\\'Ayd",
                "Sidi Maruf",
                "Sidi Marwan",
                "Tajananah",
                "Talighmah",
                "Wadi Athmaniyah"
            ],
            "Muaskar": [
                "Bu Khanifiyah",
                "Muaskar",
                "Muhammadiyah",
                "Siq",
                "Tighinnif",
                "Wadi al-Abtal",
                "Zahana"
            ],
            "Mustaghanam": [
                "Hassi Mamash",
                "Mazaghran",
                "Mustaghanam",
                "Sidi Ali",
                "\\'Ayn Tadalas"
            ],
            "Naama": [
                "Mishriyah",
                "Naama",
                "\\'Ayn Safra"
            ],
            "Oran": [
                "Oran"
            ],
            "Ouargla": [
                "Ouargla"
            ],
            "Qalmah": [
                "Bumahra Ahmad",
                "Hamman Awlad \\'Ali",
                "Qalmah",
                "Wadi Zinati",
                "\\'Ayn Bardah"
            ],
            "Qustantinah": [
                "al-Khurub",
                "Didush Murad",
                "Hamma Bu Ziyan",
                "Qustantinah",
                "Zighut Yusuf",
                "\\'Ayn Abid",
                "\\'Ayn Samara"
            ],
            "Sakikdah": [
                "al-Harush",
                "al-Qull",
                "Amjaz Adshish",
                "Fil Fila",
                "Karkira",
                "Ramadan Jamal",
                "Sakikdah",
                "Shataybih",
                "Tamalus",
                "\\'Azzabah"
            ],
            "Satif": [
                "al-\\'Ulmah",
                "Bouira",
                "Buq\\'ah",
                "Salah Bay",
                "Satif",
                "Setif",
                "Ziyama Mansuriyah",
                "\\'Ayn Arnat",
                "\\'Ayn Taqrut",
                "\\'Ayn Wilman",
                "\\'Ayn \\'Azl",
                "\\'Ayn-al-Khabira"
            ],
            "Sayda\\'": [
                "Sayda\\'",
                "\\'Ayn-al-Hajar"
            ],
            "Sidi ban-al-\\'Abbas": [
                "Sayda\\'",
                "\\'Ayn-al-Hajar"
            ],
            "Suq Ahras": [
                "Sayda\\'",
                "\\'Ayn-al-Hajar"
            ],
            "Tamanghasat": [
                "Tamanghasat",
                "\\'Ayn Qazzan",
                "\\'Ayn Salah"
            ],
            "Tibazah": [
                "al-Qull\\'ah",
                "Bu Isma\\'il",
                "Bu Midfar\\'ah",
                "Damus",
                "Duwirah",
                "Hajut",
                "Hammam Righa",
                "Sawlah",
                "Shiragha",
                "Shirshall",
                "Sidi Farj",
                "Stawali",
                "Tibazah",
                "Ziralda",
                "\\'Ayn Binyan"
            ],
            "Tibissah": [
                "al-\\'Awaynat",
                "Bi\\'r-al-\\'Itir",
                "Hammamat",
                "Mursut",
                "Shariyah",
                "Tibissah",
                "Winzah"
            ],
            "Tilimsan": [
                "al-Mansurah",
                "Awlad Mimun",
                "Bani Mastar",
                "Bani Sikran",
                "Ghazawat",
                "Hannayah",
                "Maghniyah",
                "Nidruma",
                "Ramsh",
                "Sabra",
                "Shatwan",
                "Sibdu",
                "Sidi \\'Abdallah",
                "Tilimsan"
            ],
            "Tinduf": [
                "Tinduf"
            ],
            "Tisamsilt": [
                "Thaniyat-al-Had",
                "Tisamsilt"
            ],
            "Tiyarat": [
                "Firindah",
                "Mahdiyah",
                "Mashra\\'a Asfa",
                "Qasr Shillalah",
                "Rahuyah",
                "Sughar",
                "Takhamarat",
                "Tiyarat",
                "\\'Ayn Dhahab"
            ],
            "Tizi Wazu": [
                "Firindah",
                "Mahdiyah",
                "Mashra\\'a Asfa",
                "Qasr Shillalah",
                "Rahuyah",
                "Sughar",
                "Takhamarat",
                "Tiyarat",
                "\\'Ayn Dhahab"
            ],
            "Umm-al-Bawaghi": [
                "Bi\\'r Shuhada",
                "Miskyanah",
                "Shamurah",
                "Umm-al-Bawaghi",
                "\\'Ayn Bayda",
                "\\'Ayn Fakrun",
                "\\'Ayn Kirshah",
                "\\'Ayn Malilah"
            ],
            "Wahran": [
                "al-Ansur",
                "Arzu",
                "as-Saniyah",
                "Bi\\'r-al-Jir",
                "Butlilis",
                "Hassi Bu Nif",
                "Mars-al-Kabir",
                "Qadayal",
                "Sidi ash-Shami",
                "Wadi Thalatha",
                "Wahran",
                "\\'Ayn Biya",
                "\\'Ayn-at-Turk"
            ],
            "Warqla": [
                "al-Hajirah",
                "Hassi Mas\\'ud",
                "Nazla",
                "Ruwisiyat",
                "Tabisbast",
                "Tamalhat",
                "Tamasin",
                "Tayabat-al-Janubiyah",
                "Tughghurt",
                "Warqla"
            ],
            "Wilaya d Alger": [
                "al-Hajirah",
                "Hassi Mas\\'ud",
                "Nazla",
                "Ruwisiyat",
                "Tabisbast",
                "Tamalhat",
                "Tamasin",
                "Tayabat-al-Janubiyah",
                "Tughghurt",
                "Warqla"
            ],
            "Wilaya de Bejaia": [
                "al-Hajirah",
                "Hassi Mas\\'ud",
                "Nazla",
                "Ruwisiyat",
                "Tabisbast",
                "Tamalhat",
                "Tamasin",
                "Tayabat-al-Janubiyah",
                "Tughghurt",
                "Warqla"
            ],
            "Wilaya de Constantine": [
                "al-Hajirah",
                "Hassi Mas\\'ud",
                "Nazla",
                "Ruwisiyat",
                "Tabisbast",
                "Tamalhat",
                "Tamasin",
                "Tayabat-al-Janubiyah",
                "Tughghurt",
                "Warqla"
            ],
            "\\'Ayn Daflah": [
                "al-Hajirah",
                "Hassi Mas\\'ud",
                "Nazla",
                "Ruwisiyat",
                "Tabisbast",
                "Tamalhat",
                "Tamasin",
                "Tayabat-al-Janubiyah",
                "Tughghurt",
                "Warqla"
            ],
            "\\'Ayn Tamushanat": [
                "al-Hajirah",
                "Hassi Mas\\'ud",
                "Nazla",
                "Ruwisiyat",
                "Tabisbast",
                "Tamalhat",
                "Tamasin",
                "Tayabat-al-Janubiyah",
                "Tughghurt",
                "Warqla"
            ]
        }
    },
    {
        "id": 5,
        "name": "American Samoa",
        "iso3": "ASM",
        "iso2": "AS",
        "phone_code": "+1-684",
        "capital": "Pago Pago",
        "currency": "USD",
        "states": {
            "Eastern": [
                "Afono",
                "Alao",
                "Alofau",
                "Amouli",
                "Aoa",
                "Atu\\'u",
                "Aua",
                "Aunu\\'u",
                "Fagasa",
                "Fagatogo",
                "Faga\\'alu",
                "Faga\\'itua",
                "Lauli\\'i",
                "Leloaloa",
                "Nu\\'uuli",
                "Pago Pago",
                "Tula",
                "Utulei",
                "Vatia"
            ],
            "Manu\\'a": [
                "Ofu"
            ],
            "Swains Island": [
                "Ofu"
            ],
            "Western": [
                "Aoloau",
                "Faleniu",
                "Futiga",
                "Ili\\'ili",
                "Leone",
                "Malaeimi",
                "Malaeloa-Aitulagi",
                "Malaeloa-Ituau",
                "Mapusagafou",
                "Mesepa",
                "Pava\\'ia\\'i",
                "Tafuna",
                "Taputimu",
                "Vailoatai",
                "Vaitogi"
            ]
        }
    },
    {
        "id": 6,
        "name": "Andorra",
        "iso3": "AND",
        "iso2": "AD",
        "phone_code": "376",
        "capital": "Andorra la Vella",
        "currency": "EUR",
        "states": {
            "Andorra la Vella": [
                "Aoloau",
                "Faleniu",
                "Futiga",
                "Ili\\'ili",
                "Leone",
                "Malaeimi",
                "Malaeloa-Aitulagi",
                "Malaeloa-Ituau",
                "Mapusagafou",
                "Mesepa",
                "Pava\\'ia\\'i",
                "Tafuna",
                "Taputimu",
                "Vailoatai",
                "Vaitogi"
            ],
            "Canillo": [
                "Canillo"
            ],
            "Encamp": [
                "Encamp"
            ],
            "La Massana": [
                "Encamp"
            ],
            "Les Escaldes": [
                "Encamp"
            ],
            "Ordino": [
                "Ordino"
            ],
            "Sant Julia de Loria": [
                "Ordino"
            ]
        }
    },
    {
        "id": 7,
        "name": "Angola",
        "iso3": "AGO",
        "iso2": "AO",
        "phone_code": "244",
        "capital": "Luanda",
        "currency": "AOA",
        "states": {
            "Bengo": [
                "Caxito"
            ],
            "Benguela": [
                "Benguela",
                "Lobito"
            ],
            "Bie": [
                "Camacupa",
                "Catabola",
                "Catumbela",
                "Chissamba",
                "Kuito"
            ],
            "Cabinda": [
                "Cabinda"
            ],
            "Cunene": [
                "Ondjiva"
            ],
            "Huambo": [
                "Caala",
                "Catchiungo",
                "Huambo",
                "Longonjo"
            ],
            "Huila": [
                "Caconda",
                "Caluquembe",
                "Lubango"
            ],
            "Kuando-Kubango": [
                "Menongue"
            ],
            "Kwanza Norte": [
                "Menongue"
            ],
            "Kwanza Sul": [
                "Menongue"
            ],
            "Luanda": [
                "Luanda"
            ],
            "Lunda Norte": [
                "Luanda"
            ],
            "Lunda Sul": [
                "Luanda"
            ],
            "Malanje": [
                "Malanje"
            ],
            "Moxico": [
                "Cazaje",
                "Leua",
                "Luau",
                "Luena",
                "Lumeje"
            ],
            "Namibe": [
                "Namibe"
            ],
            "Uige": [
                "Camabatela",
                "Uige"
            ],
            "Zaire": [
                "M\\'banza-Kongo",
                "N\\'zeto",
                "Soyo"
            ]
        }
    },
    {
        "id": 8,
        "name": "Anguilla",
        "iso3": "AIA",
        "iso2": "AI",
        "phone_code": "+1-264",
        "capital": "The Valley",
        "currency": "XCD",
        "states": {
            "Other Provinces": [
                "M\\'banza-Kongo",
                "N\\'zeto",
                "Soyo"
            ]
        }
    },
    {
        "id": 9,
        "name": "Antarctica",
        "iso3": "ATA",
        "iso2": "AQ",
        "phone_code": "",
        "capital": "",
        "currency": "",
        "states": {
            "Sector claimed by Argentina\/Ch": [
                "M\\'banza-Kongo",
                "N\\'zeto",
                "Soyo"
            ],
            "Sector claimed by Argentina\/UK": [
                "M\\'banza-Kongo",
                "N\\'zeto",
                "Soyo"
            ],
            "Sector claimed by Australia": [
                "M\\'banza-Kongo",
                "N\\'zeto",
                "Soyo"
            ],
            "Sector claimed by France": [
                "M\\'banza-Kongo",
                "N\\'zeto",
                "Soyo"
            ],
            "Sector claimed by New Zealand": [
                "M\\'banza-Kongo",
                "N\\'zeto",
                "Soyo"
            ],
            "Sector claimed by Norway": [
                "M\\'banza-Kongo",
                "N\\'zeto",
                "Soyo"
            ],
            "Unclaimed Sector": [
                "M\\'banza-Kongo",
                "N\\'zeto",
                "Soyo"
            ]
        }
    },
    {
        "id": 10,
        "name": "Antigua And Barbuda",
        "iso3": "ATG",
        "iso2": "AG",
        "phone_code": "+1-268",
        "capital": "St. John's",
        "currency": "XCD",
        "states": {
            "Barbuda": [
                "Codrington"
            ],
            "Saint George": [
                "Codrington"
            ],
            "Saint John": [
                "Codrington"
            ],
            "Saint Mary": [
                "Codrington"
            ],
            "Saint Paul": [
                "Codrington"
            ],
            "Saint Peter": [
                "Codrington"
            ],
            "Saint Philip": [
                "Codrington"
            ]
        }
    },
    {
        "id": 11,
        "name": "Argentina",
        "iso3": "ARG",
        "iso2": "AR",
        "phone_code": "54",
        "capital": "Buenos Aires",
        "currency": "ARS",
        "states": {
            "Buenos Aires": [
                "Codrington"
            ],
            "Catamarca": [
                "Catamarca"
            ],
            "Chaco": [
                "Presidencia Roque Saenz Pena",
                "Resistencia"
            ],
            "Chubut": [
                "Comodoro Rivadavia",
                "Puerto Madryn",
                "Rawson",
                "Trelew"
            ],
            "Cordoba": [
                "Alta Gracia",
                "Bell Ville",
                "Cordoba",
                "Rio Cuarto",
                "Rio Tercero",
                "San Francisco",
                "Villa Carlos Paz",
                "Villa Maria"
            ],
            "Corrientes": [
                "Corrientes",
                "Goya",
                "Mercedes",
                "San Lorenzo"
            ],
            "Distrito Federal": [
                "Corrientes",
                "Goya",
                "Mercedes",
                "San Lorenzo"
            ],
            "Entre Rios": [
                "Corrientes",
                "Goya",
                "Mercedes",
                "San Lorenzo"
            ],
            "Formosa": [
                "Formosa"
            ],
            "Jujuy": [
                "Jujuy",
                "Libertador General San Martin",
                "San Pedro"
            ],
            "La Pampa": [
                "Jujuy",
                "Libertador General San Martin",
                "San Pedro"
            ],
            "La Rioja": [
                "Jujuy",
                "Libertador General San Martin",
                "San Pedro"
            ],
            "Mendoza": [
                "Godoy Cruz",
                "Lujan",
                "Mendoza",
                "Rivadavia",
                "San Martin",
                "San Rafael"
            ],
            "Misiones": [
                "Eldorado",
                "Obera",
                "Posadas"
            ],
            "Neuquen": [
                "Cutral Co",
                "Neuquen"
            ],
            "Rio Negro": [
                "Cutral Co",
                "Neuquen"
            ],
            "Salta": [
                "Salta",
                "San Ramon de la Nueva Oran",
                "Tartagal"
            ],
            "San Juan": [
                "Salta",
                "San Ramon de la Nueva Oran",
                "Tartagal"
            ],
            "San Luis": [
                "Salta",
                "San Ramon de la Nueva Oran",
                "Tartagal"
            ],
            "Santa Cruz": [
                "Salta",
                "San Ramon de la Nueva Oran",
                "Tartagal"
            ],
            "Santa Fe": [
                "Salta",
                "San Ramon de la Nueva Oran",
                "Tartagal"
            ],
            "Santiago del Estero": [
                "Salta",
                "San Ramon de la Nueva Oran",
                "Tartagal"
            ],
            "Tierra del Fuego": [
                "Salta",
                "San Ramon de la Nueva Oran",
                "Tartagal"
            ],
            "Tucuman": [
                "Tucuman"
            ]
        }
    },
    {
        "id": 12,
        "name": "Armenia",
        "iso3": "ARM",
        "iso2": "AM",
        "phone_code": "374",
        "capital": "Yerevan",
        "currency": "AMD",
        "states": {
            "Aragatsotn": [
                "Ashtarak",
                "Talin"
            ],
            "Ararat": [
                "Ararat",
                "Artashat"
            ],
            "Armavir": [
                "Armavir",
                "Vagharshapat"
            ],
            "Gegharkunik": [
                "Gavar",
                "Martuni",
                "Sevan",
                "Vardenis"
            ],
            "Kotaik": [
                "Abovyan",
                "Arzni",
                "Charentsavan",
                "Hrazdan"
            ],
            "Lori": [
                "Akhtala",
                "Alaverdi",
                "Spitak",
                "Stepanavan",
                "Tashir",
                "Vanadzor"
            ],
            "Shirak": [
                "Artik",
                "Gyumri"
            ],
            "Stepanakert": [
                "Stepanakert"
            ],
            "Syunik": [
                "Angeghakot",
                "Goris",
                "Kajaran",
                "Kapan",
                "Meghri",
                "Sisian"
            ],
            "Tavush": [
                "Dilijan",
                "Ijevan"
            ],
            "Vayots Dzor": [
                "Dilijan",
                "Ijevan"
            ],
            "Yerevan": [
                "Yerevan"
            ]
        }
    },
    {
        "id": 13,
        "name": "Aruba",
        "iso3": "ABW",
        "iso2": "AW",
        "phone_code": "297",
        "capital": "Oranjestad",
        "currency": "AWG",
        "states": {
            "Aruba": [
                "Aruba"
            ]
        }
    },
    {
        "id": 14,
        "name": "Australia",
        "iso3": "AUS",
        "iso2": "AU",
        "phone_code": "61",
        "capital": "Canberra",
        "currency": "AUD",
        "states": {
            "Auckland": [
                "Glenfield",
                "Henderson",
                "Milford"
            ],
            "Australian Capital Territory": [
                "Glenfield",
                "Henderson",
                "Milford"
            ],
            "Balgowlah": [
                "Balgowlah"
            ],
            "Balmain": [
                "Balmain"
            ],
            "Bankstown": [
                "Bankstown"
            ],
            "Baulkham Hills": [
                "Bankstown"
            ],
            "Bonnet Bay": [
                "Bankstown"
            ],
            "Camberwell": [
                "Camberwell"
            ],
            "Carole Park": [
                "Camberwell"
            ],
            "Castle Hill": [
                "Camberwell"
            ],
            "Caulfield": [
                "Caulfield"
            ],
            "Chatswood": [
                "Chatswood"
            ],
            "Cheltenham": [
                "Cheltenham"
            ],
            "Cherrybrook": [
                "Cherrybrook"
            ],
            "Clayton": [
                "Clayton"
            ],
            "Collingwood": [
                "Collingwood"
            ],
            "Frenchs Forest": [
                "Collingwood"
            ],
            "Hawthorn": [
                "Hawthorn"
            ],
            "Jannnali": [
                "Jannnali"
            ],
            "Knoxfield": [
                "Knoxfield"
            ],
            "Melbourne": [
                "Melbourne",
                "South Oakleigh"
            ],
            "New South Wales": [
                "Melbourne",
                "South Oakleigh"
            ],
            "Northern Territory": [
                "Melbourne",
                "South Oakleigh"
            ],
            "Perth": [
                "Cottesole",
                "Darch",
                "Mullaloo",
                "Noranda",
                "Osborne Park",
                "Willetton"
            ],
            "Queensland": [
                "Acacia Ridge",
                "Aitkenvale",
                "Alderley",
                "Alexander Hills",
                "Archerfield",
                "Ashmore",
                "Atherton",
                "Ayr",
                "Beachmere",
                "Beenleigh",
                "Beerwah",
                "Bell",
                "Belmont",
                "Biloela",
                "Birkdale",
                "Blackwater",
                "Bongaree",
                "Bonogin",
                "Boonah",
                "Bowen",
                "Brendale",
                "Brisbane",
                "Buddina",
                "Buderim",
                "Bundaberg",
                "Bundall",
                "Burleigh Heads",
                "Burpengary",
                "Cabaland",
                "Caboolture",
                "Cairns",
                "Cairns Northern Beaches",
                "Caloundra",
                "Capalaba",
                "Charters Towers",
                "Childers",
                "Cleveland",
                "Coolum Beach",
                "Coopers Plain",
                "Dalby",
                "Deception Bay",
                "Eagle Farm",
                "Eagleby",
                "Emerald",
                "Emundi",
                "Forest Lake",
                "Gatton",
                "Gladstone",
                "Gold Coast",
                "Gowrie Junction",
                "Gympie",
                "Helensvale",
                "Hervey Bay",
                "Ingham",
                "Innisfail",
                "Ipswich",
                "Jindalee",
                "Julatten",
                "Kawana Waters",
                "Kingaroy",
                "Lawnton",
                "Loganholme",
                "Mackay",
                "Mareeba",
                "Maroochydore",
                "Maroochydore-Mooloolaba",
                "Maryborough",
                "Miami",
                "Milton",
                "Molendinar",
                "Moranbah",
                "Morayfield",
                "Mossman",
                "Mount Glorious",
                "Mount Isa",
                "Murarrie",
                "Nairand Kay",
                "Nambour",
                "Nerang",
                "Nobby Beach",
                "Noosa Heads",
                "Noosaville",
                "Paddington",
                "Port Douglas",
                "Robina",
                "Rockhampton",
                "Rocklea",
                "Roma",
                "Rubyvale",
                "Sanctuary Cove",
                "Slade Point",
                "Southport",
                "Suburb",
                "Sunshine Coast",
                "Tewantin-Noosa",
                "The Summit",
                "Toowong",
                "Toowoomba",
                "Townsville",
                "Victoria Point",
                "Warwick",
                "Willawong",
                "Yatala",
                "Yeppoon",
                "Zillmere"
            ],
            "South Australia": [
                "Acacia Ridge",
                "Aitkenvale",
                "Alderley",
                "Alexander Hills",
                "Archerfield",
                "Ashmore",
                "Atherton",
                "Ayr",
                "Beachmere",
                "Beenleigh",
                "Beerwah",
                "Bell",
                "Belmont",
                "Biloela",
                "Birkdale",
                "Blackwater",
                "Bongaree",
                "Bonogin",
                "Boonah",
                "Bowen",
                "Brendale",
                "Brisbane",
                "Buddina",
                "Buderim",
                "Bundaberg",
                "Bundall",
                "Burleigh Heads",
                "Burpengary",
                "Cabaland",
                "Caboolture",
                "Cairns",
                "Cairns Northern Beaches",
                "Caloundra",
                "Capalaba",
                "Charters Towers",
                "Childers",
                "Cleveland",
                "Coolum Beach",
                "Coopers Plain",
                "Dalby",
                "Deception Bay",
                "Eagle Farm",
                "Eagleby",
                "Emerald",
                "Emundi",
                "Forest Lake",
                "Gatton",
                "Gladstone",
                "Gold Coast",
                "Gowrie Junction",
                "Gympie",
                "Helensvale",
                "Hervey Bay",
                "Ingham",
                "Innisfail",
                "Ipswich",
                "Jindalee",
                "Julatten",
                "Kawana Waters",
                "Kingaroy",
                "Lawnton",
                "Loganholme",
                "Mackay",
                "Mareeba",
                "Maroochydore",
                "Maroochydore-Mooloolaba",
                "Maryborough",
                "Miami",
                "Milton",
                "Molendinar",
                "Moranbah",
                "Morayfield",
                "Mossman",
                "Mount Glorious",
                "Mount Isa",
                "Murarrie",
                "Nairand Kay",
                "Nambour",
                "Nerang",
                "Nobby Beach",
                "Noosa Heads",
                "Noosaville",
                "Paddington",
                "Port Douglas",
                "Robina",
                "Rockhampton",
                "Rocklea",
                "Roma",
                "Rubyvale",
                "Sanctuary Cove",
                "Slade Point",
                "Southport",
                "Suburb",
                "Sunshine Coast",
                "Tewantin-Noosa",
                "The Summit",
                "Toowong",
                "Toowoomba",
                "Townsville",
                "Victoria Point",
                "Warwick",
                "Willawong",
                "Yatala",
                "Yeppoon",
                "Zillmere"
            ],
            "Tasmania": [
                "Bridgewater-Gagebrook",
                "Burnie",
                "Burnie-Devonport",
                "Glenorchy",
                "Hobart",
                "Kingston-Blackmans Bay",
                "Launceston",
                "New Norfolk",
                "Rowella",
                "Sheffield",
                "Ulverstone"
            ],
            "Templestowe": [
                "Templestowe"
            ],
            "Victoria": [
                "Albion",
                "Ararat",
                "Avenel",
                "Avondale Heights",
                "Baccus Marsh",
                "Bairnsdale",
                "Ballarat",
                "Balwyn",
                "Batman",
                "Bayswater",
                "Belmont",
                "Benalla",
                "Bendigo",
                "Bentleigh East",
                "Berwick",
                "Blackburn",
                "Blue Mountains",
                "Boonah",
                "Boronia",
                "Box Hill",
                "Brighton East",
                "Bundoora",
                "Campbellfield",
                "Carlton South",
                "Caroline Springs",
                "Castle Hill",
                "Castlemaine",
                "Caulfield",
                "Cheltenham",
                "Chester",
                "Clayton South",
                "Clifton Springs",
                "Coburg",
                "Colac",
                "Collingwood",
                "Craigieburn",
                "Cranbourne",
                "Crib Point",
                "Dandenong",
                "Devon Meadows",
                "Diamond Creek",
                "Doncaster East",
                "Doncester",
                "Donvale",
                "Echuca-Moama",
                "Elsternwick",
                "Eltham",
                "Fairfield",
                "Fitzroy",
                "Frnakston",
                "Geelong",
                "Glen Waverley",
                "Glenorchy",
                "Hallam",
                "Hamilton",
                "Hastings",
                "Healesville",
                "Horsham",
                "Hughesdale",
                "Ivanhoe",
                "Keilor",
                "Kensington",
                "Kerang",
                "Kilmore",
                "Kilsyth",
                "Kyabram",
                "Lakes Entrance",
                "Lalor",
                "Lara",
                "Laverton North",
                "Leopold",
                "Lower Templestowe",
                "Maidstone",
                "Malvern",
                "Maryborough",
                "Melbourne",
                "Melton",
                "Middle Park",
                "Mildura",
                "Mitcham",
                "Moe-Yallourn",
                "Moorabbin",
                "Mordialloc",
                "Morwell",
                "Mulgrave",
                "Murrumbeena",
                "Neerim South",
                "Noble Park North",
                "Notting Hill",
                "Oak Park",
                "Ocean Grove-Barwon Heads",
                "Olinda",
                "Pakenham",
                "Park Orchards",
                "Port Melbourne",
                "Portland",
                "Prahran",
                "Preston",
                "Pritbourne",
                "Queenscliff",
                "Reservoir",
                "Ringwood",
                "Rowville",
                "Sale",
                "Sandringham",
                "Scoresby",
                "Sebastopol",
                "Seymour",
                "Shepparton-Mooroopna",
                "Somerville",
                "South Yarra",
                "Southbank",
                "St. Albans",
                "St. Kilda East",
                "Stawell",
                "Sunbury",
                "Sunshine West",
                "Swan Hill",
                "Tatura",
                "Thomastown",
                "Thornbury",
                "Tongala",
                "Torquay",
                "Traralgon",
                "Tullamarine",
                "Vermont",
                "Wangaratta",
                "Wantirna",
                "Warragul",
                "Warrnambool",
                "Welshpool",
                "Wendouree",
                "Wonthaggi",
                "Woodend",
                "Yarrawonga-Mulwala",
                "Yea"
            ],
            "Werribee south": [
                "Albion",
                "Ararat",
                "Avenel",
                "Avondale Heights",
                "Baccus Marsh",
                "Bairnsdale",
                "Ballarat",
                "Balwyn",
                "Batman",
                "Bayswater",
                "Belmont",
                "Benalla",
                "Bendigo",
                "Bentleigh East",
                "Berwick",
                "Blackburn",
                "Blue Mountains",
                "Boonah",
                "Boronia",
                "Box Hill",
                "Brighton East",
                "Bundoora",
                "Campbellfield",
                "Carlton South",
                "Caroline Springs",
                "Castle Hill",
                "Castlemaine",
                "Caulfield",
                "Cheltenham",
                "Chester",
                "Clayton South",
                "Clifton Springs",
                "Coburg",
                "Colac",
                "Collingwood",
                "Craigieburn",
                "Cranbourne",
                "Crib Point",
                "Dandenong",
                "Devon Meadows",
                "Diamond Creek",
                "Doncaster East",
                "Doncester",
                "Donvale",
                "Echuca-Moama",
                "Elsternwick",
                "Eltham",
                "Fairfield",
                "Fitzroy",
                "Frnakston",
                "Geelong",
                "Glen Waverley",
                "Glenorchy",
                "Hallam",
                "Hamilton",
                "Hastings",
                "Healesville",
                "Horsham",
                "Hughesdale",
                "Ivanhoe",
                "Keilor",
                "Kensington",
                "Kerang",
                "Kilmore",
                "Kilsyth",
                "Kyabram",
                "Lakes Entrance",
                "Lalor",
                "Lara",
                "Laverton North",
                "Leopold",
                "Lower Templestowe",
                "Maidstone",
                "Malvern",
                "Maryborough",
                "Melbourne",
                "Melton",
                "Middle Park",
                "Mildura",
                "Mitcham",
                "Moe-Yallourn",
                "Moorabbin",
                "Mordialloc",
                "Morwell",
                "Mulgrave",
                "Murrumbeena",
                "Neerim South",
                "Noble Park North",
                "Notting Hill",
                "Oak Park",
                "Ocean Grove-Barwon Heads",
                "Olinda",
                "Pakenham",
                "Park Orchards",
                "Port Melbourne",
                "Portland",
                "Prahran",
                "Preston",
                "Pritbourne",
                "Queenscliff",
                "Reservoir",
                "Ringwood",
                "Rowville",
                "Sale",
                "Sandringham",
                "Scoresby",
                "Sebastopol",
                "Seymour",
                "Shepparton-Mooroopna",
                "Somerville",
                "South Yarra",
                "Southbank",
                "St. Albans",
                "St. Kilda East",
                "Stawell",
                "Sunbury",
                "Sunshine West",
                "Swan Hill",
                "Tatura",
                "Thomastown",
                "Thornbury",
                "Tongala",
                "Torquay",
                "Traralgon",
                "Tullamarine",
                "Vermont",
                "Wangaratta",
                "Wantirna",
                "Warragul",
                "Warrnambool",
                "Welshpool",
                "Wendouree",
                "Wonthaggi",
                "Woodend",
                "Yarrawonga-Mulwala",
                "Yea"
            ],
            "Western Australia": [
                "Albion",
                "Ararat",
                "Avenel",
                "Avondale Heights",
                "Baccus Marsh",
                "Bairnsdale",
                "Ballarat",
                "Balwyn",
                "Batman",
                "Bayswater",
                "Belmont",
                "Benalla",
                "Bendigo",
                "Bentleigh East",
                "Berwick",
                "Blackburn",
                "Blue Mountains",
                "Boonah",
                "Boronia",
                "Box Hill",
                "Brighton East",
                "Bundoora",
                "Campbellfield",
                "Carlton South",
                "Caroline Springs",
                "Castle Hill",
                "Castlemaine",
                "Caulfield",
                "Cheltenham",
                "Chester",
                "Clayton South",
                "Clifton Springs",
                "Coburg",
                "Colac",
                "Collingwood",
                "Craigieburn",
                "Cranbourne",
                "Crib Point",
                "Dandenong",
                "Devon Meadows",
                "Diamond Creek",
                "Doncaster East",
                "Doncester",
                "Donvale",
                "Echuca-Moama",
                "Elsternwick",
                "Eltham",
                "Fairfield",
                "Fitzroy",
                "Frnakston",
                "Geelong",
                "Glen Waverley",
                "Glenorchy",
                "Hallam",
                "Hamilton",
                "Hastings",
                "Healesville",
                "Horsham",
                "Hughesdale",
                "Ivanhoe",
                "Keilor",
                "Kensington",
                "Kerang",
                "Kilmore",
                "Kilsyth",
                "Kyabram",
                "Lakes Entrance",
                "Lalor",
                "Lara",
                "Laverton North",
                "Leopold",
                "Lower Templestowe",
                "Maidstone",
                "Malvern",
                "Maryborough",
                "Melbourne",
                "Melton",
                "Middle Park",
                "Mildura",
                "Mitcham",
                "Moe-Yallourn",
                "Moorabbin",
                "Mordialloc",
                "Morwell",
                "Mulgrave",
                "Murrumbeena",
                "Neerim South",
                "Noble Park North",
                "Notting Hill",
                "Oak Park",
                "Ocean Grove-Barwon Heads",
                "Olinda",
                "Pakenham",
                "Park Orchards",
                "Port Melbourne",
                "Portland",
                "Prahran",
                "Preston",
                "Pritbourne",
                "Queenscliff",
                "Reservoir",
                "Ringwood",
                "Rowville",
                "Sale",
                "Sandringham",
                "Scoresby",
                "Sebastopol",
                "Seymour",
                "Shepparton-Mooroopna",
                "Somerville",
                "South Yarra",
                "Southbank",
                "St. Albans",
                "St. Kilda East",
                "Stawell",
                "Sunbury",
                "Sunshine West",
                "Swan Hill",
                "Tatura",
                "Thomastown",
                "Thornbury",
                "Tongala",
                "Torquay",
                "Traralgon",
                "Tullamarine",
                "Vermont",
                "Wangaratta",
                "Wantirna",
                "Warragul",
                "Warrnambool",
                "Welshpool",
                "Wendouree",
                "Wonthaggi",
                "Woodend",
                "Yarrawonga-Mulwala",
                "Yea"
            ],
            "Wheeler": [
                "Wheeler"
            ]
        }
    },
    {
        "id": 15,
        "name": "Austria",
        "iso3": "AUT",
        "iso2": "AT",
        "phone_code": "43",
        "capital": "Vienna",
        "currency": "EUR",
        "states": {
            "Bundesland Salzburg": [
                "Wheeler"
            ],
            "Bundesland Steiermark": [
                "Wheeler"
            ],
            "Bundesland Tirol": [
                "Wheeler"
            ],
            "Burgenland": [
                "Eisenstadt",
                "GroBpetersdorf",
                "Jennersdorf",
                "Kemeten",
                "Mattersburg",
                "Neudorfl",
                "Neusiedl am See",
                "Oberwart",
                "Pinkafeld",
                "Rust"
            ],
            "Carinthia": [
                "Maria Rain",
                "Poggersdorf"
            ],
            "Karnten": [
                "Althofen",
                "Arnoldstein",
                "Bad Sankt Leonhard",
                "Bleiburg",
                "Ebenthal",
                "Eberndorf",
                "Feldkirchen",
                "Ferlach",
                "Finkenstein",
                "Friesach",
                "Hermagor",
                "Klagenfurt",
                "Lohnsburg",
                "Moosburg",
                "Paternion",
                "Radentheim",
                "Sankt Andra",
                "Sankt Jakob",
                "Sankt Veit",
                "Seeboden",
                "Spittal",
                "Velden am Worthersee",
                "Villach",
                "Volkermarkt",
                "Wernberg",
                "Wolfsberg"
            ],
            "Liezen": [
                "Althofen",
                "Arnoldstein",
                "Bad Sankt Leonhard",
                "Bleiburg",
                "Ebenthal",
                "Eberndorf",
                "Feldkirchen",
                "Ferlach",
                "Finkenstein",
                "Friesach",
                "Hermagor",
                "Klagenfurt",
                "Lohnsburg",
                "Moosburg",
                "Paternion",
                "Radentheim",
                "Sankt Andra",
                "Sankt Jakob",
                "Sankt Veit",
                "Seeboden",
                "Spittal",
                "Velden am Worthersee",
                "Villach",
                "Volkermarkt",
                "Wernberg",
                "Wolfsberg"
            ],
            "Lower Austria": [
                "Althofen",
                "Arnoldstein",
                "Bad Sankt Leonhard",
                "Bleiburg",
                "Ebenthal",
                "Eberndorf",
                "Feldkirchen",
                "Ferlach",
                "Finkenstein",
                "Friesach",
                "Hermagor",
                "Klagenfurt",
                "Lohnsburg",
                "Moosburg",
                "Paternion",
                "Radentheim",
                "Sankt Andra",
                "Sankt Jakob",
                "Sankt Veit",
                "Seeboden",
                "Spittal",
                "Velden am Worthersee",
                "Villach",
                "Volkermarkt",
                "Wernberg",
                "Wolfsberg"
            ],
            "Niederosterreich": [
                "Amstetten",
                "Bad Voslau",
                "Baden",
                "Berndorf",
                "Boheimkirchen",
                "Bruck an der Leitha",
                "Brunn",
                "Deutsch-Wagram",
                "Ebreichsdorf",
                "Eggendorf",
                "Fischamend",
                "Gablitz",
                "Ganserndorf",
                "Gerasdorf",
                "Gloggnitz",
                "Gmund",
                "Greifenstein",
                "GroB-Enzersdorf",
                "GroB-Gerungs",
                "Guntramsdorf",
                "Haag",
                "Hainburg",
                "Heidenreichstein",
                "Herzogenburg",
                "Himberg",
                "Hollabrunn",
                "Horn",
                "Klosterneuburg",
                "Korneuburg",
                "Kottingbrunn",
                "Krems",
                "Laa",
                "Langenlois",
                "Langenzersdorf",
                "Leobendorf",
                "Leopoldsdorf",
                "Lilienfeld",
                "Loipersdorf",
                "Maria Enzersdorf",
                "Melk",
                "Mistelbach",
                "Modling",
                "Neulengbach",
                "Neunkirchen",
                "Niederleis",
                "Ober-Grabendorf",
                "Perchtoldsdorf",
                "Pernitz",
                "Pottendorf",
                "Poysdorf",
                "Pressbaum",
                "Purgstall",
                "Purkersdorf",
                "Reichenau",
                "Retz",
                "Sankt Andra-Wordern",
                "Sankt Peter in der Au",
                "Sankt Polten",
                "Sankt Valentin",
                "Scheibbs",
                "Schrems",
                "Schwechat",
                "Seitenstetten",
                "Sollenau",
                "Stockerau",
                "Strasshof",
                "Ternitz",
                "Traiskirchen",
                "Traismauer",
                "Tulln",
                "Vosendorf",
                "Waidhofen",
                "Wiener Neudorf",
                "Wiener Neustadt",
                "Wieselburg",
                "Wilhelmsburg",
                "Wolkersdorf",
                "Ybbs",
                "Ybbsitz",
                "Zistersdorf",
                "Zwettl"
            ],
            "Oberosterreich": [
                "Alkoven",
                "Altheim",
                "Altmunster",
                "Andorf",
                "Ansfelden",
                "Asten",
                "Attnang-Puchheim",
                "Aurolzmunster",
                "Bad Goisern",
                "Bad Hall",
                "Bad Ischl",
                "Braunau",
                "Breitenfurt",
                "Ebensee",
                "Eferding",
                "Engerwitzdorf",
                "Enns",
                "Feldkirchen an der Donau",
                "Frankenburg",
                "Freistadt",
                "Gallneukirchen",
                "Garsten",
                "Gmunden",
                "Gramastetten",
                "Grieskirchen",
                "Gunskirchen",
                "Horsching",
                "Kirchdorf an der Krems",
                "Kremsmunster",
                "Krenglbach",
                "Laakirchen",
                "Lenzing",
                "Leonding",
                "Linz",
                "Loibichl",
                "Marchtrenk",
                "Mattighofen",
                "Mauthausen",
                "Micheldorf",
                "Neuhofen an der Krems",
                "Ohlsdorf",
                "Ottensheim",
                "Pasching",
                "Perg",
                "Pettenbach",
                "Pram",
                "Pregarten",
                "Puchenau",
                "Regau",
                "Ried",
                "Rohrbach in Oberosterreich",
                "Rutzenmoos",
                "Sankt Florian",
                "Sankt Georgen",
                "Sankt Marien",
                "Scharding",
                "Scharnstein",
                "Schwertberg",
                "Seewalchen",
                "Sierning",
                "Stadl-Paura",
                "Steyr",
                "Steyregg",
                "Steyrermuhl",
                "Thalheim",
                "Timelkam",
                "Traun",
                "Vocklabruck",
                "Vocklamarkt",
                "Vorchdorf",
                "Wels",
                "Wilhering"
            ],
            "Salzburg": [
                "Abtenau",
                "Anif",
                "Bad Gastein",
                "Bad Hofgastein",
                "Bergheim",
                "Bischofshofen",
                "Bruck an der GroBglocknerstraB",
                "Burmoos",
                "Elsbethen",
                "Eugendorf",
                "Forstau",
                "Grodig",
                "Hallein",
                "Hallwang",
                "Henndorf",
                "Kuchl",
                "Mayrhofen",
                "Mittersill",
                "Neumarkt",
                "Oberndorf",
                "Obertrum am See",
                "Piesendorf",
                "Puch",
                "Radstadt",
                "Saalfelden",
                "Salzburg",
                "Sankt Johann im Pongau",
                "Seekirchen",
                "Sieghartskirchen",
                "StraBwalchen",
                "Strobl",
                "Tamsweg",
                "Thalgau",
                "Wals-Siezenheim",
                "Wolfgangsee",
                "Zell am See"
            ],
            "Schleswig-Holstein": [
                "Albersdorf"
            ],
            "Steiermark": [
                "Bad Aussee",
                "Barnbach",
                "Bruck an der Mur",
                "Deutschlandsberg",
                "Eisenerz",
                "Feldbach",
                "Feldkirchen bei Graz",
                "Fohnsdorf",
                "Frohnleiten",
                "Furstenfeld",
                "Gleisdorf",
                "Gratkorn",
                "Graz",
                "Hartberg",
                "Judenburg",
                "Judendorf-StraBengel",
                "Kapfenberg",
                "Karlsdorf",
                "Kindberg",
                "Knittelfeld",
                "Koflach",
                "Krieglach",
                "Lannach",
                "Leibnitz",
                "Leoben",
                "Liezen",
                "Murzzuschlag",
                "Rottenmann",
                "Schladming",
                "Seiersberg",
                "Spielberg",
                "Trofaiach",
                "Voitsberg",
                "Wagna",
                "Weiz",
                "Zeltweg"
            ],
            "Styria": [
                "Deutschfeistritz",
                "Liezen",
                "Sankt Bartholoma"
            ],
            "Tirol": [
                "Absam",
                "Axams",
                "Ebbs",
                "Fugen",
                "Hall",
                "Haselgehr",
                "Hopfgarten",
                "Imst",
                "Innsbruck",
                "Jenbach",
                "Kirchberg",
                "Kirchbichl",
                "Kitzbuhel",
                "Kramsach",
                "Kufstein",
                "Landeck",
                "Lienz",
                "Matrei",
                "Neustift im Stubaital",
                "Reutte",
                "Rum",
                "Sankt Johann in Tirol",
                "Scheffau",
                "Schwaz",
                "St. Johann Am Walde",
                "Telfs",
                "Vols",
                "Vomp",
                "Wattens",
                "Worgl",
                "Zirl"
            ],
            "Upper Austria": [
                "Absam",
                "Axams",
                "Ebbs",
                "Fugen",
                "Hall",
                "Haselgehr",
                "Hopfgarten",
                "Imst",
                "Innsbruck",
                "Jenbach",
                "Kirchberg",
                "Kirchbichl",
                "Kitzbuhel",
                "Kramsach",
                "Kufstein",
                "Landeck",
                "Lienz",
                "Matrei",
                "Neustift im Stubaital",
                "Reutte",
                "Rum",
                "Sankt Johann in Tirol",
                "Scheffau",
                "Schwaz",
                "St. Johann Am Walde",
                "Telfs",
                "Vols",
                "Vomp",
                "Wattens",
                "Worgl",
                "Zirl"
            ],
            "Vorarlberg": [
                "Altach",
                "Bludenz",
                "Bregenz",
                "Chassieu",
                "Dietmannsried",
                "Dornbirn",
                "Feldkirch",
                "Frastanz",
                "Gotzis",
                "Hard",
                "Hochst",
                "Hohenems",
                "Horbranz",
                "Hufingen",
                "Lauterach",
                "Lochau",
                "Lustenau",
                "Mittelberg",
                "Nenzing",
                "Nuziders",
                "Rankweil",
                "Schruns",
                "Thuringen",
                "Wolfurt"
            ],
            "Wien": [
                "Vienna",
                "Wien"
            ]
        }
    },
    {
        "id": 16,
        "name": "Azerbaijan",
        "iso3": "AZE",
        "iso2": "AZ",
        "phone_code": "994",
        "capital": "Baku",
        "currency": "AZN",
        "states": {
            "Abseron": [
                "Alat",
                "Artyom",
                "Baki",
                "Bakixanov",
                "Balaxani",
                "Bilacari",
                "Bilqax",
                "Bina",
                "Buzovna",
                "Haci Zeynalabdin",
                "Hovsan",
                "Lokbatan",
                "Mastaga",
                "Puta",
                "Qarasuxur",
                "Qobustan",
                "Rasulzada",
                "Sabuncu",
                "Sanqacal",
                "Sumqayit",
                "Suraxani",
                "Xirdalan",
                "Zirya"
            ],
            "Baki Sahari": [
                "Alat",
                "Artyom",
                "Baki",
                "Bakixanov",
                "Balaxani",
                "Bilacari",
                "Bilqax",
                "Bina",
                "Buzovna",
                "Haci Zeynalabdin",
                "Hovsan",
                "Lokbatan",
                "Mastaga",
                "Puta",
                "Qarasuxur",
                "Qobustan",
                "Rasulzada",
                "Sabuncu",
                "Sanqacal",
                "Sumqayit",
                "Suraxani",
                "Xirdalan",
                "Zirya"
            ],
            "Ganca": [
                "Daskasan",
                "Ganca",
                "Xanlar"
            ],
            "Ganja": [
                "Ganja"
            ],
            "Kalbacar": [
                "Cabrayil",
                "Kalbacar",
                "Lacin"
            ],
            "Lankaran": [
                "Astara",
                "Goytapa",
                "Lankaran",
                "Masalli",
                "Neftcala"
            ],
            "Mil-Qarabax": [
                "Agcabadi",
                "Agdam",
                "Barda",
                "Mingacevir",
                "Tartar",
                "Yevlax"
            ],
            "Mugan-Salyan": [
                "Ali Bayramli",
                "Bilasuvar",
                "Calilabad",
                "Qarasu",
                "Qazimammad",
                "Saatli",
                "Sabirabad",
                "Salyan"
            ],
            "Nagorni-Qarabax": [
                "Susa",
                "Xankandi",
                "Xocavand"
            ],
            "Naxcivan": [
                "Culfa",
                "Naxcivan",
                "Ordubad",
                "Sadarak",
                "Sarur"
            ],
            "Priaraks": [
                "Beylaqan",
                "Fuzuli",
                "Imisli"
            ],
            "Qazax": [
                "Agstafa",
                "Gadabay",
                "Kovlar",
                "Qaracamirli",
                "Qazax",
                "Samkir",
                "Tovuz"
            ],
            "Saki": [
                "Amircan",
                "Balakan",
                "Katex",
                "Oguz",
                "Qabala",
                "Qax",
                "Saki",
                "Zaqatala"
            ],
            "Sirvan": [
                "Agdas",
                "Agsu",
                "Goycay",
                "Ismayilli",
                "Kurdamir",
                "Samaxi",
                "Ucar",
                "Zardab"
            ],
            "Xacmaz": [
                "Davaci",
                "Quba",
                "Qusar",
                "Siyazan",
                "Xacmaz",
                "Xudat"
            ]
        }
    },
    {
        "id": 17,
        "name": "Bahamas The",
        "iso3": "BHS",
        "iso2": "BS",
        "phone_code": "+1-242",
        "capital": "Nassau",
        "currency": "BSD"
    },
    {
        "id": 18,
        "name": "Bahrain",
        "iso3": "BHR",
        "iso2": "BH",
        "phone_code": "973",
        "capital": "Manama",
        "currency": "BHD",
        "states": {
            "al-Manamah": [
                "al-Manamah"
            ],
            "al-Muharraq": [
                "al-Muharraq"
            ],
            "ar-Rifa\\'a": [
                "ar-Rifa\\'a"
            ],
            "Badiyah": [
                "Badiyah"
            ],
            "Hidd": [
                "Hidd"
            ],
            "Jidd Hafs": [
                "Hidd"
            ],
            "Mahama": [
                "Mahama"
            ],
            "Manama": [
                "Manama"
            ],
            "Sitrah": [
                "Sitrah"
            ],
            "\\'Isa": [
                "\\'Isa"
            ]
        }
    },
    {
        "id": 19,
        "name": "Bangladesh",
        "iso3": "BGD",
        "iso2": "BD",
        "phone_code": "880",
        "capital": "Dhaka",
        "currency": "BDT",
        "states": {
            "Bagar Hat": [
                "\\'Isa"
            ],
            "Bandarban": [
                "Bandarban"
            ],
            "Barguna": [
                "Barguna"
            ],
            "Barisal": [
                "Barisal",
                "Gaurnadi",
                "Mehendiganj",
                "Nalchiti"
            ],
            "Bhola": [
                "Bhola",
                "Burhanuddin",
                "Char Fasson",
                "Lalmohan"
            ],
            "Bogora": [
                "Adamighi",
                "Bogora",
                "Sherpur"
            ],
            "Brahman Bariya": [
                "Adamighi",
                "Bogora",
                "Sherpur"
            ],
            "Chandpur": [
                "Chandpur",
                "Hajiganj"
            ],
            "Chattagam": [
                "Boalkhali",
                "Chattagam",
                "Fatikchhari",
                "Lohagara",
                "Patiya",
                "Rangunia",
                "Raozan",
                "Sandip",
                "Satkaniya"
            ],
            "Chittagong Division": [
                "Boalkhali",
                "Chattagam",
                "Fatikchhari",
                "Lohagara",
                "Patiya",
                "Rangunia",
                "Raozan",
                "Sandip",
                "Satkaniya"
            ],
            "Chuadanga": [
                "Alamdanga",
                "Chuadanga",
                "Damurhuda"
            ],
            "Dhaka": [
                "Dhaka",
                "Dhamrai",
                "Dohar"
            ],
            "Dinajpur": [
                "Bochanganj",
                "Dinajpur",
                "Fulbari",
                "Parbatipur"
            ],
            "Faridpur": [
                "Bhanga",
                "Char Bhadrasan",
                "Faridpur"
            ],
            "Feni": [
                "Chhagalnaiya",
                "Feni"
            ],
            "Gaybanda": [
                "Gaybanda"
            ],
            "Gazipur": [
                "Gazipur",
                "Tungi"
            ],
            "Gopalganj": [
                "Gopalganj",
                "Tungi Para"
            ],
            "Habiganj": [
                "Baniachang",
                "Habiganj"
            ],
            "Jaipur Hat": [
                "Baniachang",
                "Habiganj"
            ],
            "Jamalpur": [
                "Jamalpur",
                "Sarishabari"
            ],
            "Jessor": [
                "Abhaynagar",
                "Jessor",
                "Jhikargachha",
                "Keshabpur"
            ],
            "Jhalakati": [
                "Jhalakati"
            ],
            "Jhanaydah": [
                "Jhanaydah",
                "Kaliganj",
                "Kotchandpur",
                "Shailkupa"
            ],
            "Khagrachhari": [
                "Khagrachari",
                "Manikchhari",
                "Ramgarh"
            ],
            "Khulna": [
                "Khulna",
                "Phultala"
            ],
            "Kishorganj": [
                "Bajitpur",
                "Bhairab Bazar",
                "Itna",
                "Kishorganj"
            ],
            "Koks Bazar": [
                "Bajitpur",
                "Bhairab Bazar",
                "Itna",
                "Kishorganj"
            ],
            "Komilla": [
                "Komilla",
                "Laksham"
            ],
            "Kurigram": [
                "Chilmari",
                "Kurigram",
                "Nageshwari",
                "Ulipur"
            ],
            "Kushtiya": [
                "Bheramara",
                "Kushtiya"
            ],
            "Lakshmipur": [
                "Lakshmipur",
                "Ramganj",
                "Ramgati",
                "Raypur"
            ],
            "Lalmanir Hat": [
                "Lakshmipur",
                "Ramganj",
                "Ramgati",
                "Raypur"
            ],
            "Madaripur": [
                "Madaripur"
            ],
            "Magura": [
                "Magura"
            ],
            "Maimansingh": [
                "Bhaluka",
                "Fulbaria",
                "Gafargaon",
                "Ishwarganj",
                "Maimansingh",
                "Muktagachha",
                "Trishal"
            ],
            "Manikganj": [
                "Manikganj"
            ],
            "Maulvi Bazar": [
                "Manikganj"
            ],
            "Meherpur": [
                "Meherpur"
            ],
            "Munshiganj": [
                "Munshiganj"
            ],
            "Naral": [
                "Kalia",
                "Naral"
            ],
            "Narayanganj": [
                "Narayanganj",
                "Rupganj"
            ],
            "Narsingdi": [
                "Narsingdi",
                "Roypura"
            ],
            "Nator": [
                "Gurudaspur",
                "Nator"
            ],
            "Naugaon": [
                "Naugaon"
            ],
            "Nawabganj": [
                "Gomastapur",
                "Nawabganj",
                "Shibganj"
            ],
            "Netrakona": [
                "Netrakona"
            ],
            "Nilphamari": [
                "Domar",
                "Nilphamari",
                "Sa\\'idpur"
            ],
            "Noakhali": [
                "Begamganj",
                "Noakhali",
                "Senbagh"
            ],
            "Pabna": [
                "Bera",
                "Bhangura",
                "Ishurdi",
                "Pabna"
            ],
            "Panchagarh": [
                "Panchagarh"
            ],
            "Patuakhali": [
                "Patuakhali"
            ],
            "Pirojpur": [
                "Bhandaria",
                "Mathbaria",
                "Nesarabad",
                "Pirojpur"
            ],
            "Rajbari": [
                "Pangsha",
                "Rajbari"
            ],
            "Rajshahi": [
                "Rajshahi"
            ],
            "Rangamati": [
                "Kaptai",
                "Rangamati"
            ],
            "Rangpur": [
                "Badarganj",
                "Kaunia",
                "Rangpur"
            ],
            "Satkhira": [
                "Satkhira"
            ],
            "Shariatpur": [
                "Palang"
            ],
            "Sherpur": [
                "Nalitabari",
                "Sherpur"
            ],
            "Silhat": [
                "Silhat"
            ],
            "Sirajganj": [
                "Shahjadpur",
                "Sirajganj"
            ],
            "Sunamganj": [
                "Chhatak",
                "Sunamganj"
            ],
            "Tangayal": [
                "Gopalpur",
                "Mirzapur",
                "Sakhipur",
                "Tangayal"
            ],
            "Thakurgaon": [
                "Pirganj",
                "Thakurgaon"
            ]
        }
    },
    {
        "id": 20,
        "name": "Barbados",
        "iso3": "BRB",
        "iso2": "BB",
        "phone_code": "+1-246",
        "capital": "Bridgetown",
        "currency": "BBD",
        "states": {
            "Christ Church": [
                "Pirganj",
                "Thakurgaon"
            ],
            "Saint Andrew": [
                "Pirganj",
                "Thakurgaon"
            ],
            "Saint George": [
                "Pirganj",
                "Thakurgaon"
            ],
            "Saint James": [
                "Pirganj",
                "Thakurgaon"
            ],
            "Saint John": [
                "Pirganj",
                "Thakurgaon"
            ],
            "Saint Joseph": [
                "Pirganj",
                "Thakurgaon"
            ],
            "Saint Lucy": [
                "Pirganj",
                "Thakurgaon"
            ],
            "Saint Michael": [
                "Pirganj",
                "Thakurgaon"
            ],
            "Saint Peter": [
                "Pirganj",
                "Thakurgaon"
            ],
            "Saint Philip": [
                "Pirganj",
                "Thakurgaon"
            ],
            "Saint Thomas": [
                "Pirganj",
                "Thakurgaon"
            ]
        }
    },
    {
        "id": 21,
        "name": "Belarus",
        "iso3": "BLR",
        "iso2": "BY",
        "phone_code": "375",
        "capital": "Minsk",
        "currency": "BYR",
        "states": {
            "Brest": [
                "Baranavichy",
                "Bjaroza",
                "Bjeloozersk",
                "Brest",
                "David-Haradok",
                "Drahichyn",
                "Hancavichy",
                "Ivacevichy",
                "Ivanava",
                "Kamjenec",
                "Kobryn",
                "Kosava",
                "Ljahavichy",
                "Luninjec",
                "Malaryta",
                "Mikashevichy",
                "Pinsk",
                "Pruzhany",
                "Stolin",
                "Vysokoje",
                "Zhabinka"
            ],
            "Homjel\\'": [
                "Buda Kosheljovo",
                "Chechersk",
                "Dobrush",
                "Hojniki",
                "Homjel\\'",
                "Jel\\'sk",
                "Kalinkavichy",
                "Kascjukovka",
                "Mazyr",
                "Narovlja",
                "Pjetrykav",
                "Rahachow",
                "Rechyca",
                "Svetlahorsk",
                "Vasilevichy",
                "Vjetka",
                "Zhlobin",
                "Zhytkavichy"
            ],
            "Hrodna": [
                "Ashmjany",
                "Berjozovka",
                "Hrodna",
                "Lida",
                "Masty",
                "Navahrudak",
                "Shchuchyn",
                "Skidel\\'",
                "Slonim",
                "Smarhon\\'",
                "Vawkavysk"
            ],
            "Mahiljow": [
                "Asipovichy",
                "Babrujsk",
                "Byhau",
                "Chausy",
                "Cherikov",
                "Horki",
                "Kascjukovichy",
                "Klimavichy",
                "Krychaw",
                "Mahiljow",
                "Mstislav",
                "Shklov",
                "Slaeuharad"
            ],
            "Mahilyowskaya Voblasts": [
                "Asipovichy",
                "Babrujsk",
                "Byhau",
                "Chausy",
                "Cherikov",
                "Horki",
                "Kascjukovichy",
                "Klimavichy",
                "Krychaw",
                "Mahiljow",
                "Mstislav",
                "Shklov",
                "Slaeuharad"
            ],
            "Minsk": [
                "Minsk"
            ],
            "Minskaja Voblasts\\'": [
                "Minsk"
            ],
            "Petrik": [
                "Petrikov"
            ],
            "Vicebsk": [
                "Baran\\'",
                "Braslav",
                "Chashniki",
                "Disna",
                "Dokshicy",
                "Dubrovna",
                "Haradok",
                "Hlybokaje",
                "Ljepjel\\'",
                "Mjory",
                "Navapolack",
                "Novalukoml\\'",
                "Orsha",
                "Pastavy",
                "Polack",
                "Polotsk",
                "Senno",
                "Talachyn",
                "Verhnjadzvimsk",
                "Vicebsk"
            ]
        }
    },
    {
        "id": 22,
        "name": "Belgium",
        "iso3": "BEL",
        "iso2": "BE",
        "phone_code": "32",
        "capital": "Brussels",
        "currency": "EUR",
        "states": {
            "Antwerpen": [
                "Aartselaar",
                "Antwerpen",
                "Arendonk",
                "Baarle-Hertog",
                "Balen",
                "Beerse",
                "Berlaar",
                "Boechout",
                "Bonheiden",
                "Boom",
                "Bornem",
                "Borsbeek",
                "Brasschaat",
                "Brecht",
                "Dessel",
                "Deurne",
                "Duffel",
                "Edegem",
                "Ekeren",
                "Essen",
                "Geel",
                "Geluvele",
                "Grobbendonk",
                "Heist-op-den-Berg",
                "Hemiksem",
                "Herentals",
                "Herenthout",
                "Herselt",
                "Hoogstraten",
                "Hove",
                "Hulshout",
                "Kalmpthout",
                "Kalmthout",
                "Kapellen",
                "Kasterlee",
                "Kontich",
                "Laakdal",
                "Lier",
                "Lille",
                "Lint",
                "Malle",
                "Mechelen",
                "Meerhout",
                "Merksplas",
                "Mol",
                "Mortsel",
                "Niel",
                "Nijlen",
                "Olen",
                "Oud-Turnhout",
                "Putte",
                "Puurs",
                "Ranst",
                "Ravels",
                "Retie",
                "Rijkevorsel",
                "Robrechts",
                "Rumst",
                "Schelle",
                "Schilde",
                "Schoten",
                "Sint-Amands",
                "Sint-Katelijne-Waver",
                "Stabroek",
                "Turnhout",
                "Vorselaar",
                "Vosselaar",
                "Westerlo",
                "Wijnegem",
                "Willebroek",
                "Wilrijk",
                "Wommelgem",
                "Wuustwezel",
                "Zandhoven",
                "Zoersel",
                "Zwijndrecht"
            ],
            "Berchem": [
                "Berchem"
            ],
            "Brabant": [
                "Alsemberg",
                "Asse Zellik",
                "Brussels",
                "Bruxelles",
                "Diegem"
            ],
            "Brabant Wallon": [
                "Alsemberg",
                "Asse Zellik",
                "Brussels",
                "Bruxelles",
                "Diegem"
            ],
            "Brussel": [
                "Brussel"
            ],
            "East Flanders": [
                "Brussel"
            ],
            "Hainaut": [
                "Aiseau-Presles",
                "Anderlues",
                "Antoing",
                "Ath",
                "Beaumont",
                "Beloeil",
                "Bernissart",
                "Binche",
                "Boussu",
                "Braine-le-Comte",
                "Brugelette",
                "Brunehaut",
                "Celles",
                "Chapelle-lez-Herlaimont",
                "Charleroi",
                "Chatelet",
                "Chievres",
                "Chimay",
                "Colfontaine",
                "Comines-Warneton",
                "Courcelles",
                "Dour",
                "Ecaussinnes",
                "Ellezelles",
                "Enghien",
                "Erquelinnes",
                "Estaimpuis",
                "Estinnes",
                "Farciennes",
                "Fleurus",
                "Flobecq",
                "Fontaine-l\\'Eveque",
                "Frameries",
                "Frasnes-lez-Anvaing",
                "Froidchapelle",
                "Gerpinnes",
                "Haine-Saint-Pierre",
                "Ham-sur-Heure-Nalinnes",
                "Hensies",
                "Honnelles",
                "Jurbise",
                "La Louviere",
                "Le Roeulx",
                "Lens",
                "Les Bons Villers",
                "Lessines",
                "Leuze-en-Hainaut",
                "Lobbes",
                "Manage",
                "Merbes-le-Chateau",
                "Momignies",
                "Mons",
                "Mont-de-l\\'Enclus",
                "Montigny-le-Tilleul",
                "Morlanwelz",
                "Mouscron",
                "Pecq",
                "Peruwelz",
                "Pont-a-Celles",
                "Quaregnon",
                "Quevy",
                "Quievrain",
                "Rumes",
                "Saint-Ghislain",
                "Seneffe",
                "Silly",
                "Sivry-Rance",
                "Soignies",
                "Thuin",
                "Tournai"
            ],
            "Liege": [
                "Amay",
                "Amel",
                "Angleur",
                "Ans",
                "Anthisnes",
                "Aubel",
                "Awans",
                "Aywaille",
                "Baelen",
                "Bassenge",
                "Berloz",
                "Beyne-Heusay",
                "Blegny",
                "Braives",
                "Bullingen",
                "Burdinne",
                "Burg-Reuland",
                "Butgenbach",
                "Chaudfontaine",
                "Clavier",
                "Comblain-au-Pont",
                "Crisnee",
                "Dalhem",
                "Dison",
                "Donceel",
                "Engis",
                "Esneux",
                "Eupen",
                "Faimes",
                "Ferrieres",
                "Fexhe-le-Haut-Clocher",
                "Flemalle",
                "Fleron",
                "Geer",
                "Grace-Hollogne",
                "Hamoir",
                "Hannut",
                "Heron",
                "Herstal",
                "Herve",
                "Huy",
                "Jalhay",
                "Juprelle",
                "Kelmis",
                "Liege",
                "Lierneux",
                "Limbourg",
                "Lincent",
                "Lontzen",
                "Malmedy",
                "Marchin",
                "Modave",
                "Montzen",
                "Nandrin",
                "Neupre",
                "Olne",
                "Oreye",
                "Ouffet",
                "Oupeye",
                "Pepinster",
                "Plombieres",
                "Racour",
                "Raeren",
                "Remicourt",
                "Saint-Georges-sur-Meuse",
                "Saint-Nicolas",
                "Sankt Vith",
                "Seraing",
                "Soumagne",
                "Spa",
                "Sprimont",
                "Stavelot",
                "Stoumont",
                "Theux",
                "Thimister-Clermont",
                "Tinlot",
                "Trois-Ponts",
                "Trooz",
                "Verlaine",
                "Verviers",
                "Villers-le-Bouillet",
                "Vise",
                "Waimes",
                "Wanze",
                "Waremme",
                "Wasseiges",
                "Welkenraedt"
            ],
            "Limburg": [
                "Alken",
                "As",
                "Beringen",
                "Bilzen",
                "Bocholt",
                "Borgloon",
                "Bree",
                "Diepenbeek",
                "Dilsen-Stokkem",
                "Genk",
                "Gingelom",
                "Halen",
                "Ham",
                "Hamont-Achel",
                "Hasselt",
                "Hechtel-Eksel",
                "Heers",
                "Herk-de-Stad",
                "Herstappe",
                "Heusden-Zolder",
                "Hoeselt",
                "Houthalen-Helchteren",
                "Kinrooi",
                "Kortessem",
                "Kuringen",
                "Lanaken",
                "Leopoldsburg",
                "Lommel",
                "Lummen",
                "Maaseik",
                "Maasmechelen",
                "Meeuwen-Gruitrode",
                "Neerpelt",
                "Nieuwerkerken",
                "Opglabbeek",
                "Overpelt",
                "Peer",
                "Riemst",
                "Sint-Truiden",
                "Tessenderlo",
                "Tongeren",
                "Voeren",
                "Wellen",
                "Zonhoven",
                "Zutendaal"
            ],
            "Luxembourg": [
                "Arlon",
                "Attert",
                "Aubange",
                "Bastogne",
                "Bertogne",
                "Bertrix",
                "Bouillon",
                "Chiny",
                "Daverdisse",
                "Durbuy",
                "Erezee",
                "Etalle",
                "Fauvillers",
                "Florenville",
                "Gouvy",
                "Grapfontaine",
                "Habay",
                "Herbeumont",
                "Hotton",
                "Houffalize",
                "La Roche-en-Ardenne",
                "Leglise",
                "Libin",
                "Libramont-Chevigny",
                "Manhay",
                "Marche-en-Famenne",
                "Marloie",
                "Martelange",
                "Meix-devant-Virton",
                "Messancy",
                "Musson",
                "Nassogne",
                "Neufchateau",
                "Paliseul",
                "Rendeux",
                "Rouvroy",
                "Saint-Hubert",
                "Saint-Leger",
                "Sainte-Ode",
                "Tellin",
                "Tenneville",
                "Tintigny",
                "Vaux-sur-Sure",
                "Vielsalm",
                "Virton",
                "Wellin"
            ],
            "Namur": [
                "Andenne",
                "Anhee",
                "Assesse",
                "Beauraing",
                "Bievre",
                "Cerfontaine",
                "Ciney",
                "Couvin",
                "Dinant",
                "Doische",
                "Eghezee",
                "Fernelmont",
                "Floreffe",
                "Florennes",
                "Fosses-la-Ville",
                "Gedinne",
                "Gembloux",
                "Gesves",
                "Hamois",
                "Hastiere",
                "Havelange",
                "Houyet",
                "Jambes",
                "Jemeppe-sur-Sambre",
                "La Bruyere",
                "Malonne",
                "Mettet",
                "Namur",
                "Ohey",
                "Onhaye",
                "Philippeville",
                "Profondeville",
                "Rochefort",
                "Sambreville",
                "Sombreffe",
                "Somme-Leuze",
                "Suarlee",
                "Viroinval",
                "Vresse-sur-Semois",
                "Walcourt",
                "Yvoir"
            ],
            "Ontario": [
                "Gullegem",
                "Langdorp"
            ],
            "Oost-Vlaanderen": [
                "Aalst",
                "Aalter",
                "Assenede",
                "Berlare",
                "Beveren",
                "Brakel",
                "Buggenhout",
                "De Pinte",
                "Deinze",
                "Denderleeuw",
                "Dendermonde",
                "Destelbergen",
                "Drongen",
                "Eeklo",
                "Erembodegem",
                "Erpe-Mere",
                "Evergem",
                "Gavere",
                "Gent",
                "Geraardsbergen",
                "Ghent",
                "Haaltert",
                "Hamme",
                "Herzele",
                "Horebeke",
                "Kaprijke",
                "Kerksken",
                "Kluisbergen",
                "Knesselare",
                "Kruibeke",
                "Kruishoutem",
                "Laarne",
                "Lebbeke",
                "Lede",
                "Lierde",
                "Lochristi",
                "Lokeren",
                "Lovendegem",
                "Maarkedal",
                "Maldegem",
                "Melle",
                "Merelbeke",
                "Moerbeke",
                "Nazareth",
                "Nevele",
                "Ninove",
                "Oosterzele",
                "Oudenaarde",
                "Ronse",
                "Semmerzake",
                "Sint-Gillis-Waas",
                "Sint-Laureins",
                "Sint-Lievens-Houtem",
                "Sint-Martens-Latem",
                "Sint-Niklaas",
                "Stekene",
                "Temse",
                "Waarschoot",
                "Waasmunster",
                "Wachtebeke",
                "Wetteren",
                "Wichelen",
                "Wortegem-Petegem",
                "Zele",
                "Zelzate",
                "Zingem",
                "Zomergem",
                "Zottegem",
                "Zulte",
                "Zwalm",
                "Zwijnaarde"
            ],
            "Provincie Brabant": [
                "Aalst",
                "Aalter",
                "Assenede",
                "Berlare",
                "Beveren",
                "Brakel",
                "Buggenhout",
                "De Pinte",
                "Deinze",
                "Denderleeuw",
                "Dendermonde",
                "Destelbergen",
                "Drongen",
                "Eeklo",
                "Erembodegem",
                "Erpe-Mere",
                "Evergem",
                "Gavere",
                "Gent",
                "Geraardsbergen",
                "Ghent",
                "Haaltert",
                "Hamme",
                "Herzele",
                "Horebeke",
                "Kaprijke",
                "Kerksken",
                "Kluisbergen",
                "Knesselare",
                "Kruibeke",
                "Kruishoutem",
                "Laarne",
                "Lebbeke",
                "Lede",
                "Lierde",
                "Lochristi",
                "Lokeren",
                "Lovendegem",
                "Maarkedal",
                "Maldegem",
                "Melle",
                "Merelbeke",
                "Moerbeke",
                "Nazareth",
                "Nevele",
                "Ninove",
                "Oosterzele",
                "Oudenaarde",
                "Ronse",
                "Semmerzake",
                "Sint-Gillis-Waas",
                "Sint-Laureins",
                "Sint-Lievens-Houtem",
                "Sint-Martens-Latem",
                "Sint-Niklaas",
                "Stekene",
                "Temse",
                "Waarschoot",
                "Waasmunster",
                "Wachtebeke",
                "Wetteren",
                "Wichelen",
                "Wortegem-Petegem",
                "Zele",
                "Zelzate",
                "Zingem",
                "Zomergem",
                "Zottegem",
                "Zulte",
                "Zwalm",
                "Zwijnaarde"
            ],
            "Vlaams-Brabant": [
                "Aarschot",
                "Affligem",
                "Asse",
                "Beersel",
                "Begijnendijk",
                "Bekkevoort",
                "Bertem",
                "Bever",
                "Bierbeek",
                "Boortmeerbeek",
                "Boutersem",
                "Diest",
                "Dilbeek",
                "Drogenbos",
                "Galmaarden",
                "Geetbets",
                "Glabbeek",
                "Gooik",
                "Grimbergen",
                "Haacht",
                "Halle",
                "Herent",
                "Herne",
                "Hoegaarden",
                "Hoeilaart",
                "Holsbeek",
                "Huldenberg",
                "Kampenhout",
                "Kapelle-op-den-Bos",
                "Keerbergen",
                "Kortenaken",
                "Kortenberg",
                "Kraainem",
                "Landen",
                "Lennik",
                "Leuven",
                "Liedekerke",
                "Linkebeek",
                "Linter",
                "Londerzeel",
                "Lubbeek",
                "Machelen",
                "Meise",
                "Merchtem",
                "Opwijk",
                "Oud-Heverlee",
                "Overijse",
                "Pepingen",
                "Roosdaal",
                "Rotselaar",
                "Scherpenheuvel-Zichem",
                "Sint-Genesius-Rode",
                "Sint-Pieters-Leeuw",
                "Steenokkerzeel",
                "Ternat",
                "Tervuren",
                "Tielt-Winge",
                "Tienen",
                "Tremelo",
                "Vilvoorde",
                "Wemmel",
                "Wezembeek-Oppem",
                "Zaventem",
                "Zemst",
                "Zoutleeuw"
            ],
            "Wallonne": [
                "Callenelle",
                "Marcinelle",
                "Strepy-Bracquegnies"
            ],
            "West-Vlaanderen": [
                "Alveringem",
                "Anzegem",
                "Ardooie",
                "Avelgem",
                "Beernem",
                "Blankenberge",
                "Bredene",
                "Brugge",
                "Damme",
                "De Haan",
                "De Panne",
                "Deerlijk",
                "Dentergem",
                "Diksmuide",
                "Geluveld",
                "Gistel",
                "Harelbeke",
                "Heuvelland",
                "Hooglede",
                "Houthulst",
                "Ichtegem",
                "Ieper",
                "Ingelmunster",
                "Ingooigem",
                "Izegem",
                "Jabbeke",
                "Knokke-Heist",
                "Koekelare",
                "Koksijde",
                "Kortemark",
                "Kortrijk",
                "Kuurne",
                "Langemark-Poelkapelle",
                "Ledegem",
                "Lendelede",
                "Lichtervelde",
                "Lo-Reninge",
                "Menen",
                "Mesen",
                "Meulebeke",
                "Middelkerke",
                "Moorslede",
                "Nieuwpoort",
                "Oostende",
                "Oostkamp",
                "Oostrozebeke",
                "Oudenburg",
                "Pittem",
                "Poperinge",
                "Roeselare",
                "Ruiselede",
                "Spiere-Helkijn",
                "Staden",
                "Tielt",
                "Torhout",
                "Veurne",
                "Vleteren",
                "Waregem",
                "Wervik",
                "Wevelgem",
                "Wielsbeke",
                "Wingene",
                "Zedelgem",
                "Zeebrugge",
                "Zonnebeke",
                "Zuienkerke",
                "Zwevegem"
            ]
        }
    },
    {
        "id": 23,
        "name": "Belize",
        "iso3": "BLZ",
        "iso2": "BZ",
        "phone_code": "501",
        "capital": "Belmopan",
        "currency": "BZD",
        "states": {
            "Belize": [
                "Belize",
                "San Pedro"
            ],
            "Cayo": [
                "Belmopan",
                "Benque Viejo",
                "San Ignacio"
            ],
            "Corozal": [
                "Corozal"
            ],
            "Orange Walk": [
                "Corozal"
            ],
            "Stann Creek": [
                "Corozal"
            ],
            "Toledo": [
                "Punta Gorda"
            ]
        }
    },
    {
        "id": 24,
        "name": "Benin",
        "iso3": "BEN",
        "iso2": "BJ",
        "phone_code": "229",
        "capital": "Porto-Novo",
        "currency": "XOF",
        "states": {
            "Alibori": [
                "Kandi",
                "Malanville"
            ],
            "Atacora": [
                "Batia",
                "Boukombe",
                "Kouande",
                "Natitingou"
            ],
            "Atlantique": [
                "Allada",
                "Cotonou",
                "Ouidah",
                "Tanguiete"
            ],
            "Borgou": [
                "Bembereke",
                "Nikki",
                "Parakou",
                "Tchaourou"
            ],
            "Collines": [
                "Dassa",
                "Savalou",
                "Save"
            ],
            "Couffo": [
                "Aplahoue",
                "Dogbo"
            ],
            "Donga": [
                "Djougou"
            ],
            "Littoral": [
                "Cotonou"
            ],
            "Mono": [
                "Athieme",
                "Come",
                "Grand Popo",
                "Lokossa"
            ],
            "Oueme": [
                "Adjohoun",
                "Porto Novo"
            ],
            "Plateau": [
                "Ketou",
                "Pobe",
                "Sakete"
            ],
            "Zou": [
                "Abomey",
                "Bohicon",
                "Cove",
                "Zagnanado"
            ]
        }
    },
    {
        "id": 25,
        "name": "Bermuda",
        "iso3": "BMU",
        "iso2": "BM",
        "phone_code": "+1-441",
        "capital": "Hamilton",
        "currency": "BMD",
        "states": {
            "Hamilton": [
                "Hamilton"
            ],
            "Saint George": [
                "Hamilton"
            ]
        }
    },
    {
        "id": 26,
        "name": "Bhutan",
        "iso3": "BTN",
        "iso2": "BT",
        "phone_code": "975",
        "capital": "Thimphu",
        "currency": "BTN",
        "states": {
            "Bumthang": [
                "Jakar"
            ],
            "Chhukha": [
                "Chhukha",
                "Phuentsholing"
            ],
            "Chirang": [
                "Damphu"
            ],
            "Daga": [
                "Taga Dzong"
            ],
            "Geylegphug": [
                "Geylegphug"
            ],
            "Ha": [
                "Ha"
            ],
            "Lhuntshi": [
                "Lhuntshi"
            ],
            "Mongar": [
                "Mongar"
            ],
            "Pemagatsel": [
                "Pemagatsel"
            ],
            "Punakha": [
                "Gasa",
                "Punakha"
            ],
            "Rinpung": [
                "Paro"
            ],
            "Samchi": [
                "Phuntsholing",
                "Samchi"
            ],
            "Samdrup Jongkhar": [
                "Phuntsholing",
                "Samchi"
            ],
            "Shemgang": [
                "Shemgang"
            ],
            "Tashigang": [
                "Tashigang"
            ],
            "Timphu": [
                "Thimphu",
                "Timphu"
            ],
            "Tongsa": [
                "Tongsa"
            ],
            "Wangdiphodrang": [
                "Wangdiphodrang"
            ]
        }
    },
    {
        "id": 27,
        "name": "Bolivia",
        "iso3": "BOL",
        "iso2": "BO",
        "phone_code": "591",
        "capital": "Sucre",
        "currency": "BOB",
        "states": {
            "Beni": [
                "Guayaramerin",
                "Magdalena",
                "Reyes",
                "Riberalta",
                "Rurrenabaque",
                "San Borja",
                "San Ignacio",
                "San Ramon",
                "Santa Ana",
                "Santa Rosa",
                "Trinidad"
            ],
            "Chuquisaca": [
                "Camargo",
                "Monteagudo",
                "Muyupampa",
                "Padilla",
                "Sucre",
                "Tarabuco",
                "Villa Serano"
            ],
            "Cochabamba": [
                "Aiquile",
                "Arani",
                "Capinota",
                "Chimore",
                "Cliza",
                "Cochabamba",
                "Colomi",
                "Entre Rios",
                "Irpa Irpa",
                "Ivirgarzama",
                "Mizque",
                "Punata",
                "Shinahota",
                "Sipe Sipe",
                "Tarata",
                "Ucurena"
            ],
            "La Paz": [
                "Aiquile",
                "Arani",
                "Capinota",
                "Chimore",
                "Cliza",
                "Cochabamba",
                "Colomi",
                "Entre Rios",
                "Irpa Irpa",
                "Ivirgarzama",
                "Mizque",
                "Punata",
                "Shinahota",
                "Sipe Sipe",
                "Tarata",
                "Ucurena"
            ],
            "Oruro": [
                "Caracollo",
                "Challapata",
                "Eucaliptus",
                "Huanuni",
                "Machacamarca",
                "Oruro",
                "Poopo",
                "Santiago de Huari",
                "Totoral"
            ],
            "Pando": [
                "Cobija"
            ],
            "Potosi": [
                "Atocha",
                "Betanzos",
                "Colquechaca",
                "Llallagua",
                "Potosi",
                "Santa Barbara",
                "Tupiza",
                "Uncia",
                "Uyuni",
                "Villazon"
            ],
            "Santa Cruz": [
                "Atocha",
                "Betanzos",
                "Colquechaca",
                "Llallagua",
                "Potosi",
                "Santa Barbara",
                "Tupiza",
                "Uncia",
                "Uyuni",
                "Villazon"
            ],
            "Tarija": [
                "Bermejo",
                "Entre Rios",
                "San Lorenzo",
                "Tarija",
                "Villamontes",
                "Yacuiba"
            ]
        }
    },
    {
        "id": 28,
        "name": "Bosnia and Herzegovina",
        "iso3": "BIH",
        "iso2": "BA",
        "phone_code": "387",
        "capital": "Sarajevo",
        "currency": "BAM",
        "states": {
            "Federacija Bosna i Hercegovina": [
                "Bermejo",
                "Entre Rios",
                "San Lorenzo",
                "Tarija",
                "Villamontes",
                "Yacuiba"
            ],
            "Republika Srpska": [
                "Bermejo",
                "Entre Rios",
                "San Lorenzo",
                "Tarija",
                "Villamontes",
                "Yacuiba"
            ]
        }
    },
    {
        "id": 29,
        "name": "Botswana",
        "iso3": "BWA",
        "iso2": "BW",
        "phone_code": "267",
        "capital": "Gaborone",
        "currency": "BWP",
        "states": {
            "Central Bobonong": [
                "Bermejo",
                "Entre Rios",
                "San Lorenzo",
                "Tarija",
                "Villamontes",
                "Yacuiba"
            ],
            "Central Boteti": [
                "Bermejo",
                "Entre Rios",
                "San Lorenzo",
                "Tarija",
                "Villamontes",
                "Yacuiba"
            ],
            "Central Mahalapye": [
                "Bermejo",
                "Entre Rios",
                "San Lorenzo",
                "Tarija",
                "Villamontes",
                "Yacuiba"
            ],
            "Central Serowe-Palapye": [
                "Bermejo",
                "Entre Rios",
                "San Lorenzo",
                "Tarija",
                "Villamontes",
                "Yacuiba"
            ],
            "Central Tutume": [
                "Bermejo",
                "Entre Rios",
                "San Lorenzo",
                "Tarija",
                "Villamontes",
                "Yacuiba"
            ],
            "Chobe": [
                "Kachikau",
                "Kasane",
                "Kavimba",
                "Kazungula",
                "Lesoma",
                "Muchinje-Mabale",
                "Pandamatenga",
                "Pandamatenga Botswana Defence ",
                "Parakarungu",
                "Satau"
            ],
            "Francistown": [
                "Francistown"
            ],
            "Gaborone": [
                "Gaborone"
            ],
            "Ghanzi": [
                "Bere",
                "Charles Hill",
                "Chobokwane",
                "Dekar",
                "East Hanahai",
                "Ghanzi",
                "Groote Laagte",
                "Kacgae",
                "Karakobis",
                "Kuke Quarantine Camp",
                "Kule",
                "Makunda",
                "Ncojane",
                "New Xade",
                "New Xanagas",
                "Qabo",
                "Tsootsha",
                "West Hanahai"
            ],
            "Jwaneng": [
                "Jwaneng"
            ],
            "Kgalagadi North": [
                "Jwaneng"
            ],
            "Kgalagadi South": [
                "Jwaneng"
            ],
            "Kgatleng": [
                "Artisia",
                "Bokaa",
                "Dikgonye",
                "Dikwididi",
                "Kgomodiatshaba",
                "Khurutshe",
                "Leshibitse",
                "Mabalane",
                "Malolwane",
                "Malotwana Siding",
                "Matebeleng",
                "Mmamashia",
                "Mmathubudukwane",
                "Mochudi",
                "Modipane",
                "Morwa",
                "Oliphants Drift",
                "Oodi",
                "Pilane",
                "Ramonaka",
                "Ramotlabaki",
                "Rasesa",
                "Sikwane"
            ],
            "Kweneng": [
                "Boatlaname",
                "Botlhapatlou",
                "Diagane",
                "Diphudugodu",
                "Diremogolo Lands",
                "Ditshegwane",
                "Ditshukudu",
                "Dumadumane",
                "Dutlwe",
                "Gabane",
                "Gakgatla",
                "Gakuto",
                "Galekgatshwane",
                "Gamodubu",
                "Gaphatshwa",
                "Hatsalatladi",
                "Kamenakwe",
                "Kaudwane",
                "Kgaphamadi",
                "Kgope",
                "Khekhenya-Chepetese",
                "Khudumelapye",
                "Kopong",
                "Kotolaname",
                "Kubung",
                "Kumakwane",
                "Kweneng",
                "Lentsweletau",
                "Lephepe",
                "Lesirane",
                "Letlhakeng",
                "Losilakgokong",
                "Maboane",
                "Mahetlwe",
                "Makabanyane-Dikgokong",
                "Malwelwe",
                "Mamhiko",
                "Manaledi",
                "Mantshwabisi",
                "Marejwane",
                "Masebele",
                "Medie",
                "Metsibotlhoko",
                "Metsimotlhaba",
                "Mmakanke",
                "Mmankgodi",
                "Mmanoko",
                "Mmokolodi",
                "Mmopane",
                "Mmopane Lands",
                "Mogoditshane",
                "Mogoditshane Botswana Defence ",
                "Mogoditshane Lands",
                "Mogonono",
                "Molepolole",
                "Mononyane",
                "Monwane",
                "Morabane",
                "Morope",
                "Moshaweng",
                "Mosokotswe",
                "Motokwe",
                "Ngware",
                "Nkoyaphiri",
                "Ramaphatlhe",
                "Salajwe",
                "Serinane",
                "Sesung",
                "Shadishadi",
                "Sojwe",
                "Sorilatholo",
                "Suping",
                "Takatokwane",
                "Thamaga",
                "Thebephatshwa",
                "Tlowaneng",
                "Tsetseng",
                "Tswaane"
            ],
            "Lobatse": [
                "Lobatse"
            ],
            "Ngamiland": [
                "Bodibeng",
                "Boro",
                "Botlhatlogo",
                "Chanoga",
                "Chuchubega",
                "Daonara",
                "Ditshiping",
                "Habu",
                "Jao",
                "Kareng",
                "Katamaga",
                "Kgakge",
                "Khwai Camp",
                "Komana",
                "Legotlhwana",
                "Mababe",
                "Makalamabedi",
                "Matlapana",
                "Matsaudi",
                "Mawana",
                "Mokgalo-Haka",
                "Morutsha",
                "Nxharaga",
                "Phuduhudu",
                "Samodupi",
                "Sankuyo",
                "Sehithwa",
                "Semboyo",
                "Sexaxa",
                "Shakawe",
                "Shorobe",
                "Somela",
                "Toteng",
                "Tsanekona",
                "Tsao",
                "Xaxaba",
                "Xhobe"
            ],
            "Ngwaketse": [
                "Bethel",
                "Borobadilepe",
                "Diabo",
                "Digawana",
                "Dikhukhung",
                "Dinatshana",
                "Dipotsana",
                "Ditlharapa",
                "Gamajalela",
                "Gasita",
                "Gathwane",
                "Good Hope",
                "Goora-seno",
                "Gopong",
                "Hebron",
                "Itholoke",
                "Kanaku",
                "Kangwe",
                "Kanye",
                "Keng",
                "Kgomokasitwa",
                "Kgoro",
                "Khakhea",
                "Khonkhwa",
                "Kokong",
                "Lehoko",
                "Lejwana",
                "Lekgolobotlo",
                "Leporung",
                "Logagane",
                "Lorolwana",
                "Lorwana",
                "Lotlhakane",
                "Lotlhakane West",
                "Mabule",
                "Mabutsane",
                "Madingwana",
                "Magoriapitse",
                "Magotlhawane",
                "Mahotshwane",
                "Maisane",
                "Makokwe",
                "Malokaganyane",
                "Manyana",
                "Maokane",
                "Marojane",
                "Maruswa",
                "Metlobo",
                "Metlojane",
                "Mmakgori",
                "Mmathethe",
                "Mogojogojo",
                "Mogonye",
                "Mogwalale",
                "Mokatako",
                "Mokgomane",
                "Mokhomba",
                "Molapowabojang",
                "Molete",
                "Morwamosu",
                "Moshaneng",
                "Moshupa",
                "Motlhwatse",
                "Motsentshe",
                "Musi",
                "Ngwatsau",
                "Ntlhantlhe",
                "Papatlo",
                "Phihitshwane",
                "Pitsana-Potokwe",
                "Pitsane",
                "Pitseng-Ralekgetho",
                "Pitshane Molopo",
                "Rakhuna",
                "Ralekgetho",
                "Ramatlabama",
                "Ranaka",
                "Sedibeng",
                "Segakwana",
                "Segwagwa",
                "Seherelela",
                "Sekhutlane",
                "Sekoma",
                "Selokolela",
                "Semane",
                "Sese",
                "Sesung",
                "Sheep Farm",
                "Tlhankane",
                "Tlhareseleele",
                "Tshidilamolomo",
                "Tshwaane",
                "Tsonyane",
                "Tswaaneng",
                "Tswagare-Lothoje-Lokalana",
                "Tswanyaneng"
            ],
            "North East": [
                "Bethel",
                "Borobadilepe",
                "Diabo",
                "Digawana",
                "Dikhukhung",
                "Dinatshana",
                "Dipotsana",
                "Ditlharapa",
                "Gamajalela",
                "Gasita",
                "Gathwane",
                "Good Hope",
                "Goora-seno",
                "Gopong",
                "Hebron",
                "Itholoke",
                "Kanaku",
                "Kangwe",
                "Kanye",
                "Keng",
                "Kgomokasitwa",
                "Kgoro",
                "Khakhea",
                "Khonkhwa",
                "Kokong",
                "Lehoko",
                "Lejwana",
                "Lekgolobotlo",
                "Leporung",
                "Logagane",
                "Lorolwana",
                "Lorwana",
                "Lotlhakane",
                "Lotlhakane West",
                "Mabule",
                "Mabutsane",
                "Madingwana",
                "Magoriapitse",
                "Magotlhawane",
                "Mahotshwane",
                "Maisane",
                "Makokwe",
                "Malokaganyane",
                "Manyana",
                "Maokane",
                "Marojane",
                "Maruswa",
                "Metlobo",
                "Metlojane",
                "Mmakgori",
                "Mmathethe",
                "Mogojogojo",
                "Mogonye",
                "Mogwalale",
                "Mokatako",
                "Mokgomane",
                "Mokhomba",
                "Molapowabojang",
                "Molete",
                "Morwamosu",
                "Moshaneng",
                "Moshupa",
                "Motlhwatse",
                "Motsentshe",
                "Musi",
                "Ngwatsau",
                "Ntlhantlhe",
                "Papatlo",
                "Phihitshwane",
                "Pitsana-Potokwe",
                "Pitsane",
                "Pitseng-Ralekgetho",
                "Pitshane Molopo",
                "Rakhuna",
                "Ralekgetho",
                "Ramatlabama",
                "Ranaka",
                "Sedibeng",
                "Segakwana",
                "Segwagwa",
                "Seherelela",
                "Sekhutlane",
                "Sekoma",
                "Selokolela",
                "Semane",
                "Sese",
                "Sesung",
                "Sheep Farm",
                "Tlhankane",
                "Tlhareseleele",
                "Tshidilamolomo",
                "Tshwaane",
                "Tsonyane",
                "Tswaaneng",
                "Tswagare-Lothoje-Lokalana",
                "Tswanyaneng"
            ],
            "Okavango": [
                "Beetsha",
                "Eretsha",
                "Etsha 1",
                "Etsha 13",
                "Etsha 6",
                "Etsha 8",
                "Etsha 9",
                "Gane",
                "Gonutsuga",
                "Gowe",
                "Gudingwa",
                "Gumare",
                "Ikoga",
                "Kajaja",
                "Kapotora Lands",
                "Kauxwhi",
                "Matswee",
                "Maun",
                "Moaha",
                "Mohembo East",
                "Mohembo West",
                "Mokgacha",
                "Ngarange",
                "Nokaneng",
                "Nxamasere",
                "Nxaunxau",
                "Nxwee",
                "Qangwa",
                "Roye",
                "Samochema",
                "Sekondomboro",
                "Sepopa",
                "Seronga",
                "Shaowe",
                "Tobere Lands",
                "Tubu",
                "Tubu Lands",
                "Xadau",
                "Xakao",
                "Xaxa",
                "Xhauga",
                "Xurube"
            ],
            "Orapa": [
                "Orapa"
            ],
            "Selibe Phikwe": [
                "Orapa"
            ],
            "South East": [
                "Orapa"
            ],
            "Sowa": [
                "Sowa"
            ]
        }
    },
    {
        "id": 30,
        "name": "Bouvet Island",
        "iso3": "BVT",
        "iso2": "BV",
        "phone_code": "",
        "capital": "",
        "currency": "NOK",
        "states": {
            "Bouvet Island": [
                "Sowa"
            ]
        }
    },
    {
        "id": 31,
        "name": "Brazil",
        "iso3": "BRA",
        "iso2": "BR",
        "phone_code": "55",
        "capital": "Brasilia",
        "currency": "BRL",
        "states": {
            "Acre": [
                "Acrelandia",
                "Brasileia",
                "Cruzeiro do Sul",
                "Epitaciolandia",
                "Feijo",
                "Mancio Lima",
                "Manoel Urbano",
                "Marechal Thaumaturgo",
                "Placido de Castro",
                "Porto Walter",
                "Rio Branco",
                "Rodrigues Alves",
                "Sena Madureira",
                "Senador Guiomard",
                "Tarauaca",
                "Xapuri"
            ],
            "Alagoas": [
                "Agua Branca",
                "Anadia",
                "Arapiraca",
                "Atalaia",
                "Barra de Santo Antonio",
                "Batalha",
                "Boca da Mata",
                "Cacimbinhas",
                "Cajueiro",
                "Campo Alegre",
                "Campo Grande",
                "Canapi",
                "Capela",
                "Coite do Noia",
                "Colonia Leopoldina",
                "Coruripe",
                "Craibas",
                "Delmiro Gouveia",
                "Dois Riachos",
                "Estrela de Alagoas",
                "Feira Grande",
                "Flexeiras",
                "Girau do Ponciano",
                "Ibateguara",
                "Igaci",
                "Igreja Nova",
                "Inhapi",
                "Joaquim Gomes",
                "Jundia",
                "Junqueiro",
                "Lagoa da Canoa",
                "Limoeiro de Anadia",
                "Maceio",
                "Major Isidoro",
                "Maragogi",
                "Maravilha",
                "Marechal Deodoro",
                "Maribondo",
                "Mata Grande",
                "Matriz de Camaragibe",
                "Messias",
                "Minador do Negrao",
                "Murici",
                "Novo Lino",
                "Olho d\\'Agua das Flores",
                "Olivenca",
                "Palmeira dos Indios",
                "Pao de Acucar",
                "Passo de Camaragibe",
                "Penedo",
                "Piacabucu",
                "Pilar",
                "Piranhas",
                "Poco das Trincheiras",
                "Porto Calvo",
                "Porto Real do Colegio",
                "Quebrangulo",
                "Rio Largo",
                "Santana do Ipanema",
                "Santana do Mundau",
                "Sao Jose da Laje",
                "Sao Jose da Tapera",
                "Sao Luis do Quitunde",
                "Sao Miguel dos Campos",
                "Sao Sebastiao",
                "Satuba",
                "Senador Rui Palmeira",
                "Taquarana",
                "Teotonio Vilela",
                "Traipu",
                "Uniao dos Palmares",
                "Vicosa"
            ],
            "Amapa": [
                "Amapa",
                "Laranjal do Jari",
                "Macapa",
                "Mazagao",
                "Oiapoque",
                "Santana"
            ],
            "Amazonas": [
                "Alvaraes",
                "Anori",
                "Apui",
                "Autazes",
                "Barcelos",
                "Barreirinha",
                "Benjamin Constant",
                "Boca do Acre",
                "Borba",
                "Canutama",
                "Carauari",
                "Careiro",
                "Careiro da Varzea",
                "Coari",
                "Codajas",
                "Eirunepe",
                "Envira",
                "Fonte Boa",
                "Guajara",
                "Humaita",
                "Ipixuna",
                "Iranduba",
                "Itacoatiara",
                "Japura",
                "Jutai",
                "Labrea",
                "Manacapuru",
                "Manaquiri",
                "Manaus",
                "Manicore",
                "Maraa",
                "Maues",
                "Nhamunda",
                "Nova Olinda do Norte",
                "Novo Airao",
                "Novo Aripuana",
                "Parintins",
                "Pauini",
                "Rio Preto da Eva",
                "Santa Isabel do Rio Negro",
                "Santo Antonio do Ica",
                "Sao Gabriel da Cachoeira",
                "Sao Paulo de Olivenca",
                "Tabatinga",
                "Tapaua",
                "Tefe",
                "Tonantins",
                "Uarini",
                "Urucara",
                "Urucurituba"
            ],
            "Bahia": [
                "Acajutiba",
                "Alagoinhas",
                "Amargosa",
                "Amelia Rodrigues",
                "America Dourada",
                "Anage",
                "Araci",
                "Aurelino Leal",
                "Baixa Grande",
                "Barra",
                "Barra da Estiva",
                "Barra do Choca",
                "Barreiras",
                "Belmonte",
                "Boa Vista do Tupim",
                "Bom Jesus da Lapa",
                "Boquira",
                "Brumado",
                "Buerarema",
                "Cachoeira",
                "Cacule",
                "Caetite",
                "Cafarnaum",
                "Camacan",
                "Camacari",
                "Camamu",
                "Campo Alegre de Lourdes",
                "Campo Formoso",
                "Canarana",
                "Canavieiras",
                "Candeias",
                "Candido Sales",
                "Cansancao",
                "Capim Grosso",
                "Caravelas",
                "Carinhanha",
                "Casa Nova",
                "Castro Alves",
                "Catu",
                "Cicero Dantas",
                "Cipo",
                "Coaraci",
                "Conceicao da Feira",
                "Conceicao do Almeida",
                "Conceicao do Coite",
                "Conceicao do Jacuipe",
                "Conde",
                "Coracao de Maria",
                "Coronel Joao Sa",
                "Correntina",
                "Cruz das Almas",
                "Curaca",
                "Dias d\\'Avila",
                "Encruzilhada",
                "Entre Rios",
                "Esplanada",
                "Euclides da Cunha",
                "Eunapolis",
                "Feira de Santana",
                "Filadelfia",
                "Formosa do Rio Preto",
                "Gandu",
                "Guanambi",
                "Guaratinga",
                "Iacu",
                "Ibicarai",
                "Ibicui",
                "Ibipeba",
                "Ibirapitanga",
                "Ibirataia",
                "Ibotirama",
                "Iguai",
                "Ilheus",
                "Inhambupe",
                "Ipiau",
                "Ipira",
                "Iraquara",
                "Irara",
                "Irece",
                "Itabela",
                "Itaberaba",
                "Itabuna",
                "Itacare",
                "Itagi",
                "Itagiba",
                "Itajuipe",
                "Itamaraju",
                "Itambe",
                "Itanhem",
                "Itaparica",
                "Itapetinga",
                "Itapicuru",
                "Itarantim",
                "Itirucu",
                "Itiuba",
                "Itororo",
                "Ituacu",
                "Itubera",
                "Jacobina",
                "Jaguaquara",
                "Jaguarari",
                "Jequie",
                "Jeremoabo",
                "Jitauna",
                "Joao Dourado",
                "Juazeiro",
                "Jussara",
                "Laje",
                "Lapao",
                "Lauro de Freitas",
                "Livramento",
                "Macarani",
                "Macaubas",
                "Madre de Deus",
                "Mairi",
                "Maracas",
                "Maragogipe",
                "Marau",
                "Mascote",
                "Mata de Sao Joao",
                "Medeiros Neto",
                "Miguel Calmon",
                "Milagres",
                "Monte Santo",
                "Morro de Chapeu",
                "Mucuri",
                "Mundo Novo",
                "Muritiba",
                "Mutuipe",
                "Nazare",
                "Nova Soure",
                "Nova Vicosa",
                "Olindina",
                "Oliveira dos Brejinhos",
                "Palmas de Monte Alto",
                "Paramirim",
                "Paratinga",
                "Paripiranga",
                "Pau Brasil",
                "Paulo Afonso",
                "Pilao Arcado",
                "Pindobacu",
                "Piritiba",
                "Planalto",
                "Pocoes",
                "Pojuca",
                "Ponto Novo",
                "Porto Seguro",
                "Prado",
                "Presidente Tancredo Neves",
                "Queimadas",
                "Quijingue",
                "Rafael Jambeiro",
                "Remanso",
                "Riachao das Neves",
                "Riachao do Jacuipe",
                "Riacho de Santana",
                "Ribeira do Pombal",
                "Rio Real",
                "Ruy Barbosa",
                "Salvador",
                "Santa Cruz Cabralia",
                "Santa Ines",
                "Santa Maria da Vitoria",
                "Santa Rita de Cassia",
                "Santaluz",
                "Santana",
                "Santo Amaro",
                "Santo Antonio de Jesus",
                "Santo Estevao",
                "Sao Desiderio",
                "Sao Felipe",
                "Sao Francisco do Conde",
                "Sao Gabriel",
                "Sao Goncalo dos Campos",
                "Sao Sebastiao do Passe",
                "Saubara",
                "Seabra",
                "Senhor do Bonfim",
                "Sento Se",
                "Serra do Ramalho",
                "Serra Dourada",
                "Serrinha",
                "Simoes Filho",
                "Sobradinho",
                "Souto Soares",
                "Tanhacu",
                "Taperoa",
                "Tapiramuta",
                "Teixeira de Freitas",
                "Teofilandia",
                "Terra Nova",
                "Tremedal",
                "Tucano",
                "Uaua",
                "Ubaira",
                "Ubaitaba",
                "Ubata",
                "Una",
                "Urucuca",
                "Utinga",
                "Valenca",
                "Valente",
                "Vera Cruz",
                "Vitoria da Conquista",
                "Wenceslau Guimaraes",
                "Xique-Xique"
            ],
            "Ceara": [
                "Acarau",
                "Acopiara",
                "Amontada",
                "Aquiraz",
                "Aracati",
                "Aracoiaba",
                "Araripe",
                "Assare",
                "Aurora",
                "Barbalha",
                "Barro",
                "Barroquinha",
                "Baturite",
                "Beberibe",
                "Bela Cruz",
                "Boa Viagem",
                "Brejo Santo",
                "Camocim",
                "Campos Sales",
                "Caninde",
                "Carire",
                "Caririacu",
                "Cascavel",
                "Caucaia",
                "Cedro",
                "Chorozinho",
                "Coreau",
                "Crateus",
                "Crato",
                "Cruz",
                "Eusebio",
                "Farias Brito",
                "Forquilha",
                "Fortaleza",
                "Granja",
                "Guaiuba",
                "Guaraciaba do Norte",
                "Hidrolandia",
                "Horizonte",
                "Ibiapina",
                "Ico",
                "Iguatu",
                "Independencia",
                "Ipu",
                "Ipueiras",
                "Iraucuba",
                "Itaitinga",
                "Itapage",
                "Itapipoca",
                "Itarema",
                "Jaguaribe",
                "Jaguaruana",
                "Jardim",
                "Juazeiro do Norte",
                "Jucas",
                "Lavras da Mangabeira",
                "Limoeiro do Norte",
                "Maracanau",
                "Maranguape",
                "Marco",
                "Massape",
                "Mauriti",
                "Milagres",
                "Missao Velha",
                "Mombaca",
                "Morada Nova",
                "Nova Russas",
                "Novo Oriente",
                "Ocara",
                "Oros",
                "Pacajus",
                "Pacatuba",
                "Paracuru",
                "Paraipaba",
                "Parambu",
                "Pedra Branca",
                "Pentecoste",
                "Quixada",
                "Quixeramobim",
                "Quixere",
                "Redencao",
                "Reriutaba",
                "Russas",
                "Santa Quiteria",
                "Santana do Acarau",
                "Sao Benedito",
                "Sao Goncalo do Amarante",
                "Senador Pompeu",
                "Sobral",
                "Tabuleiro do Norte",
                "Tamboril",
                "Taua",
                "Tiangua",
                "Trairi",
                "Ubajara",
                "Umirim",
                "Uruburetama",
                "Varjota",
                "Varzea Alegre",
                "Vicosa do Ceara"
            ],
            "Distrito Federal": [
                "Acarau",
                "Acopiara",
                "Amontada",
                "Aquiraz",
                "Aracati",
                "Aracoiaba",
                "Araripe",
                "Assare",
                "Aurora",
                "Barbalha",
                "Barro",
                "Barroquinha",
                "Baturite",
                "Beberibe",
                "Bela Cruz",
                "Boa Viagem",
                "Brejo Santo",
                "Camocim",
                "Campos Sales",
                "Caninde",
                "Carire",
                "Caririacu",
                "Cascavel",
                "Caucaia",
                "Cedro",
                "Chorozinho",
                "Coreau",
                "Crateus",
                "Crato",
                "Cruz",
                "Eusebio",
                "Farias Brito",
                "Forquilha",
                "Fortaleza",
                "Granja",
                "Guaiuba",
                "Guaraciaba do Norte",
                "Hidrolandia",
                "Horizonte",
                "Ibiapina",
                "Ico",
                "Iguatu",
                "Independencia",
                "Ipu",
                "Ipueiras",
                "Iraucuba",
                "Itaitinga",
                "Itapage",
                "Itapipoca",
                "Itarema",
                "Jaguaribe",
                "Jaguaruana",
                "Jardim",
                "Juazeiro do Norte",
                "Jucas",
                "Lavras da Mangabeira",
                "Limoeiro do Norte",
                "Maracanau",
                "Maranguape",
                "Marco",
                "Massape",
                "Mauriti",
                "Milagres",
                "Missao Velha",
                "Mombaca",
                "Morada Nova",
                "Nova Russas",
                "Novo Oriente",
                "Ocara",
                "Oros",
                "Pacajus",
                "Pacatuba",
                "Paracuru",
                "Paraipaba",
                "Parambu",
                "Pedra Branca",
                "Pentecoste",
                "Quixada",
                "Quixeramobim",
                "Quixere",
                "Redencao",
                "Reriutaba",
                "Russas",
                "Santa Quiteria",
                "Santana do Acarau",
                "Sao Benedito",
                "Sao Goncalo do Amarante",
                "Senador Pompeu",
                "Sobral",
                "Tabuleiro do Norte",
                "Tamboril",
                "Taua",
                "Tiangua",
                "Trairi",
                "Ubajara",
                "Umirim",
                "Uruburetama",
                "Varjota",
                "Varzea Alegre",
                "Vicosa do Ceara"
            ],
            "Espirito Santo": [
                "Acarau",
                "Acopiara",
                "Amontada",
                "Aquiraz",
                "Aracati",
                "Aracoiaba",
                "Araripe",
                "Assare",
                "Aurora",
                "Barbalha",
                "Barro",
                "Barroquinha",
                "Baturite",
                "Beberibe",
                "Bela Cruz",
                "Boa Viagem",
                "Brejo Santo",
                "Camocim",
                "Campos Sales",
                "Caninde",
                "Carire",
                "Caririacu",
                "Cascavel",
                "Caucaia",
                "Cedro",
                "Chorozinho",
                "Coreau",
                "Crateus",
                "Crato",
                "Cruz",
                "Eusebio",
                "Farias Brito",
                "Forquilha",
                "Fortaleza",
                "Granja",
                "Guaiuba",
                "Guaraciaba do Norte",
                "Hidrolandia",
                "Horizonte",
                "Ibiapina",
                "Ico",
                "Iguatu",
                "Independencia",
                "Ipu",
                "Ipueiras",
                "Iraucuba",
                "Itaitinga",
                "Itapage",
                "Itapipoca",
                "Itarema",
                "Jaguaribe",
                "Jaguaruana",
                "Jardim",
                "Juazeiro do Norte",
                "Jucas",
                "Lavras da Mangabeira",
                "Limoeiro do Norte",
                "Maracanau",
                "Maranguape",
                "Marco",
                "Massape",
                "Mauriti",
                "Milagres",
                "Missao Velha",
                "Mombaca",
                "Morada Nova",
                "Nova Russas",
                "Novo Oriente",
                "Ocara",
                "Oros",
                "Pacajus",
                "Pacatuba",
                "Paracuru",
                "Paraipaba",
                "Parambu",
                "Pedra Branca",
                "Pentecoste",
                "Quixada",
                "Quixeramobim",
                "Quixere",
                "Redencao",
                "Reriutaba",
                "Russas",
                "Santa Quiteria",
                "Santana do Acarau",
                "Sao Benedito",
                "Sao Goncalo do Amarante",
                "Senador Pompeu",
                "Sobral",
                "Tabuleiro do Norte",
                "Tamboril",
                "Taua",
                "Tiangua",
                "Trairi",
                "Ubajara",
                "Umirim",
                "Uruburetama",
                "Varjota",
                "Varzea Alegre",
                "Vicosa do Ceara"
            ],
            "Estado de Sao Paulo": [
                "Acarau",
                "Acopiara",
                "Amontada",
                "Aquiraz",
                "Aracati",
                "Aracoiaba",
                "Araripe",
                "Assare",
                "Aurora",
                "Barbalha",
                "Barro",
                "Barroquinha",
                "Baturite",
                "Beberibe",
                "Bela Cruz",
                "Boa Viagem",
                "Brejo Santo",
                "Camocim",
                "Campos Sales",
                "Caninde",
                "Carire",
                "Caririacu",
                "Cascavel",
                "Caucaia",
                "Cedro",
                "Chorozinho",
                "Coreau",
                "Crateus",
                "Crato",
                "Cruz",
                "Eusebio",
                "Farias Brito",
                "Forquilha",
                "Fortaleza",
                "Granja",
                "Guaiuba",
                "Guaraciaba do Norte",
                "Hidrolandia",
                "Horizonte",
                "Ibiapina",
                "Ico",
                "Iguatu",
                "Independencia",
                "Ipu",
                "Ipueiras",
                "Iraucuba",
                "Itaitinga",
                "Itapage",
                "Itapipoca",
                "Itarema",
                "Jaguaribe",
                "Jaguaruana",
                "Jardim",
                "Juazeiro do Norte",
                "Jucas",
                "Lavras da Mangabeira",
                "Limoeiro do Norte",
                "Maracanau",
                "Maranguape",
                "Marco",
                "Massape",
                "Mauriti",
                "Milagres",
                "Missao Velha",
                "Mombaca",
                "Morada Nova",
                "Nova Russas",
                "Novo Oriente",
                "Ocara",
                "Oros",
                "Pacajus",
                "Pacatuba",
                "Paracuru",
                "Paraipaba",
                "Parambu",
                "Pedra Branca",
                "Pentecoste",
                "Quixada",
                "Quixeramobim",
                "Quixere",
                "Redencao",
                "Reriutaba",
                "Russas",
                "Santa Quiteria",
                "Santana do Acarau",
                "Sao Benedito",
                "Sao Goncalo do Amarante",
                "Senador Pompeu",
                "Sobral",
                "Tabuleiro do Norte",
                "Tamboril",
                "Taua",
                "Tiangua",
                "Trairi",
                "Ubajara",
                "Umirim",
                "Uruburetama",
                "Varjota",
                "Varzea Alegre",
                "Vicosa do Ceara"
            ],
            "Goias": [
                "Abadiania",
                "Acreuna",
                "Aguas Lindas de Goias",
                "Alexania",
                "Anapolis",
                "Anicuns",
                "Aparecida de Goiania",
                "Aragarcas",
                "Bela Vista de Goias",
                "Bom Jesus de Goias",
                "Buriti Alegre",
                "Cacu",
                "Caiaponia",
                "Caldas Novas",
                "Campos Belos",
                "Campos Verdes",
                "Carmo do Rio Verde",
                "Catalao",
                "Cavalcante",
                "Ceres",
                "Cidade Ocidental",
                "Cocalzinho de Coias",
                "Cristalina",
                "Crixas",
                "Doverlandia",
                "Edeia",
                "Firminopolis",
                "Formosa",
                "Goianapolis",
                "Goianesia",
                "Goiania",
                "Goianira",
                "Goias",
                "Goiatuba",
                "Guapo",
                "Hidrolandia",
                "Iaciara",
                "Indiara",
                "Inhumas",
                "Ipameri",
                "Ipora",
                "Itaberai",
                "Itapaci",
                "Itapirapua",
                "Itapuranga",
                "Itumbiara",
                "Jaragua",
                "Jatai",
                "Jussara",
                "Luziania",
                "Mara Rosa",
                "Minacu",
                "Mineiros",
                "Morrinhos",
                "Mozarlandia",
                "Neropolis",
                "Niquelandia",
                "Nova Crixas",
                "Novo Gama",
                "Orizona",
                "Padre Bernardo",
                "Palmeiras de Goias",
                "Parauna",
                "Petrolina de Goias",
                "Piracanjuba",
                "Piranhas",
                "Pirenopolis",
                "Pires do Rio",
                "Planaltina",
                "Pontalina",
                "Porangatu",
                "Posse",
                "Quirinopolis",
                "Rialma",
                "Rio Verde",
                "Rubiataba",
                "Santa Helena de Goias",
                "Santa Terezinha de Goias",
                "Santo Antonio do Descoberto",
                "Sao Domingos",
                "Sao Luis de Montes Belos",
                "Sao Miguel do Araguaia",
                "Sao Simao",
                "Senador Canedo",
                "Silvania",
                "Trindade",
                "Uruacu",
                "Uruana",
                "Valparaiso de Goias",
                "Vianopolis"
            ],
            "Maranhao": [
                "Acailandia",
                "Alcantara",
                "Aldeias Altas",
                "Alto Alegre do Pindare",
                "Amarante do Maranhao",
                "Anajatuba",
                "Araioses",
                "Arame",
                "Arari",
                "Bacabal",
                "Balsas",
                "Barra do Corda",
                "Barreirinhas",
                "Bequimao",
                "Bom Jardim",
                "Brejo",
                "Buriti",
                "Buriti Bravo",
                "Buriticupu",
                "Candido Mendes",
                "Cantanhede",
                "Carolina",
                "Carutapera",
                "Caxias",
                "Chapadinha",
                "Codo",
                "Coelho Neto",
                "Colinas",
                "Coroata",
                "Cururupu",
                "Davinopolis",
                "Dom Pedro",
                "Esperantinopolis",
                "Estreito",
                "Fortuna",
                "Godofredo Viana",
                "Governador Eugenio Barros",
                "Governador Nunes Freire",
                "Grajau",
                "Humberto de Campos",
                "Icatu",
                "Imperatriz",
                "Itapecuru Mirim",
                "Itinga do Maranhao",
                "Joao Lisboa",
                "Lago da Pedra",
                "Lago do Junco",
                "Maracacume",
                "Matinha",
                "Matoes",
                "Mirador",
                "Miranda do Norte",
                "Moncao",
                "Montes Altos",
                "Morros",
                "Nova Olinda do Maranhao",
                "Olho d\\'Agua das Cunhas",
                "Paco do Lumiar",
                "Paraibano",
                "Parnarama",
                "Passagem Franca",
                "Pastos Bons",
                "Paulo Ramos",
                "Pedreiras",
                "Penalva",
                "Pindare Mirim",
                "Pinheiro",
                "Pio XII",
                "Pirapemas",
                "Pocao de Pedras",
                "Porto Franco",
                "Presidente Dutra",
                "Raposa",
                "Riachao",
                "Rosario",
                "Santa Helena",
                "Santa Ines",
                "Santa Luzia",
                "Santa Luzia do Parua",
                "Santa Quiteria do Maranhao",
                "Santa Rita",
                "Sao Benedito do Rio Preto",
                "Sao Bento",
                "Sao Bernardo",
                "Sao Domingos do Maranhao",
                "Sao Joao Batista",
                "Sao Joao dos Patos",
                "Sao Jose de Ribamar",
                "Sao Luis",
                "Sao Luis Gonzaga do Maranhao",
                "Sao Mateus do Maranhao",
                "Sao Pedro da Agua Branca",
                "Sao Raimundo das Mangabeiras",
                "Timbiras",
                "Timon",
                "Trizidela do Vale",
                "Tuntum",
                "Turiacu",
                "Tutoia",
                "Urbano Santos",
                "Vargem Grande",
                "Viana",
                "Vitoria do Mearim",
                "Vitorino Freire",
                "Ze Doca"
            ],
            "Mato Grosso": [
                "Acailandia",
                "Alcantara",
                "Aldeias Altas",
                "Alto Alegre do Pindare",
                "Amarante do Maranhao",
                "Anajatuba",
                "Araioses",
                "Arame",
                "Arari",
                "Bacabal",
                "Balsas",
                "Barra do Corda",
                "Barreirinhas",
                "Bequimao",
                "Bom Jardim",
                "Brejo",
                "Buriti",
                "Buriti Bravo",
                "Buriticupu",
                "Candido Mendes",
                "Cantanhede",
                "Carolina",
                "Carutapera",
                "Caxias",
                "Chapadinha",
                "Codo",
                "Coelho Neto",
                "Colinas",
                "Coroata",
                "Cururupu",
                "Davinopolis",
                "Dom Pedro",
                "Esperantinopolis",
                "Estreito",
                "Fortuna",
                "Godofredo Viana",
                "Governador Eugenio Barros",
                "Governador Nunes Freire",
                "Grajau",
                "Humberto de Campos",
                "Icatu",
                "Imperatriz",
                "Itapecuru Mirim",
                "Itinga do Maranhao",
                "Joao Lisboa",
                "Lago da Pedra",
                "Lago do Junco",
                "Maracacume",
                "Matinha",
                "Matoes",
                "Mirador",
                "Miranda do Norte",
                "Moncao",
                "Montes Altos",
                "Morros",
                "Nova Olinda do Maranhao",
                "Olho d\\'Agua das Cunhas",
                "Paco do Lumiar",
                "Paraibano",
                "Parnarama",
                "Passagem Franca",
                "Pastos Bons",
                "Paulo Ramos",
                "Pedreiras",
                "Penalva",
                "Pindare Mirim",
                "Pinheiro",
                "Pio XII",
                "Pirapemas",
                "Pocao de Pedras",
                "Porto Franco",
                "Presidente Dutra",
                "Raposa",
                "Riachao",
                "Rosario",
                "Santa Helena",
                "Santa Ines",
                "Santa Luzia",
                "Santa Luzia do Parua",
                "Santa Quiteria do Maranhao",
                "Santa Rita",
                "Sao Benedito do Rio Preto",
                "Sao Bento",
                "Sao Bernardo",
                "Sao Domingos do Maranhao",
                "Sao Joao Batista",
                "Sao Joao dos Patos",
                "Sao Jose de Ribamar",
                "Sao Luis",
                "Sao Luis Gonzaga do Maranhao",
                "Sao Mateus do Maranhao",
                "Sao Pedro da Agua Branca",
                "Sao Raimundo das Mangabeiras",
                "Timbiras",
                "Timon",
                "Trizidela do Vale",
                "Tuntum",
                "Turiacu",
                "Tutoia",
                "Urbano Santos",
                "Vargem Grande",
                "Viana",
                "Vitoria do Mearim",
                "Vitorino Freire",
                "Ze Doca"
            ],
            "Mato Grosso do Sul": [
                "Acailandia",
                "Alcantara",
                "Aldeias Altas",
                "Alto Alegre do Pindare",
                "Amarante do Maranhao",
                "Anajatuba",
                "Araioses",
                "Arame",
                "Arari",
                "Bacabal",
                "Balsas",
                "Barra do Corda",
                "Barreirinhas",
                "Bequimao",
                "Bom Jardim",
                "Brejo",
                "Buriti",
                "Buriti Bravo",
                "Buriticupu",
                "Candido Mendes",
                "Cantanhede",
                "Carolina",
                "Carutapera",
                "Caxias",
                "Chapadinha",
                "Codo",
                "Coelho Neto",
                "Colinas",
                "Coroata",
                "Cururupu",
                "Davinopolis",
                "Dom Pedro",
                "Esperantinopolis",
                "Estreito",
                "Fortuna",
                "Godofredo Viana",
                "Governador Eugenio Barros",
                "Governador Nunes Freire",
                "Grajau",
                "Humberto de Campos",
                "Icatu",
                "Imperatriz",
                "Itapecuru Mirim",
                "Itinga do Maranhao",
                "Joao Lisboa",
                "Lago da Pedra",
                "Lago do Junco",
                "Maracacume",
                "Matinha",
                "Matoes",
                "Mirador",
                "Miranda do Norte",
                "Moncao",
                "Montes Altos",
                "Morros",
                "Nova Olinda do Maranhao",
                "Olho d\\'Agua das Cunhas",
                "Paco do Lumiar",
                "Paraibano",
                "Parnarama",
                "Passagem Franca",
                "Pastos Bons",
                "Paulo Ramos",
                "Pedreiras",
                "Penalva",
                "Pindare Mirim",
                "Pinheiro",
                "Pio XII",
                "Pirapemas",
                "Pocao de Pedras",
                "Porto Franco",
                "Presidente Dutra",
                "Raposa",
                "Riachao",
                "Rosario",
                "Santa Helena",
                "Santa Ines",
                "Santa Luzia",
                "Santa Luzia do Parua",
                "Santa Quiteria do Maranhao",
                "Santa Rita",
                "Sao Benedito do Rio Preto",
                "Sao Bento",
                "Sao Bernardo",
                "Sao Domingos do Maranhao",
                "Sao Joao Batista",
                "Sao Joao dos Patos",
                "Sao Jose de Ribamar",
                "Sao Luis",
                "Sao Luis Gonzaga do Maranhao",
                "Sao Mateus do Maranhao",
                "Sao Pedro da Agua Branca",
                "Sao Raimundo das Mangabeiras",
                "Timbiras",
                "Timon",
                "Trizidela do Vale",
                "Tuntum",
                "Turiacu",
                "Tutoia",
                "Urbano Santos",
                "Vargem Grande",
                "Viana",
                "Vitoria do Mearim",
                "Vitorino Freire",
                "Ze Doca"
            ],
            "Minas Gerais": [
                "Acailandia",
                "Alcantara",
                "Aldeias Altas",
                "Alto Alegre do Pindare",
                "Amarante do Maranhao",
                "Anajatuba",
                "Araioses",
                "Arame",
                "Arari",
                "Bacabal",
                "Balsas",
                "Barra do Corda",
                "Barreirinhas",
                "Bequimao",
                "Bom Jardim",
                "Brejo",
                "Buriti",
                "Buriti Bravo",
                "Buriticupu",
                "Candido Mendes",
                "Cantanhede",
                "Carolina",
                "Carutapera",
                "Caxias",
                "Chapadinha",
                "Codo",
                "Coelho Neto",
                "Colinas",
                "Coroata",
                "Cururupu",
                "Davinopolis",
                "Dom Pedro",
                "Esperantinopolis",
                "Estreito",
                "Fortuna",
                "Godofredo Viana",
                "Governador Eugenio Barros",
                "Governador Nunes Freire",
                "Grajau",
                "Humberto de Campos",
                "Icatu",
                "Imperatriz",
                "Itapecuru Mirim",
                "Itinga do Maranhao",
                "Joao Lisboa",
                "Lago da Pedra",
                "Lago do Junco",
                "Maracacume",
                "Matinha",
                "Matoes",
                "Mirador",
                "Miranda do Norte",
                "Moncao",
                "Montes Altos",
                "Morros",
                "Nova Olinda do Maranhao",
                "Olho d\\'Agua das Cunhas",
                "Paco do Lumiar",
                "Paraibano",
                "Parnarama",
                "Passagem Franca",
                "Pastos Bons",
                "Paulo Ramos",
                "Pedreiras",
                "Penalva",
                "Pindare Mirim",
                "Pinheiro",
                "Pio XII",
                "Pirapemas",
                "Pocao de Pedras",
                "Porto Franco",
                "Presidente Dutra",
                "Raposa",
                "Riachao",
                "Rosario",
                "Santa Helena",
                "Santa Ines",
                "Santa Luzia",
                "Santa Luzia do Parua",
                "Santa Quiteria do Maranhao",
                "Santa Rita",
                "Sao Benedito do Rio Preto",
                "Sao Bento",
                "Sao Bernardo",
                "Sao Domingos do Maranhao",
                "Sao Joao Batista",
                "Sao Joao dos Patos",
                "Sao Jose de Ribamar",
                "Sao Luis",
                "Sao Luis Gonzaga do Maranhao",
                "Sao Mateus do Maranhao",
                "Sao Pedro da Agua Branca",
                "Sao Raimundo das Mangabeiras",
                "Timbiras",
                "Timon",
                "Trizidela do Vale",
                "Tuntum",
                "Turiacu",
                "Tutoia",
                "Urbano Santos",
                "Vargem Grande",
                "Viana",
                "Vitoria do Mearim",
                "Vitorino Freire",
                "Ze Doca"
            ],
            "Para": [
                "Abaetetuba",
                "Acara",
                "Afua",
                "Agua Azul do Norte",
                "Alenquer",
                "Almeirim",
                "Altamira",
                "Ananindeua",
                "Augusto Correa",
                "Baiao",
                "Barcarena",
                "Belem",
                "Benevides",
                "Braganca",
                "Breu Branco",
                "Breves",
                "Bujaru",
                "Cameta",
                "Capanema",
                "Capitao Poco",
                "Castanhal",
                "Conceicao do Araguaia",
                "Concordia do Para",
                "Curionopolis",
                "Curuca",
                "Dom Eliseu",
                "Eldorado dos Carajas",
                "Garrafao do Norte",
                "Goianesia do Para",
                "Gurupa",
                "Igarape-Acu",
                "Igarape-Miri",
                "Irituia",
                "Itaituba",
                "Itupiranga",
                "Jacareacanga",
                "Jacunda",
                "Juruti",
                "Limoeiro do Ajuru",
                "Mae do Rio",
                "Maraba",
                "Maracana",
                "Marapanim",
                "Marituba",
                "Medicilandia",
                "Mocajuba",
                "Moju",
                "Monte Alegre",
                "Muana",
                "Novo Progresso",
                "Novo Repartimento",
                "Obidos",
                "Oeiras do Para",
                "Oriximina",
                "Ourem",
                "Ourilandia",
                "Pacaja",
                "Paragominas",
                "Parauapebas",
                "Portel",
                "Porto de Moz",
                "Prainha",
                "Redencao",
                "Rio Maria",
                "Rondon do Para",
                "Ruropolis",
                "Salinopolis",
                "Santa Isabel do Para",
                "Santa Luzia do Para",
                "Santa Maria do Para",
                "Santana do Araguaia",
                "Santarem",
                "Santo Antonio do Taua",
                "Sao Caetano de Odivelas",
                "Sao Domingos do Araguaia",
                "Sao Domingos do Capim",
                "Sao Felix do Xingu",
                "Sao Geraldo do Araguaia",
                "Sao Joao de Pirabas",
                "Sao Miguel do Guama",
                "Senador Jose Porfirio",
                "Soure",
                "Tailandia",
                "Terra Santa",
                "Tome-Acu",
                "Tucuma",
                "Tucurui",
                "Ulianopolis",
                "Uruara",
                "Vigia",
                "Viseu",
                "Xinguara"
            ],
            "Paraiba": [
                "Alagoa Grande",
                "Alagoa Nova",
                "Alagoinha",
                "Alhandra",
                "Aracagi",
                "Arara",
                "Araruna",
                "Areia",
                "Aroeiras",
                "Bananeiras",
                "Barra de Santa Rosa",
                "Bayeux",
                "Belem",
                "Boqueirao",
                "Brejo do Cruz",
                "Caapora",
                "Cabedelo",
                "Cacimba de Dentro",
                "Cajazeiras",
                "Campina Grande",
                "Catole do Rocha",
                "Conceicao",
                "Conde",
                "Coremas",
                "Cruz do Espirito Santo",
                "Cuite",
                "Desterro",
                "Dona Ines",
                "Esperanca",
                "Fagundes",
                "Guarabira",
                "Gurinhem",
                "Imaculada",
                "Inga",
                "Itabaiana",
                "Itaporanga",
                "Itapororoca",
                "Itatuba",
                "Jacarau",
                "Joao Pessoa",
                "Juazeirinho",
                "Juripiranga",
                "Juru",
                "Lagoa Seca",
                "Mamanguape",
                "Manaira",
                "Mari",
                "Massaranduba",
                "Mogeiro",
                "Monteiro",
                "Mulungu",
                "Natuba",
                "Nova Floresta",
                "Patos",
                "Paulista",
                "Pedras de Fogo",
                "Pianco",
                "Picui",
                "Pilar",
                "Pirpirituba",
                "Pitimbu",
                "Pocinhos",
                "Pombal",
                "Princesa Isabel",
                "Puxinana",
                "Queimadas",
                "Remigio",
                "Rio Tinto",
                "Salgado de Sao Felix",
                "Santa Luzia",
                "Santa Rita",
                "Sao Bento",
                "Sao Joao do Rio do Peixe",
                "Sao Jose de Piranhas",
                "Sao Sebastiao de Lagoa de Roca",
                "Sape",
                "Serra Branca",
                "Solanea",
                "Soledade",
                "Sousa",
                "Sume",
                "Taperoa",
                "Tavares",
                "Teixeira",
                "Triunfo",
                "Uirauna",
                "Umbuzeiro"
            ],
            "Parana": [
                "Almirante Tamandare",
                "Alto Parana",
                "Alto Piquiri",
                "Altonia",
                "Ampere",
                "Andira",
                "Antonina",
                "Apucarana",
                "Arapongas",
                "Arapoti",
                "Araucaria",
                "Assai",
                "Assis Chateaubriand",
                "Astorga",
                "Bandeirantes",
                "Barbosa Ferraz",
                "Bela Vista do Paraiso",
                "Cambara",
                "Cambe",
                "Campina da Lagoa",
                "Campina Grande do Sul",
                "Campo Largo",
                "Campo Murao",
                "Candido de Abreu",
                "Capitao Leonidas Marques",
                "Carambei",
                "Cascavel",
                "Castro",
                "Centenario do Sul",
                "Chopinzinho",
                "Cianorte",
                "Clevelandia",
                "Colombo",
                "Colorado",
                "Contenda",
                "Corbelia",
                "Cornelio Procopio",
                "Coronel Vivida",
                "Cruzeiro do Oeste",
                "Curitiba",
                "Dois Vizinhos",
                "Engenheiro Beltrao",
                "Faxinal",
                "Fazenda Rio Grande",
                "Florestopolis",
                "Foz do Iguacu",
                "Francisco Beltrao",
                "Goioere",
                "Guaira",
                "Guaraniacu",
                "Guarapuava",
                "Guaratuba",
                "Ibaiti",
                "Ibipora",
                "Imbituva",
                "Ipora",
                "Irati",
                "Itaperucu",
                "Ivaipora",
                "Jacarezinho",
                "Jaguariaiva",
                "Jandaia do Sul",
                "Jataizinho",
                "Lapa",
                "Laranjeiras do Sul",
                "Loanda",
                "Londrina",
                "Mandaguacu",
                "Mandaguari",
                "Marechal Candido Rondon",
                "Marialva",
                "Maringa",
                "Matelandia",
                "Matinhos",
                "Medianeira",
                "Moreira Sales",
                "Nova Aurora",
                "Nova Esperanca",
                "Nova Londrina",
                "Ortigueira",
                "Paicandu",
                "Palmas",
                "Palmeira",
                "Palotina",
                "Paranagua",
                "Paranavai",
                "Pato Branco",
                "Peabiru",
                "Pinhais",
                "Pinhao",
                "Pirai do Sul",
                "Piraquara",
                "Pitanga",
                "Ponta Grossa",
                "Pontal do Parana",
                "Porecatu",
                "Primero de Maio",
                "Prudentopolis",
                "Quatro Barras",
                "Quedas do Iguacu",
                "Realeza",
                "Reserva",
                "Ribeirao do Pinhal",
                "Rio Branco do Sul",
                "Rio Negro",
                "Rolandia",
                "Santa Helena",
                "Santa Terezinha de Itaipu",
                "Santo Antonio da Platina",
                "Santo Antonio do Sudoeste",
                "Sao Joao do Ivai",
                "Sao Jose dos Pinhais",
                "Sao Mateus do Sul",
                "Sao Miguel do Iguacu",
                "Sarandi",
                "Senges",
                "Sertanopolis",
                "Siquera Campos",
                "Tapejara",
                "Telemaco Borba",
                "Terra Boa",
                "Terra Rica",
                "Terra Roxa",
                "Tibagi",
                "Toledo",
                "Ubirata",
                "Umuarama",
                "Uniao da Victoria",
                "Wenceslau Braz"
            ],
            "Pernambuco": [
                "Abreu e Lima",
                "Afogados da Ingazeira",
                "Agrestina",
                "Agua Preta",
                "Aguas Belas",
                "Alianca",
                "Altinho",
                "Amaraji",
                "Aracoiaba",
                "Araripina",
                "Arcoverde",
                "Barra de Guabiraba",
                "Barreiros",
                "Belem de Sao Francisco",
                "Belo Jardim",
                "Bezerros",
                "Bodoco",
                "Bom Conselho",
                "Bom Jardim",
                "Bonito",
                "Brejo da Madre de Deus",
                "Buique",
                "Cabo de Santo Agostinho",
                "Cabrobo",
                "Cachoeirinha",
                "Caetes",
                "Camaragibe",
                "Camocim de Sao Felix",
                "Canhotinho",
                "Capoeiras",
                "Carnaiba",
                "Carpina",
                "Caruaru",
                "Catende",
                "Cha Grande",
                "Condado",
                "Cumaru",
                "Cupira",
                "Custodia",
                "Escada",
                "Exu",
                "Feira Nova",
                "Fernando de Noronha",
                "Flores",
                "Floresta",
                "Gameleira",
                "Garanhuns",
                "Gloria do Goita",
                "Goiana",
                "Gravata",
                "Ibimirim",
                "Igarassu",
                "Inaja",
                "Ipojuca",
                "Ipubi",
                "Itaiba",
                "Itamaraca",
                "Itambe",
                "Itapissuma",
                "Itaquitinga",
                "Jaboatao",
                "Joao Alfredo",
                "Joaquim Nabuco",
                "Lagoa do Itaenga",
                "Lajedo",
                "Limoeiro",
                "Macaparana",
                "Maraial",
                "Moreno",
                "Nazare da Mata",
                "Olinda",
                "Orobo",
                "Ouricuri",
                "Palmares",
                "Panelas",
                "Parnamirim",
                "Passira",
                "Paudalho",
                "Paulista",
                "Pedra",
                "Pesqueira",
                "Petrolandia",
                "Petrolina",
                "Pombos",
                "Quipapa",
                "Recife",
                "Ribeirao",
                "Rio Formoso",
                "Salgueiro",
                "Santa Cruz do Capibaribe",
                "Santa Maria da Boa Vista",
                "Sao Bento do Una",
                "Sao Caitano",
                "Sao Joao",
                "Sao Joaquim do Monte",
                "Sao Jose da Coroa Grande",
                "Sao Jose do Belmonte",
                "Sao Jose do Egito",
                "Sao Lourenco da Mata",
                "Serra Talhada",
                "Sertania",
                "Sirinhaem",
                "Surubim",
                "Tabira",
                "Tamandare",
                "Taquaritinga do Norte",
                "Timbauba",
                "Toritama",
                "Trindade",
                "Triunfo",
                "Tupanatinga",
                "Vicencia",
                "Vitoria de Santo Antao"
            ],
            "Piaui": [
                "Agua Branca",
                "Alto Longa",
                "Altos",
                "Amarante",
                "Avelino Lopes",
                "Barras",
                "Batalha",
                "Beneditinos",
                "Bom Jesus",
                "Buriti dos Lopes",
                "Campo Maior",
                "Canto do Buriti",
                "Castelo do Piaui",
                "Cocal",
                "Corrente",
                "Demerval Lobao",
                "Elesbao Veloso",
                "Esperantina",
                "Floriano",
                "Gilbues",
                "Guadalupe",
                "Inhuma",
                "Itainopolis",
                "Itaueira",
                "Jaicos",
                "Joaquim Pires",
                "Jose de Freitas",
                "Luis Correia",
                "Luzilandia",
                "Matias Olimpio",
                "Miguel Alves",
                "Monsenhor Gil",
                "Oeiras",
                "Palmeirais",
                "Parnaiba",
                "Pedro II",
                "Picos",
                "Pimenteiras",
                "Pio IX",
                "Piracuruca",
                "Piripiri",
                "Porto",
                "Regeneracao",
                "Sao Joao do Piaui",
                "Sao Miguel do Tapuio",
                "Sao Pedro do Piaui",
                "Sao Raimundo Nonato",
                "Simoes",
                "Simplicio Mendes",
                "Teresina",
                "Uniao",
                "Urucui",
                "Valenca do Piaui"
            ],
            "Rio de Janeiro": [
                "Agua Branca",
                "Alto Longa",
                "Altos",
                "Amarante",
                "Avelino Lopes",
                "Barras",
                "Batalha",
                "Beneditinos",
                "Bom Jesus",
                "Buriti dos Lopes",
                "Campo Maior",
                "Canto do Buriti",
                "Castelo do Piaui",
                "Cocal",
                "Corrente",
                "Demerval Lobao",
                "Elesbao Veloso",
                "Esperantina",
                "Floriano",
                "Gilbues",
                "Guadalupe",
                "Inhuma",
                "Itainopolis",
                "Itaueira",
                "Jaicos",
                "Joaquim Pires",
                "Jose de Freitas",
                "Luis Correia",
                "Luzilandia",
                "Matias Olimpio",
                "Miguel Alves",
                "Monsenhor Gil",
                "Oeiras",
                "Palmeirais",
                "Parnaiba",
                "Pedro II",
                "Picos",
                "Pimenteiras",
                "Pio IX",
                "Piracuruca",
                "Piripiri",
                "Porto",
                "Regeneracao",
                "Sao Joao do Piaui",
                "Sao Miguel do Tapuio",
                "Sao Pedro do Piaui",
                "Sao Raimundo Nonato",
                "Simoes",
                "Simplicio Mendes",
                "Teresina",
                "Uniao",
                "Urucui",
                "Valenca do Piaui"
            ],
            "Rio Grande do Norte": [
                "Agua Branca",
                "Alto Longa",
                "Altos",
                "Amarante",
                "Avelino Lopes",
                "Barras",
                "Batalha",
                "Beneditinos",
                "Bom Jesus",
                "Buriti dos Lopes",
                "Campo Maior",
                "Canto do Buriti",
                "Castelo do Piaui",
                "Cocal",
                "Corrente",
                "Demerval Lobao",
                "Elesbao Veloso",
                "Esperantina",
                "Floriano",
                "Gilbues",
                "Guadalupe",
                "Inhuma",
                "Itainopolis",
                "Itaueira",
                "Jaicos",
                "Joaquim Pires",
                "Jose de Freitas",
                "Luis Correia",
                "Luzilandia",
                "Matias Olimpio",
                "Miguel Alves",
                "Monsenhor Gil",
                "Oeiras",
                "Palmeirais",
                "Parnaiba",
                "Pedro II",
                "Picos",
                "Pimenteiras",
                "Pio IX",
                "Piracuruca",
                "Piripiri",
                "Porto",
                "Regeneracao",
                "Sao Joao do Piaui",
                "Sao Miguel do Tapuio",
                "Sao Pedro do Piaui",
                "Sao Raimundo Nonato",
                "Simoes",
                "Simplicio Mendes",
                "Teresina",
                "Uniao",
                "Urucui",
                "Valenca do Piaui"
            ],
            "Rio Grande do Sul": [
                "Agua Branca",
                "Alto Longa",
                "Altos",
                "Amarante",
                "Avelino Lopes",
                "Barras",
                "Batalha",
                "Beneditinos",
                "Bom Jesus",
                "Buriti dos Lopes",
                "Campo Maior",
                "Canto do Buriti",
                "Castelo do Piaui",
                "Cocal",
                "Corrente",
                "Demerval Lobao",
                "Elesbao Veloso",
                "Esperantina",
                "Floriano",
                "Gilbues",
                "Guadalupe",
                "Inhuma",
                "Itainopolis",
                "Itaueira",
                "Jaicos",
                "Joaquim Pires",
                "Jose de Freitas",
                "Luis Correia",
                "Luzilandia",
                "Matias Olimpio",
                "Miguel Alves",
                "Monsenhor Gil",
                "Oeiras",
                "Palmeirais",
                "Parnaiba",
                "Pedro II",
                "Picos",
                "Pimenteiras",
                "Pio IX",
                "Piracuruca",
                "Piripiri",
                "Porto",
                "Regeneracao",
                "Sao Joao do Piaui",
                "Sao Miguel do Tapuio",
                "Sao Pedro do Piaui",
                "Sao Raimundo Nonato",
                "Simoes",
                "Simplicio Mendes",
                "Teresina",
                "Uniao",
                "Urucui",
                "Valenca do Piaui"
            ],
            "Rondonia": [
                "Alta Floresta d\\'Oeste",
                "Alto Alegre do Parecis",
                "Alto Paraiso",
                "Alvorada d\\'Oeste",
                "Ariquemes",
                "Buritis",
                "Cacoal",
                "Candeias do Jamari",
                "Cerejeiras",
                "Colorado do Oeste",
                "Corumbiara",
                "Espigao d\\'Oeste",
                "Governador Jorge Teixeira",
                "Guajara-Mirim",
                "Jaru",
                "Ji-Parana",
                "Machadinho d\\'Oeste",
                "Ministro Andreazza",
                "Mirante da Serra",
                "Nova Brasilandia d\\'Oeste",
                "Nova Mamore",
                "Novo Horizonte do Oeste",
                "Ouro Preto do Oeste",
                "Pimenta Bueno",
                "Porto Velho",
                "Presidente Medici",
                "Rolim de Moura",
                "Santa Luzia d\\'Oeste",
                "Sao Miguel do Guapore",
                "Urupa",
                "Vale do Paraiso",
                "Vilhena"
            ],
            "Roraima": [
                "Alto Alegre",
                "Boa Vista",
                "Bonfim",
                "Caracarai",
                "Mucajai",
                "Normandia",
                "Sao Joao da Baliza",
                "Sao Luiz"
            ],
            "Santa Catarina": [
                "Alto Alegre",
                "Boa Vista",
                "Bonfim",
                "Caracarai",
                "Mucajai",
                "Normandia",
                "Sao Joao da Baliza",
                "Sao Luiz"
            ],
            "Sao Paulo": [
                "Alto Alegre",
                "Boa Vista",
                "Bonfim",
                "Caracarai",
                "Mucajai",
                "Normandia",
                "Sao Joao da Baliza",
                "Sao Luiz"
            ],
            "Sergipe": [
                "Aquidaba",
                "Aracaju",
                "Araua",
                "Areia Branca",
                "Barra dos Coqueiros",
                "Boquim",
                "Campo do Brito",
                "Caninde de Sao Francisco",
                "Capela",
                "Carira",
                "Cristinapolis",
                "Estancia",
                "Frei Paulo",
                "Gararu",
                "Indiaroba",
                "Itabaiana",
                "Itabaianinha",
                "Itaporanga d\\'Ajuda",
                "Japaratuba",
                "Japoata",
                "Lagarto",
                "Laranjeiras",
                "Malhador",
                "Maruim",
                "Moita Bonita",
                "Monte Alegre de Sergipe",
                "Neopolis",
                "Nossa Senhora da Gloria",
                "Nossa Senhora das Dores",
                "Nossa Senhora do Socorro",
                "Pacatuba",
                "Poco Verde",
                "Porto da Folha",
                "Propria",
                "Riachao do Dantas",
                "Ribeiropolis",
                "Salgado",
                "Santa Luzia do Itanhy",
                "Santo Amaro das Brotas",
                "Sao Cristovao",
                "Simao Dias",
                "Tobias Barreto",
                "Tomar do Geru",
                "Umbauba"
            ],
            "Tocantins": [
                "Alvorada",
                "Ananas",
                "Araguacu",
                "Araguaina",
                "Araguatins",
                "Arraias",
                "Augustinopolis",
                "Axixa do Tocantins",
                "Colinas do Tocantins",
                "Dianopolis",
                "Formoso do Araguaia",
                "Goiatins",
                "Guarai",
                "Gurupi",
                "Miracema do Tocantins",
                "Miranorte",
                "Palmas",
                "Paraiso",
                "Parana",
                "Porto Nacional",
                "Sitio Novo do Tocantins",
                "Taguatinga",
                "Tocantinopolis",
                "Wanderlandia",
                "Xambioa"
            ]
        }
    },
    {
        "id": 32,
        "name": "British Indian Ocean Territory",
        "iso3": "IOT",
        "iso2": "IO",
        "phone_code": "246",
        "capital": "Diego Garcia",
        "currency": "USD",
        "states": {
            "British Indian Ocean Territory": [
                "Alvorada",
                "Ananas",
                "Araguacu",
                "Araguaina",
                "Araguatins",
                "Arraias",
                "Augustinopolis",
                "Axixa do Tocantins",
                "Colinas do Tocantins",
                "Dianopolis",
                "Formoso do Araguaia",
                "Goiatins",
                "Guarai",
                "Gurupi",
                "Miracema do Tocantins",
                "Miranorte",
                "Palmas",
                "Paraiso",
                "Parana",
                "Porto Nacional",
                "Sitio Novo do Tocantins",
                "Taguatinga",
                "Tocantinopolis",
                "Wanderlandia",
                "Xambioa"
            ]
        }
    },
    {
        "id": 33,
        "name": "Brunei",
        "iso3": "BRN",
        "iso2": "BN",
        "phone_code": "673",
        "capital": "Bandar Seri Begawan",
        "currency": "BND"
    },
    {
        "id": 34,
        "name": "Bulgaria",
        "iso3": "BGR",
        "iso2": "BG",
        "phone_code": "359",
        "capital": "Sofia",
        "currency": "BGN",
        "states": {
            "Blagoevgrad": [
                "Bansko",
                "Belica",
                "Blagoevgrad",
                "Goce Delchev",
                "Hadzhidimovo",
                "Jakoruda",
                "Kresna",
                "Melnik",
                "Petrich",
                "Razlog",
                "Sandanski",
                "Simitli"
            ],
            "Burgas": [
                "Ahtopol",
                "Ajtos",
                "Balgarovo",
                "Bourgas",
                "Burgas",
                "Carevo",
                "Kableshkovo",
                "Kameno",
                "Karnobat",
                "Malko Tarnovo",
                "Nesebar",
                "Obzor",
                "Pomorie",
                "Primorsko",
                "Sozopol",
                "Sredec",
                "Sungurlare",
                "Tvardica"
            ],
            "Dobrich": [
                "Balchik",
                "Dobrich",
                "General-Toshevo",
                "Kavarna",
                "Loznica",
                "Shabla",
                "Tervel"
            ],
            "Gabrovo": [
                "Drjanovo",
                "Gabrovo",
                "Plachkovci",
                "Sevlievo",
                "Trjavna"
            ],
            "Haskovo": [
                "Dimitrovgrad",
                "Harmanli",
                "Haskovo",
                "Ivajlovgrad",
                "Ljubimec",
                "Madzharovo",
                "Merichleri",
                "Simeonovgrad",
                "Svilengrad"
            ],
            "Jambol": [
                "Boljarovo",
                "Elhovo",
                "Jambol",
                "Straldzha",
                "Topolovgrad"
            ],
            "Kardzhali": [
                "Ardino",
                "Dzhebel",
                "Kardzhali",
                "Krumovgrad",
                "Momchilgrad"
            ],
            "Kjustendil": [
                "Boboshevo",
                "Bobovdol",
                "Dupnica",
                "Kjustendil",
                "Kocherinovo",
                "Rila",
                "Sapareva Banja",
                "Zemen"
            ],
            "Lovech": [
                "Aprilci",
                "Jablanica",
                "Letnica",
                "Lovech",
                "Lukovit",
                "Sopot",
                "Teteven",
                "Trojan",
                "Ugarchin"
            ],
            "Montana": [
                "Berkovica",
                "Bojchinovci",
                "Brusarci",
                "Chiprovci",
                "Lom",
                "Montana",
                "Valchedram",
                "Varshec"
            ],
            "Oblast Sofiya-Grad": [
                "Berkovica",
                "Bojchinovci",
                "Brusarci",
                "Chiprovci",
                "Lom",
                "Montana",
                "Valchedram",
                "Varshec"
            ],
            "Pazardzhik": [
                "Batak",
                "Belovo",
                "Bracigovo",
                "Koprivshtica",
                "Panagjurishte",
                "Pazardzhik",
                "Peshtera",
                "Rakitovo",
                "Septemvri",
                "Strelcha",
                "Velingrad"
            ],
            "Pernik": [
                "Bankja",
                "Batanovci",
                "Breznik",
                "Pernik",
                "Radomir",
                "Tran"
            ],
            "Pleven": [
                "Belene",
                "Cherven Brjag",
                "Dolna Mitropolija",
                "Dolni Dabnik",
                "Guljanci",
                "Levski",
                "Nikopol",
                "Pleven",
                "Pordim",
                "Slavjanovo",
                "Trashtenik",
                "Varbica"
            ],
            "Plovdiv": [
                "Asenovgrad",
                "Brezovo",
                "Car Kalojan",
                "Hisarja",
                "Kalofer",
                "Karlovo",
                "Klisura",
                "Krichim",
                "Parvomaj",
                "Perushtica",
                "Plovdiv",
                "Rakovski",
                "Sadovo",
                "Saedinenie",
                "Stambolijski"
            ],
            "Razgrad": [
                "Isperih",
                "Kubrat",
                "Razgrad",
                "Senovo",
                "Zavet"
            ],
            "Ruse": [
                "Bjala",
                "Borovo",
                "Dve Mogili",
                "Ruse",
                "Russe",
                "Vetovo"
            ],
            "Shumen": [
                "Kaolinovo",
                "Kaspichan",
                "Novi Pazar",
                "Pliska",
                "Shumen",
                "Smjadovo",
                "Veliki Preslav"
            ],
            "Silistra": [
                "Alfatar",
                "Dulovo",
                "Glavinica",
                "Silistra",
                "Tutrakan"
            ],
            "Sliven": [
                "Kermen",
                "Kotel",
                "Nova Zagora",
                "Shivachevo",
                "Sliven"
            ],
            "Smoljan": [
                "Chepelare",
                "Devin",
                "Dospat",
                "Laki",
                "Madan",
                "Nedelino",
                "Rudozem",
                "Smoljan",
                "Zlatograd"
            ],
            "Sofija grad": [
                "Chepelare",
                "Devin",
                "Dospat",
                "Laki",
                "Madan",
                "Nedelino",
                "Rudozem",
                "Smoljan",
                "Zlatograd"
            ],
            "Sofijska oblast": [
                "Chepelare",
                "Devin",
                "Dospat",
                "Laki",
                "Madan",
                "Nedelino",
                "Rudozem",
                "Smoljan",
                "Zlatograd"
            ],
            "Stara Zagora": [
                "Chepelare",
                "Devin",
                "Dospat",
                "Laki",
                "Madan",
                "Nedelino",
                "Rudozem",
                "Smoljan",
                "Zlatograd"
            ],
            "Targovishte": [
                "Antonovo",
                "Omurtag",
                "Opaka",
                "Popovo",
                "Targovishte"
            ],
            "Varna": [
                "Beloslav",
                "Bjala",
                "Dalgopol",
                "Devnja",
                "Iskar",
                "Provadija",
                "Suvorovo",
                "Valchi Dol",
                "Varna"
            ],
            "Veliko Tarnovo": [
                "Beloslav",
                "Bjala",
                "Dalgopol",
                "Devnja",
                "Iskar",
                "Provadija",
                "Suvorovo",
                "Valchi Dol",
                "Varna"
            ],
            "Vidin": [
                "Belogradchik",
                "Bregovo",
                "Dimovo",
                "Dolni Chiflik",
                "Dunavci",
                "Gramada",
                "Kula",
                "Vidin"
            ],
            "Vraca": [
                "Bjala Slatina",
                "Knezha",
                "Kojnare",
                "Kozloduj",
                "Krivodol",
                "Mezdra",
                "Mizija",
                "Orjahovo",
                "Roman",
                "Vraca"
            ],
            "Yablaniza": [
                "Yablaniza"
            ]
        }
    },
    {
        "id": 35,
        "name": "Burkina Faso",
        "iso3": "BFA",
        "iso2": "BF",
        "phone_code": "226",
        "capital": "Ouagadougou",
        "currency": "XOF",
        "states": {
            "Bale": [
                "Boromo"
            ],
            "Bam": [
                "Kongoussi"
            ],
            "Bazega": [
                "Kombissiri"
            ],
            "Bougouriba": [
                "Diebougou",
                "Pa"
            ],
            "Boulgou": [
                "Garango",
                "Tenkodogo"
            ],
            "Boulkiemde": [
                "Koudougou"
            ],
            "Comoe": [
                "Banfora"
            ],
            "Ganzourgou": [
                "Zorgo"
            ],
            "Gnagna": [
                "Bogande"
            ],
            "Gourma": [
                "Fada N\\'gourma"
            ],
            "Houet": [
                "Bekuy",
                "Bobo Dioulasso"
            ],
            "Ioba": [
                "Dano"
            ],
            "Kadiogo": [
                "Ouagadougou"
            ],
            "Kenedougou": [
                "Koalla",
                "Koloko",
                "Orodara"
            ],
            "Komandjari": [
                "Gayeri"
            ],
            "Kompienga": [
                "Pama"
            ],
            "Kossi": [
                "Nouna"
            ],
            "Kouritenga": [
                "Koupela"
            ],
            "Kourweogo": [
                "Bousse"
            ],
            "Leraba": [
                "Sindou"
            ],
            "Mouhoun": [
                "Dedougou"
            ],
            "Nahouri": [
                "Po"
            ],
            "Namentenga": [
                "Boulsa"
            ],
            "Noumbiel": [
                "Batie"
            ],
            "Oubritenga": [
                "Ziniare"
            ],
            "Oudalan": [
                "Gorom-Gorom"
            ],
            "Passore": [
                "Yako"
            ],
            "Poni": [
                "Gaoua",
                "Kampti",
                "Loropeni"
            ],
            "Sanguie": [
                "Reo"
            ],
            "Sanmatenga": [
                "Kaya"
            ],
            "Seno": [
                "Dori"
            ],
            "Sissili": [
                "Gao",
                "Leo"
            ],
            "Soum": [
                "Aribinda",
                "Djibo"
            ],
            "Sourou": [
                "Louta",
                "Tougan"
            ],
            "Tapoa": [
                "Diapaga",
                "Kantchari"
            ],
            "Tuy": [
                "Hounde"
            ],
            "Yatenga": [
                "Ouahigouya"
            ],
            "Zondoma": [
                "Gourcy"
            ],
            "Zoundweogo": [
                "Manga"
            ]
        }
    },
    {
        "id": 36,
        "name": "Burundi",
        "iso3": "BDI",
        "iso2": "BI",
        "phone_code": "257",
        "capital": "Bujumbura",
        "currency": "BIF",
        "states": {
            "Bubanza": [
                "Bubanza"
            ],
            "Bujumbura": [
                "Bujumbura"
            ],
            "Bururi": [
                "Bururi"
            ],
            "Cankuzo": [
                "Cankuzo"
            ],
            "Cibitoke": [
                "Cibitoke"
            ],
            "Gitega": [
                "Gitega"
            ],
            "Karuzi": [
                "Karuzi"
            ],
            "Kayanza": [
                "Kayanza"
            ],
            "Kirundo": [
                "Kirundo"
            ],
            "Makamba": [
                "Makamba"
            ],
            "Muramvya": [
                "Muramvya"
            ],
            "Muyinga": [
                "Muyinga"
            ],
            "Ngozi": [
                "Ngozi"
            ],
            "Rutana": [
                "Rutana"
            ],
            "Ruyigi": [
                "Ruyigi"
            ]
        }
    },
    {
        "id": 37,
        "name": "Cambodia",
        "iso3": "KHM",
        "iso2": "KH",
        "phone_code": "855",
        "capital": "Phnom Penh",
        "currency": "KHR",
        "states": {
            "Banteay Mean Chey": [
                "Ruyigi"
            ],
            "Bat Dambang": [
                "Ruyigi"
            ],
            "Kampong Cham": [
                "Ruyigi"
            ],
            "Kampong Chhnang": [
                "Ruyigi"
            ],
            "Kampong Spoeu": [
                "Ruyigi"
            ],
            "Kampong Thum": [
                "Ruyigi"
            ],
            "Kampot": [
                "Kampot"
            ],
            "Kandal": [
                "Ta Khmau"
            ],
            "Kaoh Kong": [
                "Ta Khmau"
            ],
            "Kracheh": [
                "Kracheh"
            ],
            "Krong Kaeb": [
                "Kracheh"
            ],
            "Krong Pailin": [
                "Kracheh"
            ],
            "Krong Preah Sihanouk": [
                "Kracheh"
            ],
            "Mondol Kiri": [
                "Kracheh"
            ],
            "Otdar Mean Chey": [
                "Kracheh"
            ],
            "Phnum Penh": [
                "Kracheh"
            ],
            "Pousat": [
                "Pousat"
            ],
            "Preah Vihear": [
                "Pousat"
            ],
            "Prey Veaeng": [
                "Pousat"
            ],
            "Rotanak Kiri": [
                "Pousat"
            ],
            "Siem Reab": [
                "Pousat"
            ],
            "Stueng Traeng": [
                "Pousat"
            ],
            "Svay Rieng": [
                "Pousat"
            ],
            "Takaev": [
                "Phumi Takaev"
            ]
        }
    },
    {
        "id": 38,
        "name": "Cameroon",
        "iso3": "CMR",
        "iso2": "CM",
        "phone_code": "237",
        "capital": "Yaounde",
        "currency": "XAF",
        "states": {
            "Adamaoua": [
                "Banyo",
                "Meiganga",
                "Ngaoundere",
                "Tibati",
                "Tignere"
            ],
            "Centre": [
                "Akonolinga",
                "Bafia",
                "Eseka",
                "Mbalmayo",
                "Mfou",
                "Monatele",
                "Nanga Eboko",
                "Obala",
                "Ombesa",
                "Saa",
                "Yaounde"
            ],
            "Est": [
                "Abong Mbang",
                "Batouri",
                "Bertoua",
                "Betare Oya",
                "Djoum",
                "Doume",
                "Lomie",
                "Yokadouma"
            ],
            "Littoral": [
                "Bonaberi",
                "Dibombari",
                "Douala",
                "Edea",
                "Loum",
                "Manjo",
                "Mbanga",
                "Nkongsamba",
                "Yabassi"
            ],
            "Nord": [
                "Figuif",
                "Garoua",
                "Guider",
                "Lagdo",
                "Poli",
                "Rey Bouba",
                "Tchollire"
            ],
            "Nord Extreme": [
                "Figuif",
                "Garoua",
                "Guider",
                "Lagdo",
                "Poli",
                "Rey Bouba",
                "Tchollire"
            ],
            "Nordouest": [
                "Bamenda",
                "Kumbo",
                "Mbengwi",
                "Mme",
                "Njinikom",
                "Nkambe",
                "Wum"
            ],
            "Ouest": [
                "Bafang",
                "Bafoussam",
                "Bafut",
                "Bali",
                "Bana",
                "Bangangte",
                "Djang",
                "Fontem",
                "Foumban",
                "Foumbot",
                "Mbouda"
            ],
            "Sud": [
                "Akom",
                "Ambam",
                "Ebolowa",
                "Kribi",
                "Lolodorf",
                "Moloundou",
                "Mvangue",
                "Sangmelima"
            ],
            "Sudouest": [
                "Buea",
                "Idenao",
                "Kumba",
                "Limbe",
                "Mamfe",
                "Muyuka",
                "Tiko"
            ]
        }
    },
    {
        "id": 39,
        "name": "Canada",
        "iso3": "CAN",
        "iso2": "CA",
        "phone_code": "1",
        "capital": "Ottawa",
        "currency": "CAD",
        "states": {
            "Alberta": [
                "Airdrie",
                "Athabasca",
                "Banff",
                "Barrhead",
                "Bassano",
                "Beaumont",
                "Beaverlodge",
                "Black Diamond",
                "Blackfalds",
                "Blairmore",
                "Bon Accord",
                "Bonnyville",
                "Bow Island",
                "Brooks",
                "Calgary",
                "Calmar",
                "Camrose",
                "Canmore",
                "Cardston",
                "Carstairs",
                "Chateau Lake Louise",
                "Chestermere",
                "Clairmont",
                "Claresholm",
                "Coaldale",
                "Coalhurst",
                "Cochrane",
                "Crossfield",
                "Devon",
                "Didsbury",
                "Drayton Valley",
                "Drumheller",
                "Edmonton",
                "Edson",
                "Elk Point",
                "Fairview",
                "Falher",
                "Fort MacLeod",
                "Fox Creek",
                "Gibbons",
                "Grand Centre",
                "Grande Cache",
                "Grande Prairie",
                "Grimshaw",
                "Hanna",
                "High Level",
                "High Prairie",
                "High River",
                "Hinton",
                "Irricana",
                "Jasper",
                "Killam",
                "La Crete",
                "Lac la Biche",
                "Lacombe",
                "Lamont",
                "Leduc",
                "Lethbridge",
                "Lloydminster",
                "Magrath",
                "Manning",
                "Mayerthorpe",
                "McMurray",
                "Medicine Hat",
                "Millet",
                "Morinville",
                "Nanton",
                "Okotoks",
                "Olds",
                "Peace River",
                "Penhold",
                "Picture Butte",
                "Pincher Creek",
                "Ponoka",
                "Provost",
                "Raymond",
                "Red Deer",
                "Redwater",
                "Rimbey",
                "Rocky Mountain House",
                "Rocky View",
                "Saint Paul",
                "Sexsmith",
                "Sherwood Park",
                "Slave Lake",
                "Smoky Lake",
                "Spirit River",
                "Spruce Grove",
                "Stettler",
                "Stony Plain",
                "Strathmore",
                "Sundre",
                "Swan Hills",
                "Sylvan Lake",
                "Taber",
                "Three Hills",
                "Tofield",
                "Two Hills",
                "Valleyview",
                "Vegreville",
                "Vermilion",
                "Viking",
                "Vulcan",
                "Wainwright",
                "Wembley",
                "Westlock",
                "Wetaskiwin",
                "Whitecourt",
                "Wood Buffalo"
            ],
            "British Columbia": [
                "Airdrie",
                "Athabasca",
                "Banff",
                "Barrhead",
                "Bassano",
                "Beaumont",
                "Beaverlodge",
                "Black Diamond",
                "Blackfalds",
                "Blairmore",
                "Bon Accord",
                "Bonnyville",
                "Bow Island",
                "Brooks",
                "Calgary",
                "Calmar",
                "Camrose",
                "Canmore",
                "Cardston",
                "Carstairs",
                "Chateau Lake Louise",
                "Chestermere",
                "Clairmont",
                "Claresholm",
                "Coaldale",
                "Coalhurst",
                "Cochrane",
                "Crossfield",
                "Devon",
                "Didsbury",
                "Drayton Valley",
                "Drumheller",
                "Edmonton",
                "Edson",
                "Elk Point",
                "Fairview",
                "Falher",
                "Fort MacLeod",
                "Fox Creek",
                "Gibbons",
                "Grand Centre",
                "Grande Cache",
                "Grande Prairie",
                "Grimshaw",
                "Hanna",
                "High Level",
                "High Prairie",
                "High River",
                "Hinton",
                "Irricana",
                "Jasper",
                "Killam",
                "La Crete",
                "Lac la Biche",
                "Lacombe",
                "Lamont",
                "Leduc",
                "Lethbridge",
                "Lloydminster",
                "Magrath",
                "Manning",
                "Mayerthorpe",
                "McMurray",
                "Medicine Hat",
                "Millet",
                "Morinville",
                "Nanton",
                "Okotoks",
                "Olds",
                "Peace River",
                "Penhold",
                "Picture Butte",
                "Pincher Creek",
                "Ponoka",
                "Provost",
                "Raymond",
                "Red Deer",
                "Redwater",
                "Rimbey",
                "Rocky Mountain House",
                "Rocky View",
                "Saint Paul",
                "Sexsmith",
                "Sherwood Park",
                "Slave Lake",
                "Smoky Lake",
                "Spirit River",
                "Spruce Grove",
                "Stettler",
                "Stony Plain",
                "Strathmore",
                "Sundre",
                "Swan Hills",
                "Sylvan Lake",
                "Taber",
                "Three Hills",
                "Tofield",
                "Two Hills",
                "Valleyview",
                "Vegreville",
                "Vermilion",
                "Viking",
                "Vulcan",
                "Wainwright",
                "Wembley",
                "Westlock",
                "Wetaskiwin",
                "Whitecourt",
                "Wood Buffalo"
            ],
            "Manitoba": [
                "Altona",
                "Beausejour",
                "Boissevain",
                "Brandon",
                "Carberry",
                "Carman",
                "Dauphin",
                "Deloraine",
                "Dugald",
                "Flin Flon",
                "Gimli",
                "Hamiota",
                "Killarney",
                "Lac du Bonnet",
                "Leaf Rapids",
                "Lorette",
                "Melita",
                "Minnedosa",
                "Morden",
                "Morris",
                "Neepawa",
                "Niverville",
                "Pinawa",
                "Portage la Prairie",
                "Ritchot",
                "Rivers",
                "Roblin",
                "Saint Adolphe",
                "Sainte Anne",
                "Sainte Rose du Lac",
                "Selkirk",
                "Shilo",
                "Snow Lake",
                "Souris",
                "Springfield",
                "Steinbach",
                "Stonewall",
                "Stony Mountain",
                "Swan River",
                "The Pas",
                "Thompson",
                "Virden",
                "Winkler",
                "Winnipeg"
            ],
            "New Brunswick": [
                "Altona",
                "Beausejour",
                "Boissevain",
                "Brandon",
                "Carberry",
                "Carman",
                "Dauphin",
                "Deloraine",
                "Dugald",
                "Flin Flon",
                "Gimli",
                "Hamiota",
                "Killarney",
                "Lac du Bonnet",
                "Leaf Rapids",
                "Lorette",
                "Melita",
                "Minnedosa",
                "Morden",
                "Morris",
                "Neepawa",
                "Niverville",
                "Pinawa",
                "Portage la Prairie",
                "Ritchot",
                "Rivers",
                "Roblin",
                "Saint Adolphe",
                "Sainte Anne",
                "Sainte Rose du Lac",
                "Selkirk",
                "Shilo",
                "Snow Lake",
                "Souris",
                "Springfield",
                "Steinbach",
                "Stonewall",
                "Stony Mountain",
                "Swan River",
                "The Pas",
                "Thompson",
                "Virden",
                "Winkler",
                "Winnipeg"
            ],
            "Newfoundland and Labrador": [
                "Altona",
                "Beausejour",
                "Boissevain",
                "Brandon",
                "Carberry",
                "Carman",
                "Dauphin",
                "Deloraine",
                "Dugald",
                "Flin Flon",
                "Gimli",
                "Hamiota",
                "Killarney",
                "Lac du Bonnet",
                "Leaf Rapids",
                "Lorette",
                "Melita",
                "Minnedosa",
                "Morden",
                "Morris",
                "Neepawa",
                "Niverville",
                "Pinawa",
                "Portage la Prairie",
                "Ritchot",
                "Rivers",
                "Roblin",
                "Saint Adolphe",
                "Sainte Anne",
                "Sainte Rose du Lac",
                "Selkirk",
                "Shilo",
                "Snow Lake",
                "Souris",
                "Springfield",
                "Steinbach",
                "Stonewall",
                "Stony Mountain",
                "Swan River",
                "The Pas",
                "Thompson",
                "Virden",
                "Winkler",
                "Winnipeg"
            ],
            "Northwest Territories": [
                "Altona",
                "Beausejour",
                "Boissevain",
                "Brandon",
                "Carberry",
                "Carman",
                "Dauphin",
                "Deloraine",
                "Dugald",
                "Flin Flon",
                "Gimli",
                "Hamiota",
                "Killarney",
                "Lac du Bonnet",
                "Leaf Rapids",
                "Lorette",
                "Melita",
                "Minnedosa",
                "Morden",
                "Morris",
                "Neepawa",
                "Niverville",
                "Pinawa",
                "Portage la Prairie",
                "Ritchot",
                "Rivers",
                "Roblin",
                "Saint Adolphe",
                "Sainte Anne",
                "Sainte Rose du Lac",
                "Selkirk",
                "Shilo",
                "Snow Lake",
                "Souris",
                "Springfield",
                "Steinbach",
                "Stonewall",
                "Stony Mountain",
                "Swan River",
                "The Pas",
                "Thompson",
                "Virden",
                "Winkler",
                "Winnipeg"
            ],
            "Nova Scotia": [
                "Altona",
                "Beausejour",
                "Boissevain",
                "Brandon",
                "Carberry",
                "Carman",
                "Dauphin",
                "Deloraine",
                "Dugald",
                "Flin Flon",
                "Gimli",
                "Hamiota",
                "Killarney",
                "Lac du Bonnet",
                "Leaf Rapids",
                "Lorette",
                "Melita",
                "Minnedosa",
                "Morden",
                "Morris",
                "Neepawa",
                "Niverville",
                "Pinawa",
                "Portage la Prairie",
                "Ritchot",
                "Rivers",
                "Roblin",
                "Saint Adolphe",
                "Sainte Anne",
                "Sainte Rose du Lac",
                "Selkirk",
                "Shilo",
                "Snow Lake",
                "Souris",
                "Springfield",
                "Steinbach",
                "Stonewall",
                "Stony Mountain",
                "Swan River",
                "The Pas",
                "Thompson",
                "Virden",
                "Winkler",
                "Winnipeg"
            ],
            "Nunavut": [
                "Clyde River",
                "Iqaluit",
                "Kangerdlinerk",
                "Oqsuqtooq",
                "Pangnirtung",
                "Tununirusiq"
            ],
            "Ontario": [
                "Acton",
                "Ajax",
                "Alexandria",
                "Alfred",
                "Alliston",
                "Almonte",
                "Amherstburg",
                "Amigo Beach",
                "Angus-Borden",
                "Arnprior",
                "Arthur",
                "Athens",
                "Atikokan",
                "Attawapiskat",
                "Aurora",
                "Aylmer",
                "Ayr",
                "Barrie",
                "Barry\\'s Bay",
                "Beamsville",
                "Beaverton",
                "Beeton",
                "Belleville",
                "Belmont",
                "Blenheim",
                "Blind River",
                "Bobcaygeon",
                "Bolton",
                "Bourget",
                "Bowmanville-Newcastle",
                "Bracebridge",
                "Bradford",
                "Brampton",
                "Brantford",
                "Bridgenorth-Chemong Park Area",
                "Brighton",
                "Brockville",
                "Brooklin",
                "Brussels",
                "Burford",
                "Burlington",
                "Caledon",
                "Caledon East",
                "Caledonia",
                "Cambridge",
                "Campbellford",
                "Campbellville",
                "Cannington",
                "Capreol",
                "Cardinal",
                "Carleton Place",
                "Carlisle",
                "Casselman",
                "Cayuga",
                "Chalk River",
                "Chapleau",
                "Chatham",
                "Chesley",
                "Chesterville",
                "Clinton",
                "Cobourg",
                "Cochrane",
                "Colborne",
                "Colchester",
                "Collingwood",
                "Concord",
                "Constance Bay",
                "Cookstown",
                "Cornwall",
                "Creemore",
                "Crystal Beach",
                "Deep River",
                "Delhi",
                "Deseronto",
                "Downsview",
                "Drayton",
                "Dresden",
                "Dryden",
                "Dundalk",
                "Dunnville",
                "Durham",
                "Dutton",
                "Eganville",
                "Elliot Lake",
                "Elmira",
                "Elmvale",
                "Embrun",
                "Englehart",
                "Erin",
                "Espanola",
                "Essex",
                "Etobicoke",
                "Everett",
                "Exeter",
                "Fenelon Falls",
                "Fergus",
                "Forest",
                "Fort Erie",
                "Fort Frances",
                "Frankford",
                "Gananoque",
                "Georgetown",
                "Georgina",
                "Geraldton",
                "Glencoe",
                "Goderich",
                "Golden",
                "Gormley",
                "Grand Bend",
                "Grand Valley",
                "Gravenhurst",
                "Guelph",
                "Hagersville",
                "Haileybury",
                "Hamilton",
                "Hanover",
                "Harriston",
                "Harrow",
                "Hastings",
                "Havelock",
                "Hawkesbury",
                "Hearst",
                "Hensall",
                "Hillsburgh",
                "Hornepayne",
                "Huntsville",
                "Ingersoll",
                "Innisfil",
                "Iroquois",
                "Iroquois Falls",
                "Jarvis",
                "Kanata",
                "Kapuskasing",
                "Kars",
                "Kemptville",
                "Kenora",
                "Kincardine",
                "Kingston",
                "Kirkland Lake",
                "Kitchener",
                "Lakefield",
                "Lanark",
                "Leamington",
                "Lindsay",
                "Listowel",
                "Little Current",
                "Lively",
                "London",
                "Longlac",
                "Lucan",
                "Lucknow",
                "L\\'Original",
                "Madoc",
                "Manitouwadge",
                "Maple",
                "Marathon",
                "Markdale",
                "Markham",
                "Marmora",
                "Mattawa",
                "Meaford",
                "Metcalfe",
                "Midland",
                "Mildmay",
                "Millbrook",
                "Milton",
                "Milverton",
                "Mississauga",
                "Mississauga Beach",
                "Mitchell",
                "Moose Factory",
                "Morrisburg",
                "Mount Albert",
                "Mount Brydges",
                "Mount Forest",
                "Munster",
                "Nanticoke",
                "Napanee",
                "Nepean",
                "New Hamburg",
                "Newmarket",
                "Newtonville",
                "Nobleton",
                "North Bay",
                "North Gower",
                "North York",
                "Norwich",
                "Norwood",
                "Oakville",
                "Omemee",
                "Onaping-Levack",
                "Ontario",
                "Orangeville",
                "Orillia",
                "Orono",
                "Osgoode",
                "Oshawa",
                "Ottawa",
                "Owen Sound",
                "Paisley",
                "Palmerston",
                "Paris",
                "Parkhill",
                "Parry Sound",
                "Pembroke",
                "Perth",
                "Petawawa",
                "Peterborough",
                "Petrolia",
                "Pickering",
                "Picton",
                "Porcupine",
                "Port Credit",
                "Port Dover",
                "Port Elgin",
                "Port Hope",
                "Port Perry",
                "Port Stanley",
                "Powassan",
                "Prescott",
                "Queensville",
                "Renfrew",
                "Richmond",
                "Richmond Hill",
                "Ridgetown",
                "Rockland",
                "Rockwood",
                "Rodney",
                "Saint Catharines",
                "Saint Catharines-Niagara",
                "Saint George",
                "Saint Jacobs",
                "Saint Marys",
                "Saint Thomas",
                "Sarnia",
                "Sault Sainte Marie",
                "Scarborough",
                "Schomberg",
                "Seaforth",
                "Shelburne",
                "Simcoe",
                "Sioux Lookout",
                "Smiths Falls",
                "Smithville",
                "South River",
                "Southampton",
                "Stayner",
                "Stirling",
                "Stoney Creek",
                "Stoney Point",
                "Stouffville",
                "Stratford",
                "Strathroy",
                "Sturgeon Falls",
                "Sudbury",
                "Sutton",
                "Tavistock",
                "Teeswater",
                "Terrace Bay",
                "Thamesford",
                "Thessalon",
                "Thornbury",
                "Thornhill",
                "Thunder Bay",
                "Tilbury",
                "Tilsonburg",
                "Timmins",
                "Toronto",
                "Tory Hill",
                "Tottenham",
                "Tweed",
                "Uxbridge",
                "Valley East",
                "Vankleek Hill",
                "Vaughan",
                "Vineland",
                "Walkerton",
                "Wallaceburg",
                "Wasaga Beach",
                "Waterdown",
                "Waterford",
                "Waterloo",
                "Watford",
                "Wawa",
                "Welland",
                "Wellesley",
                "Wellington",
                "West Lorne",
                "Wheatley",
                "Whitby",
                "Whitchurch-Stouffville",
                "Wiarton",
                "Wikwemikong",
                "Willowdale",
                "Winchester",
                "Windsor",
                "Wingham",
                "Woodbridge",
                "Woodstock",
                "Wyoming"
            ],
            "Prince Edward Island": [
                "Acton",
                "Ajax",
                "Alexandria",
                "Alfred",
                "Alliston",
                "Almonte",
                "Amherstburg",
                "Amigo Beach",
                "Angus-Borden",
                "Arnprior",
                "Arthur",
                "Athens",
                "Atikokan",
                "Attawapiskat",
                "Aurora",
                "Aylmer",
                "Ayr",
                "Barrie",
                "Barry\\'s Bay",
                "Beamsville",
                "Beaverton",
                "Beeton",
                "Belleville",
                "Belmont",
                "Blenheim",
                "Blind River",
                "Bobcaygeon",
                "Bolton",
                "Bourget",
                "Bowmanville-Newcastle",
                "Bracebridge",
                "Bradford",
                "Brampton",
                "Brantford",
                "Bridgenorth-Chemong Park Area",
                "Brighton",
                "Brockville",
                "Brooklin",
                "Brussels",
                "Burford",
                "Burlington",
                "Caledon",
                "Caledon East",
                "Caledonia",
                "Cambridge",
                "Campbellford",
                "Campbellville",
                "Cannington",
                "Capreol",
                "Cardinal",
                "Carleton Place",
                "Carlisle",
                "Casselman",
                "Cayuga",
                "Chalk River",
                "Chapleau",
                "Chatham",
                "Chesley",
                "Chesterville",
                "Clinton",
                "Cobourg",
                "Cochrane",
                "Colborne",
                "Colchester",
                "Collingwood",
                "Concord",
                "Constance Bay",
                "Cookstown",
                "Cornwall",
                "Creemore",
                "Crystal Beach",
                "Deep River",
                "Delhi",
                "Deseronto",
                "Downsview",
                "Drayton",
                "Dresden",
                "Dryden",
                "Dundalk",
                "Dunnville",
                "Durham",
                "Dutton",
                "Eganville",
                "Elliot Lake",
                "Elmira",
                "Elmvale",
                "Embrun",
                "Englehart",
                "Erin",
                "Espanola",
                "Essex",
                "Etobicoke",
                "Everett",
                "Exeter",
                "Fenelon Falls",
                "Fergus",
                "Forest",
                "Fort Erie",
                "Fort Frances",
                "Frankford",
                "Gananoque",
                "Georgetown",
                "Georgina",
                "Geraldton",
                "Glencoe",
                "Goderich",
                "Golden",
                "Gormley",
                "Grand Bend",
                "Grand Valley",
                "Gravenhurst",
                "Guelph",
                "Hagersville",
                "Haileybury",
                "Hamilton",
                "Hanover",
                "Harriston",
                "Harrow",
                "Hastings",
                "Havelock",
                "Hawkesbury",
                "Hearst",
                "Hensall",
                "Hillsburgh",
                "Hornepayne",
                "Huntsville",
                "Ingersoll",
                "Innisfil",
                "Iroquois",
                "Iroquois Falls",
                "Jarvis",
                "Kanata",
                "Kapuskasing",
                "Kars",
                "Kemptville",
                "Kenora",
                "Kincardine",
                "Kingston",
                "Kirkland Lake",
                "Kitchener",
                "Lakefield",
                "Lanark",
                "Leamington",
                "Lindsay",
                "Listowel",
                "Little Current",
                "Lively",
                "London",
                "Longlac",
                "Lucan",
                "Lucknow",
                "L\\'Original",
                "Madoc",
                "Manitouwadge",
                "Maple",
                "Marathon",
                "Markdale",
                "Markham",
                "Marmora",
                "Mattawa",
                "Meaford",
                "Metcalfe",
                "Midland",
                "Mildmay",
                "Millbrook",
                "Milton",
                "Milverton",
                "Mississauga",
                "Mississauga Beach",
                "Mitchell",
                "Moose Factory",
                "Morrisburg",
                "Mount Albert",
                "Mount Brydges",
                "Mount Forest",
                "Munster",
                "Nanticoke",
                "Napanee",
                "Nepean",
                "New Hamburg",
                "Newmarket",
                "Newtonville",
                "Nobleton",
                "North Bay",
                "North Gower",
                "North York",
                "Norwich",
                "Norwood",
                "Oakville",
                "Omemee",
                "Onaping-Levack",
                "Ontario",
                "Orangeville",
                "Orillia",
                "Orono",
                "Osgoode",
                "Oshawa",
                "Ottawa",
                "Owen Sound",
                "Paisley",
                "Palmerston",
                "Paris",
                "Parkhill",
                "Parry Sound",
                "Pembroke",
                "Perth",
                "Petawawa",
                "Peterborough",
                "Petrolia",
                "Pickering",
                "Picton",
                "Porcupine",
                "Port Credit",
                "Port Dover",
                "Port Elgin",
                "Port Hope",
                "Port Perry",
                "Port Stanley",
                "Powassan",
                "Prescott",
                "Queensville",
                "Renfrew",
                "Richmond",
                "Richmond Hill",
                "Ridgetown",
                "Rockland",
                "Rockwood",
                "Rodney",
                "Saint Catharines",
                "Saint Catharines-Niagara",
                "Saint George",
                "Saint Jacobs",
                "Saint Marys",
                "Saint Thomas",
                "Sarnia",
                "Sault Sainte Marie",
                "Scarborough",
                "Schomberg",
                "Seaforth",
                "Shelburne",
                "Simcoe",
                "Sioux Lookout",
                "Smiths Falls",
                "Smithville",
                "South River",
                "Southampton",
                "Stayner",
                "Stirling",
                "Stoney Creek",
                "Stoney Point",
                "Stouffville",
                "Stratford",
                "Strathroy",
                "Sturgeon Falls",
                "Sudbury",
                "Sutton",
                "Tavistock",
                "Teeswater",
                "Terrace Bay",
                "Thamesford",
                "Thessalon",
                "Thornbury",
                "Thornhill",
                "Thunder Bay",
                "Tilbury",
                "Tilsonburg",
                "Timmins",
                "Toronto",
                "Tory Hill",
                "Tottenham",
                "Tweed",
                "Uxbridge",
                "Valley East",
                "Vankleek Hill",
                "Vaughan",
                "Vineland",
                "Walkerton",
                "Wallaceburg",
                "Wasaga Beach",
                "Waterdown",
                "Waterford",
                "Waterloo",
                "Watford",
                "Wawa",
                "Welland",
                "Wellesley",
                "Wellington",
                "West Lorne",
                "Wheatley",
                "Whitby",
                "Whitchurch-Stouffville",
                "Wiarton",
                "Wikwemikong",
                "Willowdale",
                "Winchester",
                "Windsor",
                "Wingham",
                "Woodbridge",
                "Woodstock",
                "Wyoming"
            ],
            "Quebec": [
                "Acton Vale",
                "Albanel",
                "Alencon",
                "Alma",
                "Amos",
                "Amqui",
                "Anjou",
                "Asbestos",
                "Bagotville",
                "Baie-Comeau",
                "Baie-Saint-Paul",
                "Barraute",
                "Beauceville",
                "Beaupre",
                "Bedford",
                "Beloeil",
                "Bernierville",
                "Berthierville",
                "Betsiamites",
                "Boisbriand",
                "Bonaventure",
                "Boucherville",
                "Bromont",
                "Brossard",
                "Brownsburg",
                "Buckingham",
                "Cabano",
                "Candiac",
                "Cap-aux-Meules",
                "Cap-Chat",
                "Carleton",
                "Causapscal",
                "Chandler",
                "Chapais",
                "Charlesbourg",
                "Chateau-Richer",
                "Chibougamou",
                "Chicoutimi-Jonquiere",
                "Chisasibi",
                "Chute-aux-Outardes",
                "Clermont",
                "Coaticook",
                "Coleraine",
                "Contrecoeur",
                "Cookshire",
                "Cowansville",
                "Crabtree",
                "Danville",
                "Daveluyville",
                "Degelis",
                "Desbiens",
                "Disraeli",
                "Dolbeau",
                "Donnacona",
                "Dorval",
                "Drummondville",
                "East Angus",
                "East Broughton",
                "Farnham",
                "Ferme-Neuve",
                "Fermont",
                "Filion",
                "Forestville",
                "Fort-Coulonge",
                "Gaspe",
                "Gentilly",
                "Granby",
                "Grande-Riviere",
                "Grenville",
                "Ham Nord",
                "Hampstead",
                "Hauterive",
                "Havre-Saint-Pierre",
                "Hebertville",
                "Huntingdon",
                "Joliette",
                "Kingsey Falls",
                "La Malbaie",
                "La Pocatiere",
                "La Sarre",
                "La Tuque",
                "Labelle",
                "Lac-au-Saumon",
                "Lac-Etchemin",
                "Lac-Lapierre",
                "Lac-Megantic",
                "Lachine",
                "Lachute",
                "Lacolle",
                "Lasalle",
                "Laurentides",
                "Laurier-Station",
                "Laval",
                "Lavaltrie",
                "Le Bic",
                "Lebel-sur-Quevillon",
                "Les Cedres",
                "Les Coteaux",
                "Les Escoumins",
                "Liniere",
                "Longueuil",
                "Louiseville",
                "Luceville",
                "L\\'Annonciation",
                "L\\'Ascension-de-Notre-Seigneur",
                "L\\'Epiphanie",
                "Macamic",
                "Magog",
                "Malartic",
                "Maniwaki",
                "Marieville",
                "Maskinonge",
                "Matagami",
                "Matane",
                "Metabetchouan",
                "Mirabel",
                "Mistissini",
                "Mont-Joli",
                "Mont-Laurier",
                "Montmagny",
                "Montreal",
                "Murdochville",
                "Napierville",
                "New Richmond",
                "Nicolet",
                "Normandin",
                "Notre-Dame-du-Bon-Conseil",
                "Notre-Dame-du-Lac",
                "Notre-Dame-du-Mont-Carmel",
                "Oka-Kanesatake",
                "Ormstown",
                "Papineauville",
                "Pierreville",
                "Plessisville",
                "Pointe-Claire",
                "Pont-Rouge",
                "Port-Alfred-Bagotville",
                "Port-Cartier",
                "Portneuf",
                "Price",
                "Princeville",
                "Quebec",
                "Rawdon",
                "Repentigny",
                "Richmond",
                "Rigaud",
                "Rimouski",
                "Riviere-au-Renard",
                "Riviere-du-Loup",
                "Roberval",
                "Rougemont",
                "Rouyn-Noranda",
                "Saint-Agapit",
                "Saint-Alexandre",
                "Saint-Alexis-des-Monts",
                "Saint-Ambroise",
                "Saint-Andre-Avellin",
                "Saint-Anselme",
                "Saint-Apollinaire",
                "Saint-Augustin",
                "Saint-Basile-Sud",
                "Saint-Bruno",
                "Saint-Canut",
                "Saint-Cesaire",
                "Saint-Cyrill-de-Wendover",
                "Saint-Damase",
                "Saint-Damien-de-Buckland",
                "Saint-Denis",
                "Saint-Donat-de-Montcalm",
                "Saint-Ephrem-de-Tring",
                "Saint-Fabien",
                "Saint-Felicien",
                "Saint-Felix-de-Valois",
                "Saint-Gabriel",
                "Saint-Gedeon",
                "Saint-Georges",
                "Saint-Germain-de-Grantham",
                "Saint-Gregoire",
                "Saint-Henri-de-Levis",
                "Saint-Honore",
                "Saint-Hyacinthe",
                "Saint-Jacques",
                "Saint-Jean-de-Dieu",
                "Saint-Jean-Port-Joli",
                "Saint-Jean-sur-Richelieu",
                "Saint-Jerome",
                "Saint-Josephe-de-Beauce",
                "Saint-Josephe-de-la-Riviere-Bl",
                "Saint-Josephe-de-Lanoraie",
                "Saint-Jovite",
                "Saint-Laurent",
                "Saint-Liboire",
                "Saint-Marc-des-Carrieres",
                "Saint-Martin",
                "Saint-Michel-des-Saints",
                "Saint-Pacome",
                "Saint-Pascal",
                "Saint-Pie",
                "Saint-Prosper",
                "Saint-Raphael",
                "Saint-Raymond",
                "Saint-Remi",
                "Saint-Roch-de-l\\'Achigan",
                "Saint-Sauveur-des-Monts",
                "Saint-Tite",
                "Sainte-Adele",
                "Sainte-Agathe-des-Monts",
                "Sainte-Anne-des-Monts",
                "Sainte-Anne-des-Plaines",
                "Sainte-Catherine",
                "Sainte-Claire",
                "Sainte-Julienne",
                "Sainte-Justine",
                "Sainte-Madeleine",
                "Sainte-Marie",
                "Sainte-Martine",
                "Sainte-Sophie",
                "Sainte-Thecle",
                "Sainte-Therese",
                "Salaberry-de-Valleyfield",
                "Sayabec",
                "Senneterre",
                "Sept-Iles",
                "Shawinigan",
                "Shawville",
                "Sherbrooke",
                "Sorel",
                "St Faustin",
                "St. Hubert",
                "St. Jean Chrysostome",
                "Temiscaming",
                "Terrebonne",
                "Thetford Mines",
                "Thurso",
                "Trois-Pistoles",
                "Trois-Rivieres",
                "Val-David",
                "Val-d\\'Or",
                "Valcourt",
                "Vallee-Jonction",
                "Vaudreuil",
                "Vercheres",
                "Victoriaville",
                "Ville-Marie",
                "Warwick",
                "Waterloo",
                "Weedon Centre",
                "Westmount",
                "Wickham",
                "Windsor",
                "Yamachiche"
            ],
            "Saskatchewan": [
                "Assiniboia",
                "Biggar",
                "Canora",
                "Carlyle",
                "Carnduff",
                "Caronport",
                "Carrot",
                "Dalmeny",
                "Davidson",
                "Esterhazy",
                "Estevan",
                "Eston",
                "Foam Lake",
                "Fort Qu\\'Appelle",
                "Gravelbourg",
                "Grenfell",
                "Gull Lake",
                "Hudson Bay",
                "Humboldt",
                "Indian Head",
                "Kamsack",
                "Kelvington",
                "Kerrobert",
                "Kindersley",
                "Kipling",
                "La Ronge",
                "Langenburg",
                "Langham",
                "Lanigan",
                "Lloydminster",
                "Lumsden",
                "Macklin",
                "Maple Creek",
                "Martensville",
                "Meadow Lake",
                "Melfort",
                "Melville",
                "Moose Jaw",
                "Moosomin",
                "Nipawin",
                "North Battleford",
                "Outlook",
                "Oxbow",
                "Pilot Butte",
                "Preeceville",
                "Prince Albert",
                "Regina",
                "Rosetown",
                "Rosthem",
                "Saskatoon",
                "Shaunavon",
                "Shellbrook",
                "Swift Current",
                "Tisdale",
                "Unity",
                "Wadena",
                "Warman",
                "Watrous",
                "Weyburn",
                "White City",
                "Wilkie",
                "Wynyard",
                "Yorkton"
            ],
            "Yukon": [
                "Haines Junction",
                "Mayo",
                "Whitehorse"
            ]
        }
    },
    {
        "id": 40,
        "name": "Cape Verde",
        "iso3": "CPV",
        "iso2": "CV",
        "phone_code": "238",
        "capital": "Praia",
        "currency": "CVE",
        "states": {
            "Boavista": [
                "Sal Rei"
            ],
            "Brava": [
                "Nova Sintra"
            ],
            "Fogo": [
                "Mosteiros",
                "Sao Filipe"
            ],
            "Maio": [
                "Vila do Maio"
            ],
            "Sal": [
                "Santa Maria"
            ],
            "Santo Antao": [
                "Santa Maria"
            ],
            "Sao Nicolau": [
                "Santa Maria"
            ],
            "Sao Tiago": [
                "Santa Maria"
            ],
            "Sao Vicente": [
                "Santa Maria"
            ]
        }
    },
    {
        "id": 41,
        "name": "Cayman Islands",
        "iso3": "CYM",
        "iso2": "KY",
        "phone_code": "+1-345",
        "capital": "George Town",
        "currency": "KYD",
        "states": {
            "Grand Cayman": [
                "Santa Maria"
            ]
        }
    },
    {
        "id": 42,
        "name": "Central African Republic",
        "iso3": "CAF",
        "iso2": "CF",
        "phone_code": "236",
        "capital": "Bangui",
        "currency": "XAF",
        "states": {
            "Bamingui-Bangoran": [
                "Ndele"
            ],
            "Bangui": [
                "Bangui"
            ],
            "Basse-Kotto": [
                "Alindao",
                "Kembe",
                "Mobaye"
            ],
            "Haut-Mbomou": [
                "Obo",
                "Zemio"
            ],
            "Haute-Kotto": [
                "Bria",
                "Ouadda"
            ],
            "Kemo": [
                "Dekoa",
                "Sibut"
            ],
            "Lobaye": [
                "Boda",
                "Mbaiki",
                "Mongoumba"
            ],
            "Mambere-Kadei": [
                "Berberati",
                "Carnot",
                "Gamboula"
            ],
            "Mbomou": [
                "Bangassou",
                "Gambo",
                "Ouango",
                "Rafai"
            ],
            "Nana-Gribizi": [
                "Kaga-Bandoro"
            ],
            "Nana-Mambere": [
                "Baboua",
                "Baoro",
                "Bouar"
            ],
            "Ombella Mpoko": [
                "Baboua",
                "Baoro",
                "Bouar"
            ],
            "Ouaka": [
                "Bambari",
                "Grimari",
                "Ippy",
                "Kouango"
            ],
            "Ouham": [
                "Batangafo",
                "Bossangoa",
                "Bouca",
                "Kabo"
            ],
            "Ouham-Pende": [
                "Bocaranga",
                "Bozoum",
                "Paoua"
            ],
            "Sangha-Mbaere": [
                "Nola"
            ],
            "Vakaga": [
                "Birao"
            ]
        }
    },
    {
        "id": 43,
        "name": "Chad",
        "iso3": "TCD",
        "iso2": "TD",
        "phone_code": "235",
        "capital": "N'Djamena",
        "currency": "XAF",
        "states": {
            "Batha": [
                "Ati",
                "Oum Hadjer"
            ],
            "Biltine": [
                "Biltine"
            ],
            "Bourkou-Ennedi-Tibesti": [
                "Aouzou",
                "Bardai",
                "Fada",
                "Faya"
            ],
            "Chari-Baguirmi": [
                "Bokoro",
                "Bousso",
                "Dourbali",
                "Massaguet",
                "Massakory",
                "Massenya",
                "Ngama",
                "N\\'Djamena"
            ],
            "Guera": [
                "Bitkine",
                "Melfi",
                "Mongo"
            ],
            "Kanem": [
                "Mao",
                "Moussoro",
                "Rig-Rig"
            ],
            "Lac": [
                "Bol"
            ],
            "Logone Occidental": [
                "Bol"
            ],
            "Logone Oriental": [
                "Bol"
            ],
            "Mayo-Kebbi": [
                "Bongor",
                "Fianga",
                "Gounou Gaya",
                "Guelengdeng",
                "Lere",
                "Pala"
            ],
            "Moyen-Chari": [
                "Goundi",
                "Koumra",
                "Kyabe",
                "Moissala",
                "Sarh"
            ],
            "Ouaddai": [
                "Abeche",
                "Adre",
                "Am Dam"
            ],
            "Salamat": [
                "Abou Deia",
                "Am Timan",
                "Mangueigne"
            ],
            "Tandjile": [
                "Benoy",
                "Bere",
                "Kelo",
                "Lai"
            ]
        }
    },
    {
        "id": 44,
        "name": "Chile",
        "iso3": "CHL",
        "iso2": "CL",
        "phone_code": "56",
        "capital": "Santiago",
        "currency": "CLP",
        "states": {
            "Aisen": [
                "Aisen",
                "Chile Chico",
                "Cisnes",
                "Cochrane",
                "Coihaique",
                "Guaitecas",
                "Lago Verde",
                "O\\'Higgins",
                "Rio Ibanez",
                "Tortel"
            ],
            "Antofagasta": [
                "Antofagasta",
                "Calama",
                "Maria Elena",
                "Mejilones",
                "Ollague",
                "San Pedro de Atacama",
                "Sierra Gorda",
                "Taltal",
                "Tocopilla"
            ],
            "Araucania": [
                "Angol",
                "Carahue",
                "Collipulli",
                "Cunco",
                "Curacautin",
                "Curarrehue",
                "Ercilla",
                "Freire",
                "Galvarino",
                "Gorbea",
                "Lautaro",
                "Loncoche",
                "Lonquimay",
                "Los Sauces",
                "Lumaco",
                "Melipeuco",
                "Nueva Imperial",
                "Padre las Casas",
                "Perquenco",
                "Pitrufquen",
                "Pucon",
                "Puren",
                "Renaico",
                "Saavedra",
                "Temuco",
                "Teodoro Schmidt",
                "Tolten",
                "Traiguen",
                "Victoria",
                "Vilcun",
                "Villarica"
            ],
            "Atacama": [
                "Alto del Carmen",
                "Caldera",
                "Chanaral",
                "Copiapo",
                "Diego de Almagro",
                "Freirina",
                "Huasco",
                "Tierra Amarilla",
                "Vallenar"
            ],
            "Bio Bio": [
                "Alto del Carmen",
                "Caldera",
                "Chanaral",
                "Copiapo",
                "Diego de Almagro",
                "Freirina",
                "Huasco",
                "Tierra Amarilla",
                "Vallenar"
            ],
            "Coquimbo": [
                "Andacollo",
                "Canela",
                "Combarbala",
                "Coquimbo",
                "Illapel",
                "La Higuera",
                "La Serena",
                "Los Vilos",
                "Monte Patria",
                "Ovalle",
                "Paiguano",
                "Punitaci",
                "Rio Hurtado",
                "Salamanca",
                "Vicuna"
            ],
            "Libertador General Bernardo O\\'": [
                "Andacollo",
                "Canela",
                "Combarbala",
                "Coquimbo",
                "Illapel",
                "La Higuera",
                "La Serena",
                "Los Vilos",
                "Monte Patria",
                "Ovalle",
                "Paiguano",
                "Punitaci",
                "Rio Hurtado",
                "Salamanca",
                "Vicuna"
            ],
            "Los Lagos": [
                "Andacollo",
                "Canela",
                "Combarbala",
                "Coquimbo",
                "Illapel",
                "La Higuera",
                "La Serena",
                "Los Vilos",
                "Monte Patria",
                "Ovalle",
                "Paiguano",
                "Punitaci",
                "Rio Hurtado",
                "Salamanca",
                "Vicuna"
            ],
            "Magellanes": [
                "Cabo de Horno",
                "Laguna Blanca",
                "Natales",
                "Porvenir",
                "Primavera",
                "Punta Arenas",
                "Rio Verde",
                "San Gregorio",
                "Timaukel",
                "Torres del Paine"
            ],
            "Maule": [
                "Cauquenes",
                "Chanco",
                "Colbun",
                "Constitucion",
                "Curepto",
                "Curico",
                "Empedrado",
                "Hualane",
                "Licanten",
                "Linares",
                "Longavi",
                "Maule",
                "Molina",
                "Parral",
                "Pelarco",
                "Pelluhue",
                "Pencahue",
                "Rauco",
                "Retiro",
                "Rio Claro",
                "Romeral",
                "Sagrada Familia",
                "San Clemente",
                "San Javier",
                "San Rafael",
                "Talca",
                "Teno",
                "Vichuquen",
                "Villa Alegre",
                "Yerbas Buenas"
            ],
            "Metropolitana": [
                "Alhue",
                "Buin",
                "Calera de Tango",
                "Colina",
                "Curacavi",
                "El Monte",
                "Isla de Maipo",
                "Lampa",
                "Maria Pinto",
                "Melipilla",
                "Padre Hurtado",
                "Paine",
                "Penaflor",
                "Pirque",
                "Puente Alto",
                "Quilicura",
                "San Bernardo",
                "San Jose de Maipo",
                "San Pedro",
                "Santiago",
                "Talagante",
                "Tiltil"
            ],
            "Metropolitana de Santiago": [
                "Alhue",
                "Buin",
                "Calera de Tango",
                "Colina",
                "Curacavi",
                "El Monte",
                "Isla de Maipo",
                "Lampa",
                "Maria Pinto",
                "Melipilla",
                "Padre Hurtado",
                "Paine",
                "Penaflor",
                "Pirque",
                "Puente Alto",
                "Quilicura",
                "San Bernardo",
                "San Jose de Maipo",
                "San Pedro",
                "Santiago",
                "Talagante",
                "Tiltil"
            ],
            "Tarapaca": [
                "Arica",
                "Camarones",
                "Camina",
                "Colchane",
                "General Lagos",
                "Huara",
                "Iquique",
                "Pica",
                "Pozo Almonte",
                "Putre"
            ],
            "Valparaiso": [
                "Algarrobo",
                "Cabildo",
                "Calera",
                "Calle Larga",
                "Cartagena",
                "Casablanca",
                "Catemu",
                "Concon",
                "El Quisco",
                "El Tabo",
                "Hijuelas",
                "La Cruz",
                "La Ligua",
                "Limache",
                "Llaillay",
                "Los Andes",
                "Nogales",
                "Olmue",
                "Panquehue",
                "Papudo",
                "Petorca",
                "Puchuncavi",
                "Putaendeo",
                "Quillota",
                "Quilpue",
                "Quintero",
                "Rinconada",
                "San Antonio",
                "San Esteban",
                "San Felipe",
                "Santa Maria",
                "Santo Domingo",
                "Valparaiso",
                "Villa Alemana",
                "Vina del Mar",
                "Zapallar"
            ]
        }
    },
    {
        "id": 45,
        "name": "China",
        "iso3": "CHN",
        "iso2": "CN",
        "phone_code": "86",
        "capital": "Beijing",
        "currency": "CNY",
        "states": {
            "Anhui": [
                "Fengyang",
                "Guangde",
                "Liuan",
                "Ningguo",
                "Shucheng",
                "Xinchang",
                "Xuancheng"
            ],
            "Anhui Province": [
                "Fengyang",
                "Guangde",
                "Liuan",
                "Ningguo",
                "Shucheng",
                "Xinchang",
                "Xuancheng"
            ],
            "Anhui Sheng": [
                "Fengyang",
                "Guangde",
                "Liuan",
                "Ningguo",
                "Shucheng",
                "Xinchang",
                "Xuancheng"
            ],
            "Aomen": [
                "Aomen"
            ],
            "Beijing": [
                "Beijing",
                "Changping",
                "Fangshan",
                "Huangcun",
                "Liangxiang",
                "Mentougou",
                "Shunyi",
                "Tongzhou"
            ],
            "Beijing Shi": [
                "Beijing",
                "Changping",
                "Fangshan",
                "Huangcun",
                "Liangxiang",
                "Mentougou",
                "Shunyi",
                "Tongzhou"
            ],
            "Chongqing": [
                "Beibei",
                "Chongqing",
                "Fuling",
                "Longhua",
                "Nantongkuang",
                "Wanxian",
                "Xiuma",
                "Yubei",
                "Yudong"
            ],
            "Fujian": [
                "Bantou",
                "Dongshan",
                "Fuan",
                "Fujian",
                "Fuqing",
                "Fuzhou",
                "Gantou",
                "Hanyang",
                "Jiangkou",
                "Jiaocheng",
                "Jinjiang",
                "Jinshang",
                "Longhai",
                "Longyan",
                "Luoyang",
                "Nanan",
                "Nanping",
                "Nanpu",
                "Putian",
                "Qingyang",
                "Quanzhou",
                "Rongcheng",
                "Sanming",
                "Shaowu",
                "Shima",
                "Shishi",
                "Tantou",
                "Tongshan",
                "Xiamen",
                "Xiapu",
                "Xiapu Ningde",
                "Ximei",
                "Yongan",
                "Zhangzhou",
                "Zhicheng"
            ],
            "Fujian Sheng": [
                "Bantou",
                "Dongshan",
                "Fuan",
                "Fujian",
                "Fuqing",
                "Fuzhou",
                "Gantou",
                "Hanyang",
                "Jiangkou",
                "Jiaocheng",
                "Jinjiang",
                "Jinshang",
                "Longhai",
                "Longyan",
                "Luoyang",
                "Nanan",
                "Nanping",
                "Nanpu",
                "Putian",
                "Qingyang",
                "Quanzhou",
                "Rongcheng",
                "Sanming",
                "Shaowu",
                "Shima",
                "Shishi",
                "Tantou",
                "Tongshan",
                "Xiamen",
                "Xiapu",
                "Xiapu Ningde",
                "Ximei",
                "Yongan",
                "Zhangzhou",
                "Zhicheng"
            ],
            "Gansu": [
                "Baiyin",
                "Baoji",
                "Beidao",
                "Jiayuguan",
                "Jinchang",
                "Jiuquan",
                "Lanzhou",
                "Linxia",
                "Pingliang",
                "Qincheng",
                "Wuwei",
                "Yaojie",
                "Yumen",
                "Zhangye",
                "Zhuanglang"
            ],
            "Guangdong": [
                "Anbu",
                "Chaozhou",
                "Chenghai",
                "Chuncheng",
                "Daliang",
                "Danshui",
                "Dongguan",
                "Donghai",
                "Dongli",
                "Dongzhen",
                "Ducheng",
                "Encheng",
                "Foahn",
                "Foshan",
                "Gaozhou",
                "Guangdong",
                "Guangzhou",
                "Guanjiao",
                "Haicheng",
                "Haimen",
                "Hepo",
                "Houpu",
                "Huaicheng",
                "Huanggang",
                "Huangpu",
                "Huazhou",
                "Huicheng",
                "Huizhou",
                "Humen",
                "Jiangmen",
                "Jiazi",
                "Jieshi",
                "Jieyang",
                "Lecheng",
                "Leicheng",
                "Liancheng",
                "Lianzhou",
                "Licheng",
                "Liusha",
                "Longgang",
                "Lubu",
                "Luocheng",
                "Luohu",
                "Luoyang",
                "Maba",
                "Maoming",
                "Mata",
                "Meilu",
                "Meizhou",
                "Mianchang",
                "Nanfeng",
                "Nanhai",
                "Pingshan",
                "Qingtang",
                "Qingyuan",
                "Rongcheng",
                "Sanbu",
                "Shantou",
                "Shanwei",
                "Shaoguan",
                "Shaping",
                "Shenzhen",
                "Shilong",
                "Shiqiao",
                "Shiwan",
                "Shuizhai",
                "Shunde",
                "Suicheng",
                "Taicheng",
                "Tangping",
                "Xiaolan",
                "Xinan",
                "Xingcheng",
                "Xiongzhou",
                "Xucheng",
                "Yangjiang",
                "Yingcheng",
                "Yuancheng",
                "Yuncheng",
                "Yunfu",
                "Zengcheng",
                "Zhanjiang",
                "Zhaoqing",
                "Zhilong",
                "Zhongshan",
                "Zhuhai"
            ],
            "Guangdong Sheng": [
                "Anbu",
                "Chaozhou",
                "Chenghai",
                "Chuncheng",
                "Daliang",
                "Danshui",
                "Dongguan",
                "Donghai",
                "Dongli",
                "Dongzhen",
                "Ducheng",
                "Encheng",
                "Foahn",
                "Foshan",
                "Gaozhou",
                "Guangdong",
                "Guangzhou",
                "Guanjiao",
                "Haicheng",
                "Haimen",
                "Hepo",
                "Houpu",
                "Huaicheng",
                "Huanggang",
                "Huangpu",
                "Huazhou",
                "Huicheng",
                "Huizhou",
                "Humen",
                "Jiangmen",
                "Jiazi",
                "Jieshi",
                "Jieyang",
                "Lecheng",
                "Leicheng",
                "Liancheng",
                "Lianzhou",
                "Licheng",
                "Liusha",
                "Longgang",
                "Lubu",
                "Luocheng",
                "Luohu",
                "Luoyang",
                "Maba",
                "Maoming",
                "Mata",
                "Meilu",
                "Meizhou",
                "Mianchang",
                "Nanfeng",
                "Nanhai",
                "Pingshan",
                "Qingtang",
                "Qingyuan",
                "Rongcheng",
                "Sanbu",
                "Shantou",
                "Shanwei",
                "Shaoguan",
                "Shaping",
                "Shenzhen",
                "Shilong",
                "Shiqiao",
                "Shiwan",
                "Shuizhai",
                "Shunde",
                "Suicheng",
                "Taicheng",
                "Tangping",
                "Xiaolan",
                "Xinan",
                "Xingcheng",
                "Xiongzhou",
                "Xucheng",
                "Yangjiang",
                "Yingcheng",
                "Yuancheng",
                "Yuncheng",
                "Yunfu",
                "Zengcheng",
                "Zhanjiang",
                "Zhaoqing",
                "Zhilong",
                "Zhongshan",
                "Zhuhai"
            ],
            "Guangxi": [
                "Babu",
                "Baihe",
                "Baise",
                "Beihai",
                "Binzhou",
                "Bose",
                "Fangchenggang",
                "Guicheng",
                "Guilin",
                "Guiping",
                "Jinchengjiang",
                "Jinji",
                "Laibin",
                "Lianzhou",
                "Liuzhou",
                "Luorong",
                "Matong",
                "Nandu",
                "Nanning",
                "Pingnan",
                "Pumiao",
                "Qinzhou",
                "Songhua",
                "Wuzhou",
                "Yashan",
                "Yulin"
            ],
            "Guizhou": [
                "Anshun",
                "Bijie",
                "Caohai",
                "Duyun",
                "Guiyang",
                "Kaili",
                "Liupanshui",
                "Luoyang",
                "Pingzhai",
                "Tongren",
                "Tongzi",
                "Xiaoweizhai",
                "Xingyi",
                "Zunyi"
            ],
            "Hainan": [
                "Chengmai",
                "Dingan",
                "Haikou",
                "Lingao",
                "Qiongshan",
                "Sansha ",
                "Sanya",
                "Wanning"
            ],
            "Hebei": [
                "Anping",
                "Baoding",
                "Botou",
                "Cangzhou",
                "Changli",
                "Chengde",
                "Dingzhou",
                "Fengfeng",
                "Fengrun",
                "Guye",
                "Handan",
                "Hebei",
                "Hecun",
                "Hejian",
                "Hengshui",
                "Huanghua",
                "Jingxingkuang",
                "Jinzhou",
                "Langfang",
                "Lianzhou",
                "Linshui",
                "Linxi",
                "Longyao County",
                "Nangong",
                "Pengcheng",
                "Qinhuangdao",
                "Renqiu",
                "Shahe",
                "Shijiazhuang",
                "Tangjiazhuang",
                "Tangshan",
                "Wuan",
                "Xian County",
                "Xingtai",
                "Xinji",
                "Xinle",
                "Xuanhua",
                "Zhangjiakou",
                "Zhaogezhuang",
                "Zhuozhou"
            ],
            "Heilongjiang": [
                "Acheng",
                "Anda",
                "Angangxi",
                "Baiquan",
                "Bamiantong",
                "Baoqing",
                "Baoshan",
                "Bayan",
                "Beian",
                "Binzhou",
                "Boli",
                "Chaihe",
                "Chengzihe",
                "Cuiluan",
                "Daqing",
                "Didao",
                "Dongning",
                "Fujin",
                "Fuli",
                "Fulitun",
                "Fuyu",
                "Gannan",
                "Hailin",
                "Hailun",
                "Harbin",
                "Hegang",
                "Heihe",
                "Hengshan",
                "Honggang",
                "Huanan",
                "Hulan",
                "Hulan Ergi",
                "Jiamusi",
                "Jidong",
                "Jixi",
                "Keshan",
                "Langxiang",
                "Lanxi",
                "Lingdong",
                "Linkou",
                "Lishu",
                "Longfeng",
                "Longjiang",
                "Mingshui",
                "Mishan",
                "Mudanjiang",
                "Nancha",
                "Nehe",
                "Nenjiang",
                "Nianzishan",
                "Ningan",
                "Qingan",
                "Qinggang",
                "Qiqihar",
                "Qitaihe",
                "Ranghulu",
                "Saertu",
                "Shangzhi",
                "Shanhetun",
                "Shuangcheng",
                "Shuangyashan",
                "Sifantan",
                "Suifenhe",
                "Suihua",
                "Suileng",
                "Tahe",
                "Taikang",
                "Tailai",
                "Tieli",
                "Wangkui",
                "Weihe",
                "Wuchang",
                "Xinglongzhen",
                "Xinqing",
                "Yian",
                "Yichun",
                "Yilan",
                "Youhao",
                "Zhaodong",
                "Zhaoyuan",
                "Zhaozhou"
            ],
            "Henan": [
                "Anyang",
                "Changying",
                "Dancheng",
                "Daokou",
                "Dengzhou",
                "Gongyi",
                "Gushi",
                "Hebi",
                "Huaidian",
                "Huangchuan",
                "Huangzhai",
                "Jiaozuo",
                "Jishui",
                "Kaifeng",
                "Liupen",
                "Luohe",
                "Luoyang",
                "Luyang",
                "Mengzhou",
                "Minggang",
                "Nandun",
                "Nanyang",
                "Pingdingshan",
                "Puyang",
                "Sanmenxia",
                "Shangqiu",
                "Tanghe",
                "Xiaoyi",
                "Xihua",
                "Xinxiang",
                "Xinyang",
                "Xinye",
                "Xixiang",
                "Xuanwu",
                "Xuchang",
                "Yigou",
                "Yima",
                "Yinzhuang",
                "Yunyang",
                "Yuzhou",
                "Zhecheng",
                "Zhengzhou",
                "Zhenping",
                "Zhoukou",
                "Zhumadian"
            ],
            "Hubei": [
                "Anlu",
                "Baisha",
                "Buhe",
                "Caidian",
                "Caohe",
                "Danjiangkou",
                "Daye",
                "Duobao",
                "Enshi",
                "Ezhou",
                "Fengkou",
                "Guangshui",
                "Gucheng",
                "Hanchuan",
                "Hongan",
                "Honghu",
                "Huangmei",
                "Huangpi",
                "Huangshi",
                "Huangzhou",
                "Jingmen",
                "Jingzhou",
                "Laohekou",
                "Lichuan",
                "Macheng",
                "Nanhai",
                "Nanzhang",
                "Puqi",
                "Qianjiang",
                "Qingquan",
                "Qixingtai",
                "Rongcheng",
                "Shashi",
                "Shishou",
                "Shiyan",
                "Suizhou",
                "Tianmen",
                "Tongcheng",
                "Wuhan",
                "Wuxue",
                "Xiangfan",
                "Xianning",
                "Xiantao",
                "Xiaogan",
                "Xiaoxita",
                "Xiaxindian",
                "Xihe",
                "Xinpu",
                "Xinshi",
                "Xinzhou",
                "Yichang",
                "Yicheng",
                "Yingcheng",
                "Yingzhong",
                "Zaoyang",
                "Zhengchang",
                "Zhicheng",
                "Zhifang",
                "Zhongxiang"
            ],
            "Hunan": [
                "Anjiang",
                "Anxiang",
                "Changde",
                "Changsha",
                "Chenzhou",
                "Dayong",
                "Hengyang",
                "Hongjiang",
                "Huaihua",
                "Jinshi",
                "Jishou",
                "Leiyang",
                "Lengshuijiang",
                "Lengshuitan",
                "Lianyuan",
                "Liling",
                "Liuyang",
                "Loudi",
                "Matian",
                "Nanzhou",
                "Ningxiang",
                "Qidong",
                "Qiyang",
                "Shaoyang",
                "Xiangtan",
                "Xiangxiang",
                "Xiangyin",
                "Xinhua",
                "Yiyang",
                "Yongfeng",
                "Yongzhou",
                "Yuanjiang",
                "Yueyang",
                "Zhuzhou"
            ],
            "Jiangsu": [
                "Baoying",
                "Changzhou",
                "Dachang",
                "Dafeng",
                "Danyang",
                "Dingshu",
                "Dongkan",
                "Dongtai",
                "Fengxian",
                "Gaogou",
                "Gaoyou",
                "Guiren",
                "Haian",
                "Haizhou",
                "Hede",
                "Huaicheng",
                "Huaiyin",
                "Huilong",
                "Hutang",
                "Jiangdu",
                "Jiangyan",
                "Jiangyin",
                "Jiangyuan",
                "Jianhu",
                "Jingcheng",
                "Jinsha",
                "Jintan",
                "Juegang",
                "Jurong",
                "Kunshan",
                "Lianyungang",
                "Liucheng",
                "Liyang",
                "Luodu",
                "Mudu",
                "Nanjing",
                "Nantong",
                "Pecheng",
                "Pukou",
                "Qidong",
                "Qinnan",
                "Qixia",
                "Rucheng",
                "Songling",
                "Sucheng",
                "Suicheng",
                "Suqian",
                "Suzhou",
                "Taicang",
                "Taixing",
                "Wujiang",
                "Wuxi",
                "Xiaolingwei",
                "Xiaoshi",
                "Xinan",
                "Xinpu",
                "Xuzhou",
                "Yancheng",
                "Yangshe",
                "Yangzhou",
                "Yizheng",
                "Yunhe",
                "Yunyang",
                "Yushan",
                "Zhangjiagang",
                "Zhangjiangang",
                "Zhaoyang",
                "Zhenjiang",
                "Zhongxing"
            ],
            "Jiangsu Sheng": [
                "Baoying",
                "Changzhou",
                "Dachang",
                "Dafeng",
                "Danyang",
                "Dingshu",
                "Dongkan",
                "Dongtai",
                "Fengxian",
                "Gaogou",
                "Gaoyou",
                "Guiren",
                "Haian",
                "Haizhou",
                "Hede",
                "Huaicheng",
                "Huaiyin",
                "Huilong",
                "Hutang",
                "Jiangdu",
                "Jiangyan",
                "Jiangyin",
                "Jiangyuan",
                "Jianhu",
                "Jingcheng",
                "Jinsha",
                "Jintan",
                "Juegang",
                "Jurong",
                "Kunshan",
                "Lianyungang",
                "Liucheng",
                "Liyang",
                "Luodu",
                "Mudu",
                "Nanjing",
                "Nantong",
                "Pecheng",
                "Pukou",
                "Qidong",
                "Qinnan",
                "Qixia",
                "Rucheng",
                "Songling",
                "Sucheng",
                "Suicheng",
                "Suqian",
                "Suzhou",
                "Taicang",
                "Taixing",
                "Wujiang",
                "Wuxi",
                "Xiaolingwei",
                "Xiaoshi",
                "Xinan",
                "Xinpu",
                "Xuzhou",
                "Yancheng",
                "Yangshe",
                "Yangzhou",
                "Yizheng",
                "Yunhe",
                "Yunyang",
                "Yushan",
                "Zhangjiagang",
                "Zhangjiangang",
                "Zhaoyang",
                "Zhenjiang",
                "Zhongxing"
            ],
            "Jiangxi": [
                "Fengxin",
                "Fenyi",
                "Ganzhou",
                "Jian",
                "Jiangguang",
                "Jingdezhen",
                "Jiujiang",
                "Leping",
                "Linchuan",
                "Nanchang",
                "Pingxiang",
                "Poyang",
                "Shangrao",
                "Xiangdong",
                "Xingan",
                "Xinjian",
                "Xinyu",
                "Xiongshi",
                "Yichun",
                "Yingtai",
                "Yingtan",
                "Zhangshui"
            ],
            "Jilin": [
                "Badaojiang",
                "Baicheng",
                "Baishishan",
                "Changchun",
                "Changling",
                "Chaoyang",
                "Daan",
                "Dashitou",
                "Dehui",
                "Dongchang",
                "Dongfeng",
                "Dunhua",
                "Erdaojiang",
                "Fuyu",
                "Gongzhuling",
                "Helong",
                "Hongmei",
                "Huadian",
                "Huangnihe",
                "Huinan",
                "Hunchun",
                "Jiaohe",
                "Jilin",
                "Jishu",
                "Jiutai",
                "Kaitong",
                "Kouqian",
                "Liaoyuan",
                "Linjiang",
                "Lishu",
                "Liuhe",
                "Longjing",
                "Meihekou",
                "Mingyue",
                "Nongan",
                "Panshi",
                "Pizhou",
                "Qianan",
                "Qianguo",
                "Sanchazi",
                "Shuangyang",
                "Shulan",
                "Siping",
                "Songjianghe",
                "Taonan",
                "Tumen",
                "Wangou",
                "Wangqing",
                "Xinglongshan",
                "Yanji",
                "Yantongshan",
                "Yushu",
                "Zhengjiatun",
                "Zhenlai"
            ],
            "Liaoning": [
                "Anshan",
                "Beipiao",
                "Benxi",
                "Changtu",
                "Chaoyang",
                "Dalian",
                "Dalianwan",
                "Dalinghe",
                "Dandong",
                "Dashiqiao",
                "Dongling",
                "Fengcheng",
                "Fushun",
                "Fuxin",
                "Haicheng",
                "Heishan",
                "Huanren",
                "Huludao",
                "Hushitai",
                "Jinxi",
                "Jinzhou",
                "Jiupu",
                "Kaiyuan",
                "Kuandian",
                "Langtou",
                "Liaoyang",
                "Liaozhong",
                "Lingyuan",
                "Liuerbao",
                "Lushunkou",
                "Nantai",
                "Panjin",
                "Pulandian",
                "Shenyang",
                "Sujiatun",
                "Tieling",
                "Wafangdian",
                "Xiaoshi",
                "Xifeng",
                "Xinchengxi",
                "Xingcheng",
                "Xinmin",
                "Xiongyue",
                "Xiuyan",
                "Yebaishou",
                "Yingkou",
                "Yuhong",
                "Zhuanghe"
            ],
            "Liaoning Sheng": [
                "Anshan",
                "Beipiao",
                "Benxi",
                "Changtu",
                "Chaoyang",
                "Dalian",
                "Dalianwan",
                "Dalinghe",
                "Dandong",
                "Dashiqiao",
                "Dongling",
                "Fengcheng",
                "Fushun",
                "Fuxin",
                "Haicheng",
                "Heishan",
                "Huanren",
                "Huludao",
                "Hushitai",
                "Jinxi",
                "Jinzhou",
                "Jiupu",
                "Kaiyuan",
                "Kuandian",
                "Langtou",
                "Liaoyang",
                "Liaozhong",
                "Lingyuan",
                "Liuerbao",
                "Lushunkou",
                "Nantai",
                "Panjin",
                "Pulandian",
                "Shenyang",
                "Sujiatun",
                "Tieling",
                "Wafangdian",
                "Xiaoshi",
                "Xifeng",
                "Xinchengxi",
                "Xingcheng",
                "Xinmin",
                "Xiongyue",
                "Xiuyan",
                "Yebaishou",
                "Yingkou",
                "Yuhong",
                "Zhuanghe"
            ],
            "Nei Monggol": [
                "Anshan",
                "Beipiao",
                "Benxi",
                "Changtu",
                "Chaoyang",
                "Dalian",
                "Dalianwan",
                "Dalinghe",
                "Dandong",
                "Dashiqiao",
                "Dongling",
                "Fengcheng",
                "Fushun",
                "Fuxin",
                "Haicheng",
                "Heishan",
                "Huanren",
                "Huludao",
                "Hushitai",
                "Jinxi",
                "Jinzhou",
                "Jiupu",
                "Kaiyuan",
                "Kuandian",
                "Langtou",
                "Liaoyang",
                "Liaozhong",
                "Lingyuan",
                "Liuerbao",
                "Lushunkou",
                "Nantai",
                "Panjin",
                "Pulandian",
                "Shenyang",
                "Sujiatun",
                "Tieling",
                "Wafangdian",
                "Xiaoshi",
                "Xifeng",
                "Xinchengxi",
                "Xingcheng",
                "Xinmin",
                "Xiongyue",
                "Xiuyan",
                "Yebaishou",
                "Yingkou",
                "Yuhong",
                "Zhuanghe"
            ],
            "Ningxia Hui": [
                "Anshan",
                "Beipiao",
                "Benxi",
                "Changtu",
                "Chaoyang",
                "Dalian",
                "Dalianwan",
                "Dalinghe",
                "Dandong",
                "Dashiqiao",
                "Dongling",
                "Fengcheng",
                "Fushun",
                "Fuxin",
                "Haicheng",
                "Heishan",
                "Huanren",
                "Huludao",
                "Hushitai",
                "Jinxi",
                "Jinzhou",
                "Jiupu",
                "Kaiyuan",
                "Kuandian",
                "Langtou",
                "Liaoyang",
                "Liaozhong",
                "Lingyuan",
                "Liuerbao",
                "Lushunkou",
                "Nantai",
                "Panjin",
                "Pulandian",
                "Shenyang",
                "Sujiatun",
                "Tieling",
                "Wafangdian",
                "Xiaoshi",
                "Xifeng",
                "Xinchengxi",
                "Xingcheng",
                "Xinmin",
                "Xiongyue",
                "Xiuyan",
                "Yebaishou",
                "Yingkou",
                "Yuhong",
                "Zhuanghe"
            ],
            "Qinghai": [
                "Qiatou",
                "Xining"
            ],
            "Shaanxi": [
                "Ankang",
                "Baoji",
                "Guozhen",
                "Hancheng",
                "Hanzhong",
                "Lishan",
                "Qili",
                "Tongchuan",
                "Weinan",
                "Xian",
                "Xianyang",
                "Yanan",
                "Yanliang",
                "Yulin",
                "Yuxia"
            ],
            "Shandong": [
                "Anqiu",
                "Bianzhuang",
                "Binzhou",
                "Boshan",
                "Boxing County",
                "Caocheng",
                "Changqing",
                "Chengyang",
                "Dengzhou",
                "Dezhou",
                "Dingtao",
                "Dongcun",
                "Dongdu",
                "Donge County",
                "Dongying",
                "Feicheng",
                "Fushan",
                "Gaomi",
                "Haiyang",
                "Hanting",
                "Hekou",
                "Heze",
                "Jiaonan",
                "Jiaozhou",
                "Jiehu",
                "Jimo",
                "Jinan",
                "Jining",
                "Juxian",
                "Juye",
                "Kunlun",
                "Laiwu",
                "Laiyang",
                "Laizhou",
                "Leling",
                "Liaocheng",
                "Licung",
                "Linqing",
                "Linqu",
                "Linshu",
                "Linyi",
                "Longkou",
                "Mengyin",
                "Mingshui",
                "Nanchou",
                "Nanding",
                "Nanma",
                "Ninghai",
                "Ningyang",
                "Pingdu",
                "Pingyi",
                "Pingyin",
                "Qingdao",
                "Qingzhou",
                "Qixia",
                "Qufu",
                "Rizhao",
                "Rongcheng",
                "Shancheng",
                "Shanting",
                "Shengzhuang",
                "Shenxian",
                "Shizilu",
                "Shouguang",
                "Shuiji",
                "Sishui",
                "Suozhen",
                "Taian",
                "Tancheng",
                "Taozhuang",
                "Tengzhou",
                "Weifang",
                "Weihai",
                "Wencheng",
                "Wendeng",
                "Wenshang",
                "Wudi",
                "Xiazhen",
                "Xincheng",
                "Xindian",
                "Xintai",
                "Yanggu",
                "Yangshan",
                "Yantai",
                "Yanzhou",
                "Yatou",
                "Yidu",
                "Yishui",
                "Yucheng",
                "Yuncheng",
                "Zaozhuang",
                "Zhangdian",
                "Zhangjiawa",
                "Zhangqiu",
                "Zhaocheng",
                "Zhoucheng",
                "Zhoucun",
                "Zhucheng",
                "Zhuwang",
                "Zicheng",
                "Zouping",
                "Zouxian"
            ],
            "Shandong Sheng": [
                "Anqiu",
                "Bianzhuang",
                "Binzhou",
                "Boshan",
                "Boxing County",
                "Caocheng",
                "Changqing",
                "Chengyang",
                "Dengzhou",
                "Dezhou",
                "Dingtao",
                "Dongcun",
                "Dongdu",
                "Donge County",
                "Dongying",
                "Feicheng",
                "Fushan",
                "Gaomi",
                "Haiyang",
                "Hanting",
                "Hekou",
                "Heze",
                "Jiaonan",
                "Jiaozhou",
                "Jiehu",
                "Jimo",
                "Jinan",
                "Jining",
                "Juxian",
                "Juye",
                "Kunlun",
                "Laiwu",
                "Laiyang",
                "Laizhou",
                "Leling",
                "Liaocheng",
                "Licung",
                "Linqing",
                "Linqu",
                "Linshu",
                "Linyi",
                "Longkou",
                "Mengyin",
                "Mingshui",
                "Nanchou",
                "Nanding",
                "Nanma",
                "Ninghai",
                "Ningyang",
                "Pingdu",
                "Pingyi",
                "Pingyin",
                "Qingdao",
                "Qingzhou",
                "Qixia",
                "Qufu",
                "Rizhao",
                "Rongcheng",
                "Shancheng",
                "Shanting",
                "Shengzhuang",
                "Shenxian",
                "Shizilu",
                "Shouguang",
                "Shuiji",
                "Sishui",
                "Suozhen",
                "Taian",
                "Tancheng",
                "Taozhuang",
                "Tengzhou",
                "Weifang",
                "Weihai",
                "Wencheng",
                "Wendeng",
                "Wenshang",
                "Wudi",
                "Xiazhen",
                "Xincheng",
                "Xindian",
                "Xintai",
                "Yanggu",
                "Yangshan",
                "Yantai",
                "Yanzhou",
                "Yatou",
                "Yidu",
                "Yishui",
                "Yucheng",
                "Yuncheng",
                "Zaozhuang",
                "Zhangdian",
                "Zhangjiawa",
                "Zhangqiu",
                "Zhaocheng",
                "Zhoucheng",
                "Zhoucun",
                "Zhucheng",
                "Zhuwang",
                "Zicheng",
                "Zouping",
                "Zouxian"
            ],
            "Shanghai": [
                "Jiading",
                "Minhang",
                "Shanghai",
                "Songjiang",
                "Trencin"
            ],
            "Shanxi": [
                "Changzhi",
                "Datong",
                "Houma",
                "Jiexiu",
                "Jincheng",
                "Linfen",
                "Taiyuan",
                "Xian",
                "Xinzhi",
                "Xinzhou",
                "Yangquan",
                "Yuanping",
                "Yuci",
                "Yuncheng"
            ],
            "Sichuan": [
                "Anju",
                "Baoning",
                "Chengdu",
                "Daan",
                "Dawan",
                "Daxian",
                "Deyang",
                "Dujiangyan City",
                "Guangkou",
                "Guangyuan",
                "Guihu",
                "Heyang",
                "Huayang",
                "Jiancheng",
                "Jiangyou",
                "Jijiang",
                "Leshan",
                "Linqiong",
                "Luocheng",
                "Luzhou",
                "Mianyang",
                "Nanchong",
                "Nanlong",
                "Neijiang",
                "Panzhihua",
                "Shifang",
                "Suining",
                "Taihe",
                "Tianpeng",
                "Tongchuan",
                "Xichang",
                "Xunchang",
                "Yaan",
                "Yibin",
                "Yongchang",
                "Zhonglong",
                "Zigong",
                "Ziyang"
            ],
            "Tianjin": [
                "Beichen",
                "Gangdong",
                "Hangu",
                "Jinghai",
                "Nankai",
                "Tanggu",
                "Tianjin",
                "Xianshuigu",
                "Yangcun",
                "Yangliuqing"
            ],
            "Xianggang": [
                "Guiqing",
                "Jiulong",
                "Quanwan",
                "Saigong",
                "Shatin",
                "Taipo",
                "Tuanmun",
                "Xianggang",
                "Yuanlong"
            ],
            "Xinjiang": [
                "Aksu",
                "Baijiantan",
                "Changji",
                "Dongshan",
                "Hami",
                "Hetian",
                "Karamay",
                "Kashi",
                "Korla",
                "Kuche",
                "Kuytun",
                "Shache",
                "Shihezi",
                "Shuimogou",
                "Toutunhe",
                "Urumqi",
                "Yining"
            ],
            "Xizang": [
                "Lasa"
            ],
            "Yunnan": [
                "Dali",
                "Gejiu",
                "Haikou",
                "Heilin",
                "Jinma",
                "Kaihua",
                "Kaiyuan",
                "Kunming",
                "Lianran",
                "Longquan",
                "Lucheng",
                "Mabai",
                "Majie",
                "Miyang",
                "Qujing",
                "Rongcheng",
                "Simao",
                "Wufeng",
                "Yunjinghong",
                "Yuxi Municipal",
                "Zhaotong",
                "Zhenhai",
                "Zhongshu",
                "Zhoucheng",
                "Zhuocheng"
            ],
            "Zhejiang": [
                "Aojiang",
                "Choucheng",
                "Cixi",
                "Daqiao",
                "Deqing",
                "Dinghai",
                "Dongyang",
                "fenghua",
                "Fuyang",
                "Haining",
                "Haiyan",
                "Hangzhou",
                "Huangyan",
                "Hushan",
                "Huzhou",
                "Jiaojiang",
                "jiashan",
                "Jiaxing",
                "Jinhua",
                "Jinxiang",
                "Kaihua",
                "Kunyang",
                "Lanxi",
                "Linan City",
                "Linhai",
                "Linping",
                "Lishui",
                "Liushi",
                "Ningbo",
                "Ninghai",
                "Pinghu",
                "Quzhou",
                "Ruian",
                "Shangyu",
                "Shaoxing",
                "Shenjiamen",
                "Taizhou City",
                "Tonglu",
                "Wenling",
                "Wenzhou",
                "Wuning",
                "Wuyi",
                "Xianju",
                "Xiaoshan",
                "Xiashi",
                "Xushan",
                "Yiwu",
                "Yongkang",
                "Yueqing",
                "Yuhuan",
                "Yuyao",
                "Zhejiang",
                "Zhenhai",
                "Zhicheng",
                "Zhuji"
            ],
            "Zhejiang Sheng": [
                "Aojiang",
                "Choucheng",
                "Cixi",
                "Daqiao",
                "Deqing",
                "Dinghai",
                "Dongyang",
                "fenghua",
                "Fuyang",
                "Haining",
                "Haiyan",
                "Hangzhou",
                "Huangyan",
                "Hushan",
                "Huzhou",
                "Jiaojiang",
                "jiashan",
                "Jiaxing",
                "Jinhua",
                "Jinxiang",
                "Kaihua",
                "Kunyang",
                "Lanxi",
                "Linan City",
                "Linhai",
                "Linping",
                "Lishui",
                "Liushi",
                "Ningbo",
                "Ninghai",
                "Pinghu",
                "Quzhou",
                "Ruian",
                "Shangyu",
                "Shaoxing",
                "Shenjiamen",
                "Taizhou City",
                "Tonglu",
                "Wenling",
                "Wenzhou",
                "Wuning",
                "Wuyi",
                "Xianju",
                "Xiaoshan",
                "Xiashi",
                "Xushan",
                "Yiwu",
                "Yongkang",
                "Yueqing",
                "Yuhuan",
                "Yuyao",
                "Zhejiang",
                "Zhenhai",
                "Zhicheng",
                "Zhuji"
            ]
        }
    },
    {
        "id": 46,
        "name": "Christmas Island",
        "iso3": "CXR",
        "iso2": "CX",
        "phone_code": "61",
        "capital": "Flying Fish Cove",
        "currency": "AUD",
        "states": {
            "Christmas Island": [
                "Aojiang",
                "Choucheng",
                "Cixi",
                "Daqiao",
                "Deqing",
                "Dinghai",
                "Dongyang",
                "fenghua",
                "Fuyang",
                "Haining",
                "Haiyan",
                "Hangzhou",
                "Huangyan",
                "Hushan",
                "Huzhou",
                "Jiaojiang",
                "jiashan",
                "Jiaxing",
                "Jinhua",
                "Jinxiang",
                "Kaihua",
                "Kunyang",
                "Lanxi",
                "Linan City",
                "Linhai",
                "Linping",
                "Lishui",
                "Liushi",
                "Ningbo",
                "Ninghai",
                "Pinghu",
                "Quzhou",
                "Ruian",
                "Shangyu",
                "Shaoxing",
                "Shenjiamen",
                "Taizhou City",
                "Tonglu",
                "Wenling",
                "Wenzhou",
                "Wuning",
                "Wuyi",
                "Xianju",
                "Xiaoshan",
                "Xiashi",
                "Xushan",
                "Yiwu",
                "Yongkang",
                "Yueqing",
                "Yuhuan",
                "Yuyao",
                "Zhejiang",
                "Zhenhai",
                "Zhicheng",
                "Zhuji"
            ]
        }
    },
    {
        "id": 47,
        "name": "Cocos (Keeling) Islands",
        "iso3": "CCK",
        "iso2": "CC",
        "phone_code": "61",
        "capital": "West Island",
        "currency": "AUD",
        "states": {
            "Cocos (Keeling) Islands": [
                "Aojiang",
                "Choucheng",
                "Cixi",
                "Daqiao",
                "Deqing",
                "Dinghai",
                "Dongyang",
                "fenghua",
                "Fuyang",
                "Haining",
                "Haiyan",
                "Hangzhou",
                "Huangyan",
                "Hushan",
                "Huzhou",
                "Jiaojiang",
                "jiashan",
                "Jiaxing",
                "Jinhua",
                "Jinxiang",
                "Kaihua",
                "Kunyang",
                "Lanxi",
                "Linan City",
                "Linhai",
                "Linping",
                "Lishui",
                "Liushi",
                "Ningbo",
                "Ninghai",
                "Pinghu",
                "Quzhou",
                "Ruian",
                "Shangyu",
                "Shaoxing",
                "Shenjiamen",
                "Taizhou City",
                "Tonglu",
                "Wenling",
                "Wenzhou",
                "Wuning",
                "Wuyi",
                "Xianju",
                "Xiaoshan",
                "Xiashi",
                "Xushan",
                "Yiwu",
                "Yongkang",
                "Yueqing",
                "Yuhuan",
                "Yuyao",
                "Zhejiang",
                "Zhenhai",
                "Zhicheng",
                "Zhuji"
            ]
        }
    },
    {
        "id": 48,
        "name": "Colombia",
        "iso3": "COL",
        "iso2": "CO",
        "phone_code": "57",
        "capital": "Bogota",
        "currency": "COP",
        "states": {
            "Amazonas": [
                "Leticia",
                "Puerto Narino"
            ],
            "Antioquia": [
                "Abejorral",
                "Abriaqui",
                "Alejandria",
                "Amaga",
                "Amalfi",
                "Andes",
                "Angelopolis",
                "Angostura",
                "Anori",
                "Antioquia",
                "Anza",
                "Apartado",
                "Arboletes",
                "Argelia",
                "Armenia",
                "Barbosa",
                "Bello",
                "Belmira",
                "Betania",
                "Betulia",
                "Bolivar",
                "Briceno",
                "Buritica",
                "Caceres",
                "Caicedo",
                "Caldas",
                "Campamento",
                "Canasgordas",
                "Caracoli",
                "Caramanta",
                "Carepa",
                "Carmen de Viboral",
                "Carolina",
                "Caucasia",
                "Chigorodo",
                "Cisneros",
                "Cocorna",
                "Concepcion",
                "Concordia",
                "Copacabana",
                "Dabeiba",
                "Don Matias",
                "Ebejico",
                "El Bagre",
                "Entrerrios",
                "Envigado",
                "Fredonia",
                "Frontino",
                "Giraldo",
                "Girardota",
                "Gomez Plata",
                "Granada",
                "Guadalupe",
                "Guarne",
                "Guatape",
                "Heliconia",
                "Hispania",
                "Itagui",
                "Ituango",
                "Jardin",
                "Jerico",
                "La Ceja",
                "La Estrella",
                "La Pintada",
                "La Union",
                "Liborina",
                "Maceo",
                "Marinilla",
                "Medellin",
                "Montebello",
                "Murindo",
                "Mutata",
                "Narino",
                "Nechi",
                "Necocli",
                "Olaya",
                "Penol",
                "Peque",
                "Pueblorrico",
                "Puerto Berrio",
                "Puerto Nare",
                "Puerto Triunfo",
                "Remedios",
                "Retiro",
                "Rionegro",
                "Sabanalarga",
                "Sabaneta",
                "Salgar",
                "San Andres",
                "San Carlos",
                "San Francisco",
                "San Jeronimo",
                "San Jose de la Montana",
                "San Juan de Uraba",
                "San Luis",
                "San Pedro",
                "San Pedro de Uraba",
                "San Rafael",
                "San Roque",
                "San Vicente",
                "Santa Barbara",
                "Santa Rosa de Osos",
                "Santo Domingo",
                "Santuario",
                "Segovia",
                "Sonson",
                "Sopetran",
                "Tamesis",
                "Taraza",
                "Tarso",
                "Titiribi",
                "Toledo",
                "Turbo",
                "Uramita",
                "Urrao",
                "Valdivia",
                "Valparaiso",
                "Vegachi",
                "Venecia",
                "Vigia del Fuerte",
                "Yali",
                "Yarumal",
                "Yolombo",
                "Yondo",
                "Zaragoza"
            ],
            "Arauca": [
                "Arauca",
                "Arauquita",
                "Cravo Norte",
                "Fortul",
                "Puerto Rondon",
                "Saravena",
                "Tame"
            ],
            "Atlantico": [
                "Baranoa",
                "Barranquilla",
                "Campo de la Cruz",
                "Candelaria",
                "Galapa",
                "Juan de Acosta",
                "Luruaco",
                "Malambo",
                "Manati",
                "Palmar de Varela",
                "Piojo",
                "Polo Nuevo",
                "Ponedera",
                "Puerto Colombia",
                "Repelon",
                "Sabanagrande",
                "Sabanalarga",
                "Santa Lucia",
                "Santo Tomas",
                "Soledad",
                "Suan",
                "Tubara",
                "Usiacuri"
            ],
            "Bogota": [
                "Bogota"
            ],
            "Bolivar": [
                "Achi",
                "Altos del Rosario",
                "Arenal",
                "Arjona",
                "Arroyohondo",
                "Barranco de Loba",
                "Calamar",
                "Cantagallo",
                "Cartagena",
                "Cicuco",
                "Clemencia",
                "Cordoba",
                "El Carmen de Bolivar",
                "El Guamo",
                "El Penon",
                "Hatillo de Loba",
                "Magangue",
                "Mahates",
                "Margarita",
                "Maria la Baja",
                "Mompos",
                "Montecristo",
                "Morales",
                "Pinillos",
                "Regidor",
                "Rio Viejo",
                "San Cristobal",
                "San Estanislao",
                "San Fernando",
                "San Jacinto",
                "San Jacinto del Cauca",
                "San Juan Nepomuceno",
                "San Martin de Loba",
                "San Pablo",
                "Santa Catalina",
                "Santa Rosa",
                "Santa Rosa del Sur",
                "Simiti",
                "Soplaviento",
                "Talaigua Nuevo",
                "Tiquisio",
                "Turbaco",
                "Turbana",
                "Villanueva",
                "Zambrano"
            ],
            "Boyaca": [
                "Almeida",
                "Aquitania",
                "Arcabuco",
                "Belen",
                "Berbeo",
                "Beteitiva",
                "Boavita",
                "Boyaca",
                "Briceno",
                "Buenavista",
                "Busbanza",
                "Caldas",
                "Campohermoso",
                "Cerinza",
                "Chinavita",
                "Chiquinquira",
                "Chiquiza",
                "Chiscas",
                "Chita",
                "Chitaraque",
                "Chivata",
                "Chivor",
                "Cienega",
                "Combita",
                "Coper",
                "Corrales",
                "Covarachia",
                "Cubara",
                "Cucaita",
                "Cuitiva",
                "Duitama",
                "El Cocuy",
                "El Espino",
                "Firavitoba",
                "Floresta",
                "Gachantiva",
                "Gameza",
                "Garagoa",
                "Guacamayas",
                "Guateque",
                "Guayata",
                "Guican",
                "Iza",
                "Jenesano",
                "Jerico",
                "La Capilla",
                "La Uvita",
                "La Victoria",
                "Labranzagrande",
                "Leiva",
                "Macanal",
                "Maripi",
                "Miraflores",
                "Mongua",
                "Mongui",
                "Moniquira",
                "Motavita",
                "Muzo",
                "Nobsa",
                "Nuevo Colon",
                "Oicata",
                "Otanche",
                "Pachavita",
                "Paez",
                "Paipa",
                "Pajarito",
                "Panqueba",
                "Pauna",
                "Paya",
                "Paz del Rio",
                "Pesca",
                "Pisba",
                "Puerto Boyaca",
                "Quipama",
                "Ramiriqui",
                "Raquira",
                "Rondon",
                "Saboya",
                "Sachica",
                "Samaca",
                "San Eduardo",
                "San Jose de Pare",
                "San Luis de Gaceno",
                "San Mateo",
                "San Miguel de Sema",
                "San Pablo de Borbur",
                "Santa Maria",
                "Santa Rosa de Viterbo",
                "Santa Sofia",
                "Santana",
                "Sativanorte",
                "Sativasur",
                "Siachoque",
                "Soata",
                "Socha",
                "Socota",
                "Sogamoso",
                "Somondoco",
                "Sora",
                "Soraca",
                "Sotaquira",
                "Susacon",
                "Sutamarchan",
                "Sutatenza",
                "Tasco",
                "Tenza",
                "Tibana",
                "Tibasosa",
                "Tinjaca",
                "Tipacoque",
                "Toca",
                "Togui",
                "Topaga",
                "Tota",
                "Tunja",
                "Tunungua",
                "Turmeque",
                "Tuta",
                "Tutasa",
                "Umbita",
                "Ventaquemada",
                "Viracacha",
                "Zetaquira"
            ],
            "Caldas": [
                "Aguadas",
                "Anserma",
                "Aranzazu",
                "Belalcazar",
                "Chinchina",
                "Filadelfia",
                "La Dorada",
                "La Merced",
                "Manizales",
                "Manzanares",
                "Marmato",
                "Marquetalia",
                "Marulanda",
                "Neira",
                "Norcasia",
                "Pacora",
                "Palestina",
                "Pensilvania",
                "Riosucio",
                "Risaralda",
                "Salamina",
                "Samana",
                "San Jose",
                "Supia",
                "Victoria",
                "Villamaria",
                "Viterbo"
            ],
            "Caqueta": [
                "Albania",
                "Belen Andaquies",
                "Cartagena del Chaira",
                "Curillo",
                "El Doncello",
                "El Paujil",
                "Florencia",
                "La Montanita",
                "Milan",
                "Morelia",
                "Puerto Rico",
                "San Jose de Fragua",
                "San Vicente del Caguan",
                "Solano",
                "Solita",
                "Valparaiso"
            ],
            "Casanare": [
                "Aguazul",
                "Chameza",
                "Hato Corozal",
                "La Salina",
                "Mani",
                "Monterrey",
                "Nunchia",
                "Orocue",
                "Paz de Ariporo",
                "Pore",
                "Recetor",
                "Sabanalarga",
                "Sacama",
                "San Luis de Palenque",
                "Tamara",
                "Tauramena",
                "Trinidad",
                "Villanueva",
                "Yopal"
            ],
            "Cauca": [
                "Almaguer",
                "Argelia",
                "Balboa",
                "Bolivar",
                "Buenos Aires",
                "Cajibio",
                "Caldono",
                "Caloto",
                "Corinto",
                "El Bordo",
                "El Tambo",
                "Florencia",
                "Guapi",
                "Inza",
                "Jambalo",
                "La Sierra",
                "La Vega",
                "Lopez",
                "Mercaderes",
                "Miranda",
                "Morales",
                "Padilla",
                "Paez",
                "Piamonte",
                "Piendamo",
                "Popayan",
                "Puerto Tejada",
                "Purace",
                "Rosas",
                "San Sebastian",
                "Santa Rosa",
                "Santander de Quilichao",
                "Silvia",
                "Sotara",
                "Suarez",
                "Sucre",
                "Timbio",
                "Timbiqui",
                "Toribio",
                "Totoro",
                "Villa Rica"
            ],
            "Cesar": [
                "Aguachica",
                "Agustin Codazzi",
                "Astrea",
                "Becerril",
                "Bosconia",
                "Chimichagua",
                "Chiriguana",
                "Curumani",
                "El Copey",
                "El Paso",
                "Gamarra",
                "Gonzalez",
                "La Gloria",
                "La Jagua Ibirico",
                "Manaure",
                "Pailitas",
                "Pelaya",
                "Pueblo Bello",
                "Rio de Oro",
                "Robles la Paz",
                "San Alberto",
                "San Diego",
                "San Martin",
                "Tamalameque",
                "Valledupar"
            ],
            "Choco": [
                "Acandi",
                "Alto Baudo",
                "Atrato",
                "Bagado",
                "Bahia Solano",
                "Bajo Baudo",
                "Bojaya",
                "Canton de San Pablo",
                "Carmen del Darien",
                "Certegui",
                "Condoto",
                "El Carmen",
                "Istmina",
                "Jurado",
                "Litoral del San Juan",
                "Lloro",
                "Medio Atrato",
                "Medio Baudo",
                "Medio San Juan",
                "Novita",
                "Nuqui",
                "Quibdo",
                "Rio Iro",
                "Rio Quito",
                "Riosucio",
                "San Jose del Palmar",
                "Sipi",
                "Tado",
                "Unguia",
                "Union Panamericana"
            ],
            "Cordoba": [
                "Ayapel",
                "Buenavista",
                "Canalete",
                "Cerete",
                "Chima",
                "Chinu",
                "Cienaga de Oro",
                "Cotorra",
                "La Apartada",
                "Lorica",
                "Los Cordobas",
                "Momil",
                "Monitos",
                "Montelibano",
                "Monteria",
                "Planeta Rica",
                "Pueblo Nuevo",
                "Puerto Escondido",
                "Puerto Libertador",
                "Purisima",
                "Sahagun",
                "San Andres Sotavento",
                "San Antero",
                "San Bernardo Viento",
                "San Carlos",
                "San Pelayo",
                "Tierralta",
                "Valencia"
            ],
            "Cundinamarca": [
                "Agua de Dios",
                "Alban",
                "Anapoima",
                "Anolaima",
                "Arbelaez",
                "Beltran",
                "Bituima",
                "Bojaca",
                "Cabrera",
                "Cachipay",
                "Cajica",
                "Caparrapi",
                "Caqueza",
                "Carmen de Carupa",
                "Chaguani",
                "Chia",
                "Chipaque",
                "Choachi",
                "Choconta",
                "Cogua",
                "Cota",
                "Cucunuba",
                "El Colegio",
                "El Penon",
                "El Rosal",
                "Facatativa",
                "Fomeque",
                "Fosca",
                "Funza",
                "Fuquene",
                "Fusagasuga",
                "Gachala",
                "Gachancipa",
                "Gacheta",
                "Gama",
                "Girardot",
                "Granada",
                "Guacheta",
                "Guaduas",
                "Guasca",
                "Guataqui",
                "Guatavita",
                "Guayabal de Siquima",
                "Guayabetal",
                "Gutierrez",
                "Jerusalen",
                "Junin",
                "La Calera",
                "La Mesa",
                "La Palma",
                "La Pena",
                "La Vega",
                "Lenguazaque",
                "Macheta",
                "Madrid",
                "Manta",
                "Medina",
                "Mosquera",
                "Narino",
                "Nemocon",
                "Nilo",
                "Nimaima",
                "Nocaima",
                "Ospina Perez",
                "Pacho",
                "Paime",
                "Pandi",
                "Paratebueno",
                "Pasca",
                "Puerto Salgar",
                "Puli",
                "Quebradanegra",
                "Quetame",
                "Quipile",
                "Rafael Reyes",
                "Ricaurte",
                "San Antonio del Tequendama",
                "San Bernardo",
                "San Cayetano",
                "San Francisco",
                "San Juan de Rioseco",
                "Sasaima",
                "Sesquile",
                "Sibate",
                "Silvania",
                "Simijaca",
                "Soacha",
                "Sopo",
                "Subachoque",
                "Suesca",
                "Supata",
                "Susa",
                "Sutatausa",
                "Tabio",
                "Tausa",
                "Tena",
                "Tenjo",
                "Tibacuy",
                "Tibirita",
                "Tocaima",
                "Tocancipa",
                "Topaipi",
                "Ubala",
                "Ubaque",
                "Ubate",
                "Une",
                "Utica",
                "Vergara",
                "Viani",
                "Villagomez",
                "Villapinzon",
                "Villeta",
                "Viota",
                "Yacopi",
                "Zipacon",
                "Zipaquira"
            ],
            "Guainia": [
                "Inirida"
            ],
            "Guaviare": [
                "Calamar",
                "El Retorno",
                "Miraflores",
                "San Jose del Guaviare"
            ],
            "Huila": [
                "Acevedo",
                "Agrado",
                "Aipe",
                "Algeciras",
                "Altamira",
                "Baraya",
                "Campoalegre",
                "Colombia",
                "Elias",
                "Garzon",
                "Gigante",
                "Guadalupe",
                "Hobo",
                "Iquira",
                "Isnos",
                "La Argentina",
                "La Plata",
                "Nataga",
                "Neiva",
                "Oporapa",
                "Paicol",
                "Palermo",
                "Palestina",
                "Pital",
                "Pitalito",
                "Rivera",
                "Saladoblanco",
                "San Agustin",
                "Santa Maria",
                "Suaza",
                "Tarqui",
                "Tello",
                "Teruel",
                "Tesalia",
                "Timana",
                "Villavieja",
                "Yaguara"
            ],
            "La Guajira": [
                "Acevedo",
                "Agrado",
                "Aipe",
                "Algeciras",
                "Altamira",
                "Baraya",
                "Campoalegre",
                "Colombia",
                "Elias",
                "Garzon",
                "Gigante",
                "Guadalupe",
                "Hobo",
                "Iquira",
                "Isnos",
                "La Argentina",
                "La Plata",
                "Nataga",
                "Neiva",
                "Oporapa",
                "Paicol",
                "Palermo",
                "Palestina",
                "Pital",
                "Pitalito",
                "Rivera",
                "Saladoblanco",
                "San Agustin",
                "Santa Maria",
                "Suaza",
                "Tarqui",
                "Tello",
                "Teruel",
                "Tesalia",
                "Timana",
                "Villavieja",
                "Yaguara"
            ],
            "Magdalena": [
                "Algarrobo",
                "Aracataca",
                "Ariguani",
                "Cerro San Antonio",
                "Chivolo",
                "Cienaga",
                "Concordia",
                "El Banco",
                "El Pinon",
                "El Reten",
                "Fundacion",
                "Guamal",
                "Nueva Granada",
                "Pedraza",
                "Pijino del Carmen",
                "Pivijay",
                "Plato",
                "Puebloviejo",
                "Remolino",
                "Sabanas de San Angel",
                "Salamina",
                "San Sebastian",
                "San Zenon",
                "Santa Ana",
                "Santa Barbara de Pinto",
                "Santa Marta",
                "Sitionuevo",
                "Tenerife",
                "Zapayan",
                "Zona Bananera"
            ],
            "Meta": [
                "Acacias",
                "Barranca de Upia",
                "Cabuyaro",
                "Castilla la Nueva",
                "Cubarral",
                "Cumaral",
                "El Calvario",
                "El Castillo",
                "El Dorado",
                "Fuente de Oro",
                "Granada",
                "Guamal",
                "La Macarena",
                "La Uribe",
                "Lejanias",
                "Mapiripan",
                "Mesetas",
                "Puerto Concordia",
                "Puerto Gaitan",
                "Puerto Lleras",
                "Puerto Lopez",
                "Puerto Rico",
                "Restrepo",
                "San Carlos Guaroa",
                "San Juan de Arama",
                "San Juanito",
                "San Martin",
                "Villavicencio",
                "Vista Hermosa"
            ],
            "Narino": [
                "Alban",
                "Aldana",
                "Ancuya",
                "Arboleda",
                "Barbacoas",
                "Belen",
                "Buesaco",
                "Chachagui",
                "Colon",
                "Consaca",
                "Contadero",
                "Cordoba",
                "Cuaspud",
                "Cumbal",
                "Cumbitara",
                "El Charco",
                "El Penol",
                "El Rosario",
                "El Tablon",
                "El Tambo",
                "Funes",
                "Guachucal",
                "Guaitarilla",
                "Gualmatan",
                "Iles",
                "Imues",
                "Ipiales",
                "La Cruz",
                "La Florida",
                "La Llanada",
                "La Tola",
                "La Union",
                "Leiva",
                "Linares",
                "Los Andes",
                "Magui",
                "Mallama",
                "Mosquera",
                "Narino",
                "Olaya Herrera",
                "Ospina",
                "Pasto",
                "Pizarro",
                "Policarpa",
                "Potosi",
                "Providencia",
                "Puerres",
                "Pupiales",
                "Ricaurte",
                "Roberto Payan",
                "Samaniego",
                "San Bernardo",
                "San Lorenzo",
                "San Pablo",
                "San Pedro de Cartago",
                "Sandona",
                "Santa Barbara",
                "Santacruz",
                "Sapuyes",
                "Taminango",
                "Tangua",
                "Tumaco",
                "Tuquerres",
                "Yacuanquer"
            ],
            "Norte de Santander": [
                "Alban",
                "Aldana",
                "Ancuya",
                "Arboleda",
                "Barbacoas",
                "Belen",
                "Buesaco",
                "Chachagui",
                "Colon",
                "Consaca",
                "Contadero",
                "Cordoba",
                "Cuaspud",
                "Cumbal",
                "Cumbitara",
                "El Charco",
                "El Penol",
                "El Rosario",
                "El Tablon",
                "El Tambo",
                "Funes",
                "Guachucal",
                "Guaitarilla",
                "Gualmatan",
                "Iles",
                "Imues",
                "Ipiales",
                "La Cruz",
                "La Florida",
                "La Llanada",
                "La Tola",
                "La Union",
                "Leiva",
                "Linares",
                "Los Andes",
                "Magui",
                "Mallama",
                "Mosquera",
                "Narino",
                "Olaya Herrera",
                "Ospina",
                "Pasto",
                "Pizarro",
                "Policarpa",
                "Potosi",
                "Providencia",
                "Puerres",
                "Pupiales",
                "Ricaurte",
                "Roberto Payan",
                "Samaniego",
                "San Bernardo",
                "San Lorenzo",
                "San Pablo",
                "San Pedro de Cartago",
                "Sandona",
                "Santa Barbara",
                "Santacruz",
                "Sapuyes",
                "Taminango",
                "Tangua",
                "Tumaco",
                "Tuquerres",
                "Yacuanquer"
            ],
            "Putumayo": [
                "Colon",
                "Mocoa",
                "Orito",
                "Puerto Asis",
                "Puerto Caycedo",
                "Puerto Guzman",
                "Puerto Leguizamo",
                "San Francisco",
                "San Miguel",
                "Santiago",
                "Sibundoy",
                "Valle del Guamuez",
                "Villagarzon"
            ],
            "Quindio": [
                "Armenia",
                "Buenavista",
                "Calarca",
                "Circasia",
                "Cordoba",
                "Filandia",
                "Genova",
                "La Tebaida",
                "Montenegro",
                "Pijao",
                "Quimbaya",
                "Salento"
            ],
            "Risaralda": [
                "Apia",
                "Balboa",
                "Belen de Umbria",
                "Dos Quebradas",
                "Guatica",
                "La Celia",
                "La Virginia",
                "Marsella",
                "Mistrato",
                "Pereira",
                "Pueblo Rico",
                "Quinchia",
                "Santa Rosa de Cabal",
                "Santuario"
            ],
            "San Andres y Providencia": [
                "Apia",
                "Balboa",
                "Belen de Umbria",
                "Dos Quebradas",
                "Guatica",
                "La Celia",
                "La Virginia",
                "Marsella",
                "Mistrato",
                "Pereira",
                "Pueblo Rico",
                "Quinchia",
                "Santa Rosa de Cabal",
                "Santuario"
            ],
            "Santander": [
                "Aguada",
                "Albania",
                "Aratoca",
                "Barbosa",
                "Barichara",
                "Barrancabermeja",
                "Betulia",
                "Bolivar",
                "Bucaramanga",
                "Cabrera",
                "California",
                "Capitanejo",
                "Carcasi",
                "Cepita",
                "Cerrito",
                "Charala",
                "Charta",
                "Chima",
                "Chipata",
                "Cimitarra",
                "Concepcion",
                "Confines",
                "Contratacion",
                "Coromoro",
                "Curiti",
                "El Carmen",
                "El Guacamayo",
                "El Penon",
                "El Playon",
                "Encino",
                "Enciso",
                "Florian",
                "Floridablanca",
                "Galan",
                "Gambita",
                "Giron",
                "Guaca",
                "Guadalupe",
                "Guapota",
                "Guavata",
                "Guepsa",
                "Hato",
                "Jesus Maria",
                "Jordan",
                "La Belleza",
                "La Paz",
                "Landazuri",
                "Lebrija",
                "Los Santos",
                "Macaravita",
                "Malaga",
                "Matanza",
                "Mogotes",
                "Molagavita",
                "Ocamonte",
                "Oiba",
                "Onzaga",
                "Palmar",
                "Palmas del Socorro",
                "Paramo",
                "Piedecuesta",
                "Pinchote",
                "Puente Nacional",
                "Puerto Parra",
                "Puerto Wilches",
                "Rionegro",
                "Sabana de Torres",
                "San Andres",
                "San Benito",
                "San Gil",
                "San Joaquin",
                "San Jose de Miranda",
                "San Miguel",
                "San Vicente de Chucuri",
                "Santa Barbara",
                "Santa Helena",
                "Simacota",
                "Socorro",
                "Suaita",
                "Sucre",
                "Surata",
                "Tona",
                "Valle San Jose",
                "Velez",
                "Vetas",
                "Villanueva",
                "Zapatoca"
            ],
            "Sucre": [
                "Buenavista",
                "Caimito",
                "Chalan",
                "Coloso",
                "Corozal",
                "El Roble",
                "Galeras",
                "Guaranda",
                "La Union",
                "Los Palmitos",
                "Majagual",
                "Morroa",
                "Ovejas",
                "Palmito",
                "Sampues",
                "San Benito Abad",
                "San Juan de Betulia",
                "San Marcos",
                "San Onofre",
                "San Pedro",
                "Since",
                "Sincelejo",
                "Sucre",
                "Tolu",
                "Toluviejo"
            ],
            "Tolima": [
                "Alpujarra",
                "Alvarado",
                "Ambalema",
                "Anzoategui",
                "Ataco",
                "Cajamarca",
                "Carmen de Apicala",
                "Casabianca",
                "Chaparral",
                "Coello",
                "Coyaima",
                "Cunday",
                "Dolores",
                "Espinal",
                "Falan",
                "Flandes",
                "Fresno",
                "Guamo",
                "Guayabal",
                "Herveo",
                "Honda",
                "Ibague",
                "Icononzo",
                "Lerida",
                "Libano",
                "Mariquita",
                "Melgar",
                "Murillo",
                "Natagaima",
                "Ortega",
                "Palocabildo",
                "Piedras",
                "Planadas",
                "Prado",
                "Purificacion",
                "Rioblanco",
                "Roncesvalles",
                "Rovira",
                "Saldana",
                "San Antonio",
                "San Luis",
                "Santa Isabel",
                "Suarez",
                "Valle de San Juan",
                "Venadillo",
                "Villahermosa",
                "Villarrica"
            ],
            "Valle del Cauca": [
                "Alpujarra",
                "Alvarado",
                "Ambalema",
                "Anzoategui",
                "Ataco",
                "Cajamarca",
                "Carmen de Apicala",
                "Casabianca",
                "Chaparral",
                "Coello",
                "Coyaima",
                "Cunday",
                "Dolores",
                "Espinal",
                "Falan",
                "Flandes",
                "Fresno",
                "Guamo",
                "Guayabal",
                "Herveo",
                "Honda",
                "Ibague",
                "Icononzo",
                "Lerida",
                "Libano",
                "Mariquita",
                "Melgar",
                "Murillo",
                "Natagaima",
                "Ortega",
                "Palocabildo",
                "Piedras",
                "Planadas",
                "Prado",
                "Purificacion",
                "Rioblanco",
                "Roncesvalles",
                "Rovira",
                "Saldana",
                "San Antonio",
                "San Luis",
                "Santa Isabel",
                "Suarez",
                "Valle de San Juan",
                "Venadillo",
                "Villahermosa",
                "Villarrica"
            ],
            "Vaupes": [
                "Acaricuara",
                "Mitu",
                "Papunaua",
                "Taraira",
                "Villa Fatima",
                "Yavarate"
            ],
            "Vichada": [
                "Cumaribo",
                "La Primavera",
                "Puerto Carreno",
                "Santa Rosalia"
            ]
        }
    },
    {
        "id": 49,
        "name": "Comoros",
        "iso3": "COM",
        "iso2": "KM",
        "phone_code": "269",
        "capital": "Moroni",
        "currency": "KMF",
        "states": {
            "Mwali": [
                "Fomboni"
            ],
            "Njazidja": [
                "Mitsamiouli",
                "Moroni"
            ],
            "Nzwani": [
                "Domoni",
                "Mutsamudu"
            ]
        }
    },
    {
        "id": 50,
        "name": "Congo",
        "iso3": "COG",
        "iso2": "CG",
        "phone_code": "242",
        "capital": "Brazzaville",
        "currency": "XAF",
        "states": {
            "Bouenza": [
                "Loudima",
                "Madingou",
                "Nkayi"
            ],
            "Brazzaville": [
                "Brazzaville"
            ],
            "Cuvette": [
                "Ewo",
                "Kelle",
                "Makoua",
                "Mossaka",
                "Owando"
            ],
            "Kouilou": [
                "Loandjili",
                "Ngamaba-Mfilou",
                "Pointe Noire"
            ],
            "Lekoumou": [
                "Sibiti",
                "Zanaga"
            ],
            "Likouala": [
                "Dongou",
                "Epena",
                "Impfondo"
            ],
            "Niari": [
                "Kibangou",
                "Loubomo",
                "Matsanga",
                "Mossendjo"
            ],
            "Plateaux": [
                "Djambala",
                "Gamboma"
            ],
            "Pool": [
                "Boko",
                "Kinkala",
                "Mindouli"
            ],
            "Sangha": [
                "Ikelemba",
                "Ouesso",
                "Sembe",
                "Souanke"
            ]
        }
    },
    {
        "id": 51,
        "name": "Congo The Democratic Republic Of The",
        "iso3": "COD",
        "iso2": "CD",
        "phone_code": "243",
        "capital": "Kinshasa",
        "currency": "CDF"
    },
    {
        "id": 52,
        "name": "Cook Islands",
        "iso3": "COK",
        "iso2": "CK",
        "phone_code": "682",
        "capital": "Avarua",
        "currency": "NZD",
        "states": {
            "Aitutaki": [
                "Amuri"
            ],
            "Atiu": [
                "Atiu"
            ],
            "Mangaia": [
                "Mangaia"
            ],
            "Manihiki": [
                "Tauhunu"
            ],
            "Mauke": [
                "Mauke"
            ],
            "Mitiaro": [
                "Mitiaro"
            ],
            "Nassau": [
                "Nassau"
            ],
            "Pukapuka": [
                "Roto"
            ],
            "Rakahanga": [
                "Rakahanga"
            ],
            "Rarotonga": [
                "Avarua"
            ],
            "Tongareva": [
                "Omoka"
            ]
        }
    },
    {
        "id": 53,
        "name": "Costa Rica",
        "iso3": "CRI",
        "iso2": "CR",
        "phone_code": "506",
        "capital": "San Jose",
        "currency": "CRC",
        "states": {
            "Alajuela": [
                "Alajuela",
                "Atenas",
                "Bijagua",
                "Buenos Aires",
                "Carrillos",
                "Desemparados",
                "Dos Rios",
                "Esquipulas",
                "Florencia",
                "Fortuna",
                "Grecia",
                "Guacimo",
                "Laguna",
                "Los Chiles",
                "Mastate",
                "Naranjo",
                "Orotina",
                "Palmares",
                "Piedades Norte",
                "Pital",
                "Pocosol",
                "Quesada",
                "Rio Segundo",
                "Sabanilla",
                "San Antonio",
                "San Jose",
                "San Juan",
                "San Mateo",
                "San Pedro",
                "San Rafael",
                "San Ramon",
                "San Roque",
                "Santiago",
                "Sarchi Norte",
                "Sarchi Sur",
                "Tigra",
                "Turricares",
                "Upala",
                "Venado",
                "Zarcero"
            ],
            "Cartago": [
                "Aguacaliente",
                "Capellades",
                "Carmen",
                "Cartago",
                "Concepcion",
                "Cot",
                "Dulce Nombre",
                "El Tejar",
                "Guadalupe",
                "Juan Vinas",
                "La Suiza",
                "Orosi",
                "Pacayas",
                "Paraiso",
                "Pejibaye",
                "San Diego",
                "San Isidro",
                "San Juan",
                "San Nicolas",
                "San Rafael",
                "San Ramon",
                "Tierra Blanca",
                "Tobosi",
                "Tres Rios",
                "Tucurrique",
                "Turrialba"
            ],
            "Guanacaste": [
                "Bagaces",
                "Belen",
                "Canas",
                "Filadeldia",
                "Fortuna",
                "Hojancha",
                "Juntas",
                "La Cruz",
                "Liberia",
                "Mogote",
                "Nandayure",
                "Nicoya",
                "Samara",
                "Santa Cruz",
                "Sardinal",
                "Tilaran"
            ],
            "Heredia": [
                "Angeles",
                "Asuncion",
                "Barrantes",
                "Barva",
                "Heredia",
                "Horquetas",
                "Llorente",
                "Mercedes",
                "Puerto Viejo",
                "Rivera",
                "San Antonio",
                "San Francisco",
                "San Isidro",
                "San Joaquin",
                "San Jose",
                "San Josecito",
                "San Juan",
                "San Miguel",
                "San Pablo",
                "San Pedro",
                "San Rafael",
                "San Roque",
                "San Vicente",
                "Santa Barbara",
                "Santa Lucia",
                "Santa Rosa",
                "Santiago",
                "Santo Domingo",
                "Santo Tomas",
                "Ulloa"
            ],
            "Limon": [
                "Batan",
                "Cahuita",
                "Cariari",
                "Guacimo",
                "Guapiles",
                "Jimenez",
                "Limon",
                "Matina",
                "Pocora",
                "Rita",
                "Roxana",
                "Siquirres",
                "Sixaola",
                "Valle la Estrella"
            ],
            "Puntarenas": [
                "Buenos Aires",
                "Canoas",
                "Chacarita",
                "Corredor",
                "Esparta",
                "Espiritu Santo",
                "Golfito",
                "Guaycara",
                "Jaco",
                "La Cuesta",
                "Macacona",
                "Manzanillo",
                "Miramar",
                "Palmar",
                "Paquera",
                "Parrita",
                "Puerto Cortes",
                "Puerto Jimenez",
                "Puntarenas",
                "Quepos",
                "San Vito"
            ],
            "San Jose": [
                "Buenos Aires",
                "Canoas",
                "Chacarita",
                "Corredor",
                "Esparta",
                "Espiritu Santo",
                "Golfito",
                "Guaycara",
                "Jaco",
                "La Cuesta",
                "Macacona",
                "Manzanillo",
                "Miramar",
                "Palmar",
                "Paquera",
                "Parrita",
                "Puerto Cortes",
                "Puerto Jimenez",
                "Puntarenas",
                "Quepos",
                "San Vito"
            ]
        }
    },
    {
        "id": 54,
        "name": "Cote D'Ivoire (Ivory Coast)",
        "iso3": "CIV",
        "iso2": "CI",
        "phone_code": "225",
        "capital": "Yamoussoukro",
        "currency": "XOF"
    },
    {
        "id": 55,
        "name": "Croatia (Hrvatska)",
        "iso3": "HRV",
        "iso2": "HR",
        "phone_code": "385",
        "capital": "Zagreb",
        "currency": "HRK"
    },
    {
        "id": 56,
        "name": "Cuba",
        "iso3": "CUB",
        "iso2": "CU",
        "phone_code": "53",
        "capital": "Havana",
        "currency": "CUP",
        "states": {
            "Camaguey": [
                "Camaguey",
                "Caney",
                "Carlos Manuel de Cespedes",
                "Esmeralda",
                "Florida",
                "Guaimaro",
                "Minas",
                "Nuevitas",
                "Santa Cruz del Sur",
                "Sibanicu",
                "Vertientes"
            ],
            "Ciego de Avila": [
                "Camaguey",
                "Caney",
                "Carlos Manuel de Cespedes",
                "Esmeralda",
                "Florida",
                "Guaimaro",
                "Minas",
                "Nuevitas",
                "Santa Cruz del Sur",
                "Sibanicu",
                "Vertientes"
            ],
            "Cienfuegos": [
                "Cienfuegos",
                "Cruces",
                "Cumanayagua",
                "Palmira",
                "Rodas"
            ],
            "Ciudad de la Habana": [
                "Cienfuegos",
                "Cruces",
                "Cumanayagua",
                "Palmira",
                "Rodas"
            ],
            "Granma": [
                "Bayamo",
                "Campechuela",
                "Guisa",
                "Jiguani",
                "Manzanillo",
                "Media Luna",
                "Niquero",
                "Pilon",
                "Rio Cauto",
                "Yara"
            ],
            "Guantanamo": [
                "Baracoa",
                "Guantanamo",
                "Yateras"
            ],
            "Habana": [
                "Havana"
            ],
            "Holguin": [
                "Antilla",
                "Baguanos",
                "Banes",
                "Cacocum",
                "Cauto Cristo",
                "Cueto",
                "Gibara",
                "Holguin",
                "Jobabo",
                "Moa",
                "Sagua de Tanamo"
            ],
            "Isla de la Juventud": [
                "Antilla",
                "Baguanos",
                "Banes",
                "Cacocum",
                "Cauto Cristo",
                "Cueto",
                "Gibara",
                "Holguin",
                "Jobabo",
                "Moa",
                "Sagua de Tanamo"
            ],
            "La Habana": [
                "Antilla",
                "Baguanos",
                "Banes",
                "Cacocum",
                "Cauto Cristo",
                "Cueto",
                "Gibara",
                "Holguin",
                "Jobabo",
                "Moa",
                "Sagua de Tanamo"
            ],
            "Las Tunas": [
                "Antilla",
                "Baguanos",
                "Banes",
                "Cacocum",
                "Cauto Cristo",
                "Cueto",
                "Gibara",
                "Holguin",
                "Jobabo",
                "Moa",
                "Sagua de Tanamo"
            ],
            "Matanzas": [
                "Abreus",
                "Agramonte",
                "Aguacate",
                "Aguada de Pasajeros",
                "Alacranes",
                "Bolondron",
                "Calimete",
                "Cardenas",
                "Carlos Rojas",
                "Colon",
                "Corralillo",
                "Jaguey Grande",
                "Jovellanos",
                "Juan Gualberto Gomez",
                "Los Arabos",
                "Manguito",
                "Marti",
                "Matanzas",
                "Maximo Gomez",
                "Pedro Betancourt",
                "Perico",
                "Union de Reyes",
                "Varadero"
            ],
            "Pinar del Rio": [
                "Abreus",
                "Agramonte",
                "Aguacate",
                "Aguada de Pasajeros",
                "Alacranes",
                "Bolondron",
                "Calimete",
                "Cardenas",
                "Carlos Rojas",
                "Colon",
                "Corralillo",
                "Jaguey Grande",
                "Jovellanos",
                "Juan Gualberto Gomez",
                "Los Arabos",
                "Manguito",
                "Marti",
                "Matanzas",
                "Maximo Gomez",
                "Pedro Betancourt",
                "Perico",
                "Union de Reyes",
                "Varadero"
            ],
            "Sancti Spiritus": [
                "Abreus",
                "Agramonte",
                "Aguacate",
                "Aguada de Pasajeros",
                "Alacranes",
                "Bolondron",
                "Calimete",
                "Cardenas",
                "Carlos Rojas",
                "Colon",
                "Corralillo",
                "Jaguey Grande",
                "Jovellanos",
                "Juan Gualberto Gomez",
                "Los Arabos",
                "Manguito",
                "Marti",
                "Matanzas",
                "Maximo Gomez",
                "Pedro Betancourt",
                "Perico",
                "Union de Reyes",
                "Varadero"
            ],
            "Santiago de Cuba": [
                "Abreus",
                "Agramonte",
                "Aguacate",
                "Aguada de Pasajeros",
                "Alacranes",
                "Bolondron",
                "Calimete",
                "Cardenas",
                "Carlos Rojas",
                "Colon",
                "Corralillo",
                "Jaguey Grande",
                "Jovellanos",
                "Juan Gualberto Gomez",
                "Los Arabos",
                "Manguito",
                "Marti",
                "Matanzas",
                "Maximo Gomez",
                "Pedro Betancourt",
                "Perico",
                "Union de Reyes",
                "Varadero"
            ],
            "Villa Clara": [
                "Abreus",
                "Agramonte",
                "Aguacate",
                "Aguada de Pasajeros",
                "Alacranes",
                "Bolondron",
                "Calimete",
                "Cardenas",
                "Carlos Rojas",
                "Colon",
                "Corralillo",
                "Jaguey Grande",
                "Jovellanos",
                "Juan Gualberto Gomez",
                "Los Arabos",
                "Manguito",
                "Marti",
                "Matanzas",
                "Maximo Gomez",
                "Pedro Betancourt",
                "Perico",
                "Union de Reyes",
                "Varadero"
            ]
        }
    },
    {
        "id": 57,
        "name": "Cyprus",
        "iso3": "CYP",
        "iso2": "CY",
        "phone_code": "357",
        "capital": "Nicosia",
        "currency": "EUR",
        "states": {
            "Government controlled area": [
                "Abreus",
                "Agramonte",
                "Aguacate",
                "Aguada de Pasajeros",
                "Alacranes",
                "Bolondron",
                "Calimete",
                "Cardenas",
                "Carlos Rojas",
                "Colon",
                "Corralillo",
                "Jaguey Grande",
                "Jovellanos",
                "Juan Gualberto Gomez",
                "Los Arabos",
                "Manguito",
                "Marti",
                "Matanzas",
                "Maximo Gomez",
                "Pedro Betancourt",
                "Perico",
                "Union de Reyes",
                "Varadero"
            ],
            "Limassol": [
                "Limassol"
            ],
            "Nicosia District": [
                "Limassol"
            ],
            "Paphos": [
                "Paphos"
            ],
            "Turkish controlled area": [
                "Paphos"
            ]
        }
    },
    {
        "id": 58,
        "name": "Czech Republic",
        "iso3": "CZE",
        "iso2": "CZ",
        "phone_code": "420",
        "capital": "Prague",
        "currency": "CZK",
        "states": {
            "Central Bohemian": [
                "Paphos"
            ],
            "Frycovice": [
                "Frycovice"
            ],
            "Jihocesky Kraj": [
                "Frycovice"
            ],
            "Jihochesky": [
                "Bechyne",
                "Blatna",
                "Cheske Budejovice",
                "Chesky Krumlov",
                "Dachice",
                "Jindrichuv Hradec",
                "Kaplice",
                "Milevsko",
                "Pisek",
                "Prachatice",
                "Protivin",
                "Sezimovo Usti",
                "Sobeslav",
                "Strakonice",
                "Tabor",
                "Trebon",
                "Tyn nad Vltavou",
                "Veseli nad Luzhnici",
                "Vimperk",
                "Vodnany"
            ],
            "Jihomoravsky": [
                "Adamov",
                "Blansko",
                "Boskovice",
                "Breclav",
                "Brno",
                "Buchovice",
                "Dubnany",
                "Hodonin",
                "Hrusky",
                "Hustopeche",
                "Ivanchice",
                "Kurim",
                "Kyjov",
                "Letovice",
                "Mikulov",
                "Moravsky Krumlov",
                "Namesht\\' nad Oslavou",
                "Rosice",
                "Shlapanice",
                "Slavkov u Brna",
                "Tishnov",
                "Vyshkov",
                "Znojmo"
            ],
            "Karlovarsky": [
                "Ash",
                "Bozicany",
                "Cheb",
                "Chodov",
                "Frantishkovy Lazne",
                "Horni Slavkov",
                "Karlovy Vary",
                "Kraslice",
                "Kynshperk nad Ohri",
                "Marianske Lazne",
                "Nejdek",
                "Ostrov",
                "Sokolov"
            ],
            "Klecany": [
                "Klecany"
            ],
            "Kralovehradecky": [
                "Broumov",
                "Cherveny Kostelec",
                "Cheska Skalice",
                "Chlumec nad Cidlinou",
                "Dobrushka",
                "Dvur Kralove",
                "Habartov",
                "Holice",
                "Horice",
                "Hostinne",
                "Hradec Kralove",
                "Hronov",
                "Jaromer",
                "Jichin",
                "Kostelec nad Orlici",
                "Nachod",
                "Nova Paka",
                "Nove Mesto nad Metuji",
                "Novy Bydzhov",
                "Rychnov nad Knezhnou",
                "Trebechovice pod Orebem",
                "Trutnov",
                "Tynishte nad Orlici",
                "Upice",
                "Vrchlabi"
            ],
            "Liberecky": [
                "Ceska Lipa",
                "Cheska Kamenice",
                "Cheska Lipa",
                "Chrastava",
                "Doksy",
                "Frydlant",
                "Hradek",
                "Jablonec",
                "Jilemnice",
                "Liberec",
                "Lomnice nad Popelkou",
                "Mimon",
                "Novy Bor",
                "Semily",
                "Tanvald",
                "Turnov",
                "Zhelezny Brod"
            ],
            "Lipov": [
                "Lipov"
            ],
            "Moravskoslezsky": [
                "Bilovec",
                "Bohumin",
                "Bruntal",
                "Chesky Teshin",
                "Frenshtat",
                "Frydek-Mistek",
                "Frydlant nad Ostravici",
                "Fulnek",
                "Havirov",
                "Hluchin",
                "Hradec nad Moravice",
                "Jablunkov",
                "Karvina",
                "Koprivnice",
                "Kravare",
                "Krnov",
                "Novy Jichin",
                "Odry",
                "Opava",
                "Orlova",
                "Ostrava",
                "Petrvald",
                "Pribor",
                "Rychvald",
                "Rymarov",
                "Shenov",
                "Studenka",
                "Trinec",
                "Vitkov",
                "Vratimov",
                "Vrbno pod Pradedem"
            ],
            "Olomoucky": [
                "Hranice",
                "Jesenik",
                "Kojetin",
                "Lipnik nad Becvou",
                "Litovel",
                "Mohelnice",
                "Olomouc",
                "Prerov",
                "Prostejov",
                "Shternberk",
                "Shumperk",
                "Unichov",
                "Zabreh"
            ],
            "Olomoucky Kraj": [
                "Hranice",
                "Jesenik",
                "Kojetin",
                "Lipnik nad Becvou",
                "Litovel",
                "Mohelnice",
                "Olomouc",
                "Prerov",
                "Prostejov",
                "Shternberk",
                "Shumperk",
                "Unichov",
                "Zabreh"
            ],
            "Pardubicky": [
                "Cheska Trebova",
                "Chocen",
                "Chrudim",
                "Chvaletice",
                "Hermanuv Mestec",
                "Hlinsko",
                "Lanshkroun",
                "Letohrad",
                "Litomyshl",
                "Moravska Trebova",
                "Pardubice",
                "Polichka",
                "Policka",
                "Prelouch",
                "Skutech",
                "Svitavy",
                "Usti nad Orlici",
                "Vysoke Myto",
                "Zhamberk"
            ],
            "Plzensky": [
                "Dobrany",
                "Domazhlice",
                "Horazhd\\'ovice",
                "Horshovky Tyn",
                "Kdyne",
                "Klatovy",
                "Nyrany",
                "Nyrsko",
                "Plana",
                "Plzen",
                "Preshtice",
                "Radnice",
                "Rokycany",
                "Stribro",
                "Sushice",
                "Tachov"
            ],
            "Praha": [
                "Prague",
                "Praha"
            ],
            "Rajhrad": [
                "Rajhrad"
            ],
            "Smirice": [
                "Smirice"
            ],
            "South Moravian": [
                "Smirice"
            ],
            "Straz nad Nisou": [
                "Smirice"
            ],
            "Stredochesky": [
                "Benatky nad Jizerou",
                "Beneshov",
                "Beroun",
                "Brandys nad Labem-Stara Bolesl",
                "Chaslav",
                "Chavaletice",
                "Chelakovice",
                "Chesky Brod",
                "Dobrish",
                "Horovice",
                "Kladno",
                "Kolin",
                "Kralupy nad Vltavou",
                "Kutna Hora",
                "Lysa nad Labem",
                "Melnik",
                "Mlada Boleslav",
                "Mnichovo Hradishte",
                "Neratovice",
                "Nove Strasheci",
                "Nymburk",
                "Podebrady",
                "Pribram",
                "Rakovnik",
                "Richany",
                "Rousinov",
                "Roztoky",
                "Sedlcany",
                "Slany",
                "Stochov",
                "Vlashim",
                "Zruch nad Sazavou"
            ],
            "Unicov": [
                "Unicov"
            ],
            "Ustecky": [
                "Bilina",
                "Chomutov",
                "Dechin",
                "Dubi",
                "Duchcov",
                "Jilove",
                "Jirkov",
                "Kadan",
                "Klasterec nad Ohri",
                "Krupka",
                "Litomerice",
                "Litvinov",
                "Louny",
                "Lovosice",
                "Mezibori",
                "Most",
                "Osek",
                "Podborany",
                "Roudnice",
                "Rumburk",
                "Shluknov",
                "Shteti",
                "Teplice",
                "Usti",
                "Varnsdorf",
                "Zatec"
            ],
            "Valletta": [
                "Valletta"
            ],
            "Velesin": [
                "Velesin"
            ],
            "Vysochina": [
                "Bystrice nad Pernshtejnem",
                "Chotebor",
                "Havlichkuv Brod",
                "Humpolec",
                "Jihlava",
                "Ledech",
                "Moravske Budejovice",
                "Nove Mesto na Morave",
                "Okrisky",
                "Pacov",
                "Pelhrimov",
                "Polna",
                "Svetla nad Sazavou",
                "Telch",
                "Trebich",
                "Tresht\\'",
                "Velke Mezirichi",
                "Zhd\\'ar"
            ],
            "Zlinsky": [
                "Brumov",
                "Bystrice pod Hostynem",
                "Chropyne",
                "Holeshov",
                "Hulin",
                "Kromerizh",
                "Kunovice",
                "Napajedla",
                "Otrokovice",
                "Rozhnov",
                "Roznov pod Radhostem",
                "Slavicin",
                "Slusovice",
                "Stare Mesto",
                "Strazhnice",
                "Uherske Hradishte",
                "Uhersky Brod",
                "Valashske Klobouky",
                "Valashske Mezirichi",
                "Veseli nad Moravou",
                "Vsetin",
                "Zborovice",
                "Zlin"
            ]
        }
    },
    {
        "id": 59,
        "name": "Denmark",
        "iso3": "DNK",
        "iso2": "DK",
        "phone_code": "45",
        "capital": "Copenhagen",
        "currency": "DKK",
        "states": {
            "Arhus": [
                "Aarhus",
                "Allingabro",
                "Arhus",
                "Assentoft",
                "Auning",
                "Beder",
                "Brabrand",
                "Ebeltoft",
                "Framlev",
                "Galten",
                "Grenaa",
                "Hadsten",
                "Hammel",
                "Hinnerup",
                "Hjortshoj",
                "Horning",
                "Hornslet",
                "Kolt",
                "Langa",
                "Logten",
                "Lystrup",
                "Malling",
                "Mariager",
                "Marslet",
                "Odder",
                "Randers",
                "Risskov",
                "Ronde",
                "Ry",
                "Ryomgard",
                "Sabro",
                "Silkeborg",
                "Skanderborg",
                "Skovby",
                "Soften",
                "Solbjerg",
                "Spentrup",
                "Stavtrup",
                "Stilling",
                "Svejbak",
                "Tranbjerg",
                "Trige",
                "Virklund"
            ],
            "Bornholm": [
                "Aakirkeby",
                "Allinge-Sandvig",
                "Nexo",
                "Ronne"
            ],
            "Frederiksborg": [
                "Allerod",
                "Birkerod",
                "Blovstrod",
                "Espergarde",
                "Farum",
                "Fredensborg",
                "Frederikssund",
                "Frederiksvark",
                "Ganlose",
                "Gilleleje",
                "Grasted",
                "Hellebak",
                "Helsinge",
                "Helsingor",
                "Hillerod",
                "Hornbak",
                "Horsholm",
                "Humlebak",
                "Hundested",
                "Jagerspris",
                "Kvistgaard",
                "Lillerod",
                "Liseleje",
                "Lynge",
                "Niva",
                "Nodebo",
                "Olstykke",
                "Skibby",
                "Slangerup",
                "Stavnsholt",
                "Stenlose",
                "Valby",
                "Vekso"
            ],
            "Fyn": [
                "Aarup",
                "Arslev",
                "Assens",
                "Bellinge",
                "Blommenslyst",
                "Bogense",
                "Brenderup",
                "Broby",
                "Bullerup",
                "Ejby",
                "Faaborg",
                "Glamsbjerg",
                "Haarby",
                "Hojby",
                "Kerteminde",
                "Langeskov",
                "Marstal",
                "Middelfart",
                "Munkebo",
                "Neder Holluf",
                "Norre Aaby",
                "Nyborg",
                "Odense",
                "Otterup",
                "Ringe",
                "Rudkobing",
                "Sankt Klemens",
                "Seden",
                "Sonderso",
                "Stige",
                "Strib",
                "Svendborg",
                "Thuro",
                "Tommerup",
                "Ullerslev",
                "Vindeby",
                "Vissenbjerg"
            ],
            "Hovedstaden": [
                "Ballerup",
                "Brondby",
                "Stenlose",
                "Vallensbaek"
            ],
            "Kobenhavn": [
                "Dragor",
                "Flong",
                "Gentofte",
                "Glostrup",
                "Herlev",
                "Hvidovre",
                "Ishoj",
                "Kastrup",
                "Lyngby",
                "Malov",
                "Smorumnedre",
                "Taastrup",
                "Trorod",
                "Vanlose",
                "Varlose"
            ],
            "Kobenhavns Amt": [
                "Dragor",
                "Flong",
                "Gentofte",
                "Glostrup",
                "Herlev",
                "Hvidovre",
                "Ishoj",
                "Kastrup",
                "Lyngby",
                "Malov",
                "Smorumnedre",
                "Taastrup",
                "Trorod",
                "Vanlose",
                "Varlose"
            ],
            "Kobenhavns Kommune": [
                "Dragor",
                "Flong",
                "Gentofte",
                "Glostrup",
                "Herlev",
                "Hvidovre",
                "Ishoj",
                "Kastrup",
                "Lyngby",
                "Malov",
                "Smorumnedre",
                "Taastrup",
                "Trorod",
                "Vanlose",
                "Varlose"
            ],
            "Nordjylland": [
                "Aabybro",
                "Aalborg",
                "Aars",
                "Arden",
                "Bindslev",
                "Bronderslev",
                "Brovst",
                "Dronninglund",
                "Farso",
                "Fjerritslev",
                "Frederikshavn",
                "Frejlev",
                "Gistrup",
                "Gorlose",
                "Hadsund",
                "Hals",
                "Hirtshals",
                "Hjallerup",
                "Hjorring",
                "Hobro",
                "Kas",
                "Klarup",
                "Logstor",
                "N\u00c3\u0192\u00c2\u0192\u00c3\u2020\u00c2\u2019\u00c3\u0192\u00c2\u201a\u00c3\u2020\u00c2\u2019\u00c3\u0192\u00c2\u0192\u00c3\u00a2\u00c2\u20ac\u00c2\u0161\u00c3\u0192\u00c2\u201a\u00c3\u201a\u00c2\u00b8rresundby",
                "Nibe",
                "Norresundby",
                "Pandrup",
                "Saby",
                "Sindal",
                "Skagen",
                "Skorping",
                "Storvorde",
                "Stovring",
                "Strandby",
                "Sulsted",
                "Svenstrup",
                "Tars",
                "Tranekaer",
                "Vadum",
                "Vestbjerg",
                "Vester Hassing",
                "Vodskov",
                "Vra"
            ],
            "Ribe": [
                "Ansager",
                "Billund",
                "Bramming",
                "Brorup",
                "Esbjerg",
                "Grindsted",
                "Holsted",
                "Nordby",
                "Oksbol",
                "Olgod",
                "Ribe",
                "Tjareborg",
                "Varde",
                "Vejen",
                "Vorbasse"
            ],
            "Ringkobing": [
                "Aulum",
                "Bording",
                "Brande",
                "Gjellerup Kirkeby",
                "Hammerum",
                "Harboore",
                "Herning",
                "Holstebro",
                "Hvide Sande",
                "Ikast",
                "Kibak",
                "Lemvig",
                "Lind",
                "Ringkobing",
                "Skaerbaek",
                "Skjern",
                "Snejbjerg",
                "Struer",
                "Sunds",
                "Tarm",
                "Thyboron",
                "Ulfborg",
                "Videbak",
                "Vildbjerg",
                "Vinderup"
            ],
            "Roervig": [
                "Roervig"
            ],
            "Roskilde": [
                "Bjaverskov",
                "Borup",
                "Ejby",
                "Greve Strand",
                "Gundsomagle",
                "Harlev",
                "Havdrup",
                "Hvalso",
                "Jyllinge",
                "Koge",
                "Lejre",
                "Osted",
                "Roskilde",
                "Solrod",
                "Stroby Egede",
                "Svogerslev",
                "Tune",
                "Viby",
                "Vindinge"
            ],
            "Roslev": [
                "Glyngore"
            ],
            "Sjaelland": [
                "Karise",
                "Naestved"
            ],
            "Soeborg": [
                "Soeborg"
            ],
            "Sonderjylland": [
                "Aabenraa",
                "Aarsleve",
                "Augustenborg",
                "Broager",
                "Christiansfeld",
                "Dybbol",
                "Gram",
                "Grasten",
                "Guderup",
                "Haderslev",
                "Horuphav",
                "Krusa",
                "Logumkloster",
                "Lojt Kirkeby",
                "Nordborg",
                "Padborg",
                "Rodding",
                "Rodekro",
                "Skarbak",
                "Sonderborg",
                "Starup",
                "Tinglev",
                "Toftlund",
                "Tonder",
                "Vojens"
            ],
            "Storstrom": [
                "Fakse",
                "Fakse Ladeplads",
                "Fensmark",
                "Holeby",
                "Maribo",
                "Nakskov",
                "Nastved",
                "Neder Vindinge",
                "Norre Alslev",
                "Nykobing",
                "Nyrad",
                "Orslev",
                "Prasto",
                "Rodby",
                "Rodbyhavn",
                "Ronnede",
                "Sakskobing",
                "Stege",
                "Store Heddinge",
                "Stubbekobing",
                "Sundby",
                "Vordingborg"
            ],
            "Syddanmark": [
                "Bylderup-Bov",
                "Fovling"
            ],
            "Toelloese": [
                "Toelloese"
            ],
            "Vejle": [
                "Borkop",
                "Bradstrup",
                "Brejning",
                "Egtved",
                "Fredericia",
                "Give",
                "Hedensted",
                "Horsens",
                "Jelling",
                "Juelsminde",
                "Kolding",
                "Lunderskov",
                "Snoghoj",
                "Sonder Bjert",
                "Taulov",
                "Torring",
                "Vamdrup",
                "Vejle"
            ],
            "Vestsjalland": [
                "Asnas",
                "Dianalund",
                "Forlev",
                "Frederiksberg",
                "Fuglebjerg",
                "Gorlev",
                "Haslev",
                "Holbaek",
                "Hong",
                "Horve",
                "Jyderup",
                "Kalundborg",
                "Korsor",
                "Nykobing",
                "Ringsted",
                "Skalskor",
                "Slagelse",
                "Soro",
                "Svebolle",
                "Svinninge",
                "Tollose",
                "Vipperod"
            ],
            "Viborg": [
                "Aalestrup",
                "Bjerringbro",
                "Hanstholm",
                "Hojslev",
                "Hurup",
                "Karup",
                "Kjellerup",
                "Nykobing",
                "Skive",
                "Stoholm",
                "Thisted",
                "Ulstrup",
                "Viborg"
            ]
        }
    },
    {
        "id": 60,
        "name": "Djibouti",
        "iso3": "DJI",
        "iso2": "DJ",
        "phone_code": "253",
        "capital": "Djibouti",
        "currency": "DJF",
        "states": {
            "Dikhil": [
                "Dikhil"
            ],
            "Jibuti": [
                "Jibuti"
            ],
            "Tajurah": [
                "Tajurah"
            ],
            "Ubuk": [
                "Ubuk"
            ],
            "\\'Ali Sabih": [
                "Ubuk"
            ]
        }
    },
    {
        "id": 61,
        "name": "Dominica",
        "iso3": "DMA",
        "iso2": "DM",
        "phone_code": "+1-767",
        "capital": "Roseau",
        "currency": "XCD",
        "states": {
            "Saint Andrew": [
                "Ubuk"
            ],
            "Saint David": [
                "Ubuk"
            ],
            "Saint George": [
                "Ubuk"
            ],
            "Saint John": [
                "Ubuk"
            ],
            "Saint Joseph": [
                "Ubuk"
            ],
            "Saint Luke": [
                "Ubuk"
            ],
            "Saint Mark": [
                "Ubuk"
            ],
            "Saint Patrick": [
                "Ubuk"
            ],
            "Saint Paul": [
                "Ubuk"
            ],
            "Saint Peter": [
                "Ubuk"
            ]
        }
    },
    {
        "id": 62,
        "name": "Dominican Republic",
        "iso3": "DOM",
        "iso2": "DO",
        "phone_code": "+1-809 and 1-829",
        "capital": "Santo Domingo",
        "currency": "DOP",
        "states": {
            "Azua": [
                "Azua",
                "Sabana Yegua"
            ],
            "Bahoruco": [
                "Neyba",
                "Tamayo"
            ],
            "Barahona": [
                "Barahona",
                "Cabral",
                "El Penon"
            ],
            "Dajabon": [
                "Dajabon"
            ],
            "Distrito Nacional": [
                "Dajabon"
            ],
            "Duarte": [
                "Las Guaranas",
                "Pimentel",
                "San Francisco de Macoris"
            ],
            "El Seybo": [
                "Las Guaranas",
                "Pimentel",
                "San Francisco de Macoris"
            ],
            "Elias Pina": [
                "Las Guaranas",
                "Pimentel",
                "San Francisco de Macoris"
            ],
            "Espaillat": [
                "Moca"
            ],
            "Hato Mayor": [
                "Moca"
            ],
            "Independencia": [
                "Duverge",
                "Jimani"
            ],
            "La Altagracia": [
                "Duverge",
                "Jimani"
            ],
            "La Romana": [
                "Duverge",
                "Jimani"
            ],
            "La Vega": [
                "Duverge",
                "Jimani"
            ],
            "Maria Trinidad Sanchez": [
                "Duverge",
                "Jimani"
            ],
            "Monsenor Nouel": [
                "Duverge",
                "Jimani"
            ],
            "Monte Cristi": [
                "Duverge",
                "Jimani"
            ],
            "Monte Plata": [
                "Duverge",
                "Jimani"
            ],
            "Pedernales": [
                "Pedernales"
            ],
            "Peravia": [
                "Bani",
                "Ocoa"
            ],
            "Puerto Plata": [
                "Bani",
                "Ocoa"
            ],
            "Salcedo": [
                "Salcedo"
            ],
            "Samana": [
                "Samana",
                "Sanchez"
            ],
            "San Cristobal": [
                "Samana",
                "Sanchez"
            ],
            "San Juan": [
                "Samana",
                "Sanchez"
            ],
            "San Pedro de Macoris": [
                "Samana",
                "Sanchez"
            ],
            "Sanchez Ramirez": [
                "Samana",
                "Sanchez"
            ],
            "Santiago": [
                "Santiago",
                "Tamboril",
                "Villa Bisono"
            ],
            "Santiago Rodriguez": [
                "Santiago",
                "Tamboril",
                "Villa Bisono"
            ],
            "Valverde": [
                "Esperanza",
                "Mao"
            ]
        }
    },
    {
        "id": 63,
        "name": "East Timor",
        "iso3": "TLS",
        "iso2": "TL",
        "phone_code": "670",
        "capital": "Dili",
        "currency": "USD",
        "states": {
            "Aileu": [
                "Aileu"
            ],
            "Ainaro": [
                "Ainaro"
            ],
            "Ambeno": [
                "Pante Macassar"
            ],
            "Baucau": [
                "Baucau"
            ],
            "Bobonaro": [
                "Auba",
                "Lolotoi",
                "Maliana"
            ],
            "Cova Lima": [
                "Auba",
                "Lolotoi",
                "Maliana"
            ],
            "Dili": [
                "Dare",
                "Dili",
                "Metinaro"
            ],
            "Ermera": [
                "Ermera"
            ],
            "Lautem": [
                "Lautem",
                "Los Palos"
            ],
            "Liquica": [
                "Bazartete",
                "Liquica"
            ],
            "Manatuto": [
                "Manatuto"
            ],
            "Manufahi": [
                "Same"
            ],
            "Viqueque": [
                "Viqueque"
            ]
        }
    },
    {
        "id": 64,
        "name": "Ecuador",
        "iso3": "ECU",
        "iso2": "EC",
        "phone_code": "593",
        "capital": "Quito",
        "currency": "USD",
        "states": {
            "Azuay": [
                "Cuenca",
                "Gualaceo"
            ],
            "Bolivar": [
                "Guaranda",
                "San Miguel"
            ],
            "Canar": [
                "Azogues",
                "Canar",
                "La Troncal"
            ],
            "Carchi": [
                "El Angel",
                "San Gabriel",
                "Tulcan"
            ],
            "Chimborazo": [
                "Alausi",
                "Guano",
                "Riobamba"
            ],
            "Cotopaxi": [
                "La Mana",
                "Latacunga",
                "Pujili",
                "San Miguel",
                "Saquisili"
            ],
            "El Oro": [
                "La Mana",
                "Latacunga",
                "Pujili",
                "San Miguel",
                "Saquisili"
            ],
            "Esmeraldas": [
                "Esmeraldas",
                "Muisne",
                "Rosa Zarate",
                "San Lorenzo",
                "Valdez"
            ],
            "Galapagos": [
                "Puerto Ayora",
                "Puerto Baquerizo Moreno",
                "San Cristobal"
            ],
            "Guayas": [
                "Alfredo Baquerizo Moreno",
                "Balao",
                "Balzar",
                "Colimes",
                "Coronel Mariduena",
                "Daule",
                "El Salitre",
                "El Triunfo",
                "Eloy Alfaro",
                "Guayaquil",
                "La Libertad",
                "Lomas de Sargentillo",
                "Mapasingue",
                "Milagro",
                "Naranjal",
                "Naranjito",
                "Palestina",
                "Pedro Carbo",
                "Playas",
                "Salinas",
                "Samborondon",
                "Santa Elena",
                "Santa Lucia",
                "Velasco Ibarra",
                "Yaguachi"
            ],
            "Imbabura": [
                "Atuntaqui",
                "Cotacachi",
                "Ibarra",
                "Otavalo",
                "Pimampiro"
            ],
            "Loja": [
                "Alamor",
                "Cariamanga",
                "Catacocha",
                "Catamayo",
                "Celica",
                "Loja",
                "Macara"
            ],
            "Los Rios": [
                "Alamor",
                "Cariamanga",
                "Catacocha",
                "Catamayo",
                "Celica",
                "Loja",
                "Macara"
            ],
            "Manabi": [
                "Bahia de Caraquez",
                "Calceta",
                "Chone",
                "El Carmen",
                "Jipijapa",
                "Junin",
                "Manta",
                "Montecristi",
                "Pajan",
                "Pedernales",
                "Portoviejo",
                "Rocafuerte",
                "Santa Ana",
                "Sucre",
                "Tosagua"
            ],
            "Morona Santiago": [
                "Bahia de Caraquez",
                "Calceta",
                "Chone",
                "El Carmen",
                "Jipijapa",
                "Junin",
                "Manta",
                "Montecristi",
                "Pajan",
                "Pedernales",
                "Portoviejo",
                "Rocafuerte",
                "Santa Ana",
                "Sucre",
                "Tosagua"
            ],
            "Napo": [
                "Archidona",
                "Tena"
            ],
            "Orellana": [
                "Orellana"
            ],
            "Pastaza": [
                "Puyo"
            ],
            "Pichincha": [
                "Cayambe",
                "Machachi",
                "Quito",
                "Sangolqui",
                "Santo Domingo"
            ],
            "Sucumbios": [
                "Nueva Loja",
                "Shushufindi"
            ],
            "Tungurahua": [
                "Ambato",
                "Banos",
                "Pelileo",
                "Pillaro"
            ],
            "Zamora Chinchipe": [
                "Ambato",
                "Banos",
                "Pelileo",
                "Pillaro"
            ]
        }
    },
    {
        "id": 65,
        "name": "Egypt",
        "iso3": "EGY",
        "iso2": "EG",
        "phone_code": "20",
        "capital": "Cairo",
        "currency": "EGP",
        "states": {
            "ad-Daqahliyah": [
                "Aja",
                "al-Jamaliyah",
                "al-Mansurah",
                "al-Manzilah",
                "al-Matariyah",
                "al-Ma\\'sarah",
                "as-Sinbillawayn",
                "Bahut",
                "Bilqas",
                "Dikirnis",
                "Minyat-an-Nasr",
                "Mit Gamr",
                "Shirbin",
                "Talkha"
            ],
            "al-Bahr-al-Ahmar": [
                "al-Ghardaqah",
                "al-Qusayr",
                "Ras Gharib",
                "Safaja"
            ],
            "al-Buhayrah": [
                "Abu al-Matamir",
                "Abu Hummus",
                "ad-Dilinjat",
                "al-Kawm-al-Akhdar",
                "al-Mahmudiyah",
                "ar-Rahmaniyah",
                "Buturis",
                "Damanhur",
                "Edfina",
                "Hawsh \\'Isa",
                "Idku",
                "Ityay-al-Barud",
                "Kafr Salim",
                "Kafr-ad-Dawwar",
                "Kawm Hamada",
                "Nubaria",
                "Rashid",
                "Shubra Khit",
                "Zawiyat Sidi Gazi"
            ],
            "al-Fayyum": [
                "al-Fayyum",
                "Fidimin",
                "Ibshaway",
                "Itsa",
                "Qasr Qarun",
                "Sanhur",
                "Sinnuris",
                "Tamiyah"
            ],
            "al-Gharbiyah": [
                "Abyar",
                "ad-Daljamun",
                "al-Mahallah al-Kubra",
                "as-Santah",
                "Basyun",
                "Kafr-az-Zayyat",
                "Mahallat Marhum",
                "Nisf Thani Bashbish",
                "Qutur",
                "Samannud",
                "Tanta",
                "Zifta"
            ],
            "al-Iskandariyah": [
                "Agamy",
                "al-Iskandariyah",
                "al-Maks"
            ],
            "al-Ismailiyah": [
                "al-Ismailiyah",
                "Fa\\'id",
                "Sarabiyum"
            ],
            "al-Jizah": [
                "al-Badrashayn",
                "al-Hawamidiyah",
                "al-Jizah",
                "al-Mansuriyah",
                "al-Wahat-al-Bahriyah",
                "al-\\'Ayyat",
                "as-Saff",
                "Atfih",
                "Awsim",
                "Giza",
                "Madinat Sittah Uktubar",
                "Nahya",
                "Saqqarah"
            ],
            "al-Minufiyah": [
                "al-Bajur",
                "al-Batanun",
                "ash-Shuhada",
                "Ashmun",
                "Birkat-as-Sab\\'",
                "Milij",
                "Minuf",
                "Quwaysina",
                "Shibin-al-Kawm",
                "Sirs-al-Layyanah",
                "Tala"
            ],
            "al-Minya": [
                "Abu Qurqas",
                "al-Anayim",
                "al-Fikriyah",
                "al-Minya",
                "ar-Rawdah",
                "Bani Mazar",
                "Dayr Mawas",
                "Magagah",
                "Mallawi",
                "Matay",
                "Samalut",
                "Tallah",
                "Tandah"
            ],
            "al-Qahira": [
                "al-Qahira",
                "Badr City",
                "Heliopolis"
            ],
            "al-Qalyubiyah": [
                "Abu Za\\'bal",
                "al-Khankah",
                "al-Qanatir-al-Khayriyah",
                "Banha",
                "Qalyub",
                "Shubra al-Khaymah",
                "Sibin-al-Qanatir",
                "Tukh"
            ],
            "al-Uqsur": [
                "al-Uqsur"
            ],
            "al-Wadi al-Jadid": [
                "al-Uqsur"
            ],
            "as-Suways": [
                "as-Suways"
            ],
            "ash-Sharqiyah": [
                "Abu Hammad",
                "Abu Kabir",
                "al-Ashir mir-Ramadan",
                "al-Husayniyah",
                "al-Ibrahimiyah",
                "al-Qanayat",
                "al-Qassasin",
                "al-Qurayn",
                "as-Salihiyah",
                "at-Tall-al-Kabir",
                "az-Zaqaziq",
                "Bilbays",
                "Diyarb Najm",
                "Faqus",
                "Hihya",
                "Kafr Saqr",
                "Mashtul-as-Suq",
                "Minyat-al-Qamh"
            ],
            "Aswan": [
                "an-Nasir",
                "Aswan",
                "Daraw",
                "Kawm Umbu"
            ],
            "Asyut": [
                "Abnub",
                "Abu Tij",
                "al-Badari",
                "al-Qusiyah",
                "an-Nukhaylah",
                "Asyut",
                "Bani Muhammadiyat",
                "Dayrut",
                "Dayrut-ash-Sharif",
                "Manfalut",
                "Musha",
                "Sahil Salim",
                "Sanabu",
                "Umm-al-Qusur"
            ],
            "Bani Suwayf": [
                "Abnub",
                "Abu Tij",
                "al-Badari",
                "al-Qusiyah",
                "an-Nukhaylah",
                "Asyut",
                "Bani Muhammadiyat",
                "Dayrut",
                "Dayrut-ash-Sharif",
                "Manfalut",
                "Musha",
                "Sahil Salim",
                "Sanabu",
                "Umm-al-Qusur"
            ],
            "Bur Sa\\'id": [
                "Abnub",
                "Abu Tij",
                "al-Badari",
                "al-Qusiyah",
                "an-Nukhaylah",
                "Asyut",
                "Bani Muhammadiyat",
                "Dayrut",
                "Dayrut-ash-Sharif",
                "Manfalut",
                "Musha",
                "Sahil Salim",
                "Sanabu",
                "Umm-al-Qusur"
            ],
            "Cairo": [
                "6th of October City",
                "Ataba",
                "Cairo",
                "Nasr",
                "Nasr City",
                "Obour City"
            ],
            "Dumyat": [
                "az-Zarqa",
                "Damietta",
                "Dumyat",
                "El-Zarka",
                "Faraskur",
                "Kafr Sa\\'d",
                "Kafr-al-Battikh",
                "\\'Izbat-al-Burj"
            ],
            "Kafr-ash-Shaykh": [
                "al-Burj",
                "al-Burullus",
                "al-Haddadi",
                "al-Hamul",
                "Biyala",
                "Disuq",
                "Fuwah",
                "Kafr-al-Jara\\'idah",
                "Kafr-ash-Shaykh",
                "Mutubis",
                "Qallin",
                "Sidi Salim"
            ],
            "Matruh": [
                "ad-Da\\'ba",
                "Marsa Matruh",
                "Nasr",
                "Sidi Barrani",
                "Zawiyat Shammas"
            ],
            "Muhafazat ad Daqahliyah": [
                "ad-Da\\'ba",
                "Marsa Matruh",
                "Nasr",
                "Sidi Barrani",
                "Zawiyat Shammas"
            ],
            "Muhafazat al Fayyum": [
                "ad-Da\\'ba",
                "Marsa Matruh",
                "Nasr",
                "Sidi Barrani",
                "Zawiyat Shammas"
            ],
            "Muhafazat al Gharbiyah": [
                "ad-Da\\'ba",
                "Marsa Matruh",
                "Nasr",
                "Sidi Barrani",
                "Zawiyat Shammas"
            ],
            "Muhafazat al Iskandariyah": [
                "ad-Da\\'ba",
                "Marsa Matruh",
                "Nasr",
                "Sidi Barrani",
                "Zawiyat Shammas"
            ],
            "Muhafazat al Qahirah": [
                "ad-Da\\'ba",
                "Marsa Matruh",
                "Nasr",
                "Sidi Barrani",
                "Zawiyat Shammas"
            ],
            "Qina": [
                "ad-Dabbiyah",
                "ad-Dayr",
                "al-Ballas",
                "al-Karnak",
                "al-Waqf",
                "ar-Radisiyat-al-Bahriyah",
                "Armant",
                "Asfun-al-Mata\\'inah",
                "Dandarah",
                "Dishna",
                "Farshut",
                "Hijazah",
                "Hiw",
                "Idfu",
                "Isna",
                "Kiman-al-Mata\\'inah",
                "Naj\\' Hammadi",
                "Naqadah",
                "Qift",
                "Qina",
                "Qus"
            ],
            "Sawhaj": [
                "Akhmim",
                "al-Balyana",
                "al-Manshah",
                "al-Maragah",
                "Awlad Tawq Sharq",
                "Dar-as-Salam",
                "Jirja",
                "Juhaynah",
                "Sawhaj",
                "Tahta",
                "Tima"
            ],
            "Sina al-Janubiyah": [
                "Akhmim",
                "al-Balyana",
                "al-Manshah",
                "al-Maragah",
                "Awlad Tawq Sharq",
                "Dar-as-Salam",
                "Jirja",
                "Juhaynah",
                "Sawhaj",
                "Tahta",
                "Tima"
            ],
            "Sina ash-Shamaliyah": [
                "Akhmim",
                "al-Balyana",
                "al-Manshah",
                "al-Maragah",
                "Awlad Tawq Sharq",
                "Dar-as-Salam",
                "Jirja",
                "Juhaynah",
                "Sawhaj",
                "Tahta",
                "Tima"
            ]
        }
    },
    {
        "id": 66,
        "name": "El Salvador",
        "iso3": "SLV",
        "iso2": "SV",
        "phone_code": "503",
        "capital": "San Salvador",
        "currency": "USD",
        "states": {
            "Ahuachapan": [
                "Ahuachapan",
                "Atiquizaya",
                "Concepcion de Ataco",
                "Guaymango",
                "Jujutla",
                "San Francisco Menendez",
                "Tacuba"
            ],
            "Cabanas": [
                "Ilobasco",
                "Sensuntepeque",
                "Victoria"
            ],
            "Chalatenango": [
                "Chalatenango",
                "La Palma",
                "Nueva Concepcion",
                "San Francisco Morazan"
            ],
            "Cuscatlan": [
                "Cojutepeque",
                "San Pedro Perulapan",
                "Suchitoto",
                "Tecoluca",
                "Tenancingo"
            ],
            "La Libertad": [
                "Cojutepeque",
                "San Pedro Perulapan",
                "Suchitoto",
                "Tecoluca",
                "Tenancingo"
            ],
            "La Paz": [
                "Cojutepeque",
                "San Pedro Perulapan",
                "Suchitoto",
                "Tecoluca",
                "Tenancingo"
            ],
            "La Union": [
                "Cojutepeque",
                "San Pedro Perulapan",
                "Suchitoto",
                "Tecoluca",
                "Tenancingo"
            ],
            "Morazan": [
                "Cacaopera",
                "Corinto",
                "Gotera",
                "Guatajiagua",
                "Jocoro",
                "Sociedad"
            ],
            "San Miguel": [
                "Cacaopera",
                "Corinto",
                "Gotera",
                "Guatajiagua",
                "Jocoro",
                "Sociedad"
            ],
            "San Salvador": [
                "Cacaopera",
                "Corinto",
                "Gotera",
                "Guatajiagua",
                "Jocoro",
                "Sociedad"
            ],
            "San Vicente": [
                "Cacaopera",
                "Corinto",
                "Gotera",
                "Guatajiagua",
                "Jocoro",
                "Sociedad"
            ],
            "Santa Ana": [
                "Cacaopera",
                "Corinto",
                "Gotera",
                "Guatajiagua",
                "Jocoro",
                "Sociedad"
            ],
            "Sonsonate": [
                "Acajutla",
                "Armenia",
                "Izalco",
                "Juayua",
                "Nahuizalco",
                "San Antonio del Monte",
                "San Julian",
                "Sonsonate",
                "Sonzacate"
            ],
            "Usulutan": [
                "Berlin",
                "Concepcion Batres",
                "Estanzuelas",
                "Jiquilisco",
                "Jucuapa",
                "Jucuaran",
                "Ozatlan",
                "Puerto El Triunfo",
                "San Agustin",
                "Santa Elena",
                "Santiago de Maria",
                "Usulutan"
            ]
        }
    },
    {
        "id": 67,
        "name": "Equatorial Guinea",
        "iso3": "GNQ",
        "iso2": "GQ",
        "phone_code": "240",
        "capital": "Malabo",
        "currency": "XAF",
        "states": {
            "Annobon": [
                "Pale"
            ],
            "Bioko Norte": [
                "Pale"
            ],
            "Bioko Sur": [
                "Pale"
            ],
            "Centro Sur": [
                "Pale"
            ],
            "Kie-Ntem": [
                "Ebebiyin",
                "Mikomeseng"
            ],
            "Litoral": [
                "Bata",
                "Mbini"
            ],
            "Wele-Nzas": [
                "Aconibe",
                "Anisoc",
                "Mongomo",
                "Nsok"
            ]
        }
    },
    {
        "id": 68,
        "name": "Eritrea",
        "iso3": "ERI",
        "iso2": "ER",
        "phone_code": "291",
        "capital": "Asmara",
        "currency": "ERN",
        "states": {
            "Anseba": [
                "Keren"
            ],
            "Debub": [
                "Addi Kwala",
                "Addi Ugri"
            ],
            "Debub-Keih-Bahri": [
                "Asseb",
                "Beylul",
                "Edd",
                "Mersa Fatma"
            ],
            "Gash-Barka": [
                "Ak\\'ordat",
                "Barentu",
                "Teseney"
            ],
            "Maekel": [
                "Asmara",
                "Ginda",
                "Himbirti",
                "Nefasit"
            ],
            "Semien-Keih-Bahri": [
                "Addi K\\'eyih",
                "Dek\\'emhare",
                "Mitsiwa",
                "Sen\\'afe"
            ]
        }
    },
    {
        "id": 69,
        "name": "Estonia",
        "iso3": "EST",
        "iso2": "EE",
        "phone_code": "372",
        "capital": "Tallinn",
        "currency": "EUR",
        "states": {
            "Harju": [
                "Aasmae",
                "Aaviku",
                "Aegviidu",
                "Aigrumae",
                "Aila",
                "Alavere",
                "Alliku",
                "Amari",
                "Anija",
                "Ardu",
                "Arukula",
                "Aruvalla",
                "Assaku",
                "Ellamaa",
                "Haabneeme",
                "Habaja",
                "Haiba",
                "Haljava",
                "Hara",
                "Harju-Risti",
                "Harku",
                "Harkujarve",
                "Harma",
                "Huuru",
                "Ilmandu",
                "Iru",
                "Jagala",
                "Jalgimae",
                "Jarsi",
                "Jarvekula",
                "Jogisoo",
                "Juri",
                "Kaasiku",
                "Kaberneeme",
                "Kahala",
                "Kalesi",
                "Kallavere",
                "Karjakula",
                "Karla",
                "Kasepere",
                "Kasispea",
                "Kehra",
                "Keila",
                "Keila-Joa",
                "Kelvingi",
                "Kiia",
                "Kiili",
                "Kiisa",
                "Kiiu",
                "Klooga",
                "Kloogaranna",
                "Kohatu",
                "Kolga",
                "Kolga-Aabla",
                "Kolgakula",
                "Konnu",
                "Kose",
                "Kose-Uuemoisa",
                "Kostivere",
                "Krei",
                "Kuivajoe",
                "Kumna",
                "Kurtna",
                "Kuusalu",
                "Laabi",
                "Laagri",
                "Lagedi",
                "Laitse",
                "Laulasmaa",
                "Lehetu",
                "Lehola",
                "Lehtmetsa",
                "Leppneeme",
                "Liikva",
                "Lilli",
                "Lohusalu",
                "Loksa",
                "Lokuti",
                "Loo",
                "Lubja",
                "Luige",
                "Maardu",
                "Maidla",
                "Manniku",
                "Metsakasti",
                "Metsanurme",
                "Miiduranna",
                "Munalaskme",
                "Muraste",
                "Muuga",
                "Nabala",
                "Neeme",
                "Ohtu",
                "Ojasoo",
                "Oru",
                "Padise",
                "Pae",
                "Paekna",
                "Pajupea",
                "Paldiski",
                "Palvere",
                "Parispea",
                "Patika",
                "Paunkula",
                "Peetri",
                "Peningi",
                "Perila",
                "Pikva",
                "Pillapalu",
                "Pringi",
                "Puunsi",
                "Raasiku",
                "Rae",
                "Randvere",
                "Rannamoisa",
                "Ravila",
                "Riisipere",
                "Rohuneeme",
                "Roobuka",
                "Ruila",
                "Rummu",
                "Saha",
                "Saku",
                "Saue",
                "Saula",
                "Saunja",
                "Suurpea",
                "Suurupi",
                "Tabasalu",
                "Tagadi",
                "Tagametsa",
                "Tallinn",
                "Tammneeme",
                "Tiskre",
                "Todva",
                "Turba",
                "Turisalu",
                "Tutermaa",
                "Tuula",
                "Tuulna",
                "Uksnurme",
                "Ulejoe",
                "Uuri",
                "Uuskula",
                "Vaana",
                "Vaana-Joesuu",
                "Vaida",
                "Vaidasoo",
                "Valingu",
                "Valkla",
                "Vanamoisa",
                "Vardja",
                "Vasalemma",
                "Vaskjala",
                "Vatsla",
                "Veskikula",
                "Vihasoo",
                "Viimsi",
                "Viinistu",
                "Viti"
            ],
            "Hiiu": [
                "Emmaste",
                "Jausa",
                "Kaina",
                "Kardla",
                "Korgessaare",
                "Lauka",
                "Lope",
                "Mannamaa",
                "Putkaste",
                "Suuremoisa"
            ],
            "Ida-Viru": [
                "Aa",
                "Alajoe",
                "Aseri",
                "Avinurme",
                "Edise",
                "Erra",
                "Iisaku",
                "Jarve",
                "Johvi",
                "Kahula",
                "Kiikla",
                "Kivioli",
                "Kohtla-Jarve",
                "Kohtla-Nomme",
                "Konju",
                "Kose",
                "Kuremae",
                "Kurtna",
                "Lohusuu",
                "Luganuse",
                "Maetaguse",
                "Maidla",
                "Narva",
                "Narva-Joesuu",
                "Olgina",
                "Pagari",
                "Puhajoe",
                "Purtse",
                "Pussi",
                "Rannu",
                "Saka",
                "Savala",
                "Sillamae",
                "Sinimae",
                "Soldina",
                "Sompa",
                "Sonda",
                "Tammiku",
                "Toila",
                "Tudulinna",
                "Ulvi",
                "Vaivara",
                "Varja",
                "Vasavere",
                "Voka"
            ],
            "Jarva": [
                "Ahula",
                "Aiamaa",
                "Albu",
                "Ambla",
                "Anari",
                "Anna",
                "Aravete",
                "Ervita",
                "Imavere",
                "Janeda",
                "Jarva-Jaani",
                "Jogisoo",
                "Jootme",
                "Kaalepi",
                "Kabala",
                "Kahala",
                "Karavete",
                "Karevere",
                "Karinu",
                "Kasukonna",
                "Kirna",
                "Koeru",
                "Koigi",
                "Kolu",
                "Kuksema",
                "Laupa",
                "Lehtse",
                "Lokuta",
                "Loola",
                "Muusleri",
                "Oisu",
                "Paide",
                "Painurme",
                "Peetri",
                "Poikva",
                "Reopalu",
                "Retla",
                "Roa",
                "Roosna",
                "Roosna-Alliku",
                "Sarevere",
                "Sargvere",
                "Taikse",
                "Tarbja",
                "Turi",
                "Turi-Alliku",
                "Vaatsa",
                "Vahukula",
                "Vao",
                "Viisu",
                "Villevere"
            ],
            "Jogeva": [
                "Adavere",
                "Esku",
                "Harjanurme",
                "Jogeva",
                "Kaarepere",
                "Kalana",
                "Kalme",
                "Kamari",
                "Karde",
                "Kasepaa",
                "Kassinurme",
                "Konnu",
                "Kudina",
                "Kukita",
                "Kuremaa",
                "Kurista",
                "Lahavere",
                "Laiuse",
                "Laiusevalja",
                "Leedi",
                "Lustivere",
                "Luua",
                "Maarja",
                "Mallikvere",
                "Metsakula",
                "Mohkula",
                "Mustvee",
                "Neanurme",
                "Nova",
                "Omedu",
                "Ouna",
                "Painkula",
                "Pajusi",
                "Pala",
                "Palamuse",
                "Pataste",
                "Pauastvere",
                "Pikkjarve",
                "Pikknurme",
                "Pisisaare",
                "Poltsamaa",
                "Poora",
                "Puurmani",
                "Raabise",
                "Raja",
                "Saare",
                "Sadala",
                "Sadukula",
                "Siimusti",
                "Tabivere",
                "Tahkvere",
                "Tiheda",
                "Toikvere",
                "Torma",
                "Umbusi",
                "Vagari",
                "Vaiatu",
                "Vaike-Kamari",
                "Vaimastvere",
                "Valgma",
                "Visusti",
                "Voduvere",
                "Vohmanomme",
                "Voisiku",
                "Voldi",
                "Voore",
                "Votikvere"
            ],
            "Laane": [
                "Haapsalu",
                "Hullo",
                "Joodre",
                "Kirbla",
                "Kirimae",
                "Koluvere",
                "Komsi",
                "Kullamaa",
                "Lihula",
                "Liivi",
                "Linnamae",
                "Martna",
                "Nigula",
                "Nova",
                "Palivere",
                "Palli",
                "Panga",
                "Paralepa",
                "Piirsalu",
                "Purksi",
                "Rannakula",
                "Risti",
                "Roude",
                "Sutlepa",
                "Taebla",
                "Tuudi",
                "Uuemoisa",
                "Variku",
                "Vatla",
                "Virtsu"
            ],
            "Laane-Viru": [
                "Aaspere",
                "Ama",
                "Arkna",
                "Assamalla",
                "Avanduse",
                "Avispea",
                "Ebavere",
                "Eipri",
                "Essu",
                "Haljala",
                "Hulja",
                "Imastu",
                "Inju",
                "Kadapiku",
                "Kadila",
                "Kadrina",
                "Kakumae",
                "Karitsa",
                "Karu",
                "Kasmu",
                "Kihlevere",
                "Kiku",
                "Kiltsi",
                "Kohala",
                "Kunda",
                "Kuti",
                "Laekvere",
                "Lasila",
                "Lepna",
                "Levala",
                "Liigvalla",
                "Modriku",
                "Moe",
                "Moora",
                "Muuga",
                "Napi",
                "Paasvere",
                "Pajusti",
                "Pandivere",
                "Piira",
                "Pikevere",
                "Podrangu",
                "Podruse",
                "Porkuni",
                "Rahkla",
                "Rakke",
                "Rakvere",
                "Ridakula",
                "Roela",
                "Roodevalja",
                "Saase",
                "Salda",
                "Salla",
                "Simuna",
                "Someru",
                "Taaravainu",
                "Tamsalu",
                "Tapa",
                "Torma",
                "Torremae",
                "Triigi",
                "Tudu",
                "Ubja",
                "Udriku",
                "Uhtna",
                "Ulvi",
                "Undla",
                "Ussimae",
                "Uudekula",
                "Vaekula",
                "Vahakulmu",
                "Vaiatu",
                "Vaike-Maarja",
                "Vajangu",
                "Vao",
                "Veltsi",
                "Venevere",
                "Vergi",
                "Vetiku",
                "Vihula",
                "Viitna",
                "Vinni",
                "Viru-Jaagupi",
                "Viru-Kabala",
                "Viru-Nigula",
                "Vohnja",
                "Vosu",
                "Vosupere"
            ],
            "Parnu": [
                "Ahaste",
                "Aluste",
                "Are",
                "Arumetsa",
                "Audru",
                "Eametsa",
                "Haademeeste",
                "Halinga",
                "Ikla",
                "Ilvese",
                "Jaamakula",
                "Jaarja",
                "Joesuu",
                "Joopre",
                "Kaansoo",
                "Kabli",
                "Kadjaste",
                "Kaisma",
                "Kalita",
                "Kanakula",
                "Kergu",
                "Kihlepa",
                "Kilingi-Nomme",
                "Kilksama",
                "Koima",
                "Koonga",
                "Krundikula",
                "Laadi",
                "Langerma",
                "Lavassaare",
                "Leipste",
                "Lemmetsa",
                "Lemsi",
                "Libatse",
                "Linakula",
                "Lindi",
                "Liu",
                "Lodja",
                "Lope",
                "Malda",
                "Mannikuste",
                "Massiaru",
                "Metsaaare",
                "Metsapoole",
                "Moisakula",
                "Muraka",
                "Niidu",
                "Nurme",
                "Oara",
                "Oidrema",
                "Paikuse",
                "Papsaare",
                "Parnjoe",
                "Parnu",
                "Parnu-Jaagupi",
                "Penu",
                "Piistaoja",
                "Pohara",
                "Poldeotsa",
                "Pootsi",
                "Pulli",
                "Rannametsa",
                "Ratsepa",
                "Reiu",
                "Ridalepa",
                "Rootsikula",
                "Saarde",
                "Saare",
                "Sauga",
                "Seliste",
                "Selja",
                "Seljametsa",
                "Silla",
                "Sindi",
                "Soeva",
                "Soometsa",
                "Suigu",
                "Surju",
                "Suurejoe",
                "Taali",
                "Tahkuranna",
                "Tali",
                "Tammiste",
                "Tammuru",
                "Tihemetsa",
                "Tolla",
                "Tootsi",
                "Tori",
                "Tostamaa",
                "Tousi",
                "Treimani",
                "Urge",
                "Uulu",
                "Vahenurme",
                "Vaki",
                "Vandra",
                "Varbla",
                "Veelikse",
                "Vihtra",
                "Voidu",
                "Voiste"
            ],
            "Polva": [
                "Aarna",
                "Ahja",
                "Erastvere",
                "Himma",
                "Himmaste",
                "Holvandi",
                "Ihamaru",
                "Jaanimoisa",
                "Kanepi",
                "Karilatsi",
                "Karsa",
                "Karste",
                "Kauksi",
                "Kiidjarve",
                "Kostrimae",
                "Krootuse",
                "Leevaku",
                "Leevi",
                "Leevijoe",
                "Linte",
                "Lootvina",
                "Maaritsa",
                "Mammaste",
                "Metste",
                "Mikitamae",
                "Mooste",
                "Naha",
                "Orava",
                "Pahtpaa",
                "Partsi",
                "Peri",
                "Piigandi",
                "Polgaste",
                "Polva",
                "Prangli",
                "Puuri",
                "Raadama",
                "Rahumae",
                "Raigla",
                "Rapina",
                "Rasina",
                "Ristipalo",
                "Rosma",
                "Ruusa",
                "Saverna",
                "Sillapaa",
                "Soodoma",
                "Suurkula",
                "Taevaskoja",
                "Tannassilma",
                "Tilsi",
                "Treski",
                "Vaike-Rosna",
                "Valgjarve",
                "Vana-Koiola",
                "Vardja",
                "Varska",
                "Vastse-Kuuste",
                "Veriora",
                "Viluste",
                "Voopsu"
            ],
            "Rapla": [
                "Aespa",
                "Alu",
                "Eidapere",
                "Hageri",
                "Hagudi",
                "Haimre",
                "Hertu",
                "Iira",
                "Ingliste",
                "Jarlepa",
                "Jarvakandi",
                "Juuru",
                "Kaerepere",
                "Kaiu",
                "Kalbu",
                "Karu",
                "Kasti",
                "Keava",
                "Kehtna",
                "Kivi-Vigala",
                "Kodila",
                "Kohila",
                "Kuimetsa",
                "Kuusiku",
                "Laukna",
                "Lelle",
                "Lipa",
                "Lohu",
                "Lokuta",
                "Maidla",
                "Marjamaa",
                "Masti",
                "Moisamaa",
                "Naravere",
                "Orgita",
                "Paardu",
                "Pae",
                "Pahkla",
                "Pihali",
                "Pirgu",
                "Prillimae",
                "Purila",
                "Purku",
                "Rabivere",
                "Raikkula",
                "Rangu",
                "Rapla",
                "Salutaguse",
                "Sipa",
                "Sotke",
                "Sutlema",
                "Tamme",
                "Teenuse",
                "Uuskula",
                "Vahastu",
                "Valgu",
                "Valtu",
                "Vana-Kaiu",
                "Vana-Vigala",
                "Varbola",
                "Vilivere"
            ],
            "Saare": [
                "Arandi",
                "Aste",
                "Eikla",
                "Hellamaa",
                "Kao",
                "Karja",
                "Karla",
                "Kihelkonna",
                "Koimla",
                "Koljala",
                "Korkkula",
                "Korkvere",
                "Kudjape",
                "Kuressaare",
                "Laatsa",
                "Lahekula",
                "Laimjala",
                "Leisi",
                "Liiva",
                "Lumanda",
                "Metskula",
                "Mustjala",
                "Nasva",
                "Nommkula",
                "Orissaare",
                "Pahkla",
                "Parsama",
                "Pihtla",
                "Sakla",
                "Salme",
                "Sandla",
                "Tagavere",
                "Tornimae",
                "Valjala",
                "Vohma"
            ],
            "Tartu": [
                "Aardla",
                "Aksi",
                "Alasoo",
                "Alatskivi",
                "Annikoru",
                "Aravu",
                "Elva",
                "Erala",
                "Ervu",
                "Haage",
                "Haaslava",
                "Ignase",
                "Illi",
                "Ilmatsalu",
                "Kaagvere",
                "Kaardi",
                "Kaarlijarve",
                "Kallaste",
                "Kalme",
                "Kambja",
                "Kandikula",
                "Karevere",
                "Karkna",
                "Kasepaa",
                "Kavastu",
                "Kirepi",
                "Kodukula",
                "Kokora",
                "Kolkja",
                "Konguta",
                "Koosa",
                "Koruste",
                "Korvekula",
                "Kulitse",
                "Kurekula",
                "Kurista",
                "Laaniste",
                "Laeva",
                "Lahte",
                "Lalli",
                "Lapetukme",
                "Lemmatsi",
                "Lohkva",
                "Lossimae",
                "Luke",
                "Luunja",
                "Maksa",
                "Maramaa",
                "Marja",
                "Meeksi",
                "Meeri",
                "Mehikoorma",
                "Melliste",
                "Metsakivi",
                "Metsalaane",
                "Moisanurme",
                "Nina",
                "Nogiaru",
                "Noo",
                "Pilka",
                "Poka",
                "Pooritsa",
                "Puhja",
                "Rahinge",
                "Raigaste",
                "Ramsi",
                "Rani",
                "Rannu",
                "Rebase",
                "Reola",
                "Rohu",
                "Roiu",
                "Rongu",
                "Saadjarve",
                "Sinikula",
                "Sirgu",
                "Soinaste",
                "Sojamaa",
                "Tahtvere",
                "Tammiste",
                "Tammistu",
                "Tartu",
                "Teedla",
                "Tilga",
                "Toravere",
                "Torvandi",
                "Tuki",
                "Uderna",
                "Uhti",
                "Ulenurme",
                "Ulila",
                "Unikula",
                "Vahi",
                "Vaike-Rakke",
                "Valguta",
                "Vana-Kuuste",
                "Vara",
                "Varnja",
                "Vasula",
                "Vedu",
                "Vesneri",
                "Vissi",
                "Voibla",
                "Voika",
                "Vonnu",
                "Voopste",
                "Vorbuse"
            ],
            "Valga": [
                "Aakre",
                "Ala",
                "Hargla",
                "Hellenurme",
                "Helme",
                "Hummuli",
                "Jeti",
                "Jogeveste",
                "Kaagjarve",
                "Kalme",
                "Karjatnurme",
                "Karula",
                "Keeni",
                "Koikkula",
                "Laanemetsa",
                "Laatre",
                "Laukula",
                "Leebiku",
                "Linna",
                "Lossikula",
                "Lullemae",
                "Moldre",
                "Nouni",
                "Nupli",
                "Oru",
                "Oruste",
                "Otepaa",
                "Paidla",
                "Paju",
                "Palupera",
                "Patkula",
                "Piiri",
                "Pikasilla",
                "Pilkuse",
                "Puhajarve",
                "Puka",
                "Restu",
                "Riidaja",
                "Sangaste",
                "Sihva",
                "Sooru",
                "Taagepera",
                "Tagula",
                "Taheva",
                "Tiidu",
                "Tolliste",
                "Torva",
                "Tsirguliina",
                "Valga",
                "Vana-Otepaa"
            ],
            "Viljandi": [
                "Abja-Paluoja",
                "Abja-Vanamoisa",
                "Aidu",
                "Angi",
                "Anikatsi",
                "Arikula",
                "Halliste",
                "Heimtali",
                "Holstre",
                "Intsu",
                "Jamejala",
                "Jaska",
                "Kaavere",
                "Kamara",
                "Karksi",
                "Karksi-Nuia",
                "Karstna",
                "Karula",
                "Kobruvere",
                "Koidama",
                "Koksvere",
                "Kolga-Jaani",
                "Koo",
                "Kopu",
                "Kulla",
                "Lalsi",
                "Leie",
                "Lilli",
                "Lohavere",
                "Loodi",
                "Maeltkula",
                "Matapera",
                "Meleski",
                "Metskula",
                "Moisakula",
                "Monnaste",
                "Mustivere",
                "Mustla",
                "Navesti",
                "Odiste",
                "Oisu",
                "Olustvere",
                "Pahuvere",
                "Paistu",
                "Parakula",
                "Pari",
                "Parsti",
                "Peetrimoisa",
                "Pilistvere",
                "Pinska",
                "Polde",
                "Polli",
                "Puiatu",
                "Ramsi",
                "Reegoldi",
                "Saarepeedi",
                "Savikoti",
                "Sinialliku",
                "Soe",
                "Soomevere",
                "Sudiste",
                "Suislepa",
                "Sultsi",
                "Supsi",
                "Surgavere",
                "Suure-Jaani",
                "Taaksi",
                "Tanassilma",
                "Tinnikuru",
                "Tohvri",
                "Tuhalaane",
                "Tusti",
                "Ulde",
                "Ulensi",
                "Uusna",
                "Valgita",
                "Valma",
                "Valuste",
                "Vana-Kariste",
                "Vana-Voidu",
                "Vardi",
                "Vardja",
                "Vastemoisa",
                "Veelikse",
                "Verilaske",
                "Veskimae",
                "Viiratsi",
                "Viljandi",
                "Villa",
                "Vohma",
                "Voivaku",
                "Vooru"
            ],
            "Voru": [
                "Antsla",
                "Haanja",
                "Jarvere",
                "Kaapa",
                "Kirumpaa",
                "Kobela",
                "Kollino",
                "Kose",
                "Kraavi",
                "Krabi",
                "Kulaoru",
                "Kuldre",
                "Kuutsi",
                "Lasva",
                "Loosu",
                "Lusti",
                "Meegomae",
                "Meremae",
                "Misso",
                "Moniste",
                "Navi",
                "Nursi",
                "Obinitsa",
                "Osula",
                "Otsa",
                "Parksepa",
                "Puiga",
                "Raiste",
                "Rimmi",
                "Rouge",
                "Ruusmae",
                "Saru",
                "Somerpalu",
                "Sulbi",
                "Taberlaane",
                "Tagakula",
                "Tsolgo",
                "Tsooru",
                "Urvaste",
                "Uue-Antsla",
                "Vaabina",
                "Vagula",
                "Vaimela",
                "Vana-Antsla",
                "Vana-Roosa",
                "Vana-Vastseliina",
                "Varstu",
                "Vastseliina",
                "Verijarve",
                "Viitina",
                "Viitka",
                "Visela",
                "Voru",
                "Vorumoisa"
            ]
        }
    },
    {
        "id": 70,
        "name": "Ethiopia",
        "iso3": "ETH",
        "iso2": "ET",
        "phone_code": "251",
        "capital": "Addis Ababa",
        "currency": "ETB",
        "states": {
            "Addis Abeba": [
                "Antsla",
                "Haanja",
                "Jarvere",
                "Kaapa",
                "Kirumpaa",
                "Kobela",
                "Kollino",
                "Kose",
                "Kraavi",
                "Krabi",
                "Kulaoru",
                "Kuldre",
                "Kuutsi",
                "Lasva",
                "Loosu",
                "Lusti",
                "Meegomae",
                "Meremae",
                "Misso",
                "Moniste",
                "Navi",
                "Nursi",
                "Obinitsa",
                "Osula",
                "Otsa",
                "Parksepa",
                "Puiga",
                "Raiste",
                "Rimmi",
                "Rouge",
                "Ruusmae",
                "Saru",
                "Somerpalu",
                "Sulbi",
                "Taberlaane",
                "Tagakula",
                "Tsolgo",
                "Tsooru",
                "Urvaste",
                "Uue-Antsla",
                "Vaabina",
                "Vagula",
                "Vaimela",
                "Vana-Antsla",
                "Vana-Roosa",
                "Vana-Vastseliina",
                "Varstu",
                "Vastseliina",
                "Verijarve",
                "Viitina",
                "Viitka",
                "Visela",
                "Voru",
                "Vorumoisa"
            ],
            "Afar": [
                "Asayita",
                "Awash",
                "Dubti",
                "Gewane"
            ],
            "Amhara": [
                "Addi Ark\\'ay",
                "Addis Zemen",
                "Addis \\'Alem",
                "Adet",
                "Bahir Dar",
                "Bati",
                "Bichena",
                "Bure",
                "Chagni",
                "Dabat",
                "Dangla",
                "Debark",
                "Debre Birhan",
                "Debre Mark\\'os",
                "Debre Sina",
                "Debre Tabor",
                "Debre Werk",
                "Dejen",
                "Dese",
                "Finote Selam",
                "Gondar",
                "Kembolcha",
                "Kemise",
                "K\\'obo",
                "Lalibela",
                "Mott\\'a",
                "Nefas Mewcha",
                "Sek\\'ot\\'a",
                "Shewa Robit",
                "Weldiya",
                "Were Ilu",
                "Werota"
            ],
            "Benishangul": [
                "Asosa"
            ],
            "Diredawa": [
                "Dire Dawa"
            ],
            "Gambella": [
                "Gambela"
            ],
            "Harar": [
                "Harer"
            ],
            "Jigjiga": [
                "Jigjiga"
            ],
            "Mekele": [
                "Jigjiga"
            ],
            "Oromia": [
                "Abomsa",
                "Agaro",
                "Asasa",
                "Asbe Teferi",
                "Assela",
                "Bako",
                "Bedele",
                "Bedesa",
                "Burayu",
                "Debre Zeyit",
                "Deder",
                "Dembi Dolo",
                "Dodola",
                "Fiche",
                "Gebre Guracha",
                "Gedo",
                "Gelemso",
                "Gimbi",
                "Ginch\\'i",
                "Ginir",
                "Giyon",
                "Goba",
                "Gore",
                "Guder",
                "Hagere Hiywet",
                "Hagere Maryam",
                "Hirna",
                "Holeta Genet",
                "Huruta",
                "Jimma",
                "Kibre Mengist",
                "Kofele",
                "Mega",
                "Mek\\'i",
                "Mendi",
                "Metehara",
                "Metu",
                "Mojo",
                "Moyale",
                "Nazret",
                "Nedjo",
                "Negele",
                "Nek\\'emte",
                "Robe",
                "Sebeta",
                "Sendafa",
                "Shakiso",
                "Shambu",
                "Shashemenne",
                "Sheh Hussen",
                "Sire",
                "Tulu Bolo",
                "Welench\\'iti",
                "Welk\\'it\\'e",
                "Wonji",
                "Yabelo",
                "Ziway",
                "\\'Alem Maya"
            ],
            "Somali": [
                "Bircot",
                "Degeh Bur",
                "Dollo Odo",
                "Imi",
                "Jijiga",
                "Werder"
            ],
            "Southern": [
                "Alaba Kulito",
                "Arba Minch",
                "Areka",
                "Awassa",
                "Bako",
                "Boditi",
                "Bonga",
                "Butajira",
                "Ch\\'ench\\'a",
                "Dilla",
                "Gidole",
                "Hossa\\'ina",
                "Jinka",
                "Leku",
                "Mizan Teferi",
                "Sawla",
                "Soddo",
                "Teppi",
                "Wendo",
                "Yirga Alem",
                "Yirga Ch\\'efe"
            ],
            "Tigray": [
                "Abiy Adi",
                "Addigrat",
                "Adwa",
                "Aksum",
                "Alamat\\'a",
                "Endasilasie",
                "Hagere Selam",
                "Himora",
                "Korem",
                "Maych\\'ew",
                "Mekele",
                "Mek\\'ele",
                "Wik\\'ro"
            ]
        }
    },
    {
        "id": 71,
        "name": "Falkland Islands",
        "iso3": "FLK",
        "iso2": "FK",
        "phone_code": "500",
        "capital": "Stanley",
        "currency": "FKP",
        "states": {
            "Falkland Islands": [
                "Abiy Adi",
                "Addigrat",
                "Adwa",
                "Aksum",
                "Alamat\\'a",
                "Endasilasie",
                "Hagere Selam",
                "Himora",
                "Korem",
                "Maych\\'ew",
                "Mekele",
                "Mek\\'ele",
                "Wik\\'ro"
            ],
            "South Georgia": [
                "Abiy Adi",
                "Addigrat",
                "Adwa",
                "Aksum",
                "Alamat\\'a",
                "Endasilasie",
                "Hagere Selam",
                "Himora",
                "Korem",
                "Maych\\'ew",
                "Mekele",
                "Mek\\'ele",
                "Wik\\'ro"
            ]
        }
    },
    {
        "id": 72,
        "name": "Faroe Islands",
        "iso3": "FRO",
        "iso2": "FO",
        "phone_code": "298",
        "capital": "Torshavn",
        "currency": "DKK",
        "states": {
            "Klaksvik": [
                "Hvannasund",
                "Klaksvik"
            ],
            "Nor ara Eysturoy": [
                "Hvannasund",
                "Klaksvik"
            ],
            "Nor oy": [
                "Hvannasund",
                "Klaksvik"
            ],
            "Sandoy": [
                "Husavik",
                "Sands",
                "Skalavik",
                "Skopun",
                "Skuvoy"
            ],
            "Streymoy": [
                "Haldarsvik",
                "Hests",
                "Hosvik",
                "Hvalvik",
                "Kirkjubo",
                "Kollafjar ",
                "Kvivik",
                "Nolsoy",
                "Saksun",
                "Torshavn",
                "Vestmanna"
            ],
            "Su uroy": [
                "Haldarsvik",
                "Hests",
                "Hosvik",
                "Hvalvik",
                "Kirkjubo",
                "Kollafjar ",
                "Kvivik",
                "Nolsoy",
                "Saksun",
                "Torshavn",
                "Vestmanna"
            ],
            "Sy ra Eysturoy": [
                "Haldarsvik",
                "Hests",
                "Hosvik",
                "Hvalvik",
                "Kirkjubo",
                "Kollafjar ",
                "Kvivik",
                "Nolsoy",
                "Saksun",
                "Torshavn",
                "Vestmanna"
            ],
            "Torshavn": [
                "Torshavn"
            ],
            "Vaga": [
                "Biggjar",
                "Midvags",
                "Mykines",
                "Sandavags",
                "Sorvags"
            ]
        }
    },
    {
        "id": 73,
        "name": "Fiji Islands",
        "iso3": "FJI",
        "iso2": "FJ",
        "phone_code": "679",
        "capital": "Suva",
        "currency": "FJD",
        "states": {
            "Central": [
                "Deuba",
                "Korovou",
                "Lami",
                "Namosi",
                "Nausori",
                "Navua",
                "Suva"
            ],
            "Eastern": [
                "Levuka",
                "Malhaha",
                "Tubou",
                "Vunisea"
            ],
            "Northern": [
                "Korokade",
                "Labasa",
                "Savusavu"
            ],
            "South Pacific": [
                "Korokade",
                "Labasa",
                "Savusavu"
            ],
            "Western": [
                "Ba",
                "Lautoka",
                "Nadi",
                "Rakiraki",
                "Sigatoka",
                "Tavua",
                "Vatukoula"
            ]
        }
    },
    {
        "id": 74,
        "name": "Finland",
        "iso3": "FIN",
        "iso2": "FI",
        "phone_code": "358",
        "capital": "Helsinki",
        "currency": "EUR",
        "states": {
            "Ahvenanmaa": [
                "Maarianhamina"
            ],
            "Etela-Karjala": [
                "Imatra",
                "Joutseno",
                "Lappeenranta"
            ],
            "Etela-Pohjanmaa": [
                "Hyllykallio",
                "Ilmajoki",
                "Kauhajoki",
                "Kurikka",
                "Lapua",
                "Seinajoki"
            ],
            "Etela-Savo": [
                "Mikkeli",
                "Savonlinna"
            ],
            "Etela-Suomen Laani": [
                "Mikkeli",
                "Savonlinna"
            ],
            "Ita-Suomen Laani": [
                "Mikkeli",
                "Savonlinna"
            ],
            "Ita-Uusimaa": [
                "Loviisa",
                "Orimattila",
                "Porvoo"
            ],
            "Kainuu": [
                "Kajaani"
            ],
            "Kanta-Hame": [
                "Forssa",
                "Hameenlinna",
                "Janakkala",
                "Riihimaki"
            ],
            "Keski-Pohjanmaa": [
                "Kokkola"
            ],
            "Keski-Suomi": [
                "Aanekoski",
                "Jamsa",
                "Jyvaskyla",
                "Keuruu",
                "Laukaa",
                "Lieto"
            ],
            "Kymenlaakso": [
                "Anjalankoski",
                "Hamina",
                "Kotka",
                "Kouvola",
                "Kuusankoski",
                "Valkeala",
                "Vehkalahti"
            ],
            "Lansi-Suomen Laani": [
                "Anjalankoski",
                "Hamina",
                "Kotka",
                "Kouvola",
                "Kuusankoski",
                "Valkeala",
                "Vehkalahti"
            ],
            "Lappi": [
                "Kemi",
                "Kemijarvi",
                "Rovaniemi",
                "Sonka",
                "Tornio"
            ],
            "Northern Savonia": [
                "Kemi",
                "Kemijarvi",
                "Rovaniemi",
                "Sonka",
                "Tornio"
            ],
            "Ostrobothnia": [
                "Jakobstad",
                "Oulunsalo"
            ],
            "Oulun Laani": [
                "Jakobstad",
                "Oulunsalo"
            ],
            "Paijat-Hame": [
                "Heinola",
                "Hollola",
                "Lahti",
                "Nastola"
            ],
            "Pirkanmaa": [
                "Kangasala",
                "Lempaala",
                "Nokia",
                "Pirkkala",
                "Sastamala",
                "Tampere",
                "Valkeakoski",
                "Ylojarvi"
            ],
            "Pohjanmaa": [
                "Kristiinankaupunki",
                "Mustasaari",
                "Pietarsaari",
                "Uusikarlepyy",
                "Vaasa"
            ],
            "Pohjois-Karjala": [
                "Joensuu",
                "Lieksa"
            ],
            "Pohjois-Pohjanmaa": [
                "Haukipudas",
                "Kempele",
                "Kuusamo",
                "Muhos",
                "Nivala",
                "Oulainen",
                "Oulu",
                "Raahe",
                "Ylivieska"
            ],
            "Pohjois-Savo": [
                "Iisalmi",
                "Kuopio",
                "Sillinjarvi",
                "Varkaus"
            ],
            "Saarijarvi": [
                "Saarijarvi"
            ],
            "Satakunta": [
                "Eura",
                "Eurajoki",
                "Harjavalta",
                "Huittinen",
                "Kankaanpaa",
                "Kokemaki",
                "Lappi",
                "Nakkila",
                "Noormarkku",
                "Pori",
                "Rauma",
                "Sakyla",
                "Ulvila",
                "Vammala"
            ],
            "Southern Savonia": [
                "Eura",
                "Eurajoki",
                "Harjavalta",
                "Huittinen",
                "Kankaanpaa",
                "Kokemaki",
                "Lappi",
                "Nakkila",
                "Noormarkku",
                "Pori",
                "Rauma",
                "Sakyla",
                "Ulvila",
                "Vammala"
            ],
            "Tavastia Proper": [
                "Eura",
                "Eurajoki",
                "Harjavalta",
                "Huittinen",
                "Kankaanpaa",
                "Kokemaki",
                "Lappi",
                "Nakkila",
                "Noormarkku",
                "Pori",
                "Rauma",
                "Sakyla",
                "Ulvila",
                "Vammala"
            ],
            "Uleaborgs Lan": [
                "Eura",
                "Eurajoki",
                "Harjavalta",
                "Huittinen",
                "Kankaanpaa",
                "Kokemaki",
                "Lappi",
                "Nakkila",
                "Noormarkku",
                "Pori",
                "Rauma",
                "Sakyla",
                "Ulvila",
                "Vammala"
            ],
            "Uusimaa": [
                "Espoo",
                "Hanko",
                "Helsinki",
                "Hyvinkaa",
                "Jarvenpaa",
                "Kauniainen",
                "Kerava",
                "Kirkkonummi",
                "Lohja",
                "Mantsala",
                "Nurmijarvi",
                "Sipoo",
                "Tammisaari",
                "Tuusula",
                "Vantaa",
                "Vihti"
            ],
            "Varsinais-Suomi": [
                "Kaarina",
                "Naantali",
                "Parainen",
                "Raisio",
                "Salo",
                "Turku",
                "Uusikaupunki"
            ]
        }
    },
    {
        "id": 75,
        "name": "France",
        "iso3": "FRA",
        "iso2": "FR",
        "phone_code": "33",
        "capital": "Paris",
        "currency": "EUR",
        "states": {
            "Ain": [
                "Amberieu-en-Bugey",
                "Bellegarde-sur-Valserine",
                "Bourg-en-Bresse",
                "Oyonnax"
            ],
            "Aisne": [
                "Chateau-Thierry",
                "Chauny",
                "Hirson",
                "Laon",
                "Saint-Quentin",
                "Soissons",
                "Strassbourg",
                "Tergnier"
            ],
            "Albi Le Sequestre": [
                "Chateau-Thierry",
                "Chauny",
                "Hirson",
                "Laon",
                "Saint-Quentin",
                "Soissons",
                "Strassbourg",
                "Tergnier"
            ],
            "Allier": [
                "Cusset",
                "Montlucon",
                "Moulins",
                "Vichy",
                "Yzeure"
            ],
            "Alpes-Cote dAzur": [
                "Cusset",
                "Montlucon",
                "Moulins",
                "Vichy",
                "Yzeure"
            ],
            "Alpes-de-Haute-Provence": [
                "Digne-les-Bains",
                "Manosque"
            ],
            "Alpes-Maritimes": [
                "Antibes",
                "Beausoleil",
                "Cagnes-sur-Mer",
                "Cannes",
                "Carros",
                "Grasse",
                "La Trinite",
                "Le Cannet",
                "Mandelieu-la-Napoule",
                "Menton",
                "Mougins",
                "Nice",
                "Roquebrune-Cap-Martin",
                "Saint-Laurent-du-Var",
                "Valbonne",
                "Vallauris",
                "Vence",
                "Villeneuve-Loubet"
            ],
            "Alsace": [
                "Adainville",
                "Alby-sur-Cheran",
                "Aubervilliers",
                "Auterive",
                "Baillet",
                "Beaune",
                "Beauzelle",
                "Bonnet De Mure",
                "Bormes-les-Mimosas",
                "Brindas",
                "Burlats",
                "Buzancais",
                "Candillargues",
                "Carry-le-Rouet",
                "Cassis",
                "Cessenon-Sur-Orb",
                "Chanac",
                "Chevigny",
                "Cogolin",
                "Collioure",
                "Cremieu",
                "Drulingen",
                "Ecouen",
                "Eschau",
                "Feignies",
                "Ferus",
                "Fourqueux",
                "Franqueville",
                "Gignac",
                "Gravigny",
                "Hangenbieten",
                "Hunspach",
                "Kilstett",
                "La Chevroliere",
                "La-Fare-Les-Oliviers",
                "Lanvellec",
                "Le Faget",
                "Lesigny",
                "Lesquin",
                "Limonest",
                "Messein",
                "Morgat",
                "Mundolsheim",
                "Nantiat",
                "Niederbronn-les-Bain",
                "Nimes",
                "Opoul",
                "Pance",
                "Peronne",
                "Ploneour Lanvern",
                "Realmont",
                "Reichstett",
                "Saint Aubin",
                "Saint Christophe",
                "Saint Martin d\u00c3\u0192\u00c2\u0192\u00c3\u2020\u00c2\u2019\u00c3\u0192\u00c2\u201a\u00c3\u201a\u00c2\u00a2\u00c3\u0192\u00c2\u0192\u00c3\u00a2\u00c2\u20ac\u00c2\u0161\u00c3\u0192\u00c2\u201a\u00c3\u00a2\u00c2\u201a\u00c2\u00ac\u00c3\u0192\u00c2\u0192\u00c3\u00a2\u00c2\u20ac\u00c2\u0161\u00c3\u0192\u00c2\u201a\u00c3\u00a2\u00c2\u201e\u00c2\u00a2Here",
                "Saint-Berthevin",
                "Saint-Cergues",
                "Sancerre",
                "Sarzeau",
                "Seltz",
                "Seyssins",
                "Souffelweyersheim",
                "Vireux-Molhain",
                "Voves",
                "Wambrechies",
                "Wervocq Sud"
            ],
            "Aquitaine": [
                "Abzac",
                "Bidart",
                "Biganos",
                "Buzet-sur-Baise",
                "Coursac",
                "Hasparren",
                "Landiras",
                "Le Haillan",
                "Ledat",
                "Martillac",
                "Puyoo",
                "Saint-Jean-dIllac",
                "Seignosse",
                "Tresses"
            ],
            "Ardeche": [
                "Annonay",
                "Aubenas",
                "Guilherand-Granges",
                "Privas",
                "Tournon-sur-Rhone"
            ],
            "Ardennes": [
                "Charleville-Mezieres",
                "Les Hautes-Rivieres",
                "Revin",
                "Sedan"
            ],
            "Ariege": [
                "Foix",
                "Pamier"
            ],
            "Aube": [
                "Bar-sur-Seine",
                "Romilly-sur-Seine",
                "Saint-Andre-les-Vergers",
                "Sainte-Savine",
                "Troyes"
            ],
            "Aude": [
                "Carcassonne",
                "Castelnaudary",
                "Limoux",
                "Narbonne"
            ],
            "Auvergne": [
                "Ambert",
                "Creuzier-le-Vieux",
                "Ferrieres",
                "Peschadoires",
                "Riotord",
                "Saint-Pal-de-Chalencon",
                "Saint-Romain-Lachalm",
                "Saint-Vidal",
                "Sainte-Sigolene"
            ],
            "Aveyron": [
                "Millau",
                "Onet-le-Chataeu",
                "Rodez",
                "Villefranche-de-Rouergue"
            ],
            "Bas-Rhin": [
                "Bischheim",
                "Bischwiller",
                "Haguenau",
                "Hoenheim",
                "Illkirch-Graffenstaden",
                "Lingolsheim",
                "Obernai",
                "Ostwald",
                "Saverne",
                "Schiltigheim",
                "Selestat",
                "Strasbourg"
            ],
            "Basse-Normandie": [
                "Deauville"
            ],
            "Bouches-du-Rhone": [
                "Aix-en-Provence",
                "Allauch",
                "Arles",
                "Aubagne",
                "Berre-l\\'Etang",
                "Bouc-Bel-Air",
                "Chateauneuf-les-Martigues",
                "Chateaurenard",
                "Fos-sur-Mer",
                "Gardanne",
                "Istres",
                "La Ciotat",
                "Les Pennes-Mirabeau",
                "Maillane",
                "Marignane",
                "Marseille",
                "Martigues",
                "Miramas",
                "Plan-de-Cuques",
                "Port-de-Bouc",
                "Rognac",
                "Saint-Martin-de-Crau",
                "Saint-Remy-de-Provence",
                "Salon-de-Provence",
                "Septemes-les-Vallons",
                "Tarascon",
                "Vitrolles"
            ],
            "Bourgogne": [
                "Migennes"
            ],
            "Bretagne": [
                "Javene",
                "Plouha"
            ],
            "Brittany": [
                "Brehan",
                "Broons",
                "Guipry",
                "Miniac-Morvan",
                "Ploudaniel",
                "Vern-sur-Seiche"
            ],
            "Burgundy": [
                "Aillant-sur-Tholon"
            ],
            "Calvados": [
                "Bayeux",
                "Caen",
                "Herouville-Saint-Clair",
                "Lisieux",
                "Mondeville",
                "Vire"
            ],
            "Cantal": [
                "Aurillac"
            ],
            "Cedex": [
                "Brumath",
                "cedex",
                "Courtaboeuf",
                "Iregny"
            ],
            "Centre": [
                "Azay-le-Rideau",
                "Chevillon-sur-Huillard",
                "Cloyes-sur-le-Loir",
                "Gellainville",
                "La Chaussse-Saint-Victor",
                "La Ville-aux-Clercs",
                "Ladon",
                "Le Chatelet"
            ],
            "Charente": [
                "Angouleme",
                "Cognac",
                "Lencloitre",
                "Soyaux"
            ],
            "Charente-Maritime": [
                "La Rochelle",
                "Rochefort",
                "Royan",
                "Saintes"
            ],
            "Cher": [
                "Bourges",
                "Saint-Amand-Montrond",
                "Saint-Doulchard",
                "Vierzon"
            ],
            "Correze": [
                "Brive-la-Gaillarde",
                "Tulle",
                "Ussel"
            ],
            "Corse-du-Sud": [
                "Ajaccio",
                "Porto-Vecchio"
            ],
            "Cote-d\\'Or": [
                "Beaune",
                "Chenove",
                "Dijon",
                "Quetigny",
                "Talant"
            ],
            "Cotes-d\\'Armor": [
                "Dinan",
                "Lamballe",
                "Lannion",
                "Loudeac",
                "Plerin",
                "Ploufragan",
                "Saint-Brieuc"
            ],
            "Creuse": [
                "Gueret"
            ],
            "Crolles": [
                "Crolles"
            ],
            "Deux-Sevres": [
                "Bressuire",
                "Niort",
                "Parthenay",
                "Thouars"
            ],
            "Dordogne": [
                "Bergerac",
                "Perigueux",
                "Sarlat-la-Caneda"
            ],
            "Doubs": [
                "Audincourt",
                "Besancon",
                "Beure",
                "Montbeliard",
                "Pontarlier",
                "Valentigney"
            ],
            "Drome": [
                "Bourg-les-Valence",
                "Montelimar",
                "Pierrelatte",
                "Romans-sur-Isere",
                "Valence"
            ],
            "Essonne": [
                "Athis-Mons",
                "Bretigny-sur-Orge",
                "Brunoy",
                "Bures-sur-Yvette",
                "Chilly-Mazarin",
                "Corbeil-Essonnes",
                "Courcouronnes",
                "Dourdan",
                "Draveil",
                "Epinay-sous-Senart",
                "Epinay-sur-Orge",
                "Etampes",
                "Evry",
                "Fleury-Merogis",
                "Gif-sur-Yvette",
                "Grigny",
                "Igny",
                "Juvisy-sur-Orge",
                "Les Ulis",
                "Longjumeau",
                "Massy",
                "Mennecy",
                "Montgeron",
                "Morangis",
                "Morsang-sur-Orge",
                "Orsay",
                "Palaiseau",
                "Ris-Orangis",
                "Saint-Michel-sur-Orge",
                "Sainte-Genevieve-des-Bois",
                "Savigny-sur-Orge",
                "Verrieres-le-Buisson",
                "Vigneux-sur-Seine",
                "Villebon-sur-Yvette",
                "Viry-Chatillon",
                "Yerres"
            ],
            "Eure": [
                "Bernay",
                "Evreux",
                "Gisors",
                "Louviers",
                "Pont-Audemer",
                "Val-de-Reuil",
                "Vernon"
            ],
            "Eure-et-Loir": [
                "Chartres",
                "Chateaudun",
                "Dreux",
                "Luce",
                "Mainvillier",
                "Nogent-le-Rotrou",
                "Vernouillet"
            ],
            "Feucherolles": [
                "Feucherolles"
            ],
            "Finistere": [
                "Brest",
                "Concarneau",
                "Douarnenez",
                "Guipavas",
                "Landerneau",
                "Le Relecq-Kerhoun",
                "Morlaix",
                "Plougastel-Daoulas",
                "Plouzane",
                "Quimper",
                "Quimperle"
            ],
            "Franche-Comte": [
                "Charquemont",
                "Chemaudin",
                "Pelousey",
                "Perrigny",
                "Pirey",
                "Villers-le-Lac"
            ],
            "Gard": [
                "Ales",
                "Bagnols-sur-Ceze",
                "Beaucaire",
                "Nimes",
                "Pont-Saint-Esprit",
                "Saint-Gilles",
                "Vauvert",
                "Villeneuve-les-Avignon"
            ],
            "Gers": [
                "Auch",
                "Beraut"
            ],
            "Gironde": [
                "Ambares-et-Lagrave",
                "Arcachon",
                "Begles",
                "Blanquefort",
                "Bordeaux",
                "Bruges",
                "Cenon",
                "Cestas",
                "Eysines",
                "Floirac",
                "Gradignan",
                "Gujan-Mestras",
                "La Teste-de-Buch",
                "Le Bouscat",
                "Libourne",
                "Lormont",
                "Merignac",
                "Pessac",
                "Saint-Medard-en-Jalles",
                "Talence",
                "Villenave-d\\'Ornon"
            ],
            "Haut-Rhin": [
                "Cernay",
                "Colmar",
                "Guebwiller",
                "Illzach",
                "Kingersheim",
                "Mulhouse",
                "Riediesheim",
                "Rixheim",
                "Saint-Louis",
                "Wittelsheim",
                "Wittenheim"
            ],
            "Haute-Corse": [
                "Bastia"
            ],
            "Haute-Garonne": [
                "Balma",
                "Blagnac",
                "Colomiers",
                "Cugnaux",
                "L\\'Union",
                "Muret",
                "Plaisance-du-Touch",
                "Ramonville-Saint-Agne",
                "Saint-Gaudens",
                "Saint-Orens-de-Gameville",
                "Toulouse",
                "Tournefeuille"
            ],
            "Haute-Loire": [
                "Blanzac",
                "Le Puy-en-Velay"
            ],
            "Haute-Marne": [
                "Chaumont",
                "Langres",
                "Saint-Dizier"
            ],
            "Haute-Saone": [
                "Hericourt",
                "Lure",
                "Luxeuil-les-Bains",
                "Vesoul"
            ],
            "Haute-Savoie": [
                "Annecy",
                "Annecy-le-Vieux",
                "Annemasse",
                "Archamps",
                "Bonneville",
                "Chamonix-Mont-Blanc",
                "Cluses",
                "Contamine sur Arve",
                "Cran-Gevrier",
                "Passy",
                "Rumilly",
                "Sallanches",
                "Seynod",
                "Thonon-les-Bains"
            ],
            "Haute-Vienne": [
                "Gaillard",
                "Limoges",
                "Saint-Junien"
            ],
            "Hautes-Alpes": [
                "Briancon",
                "Gap"
            ],
            "Hautes-Pyrenees": [
                "Lourdes",
                "Tarbes"
            ],
            "Hauts-de-Seine": [
                "Antony",
                "Asnieres-sur-Seine",
                "Bagneux",
                "Bois-Colombes",
                "Boulogne-Billancourt",
                "Bourg-la-Reine",
                "Chatenay-Malabry",
                "Chatillon",
                "Chaville",
                "Clamart",
                "Clichy",
                "Colombes",
                "Courbevoie",
                "Fontenay-aux-Roses",
                "Garches",
                "Gennevillers",
                "Issy-les-Moulineaux",
                "La Garenne-Colombes",
                "Le Plessis-Robinson",
                "Levallois-Perret",
                "Malakoff",
                "Meudon",
                "Montrouge",
                "Nanterre",
                "Neuilly-sur-Seine",
                "Puteaux",
                "Rueil-Malmaison",
                "Saint-Cloud",
                "Sceaux",
                "Sevres",
                "Suresnes",
                "Vanves",
                "Ville-d\\'Avray",
                "Villeneuve-la-Garenne"
            ],
            "Herault": [
                "Agde",
                "Beziers",
                "Castelnau-le-Lez",
                "Frontignan",
                "Lattes",
                "Lunel",
                "Mauguio",
                "Montpellier",
                "Sete"
            ],
            "Ile-de-France": [
                "Champagne-sur-Oise",
                "Croissy-Beaubourg",
                "Gennevilliers",
                "Le Mesnil-le-Roi",
                "Le Plessis-Bouchard",
                "Rebais",
                "Saint-Thibault-des-Vignes"
            ],
            "Ille-et-Vilaine": [
                "Cesson-Sevigne",
                "Dinard",
                "Fougeres",
                "Rennes",
                "Saint-Malo",
                "Vitre"
            ],
            "Indre": [
                "Chateauroux",
                "Issoudun"
            ],
            "Indre-et-Loire": [
                "Amboise",
                "Joue-les-Tours",
                "Saint-Avertin",
                "Saint-Cyr-sur-Loire",
                "Saint-Pierre-des-Corps",
                "Tours"
            ],
            "Isere": [
                "Bourgoin-Jallieu",
                "Crolles",
                "Echirolles",
                "Fontaine",
                "Grenoble",
                "Le Pont-de-Claix",
                "Meylan",
                "Saint-Egreve",
                "Saint-Martin-d\\'Heres",
                "Seyssinet-Pariset",
                "Vienne",
                "Villefontaine",
                "Voiron"
            ],
            "Jura": [
                "Champagnole",
                "Dole",
                "Lons-le-Saunier",
                "Saint-Claude"
            ],
            "Klagenfurt": [
                "Sebastiangasse"
            ],
            "Landes": [
                "Biscarrosse",
                "Dax",
                "Hagetmau",
                "Landes",
                "Mont-de-Marsan",
                "Saint-Paul-les-Dax",
                "Tarnos"
            ],
            "Languedoc-Roussillon": [
                "Codolet",
                "Cuxac-d\\'Aude",
                "Gigean",
                "Grabels",
                "Lamalou-les-Bains",
                "Perols",
                "Peyrens",
                "Tuchan"
            ],
            "Larcay": [
                "Larcay"
            ],
            "Le Castellet": [
                "Larcay"
            ],
            "Le Creusot": [
                "Larcay"
            ],
            "Limousin": [
                "Voutezac"
            ],
            "Loir-et-Cher": [
                "Blois",
                "Romorantin-Lanthenay",
                "Vendome"
            ],
            "Loire": [
                "Andrezieux-Boutheon",
                "Firminy",
                "La Ricamarie",
                "Le Chambon-Feugerolles",
                "Montbrison",
                "Riorges",
                "Rive-de-Gier",
                "Roanne",
                "Roche-la-Moliere",
                "Saint-Chamond",
                "Saint-Etienne",
                "Saint-Just-Saint-Rambert"
            ],
            "Loire-Atlantique": [
                "Bouguenais",
                "Carquefou",
                "Chateaubriant",
                "Coueron",
                "Guerande",
                "La Baule-Escoublac",
                "La Chapelle-sur-Erdre",
                "Nantes",
                "Orvault",
                "Reze",
                "Saint Etienne de Mer Morte",
                "Saint-Herblain",
                "Saint-Nazaire",
                "Saint-Sebastien-sur-Loire",
                "Sainte-Luce-sur-Loire",
                "Vertou"
            ],
            "Loiret": [
                "Amilly",
                "Chalette-sur-Loing",
                "Fleury-les-Aubrais",
                "Gien",
                "Montargis",
                "Olivet",
                "Orleans",
                "Pithiviers",
                "Saint-Jean-de-Braye",
                "Saint-Jean-de-la-Ruelle",
                "Saran"
            ],
            "Lorraine": [
                "Montbronn"
            ],
            "Lot": [
                "Cahors",
                "Figeac"
            ],
            "Lot-et-Garonne": [
                "Agen",
                "Le Passage",
                "Marmande",
                "Tonneins",
                "Villeneuve-sur-Lot"
            ],
            "Lower Normandy": [
                "Agen",
                "Le Passage",
                "Marmande",
                "Tonneins",
                "Villeneuve-sur-Lot"
            ],
            "Lozere": [
                "Mende"
            ],
            "Maine-et-Loire": [
                "Angers",
                "Avrille",
                "Cholet",
                "Les Ponts-de-Ce",
                "Saint-Barthelemy-d\\'Anjou",
                "Saumur",
                "Torfou",
                "Trelaze"
            ],
            "Manche": [
                "Cherbourg",
                "Coutances",
                "Equeurdreville-Hainneville",
                "Granville",
                "Octeville",
                "Saint-Lo",
                "Tourlaville"
            ],
            "Marne": [
                "Chalons-en-Champagne",
                "Epernay",
                "Reims",
                "Tinqueux",
                "Vitry-le-Francois"
            ],
            "Mayenne": [
                "Chateau-Gontier",
                "Laval",
                "Mayenne",
                "Montsurs"
            ],
            "Meurthe-et-Moselle": [
                "Dombasle-sur-Meurthe",
                "Jarville-la-Malgrange",
                "Laxou",
                "Longwy",
                "Luneville",
                "Nancy",
                "Pont-a-Mousson",
                "Saint-Max",
                "Toul",
                "Vandoeuvre-les-Nancy",
                "Villers-les-Nancy",
                "Villerupt"
            ],
            "Meuse": [
                "Bar-le-Duc",
                "Verdun"
            ],
            "Midi-Pyrenees": [
                "Boissezon",
                "Hauterive",
                "Launaguet",
                "Mauleon",
                "Maurens-Scopont"
            ],
            "Morbihan": [
                "Auray",
                "Guidel",
                "Hennebont",
                "Lanester",
                "Lorient",
                "Ploemeur",
                "Pontivy",
                "Vannes"
            ],
            "Moselle": [
                "Amneville",
                "Behren-les-Forbach",
                "Creutzwald",
                "Fameck",
                "Florange",
                "Forbach",
                "Freyming-Merlebach",
                "Hagondange",
                "Hayange",
                "Hombourg-Haut",
                "Maizieres-les-Metz",
                "Marly",
                "Metz",
                "Montigny-les-Metz",
                "Moyeuvre-Grande",
                "Rombas",
                "Saint-Avold",
                "Sarrebourg",
                "Sarreguemines",
                "Stiring-Wendel",
                "Thionville",
                "Uckange",
                "Woippy",
                "Yutz"
            ],
            "Nievre": [
                "Cosne-Cours-sur-Loire",
                "Nevers",
                "Varennes-Vauzelles"
            ],
            "Nord": [
                "Aniche",
                "Annoeullin",
                "Anzin",
                "Armentieres",
                "Aulnoye-Aymeries",
                "Bailleul",
                "Bondues",
                "Bruay-sur-l\\'Escaut",
                "Cambrai",
                "Cappelle-la-Grande",
                "Caudry",
                "Comines",
                "Conde-sur-l\\'Escaut",
                "Coudekerque-Branche",
                "Croix",
                "Denain",
                "Douai",
                "Douchy-les-Mines",
                "Dunkerque",
                "Escaudain",
                "Fache-Thumesnil",
                "Fourmies",
                "Grande-Synthe",
                "Graveline",
                "Halluin",
                "Haubourdin",
                "Hautmont",
                "Hazebrouck",
                "Hem",
                "Hulluch",
                "Jeumont",
                "La Madeleine",
                "Lambersart",
                "Leers",
                "Lille",
                "Lomme",
                "Loos",
                "Lys-lez-Lannoy",
                "Marcq-en-Baroeul",
                "Marennes",
                "Marly",
                "Marquette-lez-Lille",
                "Maubeuge",
                "Merville",
                "Mons-en-Baroeul",
                "Mouvaux",
                "Neuville-en-Ferrain",
                "Onnaing",
                "Raismes",
                "Ronchin",
                "Roncq",
                "Roubaix",
                "Saint-Amand-les-Eaux",
                "Saint-Andre-lez-Lille",
                "Saint-Pol-sur-Mer",
                "Saint-Saulve",
                "Seclin",
                "Sin-le-Noble",
                "Somain",
                "Tourcoing",
                "Valenciennes",
                "Vieux-Conde",
                "Villeneuve-d\\'Ascq",
                "Wasquehal",
                "Wattignies",
                "Wattrelos",
                "Waziers"
            ],
            "Nord-Pas-de-Calais": [
                "Esquelbecq"
            ],
            "Oise": [
                "Beauvais",
                "Chantilly",
                "Clermont",
                "Compiegne",
                "Creil",
                "Crepy-en-Valois",
                "Gouvieux",
                "Meru",
                "Montataire",
                "Nogent-sur-Oise",
                "Noyon",
                "Pont-Sainte-Maxence",
                "Senlis"
            ],
            "Orne": [
                "Alencon",
                "Argentan",
                "Flers",
                "L\\'Aigle"
            ],
            "Paris": [
                "Paris"
            ],
            "Pas-de-Calais": [
                "Aire-sur-la-Lys",
                "Arras",
                "Auchel",
                "Avion",
                "Berck",
                "Bethune",
                "Boulogne-sur-Mer",
                "Bruay-la-Brussiere",
                "Bully-les-Mines",
                "Calais",
                "Carvin",
                "Courrieres",
                "Etaples",
                "Harnes",
                "Henin-Beaumont",
                "Le Portel",
                "Lens",
                "Libercourt",
                "Lievin",
                "Lillers",
                "Longuenesse",
                "Marck",
                "Mericourt",
                "Montigny-en-Gohelle",
                "Noeux-les-Mines",
                "Oignies",
                "Outreau",
                "Rouvroy",
                "Saint-Martin-Boulogne",
                "Saint-Omer",
                "Sallaumines",
                "Vendin-le-Vieil"
            ],
            "Pays de la Loire": [
                "Aire-sur-la-Lys",
                "Arras",
                "Auchel",
                "Avion",
                "Berck",
                "Bethune",
                "Boulogne-sur-Mer",
                "Bruay-la-Brussiere",
                "Bully-les-Mines",
                "Calais",
                "Carvin",
                "Courrieres",
                "Etaples",
                "Harnes",
                "Henin-Beaumont",
                "Le Portel",
                "Lens",
                "Libercourt",
                "Lievin",
                "Lillers",
                "Longuenesse",
                "Marck",
                "Mericourt",
                "Montigny-en-Gohelle",
                "Noeux-les-Mines",
                "Oignies",
                "Outreau",
                "Rouvroy",
                "Saint-Martin-Boulogne",
                "Saint-Omer",
                "Sallaumines",
                "Vendin-le-Vieil"
            ],
            "Pays-de-la-Loire": [
                "Loiron",
                "Marolles-les-Braults",
                "Mortagne-sur-Sevre",
                "Mouzillon",
                "Noirmoutier-en-l\\'\u00c3\u0192\u00c2\u0192\u00c3\u2020\u00c2\u2019\u00c3\u0192\u00c2\u201a\u00c3\u2026\u00c2\u00bdle"
            ],
            "Picardy": [
                "Friville",
                "Liancourt",
                "Maizy",
                "Oust-Marest",
                "Puiseux-le-Hauberger",
                "Saint-Crepin-Ibouvillers"
            ],
            "Puy-de-Dome": [
                "Aubiere",
                "Beaumont",
                "Chamalieres",
                "Clermont-Ferrand",
                "Cournon-d\\'Auvergne",
                "Gerzat",
                "Issoire",
                "Riom",
                "Thiers"
            ],
            "Pyrenees-Atlantiques": [
                "Anglet",
                "Bayonne",
                "Biarritz",
                "Billere",
                "Hendaye",
                "Lons",
                "Oloron-Sainte-Marie",
                "Orthez",
                "Pau",
                "Saint-Jean-de-Luz"
            ],
            "Pyrenees-Orientales": [
                "Perpignan",
                "Saint-Esteve"
            ],
            "Quelmes": [
                "Quelmes"
            ],
            "Rhone": [
                "Brignais",
                "Bron",
                "Caluire-et-Cuire",
                "Decines-Charpieu",
                "Ecully",
                "Francheville",
                "Genas",
                "Genay",
                "Givors",
                "Lyon",
                "Meyzieu",
                "Mions",
                "Oullins",
                "Pierre-Benite",
                "Rillieux-la-Pape",
                "Saint-Fons",
                "Saint-Genis-Laval",
                "Saint-Priest",
                "Sainte-Foy-les-Lyon",
                "Tarare",
                "Tassin-la-Demi-Lune",
                "Vaulx-en-Velin",
                "Venissieux",
                "Villefranche-sur-Saone",
                "Villeurbanne"
            ],
            "Rhone-Alpes": [
                "Beauvoir-en-Royans",
                "Belley",
                "Bons-en-Chablais",
                "Chalain-d\\'Uzore",
                "Chassieu",
                "Chavanod",
                "Chazay-d-Azergues",
                "Chimilin",
                "Civrieux-d Azergues",
                "Corbas",
                "Courzieu",
                "Dardilly",
                "Guereins",
                "Izernore",
                "La Talaudiere",
                "La Tronche",
                "La Verpilliere",
                "Le Cheylard",
                "Le Cheylas",
                "Mery",
                "Moirans",
                "Montalieu-Vercieu",
                "Montmiral",
                "Peronnas",
                "Poncin",
                "Quincie-en-Beaujolais",
                "Saint-Quentin-sur-Isere",
                "Sainte Agathe la Bouteresse",
                "Sainte-Consorce",
                "Sisteron",
                "Trevoux",
                "Villard-Bonnot"
            ],
            "Saint Ouen": [
                "Beauvoir-en-Royans",
                "Belley",
                "Bons-en-Chablais",
                "Chalain-d\\'Uzore",
                "Chassieu",
                "Chavanod",
                "Chazay-d-Azergues",
                "Chimilin",
                "Civrieux-d Azergues",
                "Corbas",
                "Courzieu",
                "Dardilly",
                "Guereins",
                "Izernore",
                "La Talaudiere",
                "La Tronche",
                "La Verpilliere",
                "Le Cheylard",
                "Le Cheylas",
                "Mery",
                "Moirans",
                "Montalieu-Vercieu",
                "Montmiral",
                "Peronnas",
                "Poncin",
                "Quincie-en-Beaujolais",
                "Saint-Quentin-sur-Isere",
                "Sainte Agathe la Bouteresse",
                "Sainte-Consorce",
                "Sisteron",
                "Trevoux",
                "Villard-Bonnot"
            ],
            "Saint Viatre": [
                "Beauvoir-en-Royans",
                "Belley",
                "Bons-en-Chablais",
                "Chalain-d\\'Uzore",
                "Chassieu",
                "Chavanod",
                "Chazay-d-Azergues",
                "Chimilin",
                "Civrieux-d Azergues",
                "Corbas",
                "Courzieu",
                "Dardilly",
                "Guereins",
                "Izernore",
                "La Talaudiere",
                "La Tronche",
                "La Verpilliere",
                "Le Cheylard",
                "Le Cheylas",
                "Mery",
                "Moirans",
                "Montalieu-Vercieu",
                "Montmiral",
                "Peronnas",
                "Poncin",
                "Quincie-en-Beaujolais",
                "Saint-Quentin-sur-Isere",
                "Sainte Agathe la Bouteresse",
                "Sainte-Consorce",
                "Sisteron",
                "Trevoux",
                "Villard-Bonnot"
            ],
            "Saone-et-Loire": [
                "Autun",
                "Chalon-sur-Saone",
                "Digoin",
                "Gueugnon",
                "Le Creusot",
                "Macon",
                "Montceau-les-Mines",
                "Paray-le-Monial",
                "Saint-Vallier"
            ],
            "Sarthe": [
                "Allonnes",
                "La Ferte-Bernard",
                "La Fleche",
                "Le Mans",
                "Sable-sur-Sarthe"
            ],
            "Savoie": [
                "Aix-les-Bains",
                "Albertville",
                "Chambery",
                "La Motte-Servolex",
                "Saint-Jean-de-Maurienne"
            ],
            "Seine-et-Marne": [
                "Avon",
                "Brie-Comte-Robert",
                "Champs-sur-Marne",
                "Chelles",
                "Claye-Souilly",
                "Combs-la-Ville",
                "Coulommiers",
                "Dammarie-les-Lys",
                "Fontainebleau",
                "Lagny-sur-Marne",
                "Le Mee-sur-Seine",
                "Lognes",
                "Meaux",
                "Melun",
                "Mitry-Mory",
                "Moissy-Cramayel",
                "Montereau-Fault-Yonne",
                "Nemours",
                "Noisiel",
                "Ozoir-la-Ferriere",
                "Pontault-Combault",
                "Provins",
                "Roissy-en-Brie",
                "Saint-Fargeau-Ponthierry",
                "Savigny-le-Temple",
                "Torcy",
                "Vaires-sur-Marne",
                "Veneux",
                "Villeparisis"
            ],
            "Seine-Maritime": [
                "Barentin",
                "Bihorel",
                "Bois-Guillaume",
                "Bolbec",
                "Canteleu",
                "Caudebec-les-Elbeuf",
                "Darnetal",
                "Deville-les-Rouen",
                "Dieppe",
                "Elbeuf",
                "Fecamp",
                "Gonfreville-l\\'Orcher",
                "Grand-Couronne",
                "Harfleur",
                "Le Grand-Quevilly",
                "Le Havre",
                "Le Petit-Quevilly",
                "Lillebonne",
                "Maromme",
                "Mont-Saint-Aignan",
                "Montivilliers",
                "Notre-Dame-de-Gravenchon",
                "Oissel",
                "Rouen",
                "Saint-Etienne-du-Rouvray",
                "Sotteville-les-Rouen",
                "Yvetot"
            ],
            "Seine-Saint-Denis": [
                "Aubervillers",
                "Aulnay-sous-Bois",
                "Bagnolet",
                "Bobigny",
                "Bondy",
                "Clichy-sous-Bois",
                "Drancy",
                "Epinay-sur-Seine",
                "Gagny",
                "La Courneuve",
                "Le Blanc-Mesnil",
                "Le Bourget",
                "Le Pre-Saint-Gervais",
                "Le Raincy",
                "Les Lilas",
                "Les Pavillons-sous-Bois",
                "Livry-Gargan",
                "Montfermeil",
                "Montreuil",
                "Neuilly-Plaisance",
                "Neuilly-sur-Marne",
                "Noisy-le-Grand",
                "Noisy-le-Sec",
                "Pantin",
                "Pierrefitte-sur-Seine",
                "Romainville",
                "Rosny-sous-Bois",
                "Saint-Denis",
                "Saint-Ouen",
                "Sevran",
                "Stains",
                "Tremblay-en-France",
                "Villemomble",
                "Villepinte",
                "Villetaneuse"
            ],
            "Somme": [
                "Abbeville",
                "Albert",
                "Amiens"
            ],
            "Sophia Antipolis": [
                "Abbeville",
                "Albert",
                "Amiens"
            ],
            "Souvans": [
                "Souvans"
            ],
            "Tarn": [
                "Albi",
                "Carmaux",
                "Castres",
                "Gaillac",
                "Graulhet",
                "Mazamet"
            ],
            "Tarn-et-Garonne": [
                "Castelsarassin",
                "Moissac",
                "Montauban"
            ],
            "Territoire de Belfort": [
                "Castelsarassin",
                "Moissac",
                "Montauban"
            ],
            "Treignac": [
                "Chamberet"
            ],
            "Upper Normandy": [
                "Chamberet"
            ],
            "Val-de-Marne": [
                "Alfortville",
                "Arcueil",
                "Boissy-Saint-Leger",
                "Bonneuil",
                "Bry-sur-Marne",
                "Cachan",
                "Champigny-sur-Marne",
                "Charenton-le-Pont",
                "Chennevieres-sur-Marne",
                "Chevilly-Larue",
                "Choisy-le-Roi",
                "Creteil",
                "Fontenay-sous-Bois",
                "Fresnes",
                "Gentilly",
                "Ivry-sur-Seine",
                "Joinville-le-Pont",
                "La Queue-en-Brie",
                "Le Kremlin-Bicetre",
                "Le Perreux-sur-Marne",
                "Le Plessis-Trevise",
                "Limeil-Brevannes",
                "L\\'Hay-les-Roses",
                "Maisons-Alfort",
                "Nogent-sur-Marne",
                "Orly",
                "Ormesson-sur-Marne",
                "Saint-Mande",
                "Saint-Maur-des-Fosses",
                "Saint-Maurice",
                "Sucy-en-Brie",
                "Thiais",
                "Valenton",
                "Villejuif",
                "Villeneuve-le-Roi",
                "Villeneuve-Saint-Georges",
                "Villiers-sur-Marne",
                "Vincennes",
                "Vitry-sur-Seine"
            ],
            "Val-d\\'Oise": [
                "Argenteuil",
                "Arnouville-les-Gonesse",
                "Beauchamps",
                "Bezons",
                "Cergy",
                "Cormeilles-en-Parisis",
                "Deuil-la-Barre",
                "Domont",
                "Eaubonne",
                "Enghien-les-Bains",
                "Eragny",
                "Ermont",
                "Ezanville",
                "Fosses",
                "Franconville",
                "Garges-les-Gonesse",
                "Gonesse",
                "Goussainville",
                "Herblay",
                "Jouy-le-Moutier",
                "L\\'Isle-Adam",
                "Montigny-les-Cormeilles",
                "Montmagny",
                "Montmorency",
                "Osny",
                "Persan",
                "Pontoise",
                "Saint-Brice-sous-Foret",
                "Saint-Gratien",
                "Saint-Leu-la-Foret",
                "Saint-Ouen-l\\'Aumone",
                "Sannois",
                "Sarcelles",
                "Soisy-sous-Montmorency",
                "Taverny",
                "Vaureal",
                "Villiers-le-Bel"
            ],
            "Var": [
                "Brignoles",
                "Draguignan",
                "Frejus",
                "Hyeres",
                "La Crau",
                "La Garde",
                "La Seyne-sur-Mer",
                "La Valette-du-Var",
                "Le Pradet",
                "Ollioules",
                "Roquebrune-sur-Argens",
                "Saint-Maximin-la-Sainte-Baume",
                "Saint-Raphael",
                "Sainte-Maxime",
                "Sanary-sur-Mer",
                "Six-Fours-les-Plages",
                "Sollies-Pont",
                "Toulon"
            ],
            "Vaucluse": [
                "Apt",
                "Avignon",
                "Bollene",
                "Carpentras",
                "Cavaillon",
                "Le Pontet",
                "L\\'Isle-sur-la-Sorgue",
                "Orange",
                "Pertuis",
                "Sorgues",
                "Valreas"
            ],
            "Vellise": [
                "Vellise"
            ],
            "Vendee": [
                "Challans",
                "Chateau-d\\'Olonne",
                "Fontenay-le-Comte",
                "La Chasnis",
                "La Roche-sur-Yon",
                "Les Herbiers",
                "Les Sables-d\\'Olonne",
                "Longeville-sur-Mer"
            ],
            "Vienne": [
                "Chatellerault",
                "Poitiers"
            ],
            "Vosges": [
                "Epinal",
                "Gerardmer",
                "Remiremont",
                "Saint-Die"
            ],
            "Yonne": [
                "Auxerre",
                "Avallon",
                "Joigny",
                "Sens"
            ],
            "Yvelines": [
                "Acheres",
                "Andresy",
                "Aubergenville",
                "Bois-d\\'Arcy",
                "Carrieres-sous-Poissy",
                "Carrieres-sur-Seine",
                "Chanteloup-les-Vignes",
                "Chatou",
                "Conflans-Sainte-Honorine",
                "Croissy-sur-Seine",
                "Elancourt",
                "Fontenay-le-Fleury",
                "Guyancourt",
                "Houilles",
                "La Celle-Saint-Cloud",
                "Le Chesnay",
                "Le Pecq",
                "Le Vesinet",
                "Les Clayes-sous-Bois",
                "Les Mureaux",
                "Limay",
                "Maisons-Laffitte",
                "Mantes-la-Jolie",
                "Mantes-la-Ville",
                "Marly-le-Roi",
                "Maurepas",
                "Montesson",
                "Montigny-le-Bretonneux",
                "Plaisir",
                "Poissy",
                "Rambouillet",
                "Saint-Cyr-l\\'Ecole",
                "Saint-Germain-en-Laye",
                "Sartrouville",
                "Trappes",
                "Triel-sur-Seine",
                "Velizy-Villacoublay",
                "Verneuil-sur-Seine",
                "Versailles",
                "Viroflay",
                "Voisins-le-Bretonneux"
            ]
        }
    },
    {
        "id": 76,
        "name": "French Guiana",
        "iso3": "GUF",
        "iso2": "GF",
        "phone_code": "594",
        "capital": "Cayenne",
        "currency": "EUR",
        "states": {
            "Cayenne": [
                "Camopi",
                "Cayenne",
                "Iracoubo",
                "Kourou",
                "Macouria",
                "Matoury",
                "Remire-Montjoly",
                "Roura",
                "Saint-Georges",
                "Sinnamary"
            ],
            "Saint-Laurent-du-Maroni": [
                "Apatou",
                "Grand-Santi",
                "Mana",
                "Maripasoula",
                "Saint-Laurent-du-Maroni"
            ]
        }
    },
    {
        "id": 77,
        "name": "French Polynesia",
        "iso3": "PYF",
        "iso2": "PF",
        "phone_code": "689",
        "capital": "Papeete",
        "currency": "XPF",
        "states": {
            "Iles du Vent": [
                "Apatou",
                "Grand-Santi",
                "Mana",
                "Maripasoula",
                "Saint-Laurent-du-Maroni"
            ],
            "Iles sous le Vent": [
                "Apatou",
                "Grand-Santi",
                "Mana",
                "Maripasoula",
                "Saint-Laurent-du-Maroni"
            ],
            "Marquesas": [
                "Atuona",
                "Fatu-Hiva",
                "Hakahao",
                "Hakamaii",
                "Hatiheu",
                "Tahuata",
                "Taiohae",
                "Taipivai",
                "Ua-Huka"
            ],
            "Tuamotu": [
                "Ahe",
                "Amanu",
                "Anaa",
                "Apataki",
                "Arutua",
                "Faaite",
                "Fakahima",
                "Fakarava",
                "Fangatau",
                "Hao",
                "Hereheretue",
                "Hikueru",
                "Katiu",
                "Kauehi",
                "Kaukura",
                "Makatea",
                "Makemo",
                "Manihi",
                "Marokau",
                "Napuka",
                "Niau",
                "Nukutavake",
                "Puamau",
                "Pukapuka",
                "Pukarua",
                "Rangiroa",
                "Raroia",
                "Reao",
                "Rikitea",
                "Taenga",
                "Takapoto",
                "Takaroa",
                "Tatakoto",
                "Tepoto",
                "Tikehau",
                "Tureia",
                "Vahitahi",
                "Vairaatea"
            ],
            "Tubuai": [
                "Amaru",
                "Anapoto",
                "Anatonu",
                "Auti",
                "Avera",
                "Mahu",
                "Mataura",
                "Moerai",
                "Mutuaura",
                "Rairua",
                "Rapa",
                "Taahuaia",
                "Vaiuru"
            ]
        }
    },
    {
        "id": 78,
        "name": "French Southern Territories",
        "iso3": "ATF",
        "iso2": "TF",
        "phone_code": "",
        "capital": "Port-aux-Francais",
        "currency": "EUR",
        "states": {
            "Amsterdam": [
                "Martin-de-Vivies"
            ],
            "Crozet Islands": [
                "Martin-de-Vivies"
            ],
            "Kerguelen": [
                "Port-aux-Francais"
            ]
        }
    },
    {
        "id": 79,
        "name": "Gabon",
        "iso3": "GAB",
        "iso2": "GA",
        "phone_code": "241",
        "capital": "Libreville",
        "currency": "XAF",
        "states": {
            "Estuaire": [
                "Cocobeach",
                "Kango",
                "Libreville",
                "Nkan",
                "Ntoum"
            ],
            "Haut-Ogooue": [
                "Lekoni",
                "Masuku",
                "Moanda",
                "Mounana",
                "Okandja"
            ],
            "Moyen-Ogooue": [
                "Lambarene",
                "Ndjole"
            ],
            "Ngounie": [
                "Fougamou",
                "Mbigou",
                "Mimongo",
                "Mouila",
                "Ndende"
            ],
            "Nyanga": [
                "Mayumba",
                "Tchibanga",
                "Tsogni"
            ],
            "Ogooue-Ivindo": [
                "Booue",
                "Makokou",
                "Mekambo"
            ],
            "Ogooue-Lolo": [
                "Koulamoutou",
                "Lastoursville"
            ],
            "Ogooue-Maritime": [
                "Gamba",
                "Omboue",
                "Port-Gentil",
                "Sette Cama"
            ],
            "Woleu-Ntem": [
                "Bitam",
                "Lalara",
                "Medouneu",
                "Minvoul",
                "Mitzic",
                "Oyem"
            ]
        }
    },
    {
        "id": 80,
        "name": "Gambia The",
        "iso3": "GMB",
        "iso2": "GM",
        "phone_code": "220",
        "capital": "Banjul",
        "currency": "GMD",
        "states": {
            "Banjul": [
                "Banjul"
            ],
            "Basse": [
                "Basse",
                "Gambissara",
                "Sabi",
                "Salikeni"
            ],
            "Brikama": [
                "Brikama",
                "Brufut",
                "Gunjur",
                "Sukuta"
            ],
            "Janjanbureh": [
                "Bansang",
                "Janjanbureh"
            ],
            "Kanifing": [
                "Bakau",
                "Serekunda"
            ],
            "Kerewan": [
                "Barra",
                "Essau",
                "Farafenni",
                "Kerewan",
                "Lamin"
            ],
            "Kuntaur": [
                "Kuntaur"
            ],
            "Mansakonko": [
                "Mansakonko"
            ]
        }
    },
    {
        "id": 81,
        "name": "Georgia",
        "iso3": "GEO",
        "iso2": "GE",
        "phone_code": "995",
        "capital": "Tbilisi",
        "currency": "GEL",
        "states": {
            "Abhasia": [
                "Ahali Atoni",
                "Bihvinta",
                "Gagra",
                "Gali",
                "Gudauta",
                "Gulripshi",
                "Ochamchira",
                "Suhumi",
                "Tkvarcheli"
            ],
            "Ajaria": [
                "Batumi",
                "Dioknisi",
                "Kobuleti",
                "Mahindzhauri"
            ],
            "Guria": [
                "Lanchhuti",
                "Ozurgeti"
            ],
            "Imereti": [
                "Bagdadi",
                "Chaltubo",
                "Chiatura",
                "Honi",
                "Kutaisi",
                "Sachhere",
                "Samtredia",
                "Terzhola",
                "Tkibuli",
                "Vani",
                "Zestaponi"
            ],
            "Kaheti": [
                "Ahmeta",
                "Cnori",
                "Dedoplisckaro",
                "Gurdzhaani",
                "Kvareli",
                "Lagodehi",
                "Sagaredzho",
                "Signahi",
                "Telavi"
            ],
            "Kvemo Kartli": [
                "Ahmeta",
                "Cnori",
                "Dedoplisckaro",
                "Gurdzhaani",
                "Kvareli",
                "Lagodehi",
                "Sagaredzho",
                "Signahi",
                "Telavi"
            ],
            "Mcheta-Mtianeti": [
                "Dusheti",
                "Mcheta"
            ],
            "Racha": [
                "Ambrolauri",
                "Cageri",
                "Oni"
            ],
            "Samagrelo-Zemo Svaneti": [
                "Ambrolauri",
                "Cageri",
                "Oni"
            ],
            "Samche-Zhavaheti": [
                "Ahalcihe",
                "Ahalkalaki",
                "Borzhomi",
                "Ninocminda",
                "Vale"
            ],
            "Shida Kartli": [
                "Ahalcihe",
                "Ahalkalaki",
                "Borzhomi",
                "Ninocminda",
                "Vale"
            ],
            "Tbilisi": [
                "Tbilisi"
            ]
        }
    },
    {
        "id": 82,
        "name": "Germany",
        "iso3": "DEU",
        "iso2": "DE",
        "phone_code": "49",
        "capital": "Berlin",
        "currency": "EUR",
        "states": {
            "Auvergne": [
                "Tbilisi"
            ],
            "Baden-Wurttemberg": [
                "Aalen",
                "Achern",
                "Aichtal",
                "Albstadt",
                "Aldingen",
                "Allmersbach",
                "Alpirsbach",
                "Altensteig",
                "Altlussheim",
                "Ammerbuch",
                "Appenweier",
                "Asperg",
                "Backnang",
                "Bad Durrheim",
                "Bad Friedrichshall",
                "Bad Krozingen",
                "Bad Liebenzell",
                "Bad Mergentheim",
                "Bad Rappenau",
                "Bad Sackingen",
                "Bad Schonborn",
                "Bad Urach",
                "Bad Waldsee",
                "Bad Wurzach",
                "Baden-Baden",
                "Bahlingen",
                "Baiersbronn",
                "Balgheim",
                "Balingen",
                "Ballrechten-Dottingen",
                "Besigheim",
                "Biberach",
                "Bietigheim-Bissingen",
                "Binzen",
                "Birkenfeld",
                "Bisingen",
                "Bitz",
                "Blaubeuren",
                "Blaustein",
                "Blumberg",
                "Boblingen",
                "Bodelshausen",
                "Bonndorf",
                "Bonnigheim",
                "Bopfingen",
                "Bottingen",
                "Brackenheim",
                "Breisach",
                "Bretten",
                "Bretzfeld",
                "Bruchsal",
                "Bruhl",
                "Buchen",
                "Buchenbach",
                "Buhl",
                "Burgstetten",
                "Burladingen",
                "Calw",
                "Crailsheim",
                "Denkendorf",
                "Denzlingen",
                "Dettingen",
                "Dietenheim",
                "Ditzingen",
                "Donaueschingen",
                "Donzdorf",
                "Dornhan",
                "Dornstadt",
                "Dornstetten",
                "Dossenheim",
                "Durbheim",
                "Durmersheim",
                "Eberbach",
                "Ebersbach",
                "Edingen",
                "Edingen-Neckarhausen",
                "Eggenstein-Leopoldshafen",
                "Ehingen",
                "Eislingen",
                "Ellhofen",
                "Ellwangen",
                "Emmendingen",
                "Emmingen-Liptingen",
                "Engen",
                "Eningen",
                "Eppelheim",
                "Eppingen",
                "Erbach",
                "Eriskirch",
                "Eschach",
                "Esslingen",
                "Ettenheim",
                "Ettlingen",
                "Fellbach",
                "Filderstadt",
                "Freiberg",
                "Freiburg",
                "Freudenstadt",
                "Frickenhausen",
                "Fridingen",
                "Friedrichshafen",
                "Friesenheim",
                "Frittlingen",
                "Furtwangen",
                "Gaggenau",
                "Gaildorf",
                "Gartringen",
                "Gaufelden",
                "Geislingen",
                "Gengenbach",
                "Gerlingen",
                "Gernsbach",
                "Gerstetten",
                "Giengen",
                "Goppingen",
                "Gottmadingen",
                "Graben-Neudorf",
                "Grafenberg",
                "Grafenhausen",
                "Grenzach-Wyhlen",
                "GroBbettlingen",
                "Grunkraut",
                "Gschwend",
                "Guglingen",
                "Gundelfingen",
                "Gutach",
                "Haigerloch",
                "Haiterbach",
                "Harmersabch",
                "Hausach",
                "Hechingen",
                "Heddesheim",
                "Heidelberg",
                "Heidenheim",
                "Heilbronn",
                "Hemsbach",
                "Herbrechtingen",
                "Herrenberg",
                "Heubach",
                "Hirschberg",
                "Hockenheim",
                "Holzgerlingen",
                "Horb",
                "Hufingen",
                "Huttlingen",
                "Isny",
                "Ispringen",
                "Jungingen",
                "Karlsbad",
                "Karlsruhe",
                "Kehl",
                "Kernen",
                "Ketsch",
                "Kieselbronn",
                "Kirchberg an der Jagst",
                "Kirchheim",
                "Kirchzarten",
                "Kisslegg",
                "Klettgau",
                "Kongen",
                "Konigsbach-Stein",
                "Konigsbronn",
                "Konstanz",
                "Korb",
                "Korntal-Munchingen",
                "Kornwestheim",
                "Kraichtal",
                "Kressbronn an Bodensee",
                "Kronau",
                "Kuessaberg",
                "Kunzelsau",
                "Ladenburg",
                "Lahr",
                "Laichingen",
                "Langenau",
                "Lauda-Konigshofen",
                "Lauffen",
                "Laupheim",
                "Leimen",
                "Leinfelden-Echterdingen",
                "Leingarten",
                "Lenningen",
                "Lenzkirch",
                "Leonberg",
                "Leutenbach",
                "Leutkirch",
                "Lichtenstein",
                "Linkenheim-Hochstetten",
                "Lorch",
                "Lorrach",
                "Ludwigsburg",
                "Malsch",
                "Mannheim",
                "Marbach",
                "Markdorf",
                "Markgroningen",
                "Maulburg",
                "MeBstetten",
                "Meckenbeuren",
                "Meckesheim",
                "Meersburg",
                "Mengen",
                "Metzingen",
                "Mockmuhl",
                "Moglingen",
                "Monsheim",
                "Mosbach",
                "Mossingen",
                "Muhlacker",
                "Mullheim",
                "Munsingen",
                "Murrhardt",
                "Nagold",
                "Neckargemund",
                "Neckarsulm",
                "Neresheim",
                "Neuenburg",
                "Neuhausen",
                "Niederstetten",
                "Niefern-Oschelbronn",
                "NuBloch",
                "Nurtingen",
                "Oberhausen-Rheinhausen",
                "Oberkirch",
                "Oberndorf",
                "Oberstenfeld",
                "Obersulm",
                "Ochsenhausen",
                "Offenburg",
                "Ofterdingen",
                "Oftersheim",
                "Oggelshausen",
                "Ohringen",
                "Olbronn-Durrn",
                "Oppenweiler",
                "Ostfildern",
                "Ostringen",
                "Otisheim",
                "Pfalzgrafenweiler",
                "Pfinztal",
                "Pforzheim",
                "Pfullendorf",
                "Pfullingen",
                "Philippsburg",
                "Plankstadt",
                "Pleidelsheim",
                "Pliezhausen",
                "Plochingen",
                "Pluderhausen",
                "Radolfzell",
                "Rastatt",
                "Ravensburg",
                "Reilingen",
                "Remchingen",
                "Remseck",
                "Remshalden",
                "Renchen",
                "Renningen",
                "Reutlingen",
                "Rheinau",
                "Rheinfelden",
                "Rheinmunster",
                "Rheinstetten",
                "Riederich",
                "Riedlingen",
                "Rielasingen-Worblingen",
                "Rosenfeld",
                "Rottenburg",
                "Rottweil",
                "Rudersberg",
                "Rutesheim",
                "Sachsenheim",
                "Salem",
                "Sandhausen",
                "Sankt Georgen",
                "Sankt Leon-Rot",
                "Saulgau",
                "Scheer",
                "Schlierbach",
                "Schonaich",
                "Schopfheim",
                "Schorndorf",
                "Schramberg",
                "Schriesheim",
                "Schwabisch Gmund",
                "Schwabisch Hall",
                "Schwaigern",
                "Schwetzingen",
                "Schwieberdingen",
                "Seitingen-Oberflacht",
                "Sexau",
                "Sigmaringen",
                "Sindelfingen",
                "Singen",
                "Sinsheim",
                "Sinzheim",
                "Sonnenbuhl",
                "Sontheim",
                "Spaichingen",
                "Stegen",
                "Steinen",
                "Steinheim",
                "Steinmauern",
                "Stockach",
                "Straubenhardt",
                "Stutensee",
                "Stuttgart",
                "SuBen",
                "Sulz",
                "Sulzfeld",
                "Tamm",
                "Tauberbischofsheim",
                "Teningen",
                "Tettnang",
                "Titisee-Neustadt",
                "Trossingen",
                "Tubingen",
                "Tuningen",
                "Tuttlingen",
                "Uberlingen",
                "Ubstadt-Weiher",
                "Uhingen",
                "Ulm",
                "Umkirch",
                "Vaihingen",
                "Villingen-Schwenningen",
                "Villingendorf",
                "Waghausel",
                "Waiblingen",
                "Waldbronn",
                "Waldkirch",
                "Waldlaubersheim",
                "Waldshut-Tiengen",
                "Walldorf",
                "Walldurn",
                "Walzbachtal",
                "Wangen",
                "Wehr",
                "Weikersheim",
                "Weil",
                "Weil am Rhein",
                "Weil der Stadt",
                "Weil im Schonbuch",
                "Weilheim",
                "Weingarten",
                "Weinheim",
                "Weinsberg",
                "Weinstadt",
                "Wellendingen",
                "Welzheim",
                "Wendlingen",
                "Wernau",
                "Wertheim",
                "Wiesloch",
                "Wildbad",
                "Wildberg",
                "Winnenden",
                "Wolpertshausen",
                "Zuzenhausen"
            ],
            "Bavaria": [
                "Ahorn",
                "Allershausen",
                "Attenhofen",
                "Bad Birnbach",
                "Bad Endorf",
                "Bad Gronenbach",
                "Barbing",
                "Benediktbeuern",
                "Breitenberg",
                "Bruckberg",
                "Brunnthal",
                "Burgheim",
                "Chiemsee",
                "Emersacker",
                "Eresing",
                "Fahrenzhausen",
                "Faulbach",
                "Finning",
                "Forstinning",
                "Georgensgmund",
                "Haldenwang",
                "Heideck",
                "Heimenkirch",
                "Hergensweiler",
                "Hirschau",
                "Iffeldorf",
                "Karlstein",
                "Kleinheubach",
                "Kleinwallstadt",
                "Kotz",
                "Krailling",
                "Langenbach",
                "Laudenbach",
                "Leutershausen",
                "Mammendorf",
                "Mombris",
                "Munnerstadt",
                "Neu-Ulm",
                "Niederlauer",
                "Obernburg",
                "Oberpfaffenhofen",
                "Ostallgau",
                "Prittriching",
                "Prutting",
                "Pullach",
                "Putzbrunn",
                "Randersacker",
                "Rednitzhembach",
                "Ronsberg",
                "Ruckersdorf",
                "Schaufling",
                "Schonberg",
                "Seefeld",
                "Sengenthal",
                "Sondheim v.d.Rhon",
                "Steinwiesen",
                "Stockheim",
                "Tagmersheim",
                "Uettingen",
                "Unterfohring",
                "Weibensberg",
                "Wiesthal",
                "Wildflecken",
                "Wolfertschwenden",
                "Zorneding"
            ],
            "Bayern": [
                "Abensberg",
                "Aichach",
                "Ainring",
                "Altdorf",
                "Altotting",
                "Altusried",
                "Alzenau",
                "Amberg",
                "Amerang",
                "Ansbach",
                "Aschaffenburg",
                "Augsburg",
                "Bad Aibling",
                "Bad Kissingen",
                "Bad Neustadt",
                "Bad Reichenhall",
                "Bad Tolz",
                "Bad Windsheim",
                "Bad Worishofen",
                "Bamberg",
                "Bayreuth",
                "Bobingen",
                "Bogen",
                "Bruckmuhl",
                "Buchloe",
                "Burghausen",
                "Burgkirchen",
                "Burglengenfeld",
                "Burgthann",
                "Buttenheim",
                "Cadolzburg",
                "Castell",
                "Cham",
                "Coburg",
                "Dachau",
                "Deggendorf",
                "DieBen",
                "Diedorf",
                "Dietmannsried",
                "Dietramszell",
                "Dillingen",
                "Dingolfing",
                "Dinkelsbuhl",
                "Dombuhl",
                "Donauworth",
                "Dorfen",
                "Ebersberg",
                "Eching",
                "Eckental",
                "Eggenfelden",
                "Eichenau",
                "Eichstatt",
                "Elchingen",
                "Erding",
                "Ergolding",
                "Erlangen",
                "Erlenbach",
                "Essenbach",
                "Feldafing",
                "Feldkirchen-Westerham",
                "Feucht",
                "Feuchtwangen",
                "Forchheim",
                "Freilassing",
                "Freising",
                "Fridolfing",
                "Friedberg",
                "Furstenfeldbruck",
                "Furth",
                "Furth im Wald",
                "Fussen",
                "Gachenbach",
                "Gaimersheim",
                "Garching",
                "Garmisch-Partenkirchen",
                "Gauting",
                "Gemunden",
                "Geretsried",
                "Germering",
                "Gersthofen",
                "Gilching",
                "Goldbach",
                "Grafelfing",
                "Grafenau",
                "Grafing",
                "Grobenstadt",
                "Grobenzell",
                "GroBostheim",
                "Grunwald",
                "Gunzburg",
                "Gunzenhausen",
                "Haar",
                "HaBfurt",
                "Hammelburg",
                "Hasloch",
                "Hauzenberg",
                "Helmbrechts",
                "Henfenfeld",
                "Hersbruck",
                "Herzogenaurach",
                "Hilpoltstein",
                "Hirschaid",
                "Hochstadt",
                "Hof",
                "Holzkirchen",
                "Hosbach",
                "Illertissen",
                "Immenstadt",
                "Ingolstadt",
                "Inning",
                "Ismaning",
                "Karlsfeld",
                "Karlstadt",
                "Kaufbeuren",
                "Kelheim",
                "Kempten",
                "Kiefersfelden",
                "Kirchheim",
                "Kissing",
                "Kitzingen",
                "Kleinostheim",
                "Klingenberg",
                "Kolbermoor",
                "Konigsbrunn",
                "Kreuzwertheim",
                "Kronach",
                "Krumbach",
                "Kulmbach",
                "Kummersbruck",
                "Landau",
                "Landsberg",
                "Landshut",
                "Langenzenn",
                "Lappersdorf",
                "Lauf",
                "Lauingen",
                "Leinburg",
                "Lenggries",
                "Lichtenfels",
                "Lindach",
                "Lindau",
                "Lindenberg",
                "Lohr",
                "Mainburg",
                "Maisach",
                "Manching",
                "Markt Schwaben",
                "Marktheidenfeld",
                "Marktoberdorf",
                "Marktredwitz",
                "Maxhutte-Haidhof",
                "Meitingen",
                "Memmingen",
                "Mering",
                "Miesbach",
                "Miltenberg",
                "Mindelheim",
                "Moosburg",
                "Muhldorf",
                "Munchberg",
                "Munchen",
                "Munningen",
                "Murnau",
                "Naila",
                "Neubiberg",
                "Neuburg",
                "Neufahrn",
                "Neumarkt",
                "Neuried",
                "NeusaB",
                "Neustadt",
                "Neutraubling",
                "Nordlingen",
                "Nuremberg",
                "Nurnberg",
                "Oberasbach",
                "Oberhaching",
                "OberschleiBheim",
                "Oberstdorf",
                "Ochsenfurt",
                "Olching",
                "Osterhofen",
                "Ostheim",
                "Ottobrunn",
                "Parsberg",
                "Passau",
                "Pegnitz",
                "PeiBenberg",
                "Peiting",
                "Pentling",
                "Penzberg",
                "Pfaffenhofen",
                "Pfarrkirchen",
                "Planegg",
                "Plattling",
                "Pocking",
                "Poing",
                "Polling",
                "Pommelsbrunn",
                "Prien",
                "Puchheim",
                "Rannersdorf",
                "Raubling",
                "Regen",
                "Regensburg",
                "Regenstauf",
                "Rehau",
                "Rodental",
                "Roding",
                "Rosenheim",
                "Roth",
                "Rothenbach",
                "Rothenburg",
                "Ruhstorf",
                "Schnelldorf",
                "Schongau",
                "Schrobenhausen",
                "Schwabach",
                "Schwabmunchen",
                "Schwandorf",
                "Schwarzenbach",
                "Schweinfurt",
                "Selb",
                "Selbitz",
                "Senden",
                "Simbach",
                "Sonthofen",
                "Spalt",
                "Stadtbergen",
                "Staffelstein",
                "Starnberg",
                "Stein",
                "Stephanskirchen",
                "Straubing",
                "Sulzbach-Rosenberg",
                "Taufkirchen",
                "Thansau",
                "Tirschenreuth",
                "Toging",
                "Traunreut",
                "Traunstein",
                "Treuchtlingen",
                "Trostberg",
                "Tutzing",
                "Unterbergen",
                "Unterhaching",
                "UnterschleiBheim",
                "Valley",
                "Vaterstetten",
                "Veitshochheim",
                "Viechtach",
                "Vilgertshofen",
                "Vilsbiburg",
                "Vilshofen",
                "Vohringen",
                "Volkach",
                "Waldkirchen",
                "Waldkraiburg",
                "Walkertshofen",
                "Wasserburg",
                "WeiBenburg",
                "WeiBenhorn",
                "Weiden",
                "Weidenberg",
                "Weilheim",
                "Wendelstein",
                "Werneck",
                "Wessling",
                "Wolfratshausen",
                "Wolnzach",
                "Wunsiedel",
                "Wurzburg",
                "Zirndorf",
                "Zwiesel"
            ],
            "Beilstein Wurtt": [
                "Abensberg",
                "Aichach",
                "Ainring",
                "Altdorf",
                "Altotting",
                "Altusried",
                "Alzenau",
                "Amberg",
                "Amerang",
                "Ansbach",
                "Aschaffenburg",
                "Augsburg",
                "Bad Aibling",
                "Bad Kissingen",
                "Bad Neustadt",
                "Bad Reichenhall",
                "Bad Tolz",
                "Bad Windsheim",
                "Bad Worishofen",
                "Bamberg",
                "Bayreuth",
                "Bobingen",
                "Bogen",
                "Bruckmuhl",
                "Buchloe",
                "Burghausen",
                "Burgkirchen",
                "Burglengenfeld",
                "Burgthann",
                "Buttenheim",
                "Cadolzburg",
                "Castell",
                "Cham",
                "Coburg",
                "Dachau",
                "Deggendorf",
                "DieBen",
                "Diedorf",
                "Dietmannsried",
                "Dietramszell",
                "Dillingen",
                "Dingolfing",
                "Dinkelsbuhl",
                "Dombuhl",
                "Donauworth",
                "Dorfen",
                "Ebersberg",
                "Eching",
                "Eckental",
                "Eggenfelden",
                "Eichenau",
                "Eichstatt",
                "Elchingen",
                "Erding",
                "Ergolding",
                "Erlangen",
                "Erlenbach",
                "Essenbach",
                "Feldafing",
                "Feldkirchen-Westerham",
                "Feucht",
                "Feuchtwangen",
                "Forchheim",
                "Freilassing",
                "Freising",
                "Fridolfing",
                "Friedberg",
                "Furstenfeldbruck",
                "Furth",
                "Furth im Wald",
                "Fussen",
                "Gachenbach",
                "Gaimersheim",
                "Garching",
                "Garmisch-Partenkirchen",
                "Gauting",
                "Gemunden",
                "Geretsried",
                "Germering",
                "Gersthofen",
                "Gilching",
                "Goldbach",
                "Grafelfing",
                "Grafenau",
                "Grafing",
                "Grobenstadt",
                "Grobenzell",
                "GroBostheim",
                "Grunwald",
                "Gunzburg",
                "Gunzenhausen",
                "Haar",
                "HaBfurt",
                "Hammelburg",
                "Hasloch",
                "Hauzenberg",
                "Helmbrechts",
                "Henfenfeld",
                "Hersbruck",
                "Herzogenaurach",
                "Hilpoltstein",
                "Hirschaid",
                "Hochstadt",
                "Hof",
                "Holzkirchen",
                "Hosbach",
                "Illertissen",
                "Immenstadt",
                "Ingolstadt",
                "Inning",
                "Ismaning",
                "Karlsfeld",
                "Karlstadt",
                "Kaufbeuren",
                "Kelheim",
                "Kempten",
                "Kiefersfelden",
                "Kirchheim",
                "Kissing",
                "Kitzingen",
                "Kleinostheim",
                "Klingenberg",
                "Kolbermoor",
                "Konigsbrunn",
                "Kreuzwertheim",
                "Kronach",
                "Krumbach",
                "Kulmbach",
                "Kummersbruck",
                "Landau",
                "Landsberg",
                "Landshut",
                "Langenzenn",
                "Lappersdorf",
                "Lauf",
                "Lauingen",
                "Leinburg",
                "Lenggries",
                "Lichtenfels",
                "Lindach",
                "Lindau",
                "Lindenberg",
                "Lohr",
                "Mainburg",
                "Maisach",
                "Manching",
                "Markt Schwaben",
                "Marktheidenfeld",
                "Marktoberdorf",
                "Marktredwitz",
                "Maxhutte-Haidhof",
                "Meitingen",
                "Memmingen",
                "Mering",
                "Miesbach",
                "Miltenberg",
                "Mindelheim",
                "Moosburg",
                "Muhldorf",
                "Munchberg",
                "Munchen",
                "Munningen",
                "Murnau",
                "Naila",
                "Neubiberg",
                "Neuburg",
                "Neufahrn",
                "Neumarkt",
                "Neuried",
                "NeusaB",
                "Neustadt",
                "Neutraubling",
                "Nordlingen",
                "Nuremberg",
                "Nurnberg",
                "Oberasbach",
                "Oberhaching",
                "OberschleiBheim",
                "Oberstdorf",
                "Ochsenfurt",
                "Olching",
                "Osterhofen",
                "Ostheim",
                "Ottobrunn",
                "Parsberg",
                "Passau",
                "Pegnitz",
                "PeiBenberg",
                "Peiting",
                "Pentling",
                "Penzberg",
                "Pfaffenhofen",
                "Pfarrkirchen",
                "Planegg",
                "Plattling",
                "Pocking",
                "Poing",
                "Polling",
                "Pommelsbrunn",
                "Prien",
                "Puchheim",
                "Rannersdorf",
                "Raubling",
                "Regen",
                "Regensburg",
                "Regenstauf",
                "Rehau",
                "Rodental",
                "Roding",
                "Rosenheim",
                "Roth",
                "Rothenbach",
                "Rothenburg",
                "Ruhstorf",
                "Schnelldorf",
                "Schongau",
                "Schrobenhausen",
                "Schwabach",
                "Schwabmunchen",
                "Schwandorf",
                "Schwarzenbach",
                "Schweinfurt",
                "Selb",
                "Selbitz",
                "Senden",
                "Simbach",
                "Sonthofen",
                "Spalt",
                "Stadtbergen",
                "Staffelstein",
                "Starnberg",
                "Stein",
                "Stephanskirchen",
                "Straubing",
                "Sulzbach-Rosenberg",
                "Taufkirchen",
                "Thansau",
                "Tirschenreuth",
                "Toging",
                "Traunreut",
                "Traunstein",
                "Treuchtlingen",
                "Trostberg",
                "Tutzing",
                "Unterbergen",
                "Unterhaching",
                "UnterschleiBheim",
                "Valley",
                "Vaterstetten",
                "Veitshochheim",
                "Viechtach",
                "Vilgertshofen",
                "Vilsbiburg",
                "Vilshofen",
                "Vohringen",
                "Volkach",
                "Waldkirchen",
                "Waldkraiburg",
                "Walkertshofen",
                "Wasserburg",
                "WeiBenburg",
                "WeiBenhorn",
                "Weiden",
                "Weidenberg",
                "Weilheim",
                "Wendelstein",
                "Werneck",
                "Wessling",
                "Wolfratshausen",
                "Wolnzach",
                "Wunsiedel",
                "Wurzburg",
                "Zirndorf",
                "Zwiesel"
            ],
            "Berlin": [
                "Berlin",
                "Panketal",
                "Steinfeld"
            ],
            "Brandenburg": [
                "Angermunde",
                "Bad Freienwalde",
                "Bad Liebenwerda",
                "Barnim",
                "Beeskow",
                "Bernau",
                "Blankenfelde",
                "Brandenburg",
                "Brieselang",
                "Cottbus",
                "Dahlewitz",
                "Dahme",
                "Eberswalde",
                "Eisenhuttenstadt",
                "Elsterwerda",
                "Erkner",
                "Falkensee",
                "Finsterwalde",
                "Forst",
                "Frankfurt",
                "Fredersdorf-Vogelsdorf",
                "Furstenwalde",
                "Glienicke",
                "Gransee",
                "GroBraschen",
                "Guben",
                "Heidesee",
                "Hennigsdorf",
                "Herzberg",
                "Hohen Neuendorf",
                "Jacobsdorf",
                "Juterbog",
                "Kleinmachnow",
                "Kolkwitz",
                "Konigs Wusterhausen",
                "Kyritz",
                "Lauchhammer",
                "Lubben",
                "Lubbenau",
                "Luckenwalde",
                "Ludwigsfelde",
                "Nauen",
                "Neuenhagen",
                "Neuruppin",
                "Oranienburg",
                "Perleberg",
                "Petershagen-Eggersdorf",
                "Potsdam",
                "Premnitz",
                "Prenzlau",
                "Pritzwalk",
                "Rathenow",
                "Rudersdorf",
                "Schonefeld",
                "Schoneiche",
                "Schwedt",
                "Schwielowsee",
                "Senftenberg",
                "Spremberg",
                "Strausberg",
                "Teltow",
                "Templin",
                "Velten",
                "Werder",
                "Wildau",
                "Wittenberge",
                "Wittstock",
                "Zehdenick",
                "Zepernick"
            ],
            "Bremen": [
                "Bremen",
                "Bremerhaven"
            ],
            "Dreisbach": [
                "Bremen",
                "Bremerhaven"
            ],
            "Freistaat Bayern": [
                "Bremen",
                "Bremerhaven"
            ],
            "Hamburg": [
                "Berne",
                "Hamburg"
            ],
            "Hannover": [
                "Diekholzen"
            ],
            "Heroldstatt": [
                "Heroldstatt"
            ],
            "Hessen": [
                "ABlar",
                "Alsbach-H\u00c3\u0192\u00c2\u0192\u00c3\u2020\u00c2\u2019\u00c3\u0192\u00c2\u201a\u00c3\u201a\u00c2\u00a4hnlein",
                "Alsfeld",
                "Altenstadt",
                "Angelburg",
                "Arolsen",
                "Asslar",
                "Babenhausen",
                "Bad Camberg",
                "Bad Hersfeld",
                "Bad Homburg",
                "Bad Nauheim",
                "Bad Orb",
                "Bad Schwalbach",
                "Bad Soden",
                "Bad Soden-Salmunster",
                "Bad Sooden-Allendorf",
                "Bad Vilbel",
                "Bad Wildungen",
                "Baunatal",
                "Bebra",
                "Beerfelden",
                "Bensheim",
                "Berkatal",
                "Biblis",
                "Bickenbach",
                "Biebertal",
                "Biedenkopf",
                "Birkenau",
                "Bischofsheim",
                "Borken",
                "Braunfels",
                "Breidenbach",
                "Bruchkobel",
                "Budingen",
                "Burstadt",
                "Buseck",
                "Buttelborn",
                "Butzbach",
                "Darmstadt",
                "Dautphetal",
                "Dieburg",
                "Dietzenbach",
                "Dillenburg",
                "Dreieich",
                "Egelsbach",
                "Eichenzell",
                "Eltville",
                "Eppstein",
                "Erbach",
                "Erlensee",
                "Erzhausen",
                "Eschborn",
                "Eschenburg",
                "Eschwege",
                "Felsberg",
                "Fernwald",
                "Florsheim",
                "Frankenberg",
                "Frankfurt",
                "Freigericht",
                "Friedberg",
                "Friedrichsdorf",
                "Fritzlar",
                "Fulda",
                "Fuldabruck",
                "Fuldatal",
                "Geisenheim",
                "Gelnhausen",
                "Gernsheim",
                "GieBen",
                "Giessen",
                "Ginsheim-Gustavsburg",
                "Gladenbach",
                "Griesheim",
                "GroB-Gerau",
                "GroB-Umstadt",
                "GroB-Zimmern",
                "Grossenluder",
                "Gruenberg",
                "Grunberg",
                "Grundau",
                "Hadamar",
                "Haiger",
                "Hainburg",
                "Hanau",
                "Hattersheim",
                "Heppenheim",
                "Herborn",
                "Hessisch Lichtenau",
                "Heuchelheim",
                "Heusenstamm",
                "Hochheim",
                "Hochst",
                "Hofbieber",
                "Hofgeismar",
                "Hofheim",
                "Homberg",
                "Hunfeld",
                "Hunfelden",
                "Hungen",
                "Huttenberg",
                "Idstein",
                "Karben",
                "Kassel",
                "Kaufungen",
                "Kelkheim",
                "Kelsterbach",
                "Kirchhain",
                "Konigstein",
                "Korbach",
                "Kriftel",
                "Kronberg",
                "Kunzell",
                "Lahnau",
                "Lahntal",
                "Lampertheim",
                "Langen",
                "Langenselbold",
                "Langgons",
                "Laubach",
                "Lauterbach",
                "Lich",
                "Limburg",
                "Linden",
                "Lindenholzhausen",
                "Linsengericht",
                "Lohfelden",
                "Lollar",
                "Lorsch",
                "Maintal",
                "Marburg",
                "Melsungen",
                "Mengerskirchen",
                "Michelstadt",
                "Morfelden-Walldorf",
                "Morlenbach",
                "Mucke",
                "Muhlheim",
                "Muhltal",
                "Munster",
                "Nauheim",
                "Neu-Anspach",
                "Neu-Isenburg",
                "Neuhof",
                "Neustadt",
                "Nidda",
                "Niddatal",
                "Nidderau",
                "Niederdorfelden",
                "Niedernhausen",
                "Niestetal",
                "Ober-Ramstedt",
                "Obertshausen",
                "Oberursel",
                "Oestrich-Winkel",
                "Offenbach",
                "Petersberg",
                "Pfungstadt",
                "Pohlheim",
                "Raunheim",
                "Reichelsheim",
                "Reinheim",
                "Reiskirchen",
                "Riedstadt",
                "Rimbach",
                "RoBdorf",
                "Rodenbach",
                "Rodermark",
                "Rodgau",
                "Rosbach",
                "Rotenburg",
                "Rudesheim",
                "Runkel",
                "Russelsheim",
                "Schauenburg",
                "Schlangenbad",
                "Schlitz",
                "Schluchtern",
                "Schoneck",
                "Schotten",
                "Schwalbach",
                "Schwalbach am Taunus",
                "Schwalmstadt",
                "Seeheim-Jugenheim",
                "Seligenstadt",
                "Selters",
                "Sinntal",
                "Solms",
                "Sontra",
                "Spangenberg",
                "Stadtallendorf",
                "Steinau",
                "Steinbach",
                "Taunusstein",
                "Trebur",
                "Ulrichstein",
                "Usingen",
                "Vellmar",
                "Viernheim",
                "Volkmarsen",
                "Wachtersbach",
                "Wald-Michelbach",
                "Waldbrunn",
                "Waldems",
                "Wehrheim",
                "Weilburg",
                "Weilmunster",
                "Weiterstadt",
                "Wettenberg",
                "Wetter",
                "Wetzlar",
                "Wiesbaden",
                "Witzenhausen",
                "Wolfersheim",
                "Wolfhagen",
                "Zwingenberg"
            ],
            "Kortenberg": [
                "Kortenberg"
            ],
            "Laasdorf": [
                "Laasdorf"
            ],
            "Land Baden-Wurttemberg": [
                "Laasdorf"
            ],
            "Land Bayern": [
                "Laasdorf"
            ],
            "Land Brandenburg": [
                "Laasdorf"
            ],
            "Land Hessen": [
                "Laasdorf"
            ],
            "Land Mecklenburg-Vorpommern": [
                "Laasdorf"
            ],
            "Land Nordrhein-Westfalen": [
                "Laasdorf"
            ],
            "Land Rheinland-Pfalz": [
                "Laasdorf"
            ],
            "Land Sachsen": [
                "Laasdorf"
            ],
            "Land Sachsen-Anhalt": [
                "Laasdorf"
            ],
            "Land Thuringen": [
                "Laasdorf"
            ],
            "Lower Saxony": [
                "Laasdorf"
            ],
            "Mecklenburg-Vorpommern": [
                "Anklam",
                "Bad Doberan",
                "Barth",
                "Bergen",
                "Boizenburg",
                "Butzow",
                "Demmin",
                "Gagelow",
                "Gallin",
                "Grabow",
                "Greifswald",
                "Grevesmuhlen",
                "Grimmen",
                "Gustrow",
                "Hagenow",
                "Konigsee",
                "Lubtheen",
                "Ludersdorf",
                "Ludwigslust",
                "Malchin",
                "Neubrandenburg",
                "Neustrelitz",
                "Parchim",
                "Pasewalk",
                "Ribnitz-Damgarten",
                "Rostock",
                "SaBnitz",
                "Schweina",
                "Schwerin",
                "Selmsdorf",
                "Stralsund",
                "Teterow",
                "Torgelow",
                "Ueckermunde",
                "Waren",
                "Wismar",
                "Wolgast",
                "Zarrentin"
            ],
            "Mulfingen": [
                "Mulfingen"
            ],
            "Munich": [
                "Grafing bei Munchen"
            ],
            "Neubeuern": [
                "Neubeuern"
            ],
            "Niedersachsen": [
                "Achim",
                "Adendorf",
                "Aerzen",
                "Alfeld",
                "Ankum",
                "Apen",
                "Aurich",
                "Bad Bentheim",
                "Bad Bevensen",
                "Bad Essen",
                "Bad Gandersheim",
                "Bad Harzburg",
                "Bad Iburg",
                "Bad Laer",
                "Bad Lauterberg",
                "Bad Munder",
                "Bad Nenndorf",
                "Bad Pyrmont",
                "Bad Sachsa",
                "Bad Salzdetfurth",
                "Bad Zwischenahn",
                "BarBel",
                "Barsinghausen",
                "Bassum",
                "Beesten",
                "Belm",
                "Bergen",
                "Bissendorf",
                "Bleckede",
                "Bockenem",
                "Bohmte",
                "Bovenden",
                "Brake",
                "Bramsche",
                "Braunschweig",
                "Bremervorde",
                "Brockel",
                "Brunswick",
                "Buchholz",
                "Buckeburg",
                "Burgdorf",
                "Burgwedel",
                "Buxtehude",
                "Celle",
                "Clausthal-Zellerfeld",
                "Clenze",
                "Cloppenburg",
                "Cremlingen",
                "Cuxhaven",
                "Dahlenburg",
                "Damme",
                "Dannenberg",
                "Dassel",
                "Deinste",
                "Delmenhorst",
                "Diepholz",
                "Dinklage",
                "Dorverden",
                "Dransfeld",
                "Drochtersen",
                "Duderstadt",
                "Edemissen",
                "Edewecht",
                "Einbeck",
                "Elsfleth",
                "Elze",
                "Emden",
                "Emlichheim",
                "Emmerthal",
                "Emsburen",
                "Eppendorf",
                "Fallingbostel",
                "Frellstedt",
                "Friedeburg",
                "Friedland",
                "Friesoythe",
                "Ganderkesee",
                "Garbsen",
                "Garrel",
                "Geeste",
                "Gehrden",
                "Georgsmarienhutte",
                "Gifhorn",
                "Gleichen",
                "Goslar",
                "Gottingen",
                "GroBefehn",
                "GroBenkneten",
                "Hagen",
                "Hambergen",
                "Hameln",
                "Hannover",
                "Hannoversch Munden",
                "Haren",
                "Harsum",
                "Hasbergen",
                "Haselunne",
                "Hatten",
                "Hauslingen",
                "Helmstedt",
                "Hemmingen",
                "Herzberg",
                "Hessisch Oldendorf",
                "Hildesheim",
                "Hilter",
                "Hittfeld",
                "Holzminden",
                "Hoya",
                "Hude",
                "Ihlow",
                "Ilsede",
                "Isernhagen",
                "Jade",
                "Jever",
                "Jork",
                "Kalefeld",
                "Kirchlinteln",
                "Knesebeck",
                "Konigslutter",
                "Krummhorn",
                "Laatzen",
                "Lahstedt",
                "Langelsheim",
                "Langen",
                "Langenhagen",
                "Langwedel",
                "Lastrup",
                "Leer",
                "Lehre",
                "Lehrte",
                "Lemforde",
                "Lengede",
                "Liebenburg",
                "Lilienthal",
                "Lingen",
                "Lohne",
                "Loningen",
                "Lorup",
                "Loxstedt",
                "Luchow",
                "Luneburg",
                "Melle",
                "Meppen",
                "Moormerland",
                "Munster",
                "Neu Wulmstorf",
                "Neustadt",
                "Nienburg",
                "Norden",
                "Nordenham",
                "Nordhorn",
                "Nordstemmen",
                "Norten-Hardenberg",
                "Northeim",
                "Obernkirchen",
                "Oldenburg",
                "Osnabruck",
                "Ostercappeln",
                "Osterholz-Scharmbeck",
                "Osterode",
                "Ostrhauderfehn",
                "Ottersberg",
                "Oyten",
                "Papenburg",
                "Pattensen",
                "Peine",
                "Quakenbruck",
                "Rastede",
                "Rehburg-Loccum",
                "Rhauderfehn",
                "Rinteln",
                "Ritterhude",
                "Ronnenberg",
                "Rosdorf",
                "Rosengarten",
                "Rotenburg",
                "Salzgitter",
                "Salzhemmendorf",
                "Sarstedt",
                "Saterland",
                "ScheeBel",
                "Schiffdorf",
                "Schneverdingen",
                "Schoningen",
                "Schortens",
                "Schuttorf",
                "Schwanewede",
                "Seelze",
                "Seesen",
                "Seevetal",
                "Sehnde",
                "Soltau",
                "Springe",
                "Stade",
                "Stadthagen",
                "Stadtoldendorf",
                "Stelle",
                "Stuhr",
                "Sudbrookmerland",
                "Sulingen",
                "Syke",
                "Tarmstedt",
                "Tostedt",
                "Twistringen",
                "Uchte",
                "Uelzen",
                "Uetze",
                "Uplengen",
                "Uslar",
                "Varel",
                "Vechelde",
                "Vechta",
                "Verden",
                "Vienenburg",
                "Visselhovede",
                "Walkenried",
                "Wallenhorst",
                "Walsrode",
                "Wangerland",
                "Wardenburg",
                "Wedemark",
                "Weener",
                "Wendeburg",
                "Wennigsen",
                "Westerstede",
                "Westoverledingen",
                "Weyhe",
                "Wiefelstede",
                "Wiesmoor",
                "Wildeshausen",
                "Wilhelmshaven",
                "Winsen",
                "Wittingen",
                "Wittmund",
                "Wolfenbuttel",
                "Wolfsburg",
                "Worpswede",
                "Wunstorf",
                "Zetel",
                "Zeven"
            ],
            "Noord-Holland": [
                "Middenbeemster"
            ],
            "Nordrhein-Westfalen": [
                "Aachen",
                "Ahaus",
                "Ahlen",
                "Aldenhoven",
                "Alfter",
                "Alpen",
                "Alsdorf",
                "Altena",
                "Altendorf",
                "Anrochte",
                "Arnsberg",
                "Ascheberg",
                "Attendorn",
                "Augustdorf",
                "Bad Berleburg",
                "Bad Driburg",
                "Bad Honnef",
                "Bad Laasphe",
                "Bad Lippspringe",
                "Bad Munstereifel",
                "Bad Oeynhausen",
                "Bad Salzuflen",
                "Bad Sassendorf",
                "Baesweiler",
                "Balve",
                "Barntrup",
                "Beckum",
                "Bedburg",
                "Bedburg-Hau",
                "Bergheim",
                "Bergisch Gladbach",
                "Bergkamen",
                "Bergneustadt",
                "Bestwig",
                "Beverungen",
                "Bielefeld",
                "Billerbeck",
                "Blomberg",
                "Bocholt",
                "Bochum",
                "Bocket",
                "Bonen",
                "Bonn",
                "Borchen",
                "Borgentreich",
                "Borgholzhausen",
                "Borken",
                "Bornheim",
                "Bottrop",
                "Brakel",
                "Brilon",
                "Bruggen",
                "Bruhl",
                "Bunde",
                "Burbach",
                "Buren",
                "Burscheid",
                "Castrop-Rauxel",
                "Coesfeld",
                "Cologne",
                "Datteln",
                "Delbruck",
                "Denklingen",
                "Detmold",
                "Dingden",
                "Dinslaken",
                "Dormagen",
                "Dorsten",
                "Dortmund",
                "Drensteinfurt",
                "Drolshagen",
                "Duisburg",
                "Dulmen",
                "Duren",
                "Dusseldorf",
                "Eitorf",
                "Elsdorf",
                "Emmerich",
                "Emsdetten",
                "Engelskirchen",
                "Enger",
                "Ennepetal",
                "Ennigerloh",
                "Ense",
                "Erftstadt",
                "Erkelenz",
                "Erkrath",
                "Erwitte",
                "Espelkamp",
                "Essen",
                "Euskirchen",
                "Extertal",
                "Finnentrop",
                "Frechen",
                "Freudenberg",
                "Frondenberg",
                "Gangelt",
                "Geilenkirchen",
                "Geldern",
                "Gelsenkirchen",
                "Gescher",
                "Geseke",
                "Gevelsberg",
                "Gladbeck",
                "Goch",
                "Grefrath",
                "Greven",
                "Grevenbroich",
                "Gronau",
                "Gummersbach",
                "Gutersloh",
                "Haan",
                "Hagen",
                "Halle",
                "Haltern",
                "Halver",
                "Hamm",
                "Hamminkeln",
                "Harsewinkel",
                "Hattingen",
                "Havixbeck",
                "Heiligenhaus",
                "Heinsberg",
                "Hemer",
                "Hennef",
                "Herdecke",
                "Herford",
                "Herne",
                "Herten",
                "Herzebrock-Clarholz",
                "Herzogenrath",
                "Hiddenhausen",
                "Hilchenbach",
                "Hilden",
                "Hille",
                "Holzwickede",
                "Horn-Bad Meinberg",
                "Horstel",
                "Hovelhof",
                "Hoxter",
                "Huckelhoven",
                "Huckeswagen",
                "Hullhorst",
                "Hunxe",
                "Hurth",
                "Ibbenburen",
                "Iserlohn",
                "Isselburg",
                "Issum",
                "Juchen",
                "Julich",
                "Kaarst",
                "Kalkar",
                "Kall",
                "Kalletal",
                "Kamen",
                "Kamp-Lintfort",
                "Kempen",
                "Kerken",
                "Kerpen",
                "Kevelaer",
                "Kierspe",
                "Kirchhundem",
                "Kirchlengern",
                "Kleve",
                "Koln",
                "Konigswinter",
                "Korschenbroich",
                "Krefeld",
                "Kreuzau",
                "Kreuztal",
                "Kurten",
                "Lage",
                "Langenfeld",
                "Langerwehe",
                "Leichlingen",
                "Lemgo",
                "Lengerich",
                "Lennestadt",
                "Leopoldshohe",
                "Leverkusen",
                "Lichtenau",
                "Lindlar",
                "Linnich",
                "Lippetal",
                "Lippstadt",
                "Lohmar",
                "Lohne",
                "Lotte",
                "Lubbecke",
                "Ludenscheid",
                "Ludinghausen",
                "Lugde",
                "Lunen",
                "Marienheide",
                "Marl",
                "Marsberg",
                "Mechernich",
                "Meckenheim",
                "Meerbusch",
                "Meinerzhagen",
                "Menden",
                "Meschede",
                "Mettingen",
                "Mettmann",
                "Minden",
                "Moers",
                "Mohnesee",
                "Monchengladbach",
                "Monheim",
                "Monschau",
                "Morsbach",
                "Much",
                "Mulheim",
                "Munster",
                "Netphen",
                "Nettetal",
                "Neuenkirchen",
                "Neuenrade",
                "Neukirchen-Vluyn",
                "Neunkirchen",
                "Neunkirchen-Seelscheid",
                "Neuss",
                "Nideggen",
                "Niederkassel",
                "Niederkruchten",
                "Niederzier",
                "Nordkirchen",
                "Norvenich",
                "Nottuln",
                "Numbrecht",
                "Oberhausen",
                "Ochtrup",
                "Odenthal",
                "Oelde",
                "Oer-Erkenschwick",
                "Oerlinghausen",
                "Olfen",
                "Olpe",
                "Olsberg",
                "Overath",
                "Paderborn",
                "Petershagen",
                "Plettenberg",
                "Porta Westfalica",
                "PreuBisch Oldendorf",
                "Pulheim",
                "Radevormwald",
                "Raesfeld",
                "Rahden",
                "Ratingen",
                "Recke",
                "Recklinghausen",
                "Rees",
                "Reichshof",
                "Reken",
                "Remscheid",
                "Rheda-Wiedenbruck",
                "Rhede",
                "Rheinbach",
                "Rheinberg",
                "Rheine",
                "Rietberg",
                "Rommerskirchen",
                "Rosendahl",
                "Rosrath",
                "Ruthen",
                "Salzkotten",
                "Sassenberg",
                "Schalksmuhle",
                "Schermbeck",
                "Schieder-Schwalenberg",
                "Schleiden",
                "SchloB Holte-Stukenbrock",
                "Schmallenberg",
                "Schwalmtal",
                "Schwelm",
                "Schwerte",
                "Selm",
                "Senden",
                "Sendenhorst",
                "Siegburg",
                "Siegen",
                "Simmerath",
                "Soest",
                "Solingen",
                "Sonsbeck",
                "Spenge",
                "Sprockhovel",
                "Stadtlohn",
                "Steinfurt",
                "Steinhagen",
                "Steinheim",
                "Stemwede",
                "Stolberg",
                "Straelen",
                "Sundern",
                "Swisttal",
                "Tecklenburg",
                "Telgte",
                "Tonisvorst",
                "Troisdorf",
                "Ubach-Palenberg",
                "Unna",
                "Velbert",
                "Velen",
                "Verl",
                "Versmold",
                "Viersen",
                "Vlotho",
                "Voerde",
                "Vreden",
                "Wachtberg",
                "Wachtendonk",
                "Wadersloh",
                "Waldbrol",
                "Waltrop",
                "Warburg",
                "Warendorf",
                "Warstein",
                "Wassenberg",
                "Weeze",
                "Wegberg",
                "Weilerswist",
                "Welver",
                "Wenden",
                "Werdohl",
                "Werl",
                "Wermelskirchen",
                "Werne",
                "Werther",
                "Wesel",
                "Wesseling",
                "Westerkappeln",
                "Wetter",
                "Wickede",
                "Wiehl",
                "Willich",
                "Wilnsdorf",
                "Windeck",
                "Winterberg",
                "Wipperfurth",
                "Witten",
                "Wulfrath",
                "Wunnenberg",
                "Wuppertal",
                "Wurselen",
                "Xanten",
                "Zulpich"
            ],
            "North Rhine-Westphalia": [
                "Aachen",
                "Ahaus",
                "Ahlen",
                "Aldenhoven",
                "Alfter",
                "Alpen",
                "Alsdorf",
                "Altena",
                "Altendorf",
                "Anrochte",
                "Arnsberg",
                "Ascheberg",
                "Attendorn",
                "Augustdorf",
                "Bad Berleburg",
                "Bad Driburg",
                "Bad Honnef",
                "Bad Laasphe",
                "Bad Lippspringe",
                "Bad Munstereifel",
                "Bad Oeynhausen",
                "Bad Salzuflen",
                "Bad Sassendorf",
                "Baesweiler",
                "Balve",
                "Barntrup",
                "Beckum",
                "Bedburg",
                "Bedburg-Hau",
                "Bergheim",
                "Bergisch Gladbach",
                "Bergkamen",
                "Bergneustadt",
                "Bestwig",
                "Beverungen",
                "Bielefeld",
                "Billerbeck",
                "Blomberg",
                "Bocholt",
                "Bochum",
                "Bocket",
                "Bonen",
                "Bonn",
                "Borchen",
                "Borgentreich",
                "Borgholzhausen",
                "Borken",
                "Bornheim",
                "Bottrop",
                "Brakel",
                "Brilon",
                "Bruggen",
                "Bruhl",
                "Bunde",
                "Burbach",
                "Buren",
                "Burscheid",
                "Castrop-Rauxel",
                "Coesfeld",
                "Cologne",
                "Datteln",
                "Delbruck",
                "Denklingen",
                "Detmold",
                "Dingden",
                "Dinslaken",
                "Dormagen",
                "Dorsten",
                "Dortmund",
                "Drensteinfurt",
                "Drolshagen",
                "Duisburg",
                "Dulmen",
                "Duren",
                "Dusseldorf",
                "Eitorf",
                "Elsdorf",
                "Emmerich",
                "Emsdetten",
                "Engelskirchen",
                "Enger",
                "Ennepetal",
                "Ennigerloh",
                "Ense",
                "Erftstadt",
                "Erkelenz",
                "Erkrath",
                "Erwitte",
                "Espelkamp",
                "Essen",
                "Euskirchen",
                "Extertal",
                "Finnentrop",
                "Frechen",
                "Freudenberg",
                "Frondenberg",
                "Gangelt",
                "Geilenkirchen",
                "Geldern",
                "Gelsenkirchen",
                "Gescher",
                "Geseke",
                "Gevelsberg",
                "Gladbeck",
                "Goch",
                "Grefrath",
                "Greven",
                "Grevenbroich",
                "Gronau",
                "Gummersbach",
                "Gutersloh",
                "Haan",
                "Hagen",
                "Halle",
                "Haltern",
                "Halver",
                "Hamm",
                "Hamminkeln",
                "Harsewinkel",
                "Hattingen",
                "Havixbeck",
                "Heiligenhaus",
                "Heinsberg",
                "Hemer",
                "Hennef",
                "Herdecke",
                "Herford",
                "Herne",
                "Herten",
                "Herzebrock-Clarholz",
                "Herzogenrath",
                "Hiddenhausen",
                "Hilchenbach",
                "Hilden",
                "Hille",
                "Holzwickede",
                "Horn-Bad Meinberg",
                "Horstel",
                "Hovelhof",
                "Hoxter",
                "Huckelhoven",
                "Huckeswagen",
                "Hullhorst",
                "Hunxe",
                "Hurth",
                "Ibbenburen",
                "Iserlohn",
                "Isselburg",
                "Issum",
                "Juchen",
                "Julich",
                "Kaarst",
                "Kalkar",
                "Kall",
                "Kalletal",
                "Kamen",
                "Kamp-Lintfort",
                "Kempen",
                "Kerken",
                "Kerpen",
                "Kevelaer",
                "Kierspe",
                "Kirchhundem",
                "Kirchlengern",
                "Kleve",
                "Koln",
                "Konigswinter",
                "Korschenbroich",
                "Krefeld",
                "Kreuzau",
                "Kreuztal",
                "Kurten",
                "Lage",
                "Langenfeld",
                "Langerwehe",
                "Leichlingen",
                "Lemgo",
                "Lengerich",
                "Lennestadt",
                "Leopoldshohe",
                "Leverkusen",
                "Lichtenau",
                "Lindlar",
                "Linnich",
                "Lippetal",
                "Lippstadt",
                "Lohmar",
                "Lohne",
                "Lotte",
                "Lubbecke",
                "Ludenscheid",
                "Ludinghausen",
                "Lugde",
                "Lunen",
                "Marienheide",
                "Marl",
                "Marsberg",
                "Mechernich",
                "Meckenheim",
                "Meerbusch",
                "Meinerzhagen",
                "Menden",
                "Meschede",
                "Mettingen",
                "Mettmann",
                "Minden",
                "Moers",
                "Mohnesee",
                "Monchengladbach",
                "Monheim",
                "Monschau",
                "Morsbach",
                "Much",
                "Mulheim",
                "Munster",
                "Netphen",
                "Nettetal",
                "Neuenkirchen",
                "Neuenrade",
                "Neukirchen-Vluyn",
                "Neunkirchen",
                "Neunkirchen-Seelscheid",
                "Neuss",
                "Nideggen",
                "Niederkassel",
                "Niederkruchten",
                "Niederzier",
                "Nordkirchen",
                "Norvenich",
                "Nottuln",
                "Numbrecht",
                "Oberhausen",
                "Ochtrup",
                "Odenthal",
                "Oelde",
                "Oer-Erkenschwick",
                "Oerlinghausen",
                "Olfen",
                "Olpe",
                "Olsberg",
                "Overath",
                "Paderborn",
                "Petershagen",
                "Plettenberg",
                "Porta Westfalica",
                "PreuBisch Oldendorf",
                "Pulheim",
                "Radevormwald",
                "Raesfeld",
                "Rahden",
                "Ratingen",
                "Recke",
                "Recklinghausen",
                "Rees",
                "Reichshof",
                "Reken",
                "Remscheid",
                "Rheda-Wiedenbruck",
                "Rhede",
                "Rheinbach",
                "Rheinberg",
                "Rheine",
                "Rietberg",
                "Rommerskirchen",
                "Rosendahl",
                "Rosrath",
                "Ruthen",
                "Salzkotten",
                "Sassenberg",
                "Schalksmuhle",
                "Schermbeck",
                "Schieder-Schwalenberg",
                "Schleiden",
                "SchloB Holte-Stukenbrock",
                "Schmallenberg",
                "Schwalmtal",
                "Schwelm",
                "Schwerte",
                "Selm",
                "Senden",
                "Sendenhorst",
                "Siegburg",
                "Siegen",
                "Simmerath",
                "Soest",
                "Solingen",
                "Sonsbeck",
                "Spenge",
                "Sprockhovel",
                "Stadtlohn",
                "Steinfurt",
                "Steinhagen",
                "Steinheim",
                "Stemwede",
                "Stolberg",
                "Straelen",
                "Sundern",
                "Swisttal",
                "Tecklenburg",
                "Telgte",
                "Tonisvorst",
                "Troisdorf",
                "Ubach-Palenberg",
                "Unna",
                "Velbert",
                "Velen",
                "Verl",
                "Versmold",
                "Viersen",
                "Vlotho",
                "Voerde",
                "Vreden",
                "Wachtberg",
                "Wachtendonk",
                "Wadersloh",
                "Waldbrol",
                "Waltrop",
                "Warburg",
                "Warendorf",
                "Warstein",
                "Wassenberg",
                "Weeze",
                "Wegberg",
                "Weilerswist",
                "Welver",
                "Wenden",
                "Werdohl",
                "Werl",
                "Wermelskirchen",
                "Werne",
                "Werther",
                "Wesel",
                "Wesseling",
                "Westerkappeln",
                "Wetter",
                "Wickede",
                "Wiehl",
                "Willich",
                "Wilnsdorf",
                "Windeck",
                "Winterberg",
                "Wipperfurth",
                "Witten",
                "Wulfrath",
                "Wunnenberg",
                "Wuppertal",
                "Wurselen",
                "Xanten",
                "Zulpich"
            ],
            "Osterode": [
                "Herzberg am Harz"
            ],
            "Rheinland-Pfalz": [
                "Alzey",
                "Andernach",
                "Bad Durkheim",
                "Bad Ems",
                "Bad Kreuznach",
                "Bad Neuenahr-Ahrweiler",
                "Bendorf",
                "Betzdorf",
                "Bingen",
                "Bitburg",
                "Bobenheim-Roxheim",
                "Bohl-Iggelheim",
                "Boppard",
                "Daun",
                "Diez",
                "Eisenberg",
                "Essingen",
                "Frankenthal",
                "Gau-Odernheim",
                "Germersheim",
                "Grunstadt",
                "HaBloch",
                "Hahnstatten",
                "Hallschlag",
                "Herschbach",
                "Herxheim",
                "Hirschhorn",
                "Hohr-Grenzhausen",
                "Holzheim",
                "Idar-Oberstein",
                "Ingelheim",
                "Kaisersesch",
                "Kaiserslautern",
                "Kastellaun",
                "Kindsbach",
                "Kirchen",
                "Kirn",
                "Koblenz",
                "Lahnstein",
                "Landau",
                "Limburgerhof",
                "Luckenburg",
                "Ludwigshafen",
                "Mainz",
                "Mayen",
                "Montabaur",
                "Morbach",
                "Mulheim-Karlich",
                "Mundersbach",
                "Mutterstadt",
                "Nassau",
                "Neitersen",
                "Neustadt",
                "Neuwied",
                "Niederzissen",
                "Pirmasens",
                "Plaidt",
                "Remagen",
                "Schifferstadt",
                "Schoenenberg Kuebelberg",
                "Sinzig",
                "Speyer",
                "St. Goar",
                "Trier",
                "Vallendar",
                "Winterbach",
                "Wittlich",
                "Worms",
                "Worth",
                "Zweibrucken"
            ],
            "Rhineland-Palatinate": [
                "Adenau",
                "Anhausen",
                "Barbelroth",
                "Berndroth",
                "Bernkastel-Kues",
                "Burgbrohl",
                "Dieblich",
                "Dierdorf",
                "Dreisbach",
                "Elsoff",
                "Enkenbach-Alsenborn",
                "Etzbach",
                "Flonheim",
                "Fohren",
                "Grafschaft",
                "Hochspeyer",
                "Leiningen",
                "Moschheim",
                "Murlenbach",
                "Neuhofen",
                "Nievern",
                "Norken",
                "Oberlahr",
                "Otterstadt",
                "Rennerod",
                "Rheinbreitbach",
                "Rieschweiler-Muhlbach",
                "Saarburg",
                "Stahlhofen",
                "Steinebach",
                "Weinsheim",
                "Winnweiler",
                "Wissen"
            ],
            "Saarland": [
                "Beckingen",
                "Bexbach",
                "Blieskastel",
                "Dillingen",
                "Duppenweiler",
                "Eppelborn",
                "Friedrichsthal",
                "GroBrosseln",
                "Heusweiler",
                "Homburg",
                "Illingen",
                "Kirkel",
                "Kleinblittersdorf",
                "Lebach",
                "Losheim",
                "Mandelbachtal",
                "Marpingen",
                "Merchweiler",
                "Merzig",
                "Mettlach",
                "Nalbach",
                "Neunkirchen",
                "Nohfelden",
                "Nonnweiler",
                "Oberthal",
                "Ottweiler",
                "Puttlingen",
                "Quierschied",
                "Rehlingen-Siersburg",
                "Riegelsberg",
                "Saarbrucken",
                "Saarlouis",
                "Saarwellingen",
                "Sankt Ingbert",
                "Sankt Wendel",
                "Schiffweiler",
                "Schmelz",
                "Schwalbach",
                "Spiesen-Elversberg",
                "Sulzbach",
                "Tholey",
                "Uberherrn",
                "Volklingen",
                "Wadern",
                "Wadgassen",
                "Wallerfangen",
                "Weiskirchen"
            ],
            "Sachsen": [
                "Annaberg-Buchholz",
                "Aue",
                "Auerbach",
                "Bautzen",
                "Bischofswerda",
                "Borna",
                "Brand-Erbisdorf",
                "Burgstadt",
                "Chemnitz",
                "Coswig",
                "Crimmitschau",
                "Delitzsch",
                "Dobeln",
                "Dresden",
                "Ebersbach",
                "Eilenburg",
                "Falkenstein",
                "Floha",
                "Frankenberg",
                "Freiberg",
                "Freital",
                "Friedewald",
                "Glauchau",
                "Gorlitz",
                "Grimma",
                "GroBenhain",
                "Groditz",
                "Hainichen",
                "Heidenau",
                "Hirschstein",
                "Hohenstein-Ernstthal",
                "Hoyerswerda",
                "Kamenz",
                "Klingenthal",
                "Leipzig",
                "Lichtenstein",
                "Limbach-Oberfrohna",
                "Lobau",
                "LoBnitz",
                "Lugau",
                "Marienberg",
                "Markkleeberg",
                "Meerane",
                "MeiBen",
                "Mittweida",
                "Muldenhammer",
                "Neustadt",
                "Niesky",
                "Oelsnitz",
                "Olbernhau",
                "Olbersdorf",
                "Oschatz",
                "Pirna",
                "Plauen",
                "Radeberg",
                "Radebeul",
                "Reichenbach",
                "Riesa",
                "Rietschen",
                "Schkeuditz",
                "Schneeberg",
                "Schwarzenberg",
                "Sebnitz",
                "Stollberg",
                "Taubenheim",
                "Taucha",
                "Thalheim",
                "Torgau",
                "Waldheim",
                "WeiBwasser",
                "Werdau",
                "Wilkau-HaBlau",
                "Wurzen",
                "Zittau",
                "Zschopau",
                "Zwickau",
                "Zwonitz"
            ],
            "Sachsen-Anhalt": [
                "Aken",
                "Aschersleben",
                "Bad Durrenberg",
                "Bebitz",
                "Bernburg",
                "Bitterfeld",
                "Blankenburg",
                "Braunsbedra",
                "Burg",
                "Calbe",
                "Coswig",
                "Dessau",
                "Eisleben",
                "Gardelegen",
                "Genthin",
                "Gommern",
                "Grafenhainichen",
                "Halberstadt",
                "Haldensleben",
                "Halle",
                "Hettstedt",
                "Heyrothsberge",
                "Hotensleben",
                "Kothen",
                "Leuna",
                "Magdeburg",
                "Merseburg",
                "Naumburg",
                "Oschersleben",
                "Osterburg",
                "Osterwieck",
                "Quedlinburg",
                "Querfurt",
                "Raguhn",
                "RoBlau",
                "Salzwedel",
                "Sangerhausen",
                "Schonebeck",
                "StaBfurt",
                "Stendal",
                "Tangermunde",
                "Thale",
                "WeiBenfels",
                "Wittenberg",
                "Wolfen",
                "Wolmirstedt",
                "Zeitz",
                "Zerbst"
            ],
            "Saxony": [
                "Bad Lausick",
                "Bernsdorf",
                "Borde-Hakel",
                "Gelenau",
                "Groberkmannsdorf ",
                "Hartha",
                "Kreischa",
                "Malschwitz",
                "Naunhof",
                "Pausa",
                "Seiffen",
                "Stutzengrun"
            ],
            "Schleswig-Holstein": [
                "Ahrensbok",
                "Ahrensburg",
                "Altenholz",
                "Alveslohe",
                "Ammersbek",
                "Bad Bramstedt",
                "Bad Oldesloe",
                "Bad Schwartau",
                "Bad Segeberg",
                "Bargteheide",
                "Barmstedt",
                "Barsbuttel",
                "Bredstedt",
                "Brunsbuttel",
                "Budelsdorf",
                "Eckernforde",
                "Eddelak",
                "Elmshorn",
                "Eutin",
                "Flensburg",
                "Friedrichstadt",
                "Geesthacht",
                "Glinde",
                "Gluckstadt",
                "Grob Pampau",
                "Halstenbek",
                "Hamfelde",
                "Harrislee",
                "Hartenholm",
                "Heide",
                "Heiligenhafen",
                "Henstedt-Ulzburg",
                "Honenwestedt",
                "Husum",
                "Itzehoe",
                "Kaltenkirchen",
                "Kappeln",
                "Kiel",
                "Kronshagen",
                "Lauenburg",
                "Lensahn",
                "Lubeck",
                "Malente",
                "Mielkendorf",
                "Molfsee",
                "Molln",
                "Neuenbrook",
                "Neumunster",
                "Neustadt",
                "Norderstedt",
                "Oldenburg",
                "Oststeinbek",
                "Pinneberg",
                "Plon",
                "Preetz",
                "Quickborn",
                "Ratekau",
                "Ratzeburg",
                "Reinbek",
                "Reinfeld",
                "Rellingen",
                "Rendsburg",
                "Rethwisch",
                "Satrup",
                "Scharbeutz",
                "Schenefeld",
                "Schleswig",
                "Schmalfeld",
                "Schoenkirchen",
                "Schwarzenbek",
                "Seefeld",
                "Sievershutten",
                "Stockelsdorf",
                "Tangstedt",
                "Timmendorfer Strand",
                "Tornesch",
                "Travemunde",
                "Uetersen",
                "Wahlstedt",
                "Wedel",
                "Wentorf",
                "Westerland",
                "Westerronfeld",
                "Wohltorf",
                "Wotersen"
            ],
            "schlobborn": [
                "Schlobborn"
            ],
            "Thuringia": [
                "Altenburg",
                "Apolda",
                "Arnstadt",
                "Bad Frankenhausen",
                "Bad Langensalza",
                "Bad Salzungen",
                "Cursdorf",
                "Dornburg",
                "Eisenach",
                "Eisenberg",
                "Erfurt",
                "Gera",
                "Geschwenda",
                "Gotha",
                "Greiz",
                "Heiligenstadt",
                "Hermsdorf",
                "Hildburghausen",
                "Ilmenau",
                "Immelborn",
                "Jena",
                "Leinefelde",
                "Leutenberg",
                "Meiningen",
                "Meuselwitz",
                "Muhlhausen",
                "Neustadt",
                "Nordhausen",
                "PoBneck",
                "Rosenthal",
                "Rositz",
                "Rudolstadt",
                "Ruhla",
                "Saalfeld",
                "Schmalkalden",
                "Schmolln",
                "Sommerda",
                "Sondershausen",
                "Sonneberg",
                "Suhl",
                "Triptis",
                "Uhlstadt",
                "Waltershausen",
                "Weida",
                "Weimar",
                "Wernigerode",
                "Wormstedt",
                "Zella-Mehlis",
                "Zeulenroda"
            ],
            "Webling": [
                "Webling"
            ],
            "Weinstrabe": [
                "Neustadt"
            ]
        }
    },
    {
        "id": 83,
        "name": "Ghana",
        "iso3": "GHA",
        "iso2": "GH",
        "phone_code": "233",
        "capital": "Accra",
        "currency": "GHS",
        "states": {
            "Ashanti": [
                "Agogo",
                "Bekwai",
                "Konongo",
                "Kumasi",
                "Mampong",
                "Mankranso",
                "Obuasi",
                "Ofinso",
                "Tafo"
            ],
            "Brong-Ahafo": [
                "Bechem",
                "Berekum",
                "Duayaw Nkwanta",
                "Kintampo",
                "Sunyani",
                "Techiman",
                "Wenchi"
            ],
            "Central": [
                "Apam",
                "Cape Coast",
                "Dunkwa",
                "Elmina",
                "Foso",
                "Komenda",
                "Mauri",
                "Mumford",
                "Nyakrom",
                "Okitsiu",
                "Saltpond",
                "Swedru",
                "Winneba"
            ],
            "Eastern": [
                "Aburi",
                "Ada",
                "Akim Swedru",
                "Akropong",
                "Asamankese",
                "Begoro",
                "Kade",
                "Kibi",
                "Koforidua",
                "Mpraeso",
                "Nkawkaw",
                "Nsawam",
                "Oda",
                "Somanya",
                "Suhum"
            ],
            "Greater Accra": [
                "Aburi",
                "Ada",
                "Akim Swedru",
                "Akropong",
                "Asamankese",
                "Begoro",
                "Kade",
                "Kibi",
                "Koforidua",
                "Mpraeso",
                "Nkawkaw",
                "Nsawam",
                "Oda",
                "Somanya",
                "Suhum"
            ],
            "Northern": [
                "Kpandae",
                "Salaga",
                "Savelugu",
                "Tamale",
                "Yendi"
            ],
            "Upper East": [
                "Kpandae",
                "Salaga",
                "Savelugu",
                "Tamale",
                "Yendi"
            ],
            "Upper West": [
                "Kpandae",
                "Salaga",
                "Savelugu",
                "Tamale",
                "Yendi"
            ],
            "Volta": [
                "Aflao",
                "Anloga",
                "Ho",
                "Hohoe",
                "Keta",
                "Kete-Krachi",
                "Kpandu"
            ],
            "Western": [
                "Aboso",
                "Anomabu",
                "Axim",
                "Bibiani",
                "Prestea",
                "Sekondi",
                "Shama",
                "Takoradi",
                "Tarkwa"
            ]
        }
    },
    {
        "id": 84,
        "name": "Gibraltar",
        "iso3": "GIB",
        "iso2": "GI",
        "phone_code": "350",
        "capital": "Gibraltar",
        "currency": "GIP",
        "states": {
            "Gibraltar": [
                "Gibraltar"
            ]
        }
    },
    {
        "id": 85,
        "name": "Greece",
        "iso3": "GRC",
        "iso2": "GR",
        "phone_code": "30",
        "capital": "Athens",
        "currency": "EUR",
        "states": {
            "Acharnes": [
                "Elassonos"
            ],
            "Ahaia": [
                "Aiyion",
                "Patra"
            ],
            "Aitolia kai Akarnania": [
                "Aiyion",
                "Patra"
            ],
            "Argolis": [
                "Argos",
                "Navplion"
            ],
            "Arkadia": [
                "Tripoli"
            ],
            "Arta": [
                "Arta"
            ],
            "Attica": [
                "Acharnes",
                "Agios Ioannis Rentis",
                "Drapetsona",
                "Koropi",
                "Lavrion",
                "Mandra",
                "Spata"
            ],
            "Attiki": [
                "Aharna",
                "Aiyaleo",
                "Alimos",
                "Amarousion",
                "Ano Liosia",
                "Aryiroupoli",
                "Aspropirgos",
                "Athina",
                "Athinai",
                "Ayia Barbara",
                "Ayia Paraskevi",
                "Ayios Anaryiros",
                "Ayios Dimitrios",
                "Dafne",
                "Elevsis",
                "Ellenikon",
                "Galatsion",
                "Glifada",
                "Haidarion",
                "Halandrion",
                "Holargos",
                "Ilioupoli",
                "Iraklion",
                "Kaisariani",
                "Kallithea",
                "Kamateron",
                "Keratea",
                "Keratsinion",
                "Kifisia",
                "Koridallos",
                "Kropion",
                "Markopoulos Mesogaia",
                "Maroussi",
                "Megara",
                "Melission",
                "Metamorfosios",
                "Moshatos",
                "Nea Filedelfia",
                "Nea Ionia",
                "Nea Liosia",
                "Nea Smirni",
                "Nikaia",
                "Palaion Faliron",
                "Perama",
                "Peristerion",
                "Petroupoli",
                "Pevka",
                "Piraeus",
                "Salamis",
                "Tavros",
                "Viron",
                "Voula",
                "Vrilission",
                "Zografos"
            ],
            "Ayion Oros": [
                "Aharna",
                "Aiyaleo",
                "Alimos",
                "Amarousion",
                "Ano Liosia",
                "Aryiroupoli",
                "Aspropirgos",
                "Athina",
                "Athinai",
                "Ayia Barbara",
                "Ayia Paraskevi",
                "Ayios Anaryiros",
                "Ayios Dimitrios",
                "Dafne",
                "Elevsis",
                "Ellenikon",
                "Galatsion",
                "Glifada",
                "Haidarion",
                "Halandrion",
                "Holargos",
                "Ilioupoli",
                "Iraklion",
                "Kaisariani",
                "Kallithea",
                "Kamateron",
                "Keratea",
                "Keratsinion",
                "Kifisia",
                "Koridallos",
                "Kropion",
                "Markopoulos Mesogaia",
                "Maroussi",
                "Megara",
                "Melission",
                "Metamorfosios",
                "Moshatos",
                "Nea Filedelfia",
                "Nea Ionia",
                "Nea Liosia",
                "Nea Smirni",
                "Nikaia",
                "Palaion Faliron",
                "Perama",
                "Peristerion",
                "Petroupoli",
                "Pevka",
                "Piraeus",
                "Salamis",
                "Tavros",
                "Viron",
                "Voula",
                "Vrilission",
                "Zografos"
            ],
            "Crete": [
                "Heraklion"
            ],
            "Dodekanisos": [
                "Arhangelos",
                "Ialysos",
                "Kos",
                "Rodos"
            ],
            "Drama": [
                "Drama"
            ],
            "Evia": [
                "Chalkis"
            ],
            "Evritania": [
                "Karpenisi"
            ],
            "Evros": [
                "Alexandroupoli",
                "Orestias"
            ],
            "Evvoia": [
                "Halkida"
            ],
            "Florina": [
                "Florina"
            ],
            "Fokis": [
                "Amfissa"
            ],
            "Fthiotis": [
                "Lamia"
            ],
            "Grevena": [
                "Grevena"
            ],
            "Halandri": [
                "Halandri"
            ],
            "Halkidiki": [
                "Lakkoma",
                "N. Kallikrateia",
                "Poliyiros"
            ],
            "Hania": [
                "Hania"
            ],
            "Heraklion": [
                "Crete"
            ],
            "Hios": [
                "Hios"
            ],
            "Ilia": [
                "Pirgos"
            ],
            "Imathia": [
                "Veroia"
            ],
            "Ioannina": [
                "Ioannina"
            ],
            "Iraklion": [
                "Ioannina"
            ],
            "Karditsa": [
                "Karditsa"
            ],
            "Kastoria": [
                "Kastoria"
            ],
            "Kavala": [
                "Kavala"
            ],
            "Kefallinia": [
                "Agioi Theodoroi",
                "Argostolion"
            ],
            "Kerkira": [
                "Kerkira"
            ],
            "Kiklades": [
                "Ermoupoli",
                "Fira",
                "Mikonos"
            ],
            "Kilkis": [
                "Kilkis"
            ],
            "Korinthia": [
                "Korinthos"
            ],
            "Kozani": [
                "Kozani",
                "Ptolemais"
            ],
            "Lakonia": [
                "Sparti"
            ],
            "Larisa": [
                "Larisa",
                "Larissa"
            ],
            "Lasithi": [
                "Ayios Nikolaos",
                "Ierapetra",
                "Sitia"
            ],
            "Lesvos": [
                "Mitilini"
            ],
            "Levkas": [
                "Levkas"
            ],
            "Magnisia": [
                "Volos"
            ],
            "Messinia": [
                "Kalamata"
            ],
            "Nomos Attikis": [
                "Kalamata"
            ],
            "Nomos Zakynthou": [
                "Kalamata"
            ],
            "Pella": [
                "Edessa",
                "Yiannitsa"
            ],
            "Pieria": [
                "Katerini"
            ],
            "Piraios": [
                "Acharne",
                "Pallini"
            ],
            "Preveza": [
                "Preveza"
            ],
            "Rethimni": [
                "Rethimnon"
            ],
            "Rodopi": [
                "Komotini"
            ],
            "Samos": [
                "Samos"
            ],
            "Serrai": [
                "Serrai"
            ],
            "Thesprotia": [
                "Igoumenitsa"
            ],
            "Thessaloniki": [
                "Ampelokipa",
                "Kalamaria",
                "Neapoli",
                "Oristiada",
                "Thessaloniki"
            ],
            "Trikala": [
                "Trikala"
            ],
            "Voiotia": [
                "Levadia",
                "Thivai"
            ],
            "West Greece": [
                "Levadia",
                "Thivai"
            ],
            "Xanthi": [
                "Xanthi"
            ],
            "Zakinthos": [
                "Zakinthos"
            ]
        }
    },
    {
        "id": 86,
        "name": "Greenland",
        "iso3": "GRL",
        "iso2": "GL",
        "phone_code": "299",
        "capital": "Nuuk",
        "currency": "DKK",
        "states": {
            "Aasiaat": [
                "Aasiaat",
                "Akunnaaq",
                "Kitsissuarsuit"
            ],
            "Ammassalik": [
                "Ikkatteq",
                "Isortoq",
                "Kulusuk",
                "Kuumiut",
                "Qernertuarssuit",
                "Sermiligaaq",
                "Tasiilaq",
                "Tiniteqilaaq"
            ],
            "Illoqqortoormiut": [
                "Illoqqortoormiut",
                "Itterajivit",
                "Uunarteq"
            ],
            "Ilulissat": [
                "Ilimanaq",
                "Ilulissat",
                "Oqaatsut",
                "Qeqertaq",
                "Saqqaq"
            ],
            "Ivittuut": [
                "Ivittuut",
                "Kangilinnguit"
            ],
            "Kangaatsiaq": [
                "Attu",
                "Iginniarfik",
                "Ikerasaarsuk",
                "Kangaatsiaq",
                "Niaqornaarsuk"
            ],
            "Maniitsoq": [
                "Atammik",
                "Kangaamiut",
                "Maniitsoq",
                "Napasoq"
            ],
            "Nanortalik": [
                "Aappilattoq",
                "Akuliaruseq",
                "Alluitsoq",
                "Alluitsup Paa",
                "Ammassivik",
                "Ikerasassuaq",
                "Nanortalik",
                "Narsarmijit",
                "Nuugaarsuk",
                "Qallimiut",
                "Qortortorsuaq",
                "Tasiusaq"
            ],
            "Narsaq": [
                "Amannguit",
                "Atarnaatsoq",
                "Eqaluit Ilua",
                "Igaliku",
                "Igaliku Kujalleq",
                "Inneruulalik",
                "Issormiut",
                "Iterlak",
                "Kangerlua",
                "Narsaq",
                "Narsarsuaq",
                "Nunataaq",
                "Qassiarsuk",
                "Qinngua",
                "Qinngua Kangilleq",
                "Qolortup Itinnera",
                "Sillisit",
                "Tasiusaq",
                "Timerliit",
                "Uummannartuuaraq"
            ],
            "Nuuk": [
                "Kangerluarsoruseq",
                "Kapisillit",
                "Neriunaq",
                "Nuuk",
                "Qeqertarsuatsiaat",
                "Qooqqut",
                "Qoornoq"
            ],
            "Paamiut": [
                "Arsuk",
                "Paamiut"
            ],
            "Qaanaaq": [
                "Moriusaq",
                "Qaanaaq",
                "Qeqertarsuaq",
                "Qeqertat",
                "Savissivik",
                "Siorapaluk"
            ],
            "Qaqortoq": [
                "Eqalugaarsuit",
                "Illorsuit",
                "Kangerluarsorujuk",
                "Kangerluarsorujuup Qinngua",
                "Qanisartuut",
                "Qaqortokolook",
                "Qaqortoq",
                "Qassimiut",
                "Saarloq",
                "Saqqamiut",
                "Tasiluk",
                "Upernaviarsuk"
            ],
            "Qasigiannguit": [
                "Ikamiut",
                "Qasigiannguit"
            ],
            "Qeqertarsuaq": [
                "Kangerluk",
                "Qeqertarsuaq"
            ],
            "Sisimiut": [
                "Itilleq",
                "Kangerlussuaq",
                "Sarfannguit",
                "Sisimiut"
            ],
            "Udenfor kommunal inddeling": [
                "Itilleq",
                "Kangerlussuaq",
                "Sarfannguit",
                "Sisimiut"
            ],
            "Upernavik": [
                "Aappilattoq",
                "Ikerakuuk",
                "Innarsuit",
                "Kangersuatsiaq",
                "Kullorsuaq",
                "Naajaat",
                "Nutaarmiut",
                "Nuusuaq",
                "Tasiusaq",
                "Upernavik",
                "Upernavik Kujalleq"
            ],
            "Uummannaq": [
                "Ikerasak",
                "Illorsuit",
                "Niaqornat",
                "Nuugaatsiaq",
                "Qaarsut",
                "Saattut",
                "Ukkusissat",
                "Uummannaq"
            ]
        }
    },
    {
        "id": 87,
        "name": "Grenada",
        "iso3": "GRD",
        "iso2": "GD",
        "phone_code": "+1-473",
        "capital": "St. George's",
        "currency": "XCD",
        "states": {
            "Carriacou-Petite Martinique": [
                "Ikerasak",
                "Illorsuit",
                "Niaqornat",
                "Nuugaatsiaq",
                "Qaarsut",
                "Saattut",
                "Ukkusissat",
                "Uummannaq"
            ],
            "Saint Andrew": [
                "Ikerasak",
                "Illorsuit",
                "Niaqornat",
                "Nuugaatsiaq",
                "Qaarsut",
                "Saattut",
                "Ukkusissat",
                "Uummannaq"
            ],
            "Saint Davids": [
                "Ikerasak",
                "Illorsuit",
                "Niaqornat",
                "Nuugaatsiaq",
                "Qaarsut",
                "Saattut",
                "Ukkusissat",
                "Uummannaq"
            ],
            "Saint George\\'s": [
                "Ikerasak",
                "Illorsuit",
                "Niaqornat",
                "Nuugaatsiaq",
                "Qaarsut",
                "Saattut",
                "Ukkusissat",
                "Uummannaq"
            ],
            "Saint John": [
                "Ikerasak",
                "Illorsuit",
                "Niaqornat",
                "Nuugaatsiaq",
                "Qaarsut",
                "Saattut",
                "Ukkusissat",
                "Uummannaq"
            ],
            "Saint Mark": [
                "Ikerasak",
                "Illorsuit",
                "Niaqornat",
                "Nuugaatsiaq",
                "Qaarsut",
                "Saattut",
                "Ukkusissat",
                "Uummannaq"
            ],
            "Saint Patrick": [
                "Ikerasak",
                "Illorsuit",
                "Niaqornat",
                "Nuugaatsiaq",
                "Qaarsut",
                "Saattut",
                "Ukkusissat",
                "Uummannaq"
            ]
        }
    },
    {
        "id": 88,
        "name": "Guadeloupe",
        "iso3": "GLP",
        "iso2": "GP",
        "phone_code": "590",
        "capital": "Basse-Terre",
        "currency": "EUR",
        "states": {
            "Basse-Terre": [
                "Baie-Mahault",
                "Baillif",
                "Basse-Terre",
                "Bouillante",
                "Capesterre-Belle-Eau",
                "Gourbeyre",
                "Lamentin",
                "Petit-Bourg",
                "Pointe-Noire",
                "Saint-Claude",
                "Sainte-Rose",
                "Trois-Rivieres",
                "Vieux-Habitants"
            ],
            "Grande-Terre": [
                "Anse-Bertrand",
                "Le Gosier",
                "Le Moule",
                "Les Abymes",
                "Morne-a-l\\'Eau",
                "Petit-Canal",
                "Point-a-Pitre",
                "Port-Louis",
                "Saint-Francois",
                "Sainte-Anne"
            ],
            "Iles des Saintes": [
                "Anse-Bertrand",
                "Le Gosier",
                "Le Moule",
                "Les Abymes",
                "Morne-a-l\\'Eau",
                "Petit-Canal",
                "Point-a-Pitre",
                "Port-Louis",
                "Saint-Francois",
                "Sainte-Anne"
            ],
            "La Desirade": [
                "Anse-Bertrand",
                "Le Gosier",
                "Le Moule",
                "Les Abymes",
                "Morne-a-l\\'Eau",
                "Petit-Canal",
                "Point-a-Pitre",
                "Port-Louis",
                "Saint-Francois",
                "Sainte-Anne"
            ],
            "Marie-Galante": [
                "Grand-Bourg"
            ],
            "Saint Barthelemy": [
                "Grand-Bourg"
            ],
            "Saint Martin": [
                "Grand-Bourg"
            ]
        }
    },
    {
        "id": 89,
        "name": "Guam",
        "iso3": "GUM",
        "iso2": "GU",
        "phone_code": "+1-671",
        "capital": "Hagatna",
        "currency": "USD",
        "states": {
            "Agana Heights": [
                "Grand-Bourg"
            ],
            "Agat": [
                "Agat"
            ],
            "Barrigada": [
                "Barrigada",
                "Barrigada Heights"
            ],
            "Chalan-Pago-Ordot": [
                "Chalan Pago",
                "Ordot"
            ],
            "Dededo": [
                "Astumbo",
                "Dededo",
                "Finegayan Station"
            ],
            "Hagatna": [
                "Agana",
                "Agana Station"
            ],
            "Inarajan": [
                "Inarajan"
            ],
            "Mangilao": [
                "Mangilao"
            ],
            "Merizo": [
                "Merizo"
            ],
            "Mongmong-Toto-Maite": [
                "Mongmong",
                "Toto"
            ],
            "Santa Rita": [
                "Mongmong",
                "Toto"
            ],
            "Sinajana": [
                "Sinajana"
            ],
            "Talofofo": [
                "Talofofo"
            ],
            "Tamuning": [
                "Tamuning"
            ],
            "Yigo": [
                "Anderson Air Force Base",
                "Yigo"
            ],
            "Yona": [
                "Yona"
            ]
        }
    },
    {
        "id": 90,
        "name": "Guatemala",
        "iso3": "GTM",
        "iso2": "GT",
        "phone_code": "502",
        "capital": "Guatemala City",
        "currency": "GTQ",
        "states": {
            "Alta Verapaz": [
                "Yona"
            ],
            "Baja Verapaz": [
                "Yona"
            ],
            "Chimaltenango": [
                "Chimaltenango",
                "Comalapa",
                "Itzapa",
                "Patzun"
            ],
            "Chiquimula": [
                "Chiquimula",
                "Esquipulas"
            ],
            "El Progreso": [
                "Chiquimula",
                "Esquipulas"
            ],
            "Escuintla": [
                "Cotzumalguapa",
                "Escuintla",
                "Palin",
                "San Jose",
                "Tiquisate"
            ],
            "Guatemala": [
                "Amatitlan",
                "Chinautla",
                "Guatemala",
                "Mixco",
                "Petapa",
                "Villa Nueva"
            ],
            "Huehuetenango": [
                "Huehuetenango"
            ],
            "Izabal": [
                "Puerto Barrios"
            ],
            "Jalapa": [
                "Jalapa"
            ],
            "Jutiapa": [
                "Asuncion Mita",
                "Jutiapa"
            ],
            "Peten": [
                "Flores",
                "San Benito"
            ],
            "Quezaltenango": [
                "Quezaltenango"
            ],
            "Quiche": [
                "Quiche"
            ],
            "Retalhuleu": [
                "Retalhuleu"
            ],
            "Sacatepequez": [
                "Antigua",
                "Ciudad Vieja",
                "Jocotenango",
                "Santa Maria de Jesus",
                "Sumpango"
            ],
            "San Marcos": [
                "Antigua",
                "Ciudad Vieja",
                "Jocotenango",
                "Santa Maria de Jesus",
                "Sumpango"
            ],
            "Santa Rosa": [
                "Antigua",
                "Ciudad Vieja",
                "Jocotenango",
                "Santa Maria de Jesus",
                "Sumpango"
            ],
            "Solola": [
                "Atitlan",
                "Solola"
            ],
            "Suchitepequez": [
                "Mazatenango"
            ],
            "Totonicapan": [
                "Totonicapan"
            ],
            "Zacapa": [
                "Zacapa"
            ]
        }
    },
    {
        "id": 91,
        "name": "Guernsey and Alderney",
        "iso3": "GGY",
        "iso2": "GG",
        "phone_code": "+44-1481",
        "capital": "St Peter Port",
        "currency": "GBP",
        "states": {
            "Alderney": [
                "Saint Anne\\'s"
            ],
            "Castel": [
                "Castle"
            ],
            "Forest": [
                "Forest"
            ],
            "Saint Andrew": [
                "Forest"
            ],
            "Saint Martin": [
                "Forest"
            ],
            "Saint Peter Port": [
                "Forest"
            ],
            "Saint Pierre du Bois": [
                "Forest"
            ],
            "Saint Sampson": [
                "Forest"
            ],
            "Saint Saviour": [
                "Forest"
            ],
            "Sark": [
                "Sark"
            ],
            "Torteval": [
                "Torteval"
            ],
            "Vale": [
                "Vale"
            ]
        }
    },
    {
        "id": 92,
        "name": "Guinea",
        "iso3": "GIN",
        "iso2": "GN",
        "phone_code": "224",
        "capital": "Conakry",
        "currency": "GNF",
        "states": {
            "Beyla": [
                "Beyla"
            ],
            "Boffa": [
                "Boffa"
            ],
            "Boke": [
                "Boke"
            ],
            "Conakry": [
                "Conakry"
            ],
            "Coyah": [
                "Coyah"
            ],
            "Dabola": [
                "Dabola"
            ],
            "Dalaba": [
                "Dalaba"
            ],
            "Dinguiraye": [
                "Dinguiraye"
            ],
            "Faranah": [
                "Faranah"
            ],
            "Forecariah": [
                "Forecariah"
            ],
            "Fria": [
                "Fria"
            ],
            "Gaoual": [
                "Gaoual"
            ],
            "Gueckedou": [
                "Guekedou"
            ],
            "Kankan": [
                "Kankan"
            ],
            "Kerouane": [
                "Kerouane"
            ],
            "Kindia": [
                "Kindia"
            ],
            "Kissidougou": [
                "Kissidougou"
            ],
            "Koubia": [
                "Koubia"
            ],
            "Koundara": [
                "Koundara"
            ],
            "Kouroussa": [
                "Kouroussa"
            ],
            "Labe": [
                "Labe"
            ],
            "Lola": [
                "Lola"
            ],
            "Macenta": [
                "Macenta"
            ],
            "Mali": [
                "Mali"
            ],
            "Mamou": [
                "Mamou"
            ],
            "Mandiana": [
                "Mandiana"
            ],
            "Nzerekore": [
                "Nzerekore"
            ],
            "Pita": [
                "Pita"
            ],
            "Siguiri": [
                "Siguiri"
            ],
            "Telimele": [
                "Telimele"
            ],
            "Tougue": [
                "Tougue"
            ],
            "Yomou": [
                "Yomou"
            ]
        }
    },
    {
        "id": 93,
        "name": "Guinea-Bissau",
        "iso3": "GNB",
        "iso2": "GW",
        "phone_code": "245",
        "capital": "Bissau",
        "currency": "XOF",
        "states": {
            "Bafata": [
                "Bafata"
            ],
            "Bissau": [
                "Bissau"
            ],
            "Bolama": [
                "Bolama",
                "Bubaque"
            ],
            "Cacheu": [
                "Cacheu",
                "Canchungo"
            ],
            "Gabu": [
                "Gabu"
            ],
            "Oio": [
                "Bissora",
                "Farim",
                "Mansoa"
            ],
            "Quinara": [
                "Buba",
                "Fulacunda",
                "Quebo"
            ],
            "Tombali": [
                "Catio"
            ]
        }
    },
    {
        "id": 94,
        "name": "Guyana",
        "iso3": "GUY",
        "iso2": "GY",
        "phone_code": "592",
        "capital": "Georgetown",
        "currency": "GYD",
        "states": {
            "Barima-Waini": [
                "Mabaruma",
                "Morawhanna"
            ],
            "Cuyuni-Mazaruni": [
                "Bartica",
                "Issano",
                "Kamarang"
            ],
            "Demerara-Mahaica": [
                "Georgetown",
                "Mahaica",
                "Paradise",
                "Queenstown"
            ],
            "East Berbice-Corentyne": [
                "Georgetown",
                "Mahaica",
                "Paradise",
                "Queenstown"
            ],
            "Essequibo Islands-West Demerar": [
                "Georgetown",
                "Mahaica",
                "Paradise",
                "Queenstown"
            ],
            "Mahaica-Berbice": [
                "Fort Wellington",
                "Mahaicony",
                "Rosignol"
            ],
            "Pomeroon-Supenaam": [
                "Anna Regina",
                "Charity",
                "Suddie"
            ],
            "Potaro-Siparuni": [
                "Mahdia",
                "Tumatumari"
            ],
            "Upper Demerara-Berbice": [
                "Mahdia",
                "Tumatumari"
            ],
            "Upper Takutu-Upper Essequibo": [
                "Mahdia",
                "Tumatumari"
            ]
        }
    },
    {
        "id": 95,
        "name": "Haiti",
        "iso3": "HTI",
        "iso2": "HT",
        "phone_code": "509",
        "capital": "Port-au-Prince",
        "currency": "HTG",
        "states": {
            "Artibonite": [
                "Desdunes",
                "Dessalines",
                "Gonaives",
                "Gros-Morne",
                "L\\'Artibonite",
                "Saint-Marc",
                "Saint-Michel-de-l\\'Atalaye",
                "Saint-Raphael",
                "Verrettes"
            ],
            "Centre": [
                "Hinche",
                "Mirebalais"
            ],
            "Grand\\'Anse": [
                "Anse-d\\'Hainault",
                "Dame Marie",
                "Jeremie",
                "Miragoane"
            ],
            "Nord": [
                "Cap-Haitien",
                "Croix-des-Bouquets",
                "Grande Riviere du Nord",
                "Limbe",
                "Pignon"
            ],
            "Nord-Est": [
                "Derac",
                "Fort-Liberte",
                "Ouanaminthe",
                "Trou-du-Nord"
            ],
            "Nord-Ouest": [
                "Port-de-Paix",
                "Saint-Louis-du-Nord"
            ],
            "Ouest": [
                "Anse-a-Galets",
                "Carrefour",
                "Delmas",
                "Kenscoff",
                "Lascahobas",
                "Leogane",
                "Petionville",
                "Petit Goave",
                "Port-au-Prince"
            ],
            "Sud": [
                "Aquin",
                "Les Cayes"
            ],
            "Sud-Est": [
                "Jacmel"
            ]
        }
    },
    {
        "id": 96,
        "name": "Heard and McDonald Islands",
        "iso3": "HMD",
        "iso2": "HM",
        "phone_code": " ",
        "capital": "",
        "currency": "AUD",
        "states": {
            "Heard and McDonald Islands": [
                "Jacmel"
            ]
        }
    },
    {
        "id": 97,
        "name": "Honduras",
        "iso3": "HND",
        "iso2": "HN",
        "phone_code": "504",
        "capital": "Tegucigalpa",
        "currency": "HNL",
        "states": {
            "Atlantida": [
                "La Ceiba",
                "Olanchito",
                "Tela"
            ],
            "Choluteca": [
                "Choluteca",
                "El Triunfo",
                "Pespire"
            ],
            "Colon": [
                "Sonaguera",
                "Tocoa",
                "Trujillo"
            ],
            "Comayagua": [
                "Comayagua",
                "Siguatepeque"
            ],
            "Copan": [
                "Copan",
                "Corquin",
                "Dulce Nombre",
                "El Paraiso",
                "San Antonio",
                "San Nicolas",
                "Santa Rosa de Copan"
            ],
            "Cortes": [
                "Choloma",
                "La Lima",
                "Omoa",
                "Puerto Cortes",
                "San Pedro Sula"
            ],
            "Distrito Central": [
                "Choloma",
                "La Lima",
                "Omoa",
                "Puerto Cortes",
                "San Pedro Sula"
            ],
            "El Paraiso": [
                "Choloma",
                "La Lima",
                "Omoa",
                "Puerto Cortes",
                "San Pedro Sula"
            ],
            "Francisco Morazan": [
                "Choloma",
                "La Lima",
                "Omoa",
                "Puerto Cortes",
                "San Pedro Sula"
            ],
            "Gracias a Dios": [
                "Choloma",
                "La Lima",
                "Omoa",
                "Puerto Cortes",
                "San Pedro Sula"
            ],
            "Intibuca": [
                "Intibuca",
                "La Esperanza",
                "Utila"
            ],
            "Islas de la Bahia": [
                "Intibuca",
                "La Esperanza",
                "Utila"
            ],
            "La Paz": [
                "Intibuca",
                "La Esperanza",
                "Utila"
            ],
            "Lempira": [
                "Gracias"
            ],
            "Ocotepeque": [
                "Ocotepeque",
                "San Marcos",
                "Sinuapa"
            ],
            "Olancho": [
                "Catacamas",
                "Juticalpa"
            ],
            "Santa Barbara": [
                "Catacamas",
                "Juticalpa"
            ],
            "Valle": [
                "Amapala",
                "Langue",
                "Nacaome",
                "San Lorenzo"
            ],
            "Yoro": [
                "El Progreso",
                "Morazan",
                "Santa Rita",
                "Yoro"
            ]
        }
    },
    {
        "id": 98,
        "name": "Hong Kong S.A.R.",
        "iso3": "HKG",
        "iso2": "HK",
        "phone_code": "852",
        "capital": "Hong Kong",
        "currency": "HKD",
        "states": {
            "Hong Kong": [
                "El Progreso",
                "Morazan",
                "Santa Rita",
                "Yoro"
            ]
        }
    },
    {
        "id": 99,
        "name": "Hungary",
        "iso3": "HUN",
        "iso2": "HU",
        "phone_code": "36",
        "capital": "Budapest",
        "currency": "HUF",
        "states": {
            "Bacs-Kiskun": [
                "Akaszto",
                "Bacsalmas",
                "Bacsbokod",
                "Baja",
                "Bugac",
                "Davod",
                "Dunapataj",
                "Dunavecse",
                "Fulopszallas",
                "Hajos",
                "Harta",
                "Izsak",
                "Jakabszallas",
                "Janoshalma",
                "Kalocsa",
                "Kecel",
                "Kecskemet",
                "Kiskoros",
                "Kiskunfelegyhaza",
                "Kiskunhalas",
                "Kiskunmajsa",
                "Kunfeherto",
                "Kunszentmiklos",
                "Lajosmizse",
                "Lakitelek",
                "Madaras",
                "Melykut",
                "Nagybaracska",
                "Orgovany",
                "Palmonostora",
                "Solt",
                "Soltvadkert",
                "Sukosd",
                "Szabadszallas",
                "Szalkszentmarton",
                "Tass",
                "Tiszakecske",
                "Tompa"
            ],
            "Baranya": [
                "Beremend",
                "Boly",
                "Dunaszekcso",
                "Harkany",
                "Hosszuheteny",
                "Komlo",
                "Magocs",
                "Mohacs",
                "Pecs",
                "Pecsvarad",
                "Sasd",
                "Sellye",
                "Siklos",
                "Szentlorinc",
                "Szigetvar",
                "Vajszlo",
                "Villany"
            ],
            "Bekes": [
                "Battonya",
                "Bekes",
                "Bekescsaba",
                "Bekessamson",
                "Bekesszentandras",
                "Csorvas",
                "Devavanya",
                "Doboz",
                "Elek",
                "Endrod",
                "Fuzesgyarmat",
                "Gyula",
                "Ketegyhaza",
                "Kondoros",
                "Korosladany",
                "Kunagota",
                "Lokoshaza",
                "Mezobereny",
                "Mezohegyes",
                "Mezokovacshaza",
                "Nagyszenas",
                "Oroshaza",
                "Sarkad",
                "Szabadkigyos",
                "Szarvas",
                "Szeghalom",
                "Totkomlos",
                "Veszto"
            ],
            "Borsod-Abauj-Zemplen": [
                "Abaujszanto",
                "Arlo",
                "Bogacs",
                "Cigand",
                "Edeleny",
                "Emod",
                "Encs",
                "Gonc",
                "Karcsa",
                "Kazincbarcika",
                "Mad",
                "Megyaszo",
                "Mezokeresztes",
                "Mezokovesd",
                "Miskolc",
                "Monok",
                "Nyekladhaza",
                "Olaszliszka",
                "Onod",
                "Ozd",
                "Putnok",
                "Rudabanya",
                "Sajokaza",
                "Sajolad",
                "Sajoszentpeter",
                "Saly",
                "Sarospatak",
                "Satoraljaujhely",
                "Szendro",
                "Szentistvan",
                "Szerencs",
                "Szihalom",
                "Szikszo",
                "Taktaharkany",
                "Taktaszada",
                "Tallya",
                "Tarcal",
                "Tiszaluc",
                "Tiszaujvaros",
                "Tokaj",
                "Tolcsva"
            ],
            "Budapest": [
                "Budapest"
            ],
            "Csongrad": [
                "Csongrad",
                "Fabiansebestyen",
                "Foldeak",
                "Hodmezovasarhely",
                "Kiskundorozsma",
                "Kistelek",
                "Kiszombor",
                "Mako",
                "Mindszent",
                "Morahalom",
                "Pusztaszer",
                "Roszke",
                "Sandorfalva",
                "Szatymaz",
                "Szeged",
                "Szegvar",
                "Szekkutas",
                "Szentes"
            ],
            "Fejer": [
                "Aba",
                "Adony",
                "Alap",
                "Apostag",
                "Bakonycsernye",
                "Bicske",
                "Bodajk",
                "Cece",
                "Csakvar",
                "Deg",
                "Dios",
                "Dunaujvaros",
                "Enying",
                "Ercsi",
                "Etyek",
                "Fehervarcsurgo",
                "Lovasbereny",
                "Martonvasar",
                "Mezofalva",
                "Mezoszilas",
                "Mor",
                "Pazmand",
                "Polgardi",
                "Pusztavam",
                "Rackeresztur",
                "Sarbogard",
                "Seregelyes",
                "Soponya",
                "Szabadbattyan",
                "Szekesfehervar",
                "Val"
            ],
            "Gyor-Moson-Sopron": [
                "Asvanyraro",
                "Beled",
                "Bosarkany",
                "Csorna",
                "Fertod",
                "Fertorakos",
                "Fertoszentmiklos",
                "Gyor",
                "Gyorujbarat",
                "Hunyadi u.",
                "Kapuvar",
                "Lebeny",
                "Mihalyi",
                "Mosonmagyarovar",
                "Nyul",
                "Pannonhalma",
                "Rajka",
                "Sopron",
                "Szany",
                "Tet"
            ],
            "Hajdu-Bihar": [
                "Balmazujvaros",
                "Barand",
                "Berettyoujfalu",
                "Biharkeresztes",
                "Biharnagybajom",
                "Debrecen",
                "Derecske",
                "Egyek",
                "Foldes",
                "Hajduboszormeny",
                "Hajdudorog",
                "Hajduhadhaz",
                "Hajdusamson",
                "Hajduszoboszlo",
                "Hajduszovat",
                "Hortobagy",
                "Hosszupalyi",
                "Kaba",
                "Komadi",
                "Mikepercs",
                "Monostorpalyi",
                "Nadudvar",
                "Nagyleta",
                "Nyirabrany",
                "Nyiracsad",
                "Nyiradony",
                "Polgar",
                "Puspokladany",
                "Sarretudvari",
                "Tiszacsege",
                "Ujfeherto",
                "Vamospercs"
            ],
            "Heves": [
                "Abasar",
                "Andornaktalya",
                "Belapatfalva",
                "Domoszlo",
                "Eger",
                "Erdotelek",
                "Felsotarkany",
                "Fuzesabony",
                "Gyongyos",
                "Gyongyospata",
                "Gyongyossolymos",
                "Gyongyostarjan",
                "Hatvan",
                "Heves",
                "Kal",
                "Lorinci",
                "Matraderecske",
                "Parad",
                "Petervasara",
                "Recsk",
                "Sirok",
                "Tarnalelesz",
                "Verpelet"
            ],
            "Jasz-Nagykun-Szolnok": [
                "Abadszalok",
                "Besenyszog",
                "Cserkeszolo",
                "Fegyvernek",
                "Hegyeshalom",
                "Jaszalsoszentgyorgy",
                "Jaszapati",
                "Jaszarokszallas",
                "Jaszbereny",
                "Jaszfenyzaru",
                "Jaszjakohalma",
                "Jaszkiser",
                "Jaszladany",
                "Jaszszentandras",
                "Karcag",
                "Kenderes",
                "Kisujszallas",
                "Kunhegyes",
                "Kunmadaras",
                "Kunszentmarton",
                "Martfu",
                "Mezotur",
                "Ocsod",
                "Szolnok",
                "Tiszabura",
                "Tiszafoldvar",
                "Tiszafured",
                "Tiszapuspoki",
                "Tiszaroff",
                "Tiszasuly",
                "Torokszentmiklos",
                "Toszeg",
                "Turkeve",
                "Ujszasz"
            ],
            "Komarom-Esztergom": [
                "Acs",
                "Almasfuzito",
                "Babolna",
                "Bajna",
                "Dorog",
                "Esztergom",
                "Kesztolc",
                "Kisber",
                "Komarom",
                "Kornye",
                "Labatlan",
                "Mocsa",
                "Nagyigmand",
                "Nyergesujfalu",
                "Oroszlany",
                "Sarisap",
                "Tardos",
                "Tarjan",
                "Tata",
                "Tatabanya",
                "Tokod",
                "Vertesszolos"
            ],
            "Nograd": [
                "Balassagyarmat",
                "Batonyterenye",
                "Bercel",
                "Bujak",
                "Diosjeno",
                "Karancskeszi",
                "Matraverebely",
                "Nagyoroszi",
                "Paszto",
                "Retsag",
                "Romhany",
                "Salgotarjan",
                "Szecseny",
                "Tar"
            ],
            "Pest": [
                "Abony",
                "Albertirsa",
                "Aszod",
                "Biatorbagy",
                "Budakalasz",
                "Budakeszi",
                "Budaors",
                "Bugyi",
                "Cegled",
                "Csobanka",
                "Dabas",
                "Domsod",
                "Dunabogdany",
                "Dunaharaszti",
                "Dunakeszi",
                "Erd",
                "Forro",
                "Fot",
                "Galgaheviz",
                "God",
                "Godollo",
                "Gyomro",
                "Hevizgyork",
                "Isaszeg",
                "Jaszkarajeno",
                "Kiskunlachaza",
                "Kocser",
                "Koka",
                "Kosd",
                "Maglod",
                "Monor",
                "Nagykata",
                "Nagykoros",
                "Nagykovacsi",
                "Nagymaros",
                "Nagytarcsa",
                "Nyaregyhaza",
                "Ocsa",
                "Orbottyan",
                "Orkeny",
                "Paty",
                "Pecel",
                "Perbal",
                "Pilis",
                "Pilisborosjeno",
                "Piliscsaba",
                "Pilisszanto",
                "Pilisszentivan",
                "Pilisszentkereszt",
                "Pilisvorosvar",
                "Pomaz",
                "Racalmas",
                "Rackeve",
                "Solymar",
                "Soskut",
                "Szada",
                "Szazhalombatta",
                "Szentendre",
                "Szentmartonkata",
                "Szigetcsep",
                "Szigetszentmiklos",
                "Szigetujfalu",
                "Szob",
                "Tahitofalu",
                "Tapiobicske",
                "Tapioszecso",
                "Tapioszele",
                "Toalmas",
                "Torokbalint",
                "Tortel",
                "Tura",
                "Ullo",
                "Uri",
                "Urom",
                "Vac",
                "Vecses",
                "Veresegyhaz",
                "Verocemaros",
                "Visegrad",
                "Zsambek",
                "Zsambok"
            ],
            "Somogy": [
                "Adand",
                "Balatonfoldvar",
                "Balatonoszod",
                "Balatonszabadi",
                "Balatonszarszo",
                "Barcs",
                "Berzence",
                "Boglarlelle",
                "Bohonye",
                "Csurgo",
                "Fonyod",
                "Kaposvar",
                "Karad",
                "Kethely",
                "Lengyeltoti",
                "Marcali",
                "Nagyatad",
                "Nagybajom",
                "Siofok",
                "Somogyvar",
                "Tab",
                "Zamardi"
            ],
            "Szabolcs-Szatmar-Bereg": [
                "Ajak",
                "Baktaloranthaza",
                "Balkany",
                "Buj",
                "Demecser",
                "Dombrad",
                "Fehergyarmat",
                "Ibrany",
                "Kemecse",
                "Kisvarda",
                "Kotaj",
                "Mandok",
                "Mariapocs",
                "Mateszalka",
                "Nagyecsed",
                "Nagyhalasz",
                "Nagykallo",
                "Nyirbator",
                "Nyirbeltek",
                "Nyiregyhaza",
                "Nyirmada",
                "Nyirpazony",
                "Nyirtelek",
                "Ofeherto",
                "Rakamaz",
                "Tarpa",
                "Tiszabercel",
                "Tiszalok",
                "Tiszavasvari",
                "Tuzser",
                "Vaja",
                "Vasarosnameny",
                "Zahony"
            ],
            "Tolna": [
                "Bataszek",
                "Bonyhad",
                "Decs",
                "Dombovar",
                "Dunafoldvar",
                "Fadd",
                "Gyonk",
                "Hogyesz",
                "Iregszemcse",
                "Madocsa",
                "Nagymagocs",
                "Nagymanyok",
                "Ozora",
                "Paks",
                "Pincehely",
                "Simontornya",
                "Szekszard",
                "Szentgal",
                "Tamasi",
                "Tengelic",
                "Tolna",
                "Zomba"
            ],
            "Vas": [
                "Buk",
                "Celldomolk",
                "Csepreg",
                "Gencsapati",
                "Jak",
                "Janoshaza",
                "Kormend",
                "Koszeg",
                "Sarvar",
                "Szentgotthard",
                "Szombathely",
                "Vasvar",
                "Vep"
            ],
            "Veszprem": [
                "Ajka",
                "Badacsonytomaj",
                "Balatonalmadi",
                "Balatonfured",
                "Balatonfuzfo",
                "Balatonkenese",
                "Band",
                "Berhida",
                "Csabrendek",
                "Devecser",
                "Herend",
                "Papa",
                "Sumeg",
                "Tapolca",
                "Urkut",
                "Varpalota",
                "Veszprem",
                "Zirc"
            ],
            "Zala": [
                "Becsehely",
                "Heviz",
                "Keszthely",
                "Lenti",
                "Letenye",
                "Nagykanizsa",
                "Sarmellek",
                "Turje",
                "Zalaegerszeg",
                "Zalakomar",
                "Zalalovo",
                "Zalaszentgrot"
            ]
        }
    },
    {
        "id": 100,
        "name": "Iceland",
        "iso3": "ISL",
        "iso2": "IS",
        "phone_code": "354",
        "capital": "Reykjavik",
        "currency": "ISK",
        "states": {
            "Austurland": [
                "Bakkafjor ur",
                "Borgarfjor ur",
                "Brei dalsvik",
                "Djupivogur",
                "Egilssta ir",
                "Eskifjor ur",
                "Faskru sfjor ur",
                "Fellabar",
                "Hallormssta ur",
                "Hofn",
                "Nesjakauptun",
                "Neskaupsta ur",
                "Rey arfjor ur",
                "Sey isfjor ur",
                "Sto varfjor ur",
                "Vopnafjor ur"
            ],
            "Gullbringusysla": [
                "Bakkafjor ur",
                "Borgarfjor ur",
                "Brei dalsvik",
                "Djupivogur",
                "Egilssta ir",
                "Eskifjor ur",
                "Faskru sfjor ur",
                "Fellabar",
                "Hallormssta ur",
                "Hofn",
                "Nesjakauptun",
                "Neskaupsta ur",
                "Rey arfjor ur",
                "Sey isfjor ur",
                "Sto varfjor ur",
                "Vopnafjor ur"
            ],
            "Hofu borgarsva i": [
                "Bakkafjor ur",
                "Borgarfjor ur",
                "Brei dalsvik",
                "Djupivogur",
                "Egilssta ir",
                "Eskifjor ur",
                "Faskru sfjor ur",
                "Fellabar",
                "Hallormssta ur",
                "Hofn",
                "Nesjakauptun",
                "Neskaupsta ur",
                "Rey arfjor ur",
                "Sey isfjor ur",
                "Sto varfjor ur",
                "Vopnafjor ur"
            ],
            "Nor urland eystra": [
                "Bakkafjor ur",
                "Borgarfjor ur",
                "Brei dalsvik",
                "Djupivogur",
                "Egilssta ir",
                "Eskifjor ur",
                "Faskru sfjor ur",
                "Fellabar",
                "Hallormssta ur",
                "Hofn",
                "Nesjakauptun",
                "Neskaupsta ur",
                "Rey arfjor ur",
                "Sey isfjor ur",
                "Sto varfjor ur",
                "Vopnafjor ur"
            ],
            "Nor urland vestra": [
                "Bakkafjor ur",
                "Borgarfjor ur",
                "Brei dalsvik",
                "Djupivogur",
                "Egilssta ir",
                "Eskifjor ur",
                "Faskru sfjor ur",
                "Fellabar",
                "Hallormssta ur",
                "Hofn",
                "Nesjakauptun",
                "Neskaupsta ur",
                "Rey arfjor ur",
                "Sey isfjor ur",
                "Sto varfjor ur",
                "Vopnafjor ur"
            ],
            "Su urland": [
                "Bakkafjor ur",
                "Borgarfjor ur",
                "Brei dalsvik",
                "Djupivogur",
                "Egilssta ir",
                "Eskifjor ur",
                "Faskru sfjor ur",
                "Fellabar",
                "Hallormssta ur",
                "Hofn",
                "Nesjakauptun",
                "Neskaupsta ur",
                "Rey arfjor ur",
                "Sey isfjor ur",
                "Sto varfjor ur",
                "Vopnafjor ur"
            ],
            "Su urnes": [
                "Bakkafjor ur",
                "Borgarfjor ur",
                "Brei dalsvik",
                "Djupivogur",
                "Egilssta ir",
                "Eskifjor ur",
                "Faskru sfjor ur",
                "Fellabar",
                "Hallormssta ur",
                "Hofn",
                "Nesjakauptun",
                "Neskaupsta ur",
                "Rey arfjor ur",
                "Sey isfjor ur",
                "Sto varfjor ur",
                "Vopnafjor ur"
            ],
            "Vestfir ir": [
                "Bakkafjor ur",
                "Borgarfjor ur",
                "Brei dalsvik",
                "Djupivogur",
                "Egilssta ir",
                "Eskifjor ur",
                "Faskru sfjor ur",
                "Fellabar",
                "Hallormssta ur",
                "Hofn",
                "Nesjakauptun",
                "Neskaupsta ur",
                "Rey arfjor ur",
                "Sey isfjor ur",
                "Sto varfjor ur",
                "Vopnafjor ur"
            ],
            "Vesturland": [
                "Akranes",
                "Borgarnes",
                "Bu ardalur",
                "Grundarfjor ur",
                "Hellissandur",
                "Hvanneyri",
                "Olafsvik",
                "Rif",
                "Stykkisholmur"
            ]
        }
    },
    {
        "id": 101,
        "name": "India",
        "iso3": "IND",
        "iso2": "IN",
        "phone_code": "91",
        "capital": "New Delhi",
        "currency": "INR",
        "states": {
            "Andaman and Nicobar Islands": [
                "Bombuflat",
                "Garacharma",
                "Port Blair",
                "Rangat"
            ],
            "Andhra Pradesh": [
                "Addanki",
                "Adivivaram",
                "Adoni",
                "Aganampudi",
                "Ajjaram",
                "Akividu",
                "Akkarampalle",
                "Akkayapalle",
                "Akkireddipalem",
                "Alampur",
                "Amalapuram",
                "Amudalavalasa",
                "Amur",
                "Anakapalle",
                "Anantapur",
                "Andole",
                "Atmakur",
                "Attili",
                "Avanigadda",
                "Badepalli",
                "Badvel",
                "Balapur",
                "Bandarulanka",
                "Banganapalle",
                "Bapatla",
                "Bapulapadu",
                "Belampalli",
                "Bestavaripeta",
                "Betamcherla",
                "Bhattiprolu",
                "Bhimavaram",
                "Bhimunipatnam",
                "Bobbili",
                "Bombuflat",
                "Bommuru",
                "Bugganipalle",
                "Challapalle",
                "Chandur",
                "Chatakonda",
                "Chemmumiahpet",
                "Chidiga",
                "Chilakaluripet",
                "Chimakurthy",
                "Chinagadila",
                "Chinagantyada",
                "Chinnachawk",
                "Chintalavalasa",
                "Chipurupalle",
                "Chirala",
                "Chittoor",
                "Chodavaram",
                "Choutuppal",
                "Chunchupalle",
                "Cuddapah",
                "Cumbum",
                "Darnakal",
                "Dasnapur",
                "Dauleshwaram",
                "Dharmavaram",
                "Dhone",
                "Dommara Nandyal",
                "Dowlaiswaram",
                "East Godavari Dist.",
                "Eddumailaram",
                "Edulapuram",
                "Ekambara kuppam",
                "Eluru",
                "Enikapadu",
                "Fakirtakya",
                "Farrukhnagar",
                "Gaddiannaram",
                "Gajapathinagaram",
                "Gajularega",
                "Gajuvaka",
                "Gannavaram",
                "Garacharma",
                "Garimellapadu",
                "Giddalur",
                "Godavarikhani",
                "Gopalapatnam",
                "Gopalur",
                "Gorrekunta",
                "Gudivada",
                "Gudur",
                "Guntakal",
                "Guntur",
                "Guti",
                "Hindupur",
                "Hukumpeta",
                "Ichchapuram",
                "Isnapur",
                "Jaggayyapeta",
                "Jallaram Kamanpur",
                "Jammalamadugu",
                "Jangampalli",
                "Jarjapupeta",
                "Kadiri",
                "Kaikalur",
                "Kakinada",
                "Kallur",
                "Kalyandurg",
                "Kamalapuram",
                "Kamareddi",
                "Kanapaka",
                "Kanigiri",
                "Kanithi",
                "Kankipadu",
                "Kantabamsuguda",
                "Kanuru",
                "Karnul",
                "Katheru",
                "Kavali",
                "Kazipet",
                "Khanapuram Haveli",
                "Kodar",
                "Kollapur",
                "Kondapalem",
                "Kondapalle",
                "Kondukur",
                "Kosgi",
                "Kothavalasa",
                "Kottapalli",
                "Kovur",
                "Kovurpalle",
                "Kovvur",
                "Krishna",
                "Kuppam",
                "Kurmannapalem",
                "Kurnool",
                "Lakshettipet",
                "Lalbahadur Nagar",
                "Machavaram",
                "Macherla",
                "Machilipatnam",
                "Madanapalle",
                "Madaram",
                "Madhuravada",
                "Madikonda",
                "Madugule",
                "Mahabubnagar",
                "Mahbubabad",
                "Malkajgiri",
                "Mamilapalle",
                "Mancheral",
                "Mandapeta",
                "Mandasa",
                "Mangalagiri",
                "Manthani",
                "Markapur",
                "Marturu",
                "Metpalli",
                "Mindi",
                "Mirpet",
                "Moragudi",
                "Mothugudam",
                "Nagari",
                "Nagireddipalle",
                "Nandigama",
                "Nandikotkur",
                "Nandyal",
                "Narasannapeta",
                "Narasapur",
                "Narasaraopet",
                "Narayanavanam",
                "Narsapur",
                "Narsingi",
                "Narsipatnam",
                "Naspur",
                "Nathayyapalem",
                "Nayudupeta",
                "Nelimaria",
                "Nellore",
                "Nidadavole",
                "Nuzvid",
                "Omerkhan daira",
                "Ongole",
                "Osmania University",
                "Pakala",
                "Palakole",
                "Palakurthi",
                "Palasa",
                "Palempalle",
                "Palkonda",
                "Palmaner",
                "Pamur",
                "Panjim",
                "Papampeta",
                "Parasamba",
                "Parvatipuram",
                "Patancheru",
                "Payakaraopet",
                "Pedagantyada",
                "Pedana",
                "Peddapuram",
                "Pendurthi",
                "Penugonda",
                "Penukonda",
                "Phirangipuram",
                "Pithapuram",
                "Ponnur",
                "Port Blair",
                "Pothinamallayyapalem",
                "Prakasam",
                "Prasadampadu",
                "Prasantinilayam",
                "Proddatur",
                "Pulivendla",
                "Punganuru",
                "Puttur",
                "Qutubullapur",
                "Rajahmundry",
                "Rajamahendri",
                "Rajampet",
                "Rajendranagar",
                "Rajoli",
                "Ramachandrapuram",
                "Ramanayyapeta",
                "Ramapuram",
                "Ramarajupalli",
                "Ramavarappadu",
                "Rameswaram",
                "Rampachodavaram",
                "Ravulapalam",
                "Rayachoti",
                "Rayadrug",
                "Razam",
                "Razole",
                "Renigunta",
                "Repalle",
                "Rishikonda",
                "Salur",
                "Samalkot",
                "Sattenapalle",
                "Seetharampuram",
                "Serilungampalle",
                "Shankarampet",
                "Shar",
                "Singarayakonda",
                "Sirpur",
                "Sirsilla",
                "Sompeta",
                "Sriharikota",
                "Srikakulam",
                "Srikalahasti",
                "Sriramnagar",
                "Sriramsagar",
                "Srisailam",
                "Srisailamgudem Devasthanam",
                "Sulurpeta",
                "Suriapet",
                "Suryaraopet",
                "Tadepalle",
                "Tadepalligudem",
                "Tadpatri",
                "Tallapalle",
                "Tanuku",
                "Tekkali",
                "Tenali",
                "Tigalapahad",
                "Tiruchanur",
                "Tirumala",
                "Tirupati",
                "Tirvuru",
                "Trimulgherry",
                "Tuni",
                "Turangi",
                "Ukkayapalli",
                "Ukkunagaram",
                "Uppal Kalan",
                "Upper Sileru",
                "Uravakonda",
                "Vadlapudi",
                "Vaparala",
                "Vemalwada",
                "Venkatagiri",
                "Venkatapuram",
                "Vepagunta",
                "Vetapalem",
                "Vijayapuri",
                "Vijayapuri South",
                "Vijayawada",
                "Vinukonda",
                "Visakhapatnam",
                "Vizianagaram",
                "Vuyyuru",
                "Wanparti",
                "West Godavari Dist.",
                "Yadagirigutta",
                "Yarada",
                "Yellamanchili",
                "Yemmiganur",
                "Yenamalakudru",
                "Yendada",
                "Yerraguntla"
            ],
            "Arunachal Pradesh": [
                "Along",
                "Basar",
                "Bondila",
                "Changlang",
                "Daporijo",
                "Deomali",
                "Itanagar",
                "Jairampur",
                "Khonsa",
                "Naharlagun",
                "Namsai",
                "Pasighat",
                "Roing",
                "Seppa",
                "Tawang",
                "Tezu",
                "Ziro"
            ],
            "Assam": [
                "Abhayapuri",
                "Ambikapur",
                "Amguri",
                "Anand Nagar",
                "Badarpur",
                "Badarpur Railway Town",
                "Bahbari Gaon",
                "Bamun Sualkuchi",
                "Barbari",
                "Barpathar",
                "Barpeta",
                "Barpeta Road",
                "Basugaon",
                "Bihpuria",
                "Bijni",
                "Bilasipara",
                "Biswanath Chariali",
                "Bohori",
                "Bokajan",
                "Bokokhat",
                "Bongaigaon",
                "Bongaigaon Petro-chemical Town",
                "Borgolai",
                "Chabua",
                "Chandrapur Bagicha",
                "Chapar",
                "Chekonidhara",
                "Choto Haibor",
                "Dergaon",
                "Dharapur",
                "Dhekiajuli",
                "Dhemaji",
                "Dhing",
                "Dhubri",
                "Dhuburi",
                "Dibrugarh",
                "Digboi",
                "Digboi Oil Town",
                "Dimaruguri",
                "Diphu",
                "Dispur",
                "Doboka",
                "Dokmoka",
                "Donkamokan",
                "Duliagaon",
                "Duliajan",
                "Duliajan No.1",
                "Dum Duma",
                "Durga Nagar",
                "Gauripur",
                "Goalpara",
                "Gohpur",
                "Golaghat",
                "Golakganj",
                "Gossaigaon",
                "Guwahati",
                "Haflong",
                "Hailakandi",
                "Hamren",
                "Hauli",
                "Hauraghat",
                "Hojai",
                "Jagiroad",
                "Jagiroad Paper Mill",
                "Jogighopa",
                "Jonai Bazar",
                "Jorhat",
                "Kampur Town",
                "Kamrup",
                "Kanakpur",
                "Karimganj",
                "Kharijapikon",
                "Kharupetia",
                "Kochpara",
                "Kokrajhar",
                "Kumar Kaibarta Gaon",
                "Lakhimpur",
                "Lakhipur",
                "Lala",
                "Lanka",
                "Lido Tikok",
                "Lido Town",
                "Lumding",
                "Lumding Railway Colony",
                "Mahur",
                "Maibong",
                "Majgaon",
                "Makum",
                "Mangaldai",
                "Mankachar",
                "Margherita",
                "Mariani",
                "Marigaon",
                "Moran",
                "Moranhat",
                "Nagaon",
                "Naharkatia",
                "Nalbari",
                "Namrup",
                "Naubaisa Gaon",
                "Nazira",
                "New Bongaigaon Railway Colony",
                "Niz-Hajo",
                "North Guwahati",
                "Numaligarh",
                "Palasbari",
                "Panchgram",
                "Pathsala",
                "Raha",
                "Rangapara",
                "Rangia",
                "Salakati",
                "Sapatgram",
                "Sarthebari",
                "Sarupathar",
                "Sarupathar Bengali",
                "Senchoagaon",
                "Sibsagar",
                "Silapathar",
                "Silchar",
                "Silchar Part-X",
                "Sonari",
                "Sorbhog",
                "Sualkuchi",
                "Tangla",
                "Tezpur",
                "Tihu",
                "Tinsukia",
                "Titabor",
                "Udalguri",
                "Umrangso",
                "Uttar Krishnapur Part-I"
            ],
            "Bihar": [
                "Amarpur",
                "Ara",
                "Araria",
                "Areraj",
                "Asarganj",
                "Aurangabad",
                "Bagaha",
                "Bahadurganj",
                "Bairgania",
                "Bakhtiyarpur",
                "Banka",
                "Banmankhi",
                "Bar Bigha",
                "Barauli",
                "Barauni Oil Township",
                "Barh",
                "Barhiya",
                "Bariapur",
                "Baruni",
                "Begusarai",
                "Behea",
                "Belsand",
                "Bettiah",
                "Bhabua",
                "Bhagalpur",
                "Bhimnagar",
                "Bhojpur",
                "Bihar",
                "Bihar Sharif",
                "Bihariganj",
                "Bikramganj",
                "Birpur",
                "Bodh Gaya",
                "Buxar",
                "Chakia",
                "Chanpatia",
                "Chhapra",
                "Chhatapur",
                "Colgong",
                "Dalsingh Sarai",
                "Darbhanga",
                "Daudnagar",
                "Dehri",
                "Dhaka",
                "Dighwara",
                "Dinapur",
                "Dinapur Cantonment",
                "Dumra",
                "Dumraon",
                "Fatwa",
                "Forbesganj",
                "Gaya",
                "Gazipur",
                "Ghoghardiha",
                "Gogri Jamalpur",
                "Gopalganj",
                "Habibpur",
                "Hajipur",
                "Hasanpur",
                "Hazaribagh",
                "Hilsa",
                "Hisua",
                "Islampur",
                "Jagdispur",
                "Jahanabad",
                "Jamalpur",
                "Jamhaur",
                "Jamui",
                "Janakpur Road",
                "Janpur",
                "Jaynagar",
                "Jha Jha",
                "Jhanjharpur",
                "Jogbani",
                "Kanti",
                "Kasba",
                "Kataiya",
                "Katihar",
                "Khagaria",
                "Khagaul",
                "Kharagpur",
                "Khusrupur",
                "Kishanganj",
                "Koath",
                "Koilwar",
                "Lakhisarai",
                "Lalganj",
                "Lauthaha",
                "Madhepura",
                "Madhubani",
                "Maharajganj",
                "Mahnar Bazar",
                "Mairwa",
                "Makhdumpur",
                "Maner",
                "Manihari",
                "Marhaura",
                "Masaurhi",
                "Mirganj",
                "Mohiuddinagar",
                "Mokama",
                "Motihari",
                "Motipur",
                "Munger",
                "Murliganj",
                "Muzaffarpur",
                "Nabinagar",
                "Narkatiaganj",
                "Nasriganj",
                "Natwar",
                "Naugachhia",
                "Nawada",
                "Nirmali",
                "Nokha",
                "Paharpur",
                "Patna",
                "Phulwari",
                "Piro",
                "Purnia",
                "Pusa",
                "Rafiganj",
                "Raghunathpur",
                "Rajgir",
                "Ramnagar",
                "Raxaul",
                "Revelganj",
                "Rusera",
                "Sagauli",
                "Saharsa",
                "Samastipur",
                "Sasaram",
                "Shahpur",
                "Shaikhpura",
                "Sherghati",
                "Shivhar",
                "Silao",
                "Sitamarhi",
                "Siwan",
                "Sonepur",
                "Sultanganj",
                "Supaul",
                "Teghra",
                "Tekari",
                "Thakurganj",
                "Vaishali",
                "Waris Aliganj"
            ],
            "Chandigarh": [
                "Chandigarh"
            ],
            "Chhattisgarh": [
                "Ahiwara",
                "Akaltara",
                "Ambagarh Chauki",
                "Ambikapur",
                "Arang",
                "Bade Bacheli",
                "Bagbahara",
                "Baikunthpur",
                "Balod",
                "Baloda",
                "Baloda Bazar",
                "Banarsi",
                "Basna",
                "Bemetra",
                "Bhanpuri",
                "Bhatapara",
                "Bhatgaon",
                "Bhilai",
                "Bilaspur",
                "Bilha",
                "Birgaon",
                "Bodri",
                "Champa",
                "Charcha",
                "Charoda",
                "Chhuikhadan",
                "Chirmiri",
                "Dantewada",
                "Deori",
                "Dhamdha",
                "Dhamtari",
                "Dharamjaigarh",
                "Dipka",
                "Doman Hill Colliery",
                "Dongargaon",
                "Dongragarh",
                "Durg",
                "Frezarpur",
                "Gandai",
                "Gariaband",
                "Gaurela",
                "Gelhapani",
                "Gharghoda",
                "Gidam",
                "Gobra Nawapara",
                "Gogaon",
                "Hatkachora",
                "Jagdalpur",
                "Jamui",
                "Jashpurnagar",
                "Jhagrakhand",
                "Kanker",
                "Katghora",
                "Kawardha",
                "Khairagarh",
                "Khamhria",
                "Kharod",
                "Kharsia",
                "Khonga Pani",
                "Kirandu",
                "Kirandul",
                "Kohka",
                "Kondagaon",
                "Korba",
                "Korea",
                "Koria Block",
                "Kota",
                "Kumhari",
                "Kumud Katta",
                "Kurasia",
                "Kurud",
                "Lingiyadih",
                "Lormi",
                "Mahasamund",
                "Mahendragarh",
                "Mehmand",
                "Mongra",
                "Mowa",
                "Mungeli",
                "Nailajanjgir",
                "Namna Kalan",
                "Naya Baradwar",
                "Pandariya",
                "Patan",
                "Pathalgaon",
                "Pendra",
                "Phunderdihari",
                "Pithora",
                "Raigarh",
                "Raipur",
                "Rajgamar",
                "Rajhara",
                "Rajnandgaon",
                "Ramanuj Ganj",
                "Ratanpur",
                "Sakti",
                "Saraipali",
                "Sarajpur",
                "Sarangarh",
                "Shivrinarayan",
                "Simga",
                "Sirgiti",
                "Takhatpur",
                "Telgaon",
                "Tildanewra",
                "Urla",
                "Vishrampur"
            ],
            "Dadra and Nagar Haveli": [
                "Amli",
                "Silvassa"
            ],
            "Daman and Diu": [
                "Daman",
                "Diu"
            ],
            "Delhi": [
                "Delhi",
                "New Delhi"
            ],
            "Goa": [
                "Aldona",
                "Altinho",
                "Aquem",
                "Arpora",
                "Bambolim",
                "Bandora",
                "Bardez",
                "Benaulim",
                "Betora",
                "Bicholim",
                "Calapor",
                "Candolim",
                "Caranzalem",
                "Carapur",
                "Chicalim",
                "Chimbel",
                "Chinchinim",
                "Colvale",
                "Corlim",
                "Cortalim",
                "Cuncolim",
                "Curchorem",
                "Curti",
                "Davorlim",
                "Dona Paula",
                "Goa",
                "Guirim",
                "Jua",
                "Kalangat",
                "Kankon",
                "Kundaim",
                "Loutulim",
                "Madgaon",
                "Mapusa",
                "Margao",
                "Margaon",
                "Miramar",
                "Morjim",
                "Mormugao",
                "Navelim",
                "Pale",
                "Panaji",
                "Parcem",
                "Parra",
                "Penha de Franca",
                "Pernem",
                "Pilerne",
                "Pissurlem",
                "Ponda",
                "Porvorim",
                "Quepem",
                "Queula",
                "Raia",
                "Reis Magos",
                "Salcette",
                "Saligao",
                "Sancoale",
                "Sanguem",
                "Sanquelim",
                "Sanvordem",
                "Sao Jose-de-Areal",
                "Sattari",
                "Serula",
                "Sinquerim",
                "Siolim",
                "Taleigao",
                "Tivim",
                "Valpoi",
                "Varca",
                "Vasco",
                "Verna"
            ],
            "Gujarat": [
                "Abrama",
                "Adalaj",
                "Adityana",
                "Advana",
                "Ahmedabad",
                "Ahwa",
                "Alang",
                "Ambaji",
                "Ambaliyasan",
                "Amod",
                "Amreli",
                "Amroli",
                "Anand",
                "Andada",
                "Anjar",
                "Anklav",
                "Ankleshwar",
                "Anklesvar INA",
                "Antaliya",
                "Arambhada",
                "Asarma",
                "Atul",
                "Babra",
                "Bag-e-Firdosh",
                "Bagasara",
                "Bahadarpar",
                "Bajipura",
                "Bajva",
                "Balasinor",
                "Banaskantha",
                "Bansda",
                "Bantva",
                "Bardoli",
                "Barwala",
                "Bayad",
                "Bechar",
                "Bedi",
                "Beyt",
                "Bhachau",
                "Bhanvad",
                "Bharuch",
                "Bharuch INA",
                "Bhavnagar",
                "Bhayavadar",
                "Bhestan",
                "Bhuj",
                "Bilimora",
                "Bilkha",
                "Billimora",
                "Bodakdev",
                "Bodeli",
                "Bopal",
                "Boria",
                "Boriavi",
                "Borsad",
                "Botad",
                "Cambay",
                "Chaklasi",
                "Chala",
                "Chalala",
                "Chalthan",
                "Chanasma",
                "Chandisar",
                "Chandkheda",
                "Chanod",
                "Chaya",
                "Chenpur",
                "Chhapi",
                "Chhaprabhatha",
                "Chhatral",
                "Chhota Udepur",
                "Chikhli",
                "Chiloda",
                "Chorvad",
                "Chotila",
                "Dabhoi",
                "Dadara",
                "Dahod",
                "Dakor",
                "Damnagar",
                "Deesa",
                "Delvada",
                "Devgadh Baria",
                "Devsar",
                "Dhandhuka",
                "Dhanera",
                "Dhangdhra",
                "Dhansura",
                "Dharampur",
                "Dhari",
                "Dhola",
                "Dholka",
                "Dholka Rural",
                "Dhoraji",
                "Dhrangadhra",
                "Dhrol",
                "Dhuva",
                "Dhuwaran",
                "Digvijaygram",
                "Disa",
                "Dungar",
                "Dungarpur",
                "Dungra",
                "Dwarka",
                "Flelanganj",
                "Gadhda",
                "Gandevi",
                "Gandhidham",
                "Gandhinagar",
                "Gariadhar",
                "Ghogha",
                "Godhra",
                "Gondal",
                "GSFC Complex",
                "Hajira INA",
                "Halol",
                "Halvad",
                "Hansot",
                "Harij",
                "Himatnagar",
                "Ichchhapor",
                "Idar",
                "Jafrabad",
                "Jalalpore",
                "Jambusar",
                "Jamjodhpur",
                "Jamnagar",
                "Jasdan",
                "Jawaharnagar",
                "Jetalsar",
                "Jetpur",
                "Jodiya",
                "Joshipura",
                "Junagadh",
                "Kadi",
                "Kadodara",
                "Kalavad",
                "Kali",
                "Kaliawadi",
                "Kalol",
                "Kalol INA",
                "Kandla",
                "Kanjari",
                "Kanodar",
                "Kapadwanj",
                "Karachiya",
                "Karamsad",
                "Karjan",
                "Kathial",
                "Kathor",
                "Katpar",
                "Kavant",
                "Keshod",
                "Kevadiya",
                "Khambhaliya",
                "Khambhat",
                "Kharaghoda",
                "Khed Brahma",
                "Kheda",
                "Kheralu",
                "Kodinar",
                "Kosamba",
                "Kundla",
                "Kutch",
                "Kutiyana",
                "Lakhtar",
                "Lalpur",
                "Lambha",
                "Lathi",
                "Limbdi",
                "Limla",
                "Lunavada",
                "Madhapar",
                "Maflipur",
                "Mahemdavad",
                "Mahudha",
                "Mahuva",
                "Mahuvar",
                "Makarba",
                "Makarpura",
                "Makassar",
                "Maktampur",
                "Malia",
                "Malpur",
                "Manavadar",
                "Mandal",
                "Mandvi",
                "Mangrol",
                "Mansa",
                "Meghraj",
                "Mehsana",
                "Mendarla",
                "Mithapur",
                "Modasa",
                "Mogravadi",
                "Morbi",
                "Morvi",
                "Mundra",
                "Nadiad",
                "Naliya",
                "Nanakvada",
                "Nandej",
                "Nandesari",
                "Nandesari INA",
                "Naroda",
                "Navagadh",
                "Navagam Ghed",
                "Navsari",
                "Ode",
                "Okaf",
                "Okha",
                "Olpad",
                "Paddhari",
                "Padra",
                "Palanpur",
                "Palej",
                "Pali",
                "Palitana",
                "Paliyad",
                "Pandesara",
                "Panoli",
                "Pardi",
                "Parnera",
                "Parvat",
                "Patan",
                "Patdi",
                "Petlad",
                "Petrochemical Complex",
                "Porbandar",
                "Prantij",
                "Radhanpur",
                "Raiya",
                "Rajkot",
                "Rajpipla",
                "Rajula",
                "Ramod",
                "Ranavav",
                "Ranoli",
                "Rapar",
                "Sahij",
                "Salaya",
                "Sanand",
                "Sankheda",
                "Santrampur",
                "Saribujrang",
                "Sarigam INA",
                "Sayan",
                "Sayla",
                "Shahpur",
                "Shahwadi",
                "Shapar",
                "Shivrajpur",
                "Siddhapur",
                "Sidhpur",
                "Sihor",
                "Sika",
                "Singarva",
                "Sinor",
                "Sojitra",
                "Sola",
                "Songadh",
                "Suraj Karadi",
                "Surat",
                "Surendranagar",
                "Talaja",
                "Talala",
                "Talod",
                "Tankara",
                "Tarsali",
                "Thangadh",
                "Tharad",
                "Thasra",
                "Udyognagar",
                "Ukai",
                "Umbergaon",
                "Umbergaon INA",
                "Umrala",
                "Umreth",
                "Un",
                "Una",
                "Unjha",
                "Upleta",
                "Utran",
                "Uttarsanda",
                "V.U. Nagar",
                "V.V. Nagar",
                "Vadia",
                "Vadla",
                "Vadnagar",
                "Vadodara",
                "Vaghodia INA",
                "Valbhipur",
                "Vallabh Vidyanagar",
                "Valsad",
                "Valsad INA",
                "Vanthali",
                "Vapi",
                "Vapi INA",
                "Vartej",
                "Vasad",
                "Vasna Borsad INA",
                "Vaso",
                "Veraval",
                "Vidyanagar",
                "Vijalpor",
                "Vijapur",
                "Vinchhiya",
                "Vinzol",
                "Virpur",
                "Visavadar",
                "Visnagar",
                "Vyara",
                "Wadhwan",
                "Waghai",
                "Waghodia",
                "Wankaner",
                "Zalod"
            ],
            "Haryana": [
                "Ambala",
                "Ambala Cantt",
                "Asan Khurd",
                "Asandh",
                "Ateli",
                "Babiyal",
                "Bahadurgarh",
                "Ballabgarh",
                "Barwala",
                "Bawal",
                "Bawani Khera",
                "Beri",
                "Bhiwani",
                "Bilaspur",
                "Buria",
                "Charkhi Dadri",
                "Chhachhrauli",
                "Chita",
                "Dabwali",
                "Dharuhera",
                "Dundahera",
                "Ellenabad",
                "Farakhpur",
                "Faridabad",
                "Farrukhnagar",
                "Fatehabad",
                "Firozpur Jhirka",
                "Gannaur",
                "Ghraunda",
                "Gohana",
                "Gurgaon",
                "Haileymandi",
                "Hansi",
                "Hasanpur",
                "Hathin",
                "Hisar",
                "Hissar",
                "Hodal",
                "Indri",
                "Jagadhri",
                "Jakhal Mandi",
                "Jhajjar",
                "Jind",
                "Julana",
                "Kaithal",
                "Kalanur",
                "Kalanwali",
                "Kalayat",
                "Kalka",
                "Kanina",
                "Kansepur",
                "Kardhan",
                "Karnal",
                "Kharkhoda",
                "Kheri Sampla",
                "Kundli",
                "Kurukshetra",
                "Ladrawan",
                "Ladwa",
                "Loharu",
                "Maham",
                "Mahendragarh",
                "Mustafabad",
                "Nagai Chaudhry",
                "Narayangarh",
                "Narnaul",
                "Narnaund",
                "Narwana",
                "Nilokheri",
                "Nuh",
                "Palwal",
                "Panchkula",
                "Panipat",
                "Panipat Taraf Ansar",
                "Panipat Taraf Makhdum Zadgan",
                "Panipat Taraf Rajputan",
                "Pehowa",
                "Pinjaur",
                "Punahana",
                "Pundri",
                "Radaur",
                "Raipur Rani",
                "Rania",
                "Ratiya",
                "Rewari",
                "Rohtak",
                "Ropar",
                "Sadauri",
                "Safidon",
                "Samalkha",
                "Sankhol",
                "Sasauli",
                "Shahabad",
                "Sirsa",
                "Siwani",
                "Sohna",
                "Sonipat",
                "Sukhrali",
                "Taoru",
                "Taraori",
                "Tauru",
                "Thanesar",
                "Tilpat",
                "Tohana",
                "Tosham",
                "Uchana",
                "Uklana Mandi",
                "Uncha Siwana",
                "Yamunanagar"
            ],
            "Himachal Pradesh": [
                "Arki",
                "Baddi",
                "Bakloh",
                "Banjar",
                "Bhota",
                "Bhuntar",
                "Bilaspur",
                "Chamba",
                "Chaupal",
                "Chuari Khas",
                "Dagshai",
                "Dalhousie",
                "Dalhousie Cantonment",
                "Damtal",
                "Daulatpur",
                "Dera Gopipur",
                "Dhalli",
                "Dharamshala",
                "Gagret",
                "Ghamarwin",
                "Hamirpur",
                "Jawala Mukhi",
                "Jogindarnagar",
                "Jubbal",
                "Jutogh",
                "Kala Amb",
                "Kalpa",
                "Kangra",
                "Kasauli",
                "Kot Khai",
                "Kullu",
                "Kulu",
                "Manali",
                "Mandi",
                "Mant Khas",
                "Mehatpur Basdehra",
                "Nadaun",
                "Nagrota",
                "Nahan",
                "Naina Devi",
                "Nalagarh",
                "Narkanda",
                "Nurpur",
                "Palampur",
                "Pandoh",
                "Paonta Sahib",
                "Parwanoo",
                "Parwanu",
                "Rajgarh",
                "Rampur",
                "Rawalsar",
                "Rohru",
                "Sabathu",
                "Santokhgarh",
                "Sarahan",
                "Sarka Ghat",
                "Seoni",
                "Shimla",
                "Sirmaur",
                "Solan",
                "Solon",
                "Sundarnagar",
                "Sundernagar",
                "Talai",
                "Theog",
                "Tira Sujanpur",
                "Una",
                "Yol"
            ],
            "Jammu and Kashmir": [
                "Achabal",
                "Akhnur",
                "Anantnag",
                "Arnia",
                "Awantipora",
                "Badami Bagh",
                "Bandipur",
                "Banihal",
                "Baramula",
                "Baramulla",
                "Bari Brahmana",
                "Bashohli",
                "Batote",
                "Bhaderwah",
                "Bijbiara",
                "Billawar",
                "Birwah",
                "Bishna",
                "Budgam",
                "Charari Sharief",
                "Chenani",
                "Doda",
                "Duru-Verinag",
                "Gandarbat",
                "Gho Manhasan",
                "Gorah Salathian",
                "Gulmarg",
                "Hajan",
                "Handwara",
                "Hiranagar",
                "Jammu",
                "Jammu Cantonment",
                "Jammu Tawi",
                "Jourian",
                "Kargil",
                "Kathua",
                "Katra",
                "Khan Sahib",
                "Khour",
                "Khrew",
                "Kishtwar",
                "Kud",
                "Kukernag",
                "Kulgam",
                "Kunzer",
                "Kupwara",
                "Lakhenpur",
                "Leh",
                "Magam",
                "Mattan",
                "Naushehra",
                "Pahalgam",
                "Pampore",
                "Parole",
                "Pattan",
                "Pulwama",
                "Punch",
                "Qazigund",
                "Rajauri",
                "Ramban",
                "Ramgarh",
                "Ramnagar",
                "Ranbirsingh Pora",
                "Reasi",
                "Rehambal",
                "Samba",
                "Shupiyan",
                "Sopur",
                "Srinagar",
                "Sumbal",
                "Sunderbani",
                "Talwara",
                "Thanamandi",
                "Tral",
                "Udhampur",
                "Uri",
                "Vijaypur"
            ],
            "Jharkhand": [
                "Adityapur",
                "Amlabad",
                "Angarpathar",
                "Ara",
                "Babua Kalan",
                "Bagbahra",
                "Baliapur",
                "Baliari",
                "Balkundra",
                "Bandhgora",
                "Barajamda",
                "Barhi",
                "Barka Kana",
                "Barki Saraiya",
                "Barughutu",
                "Barwadih",
                "Basaria",
                "Basukinath",
                "Bermo",
                "Bhagatdih",
                "Bhaurah",
                "Bhojudih",
                "Bhuli",
                "Bokaro",
                "Borio Bazar",
                "Bundu",
                "Chaibasa",
                "Chaitudih",
                "Chakradharpur",
                "Chakulia",
                "Chandaur",
                "Chandil",
                "Chandrapura",
                "Chas",
                "Chatra",
                "Chhatatanr",
                "Chhotaputki",
                "Chiria",
                "Chirkunda",
                "Churi",
                "Daltenganj",
                "Danguwapasi",
                "Dari",
                "Deoghar",
                "Deorikalan",
                "Devghar",
                "Dhanbad",
                "Dhanwar",
                "Dhaunsar",
                "Dugda",
                "Dumarkunda",
                "Dumka",
                "Egarkunr",
                "Gadhra",
                "Garwa",
                "Ghatsila",
                "Ghorabandha",
                "Gidi",
                "Giridih",
                "Gobindpur",
                "Godda",
                "Godhar",
                "Golphalbari",
                "Gomoh",
                "Gua",
                "Gumia",
                "Gumla",
                "Haludbani",
                "Hazaribag",
                "Hesla",
                "Husainabad",
                "Isri",
                "Jadugora",
                "Jagannathpur",
                "Jamadoba",
                "Jamshedpur",
                "Jamtara",
                "Jarangdih",
                "Jaridih",
                "Jasidih",
                "Jena",
                "Jharia",
                "Jharia Khas",
                "Jhinkpani",
                "Jhumri Tilaiya",
                "Jorapokhar",
                "Jugsalai",
                "Kailudih",
                "Kalikapur",
                "Kandra",
                "Kanke",
                "Katras",
                "Kedla",
                "Kenduadih",
                "Kharkhari",
                "Kharsawan",
                "Khelari",
                "Khunti",
                "Kiri Buru",
                "Kiriburu",
                "Kodarma",
                "Kuju",
                "Kurpania",
                "Kustai",
                "Lakarka",
                "Lapanga",
                "Latehar",
                "Lohardaga",
                "Loiya",
                "Loyabad",
                "Madhupur",
                "Mahesh Mundi",
                "Maithon",
                "Malkera",
                "Mango",
                "Manoharpur",
                "Marma",
                "Meghahatuburu Forest village",
                "Mera",
                "Meru",
                "Mihijam",
                "Mugma",
                "Muri",
                "Mushabani",
                "Nagri Kalan",
                "Netarhat",
                "Nirsa",
                "Noamundi",
                "Okni",
                "Orla",
                "Pakaur",
                "Palamau",
                "Palawa",
                "Panchet",
                "Panrra",
                "Paratdih",
                "Pathardih",
                "Patratu",
                "Phusro",
                "Pondar Kanali",
                "Rajmahal",
                "Ramgarh",
                "Ranchi",
                "Ray",
                "Rehla",
                "Religara",
                "Rohraband",
                "Sahibganj",
                "Sahnidih",
                "Saraidhela",
                "Saraikela",
                "Sarjamda",
                "Saunda",
                "Sewai",
                "Sijhua",
                "Sijua",
                "Simdega",
                "Sindari",
                "Sinduria",
                "Sini",
                "Sirka",
                "Siuliban",
                "Surubera",
                "Tati",
                "Tenudam",
                "Tisra",
                "Topa",
                "Topchanchi"
            ],
            "Karnataka": [
                "Adityanagar",
                "Adityapatna",
                "Afzalpur",
                "Ajjampur",
                "Aland",
                "Almatti Sitimani",
                "Alnavar",
                "Alur",
                "Ambikanagara",
                "Anekal",
                "Ankola",
                "Annigeri",
                "Arkalgud",
                "Arsikere",
                "Athni",
                "Aurad",
                "Badagavettu",
                "Badami",
                "Bagalkot",
                "Bagepalli",
                "Bailhongal",
                "Baindur",
                "Bajala",
                "Bajpe",
                "Banavar",
                "Bangarapet",
                "Bankapura",
                "Bannur",
                "Bantwal",
                "Basavakalyan",
                "Basavana Bagevadi",
                "Belagula",
                "Belakavadiq",
                "Belgaum",
                "Belgaum Cantonment",
                "Bellary",
                "Belluru",
                "Beltangadi",
                "Belur",
                "Belvata",
                "Bengaluru",
                "Bhadravati",
                "Bhalki",
                "Bhatkal",
                "Bhimarayanagudi",
                "Bhogadi",
                "Bidar",
                "Bijapur",
                "Bilgi",
                "Birur",
                "Bommanahalli",
                "Bommasandra",
                "Byadgi",
                "Byatarayanapura",
                "Chakranagar Colony",
                "Challakere",
                "Chamrajnagar",
                "Chamundi Betta",
                "Channagiri",
                "Channapatna",
                "Channarayapatna",
                "Chickballapur",
                "Chik Ballapur",
                "Chikkaballapur",
                "Chikmagalur",
                "Chiknayakanhalli",
                "Chikodi",
                "Chincholi",
                "Chintamani",
                "Chitaguppa",
                "Chitapur",
                "Chitradurga",
                "Coorg",
                "Dandeli",
                "Dargajogihalli",
                "Dasarahalli",
                "Davangere",
                "Devadurga",
                "Devagiri",
                "Devanhalli",
                "Dharwar",
                "Dhupdal",
                "Dod Ballapur",
                "Donimalai",
                "Gadag",
                "Gajendragarh",
                "Ganeshgudi",
                "Gangawati",
                "Gangoli",
                "Gauribidanur",
                "Gokak",
                "Gokak Falls",
                "Gonikoppal",
                "Gorur",
                "Gottikere",
                "Gubbi",
                "Gudibanda",
                "Gulbarga",
                "Guledgudda",
                "Gundlupet",
                "Gurmatkal",
                "Haliyal",
                "Hangal",
                "Harihar",
                "Harpanahalli",
                "Hassan",
                "Hatti",
                "Hatti Gold Mines",
                "Haveri",
                "Hebbagodi",
                "Hebbalu",
                "Hebri",
                "Heggadadevanakote",
                "Herohalli",
                "Hidkal",
                "Hindalgi",
                "Hirekerur",
                "Hiriyur",
                "Holalkere",
                "Hole Narsipur",
                "Homnabad",
                "Honavar",
                "Honnali",
                "Hosakote",
                "Hosanagara",
                "Hosangadi",
                "Hosdurga",
                "Hoskote",
                "Hospet",
                "Hubli",
                "Hukeri",
                "Hunasagi",
                "Hunasamaranahalli",
                "Hungund",
                "Hunsur",
                "Huvina Hadagalli",
                "Ilkal",
                "Indi",
                "Jagalur",
                "Jamkhandi",
                "Jevargi",
                "Jog Falls",
                "Kabini Colony",
                "Kadur",
                "Kalghatgi",
                "Kamalapuram",
                "Kampli",
                "Kanakapura",
                "Kangrali BK",
                "Kangrali KH",
                "Kannur",
                "Karkala",
                "Karwar",
                "Kemminja",
                "Kengeri",
                "Kerur",
                "Khanapur",
                "Kodigenahalli",
                "Kodiyal",
                "Kodlipet",
                "Kolar",
                "Kollegal",
                "Konanakunte",
                "Konanur",
                "Konnur",
                "Koppa",
                "Koppal",
                "Koratagere",
                "Kotekara",
                "Kothnur",
                "Kotturu",
                "Krishnapura",
                "Krishnarajanagar",
                "Krishnarajapura",
                "Krishnarajasagara",
                "Krishnarajpet",
                "Kudchi",
                "Kudligi",
                "Kudremukh",
                "Kumsi",
                "Kumta",
                "Kundapura",
                "Kundgol",
                "Kunigal",
                "Kurgunta",
                "Kushalnagar",
                "Kushtagi",
                "Kyathanahalli",
                "Lakshmeshwar",
                "Lingsugur",
                "Londa",
                "Maddur",
                "Madhugiri",
                "Madikeri",
                "Magadi",
                "Magod Falls",
                "Mahadeswara Hills",
                "Mahadevapura",
                "Mahalingpur",
                "Maisuru",
                "Maisuru Cantonment",
                "Malavalli",
                "Mallar",
                "Malpe",
                "Malur",
                "Manchenahalli",
                "Mandya",
                "Mangalore",
                "Mangaluru",
                "Manipal",
                "Manvi",
                "Maski",
                "Mastikatte Colony",
                "Mayakonda",
                "Melukote",
                "Molakalmuru",
                "Mudalgi",
                "Mudbidri",
                "Muddebihal",
                "Mudgal",
                "Mudhol",
                "Mudigere",
                "Mudushedde",
                "Mulbagal",
                "Mulgund",
                "Mulki",
                "Mulur",
                "Mundargi",
                "Mundgod",
                "Munirabad",
                "Munnur",
                "Murudeshwara",
                "Mysore",
                "Nagamangala",
                "Nanjangud",
                "Naragund",
                "Narasimharajapura",
                "Naravi",
                "Narayanpur",
                "Naregal",
                "Navalgund",
                "Nelmangala",
                "Nipani",
                "Nitte",
                "Nyamati",
                "Padu",
                "Pandavapura",
                "Pattanagere",
                "Pavagada",
                "Piriyapatna",
                "Ponnampet",
                "Puttur",
                "Rabkavi",
                "Raichur",
                "Ramanagaram",
                "Ramdurg",
                "Ranibennur",
                "Raybag",
                "Robertsonpet",
                "Ron",
                "Sadalgi",
                "Sagar",
                "Sakleshpur",
                "Saligram",
                "Sandur",
                "Sanivarsante",
                "Sankeshwar",
                "Sargur",
                "Sathyamangala",
                "Saundatti Yellamma",
                "Savanur",
                "Sedam",
                "Shahabad",
                "Shahabad A.C.C.",
                "Shahapur",
                "Shahpur",
                "Shaktinagar",
                "Shiggaon",
                "Shikarpur",
                "Shimoga",
                "Shirhatti",
                "Shorapur",
                "Shravanabelagola",
                "Shrirangapattana",
                "Siddapur",
                "Sidlaghatta",
                "Sindgi",
                "Sindhnur",
                "Sira",
                "Sirakoppa",
                "Sirsi",
                "Siruguppa",
                "Someshwar",
                "Somvarpet",
                "Sorab",
                "Sringeri",
                "Srinivaspur",
                "Sulya",
                "Suntikopa",
                "Talikota",
                "Tarikera",
                "Tekkalakota",
                "Terdal",
                "Thokur",
                "Thumbe",
                "Tiptur",
                "Tirthahalli",
                "Tirumakudal Narsipur",
                "Tonse",
                "Tumkur",
                "Turuvekere",
                "Udupi",
                "Ullal",
                "Uttarahalli",
                "Venkatapura",
                "Vijayapura",
                "Virarajendrapet",
                "Wadi",
                "Wadi A.C.C.",
                "Yadgir",
                "Yelahanka",
                "Yelandur",
                "Yelbarga",
                "Yellapur",
                "Yenagudde"
            ],
            "Kenmore": [
                "Adityanagar",
                "Adityapatna",
                "Afzalpur",
                "Ajjampur",
                "Aland",
                "Almatti Sitimani",
                "Alnavar",
                "Alur",
                "Ambikanagara",
                "Anekal",
                "Ankola",
                "Annigeri",
                "Arkalgud",
                "Arsikere",
                "Athni",
                "Aurad",
                "Badagavettu",
                "Badami",
                "Bagalkot",
                "Bagepalli",
                "Bailhongal",
                "Baindur",
                "Bajala",
                "Bajpe",
                "Banavar",
                "Bangarapet",
                "Bankapura",
                "Bannur",
                "Bantwal",
                "Basavakalyan",
                "Basavana Bagevadi",
                "Belagula",
                "Belakavadiq",
                "Belgaum",
                "Belgaum Cantonment",
                "Bellary",
                "Belluru",
                "Beltangadi",
                "Belur",
                "Belvata",
                "Bengaluru",
                "Bhadravati",
                "Bhalki",
                "Bhatkal",
                "Bhimarayanagudi",
                "Bhogadi",
                "Bidar",
                "Bijapur",
                "Bilgi",
                "Birur",
                "Bommanahalli",
                "Bommasandra",
                "Byadgi",
                "Byatarayanapura",
                "Chakranagar Colony",
                "Challakere",
                "Chamrajnagar",
                "Chamundi Betta",
                "Channagiri",
                "Channapatna",
                "Channarayapatna",
                "Chickballapur",
                "Chik Ballapur",
                "Chikkaballapur",
                "Chikmagalur",
                "Chiknayakanhalli",
                "Chikodi",
                "Chincholi",
                "Chintamani",
                "Chitaguppa",
                "Chitapur",
                "Chitradurga",
                "Coorg",
                "Dandeli",
                "Dargajogihalli",
                "Dasarahalli",
                "Davangere",
                "Devadurga",
                "Devagiri",
                "Devanhalli",
                "Dharwar",
                "Dhupdal",
                "Dod Ballapur",
                "Donimalai",
                "Gadag",
                "Gajendragarh",
                "Ganeshgudi",
                "Gangawati",
                "Gangoli",
                "Gauribidanur",
                "Gokak",
                "Gokak Falls",
                "Gonikoppal",
                "Gorur",
                "Gottikere",
                "Gubbi",
                "Gudibanda",
                "Gulbarga",
                "Guledgudda",
                "Gundlupet",
                "Gurmatkal",
                "Haliyal",
                "Hangal",
                "Harihar",
                "Harpanahalli",
                "Hassan",
                "Hatti",
                "Hatti Gold Mines",
                "Haveri",
                "Hebbagodi",
                "Hebbalu",
                "Hebri",
                "Heggadadevanakote",
                "Herohalli",
                "Hidkal",
                "Hindalgi",
                "Hirekerur",
                "Hiriyur",
                "Holalkere",
                "Hole Narsipur",
                "Homnabad",
                "Honavar",
                "Honnali",
                "Hosakote",
                "Hosanagara",
                "Hosangadi",
                "Hosdurga",
                "Hoskote",
                "Hospet",
                "Hubli",
                "Hukeri",
                "Hunasagi",
                "Hunasamaranahalli",
                "Hungund",
                "Hunsur",
                "Huvina Hadagalli",
                "Ilkal",
                "Indi",
                "Jagalur",
                "Jamkhandi",
                "Jevargi",
                "Jog Falls",
                "Kabini Colony",
                "Kadur",
                "Kalghatgi",
                "Kamalapuram",
                "Kampli",
                "Kanakapura",
                "Kangrali BK",
                "Kangrali KH",
                "Kannur",
                "Karkala",
                "Karwar",
                "Kemminja",
                "Kengeri",
                "Kerur",
                "Khanapur",
                "Kodigenahalli",
                "Kodiyal",
                "Kodlipet",
                "Kolar",
                "Kollegal",
                "Konanakunte",
                "Konanur",
                "Konnur",
                "Koppa",
                "Koppal",
                "Koratagere",
                "Kotekara",
                "Kothnur",
                "Kotturu",
                "Krishnapura",
                "Krishnarajanagar",
                "Krishnarajapura",
                "Krishnarajasagara",
                "Krishnarajpet",
                "Kudchi",
                "Kudligi",
                "Kudremukh",
                "Kumsi",
                "Kumta",
                "Kundapura",
                "Kundgol",
                "Kunigal",
                "Kurgunta",
                "Kushalnagar",
                "Kushtagi",
                "Kyathanahalli",
                "Lakshmeshwar",
                "Lingsugur",
                "Londa",
                "Maddur",
                "Madhugiri",
                "Madikeri",
                "Magadi",
                "Magod Falls",
                "Mahadeswara Hills",
                "Mahadevapura",
                "Mahalingpur",
                "Maisuru",
                "Maisuru Cantonment",
                "Malavalli",
                "Mallar",
                "Malpe",
                "Malur",
                "Manchenahalli",
                "Mandya",
                "Mangalore",
                "Mangaluru",
                "Manipal",
                "Manvi",
                "Maski",
                "Mastikatte Colony",
                "Mayakonda",
                "Melukote",
                "Molakalmuru",
                "Mudalgi",
                "Mudbidri",
                "Muddebihal",
                "Mudgal",
                "Mudhol",
                "Mudigere",
                "Mudushedde",
                "Mulbagal",
                "Mulgund",
                "Mulki",
                "Mulur",
                "Mundargi",
                "Mundgod",
                "Munirabad",
                "Munnur",
                "Murudeshwara",
                "Mysore",
                "Nagamangala",
                "Nanjangud",
                "Naragund",
                "Narasimharajapura",
                "Naravi",
                "Narayanpur",
                "Naregal",
                "Navalgund",
                "Nelmangala",
                "Nipani",
                "Nitte",
                "Nyamati",
                "Padu",
                "Pandavapura",
                "Pattanagere",
                "Pavagada",
                "Piriyapatna",
                "Ponnampet",
                "Puttur",
                "Rabkavi",
                "Raichur",
                "Ramanagaram",
                "Ramdurg",
                "Ranibennur",
                "Raybag",
                "Robertsonpet",
                "Ron",
                "Sadalgi",
                "Sagar",
                "Sakleshpur",
                "Saligram",
                "Sandur",
                "Sanivarsante",
                "Sankeshwar",
                "Sargur",
                "Sathyamangala",
                "Saundatti Yellamma",
                "Savanur",
                "Sedam",
                "Shahabad",
                "Shahabad A.C.C.",
                "Shahapur",
                "Shahpur",
                "Shaktinagar",
                "Shiggaon",
                "Shikarpur",
                "Shimoga",
                "Shirhatti",
                "Shorapur",
                "Shravanabelagola",
                "Shrirangapattana",
                "Siddapur",
                "Sidlaghatta",
                "Sindgi",
                "Sindhnur",
                "Sira",
                "Sirakoppa",
                "Sirsi",
                "Siruguppa",
                "Someshwar",
                "Somvarpet",
                "Sorab",
                "Sringeri",
                "Srinivaspur",
                "Sulya",
                "Suntikopa",
                "Talikota",
                "Tarikera",
                "Tekkalakota",
                "Terdal",
                "Thokur",
                "Thumbe",
                "Tiptur",
                "Tirthahalli",
                "Tirumakudal Narsipur",
                "Tonse",
                "Tumkur",
                "Turuvekere",
                "Udupi",
                "Ullal",
                "Uttarahalli",
                "Venkatapura",
                "Vijayapura",
                "Virarajendrapet",
                "Wadi",
                "Wadi A.C.C.",
                "Yadgir",
                "Yelahanka",
                "Yelandur",
                "Yelbarga",
                "Yellapur",
                "Yenagudde"
            ],
            "Kerala": [
                "Adimaly",
                "Adoor",
                "Adur",
                "Akathiyur",
                "Alangad",
                "Alappuzha",
                "Aluva",
                "Ancharakandy",
                "Angamaly",
                "Aroor",
                "Arukutti",
                "Attingal",
                "Avinissery",
                "Azhikode North",
                "Azhikode South",
                "Azhiyur",
                "Balussery",
                "Bangramanjeshwar",
                "Beypur",
                "Brahmakulam",
                "Chala",
                "Chalakudi",
                "Changanacheri",
                "Chauwara",
                "Chavakkad",
                "Chelakkara",
                "Chelora",
                "Chendamangalam",
                "Chengamanad",
                "Chengannur",
                "Cheranallur",
                "Cheriyakadavu",
                "Cherthala",
                "Cherukunnu",
                "Cheruthazham",
                "Cheruvannur",
                "Cheruvattur",
                "Chevvur",
                "Chirakkal",
                "Chittur",
                "Chockli",
                "Churnikkara",
                "Dharmadam",
                "Edappal",
                "Edathala",
                "Elayavur",
                "Elur",
                "Eranholi",
                "Erattupetta",
                "Ernakulam",
                "Eruvatti",
                "Ettumanoor",
                "Feroke",
                "Guruvayur",
                "Haripad",
                "Hosabettu",
                "Idukki",
                "Iringaprom",
                "Irinjalakuda",
                "Iriveri",
                "Kadachira",
                "Kadalundi",
                "Kadamakkudy",
                "Kadirur",
                "Kadungallur",
                "Kakkodi",
                "Kalady",
                "Kalamassery",
                "Kalliasseri",
                "Kalpetta",
                "Kanhangad",
                "Kanhirode",
                "Kanjikkuzhi",
                "Kanjikode",
                "Kanjirappalli",
                "Kannadiparamba",
                "Kannangad",
                "Kannapuram",
                "Kannur",
                "Kannur Cantonment",
                "Karunagappally",
                "Karuvamyhuruthy",
                "Kasaragod",
                "Kasargod",
                "Kattappana",
                "Kayamkulam",
                "Kedamangalam",
                "Kochi",
                "Kodamthuruthu",
                "Kodungallur",
                "Koduvally",
                "Koduvayur",
                "Kokkothamangalam",
                "Kolazhy",
                "Kollam",
                "Komalapuram",
                "Koothattukulam",
                "Koratty",
                "Kothamangalam",
                "Kottarakkara",
                "Kottayam",
                "Kottayam Malabar",
                "Kottuvally",
                "Koyilandi",
                "Kozhikode",
                "Kudappanakunnu",
                "Kudlu",
                "Kumarakom",
                "Kumily",
                "Kunnamangalam",
                "Kunnamkulam",
                "Kurikkad",
                "Kurkkanchery",
                "Kuthuparamba",
                "Kuttakulam",
                "Kuttikkattur",
                "Kuttur",
                "Malappuram",
                "Mallappally",
                "Manjeri",
                "Manjeshwar",
                "Mannancherry",
                "Mannar",
                "Mannarakkat",
                "Maradu",
                "Marathakkara",
                "Marutharod",
                "Mattannur",
                "Mavelikara",
                "Mavilayi",
                "Mavur",
                "Methala",
                "Muhamma",
                "Mulavukad",
                "Mundakayam",
                "Munderi",
                "Munnar",
                "Muthakunnam",
                "Muvattupuzha",
                "Muzhappilangad",
                "Nadapuram",
                "Nadathara",
                "Narath",
                "Nattakam",
                "Nedumangad",
                "Nenmenikkara",
                "New Mahe",
                "Neyyattinkara",
                "Nileshwar",
                "Olavanna",
                "Ottapalam",
                "Ottappalam",
                "Paduvilayi",
                "Palai",
                "Palakkad",
                "Palayad",
                "Palissery",
                "Pallikkunnu",
                "Paluvai",
                "Panniyannur",
                "Pantalam",
                "Panthiramkavu",
                "Panur",
                "Pappinisseri",
                "Parassala",
                "Paravur",
                "Pathanamthitta",
                "Pathanapuram",
                "Pathiriyad",
                "Pattambi",
                "Pattiom",
                "Pavaratty",
                "Payyannur",
                "Peermade",
                "Perakam",
                "Peralasseri",
                "Peringathur",
                "Perinthalmanna",
                "Perole",
                "Perumanna",
                "Perumbaikadu",
                "Perumbavoor",
                "Pinarayi",
                "Piravam",
                "Ponnani",
                "Pottore",
                "Pudukad",
                "Punalur",
                "Puranattukara",
                "Puthunagaram",
                "Puthuppariyaram",
                "Puzhathi",
                "Ramanattukara",
                "Shoranur",
                "Sultans Battery",
                "Sulthan Bathery",
                "Talipparamba",
                "Thaikkad",
                "Thalassery",
                "Thannirmukkam",
                "Theyyalingal",
                "Thiruvalla",
                "Thiruvananthapuram",
                "Thiruvankulam",
                "Thodupuzha",
                "Thottada",
                "Thrippunithura",
                "Thrissur",
                "Tirur",
                "Udma",
                "Vadakara",
                "Vaikam",
                "Valapattam",
                "Vallachira",
                "Varam",
                "Varappuzha",
                "Varkala",
                "Vayalar",
                "Vazhakkala",
                "Venmanad",
                "Villiappally",
                "Wayanad"
            ],
            "Lakshadweep": [
                "Agethi",
                "Amini",
                "Androth Island",
                "Kavaratti",
                "Minicoy"
            ],
            "Madhya Pradesh": [
                "Agar",
                "Ajaigarh",
                "Akoda",
                "Akodia",
                "Alampur",
                "Alirajpur",
                "Alot",
                "Amanganj",
                "Amarkantak",
                "Amarpatan",
                "Amarwara",
                "Ambada",
                "Ambah",
                "Amla",
                "Amlai",
                "Anjad",
                "Antri",
                "Anuppur",
                "Aron",
                "Ashoknagar",
                "Ashta",
                "Babai",
                "Bada Malhera",
                "Badagaon",
                "Badagoan",
                "Badarwas",
                "Badawada",
                "Badi",
                "Badkuhi",
                "Badnagar",
                "Badnawar",
                "Badod",
                "Badoda",
                "Badra",
                "Bagh",
                "Bagli",
                "Baihar",
                "Baikunthpur",
                "Bakswaha",
                "Balaghat",
                "Baldeogarh",
                "Bamaniya",
                "Bamhani",
                "Bamor",
                "Bamora",
                "Banda",
                "Bangawan",
                "Bansatar Kheda",
                "Baraily",
                "Barela",
                "Barghat",
                "Bargi",
                "Barhi",
                "Barigarh",
                "Barwaha",
                "Barwani",
                "Basoda",
                "Begamganj",
                "Beohari",
                "Berasia",
                "Betma",
                "Betul",
                "Betul Bazar",
                "Bhainsdehi",
                "Bhamodi",
                "Bhander",
                "Bhanpura",
                "Bharveli",
                "Bhaurasa",
                "Bhavra",
                "Bhedaghat",
                "Bhikangaon",
                "Bhilakhedi",
                "Bhind",
                "Bhitarwar",
                "Bhopal",
                "Bhuibandh",
                "Biaora",
                "Bijawar",
                "Bijeypur",
                "Bijrauni",
                "Bijuri",
                "Bilaua",
                "Bilpura",
                "Bina Railway Colony",
                "Bina-Etawa",
                "Birsinghpur",
                "Boda",
                "Budhni",
                "Burhanpur",
                "Burhar",
                "Chachaura Binaganj",
                "Chakghat",
                "Chandameta Butar",
                "Chanderi",
                "Chandia",
                "Chandla",
                "Chaurai Khas",
                "Chhatarpur",
                "Chhindwara",
                "Chhota Chhindwara",
                "Chichli",
                "Chitrakut",
                "Churhat",
                "Daboh",
                "Dabra",
                "Damoh",
                "Damua",
                "Datia",
                "Deodara",
                "Deori",
                "Deori Khas",
                "Depalpur",
                "Devendranagar",
                "Devhara",
                "Dewas",
                "Dhamnod",
                "Dhana",
                "Dhanpuri",
                "Dhar",
                "Dharampuri",
                "Dighawani",
                "Diken",
                "Dindori",
                "Dola",
                "Dumar Kachhar",
                "Dungariya Chhapara",
                "Gadarwara",
                "Gairatganj",
                "Gandhi Sagar Hydel Colony",
                "Ganjbasoda",
                "Garhakota",
                "Garhi Malhara",
                "Garoth",
                "Gautapura",
                "Ghansor",
                "Ghuwara",
                "Gogaon",
                "Gogapur",
                "Gohad",
                "Gormi",
                "Govindgarh",
                "Guna",
                "Gurh",
                "Gwalior",
                "Hanumana",
                "Harda",
                "Harpalpur",
                "Harrai",
                "Harsud",
                "Hatod",
                "Hatpipalya",
                "Hatta",
                "Hindoria",
                "Hirapur",
                "Hoshangabad",
                "Ichhawar",
                "Iklehra",
                "Indergarh",
                "Indore",
                "Isagarh",
                "Itarsi",
                "Jabalpur",
                "Jabalpur Cantonment",
                "Jabalpur G.C.F",
                "Jaisinghnagar",
                "Jaithari",
                "Jaitwara",
                "Jamai",
                "Jaora",
                "Jatachhapar",
                "Jatara",
                "Jawad",
                "Jawar",
                "Jeronkhalsa",
                "Jhabua",
                "Jhundpura",
                "Jiran",
                "Jirapur",
                "Jobat",
                "Joura",
                "Kailaras",
                "Kaimur",
                "Kakarhati",
                "Kalichhapar",
                "Kanad",
                "Kannod",
                "Kantaphod",
                "Kareli",
                "Karera",
                "Kari",
                "Karnawad",
                "Karrapur",
                "Kasrawad",
                "Katangi",
                "Katni",
                "Kelhauri",
                "Khachrod",
                "Khajuraho",
                "Khamaria",
                "Khand",
                "Khandwa",
                "Khaniyadhana",
                "Khargapur",
                "Khargone",
                "Khategaon",
                "Khetia",
                "Khilchipur",
                "Khirkiya",
                "Khujner",
                "Khurai",
                "Kolaras",
                "Kotar",
                "Kothi",
                "Kotma",
                "Kukshi",
                "Kumbhraj",
                "Kurwai",
                "Lahar",
                "Lakhnadon",
                "Lateri",
                "Laundi",
                "Lidhora Khas",
                "Lodhikheda",
                "Loharda",
                "Machalpur",
                "Madhogarh",
                "Maharajpur",
                "Maheshwar",
                "Mahidpur",
                "Maihar",
                "Majholi",
                "Makronia",
                "Maksi",
                "Malaj Khand",
                "Malanpur",
                "Malhargarh",
                "Manasa",
                "Manawar",
                "Mandav",
                "Mandideep",
                "Mandla",
                "Mandleshwar",
                "Mandsaur",
                "Manegaon",
                "Mangawan",
                "Manglaya Sadak",
                "Manpur",
                "Mau",
                "Mauganj",
                "Meghnagar",
                "Mehara Gaon",
                "Mehgaon",
                "Mhaugaon",
                "Mhow",
                "Mihona",
                "Mohgaon",
                "Morar",
                "Morena",
                "Morwa",
                "Multai",
                "Mundi",
                "Mungaoli",
                "Murwara",
                "Nagda",
                "Nagod",
                "Nagri",
                "Naigarhi",
                "Nainpur",
                "Nalkheda",
                "Namli",
                "Narayangarh",
                "Narsimhapur",
                "Narsingarh",
                "Narsinghpur",
                "Narwar",
                "Nasrullaganj",
                "Naudhia",
                "Naugaon",
                "Naurozabad",
                "Neemuch",
                "Nepa Nagar",
                "Neuton Chikhli Kalan",
                "Nimach",
                "Niwari",
                "Obedullaganj",
                "Omkareshwar",
                "Orachha",
                "Ordinance Factory Itarsi",
                "Pachmarhi",
                "Pachmarhi Cantonment",
                "Pachore",
                "Palchorai",
                "Palda",
                "Palera",
                "Pali",
                "Panagar",
                "Panara",
                "Pandaria",
                "Pandhana",
                "Pandhurna",
                "Panna",
                "Pansemal",
                "Parasia",
                "Pasan",
                "Patan",
                "Patharia",
                "Pawai",
                "Petlawad",
                "Phuph Kalan",
                "Pichhore",
                "Pipariya",
                "Pipliya Mandi",
                "Piploda",
                "Pithampur",
                "Polay Kalan",
                "Porsa",
                "Prithvipur",
                "Raghogarh",
                "Rahatgarh",
                "Raisen",
                "Rajakhedi",
                "Rajgarh",
                "Rajnagar",
                "Rajpur",
                "Rampur Baghelan",
                "Rampur Naikin",
                "Rampura",
                "Ranapur",
                "Ranipura",
                "Ratangarh",
                "Ratlam",
                "Ratlam Kasba",
                "Rau",
                "Rehli",
                "Rehti",
                "Rewa",
                "Sabalgarh",
                "Sagar",
                "Sagar Cantonment",
                "Sailana",
                "Sanawad",
                "Sanchi",
                "Sanwer",
                "Sarangpur",
                "Sardarpur",
                "Sarni",
                "Satai",
                "Satna",
                "Satwas",
                "Sausar",
                "Sehore",
                "Semaria",
                "Sendhwa",
                "Seondha",
                "Seoni",
                "Seoni Malwa",
                "Sethia",
                "Shahdol",
                "Shahgarh",
                "Shahpur",
                "Shahpura",
                "Shajapur",
                "Shamgarh",
                "Sheopur",
                "Shivpuri",
                "Shujalpur",
                "Sidhi",
                "Sihora",
                "Singolo",
                "Singrauli",
                "Sinhasa",
                "Sirgora",
                "Sirmaur",
                "Sironj",
                "Sitamau",
                "Sohagpur",
                "Sonkatch",
                "Soyatkalan",
                "Suhagi",
                "Sultanpur",
                "Susner",
                "Suthaliya",
                "Tal",
                "Talen",
                "Tarana",
                "Taricharkalan",
                "Tekanpur",
                "Tendukheda",
                "Teonthar",
                "Thandia",
                "Tikamgarh",
                "Timarni",
                "Tirodi",
                "Udaipura",
                "Ujjain",
                "Ukwa",
                "Umaria",
                "Unchahara",
                "Unhel",
                "Vehicle Factory Jabalpur",
                "Vidisha",
                "Vijayraghavgarh",
                "Waraseoni"
            ],
            "Maharashtra": [
                "Achalpur",
                "Aheri",
                "Ahmadnagar Cantonment",
                "Ahmadpur",
                "Ahmednagar",
                "Ajra",
                "Akalkot",
                "Akkalkuwa",
                "Akola",
                "Akot",
                "Alandi",
                "Alibag",
                "Allapalli",
                "Alore",
                "Amalner",
                "Ambad",
                "Ambajogai",
                "Ambernath",
                "Ambivali Tarf Wankhal",
                "Amgaon",
                "Amravati",
                "Anjangaon",
                "Arvi",
                "Ashta",
                "Ashti",
                "Aurangabad",
                "Aurangabad Cantonment",
                "Ausa",
                "Babhulgaon",
                "Badlapur",
                "Balapur",
                "Ballarpur",
                "Baramati",
                "Barshi",
                "Basmat",
                "Beed",
                "Bhadravati",
                "Bhagur",
                "Bhandara",
                "Bhigvan",
                "Bhingar",
                "Bhiwandi",
                "Bhokhardan",
                "Bhor",
                "Bhosari",
                "Bhum",
                "Bhusawal",
                "Bid",
                "Biloli",
                "Birwadi",
                "Boisar",
                "Bop Khel",
                "Brahmapuri",
                "Budhgaon",
                "Buldana",
                "Buldhana",
                "Butibori",
                "Chakan",
                "Chalisgaon",
                "Chandrapur",
                "Chandur",
                "Chandur Bazar",
                "Chandvad",
                "Chicholi",
                "Chikhala",
                "Chikhaldara",
                "Chikhli",
                "Chinchani",
                "Chinchwad",
                "Chiplun",
                "Chopda",
                "Dabhol",
                "Dahance",
                "Dahanu",
                "Daharu",
                "Dapoli Camp",
                "Darwa",
                "Daryapur",
                "Dattapur",
                "Daund",
                "Davlameti",
                "Deglur",
                "Dehu Road",
                "Deolali",
                "Deolali Pravara",
                "Deoli",
                "Desaiganj",
                "Deulgaon Raja",
                "Dewhadi",
                "Dharangaon",
                "Dharmabad",
                "Dharur",
                "Dhatau",
                "Dhule",
                "Digdoh",
                "Diglur",
                "Digras",
                "Dombivli",
                "Dondaicha",
                "Dudhani",
                "Durgapur",
                "Dyane",
                "Edandol",
                "Eklahare",
                "Faizpur",
                "Fekari",
                "Gadchiroli",
                "Gadhinghaj",
                "Gandhi Nagar",
                "Ganeshpur",
                "Gangakher",
                "Gangapur",
                "Gevrai",
                "Ghatanji",
                "Ghoti",
                "Ghugus",
                "Ghulewadi",
                "Godoli",
                "Gondia",
                "Guhagar",
                "Hadgaon",
                "Harnai Beach",
                "Hinganghat",
                "Hingoli",
                "Hupari",
                "Ichalkaranji",
                "Igatpuri",
                "Indapur",
                "Jaisinghpur",
                "Jalgaon",
                "Jalna",
                "Jamkhed",
                "Jawhar",
                "Jaysingpur",
                "Jejuri",
                "Jintur",
                "Junnar",
                "Kabnur",
                "Kagal",
                "Kalamb",
                "Kalamnuri",
                "Kalas",
                "Kalmeshwar",
                "Kalundre",
                "Kalyan",
                "Kamthi",
                "Kamthi Cantonment",
                "Kandari",
                "Kandhar",
                "Kandri",
                "Kandri II",
                "Kanhan",
                "Kankavli",
                "Kannad",
                "Karad",
                "Karanja",
                "Karanje Tarf",
                "Karivali",
                "Karjat",
                "Karmala",
                "Kasara Budruk",
                "Katai",
                "Katkar",
                "Katol",
                "Kegaon",
                "Khadkale",
                "Khadki",
                "Khamgaon",
                "Khapa",
                "Kharadi",
                "Kharakvasla",
                "Khed",
                "Kherdi",
                "Khoni",
                "Khopoli",
                "Khuldabad",
                "Kinwat",
                "Kodoli",
                "Kolhapur",
                "Kon",
                "Kondumal",
                "Kopargaon",
                "Kopharad",
                "Koradi",
                "Koregaon",
                "Korochi",
                "Kudal",
                "Kundaim",
                "Kundalwadi",
                "Kurandvad",
                "Kurduvadi",
                "Kusgaon Budruk",
                "Lanja",
                "Lasalgaon",
                "Latur",
                "Loha",
                "Lohegaon",
                "Lonar",
                "Lonavala",
                "Madhavnagar",
                "Mahabaleshwar",
                "Mahad",
                "Mahadula",
                "Maindargi",
                "Majalgaon",
                "Malegaon",
                "Malgaon",
                "Malkapur",
                "Malwan",
                "Manadur",
                "Manchar",
                "Mangalvedhe",
                "Mangrul Pir",
                "Manmad",
                "Manor",
                "Mansar",
                "Manwath",
                "Mapuca",
                "Matheran",
                "Mehkar",
                "Mhasla",
                "Mhaswad",
                "Mira Bhayandar",
                "Miraj",
                "Mohpa",
                "Mohpada",
                "Moram",
                "Morshi",
                "Mowad",
                "Mudkhed",
                "Mukhed",
                "Mul",
                "Mulshi",
                "Mumbai",
                "Murbad",
                "Murgud",
                "Murtijapur",
                "Murud",
                "Nachane",
                "Nagardeole",
                "Nagothane",
                "Nagpur",
                "Nakoda",
                "Nalasopara",
                "Naldurg",
                "Nanded",
                "Nandgaon",
                "Nandura",
                "Nandurbar",
                "Narkhed",
                "Nashik",
                "Navapur",
                "Navi Mumbai",
                "Navi Mumbai Panvel",
                "Neral",
                "Nigdi",
                "Nilanga",
                "Nildoh",
                "Nimbhore",
                "Ojhar",
                "Osmanabad",
                "Pachgaon",
                "Pachora",
                "Padagha",
                "Paithan",
                "Palghar",
                "Pali",
                "Panchgani",
                "Pandhakarwada",
                "Pandharpur",
                "Panhala",
                "Panvel",
                "Paranda",
                "Parbhani",
                "Parli",
                "Parola",
                "Partur",
                "Pasthal",
                "Patan",
                "Pathardi",
                "Pathri",
                "Patur",
                "Pawni",
                "Pen",
                "Pethumri",
                "Phaltan",
                "Pimpri",
                "Poladpur",
                "Pulgaon",
                "Pune",
                "Pune Cantonment",
                "Purna",
                "Purushottamnagar",
                "Pusad",
                "Rahimatpur",
                "Rahta Pimplas",
                "Rahuri",
                "Raigad",
                "Rajapur",
                "Rajgurunagar",
                "Rajur",
                "Rajura",
                "Ramtek",
                "Ratnagiri",
                "Ravalgaon",
                "Raver",
                "Revadanda",
                "Risod",
                "Roha Ashtami",
                "Sakri",
                "Sandor",
                "Sangamner",
                "Sangli",
                "Sangole",
                "Sasti",
                "Sasvad",
                "Satana",
                "Satara",
                "Savantvadi",
                "Savda",
                "Savner",
                "Sawari Jawharnagar",
                "Selu",
                "Shahada",
                "Shahapur",
                "Shegaon",
                "Shelar",
                "Shendurjana",
                "Shirdi",
                "Shirgaon",
                "Shirpur",
                "Shirur",
                "Shirwal",
                "Shivatkar",
                "Shrigonda",
                "Shrirampur",
                "Shrirampur Rural",
                "Sillewada",
                "Sillod",
                "Sindhudurg",
                "Sindi",
                "Sindi Turf Hindnagar",
                "Sindkhed Raja",
                "Singnapur",
                "Sinnar",
                "Sirur",
                "Sitasawangi",
                "Solapur",
                "Sonai",
                "Sonegaon",
                "Soyagaon",
                "Srivardhan",
                "Surgana",
                "Talegaon Dabhade",
                "Taloda",
                "Taloja",
                "Talwade",
                "Tarapur",
                "Tasgaon",
                "Tathavade",
                "Tekadi",
                "Telhara",
                "Thane",
                "Tirira",
                "Totaladoh",
                "Trimbak",
                "Tuljapur",
                "Tumsar",
                "Uchgaon",
                "Udgir",
                "Ulhasnagar",
                "Umarga",
                "Umarkhed",
                "Umarsara",
                "Umbar Pada Nandade",
                "Umred",
                "Umri Pragane Balapur",
                "Uran",
                "Uran Islampur",
                "Utekhol",
                "Vada",
                "Vadgaon",
                "Vadgaon Kasba",
                "Vaijapur",
                "Vanvadi",
                "Varangaon",
                "Vasai",
                "Vasantnagar",
                "Vashind",
                "Vengurla",
                "Virar",
                "Visapur",
                "Vite",
                "Vithalwadi",
                "Wadi",
                "Waghapur",
                "Wai",
                "Wajegaon",
                "Walani",
                "Wanadongri",
                "Wani",
                "Wardha",
                "Warora",
                "Warthi",
                "Warud",
                "Washim",
                "Yaval",
                "Yavatmal",
                "Yeola",
                "Yerkheda"
            ],
            "Manipur": [
                "Andro",
                "Bijoy Govinda",
                "Bishnupur",
                "Churachandpur",
                "Heriok",
                "Imphal",
                "Jiribam",
                "Kakching",
                "Kakching Khunou",
                "Khongman",
                "Kumbi",
                "Kwakta",
                "Lamai",
                "Lamjaotongba",
                "Lamshang",
                "Lilong",
                "Mayang Imphal",
                "Moirang",
                "Moreh",
                "Nambol",
                "Naoriya Pakhanglakpa",
                "Ningthoukhong",
                "Oinam",
                "Porompat",
                "Samurou",
                "Sekmai Bazar",
                "Senapati",
                "Sikhong Sekmai",
                "Sugnu",
                "Thongkhong Laxmi Bazar",
                "Thoubal",
                "Torban",
                "Wangjing",
                "Wangoi",
                "Yairipok"
            ],
            "Meghalaya": [
                "Baghmara",
                "Cherrapunji",
                "Jawai",
                "Madanrting",
                "Mairang",
                "Mawlai",
                "Nongmynsong",
                "Nongpoh",
                "Nongstoin",
                "Nongthymmai",
                "Pynthorumkhrah",
                "Resubelpara",
                "Shillong",
                "Shillong Cantonment",
                "Tura",
                "Williamnagar"
            ],
            "Mizoram": [
                "Aizawl",
                "Bairabi",
                "Biate",
                "Champhai",
                "Darlawn",
                "Hnahthial",
                "Kawnpui",
                "Khawhai",
                "Khawzawl",
                "Kolasib",
                "Lengpui",
                "Lunglei",
                "Mamit",
                "North Vanlaiphai",
                "Saiha",
                "Sairang",
                "Saitul",
                "Serchhip",
                "Thenzawl",
                "Tlabung",
                "Vairengte",
                "Zawlnuam"
            ],
            "Nagaland": [
                "Chumukedima",
                "Dimapur",
                "Kohima",
                "Mokokchung",
                "Mon",
                "Phek",
                "Tuensang",
                "Wokha",
                "Zunheboto"
            ],
            "Odisha": [
                "Anandapur",
                "Angul",
                "Aska",
                "Athgarh",
                "Athmallik",
                "Balagoda",
                "Balangir",
                "Balasore",
                "Baleshwar",
                "Balimeta",
                "Balugaon",
                "Banapur",
                "Bangura",
                "Banki",
                "Banposh",
                "Barbil",
                "Bargarh",
                "Baripada",
                "Barpali",
                "Basudebpur",
                "Baudh",
                "Belagachhia",
                "Belaguntha",
                "Belpahar",
                "Berhampur",
                "Bhadrak",
                "Bhanjanagar",
                "Bhawanipatna",
                "Bhuban",
                "Bhubaneswar",
                "Binika",
                "Birmitrapur",
                "Bishama Katek",
                "Bolangir",
                "Brahmapur",
                "Brajrajnagar",
                "Buguda",
                "Burla",
                "Byasanagar",
                "Champua",
                "Chandapur",
                "Chandbali",
                "Chandili",
                "Charibatia",
                "Chatrapur",
                "Chikitigarh",
                "Chitrakonda",
                "Choudwar",
                "Cuttack",
                "Dadhapatna",
                "Daitari",
                "Damanjodi",
                "Deogarh",
                "Deracolliery",
                "Dhamanagar",
                "Dhenkanal",
                "Digapahandi",
                "Dungamal",
                "Fertilizer Corporation of Indi",
                "Ganjam",
                "Ghantapada",
                "Gopalpur",
                "Gudari",
                "Gunupur",
                "Hatibandha",
                "Hinjilikatu",
                "Hirakud",
                "Jagatsinghapur",
                "Jajpur",
                "Jalda",
                "Jaleswar",
                "Jatni",
                "Jaypur",
                "Jeypore",
                "Jharsuguda",
                "Jhumpura",
                "Joda",
                "Junagarh",
                "Kamakhyanagar",
                "Kantabanji",
                "Kantilo",
                "Karanja",
                "Kashinagara",
                "Kataka",
                "Kavisuryanagar",
                "Kendrapara",
                "Kendujhar",
                "Keonjhar",
                "Kesinga",
                "Khaliapali",
                "Khalikote",
                "Khandaparha",
                "Kharhial",
                "Kharhial Road",
                "Khatiguda",
                "Khurda",
                "Kochinda",
                "Kodala",
                "Konark",
                "Koraput",
                "Kotaparh",
                "Lanjigarh",
                "Lattikata",
                "Makundapur",
                "Malkangiri",
                "Mukhiguda",
                "Nabarangpur",
                "Nalco",
                "Naurangapur",
                "Nayagarh",
                "Nilagiri",
                "Nimaparha",
                "Nuapada",
                "Nuapatna",
                "OCL Industrialship",
                "Padampur",
                "Paradip",
                "Paradwip",
                "Parlakimidi",
                "Patamundai",
                "Patnagarh",
                "Phulabani",
                "Pipili",
                "Polasara",
                "Pratapsasan",
                "Puri",
                "Purushottampur",
                "Rairangpur",
                "Raj Gangpur",
                "Rambha",
                "Raurkela",
                "Raurkela Civil Township",
                "Rayagada",
                "Redhakhol",
                "Remuna",
                "Rengali",
                "Rourkela",
                "Sambalpur",
                "Sinapali",
                "Sonepur",
                "Sorada",
                "Soro",
                "Sunabeda",
                "Sundargarh",
                "Talcher",
                "Talcher Thermal Power Station ",
                "Tarabha",
                "Tensa",
                "Titlagarh",
                "Udala",
                "Udayagiri",
                "Umarkot",
                "Vikrampur"
            ],
            "Pondicherry": [
                "Ariankuppam",
                "Karaikal",
                "Kurumbapet",
                "Mahe",
                "Ozhukarai",
                "Pondicherry",
                "Villianur",
                "Yanam"
            ],
            "Punjab": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Rajasthan": [
                "Abu Road",
                "Ajmer",
                "Aklera",
                "Alwar",
                "Amet",
                "Antah",
                "Anupgarh",
                "Asind",
                "Bagar",
                "Bagru",
                "Bahror",
                "Bakani",
                "Bali",
                "Balotra",
                "Bandikui",
                "Banswara",
                "Baran",
                "Bari",
                "Bari Sadri",
                "Barmer",
                "Basi",
                "Basni Belima",
                "Baswa",
                "Bayana",
                "Beawar",
                "Begun",
                "Bhadasar",
                "Bhadra",
                "Bhalariya",
                "Bharatpur",
                "Bhasawar",
                "Bhawani Mandi",
                "Bhawri",
                "Bhilwara",
                "Bhindar",
                "Bhinmal",
                "Bhiwadi",
                "Bijoliya Kalan",
                "Bikaner",
                "Bilara",
                "Bissau",
                "Borkhera",
                "Budhpura",
                "Bundi",
                "Chatsu",
                "Chechat",
                "Chhabra",
                "Chhapar",
                "Chhipa Barod",
                "Chhoti Sadri",
                "Chirawa",
                "Chittaurgarh",
                "Chittorgarh",
                "Chomun",
                "Churu",
                "Daosa",
                "Dariba",
                "Dausa",
                "Deoli",
                "Deshnok",
                "Devgarh",
                "Devli",
                "Dhariawad",
                "Dhaulpur",
                "Dholpur",
                "Didwana",
                "Dig",
                "Dungargarh",
                "Dungarpur",
                "Falna",
                "Fatehnagar",
                "Fatehpur",
                "Gajsinghpur",
                "Galiakot",
                "Ganganagar",
                "Gangapur",
                "Goredi Chancha",
                "Gothra",
                "Govindgarh",
                "Gulabpura",
                "Hanumangarh",
                "Hindaun",
                "Indragarh",
                "Jahazpur",
                "Jaipur",
                "Jaisalmer",
                "Jaiselmer",
                "Jaitaran",
                "Jalore",
                "Jhalawar",
                "Jhalrapatan",
                "Jhunjhunun",
                "Jobner",
                "Jodhpur",
                "Kaithun",
                "Kaman",
                "Kankroli",
                "Kanor",
                "Kapasan",
                "Kaprain",
                "Karanpura",
                "Karauli",
                "Kekri",
                "Keshorai Patan",
                "Kesrisinghpur",
                "Khairthal",
                "Khandela",
                "Khanpur",
                "Kherli",
                "Kherliganj",
                "Kherwara Chhaoni",
                "Khetri",
                "Kiranipura",
                "Kishangarh",
                "Kishangarh Ranwal",
                "Kolvi Rajendrapura",
                "Kot Putli",
                "Kota",
                "Kuchaman",
                "Kuchera",
                "Kumbhalgarh",
                "Kumbhkot",
                "Kumher",
                "Kushalgarh",
                "Lachhmangarh",
                "Ladnun",
                "Lakheri",
                "Lalsot",
                "Losal",
                "Madanganj",
                "Mahu Kalan",
                "Mahwa",
                "Makrana",
                "Malpura",
                "Mandal",
                "Mandalgarh",
                "Mandawar",
                "Mandwa",
                "Mangrol",
                "Manohar Thana",
                "Manoharpur",
                "Marwar",
                "Merta",
                "Modak",
                "Mount Abu",
                "Mukandgarh",
                "Mundwa",
                "Nadbai",
                "Naenwa",
                "Nagar",
                "Nagaur",
                "Napasar",
                "Naraina",
                "Nasirabad",
                "Nathdwara",
                "Nawa",
                "Nawalgarh",
                "Neem Ka Thana",
                "Neemrana",
                "Newa Talai",
                "Nimaj",
                "Nimbahera",
                "Niwai",
                "Nohar",
                "Nokha",
                "One SGM",
                "Padampur",
                "Pali",
                "Partapur",
                "Parvatsar",
                "Pasoond",
                "Phalna",
                "Phalodi",
                "Phulera",
                "Pilani",
                "Pilibanga",
                "Pindwara",
                "Pipalia Kalan",
                "Pipar",
                "Pirawa",
                "Pokaran",
                "Pratapgarh",
                "Pushkar",
                "Raipur",
                "Raisinghnagar",
                "Rajakhera",
                "Rajaldesar",
                "Rajgarh",
                "Rajsamand",
                "Ramganj Mandi",
                "Ramgarh",
                "Rani",
                "Raniwara",
                "Ratan Nagar",
                "Ratangarh",
                "Rawatbhata",
                "Rawatsar",
                "Rikhabdev",
                "Ringas",
                "Sadri",
                "Sadulshahar",
                "Sagwara",
                "Salumbar",
                "Sambhar",
                "Samdari",
                "Sanchor",
                "Sangariya",
                "Sangod",
                "Sardarshahr",
                "Sarwar",
                "Satal Kheri",
                "Sawai Madhopur",
                "Sewan Kalan",
                "Shahpura",
                "Sheoganj",
                "Sikar",
                "Sirohi",
                "Siwana",
                "Sogariya",
                "Sojat",
                "Sojat Road",
                "Sri Madhopur",
                "Sriganganagar",
                "Sujangarh",
                "Suket",
                "Sumerpur",
                "Sunel",
                "Surajgarh",
                "Suratgarh",
                "Swaroopganj",
                "Takhatgarh",
                "Taranagar",
                "Three STR",
                "Tijara",
                "Toda Bhim",
                "Toda Raisingh",
                "Todra",
                "Tonk",
                "Udaipur",
                "Udpura",
                "Uniara",
                "Vanasthali",
                "Vidyavihar",
                "Vijainagar",
                "Viratnagar",
                "Wer"
            ],
            "Sikkim": [
                "Gangtok",
                "Gezing",
                "Jorethang",
                "Mangan",
                "Namchi",
                "Naya Bazar",
                "No City",
                "Rangpo",
                "Sikkim",
                "Singtam",
                "Upper Tadong"
            ],
            "Tamil Nadu": [
                "Abiramam",
                "Achampudur",
                "Acharapakkam",
                "Acharipallam",
                "Achipatti",
                "Adikaratti",
                "Adiramapattinam",
                "Aduturai",
                "Adyar",
                "Agaram",
                "Agasthiswaram",
                "Akkaraipettai",
                "Alagappapuram",
                "Alagapuri",
                "Alampalayam",
                "Alandur",
                "Alanganallur",
                "Alangayam",
                "Alangudi",
                "Alangulam",
                "Alanthurai",
                "Alapakkam",
                "Allapuram",
                "Alur",
                "Alwar Tirunagari",
                "Alwarkurichi",
                "Ambasamudram",
                "Ambur",
                "Ammainaickanur",
                "Ammaparikuppam",
                "Ammapettai",
                "Ammavarikuppam",
                "Ammur",
                "Anaimalai",
                "Anaiyur",
                "Anakaputhur",
                "Ananthapuram",
                "Andanappettai",
                "Andipalayam",
                "Andippatti",
                "Anjugramam",
                "Annamalainagar",
                "Annavasal",
                "Annur",
                "Anthiyur",
                "Appakudal",
                "Arachalur",
                "Arakandanallur",
                "Arakonam",
                "Aralvaimozhi",
                "Arani",
                "Arani Road",
                "Arantangi",
                "Arasiramani",
                "Aravakurichi",
                "Aravankadu",
                "Arcot",
                "Arimalam",
                "Ariyalur",
                "Ariyappampalayam",
                "Ariyur",
                "Arni",
                "Arulmigu Thirumuruganpundi",
                "Arumanai",
                "Arumbavur",
                "Arumuganeri",
                "Aruppukkottai",
                "Ashokapuram",
                "Athani",
                "Athanur",
                "Athimarapatti",
                "Athipattu",
                "Athur",
                "Attayyampatti",
                "Attur",
                "Auroville",
                "Avadattur",
                "Avadi",
                "Avalpundurai",
                "Avaniapuram",
                "Avinashi",
                "Ayakudi",
                "Ayanadaippu",
                "Aygudi",
                "Ayothiapattinam",
                "Ayyalur",
                "Ayyampalayam",
                "Ayyampettai",
                "Azhagiapandiapuram",
                "Balakrishnampatti",
                "Balakrishnapuram",
                "Balapallam",
                "Balasamudram",
                "Bargur",
                "Belur",
                "Berhatty",
                "Bhavani",
                "Bhawanisagar",
                "Bhuvanagiri",
                "Bikketti",
                "Bodinayakkanur",
                "Brahmana Periya Agraharam",
                "Buthapandi",
                "Buthipuram",
                "Chatrapatti",
                "Chembarambakkam",
                "Chengalpattu",
                "Chengam",
                "Chennai",
                "Chennasamudram",
                "Chennimalai",
                "Cheranmadevi",
                "Cheruvanki",
                "Chetpet",
                "Chettiarpatti",
                "Chettipalaiyam",
                "Chettipalayam Cantonment",
                "Chettithangal",
                "Cheyur",
                "Cheyyar",
                "Chidambaram",
                "Chinalapatti",
                "Chinna Anuppanadi",
                "Chinna Salem",
                "Chinnakkampalayam",
                "Chinnammanur",
                "Chinnampalaiyam",
                "Chinnasekkadu",
                "Chinnavedampatti",
                "Chitlapakkam",
                "Chittodu",
                "Cholapuram",
                "Coimbatore",
                "Coonoor",
                "Courtalam",
                "Cuddalore",
                "Dalavaipatti",
                "Darasuram",
                "Denkanikottai",
                "Desur",
                "Devadanapatti",
                "Devakkottai",
                "Devakottai",
                "Devanangurichi",
                "Devarshola",
                "Devasthanam",
                "Dhalavoipuram",
                "Dhali",
                "Dhaliyur",
                "Dharapadavedu",
                "Dharapuram",
                "Dharmapuri",
                "Dindigul",
                "Dusi",
                "Edaganasalai",
                "Edaikodu",
                "Edakalinadu",
                "Elathur",
                "Elayirampannai",
                "Elumalai",
                "Eral",
                "Eraniel",
                "Eriodu",
                "Erode",
                "Erumaipatti",
                "Eruvadi",
                "Ethapur",
                "Ettaiyapuram",
                "Ettimadai",
                "Ezhudesam",
                "Ganapathipuram",
                "Gandhi Nagar",
                "Gangaikondan",
                "Gangavalli",
                "Ganguvarpatti",
                "Gingi",
                "Gopalasamudram",
                "Gopichettipalaiyam",
                "Gudalur",
                "Gudiyattam",
                "Guduvanchery",
                "Gummidipoondi",
                "Hanumanthampatti",
                "Harur",
                "Harveypatti",
                "Highways",
                "Hosur",
                "Hubbathala",
                "Huligal",
                "Idappadi",
                "Idikarai",
                "Ilampillai",
                "Ilanji",
                "Iluppaiyurani",
                "Iluppur",
                "Inam Karur",
                "Injambakkam",
                "Irugur",
                "Jaffrabad",
                "Jagathala",
                "Jalakandapuram",
                "Jalladiampet",
                "Jambai",
                "Jayankondam",
                "Jolarpet",
                "Kadambur",
                "Kadathur",
                "Kadayal",
                "Kadayampatti",
                "Kadayanallur",
                "Kadiapatti",
                "Kalakkad",
                "Kalambur",
                "Kalapatti",
                "Kalappanaickenpatti",
                "Kalavai",
                "Kalinjur",
                "Kaliyakkavilai",
                "Kallakkurichi",
                "Kallakudi",
                "Kallidaikurichchi",
                "Kallukuttam",
                "Kallupatti",
                "Kalpakkam",
                "Kalugumalai",
                "Kamayagoundanpatti",
                "Kambainallur",
                "Kambam",
                "Kamuthi",
                "Kanadukathan",
                "Kanakkampalayam",
                "Kanam",
                "Kanchipuram",
                "Kandanur",
                "Kangayam",
                "Kangayampalayam",
                "Kangeyanallur",
                "Kaniyur",
                "Kanjikoil",
                "Kannadendal",
                "Kannamangalam",
                "Kannampalayam",
                "Kannankurichi",
                "Kannapalaiyam",
                "Kannivadi",
                "Kanyakumari",
                "Kappiyarai",
                "Karaikkudi",
                "Karamadai",
                "Karambakkam",
                "Karambakkudi",
                "Kariamangalam",
                "Kariapatti",
                "Karugampattur",
                "Karumandi Chellipalayam",
                "Karumathampatti",
                "Karumbakkam",
                "Karungal",
                "Karunguzhi",
                "Karuppur",
                "Karur",
                "Kasipalaiyam",
                "Kasipalayam G",
                "Kathirvedu",
                "Kathujuganapalli",
                "Katpadi",
                "Kattivakkam",
                "Kattumannarkoil",
                "Kattupakkam",
                "Kattuputhur",
                "Kaveripakkam",
                "Kaveripattinam",
                "Kavundampalaiyam",
                "Kavundampalayam",
                "Kayalpattinam",
                "Kayattar",
                "Kelamangalam",
                "Kelambakkam",
                "Kembainaickenpalayam",
                "Kethi",
                "Kilakarai",
                "Kilampadi",
                "Kilkulam",
                "Kilkunda",
                "Killiyur",
                "Killlai",
                "Kilpennathur",
                "Kilvelur",
                "Kinathukadavu",
                "Kiramangalam",
                "Kiranur",
                "Kiripatti",
                "Kizhapavur",
                "Kmarasamipatti",
                "Kochadai",
                "Kodaikanal",
                "Kodambakkam",
                "Kodavasal",
                "Kodumudi",
                "Kolachal",
                "Kolappalur",
                "Kolathupalayam",
                "Kolathur",
                "Kollankodu",
                "Kollankoil",
                "Komaralingam",
                "Komarapalayam",
                "Kombai",
                "Konakkarai",
                "Konavattam",
                "Kondalampatti",
                "Konganapuram",
                "Koradacheri",
                "Korampallam",
                "Kotagiri",
                "Kothinallur",
                "Kottaiyur",
                "Kottakuppam",
                "Kottaram",
                "Kottivakkam",
                "Kottur",
                "Kovilpatti",
                "Koyampattur",
                "Krishnagiri",
                "Krishnarayapuram",
                "Krishnasamudram",
                "Kuchanur",
                "Kuhalur",
                "Kulasekarappattinam",
                "Kulasekarapuram",
                "Kulithalai",
                "Kumarapalaiyam",
                "Kumarapalayam",
                "Kumarapuram",
                "Kumbakonam",
                "Kundrathur",
                "Kuniyamuthur",
                "Kunnathur",
                "Kunur",
                "Kuraikundu",
                "Kurichi",
                "Kurinjippadi",
                "Kurudampalaiyam",
                "Kurumbalur",
                "Kuthalam",
                "Kuthappar",
                "Kuttalam",
                "Kuttanallur",
                "Kuzhithurai",
                "Labbaikudikadu",
                "Lakkampatti",
                "Lalgudi",
                "Lalpet",
                "Llayangudi",
                "Madambakkam",
                "Madanur",
                "Madathukulam",
                "Madhavaram",
                "Madippakkam",
                "Madukkarai",
                "Madukkur",
                "Madurai",
                "Maduranthakam",
                "Maduravoyal",
                "Mahabalipuram",
                "Makkinanpatti",
                "Mallamuppampatti",
                "Mallankinaru",
                "Mallapuram",
                "Mallasamudram",
                "Mallur",
                "Mamallapuram",
                "Mamsapuram",
                "Manachanallur",
                "Manali",
                "Manalmedu",
                "Manalurpet",
                "Manamadurai",
                "Manapakkam",
                "Manapparai",
                "Manavalakurichi",
                "Mandaikadu",
                "Mandapam",
                "Mangadu",
                "Mangalam",
                "Mangalampet",
                "Manimutharu",
                "Mannargudi",
                "Mappilaiurani",
                "Maraimalai Nagar",
                "Marakkanam",
                "Maramangalathupatti",
                "Marandahalli",
                "Markayankottai",
                "Marudur",
                "Marungur",
                "Masinigudi",
                "Mathigiri",
                "Mattur",
                "Mayiladuthurai",
                "Mecheri",
                "Melacheval",
                "Melachokkanathapuram",
                "Melagaram",
                "Melamadai",
                "Melamaiyur",
                "Melanattam",
                "Melathiruppanthuruthi",
                "Melattur",
                "Melmananbedu",
                "Melpattampakkam",
                "Melur",
                "Melvisharam",
                "Mettupalayam",
                "Mettur",
                "Meyyanur",
                "Milavittan",
                "Minakshipuram",
                "Minambakkam",
                "Minjur",
                "Modakurichi",
                "Mohanur",
                "Mopperipalayam",
                "Mudalur",
                "Mudichur",
                "Mudukulathur",
                "Mukasipidariyur",
                "Mukkudal",
                "Mulagumudu",
                "Mulakaraipatti",
                "Mulanur",
                "Mullakkadu",
                "Muruganpalayam",
                "Musiri",
                "Muthupet",
                "Muthur",
                "Muttayyapuram",
                "Muttupet",
                "Muvarasampettai",
                "Myladi",
                "Mylapore",
                "Nadukkuthagai",
                "Naduvattam",
                "Nagapattinam",
                "Nagavakulam",
                "Nagercoil",
                "Nagojanahalli",
                "Nallampatti",
                "Nallur",
                "Namagiripettai",
                "Namakkal",
                "Nambiyur",
                "Nambutalai",
                "Nandambakkam",
                "Nandivaram",
                "Nangavalli",
                "Nangavaram",
                "Nanguneri",
                "Nanjikottai",
                "Nannilam",
                "Naranammalpuram",
                "Naranapuram",
                "Narasimhanaickenpalayam",
                "Narasingapuram",
                "Narasojipatti",
                "Naravarikuppam",
                "Nasiyanur",
                "Natham",
                "Nathampannai",
                "Natrampalli",
                "Nattam",
                "Nattapettai",
                "Nattarasankottai",
                "Navalpattu",
                "Nazarethpettai",
                "Nazerath",
                "Neikkarapatti",
                "Neiyyur",
                "Nellikkuppam",
                "Nelliyalam",
                "Nemili",
                "Nemilicheri",
                "Neripperichal",
                "Nerkunram",
                "Nerkuppai",
                "Nerunjipettai",
                "Neykkarappatti",
                "Neyveli",
                "Nidamangalam",
                "Nilagiri",
                "Nilakkottai",
                "Nilankarai",
                "Odaipatti",
                "Odaiyakulam",
                "Oddanchatram",
                "Odugathur",
                "Oggiyamduraipakkam",
                "Olagadam",
                "Omalur",
                "Ooty",
                "Orathanadu",
                "Othakadai",
                "Othakalmandapam",
                "Ottapparai",
                "Pacode",
                "Padaividu",
                "Padianallur",
                "Padirikuppam",
                "Padmanabhapuram",
                "Padririvedu",
                "Palaganangudy",
                "Palaimpatti",
                "Palakkodu",
                "Palamedu",
                "Palani",
                "Palani Chettipatti",
                "Palavakkam",
                "Palavansathu",
                "Palayakayal",
                "Palayam",
                "Palayamkottai",
                "Palladam",
                "Pallapalayam",
                "Pallapatti",
                "Pallattur",
                "Pallavaram",
                "Pallikaranai",
                "Pallikonda",
                "Pallipalaiyam",
                "Pallipalaiyam Agraharam",
                "Pallipattu",
                "Pammal",
                "Panagudi",
                "Panaimarathupatti",
                "Panapakkam",
                "Panboli",
                "Pandamangalam",
                "Pannaikadu",
                "Pannaipuram",
                "Pannuratti",
                "Panruti",
                "Papanasam",
                "Pappankurichi",
                "Papparapatti",
                "Pappireddipatti",
                "Paramakkudi",
                "Paramankurichi",
                "Paramathi",
                "Parangippettai",
                "Paravai",
                "Pasur",
                "Pathamadai",
                "Pattinam",
                "Pattiviranpatti",
                "Pattukkottai",
                "Pazhugal",
                "Pennadam",
                "Pennagaram",
                "Pennathur",
                "Peraiyur",
                "Peralam",
                "Perambalur",
                "Peranamallur",
                "Peravurani",
                "Periyakodiveri",
                "Periyakulam",
                "Periyanayakkanpalaiyam",
                "Periyanegamam",
                "Periyapatti",
                "Periyasemur",
                "Pernambut",
                "Perumagalur",
                "Perumandi",
                "Perumuchi",
                "Perundurai",
                "Perungalathur",
                "Perungudi",
                "Perungulam",
                "Perur",
                "Perur Chettipalaiyam",
                "Pethampalayam",
                "Pethanaickenpalayam",
                "Pillanallur",
                "Pirkankaranai",
                "Polichalur",
                "Pollachi",
                "Polur",
                "Ponmani",
                "Ponnamaravathi",
                "Ponnampatti",
                "Ponneri",
                "Porur",
                "Pothanur",
                "Pothatturpettai",
                "Pudukadai",
                "Pudukkottai Cantonment",
                "Pudukottai",
                "Pudupalaiyam Aghraharam",
                "Pudupalayam",
                "Pudupatti",
                "Pudupattinam",
                "Pudur",
                "Puduvayal",
                "Pulambadi",
                "Pulampatti",
                "Puliyampatti",
                "Puliyankudi",
                "Puliyur",
                "Pullampadi",
                "Puluvapatti",
                "Punamalli",
                "Punjai Puliyampatti",
                "Punjai Thottakurichi",
                "Punjaipugalur",
                "Puthalam",
                "Putteri",
                "Puvalur",
                "Puzhal",
                "Puzhithivakkam",
                "Rajapalayam",
                "Ramanathapuram",
                "Ramapuram",
                "Rameswaram",
                "Ranipet",
                "Rasipuram",
                "Rayagiri",
                "Rithapuram",
                "Rosalpatti",
                "Rudravathi",
                "Sadayankuppam",
                "Saint Thomas Mount",
                "Salangapalayam",
                "Salem",
                "Samalapuram",
                "Samathur",
                "Sambavar Vadagarai",
                "Sankaramanallur",
                "Sankarankoil",
                "Sankarapuram",
                "Sankari",
                "Sankarnagar",
                "Saravanampatti",
                "Sarcarsamakulam",
                "Sathiyavijayanagaram",
                "Sathuvachari",
                "Sathyamangalam",
                "Sattankulam",
                "Sattur",
                "Sayalgudi",
                "Sayapuram",
                "Seithur",
                "Sembakkam",
                "Semmipalayam",
                "Sennirkuppam",
                "Senthamangalam",
                "Sentharapatti",
                "Senur",
                "Sethiathoppu",
                "Sevilimedu",
                "Sevugampatti",
                "Shenbakkam",
                "Shencottai",
                "Shenkottai",
                "Sholavandan",
                "Sholinganallur",
                "Sholingur",
                "Sholur",
                "Sikkarayapuram",
                "Singampuneri",
                "Singanallur",
                "Singaperumalkoil",
                "Sirapalli",
                "Sirkali",
                "Sirugamani",
                "Sirumugai",
                "Sithayankottai",
                "Sithurajapuram",
                "Sivaganga",
                "Sivagiri",
                "Sivakasi",
                "Sivanthipuram",
                "Sivur",
                "Soranjeri",
                "South Kannanur",
                "South Kodikulam",
                "Srimushnam",
                "Sriperumpudur",
                "Sriramapuram",
                "Srirangam",
                "Srivaikuntam",
                "Srivilliputtur",
                "Suchindram",
                "Suliswaranpatti",
                "Sulur",
                "Sundarapandiam",
                "Sundarapandiapuram",
                "Surampatti",
                "Surandai",
                "Suriyampalayam",
                "Swamimalai",
                "Tambaram",
                "Taramangalam",
                "Tattayyangarpettai",
                "Tayilupatti",
                "Tenkasi",
                "Thadikombu",
                "Thakkolam",
                "Thalainayar",
                "Thalakudi",
                "Thamaraikulam",
                "Thammampatti",
                "Thanjavur",
                "Thanthoni",
                "Tharangambadi",
                "Thedavur",
                "Thenambakkam",
                "Thengampudur",
                "Theni",
                "Theni Allinagaram",
                "Thenkarai",
                "Thenthamaraikulam",
                "Thenthiruperai",
                "Thesur",
                "Thevaram",
                "Thevur",
                "Thiagadurgam",
                "Thiagarajar Colony",
                "Thingalnagar",
                "Thiruchirapalli",
                "Thirukarungudi",
                "Thirukazhukundram",
                "Thirumalayampalayam",
                "Thirumazhisai",
                "Thirunagar",
                "Thirunageswaram",
                "Thirunindravur",
                "Thirunirmalai",
                "Thiruparankundram",
                "Thiruparappu",
                "Thiruporur",
                "Thiruppanandal",
                "Thirupuvanam",
                "Thiruthangal",
                "Thiruthuraipundi",
                "Thiruvaivaru",
                "Thiruvalam",
                "Thiruvarur",
                "Thiruvattaru",
                "Thiruvenkatam",
                "Thiruvennainallur",
                "Thiruvithankodu",
                "Thisayanvilai",
                "Thittacheri",
                "Thondamuthur",
                "Thorapadi",
                "Thottipalayam",
                "Thottiyam",
                "Thudiyalur",
                "Thuthipattu",
                "Thuvakudi",
                "Timiri",
                "Tindivanam",
                "Tinnanur",
                "Tiruchchendur",
                "Tiruchengode",
                "Tirukkalukkundram",
                "Tirukkattuppalli",
                "Tirukkoyilur",
                "Tirumangalam",
                "Tirumullaivasal",
                "Tirumuruganpundi",
                "Tirunageswaram",
                "Tirunelveli",
                "Tirupathur",
                "Tirupattur",
                "Tiruppuvanam",
                "Tirupur",
                "Tirusulam",
                "Tiruttani",
                "Tiruvallur",
                "Tiruvannamalai",
                "Tiruverambur",
                "Tiruverkadu",
                "Tiruvethipuram",
                "Tiruvidaimarudur",
                "Tiruvottiyur",
                "Tittakudi",
                "TNPL Pugalur",
                "Tondi",
                "Turaiyur",
                "Tuticorin",
                "Udagamandalam",
                "Udagamandalam Valley",
                "Udankudi",
                "Udayarpalayam",
                "Udumalaipettai",
                "Udumalpet",
                "Ullur",
                "Ulundurpettai",
                "Unjalaur",
                "Unnamalaikadai",
                "Uppidamangalam",
                "Uppiliapuram",
                "Urachikkottai",
                "Urapakkam",
                "Usilampatti",
                "Uthangarai",
                "Uthayendram",
                "Uthiramerur",
                "Uthukkottai",
                "Uttamapalaiyam",
                "Uttukkuli",
                "Vadakarai Kizhpadugai",
                "Vadakkanandal",
                "Vadakku Valliyur",
                "Vadalur",
                "Vadamadurai",
                "Vadavalli",
                "Vadipatti",
                "Vadugapatti",
                "Vaithiswarankoil",
                "Valangaiman",
                "Valasaravakkam",
                "Valavanur",
                "Vallam",
                "Valparai",
                "Valvaithankoshtam",
                "Vanavasi",
                "Vandalur",
                "Vandavasi",
                "Vandiyur",
                "Vaniputhur",
                "Vaniyambadi",
                "Varadarajanpettai",
                "Varadharajapuram",
                "Vasudevanallur",
                "Vathirairuppu",
                "Vattalkundu",
                "Vazhapadi",
                "Vedapatti",
                "Vedaranniyam",
                "Vedasandur",
                "Velampalaiyam",
                "Velankanni",
                "Vellakinar",
                "Vellakoil",
                "Vellalapatti",
                "Vellalur",
                "Vellanur",
                "Vellimalai",
                "Vellore",
                "Vellottamparappu",
                "Velluru",
                "Vengampudur",
                "Vengathur",
                "Vengavasal",
                "Venghatur",
                "Venkarai",
                "Vennanthur",
                "Veppathur",
                "Verkilambi",
                "Vettaikaranpudur",
                "Vettavalam",
                "Vijayapuri",
                "Vikramasingapuram",
                "Vikravandi",
                "Vilangudi",
                "Vilankurichi",
                "Vilapakkam",
                "Vilathikulam",
                "Vilavur",
                "Villukuri",
                "Villupuram",
                "Viraganur",
                "Virakeralam",
                "Virakkalpudur",
                "Virapandi",
                "Virapandi Cantonment",
                "Virappanchatram",
                "Viravanallur",
                "Virudambattu",
                "Virudhachalam",
                "Virudhunagar",
                "Virupakshipuram",
                "Viswanatham",
                "Vriddhachalam",
                "Walajabad",
                "Walajapet",
                "Wellington",
                "Yercaud",
                "Zamin Uthukuli"
            ],
            "Telangana": [
                "Achampet",
                "Adilabad",
                "Armoor",
                "Asifabad",
                "Badepally",
                "Banswada",
                "Bellampalli",
                "Bhadrachalam",
                "Bhainsa",
                "Bhongir",
                "Bhupalpally",
                "Bodhan",
                "Bollaram",
                "Devarkonda",
                "Farooqnagar",
                "Gadwal",
                "Gajwel",
                "Ghatkesar",
                "Hyderabad",
                "Jagtial",
                "Jangaon",
                "Kagaznagar",
                "Kalwakurthy",
                "Kamareddy",
                "Karimnagar",
                "Khammam",
                "Kodada",
                "Koratla",
                "Kottagudem",
                "Kyathampalle",
                "Madhira",
                "Mahabubabad",
                "Mahbubnagar",
                "Mancherial",
                "Mandamarri",
                "Manuguru",
                "Medak",
                "Medchal",
                "Miryalaguda",
                "Nagar Karnul",
                "Nakrekal",
                "Nalgonda",
                "Narayanpet",
                "Narsampet",
                "Nirmal",
                "Nizamabad",
                "Palwancha",
                "Peddapalli",
                "Ramagundam",
                "Ranga Reddy district",
                "Sadasivpet",
                "Sangareddy",
                "Sarapaka",
                "Sathupalle",
                "Secunderabad",
                "Siddipet",
                "Singapur",
                "Sircilla",
                "Suryapet",
                "Tandur",
                "Vemulawada",
                "Vikarabad",
                "Wanaparthy",
                "Warangal",
                "Yellandu",
                "Zahirabad"
            ],
            "Tripura": [
                "Agartala",
                "Amarpur",
                "Ambassa",
                "Badharghat",
                "Belonia",
                "Dharmanagar",
                "Gakulnagar",
                "Gandhigram",
                "Indranagar",
                "Jogendranagar",
                "Kailasahar",
                "Kamalpur",
                "Kanchanpur",
                "Khowai",
                "Kumarghat",
                "Kunjaban",
                "Narsingarh",
                "Pratapgarh",
                "Ranir Bazar",
                "Sabrum",
                "Sonamura",
                "Teliamura",
                "Udaipur"
            ],
            "Uttar Pradesh": [
                "Achhalda",
                "Achhnera",
                "Adari",
                "Afzalgarh",
                "Agarwal Mandi",
                "Agra",
                "Agra Cantonment",
                "Ahraura",
                "Ailum",
                "Air Force Area",
                "Ajhuwa",
                "Akbarpur",
                "Alapur",
                "Aliganj",
                "Aligarh",
                "Allahabad",
                "Allahabad Cantonment",
                "Allahganj",
                "Amanpur",
                "Ambahta",
                "Amethi",
                "Amila",
                "Amilo",
                "Aminagar Sarai",
                "Aminagar Urf Bhurbaral",
                "Amraudha",
                "Amroha",
                "Anandnagar",
                "Anpara",
                "Antu",
                "Anupshahr",
                "Aonla",
                "Armapur Estate",
                "Ashokpuram",
                "Ashrafpur Kichhauchha",
                "Atarra",
                "Atasu",
                "Atrauli",
                "Atraulia",
                "Auraiya",
                "Aurangabad",
                "Aurangabad Bangar",
                "Auras",
                "Awagarh",
                "Ayodhya",
                "Azamgarh",
                "Azizpur",
                "Azmatgarh",
                "Babarpur Ajitmal",
                "Baberu",
                "Babina",
                "Babrala",
                "Babugarh",
                "Bachhiowan",
                "Bachhraon",
                "Bad",
                "Badaun",
                "Baghpat",
                "Bah",
                "Bahadurganj",
                "Baheri",
                "Bahjoi",
                "Bahraich",
                "Bahsuma",
                "Bahua",
                "Bajna",
                "Bakewar",
                "Bakiabad",
                "Baldeo",
                "Ballia",
                "Balrampur",
                "Banat",
                "Banda",
                "Bangarmau",
                "Banki",
                "Bansdih",
                "Bansgaon",
                "Bansi",
                "Barabanki",
                "Baragaon",
                "Baraut",
                "Bareilly",
                "Bareilly Cantonment",
                "Barhalganj",
                "Barhani",
                "Barhapur",
                "Barkhera",
                "Barsana",
                "Barva Sagar",
                "Barwar",
                "Basti",
                "Begumabad Budhana",
                "Behat",
                "Behta Hajipur",
                "Bela",
                "Belthara",
                "Beniganj",
                "Beswan",
                "Bewar",
                "Bhadarsa",
                "Bhadohi",
                "Bhagwantnagar",
                "Bharatganj",
                "Bhargain",
                "Bharthana",
                "Bharuhana",
                "Bharwari",
                "Bhatni Bazar",
                "Bhatpar Rani",
                "Bhawan Bahadurnagar",
                "Bhinga",
                "Bhojpur Dharampur",
                "Bhokarhedi",
                "Bhongaon",
                "Bhulepur",
                "Bidhuna",
                "Bighapur",
                "Bijnor",
                "Bijpur",
                "Bikapur",
                "Bilari",
                "Bilaspur",
                "Bilgram",
                "Bilhaur",
                "Bilram",
                "Bilrayaganj",
                "Bilsanda",
                "Bilsi",
                "Bindki",
                "Bisalpur",
                "Bisanda Buzurg",
                "Bisauli",
                "Bisharatganj",
                "Bisokhar",
                "Biswan",
                "Bithur",
                "Budaun",
                "Bugrasi",
                "Bulandshahar",
                "Burhana",
                "Chail",
                "Chak Imam Ali",
                "Chakeri",
                "Chakia",
                "Chandauli",
                "Chandausi",
                "Chandpur",
                "Charkhari",
                "Charthawal",
                "Chaumuhan",
                "Chhaprauli",
                "Chhara Rafatpur",
                "Chharprauli",
                "Chhata",
                "Chhatari",
                "Chhibramau",
                "Chhutmalpur",
                "Chilkana Sultanpur",
                "Chirgaon",
                "Chit Baragaon",
                "Chitrakut Dham",
                "Chopan",
                "Choubepur Kalan",
                "Chunar",
                "Churk Ghurma",
                "Colonelganj",
                "Dadri",
                "Dalmau",
                "Dankaur",
                "Dariyabad",
                "Dasna",
                "Dataganj",
                "Daurala",
                "Dayal Bagh",
                "Deoband",
                "Deoranian",
                "Deoria",
                "Dewa",
                "Dhampur",
                "Dhanauha",
                "Dhanauli",
                "Dhanaura",
                "Dharoti Khurd",
                "Dhauratanda",
                "Dhaurhra",
                "Dibai",
                "Dibiyapur",
                "Dildarnagar Fatehpur",
                "Do Ghat",
                "Dohrighat",
                "Dostpur",
                "Dudhinagar",
                "Dulhipur",
                "Dundwaraganj",
                "Ekdil",
                "Erich",
                "Etah",
                "Etawah",
                "Faizabad",
                "Faizabad Cantonment",
                "Faizganj",
                "Farah",
                "Faridnagar",
                "Faridpur",
                "Faridpur Cantonment",
                "Fariha",
                "Farrukhabad",
                "Fatehabad",
                "Fatehganj Pashchimi",
                "Fatehganj Purvi",
                "Fatehgarh",
                "Fatehpur",
                "Fatehpur Chaurasi",
                "Fatehpur Sikri",
                "Firozabad",
                "Gajraula",
                "Ganga Ghat",
                "Gangapur",
                "Gangoh",
                "Ganj Muradabad",
                "Garautha",
                "Garhi Pukhta",
                "Garhmukteshwar",
                "Gaura Barahaj",
                "Gauri Bazar",
                "Gausganj",
                "Gawan",
                "Ghatampur",
                "Ghaziabad",
                "Ghazipur",
                "Ghiror",
                "Ghorawal",
                "Ghosi",
                "Ghosia Bazar",
                "Ghughuli",
                "Gohand",
                "Gokul",
                "Gola Bazar",
                "Gola Gokarannath",
                "Gonda",
                "Gopamau",
                "Gopiganj",
                "Gorakhpur",
                "Gosainganj",
                "Govardhan",
                "Greater Noida",
                "Gulaothi",
                "Gulariya",
                "Gulariya Bhindara",
                "Gunnaur",
                "Gursahaiganj",
                "Gursarai",
                "Gyanpur",
                "Hafizpur",
                "Haidergarh",
                "Haldaur",
                "Hamirpur",
                "Handia",
                "Hapur",
                "Hardoi",
                "Harduaganj",
                "Hargaon",
                "Hariharpur",
                "Harraiya",
                "Hasanpur",
                "Hasayan",
                "Hastinapur",
                "Hata",
                "Hathras",
                "Hyderabad",
                "Ibrahimpur",
                "Iglas",
                "Ikauna",
                "Iltifatganj Bazar",
                "Indian Telephone Industry Mank",
                "Islamnagar",
                "Itaunja",
                "Itimadpur",
                "Jagner",
                "Jahanabad",
                "Jahangirabad",
                "Jahangirpur",
                "Jais",
                "Jaithara",
                "Jalalabad",
                "Jalali",
                "Jalalpur",
                "Jalaun",
                "Jalesar",
                "Jamshila",
                "Jangipur",
                "Jansath",
                "Jarwal",
                "Jasrana",
                "Jaswantnagar",
                "Jatari",
                "Jaunpur",
                "Jewar",
                "Jhalu",
                "Jhansi",
                "Jhansi Cantonment",
                "Jhansi Railway Settlement",
                "Jhinjhak",
                "Jhinjhana",
                "Jhusi",
                "Jhusi Kohna",
                "Jiyanpur",
                "Joya",
                "Jyoti Khuria",
                "Jyotiba Phule Nagar",
                "Kabrai",
                "Kachhauna Patseni",
                "Kachhla",
                "Kachhwa",
                "Kadaura",
                "Kadipur",
                "Kailashpur",
                "Kaimganj",
                "Kairana",
                "Kakgaina",
                "Kakod",
                "Kakori",
                "Kakrala",
                "Kalinagar",
                "Kalpi",
                "Kamalganj",
                "Kampil",
                "Kandhla",
                "Kandwa",
                "Kannauj",
                "Kanpur",
                "Kant",
                "Kanth",
                "Kaptanganj",
                "Karaon",
                "Karari",
                "Karhal",
                "Karnawal",
                "Kasganj",
                "Katariya",
                "Katghar Lalganj",
                "Kathera",
                "Katra",
                "Katra Medniganj",
                "Kauriaganj",
                "Kemri",
                "Kerakat",
                "Khadda",
                "Khaga",
                "Khailar",
                "Khair",
                "Khairabad",
                "Khairagarh",
                "Khalilabad",
                "Khamaria",
                "Khanpur",
                "Kharela",
                "Khargupur",
                "Khariya",
                "Kharkhoda",
                "Khatauli",
                "Khatauli Rural",
                "Khekra",
                "Kheri",
                "Kheta Sarai",
                "Khudaganj",
                "Khurja",
                "Khutar",
                "Kiraoli",
                "Kiratpur",
                "Kishanpur",
                "Kishni",
                "Kithaur",
                "Koiripur",
                "Konch",
                "Kopaganj",
                "Kora Jahanabad",
                "Korwa",
                "Kosi Kalan",
                "Kota",
                "Kotra",
                "Kotwa",
                "Kulpahar",
                "Kunda",
                "Kundarki",
                "Kunwargaon",
                "Kurara",
                "Kurawali",
                "Kursath",
                "Kurthi Jafarpur",
                "Kushinagar",
                "Kusmara",
                "Laharpur",
                "Lakhimpur",
                "Lakhna",
                "Lalganj",
                "Lalitpur",
                "Lar",
                "Lawar",
                "Ledwa Mahuwa",
                "Lohta",
                "Loni",
                "Lucknow",
                "Machhlishahr",
                "Madhoganj",
                "Madhogarh",
                "Maghar",
                "Mahaban",
                "Maharajganj",
                "Mahmudabad",
                "Mahoba",
                "Maholi",
                "Mahona",
                "Mahroni",
                "Mailani",
                "Mainpuri",
                "Majhara Pipar Ehatmali",
                "Majhauli Raj",
                "Malihabad",
                "Mallanwam",
                "Mandawar",
                "Manikpur",
                "Maniyar",
                "Manjhanpur",
                "Mankapur",
                "Marehra",
                "Mariahu",
                "Maruadih",
                "Maswasi",
                "Mataundh",
                "Mathu",
                "Mathura",
                "Mathura Cantonment",
                "Mau",
                "Mau Aima",
                "Maudaha",
                "Mauranipur",
                "Maurawan",
                "Mawana",
                "Meerut",
                "Mehnagar",
                "Mehndawal",
                "Mendu",
                "Milak",
                "Miranpur",
                "Mirat",
                "Mirat Cantonment",
                "Mirganj",
                "Mirzapur",
                "Misrikh",
                "Modinagar",
                "Mogra Badshahpur",
                "Mohan",
                "Mohanpur",
                "Mohiuddinpur",
                "Moradabad",
                "Moth",
                "Mubarakpur",
                "Mughal Sarai",
                "Mughal Sarai Railway Settlemen",
                "Muhammadabad",
                "Muhammadi",
                "Mukrampur Khema",
                "Mundia",
                "Mundora",
                "Muradnagar",
                "Mursan",
                "Musafirkhana",
                "Muzaffarnagar",
                "Nadigaon",
                "Nagina",
                "Nagram",
                "Nai Bazar",
                "Nainana Jat",
                "Najibabad",
                "Nakur",
                "Nanaunta",
                "Nandgaon",
                "Nanpara",
                "Naraini",
                "Narauli",
                "Naraura",
                "Naugawan Sadat",
                "Nautanwa",
                "Nawabganj",
                "Nichlaul",
                "Nidhauli Kalan",
                "Nihtaur",
                "Nindaura",
                "Niwari",
                "Nizamabad",
                "Noida",
                "Northern Railway Colony",
                "Nurpur",
                "Nyoria Husenpur",
                "Nyotini",
                "Obra",
                "Oel Dhakwa",
                "Orai",
                "Oran",
                "Ordinance Factory Muradnagar",
                "Pachperwa",
                "Padrauna",
                "Pahasu",
                "Paintepur",
                "Pali",
                "Palia Kalan",
                "Parasi",
                "Parichha",
                "Parichhatgarh",
                "Parsadepur",
                "Patala",
                "Patiyali",
                "Patti",
                "Pawayan",
                "Phalauda",
                "Phaphund",
                "Phulpur",
                "Phulwaria",
                "Pihani",
                "Pilibhit",
                "Pilkana",
                "Pilkhuwa",
                "Pinahat",
                "Pipalsana Chaudhari",
                "Pipiganj",
                "Pipraich",
                "Pipri",
                "Pratapgarh",
                "Pukhrayan",
                "Puranpur",
                "Purdil Nagar",
                "Purqazi",
                "Purwa",
                "Qasimpur",
                "Rabupura",
                "Radha Kund",
                "Rae Bareilly",
                "Raja Ka Rampur",
                "Rajapur",
                "Ramkola",
                "Ramnagar",
                "Rampur",
                "Rampur Bhawanipur",
                "Rampur Karkhana",
                "Rampur Maniharan",
                "Rampura",
                "Ranipur",
                "Rashidpur Garhi",
                "Rasra",
                "Rasulabad",
                "Rath",
                "Raya",
                "Renukut",
                "Reoti",
                "Richha",
                "Risia Bazar",
                "Rithora",
                "Robertsganj",
                "Roza",
                "Rudarpur",
                "Rudauli",
                "Rudayan",
                "Rura",
                "Rustamnagar Sahaspur",
                "Sabatwar",
                "Sadabad",
                "Sadat",
                "Safipur",
                "Sahanpur",
                "Saharanpur",
                "Sahaspur",
                "Sahaswan",
                "Sahawar",
                "Sahibabad",
                "Sahjanwa",
                "Sahpau",
                "Saidpur",
                "Sainthal",
                "Saiyadraja",
                "Sakhanu",
                "Sakit",
                "Salarpur Khadar",
                "Salimpur",
                "Salon",
                "Sambhal",
                "Sambhawali",
                "Samdhan",
                "Samthar",
                "Sandi",
                "Sandila",
                "Sarai akil",
                "Sarai Mir",
                "Sarauli",
                "Sardhana",
                "Sarila",
                "Sarsawan",
                "Sasni",
                "Satrikh",
                "Saunkh",
                "Saurikh",
                "Seohara",
                "Sewal Khas",
                "Sewarhi",
                "Shahabad",
                "Shahganj",
                "Shahi",
                "Shahjahanpur",
                "Shahjahanpur Cantonment",
                "Shahpur",
                "Shamli",
                "Shamsabad",
                "Shankargarh",
                "Shergarh",
                "Sherkot",
                "Shikarpur",
                "Shikohabad",
                "Shisgarh",
                "Shivdaspur",
                "Shivli",
                "Shivrajpur",
                "Shohratgarh",
                "Siddhanur",
                "Siddharthnagar",
                "Sidhauli",
                "Sidhpura",
                "Sikandarabad",
                "Sikandarpur",
                "Sikandra",
                "Sikandra Rao",
                "Singahi Bhiraura",
                "Sirathu",
                "Sirsa",
                "Sirsaganj",
                "Sirsi",
                "Sisauli",
                "Siswa Bazar",
                "Sitapur",
                "Siyana",
                "Som",
                "Sonbhadra",
                "Soron",
                "Suar",
                "Sukhmalpur Nizamabad",
                "Sultanpur",
                "Sumerpur",
                "Suriyawan",
                "Swamibagh",
                "Tajpur",
                "Talbahat",
                "Talgram",
                "Tambaur",
                "Tanda",
                "Tatarpur Lallu",
                "Tetribazar",
                "Thakurdwara",
                "Thana Bhawan",
                "Thiriya Nizamat Khan",
                "Tikaitnagar",
                "Tikri",
                "Tilhar",
                "Tindwari",
                "Tirwaganj",
                "Titron",
                "Tori Fatehpur",
                "Tulsipur",
                "Tundla",
                "Tundla Kham",
                "Tundla Railway Colony",
                "Ugu",
                "Ujhani",
                "Ujhari",
                "Umri",
                "Umri Kalan",
                "Un",
                "Unchahar",
                "Unnao",
                "Usaihat",
                "Usawan",
                "Utraula",
                "Varanasi",
                "Varanasi Cantonment",
                "Vijaigarh",
                "Vrindavan",
                "Wazirganj",
                "Zafarabad",
                "Zaidpur",
                "Zamania"
            ],
            "Uttarakhand": [
                "Almora",
                "Almora Cantonment",
                "Badrinathpuri",
                "Bageshwar",
                "Bah Bazar",
                "Banbasa",
                "Bandia",
                "Barkot",
                "Bazpur",
                "Bhim Tal",
                "Bhowali",
                "Chakrata",
                "Chamba",
                "Chamoli and Gopeshwar",
                "Champawat",
                "Clement Town",
                "Dehra Dun Cantonment",
                "Dehradun",
                "Dehrakhas",
                "Devaprayag",
                "Dhaluwala",
                "Dhandera",
                "Dharchula",
                "Dharchula Dehat",
                "Didihat",
                "Dineshpur",
                "Doiwala",
                "Dugadda",
                "Dwarahat",
                "Gadarpur",
                "Gangotri",
                "Gauchar",
                "Haldwani",
                "Haridwar",
                "Herbertpur",
                "Jaspur",
                "Jhabrera",
                "Joshimath",
                "Kachnal Gosain",
                "Kaladungi",
                "Kalagarh",
                "Karnaprayang",
                "Kashipur",
                "Kashirampur",
                "Kausani",
                "Kedarnath",
                "Kelakhera",
                "Khatima",
                "Kichha",
                "Kirtinagar",
                "Kotdwara",
                "Laksar",
                "Lalkuan",
                "Landaura",
                "Landhaura Cantonment",
                "Lensdaun",
                "Logahat",
                "Mahua Dabra Haripura",
                "Mahua Kheraganj",
                "Manglaur",
                "Masuri",
                "Mohanpur Mohammadpur",
                "Muni Ki Reti",
                "Nagla",
                "Nainital",
                "Nainital Cantonment",
                "Nandaprayang",
                "Narendranagar",
                "Pauri",
                "Pithoragarh",
                "Pratitnagar",
                "Raipur",
                "Raiwala",
                "Ramnagar",
                "Ranikhet",
                "Ranipur",
                "Rishikesh",
                "Rishikesh Cantonment",
                "Roorkee",
                "Rudraprayag",
                "Rudrapur",
                "Rurki",
                "Rurki Cantonment",
                "Shaktigarh",
                "Sitarganj",
                "Srinagar",
                "Sultanpur",
                "Tanakpur",
                "Tehri",
                "Udham Singh Nagar",
                "Uttarkashi",
                "Vikasnagar",
                "Virbhadra"
            ],
            "West Bengal": [
                "24 Parganas (n)",
                "24 Parganas (s)",
                "Adra",
                "Ahmadpur",
                "Aiho",
                "Aistala",
                "Alipur Duar",
                "Alipur Duar Railway Junction",
                "Alpur",
                "Amalhara",
                "Amkula",
                "Amlagora",
                "Amodghata",
                "Amtala",
                "Andul",
                "Anksa",
                "Ankurhati",
                "Anup Nagar",
                "Arambagh",
                "Argari",
                "Arsha",
                "Asansol",
                "Ashoknagar Kalyangarh",
                "Aurangabad",
                "Bablari Dewanganj",
                "Badhagachhi",
                "Baduria",
                "Baghdogra",
                "Bagnan",
                "Bagra",
                "Bagula",
                "Baharampur",
                "Bahirgram",
                "Bahula",
                "Baidyabati",
                "Bairatisal",
                "Baj Baj",
                "Bakreswar",
                "Balaram Pota",
                "Balarampur",
                "Bali Chak",
                "Ballavpur",
                "Bally",
                "Balurghat",
                "Bamunari",
                "Banarhat Tea Garden",
                "Bandel",
                "Bangaon",
                "Bankra",
                "Bankura",
                "Bansbaria",
                "Banshra",
                "Banupur",
                "Bara Bamonia",
                "Barakpur",
                "Barakpur Cantonment",
                "Baranagar",
                "Barasat",
                "Barddhaman",
                "Barijhati",
                "Barjora",
                "Barrackpore",
                "Baruihuda",
                "Baruipur",
                "Barunda",
                "Basirhat",
                "Baska",
                "Begampur",
                "Beldanga",
                "Beldubi",
                "Belebathan",
                "Beliator",
                "Bhadreswar",
                "Bhandardaha",
                "Bhangar Raghunathpur",
                "Bhangri Pratham Khanda",
                "Bhanowara",
                "Bhatpara",
                "Bholar Dabri",
                "Bidhannagar",
                "Bidyadharpur",
                "Biki Hakola",
                "Bilandapur",
                "Bilpahari",
                "Bipra Noapara",
                "Birlapur",
                "Birnagar",
                "Bisarpara",
                "Bishnupur",
                "Bolpur",
                "Bongaon",
                "Bowali",
                "Burdwan",
                "Canning",
                "Cart Road",
                "Chachanda",
                "Chak Bankola",
                "Chak Enayetnagar",
                "Chak Kashipur",
                "Chakalampur",
                "Chakbansberia",
                "Chakdaha",
                "Chakpara",
                "Champahati",
                "Champdani",
                "Chamrail",
                "Chandannagar",
                "Chandpur",
                "Chandrakona",
                "Chapari",
                "Chapui",
                "Char Brahmanagar",
                "Char Maijdia",
                "Charka",
                "Chata Kalikapur",
                "Chauhati",
                "Checha Khata",
                "Chelad",
                "Chhora",
                "Chikrand",
                "Chittaranjan",
                "Contai",
                "Cooch Behar",
                "Dainhat",
                "Dakshin Baguan",
                "Dakshin Jhapardaha",
                "Dakshin Rajyadharpur",
                "Dakshin Raypur",
                "Dalkola",
                "Dalurband",
                "Darap Pur",
                "Darjiling",
                "Daulatpur",
                "Debipur",
                "Defahat",
                "Deora",
                "Deulia",
                "Dhakuria",
                "Dhandadihi",
                "Dhanyakuria",
                "Dharmapur",
                "Dhatri Gram",
                "Dhuilya",
                "Dhulagari",
                "Dhulian",
                "Dhupgari",
                "Dhusaripara",
                "Diamond Harbour",
                "Digha",
                "Dignala",
                "Dinhata",
                "Dubrajpur",
                "Dumjor",
                "Durgapur",
                "Durllabhganj",
                "Egra",
                "Eksara",
                "Falakata",
                "Farakka",
                "Fatellapur",
                "Fort Gloster",
                "Gabberia",
                "Gadigachha",
                "Gairkata",
                "Gangarampur",
                "Garalgachha",
                "Garbeta Amlagora",
                "Garhbeta",
                "Garshyamnagar",
                "Garui",
                "Garulia",
                "Gayespur",
                "Ghatal",
                "Ghorsala",
                "Goaljan",
                "Goasafat",
                "Gobardanga",
                "Gobindapur",
                "Gopalpur",
                "Gopinathpur",
                "Gora Bazar",
                "Guma",
                "Gurdaha",
                "Guriahati",
                "Guskhara",
                "Habra",
                "Haldia",
                "Haldibari",
                "Halisahar",
                "Haora",
                "Harharia Chak",
                "Harindanga",
                "Haringhata",
                "Haripur",
                "Harishpur",
                "Hatgachha",
                "Hatsimla",
                "Hijuli",
                "Hindustan Cables Town",
                "Hooghly",
                "Howrah",
                "Hugli-Chunchura",
                "Humaipur",
                "Ichha Pur Defence Estate",
                "Ingraj Bazar",
                "Islampur",
                "Jafarpur",
                "Jagadanandapur",
                "Jagdishpur",
                "Jagtaj",
                "Jala Kendua",
                "Jaldhaka",
                "Jalkhura",
                "Jalpaiguri",
                "Jamuria",
                "Jangipur",
                "Jaygaon",
                "Jaynagar-Majilpur",
                "Jemari",
                "Jemari Township",
                "Jetia",
                "Jhalida",
                "Jhargram",
                "Jhorhat",
                "Jiaganj-Azimganj",
                "Joka",
                "Jot Kamal",
                "Kachu Pukur",
                "Kajora",
                "Kakdihi",
                "Kakdwip",
                "Kalaikunda",
                "Kalara",
                "Kalimpong",
                "Kaliyaganj",
                "Kalna",
                "Kalyani",
                "Kamarhati",
                "Kanaipur",
                "Kanchrapara",
                "Kandi",
                "Kanki",
                "Kankuria",
                "Kantlia",
                "Kanyanagar",
                "Karimpur",
                "Karsiyang",
                "Kasba",
                "Kasimbazar",
                "Katwa",
                "Kaugachhi",
                "Kenda",
                "Kendra Khottamdi",
                "Kendua",
                "Kesabpur",
                "Khagrabari",
                "Khalia",
                "Khalor",
                "Khandra",
                "Khantora",
                "Kharagpur",
                "Kharagpur Railway Settlement",
                "Kharar",
                "Khardaha",
                "Khari Mala Khagrabari",
                "Kharsarai",
                "Khatra",
                "Khodarampur",
                "Kodalia",
                "Kolaghat",
                "Kolaghat Thermal Power Project",
                "Kolkata",
                "Konardihi",
                "Konnogar",
                "Krishnanagar",
                "Krishnapur",
                "Kshidirpur",
                "Kshirpai",
                "Kulihanda",
                "Kulti",
                "Kunustara",
                "Kuperskem",
                "Madanpur",
                "Madhusudanpur",
                "Madhyamgram",
                "Maheshtala",
                "Mahiari",
                "Mahikpur",
                "Mahira",
                "Mahishadal",
                "Mainaguri",
                "Makardaha",
                "Mal",
                "Malda",
                "Mandarbani",
                "Mansinhapur",
                "Masila",
                "Maslandapur",
                "Mathabhanga",
                "Mekliganj",
                "Memari",
                "Midnapur",
                "Mirik",
                "Monoharpur",
                "Mrigala",
                "Muragachha",
                "Murgathaul",
                "Murshidabad",
                "Nabadhai Dutta Pukur",
                "Nabagram",
                "Nabgram",
                "Nachhratpur Katabari",
                "Nadia",
                "Naihati",
                "Nalhati",
                "Nasra",
                "Natibpur",
                "Naupala",
                "Navadwip",
                "Nebadhai Duttapukur",
                "New Barrackpore",
                "Ni Barakpur",
                "Nibra",
                "Noapara",
                "Nokpul",
                "North Barakpur",
                "Odlabari",
                "Old Maldah",
                "Ondal",
                "Pairagachha",
                "Palashban",
                "Panchla",
                "Panchpara",
                "Pandua",
                "Pangachhiya",
                "Paniara",
                "Panihati",
                "Panuhat",
                "Par Beliya",
                "Parashkol",
                "Parasia",
                "Parbbatipur",
                "Parui",
                "Paschim Jitpur",
                "Paschim Punro Para",
                "Patrasaer",
                "Pattabong Tea Garden",
                "Patuli",
                "Patulia",
                "Phulia",
                "Podara",
                "Port Blair",
                "Prayagpur",
                "Pujali",
                "Purba Medinipur",
                "Purba Tajpur",
                "Purulia",
                "Raghudebbati",
                "Raghudebpur",
                "Raghunathchak",
                "Raghunathpur",
                "Raghunathpur-Dankuni",
                "Raghunathpur-Magra",
                "Raigachhi",
                "Raiganj",
                "Raipur",
                "Rajarhat Gopalpur",
                "Rajpur",
                "Ramchandrapur",
                "Ramjibanpur",
                "Ramnagar",
                "Rampur Hat",
                "Ranaghat",
                "Raniganj",
                "Ratibati",
                "Raypur",
                "Rishra",
                "Rishra Cantonment",
                "Ruiya",
                "Sahajadpur",
                "Sahapur",
                "Sainthia",
                "Salap",
                "Sankarpur",
                "Sankrail",
                "Santoshpur",
                "Saontaidih",
                "Sarenga",
                "Sarpi",
                "Satigachha",
                "Serpur",
                "Shankhanagar",
                "Shantipur",
                "Shrirampur",
                "Siduli",
                "Siliguri",
                "Simla",
                "Singur",
                "Sirsha",
                "Siuri",
                "Sobhaganj",
                "Sodpur",
                "Sonamukhi",
                "Sonatikiri",
                "Srikantabati",
                "Srirampur",
                "Sukdal",
                "Taherpur",
                "Taki",
                "Talbandha",
                "Tamluk",
                "Tarakeswar",
                "Tentulberia",
                "Tentulkuli",
                "Thermal Power Project",
                "Tinsukia",
                "Titagarh",
                "Tufanganj",
                "Ukhra",
                "Ula",
                "Ulubaria",
                "Uttar Durgapur",
                "Uttar Goara",
                "Uttar Kalas",
                "Uttar Kamakhyaguri",
                "Uttar Latabari",
                "Uttar Mahammadpur",
                "Uttar Pirpur",
                "Uttar Raypur",
                "Uttarpara-Kotrung"
            ]
        }
    },
    {
        "id": 102,
        "name": "Indonesia",
        "iso3": "IDN",
        "iso2": "ID",
        "phone_code": "62",
        "capital": "Jakarta",
        "currency": "IDR",
        "states": {
            "Aceh": [
                "Banda Aceh",
                "Bireun",
                "Langsa",
                "Lhokseumawe",
                "Meulaboh"
            ],
            "Bali": [
                "Denpasar",
                "Karangasem",
                "Klungkung",
                "Kuta",
                "Negara",
                "Singaraja",
                "Tabanan",
                "Ubud"
            ],
            "Bangka-Belitung": [
                "Manggar",
                "Mentok",
                "Pangkal Pinang",
                "Sungai Liat",
                "Tanjung Pandan",
                "Toboali-Rias"
            ],
            "Banten": [
                "Cikupa",
                "Cilegon",
                "Ciputat",
                "Curug",
                "Kresek",
                "Labuhan",
                "Pandegelang",
                "Pondok Aren",
                "Rangkasbitung",
                "Serang",
                "Serpong",
                "Tangerang",
                "Teluknaga"
            ],
            "Bengkulu": [
                "Bengkulu",
                "Curup"
            ],
            "Gandaria": [
                "Gandaria"
            ],
            "Gorontalo": [
                "Gorontalo"
            ],
            "Jakarta": [
                "Cengkareng",
                "Jakarta"
            ],
            "Jambi": [
                "Jambi",
                "Kualatungka",
                "Simpang",
                "Sungaipenuh"
            ],
            "Jawa Barat": [
                "Jambi",
                "Kualatungka",
                "Simpang",
                "Sungaipenuh"
            ],
            "Jawa Tengah": [
                "Jambi",
                "Kualatungka",
                "Simpang",
                "Sungaipenuh"
            ],
            "Jawa Timur": [
                "Jambi",
                "Kualatungka",
                "Simpang",
                "Sungaipenuh"
            ],
            "Kalimantan Barat": [
                "Jambi",
                "Kualatungka",
                "Simpang",
                "Sungaipenuh"
            ],
            "Kalimantan Selatan": [
                "Jambi",
                "Kualatungka",
                "Simpang",
                "Sungaipenuh"
            ],
            "Kalimantan Tengah": [
                "Jambi",
                "Kualatungka",
                "Simpang",
                "Sungaipenuh"
            ],
            "Kalimantan Timur": [
                "Jambi",
                "Kualatungka",
                "Simpang",
                "Sungaipenuh"
            ],
            "Kendal": [
                "Kendal"
            ],
            "Lampung": [
                "Bandar Lampung",
                "Kota Bumi",
                "Metro",
                "Pringsewu",
                "Terbanggi Besar"
            ],
            "Maluku": [
                "Amahai",
                "Ambon",
                "Tual"
            ],
            "Maluku Utara": [
                "Amahai",
                "Ambon",
                "Tual"
            ],
            "Nusa Tenggara Barat": [
                "Amahai",
                "Ambon",
                "Tual"
            ],
            "Nusa Tenggara Timur": [
                "Amahai",
                "Ambon",
                "Tual"
            ],
            "Papua": [
                "Aberpura",
                "Biak",
                "Jaya Pura",
                "Manokwari",
                "Merauke",
                "Sorong"
            ],
            "Riau": [
                "Balaipungut",
                "Bengkalis",
                "Dumai",
                "Duri",
                "Pekan Baru",
                "Selatpanjang",
                "Tanjung Balai-Meral",
                "Tembilahan"
            ],
            "Riau Kepulauan": [
                "Balaipungut",
                "Bengkalis",
                "Dumai",
                "Duri",
                "Pekan Baru",
                "Selatpanjang",
                "Tanjung Balai-Meral",
                "Tembilahan"
            ],
            "Solo": [
                "Solo"
            ],
            "Sulawesi Selatan": [
                "Solo"
            ],
            "Sulawesi Tengah": [
                "Solo"
            ],
            "Sulawesi Tenggara": [
                "Solo"
            ],
            "Sulawesi Utara": [
                "Solo"
            ],
            "Sumatera Barat": [
                "Solo"
            ],
            "Sumatera Selatan": [
                "Solo"
            ],
            "Sumatera Utara": [
                "Solo"
            ],
            "Yogyakarta": [
                "Bambanglipuro",
                "Banguntapan",
                "Bantul",
                "Depok",
                "Gamping",
                "Godean",
                "Jetis",
                "Kasihan",
                "Ngaglik",
                "Pandak",
                "Pundong",
                "Sewon",
                "Seyegan",
                "Sleman",
                "Srandakan",
                "Wonosari",
                "Yogyakarta"
            ]
        }
    },
    {
        "id": 103,
        "name": "Iran",
        "iso3": "IRN",
        "iso2": "IR",
        "phone_code": "98",
        "capital": "Tehran",
        "currency": "IRR",
        "states": {
            "Ardabil": [
                "Ardabil",
                "Garmi",
                "Khalkhal",
                "Meshkinshahr",
                "Parsabad"
            ],
            "Azarbayjan-e Bakhtari": [
                "Ardabil",
                "Garmi",
                "Khalkhal",
                "Meshkinshahr",
                "Parsabad"
            ],
            "Azarbayjan-e Khavari": [
                "Ardabil",
                "Garmi",
                "Khalkhal",
                "Meshkinshahr",
                "Parsabad"
            ],
            "Bushehr": [
                "Bandar-e Gonaveh",
                "Borazjan",
                "Bushehr",
                "Dashti",
                "Dir",
                "Khormuj",
                "Kongan",
                "Tangestan"
            ],
            "Chahar Mahal-e Bakhtiari": [
                "Bandar-e Gonaveh",
                "Borazjan",
                "Bushehr",
                "Dashti",
                "Dir",
                "Khormuj",
                "Kongan",
                "Tangestan"
            ],
            "Esfahan": [
                "Ardistan",
                "Dorchehpiyaz",
                "Dowlatabad",
                "Esfahan",
                "Falavarjan",
                "Faridan",
                "Fereydunshahr",
                "Fuladshahr",
                "Golara",
                "Golpayegan",
                "Kashan",
                "Kelishad",
                "Khomeynishahr",
                "Khonsar",
                "Khuresgan",
                "Mobarakeh",
                "Najafabad",
                "Natnaz",
                "Na\\'in",
                "Qahdarijan",
                "Rehnan",
                "Semirom",
                "Shahinshahr",
                "Shahreza",
                "Zarinshahr"
            ],
            "Fars": [
                "Abadeh",
                "Akbarabad",
                "Darab",
                "Eqlid",
                "Estehban",
                "Fasa",
                "Firuzabad",
                "Gerash",
                "Jahrom",
                "Kazerun",
                "Lar",
                "Marv Dasht",
                "Neyriz",
                "Nurabad",
                "Qa\\'emiyeh",
                "Sepidan",
                "Shiraz"
            ],
            "Gilan": [
                "Astaneh-ye Ashrafiyeh",
                "Astara",
                "Bandar-e Anzali",
                "Faman",
                "Hashtpar",
                "Lahijan",
                "Langarud",
                "Rasht",
                "Rudbar",
                "Rudsar",
                "Sawma\\'eh Sara"
            ],
            "Golestan": [
                "Aq Qal\\'eh",
                "Azad Shahr",
                "Bandar-e Torkaman",
                "Gonbad-e Qabus",
                "Gorgan"
            ],
            "Hamadan": [
                "Asadabad",
                "Bahar",
                "Hamadan",
                "Malayer",
                "Nahavand",
                "Tuysarkan"
            ],
            "Hormozgan": [
                "Bandar Abbas",
                "Bandar-e Lengeh",
                "Bandar-e \\'Abbas",
                "Gheshm",
                "Jask",
                "Kish",
                "Kish Island",
                "Minab"
            ],
            "Ilam": [
                "Abdanan",
                "Darrehshahr",
                "Dehloran",
                "Ilam",
                "Ivan",
                "Mehran"
            ],
            "Kerman": [
                "Baft",
                "Bam",
                "Bardsir",
                "Jiroft",
                "Kahnuj",
                "Kerman",
                "Rafsanjan",
                "Ravar",
                "Shahr-e Babak",
                "Sirjan",
                "Zarand"
            ],
            "Kermanshah": [
                "Eslamabad",
                "Gilan-e Garb",
                "Harsin",
                "Javanrud",
                "Kangavar",
                "Kermanshah",
                "Paveh",
                "Sahneh",
                "Sar-e-Pol-e-Zohab",
                "Sonqor"
            ],
            "Khorasan": [
                "Birjand",
                "Bojnurd",
                "Chenaran",
                "Darreh Gaz",
                "Esfarayen",
                "Fariman",
                "Ferdus",
                "Gha\\'nat",
                "Gonabad",
                "Kashmar",
                "Mashad",
                "Mashhad",
                "Neyshabur",
                "Qayen",
                "Quchan",
                "Sabzevar",
                "Sarakhs",
                "Shirvan",
                "Tabas",
                "Tayyebat",
                "Torbat-e Heydariyeh",
                "Torbat-e Jam"
            ],
            "Khuzestan": [
                "Abadan",
                "Agha Jari",
                "Ahvaz",
                "Ahwaz",
                "Andimeshk",
                "Bandar-e Emam Khomeyni",
                "Bandar-e Mahshahr",
                "Behbahan",
                "Dezful",
                "Ezeh",
                "Hendijan",
                "Khorramshahr",
                "Masjed-e Soleyman",
                "Omidiyeh",
                "Ramhormoz",
                "Ramshir",
                "Shadegan",
                "Shush",
                "Shushtar",
                "Susangerd"
            ],
            "Kohgiluyeh-e Boyerahmad": [
                "Abadan",
                "Agha Jari",
                "Ahvaz",
                "Ahwaz",
                "Andimeshk",
                "Bandar-e Emam Khomeyni",
                "Bandar-e Mahshahr",
                "Behbahan",
                "Dezful",
                "Ezeh",
                "Hendijan",
                "Khorramshahr",
                "Masjed-e Soleyman",
                "Omidiyeh",
                "Ramhormoz",
                "Ramshir",
                "Shadegan",
                "Shush",
                "Shushtar",
                "Susangerd"
            ],
            "Kordestan": [
                "Baneh",
                "Bijar",
                "Kamyaran",
                "Marivan",
                "Qorveh",
                "Sanandaj",
                "Saqqez"
            ],
            "Lorestan": [
                "Alashtar",
                "Aligudarz",
                "Azna",
                "Borujerd",
                "Do Rud",
                "Khorramabad",
                "Kuhdasht",
                "Nurabad"
            ],
            "Markazi": [
                "Arak",
                "Ashtian",
                "Delijan",
                "Khomeyn",
                "Mahallat",
                "Sarband",
                "Saveh",
                "Tafresh"
            ],
            "Mazandaran": [
                "Aliabad",
                "Amir Kala",
                "Amol",
                "Babol",
                "Babol Sar",
                "Behshahr",
                "Chalus",
                "Fereydunkenar",
                "Juybar",
                "Kalaleh",
                "Kordkuy",
                "Mahmudabad",
                "Minudasht",
                "Neka",
                "Nur",
                "Nushahr",
                "Qa\\'emshahr",
                "Ramsar",
                "Sari",
                "Savadkuh",
                "Tonekabon"
            ],
            "Ostan-e Esfahan": [
                "Aliabad",
                "Amir Kala",
                "Amol",
                "Babol",
                "Babol Sar",
                "Behshahr",
                "Chalus",
                "Fereydunkenar",
                "Juybar",
                "Kalaleh",
                "Kordkuy",
                "Mahmudabad",
                "Minudasht",
                "Neka",
                "Nur",
                "Nushahr",
                "Qa\\'emshahr",
                "Ramsar",
                "Sari",
                "Savadkuh",
                "Tonekabon"
            ],
            "Qazvin": [
                "Abhar",
                "Abyek",
                "Qazvin",
                "Takestan"
            ],
            "Qom": [
                "Qom"
            ],
            "Semnan": [
                "Damghan",
                "Garmsar",
                "Semnan",
                "Shahrud"
            ],
            "Sistan-e Baluchestan": [
                "Damghan",
                "Garmsar",
                "Semnan",
                "Shahrud"
            ],
            "Tehran": [
                "Damavand",
                "Eqbaliyeh",
                "Eslamshahr",
                "Hashtgerd",
                "Karaj",
                "Mahdasht",
                "Malard",
                "Mohammadiyeh",
                "Nazarabad",
                "Pakdasht",
                "Pishva",
                "Qarchak",
                "Qods",
                "Robat Karim",
                "Shahriyar",
                "Tehran",
                "Varamin"
            ],
            "Yazd": [
                "Ardakan",
                "Bafq",
                "Mehriz",
                "Meybod",
                "Taft",
                "Yazd"
            ],
            "Zanjan": [
                "Alvand",
                "Khorramdarreh",
                "Zanjan"
            ]
        }
    },
    {
        "id": 104,
        "name": "Iraq",
        "iso3": "IRQ",
        "iso2": "IQ",
        "phone_code": "964",
        "capital": "Baghdad",
        "currency": "IQD",
        "states": {
            "al-Anbar": [
                "al-Fallujah",
                "al-Habbaniyah",
                "al-Hadithah",
                "ar-Ramadi",
                "ar-Rutbah",
                "Hit",
                "Rawah",
                "\\'Anah"
            ],
            "al-Basrah": [
                "Abu al-Khasib",
                "al-Basrah",
                "al-Faw",
                "al-Qurnah",
                "az-Zubayr",
                "Harithah",
                "Shatt-al-\\'Arab"
            ],
            "al-Muthanna": [
                "ar-Rumaythah",
                "as-Samawah"
            ],
            "al-Qadisiyah": [
                "ad-Diwaniyah",
                "al-Ghammas",
                "al-Hamzah",
                "ash-Shamiyah",
                "ash-Shinafiyah",
                "\\'Afak"
            ],
            "an-Najaf": [
                "al-Kufah",
                "al-Mishkhab",
                "an-Najaf"
            ],
            "as-Sulaymaniyah": [
                "as-Sulaymaniyah",
                "Chamchamal",
                "Halabjah",
                "Kusanjaq",
                "Panjwin",
                "Qal\\'at Dizeh"
            ],
            "at-Ta\\'mim": [
                "Kirkuk",
                "\\'Aqrah"
            ],
            "Babil": [
                "al-Hillah",
                "al-Madhatiyah",
                "al-Musayyib",
                "al-Qasim",
                "Jurf-as-Sakhr",
                "Saddat-al-Hindiyah"
            ],
            "Baghdad": [
                "Baghdad"
            ],
            "Dahuk": [
                "Dahuk",
                "Zakhu"
            ],
            "Dhi Qar": [
                "Dahuk",
                "Zakhu"
            ],
            "Diyala": [
                "al-Fuhud",
                "al-Khalis",
                "al-Miqdadiyah",
                "ash-Shatrah",
                "Ba\\'qubah",
                "Hanaqin",
                "Jalula\\'\\'",
                "Kifri",
                "Mandali"
            ],
            "Erbil": [
                "Ankawa"
            ],
            "Irbil": [
                "Chaqalawa",
                "Irbil",
                "Rawanduz"
            ],
            "Karbala": [
                "al-Hindiyah",
                "Karbala"
            ],
            "Kurdistan": [
                "Erbil"
            ],
            "Maysan": [
                "al-Majarr-al-Kabir",
                "al-\\'Amarah",
                "\\'Ali al Gharbi"
            ],
            "Ninawa": [
                "al-Mawsil",
                "ash-Shaykhan",
                "Qarah Qush",
                "Sinjar",
                "Tall Kayf",
                "Tall \\'Afar"
            ],
            "Salah-ad-Din": [
                "ad-Dujayl",
                "Balad",
                "Bayji",
                "Dhalu\\'iyah",
                "Samarra",
                "Tikrit",
                "Tuz"
            ],
            "Wasit": [
                "al-Hayy",
                "al-Kut",
                "al-\\'Aziziyah",
                "an-Nu\\'maniyah",
                "as-Suwayrah"
            ]
        }
    },
    {
        "id": 105,
        "name": "Ireland",
        "iso3": "IRL",
        "iso2": "IE",
        "phone_code": "353",
        "capital": "Dublin",
        "currency": "EUR",
        "states": {
            "Armagh": [
                "Moira"
            ],
            "Carlow": [
                "Bagenalstown",
                "Carlow",
                "Tullow"
            ],
            "Cavan": [
                "Bailieborough",
                "Belturbet",
                "Cavan",
                "Coothill"
            ],
            "Clare": [
                "Ennis",
                "Kilkee",
                "Kilrush",
                "Newmarket-on-Fergus",
                "Shannon"
            ],
            "Cork": [
                "Bandon",
                "Bantry",
                "Blarney",
                "Carrigaline",
                "Charleville",
                "Clonakilty",
                "Cobh",
                "Cork",
                "Drishane",
                "Dunmanway",
                "Fermoy",
                "Kanturk",
                "Kinsale",
                "Macroom",
                "Mallow",
                "Midleton",
                "Millstreet",
                "Mitchelstown",
                "Passage West",
                "Skibbereen",
                "Youghal"
            ],
            "Donegal": [
                "Ballybofey",
                "Ballyshannon",
                "Buncrana",
                "Bundoran",
                "Carndonagh",
                "Donegal",
                "Killybegs",
                "Letterkenny",
                "Lifford",
                "Moville"
            ],
            "Dublin": [
                "Balbriggan",
                "Ballsbridge",
                "Dublin",
                "Leixlip",
                "Lucan",
                "Malahide",
                "Portrane",
                "Rathcoole",
                "Rush",
                "Skerries",
                "Swords"
            ],
            "Galway": [
                "Athenry",
                "Ballinasloe",
                "Clifden",
                "Galway",
                "Gort",
                "Loughrea",
                "Tuam"
            ],
            "Kerry": [
                "Ballybunion",
                "Cahirciveen",
                "Castleisland",
                "Dingle",
                "Kenmare",
                "Killarney",
                "Killorglin",
                "Listowel",
                "Tralee"
            ],
            "Kildare": [
                "Athy",
                "Celbridge",
                "Clane",
                "Kilcock",
                "Kilcullen",
                "Kildare",
                "Maynooth",
                "Monasterevan",
                "Naas",
                "Newbridge"
            ],
            "Kilkenny": [
                "Callan",
                "Castlecomer",
                "Kilkenny",
                "Thomastown"
            ],
            "Laois": [
                "Abbeyleix",
                "Mountmellick",
                "Mountrath",
                "Port Laoise",
                "Portarlington"
            ],
            "Leinster": [
                "Meath"
            ],
            "Leitrim": [
                "Carrick-on-Shannon"
            ],
            "Limerick": [
                "Abbeyfeale",
                "Kilmallock",
                "Limerick",
                "Newcastle",
                "Rathkeale"
            ],
            "Loch Garman": [
                "Abbeyfeale",
                "Kilmallock",
                "Limerick",
                "Newcastle",
                "Rathkeale"
            ],
            "Longford": [
                "Granard",
                "Longford",
                "Moate"
            ],
            "Louth": [
                "Ardee",
                "Drogheda",
                "Drumcar",
                "Dundalk"
            ],
            "Mayo": [
                "Ballina",
                "Ballinrobe",
                "Ballyhaunis",
                "Castlebar",
                "Claremorris",
                "Swinford",
                "Westport"
            ],
            "Meath": [
                "Ashbourne",
                "Duleek",
                "Dunboyne",
                "Dunshaughlin",
                "Kells",
                "Laytown",
                "Navan",
                "Trim"
            ],
            "Monaghan": [
                "Carrickmacross",
                "Castleblayney",
                "Clones",
                "Monaghan"
            ],
            "Offaly": [
                "Banagher",
                "Birr",
                "Clara",
                "Edenderry",
                "Kilcormac",
                "Tullamore"
            ],
            "Roscommon": [
                "Ballaghaderreen",
                "Boyle",
                "Castlerea",
                "Roscommon"
            ],
            "Sligo": [
                "Sligo"
            ],
            "Tipperary North Riding": [
                "Sligo"
            ],
            "Tipperary South Riding": [
                "Sligo"
            ],
            "Ulster": [
                "Co Tyrone",
                "Downpatrick"
            ],
            "Waterford": [
                "Dungarvan",
                "Tramore",
                "Waterford"
            ],
            "Westmeath": [
                "Athlone",
                "Mullingar"
            ],
            "Wexford": [
                "Enniscorthy",
                "Gorey",
                "New Ross",
                "Wexford"
            ],
            "Wicklow": [
                "Arklow",
                "Baltinglass",
                "Blessington",
                "Bray",
                "Greystones",
                "Kilcoole",
                "Newtownmountkennedy",
                "Rathdrum",
                "Wicklow"
            ]
        }
    },
    {
        "id": 106,
        "name": "Israel",
        "iso3": "ISR",
        "iso2": "IL",
        "phone_code": "972",
        "capital": "Jerusalem",
        "currency": "ILS",
        "states": {
            "Beit Hanania": [
                "Arklow",
                "Baltinglass",
                "Blessington",
                "Bray",
                "Greystones",
                "Kilcoole",
                "Newtownmountkennedy",
                "Rathdrum",
                "Wicklow"
            ],
            "Ben Gurion Airport": [
                "Arklow",
                "Baltinglass",
                "Blessington",
                "Bray",
                "Greystones",
                "Kilcoole",
                "Newtownmountkennedy",
                "Rathdrum",
                "Wicklow"
            ],
            "Bethlehem": [
                "Bethlehem"
            ],
            "Caesarea": [
                "Caesarea"
            ],
            "Centre": [
                "Petach Tikva",
                "Ramallah"
            ],
            "Gaza": [
                "Gaza"
            ],
            "Hadaron": [
                "Ashdod",
                "Ashqelon",
                "Beersheba",
                "Bene Ayish",
                "Be\\'er Sheva",
                "Dimona",
                "Elat",
                "Gan Yavne",
                "kiryat Malachi",
                "Nahal `Oz",
                "Netivot",
                "Ofaqim",
                "Qiryat Gat",
                "Qiryat Mal\\'akhi",
                "Sederot",
                "Yeroham",
                "\\'Arad",
                "\\'Omer"
            ],
            "Haifa District": [
                "Ashdod",
                "Ashqelon",
                "Beersheba",
                "Bene Ayish",
                "Be\\'er Sheva",
                "Dimona",
                "Elat",
                "Gan Yavne",
                "kiryat Malachi",
                "Nahal `Oz",
                "Netivot",
                "Ofaqim",
                "Qiryat Gat",
                "Qiryat Mal\\'akhi",
                "Sederot",
                "Yeroham",
                "\\'Arad",
                "\\'Omer"
            ],
            "Hamerkaz": [
                "Beit Shemesh",
                "Bene Beraq",
                "Be\\'er Ya\\'aqov",
                "Bnei Brak",
                "Even Yehuda",
                "Fureidis",
                "Gat Rimon",
                "Gedera",
                "Givat Shmuel",
                "Hibat Zion",
                "Hod HaSharon",
                "Hogar",
                "Jaljulye",
                "Jatt",
                "Kafar Qasem",
                "Kefar Sava",
                "Kefar Yona",
                "Kfar Saba",
                "Kiryag Bialik",
                "Lod",
                "Mazkeret Batya",
                "Modi\\'in",
                "Nes Ziyyona",
                "Ness Ziona",
                "Netanya",
                "Nordiya",
                "Pardesiyya",
                "Petakh Tiqwa",
                "Qadima",
                "Qalansawe",
                "Qiryat \\'Eqron",
                "Ramla",
                "Ra\\'anana",
                "Rehovot",
                "Rekhovot",
                "Rinnatya",
                "Rishon LeZiyyon",
                "Rosh HaAyin",
                "Shoham",
                "Tayibe",
                "Tire",
                "Tsur Igal",
                "Udim",
                "Yavne",
                "Yehud"
            ],
            "Hazafon": [
                "Abu Sinan",
                "Basmat Tab\\'un",
                "Beit Jann",
                "Bet She\\'an",
                "Bir-al-Maksur",
                "Bi\\'ne",
                "Bu\\'eine-Nujeidat",
                "Dabburye",
                "Dayr Hannah",
                "Dayr-al-Asad",
                "Hazor HaGelilit",
                "Iksal",
                "I\\'billin",
                "Judeide-Maker",
                "Kabul",
                "Kafar Kanna",
                "Kafar Manda",
                "Kafar Yasif",
                "Karmiel",
                "Kisra-Sumei",
                "Majd-al-Kurum",
                "Ma\\'alot Tarshikha",
                "Migdal Ha\\'Emeq",
                "Mugar",
                "Nahariyya",
                "Nahef",
                "Nazerat",
                "Nazerat \\'Illit",
                "Qiryat Shemona",
                "Qiryat Tiv\\'on",
                "Rame",
                "Reine",
                "Sakhnin",
                "Shefar\\'am",
                "Tamra",
                "Tiberias",
                "Tur\\'an",
                "Yirka",
                "Yoqne\\'am Illit",
                "Zefat",
                "Zur Yigal",
                "\\'Afula",
                "\\'Akko",
                "\\'Arrabe",
                "\\'Ein Mahel",
                "\\'Ilut"
            ],
            "Hebron": [
                "Hebron"
            ],
            "Jaffa": [
                "Sgula"
            ],
            "Jerusalem": [
                "Jerusalem"
            ],
            "Khefa": [
                "Baqa al-Gharbiyyah",
                "Binyamina",
                "Daliyat-al-Karmil",
                "Jizr-az-Zarqa",
                "Khadera",
                "Khefa",
                "Nesher",
                "Or \\'Aqiva",
                "Pardes Khanna-Karkur",
                "Qiryat Atta",
                "Qiryat Bialik",
                "Qiryat Motzkin",
                "Qiryat Yam",
                "Rekhasim",
                "Tirat Karmel",
                "Umm-al-Fahm",
                "Zikhron Ya\\'aqov",
                "\\'Ar\\'ara",
                "\\'Isifya"
            ],
            "Kiryat Yam": [
                "Baqa al-Gharbiyyah",
                "Binyamina",
                "Daliyat-al-Karmil",
                "Jizr-az-Zarqa",
                "Khadera",
                "Khefa",
                "Nesher",
                "Or \\'Aqiva",
                "Pardes Khanna-Karkur",
                "Qiryat Atta",
                "Qiryat Bialik",
                "Qiryat Motzkin",
                "Qiryat Yam",
                "Rekhasim",
                "Tirat Karmel",
                "Umm-al-Fahm",
                "Zikhron Ya\\'aqov",
                "\\'Ar\\'ara",
                "\\'Isifya"
            ],
            "Lower Galilee": [
                "Baqa al-Gharbiyyah",
                "Binyamina",
                "Daliyat-al-Karmil",
                "Jizr-az-Zarqa",
                "Khadera",
                "Khefa",
                "Nesher",
                "Or \\'Aqiva",
                "Pardes Khanna-Karkur",
                "Qiryat Atta",
                "Qiryat Bialik",
                "Qiryat Motzkin",
                "Qiryat Yam",
                "Rekhasim",
                "Tirat Karmel",
                "Umm-al-Fahm",
                "Zikhron Ya\\'aqov",
                "\\'Ar\\'ara",
                "\\'Isifya"
            ],
            "Qalqilya": [
                "Qalqilya"
            ],
            "Talme Elazar": [
                "Qalqilya"
            ],
            "Tel Aviv": [
                "Qalqilya"
            ],
            "Tsafon": [
                "Hadera",
                "Kiryag Bialik"
            ],
            "Umm El Fahem": [
                "Hadera",
                "Kiryag Bialik"
            ],
            "Yerushalayim": [
                "Bet Shemesh",
                "Mevasserat Ziyyon",
                "Yerushalayim"
            ]
        }
    },
    {
        "id": 107,
        "name": "Italy",
        "iso3": "ITA",
        "iso2": "IT",
        "phone_code": "39",
        "capital": "Rome",
        "currency": "EUR",
        "states": {
            "Abruzzi": [
                "Meta",
                "Miano"
            ],
            "Abruzzo": [
                "Alba Adriatica",
                "Atessa",
                "Atri",
                "Avezzano",
                "Celano",
                "Cepagatti",
                "Chieti",
                "Citta Sant\\'Angelo",
                "Francavilla al Mare",
                "Giulianova",
                "Guardiagrele",
                "Lanciano",
                "L\\'Aquila",
                "Martinsicuro",
                "Montesilvano",
                "Montorio al Vomano",
                "Mosciano Sant\\'Angelo",
                "Ortona",
                "Penne",
                "Pescara",
                "Pineto",
                "Roseto degli Abruzzi",
                "San Giovanni Teatino",
                "San Salvo",
                "Sant\\'Egidio alla Vibrata",
                "Silvi",
                "Spoltore",
                "Sulmona",
                "Teramo",
                "Vasto"
            ],
            "Agrigento": [
                "Agrigento",
                "Alessandria della Rocca",
                "Aragona",
                "Bivona",
                "Burgio",
                "Calamonaci",
                "Caltabellotta",
                "Camastra",
                "Cammarata",
                "Campobello di Licata",
                "Canicatt\u00c3\u0192\u00c2\u0192\u00c3\u2020\u00c2\u2019\u00c3\u0192\u00c2\u201a\u00c3\u201a\u00c2\u00ac",
                "Casteltermini",
                "Castrofilippo",
                "Cattolica Eraclea",
                "Cianciana",
                "Comitini",
                "Favara",
                "Grotte",
                "Joppolo Giancaxio",
                "Lampedusa e Linosa",
                "Licata",
                "Lucca Sicula",
                "Menfi",
                "Montallegro",
                "Montevago",
                "Naro",
                "Palma di Montechiaro",
                "Porto Empedocle",
                "Racalmuto",
                "Raffadali",
                "Ravanusa",
                "Realmonte",
                "Ribera",
                "Sambuca di Sicilia",
                "San Biagio Platani",
                "San Giovanni Gemini",
                "Sant Angelo Muxaro",
                "Santa Elisabetta",
                "Santa Margherita di Belice",
                "Santo Stefano Quisquina",
                "Sciacca",
                "Siculiana",
                "Villafranca Sicula"
            ],
            "Alessandria": [
                "Castellazzo Bormida",
                "Gavi",
                "Villanova Monferrato"
            ],
            "Ancona": [
                "Camerano",
                "Castelplanio"
            ],
            "Arezzo": [
                "Capolona",
                "Montevarchi",
                "Subbiano"
            ],
            "Ascoli Piceno": [
                "Capolona",
                "Montevarchi",
                "Subbiano"
            ],
            "Asti": [
                "Buttigliera d\\'Asti"
            ],
            "Avellino": [
                "Flumeri",
                "Nusco",
                "Prata di Principato Ultra",
                "Villanova del Battista"
            ],
            "Bari": [
                "Flumeri",
                "Nusco",
                "Prata di Principato Ultra",
                "Villanova del Battista"
            ],
            "Basilicata": [
                "Avigliano",
                "Bernalda",
                "Ferrandina",
                "Lauria",
                "Lavello",
                "Matera",
                "Melfi",
                "Montescaglioso",
                "Pisticci",
                "Policoro",
                "Potenza",
                "Rionero in Vulture",
                "Venosa"
            ],
            "Belluno": [
                "Belluno",
                "D\\'alpago",
                "Longarone",
                "Pedavena"
            ],
            "Benevento": [
                "San Bartolomeo"
            ],
            "Bergamo": [
                "Bagnatica",
                "Bergamo",
                "Bolgare",
                "Bottanuco",
                "Brignano Gera d\\'Adda",
                "Calcio",
                "Caravaggio",
                "Chiuduno",
                "Ciserano",
                "Comun Nuovo",
                "Costa di Mezzate",
                "Gandino",
                "Grassobbio",
                "Grumello Del Monte",
                "Lallio",
                "Levate",
                "Lurano",
                "Mapello",
                "Pagazzano",
                "Ponteranica",
                "Pontida",
                "Sant Omobono Imagna",
                "Torre Pallavicina",
                "Trescore Balneario",
                "Verdellino",
                "Zingonia"
            ],
            "Biella": [
                "Camburzano",
                "Crevacuore",
                "Gaglianico",
                "Sandigliano",
                "Vigliano Biellese"
            ],
            "Bologna": [
                "Anzola dell\\'Emilia",
                "Bologna",
                "Borgo Tossignano",
                "Casalfiumanese",
                "Castiglione Dei Pepoli ",
                "Funo",
                "Loiano",
                "Monterenzio",
                "Osteria Grande"
            ],
            "Bolzano": [
                "Frangarto"
            ],
            "Brescia": [
                "Agnosine",
                "Brescia",
                "Capriano del Colle",
                "Capriolo",
                "Castegnato",
                "Castelcovati",
                "Cellatica",
                "Coccaglio",
                "Comezzano-Cizzago",
                "Erbusco",
                "Flero",
                "Lavenone",
                "Longhena",
                "Maclodio",
                "Muscoline",
                "Padenghe sul Garda",
                "Paderno Franciacorta",
                "Paratico",
                "Passirano",
                "Polaveno",
                "Poncarale",
                "Prevalle",
                "Provaglio dIseo",
                "Roncadelle",
                "Verolavecchia",
                "Visano"
            ],
            "Brindisi": [
                "San Donaci"
            ],
            "Calabria": [
                "Acri",
                "Amantea",
                "Bagnara Calabra",
                "Belvedere Marittimo",
                "Bisignano",
                "Bovalino",
                "Cariati",
                "Cassano allo Ionio",
                "Castrolibero",
                "Castrovillari",
                "Catanzaro",
                "Cetraro",
                "Ciro Marina",
                "Cittanova",
                "Corigliano Calabro",
                "Cosenza",
                "Crosia",
                "Crotone",
                "Cutro",
                "Fuscaldo",
                "Gioia Tauro",
                "Isola di Capo Rizzuto",
                "Lamezia Terme",
                "Locri",
                "Luzzi",
                "Melito di Porto Salvo",
                "Mendicino",
                "Montalto Uffugo",
                "Palmi",
                "Paola",
                "Petilia Policastro",
                "Pizzo",
                "Polistena",
                "Reggio di Calabria",
                "Rende",
                "Rosarno",
                "Rossano",
                "San Giovanni in Fiore",
                "Scalea",
                "Sellia Marina",
                "Siderno",
                "Soverato",
                "Taurianova",
                "Trebisacce",
                "Vibo Valentia",
                "Villa San Giovanni"
            ],
            "Campania": [
                "Acerra",
                "Afragola",
                "Agropoli",
                "Angri",
                "Ariano Irpino",
                "Arzano",
                "Atripalda",
                "Avellino",
                "Aversa",
                "Bacoli",
                "Barano d\\'Ischia",
                "Baronissi",
                "Battipaglia",
                "Bellizzi",
                "Benevento",
                "Boscoreale",
                "Boscotrecase",
                "Brusciano",
                "Caivano",
                "Calvizzano",
                "Campagna",
                "Capaccio",
                "Capua",
                "Cardito",
                "Carinola",
                "Casagiove",
                "Casal di Principe",
                "Casalnuovo di Napoli",
                "Casaluce",
                "Casandrino",
                "Casavatore",
                "Caserta",
                "Casoria",
                "Castel San Giorgio",
                "Castel Volturno",
                "Castellammare di Stabia",
                "Cava de\\' Tirreni",
                "Cercola",
                "Cervinara",
                "Cicciano",
                "Crispano",
                "Eboli",
                "Ercolano",
                "Fisciano",
                "Forio",
                "Frattamaggiore",
                "Frattaminore",
                "Frignano",
                "Giffoni Valle Piana",
                "Giugliano in Campania",
                "Gragnano",
                "Gricignano di Aversa",
                "Grottaminarda",
                "Grumo Nevano",
                "Ischia",
                "Lusciano",
                "Macerata Campania",
                "Maddaloni",
                "Marano di Napoli",
                "Marcianise",
                "Marigliano",
                "Massa Lubrense",
                "Melito di Napoli",
                "Mercato San Severino",
                "Mercogliano",
                "Meta",
                "Mirabella Eclano",
                "Mondragone",
                "Monte di Procida",
                "Montecorvino Rovella",
                "Monteforte Irpino",
                "Montesarchio",
                "Montoro Inferiore",
                "Mugnano di Napoli",
                "Naples",
                "Napoli",
                "Nocera Inferiore",
                "Nocera Superiore",
                "Nola",
                "Orta di Atella",
                "Ottaviano",
                "Pagani",
                "Palma Campania",
                "Parete",
                "Pellezzano",
                "Piano di Sorrento",
                "Piedimonte Matese",
                "Poggiomarino",
                "Pollena Trocchia",
                "Pomigliano d\\'Arco",
                "Pompei",
                "Pontecagnano",
                "Portici",
                "Positano",
                "Pozzuoli",
                "Procida",
                "Qualiano",
                "Quarto",
                "Roccapiemonte",
                "Sala Consilina",
                "Salerno",
                "San Cipriano d\\'Aversa",
                "San Felice a Cancello",
                "San Gennaro Vesuviano",
                "San Giorgio a Cremano",
                "San Giorgio del Sannio",
                "San Giuseppe Vesuviano",
                "San Marcellino",
                "San Marzano sul Sarno",
                "San Nicola",
                "San Prisco",
                "San Sebastiano al Vesuvio",
                "San Valentino Torio",
                "Sant Antimo",
                "Santa Maria a Vico",
                "Santa Maria Capua Vetere",
                "Santa Maria la Carita",
                "Sant\\'Agata de\\' Goti",
                "Sant\\'Agnello",
                "Sant\\'Anastasia",
                "Sant\\'Antonio Abate",
                "Sant\\'Arpino",
                "Sant\\'Egidio del Monte Albino",
                "Sarno",
                "Saviano",
                "Scafati",
                "Sessa Aurunca",
                "Siano",
                "Solofra",
                "Somma Vesuviana",
                "Sorrento",
                "Teano",
                "Teggiano",
                "Terzigno",
                "Teverola",
                "Torre Annunziata",
                "Torre del Greco",
                "Trecase",
                "Trentola-Ducenta",
                "Vallo della Lucania",
                "Vico Equense",
                "Vietri sul Mare",
                "Villa Literno",
                "Villaricca",
                "Volla"
            ],
            "Cartoceto": [
                "Cartoceto"
            ],
            "Caserta": [
                "Carinaro",
                "San Marco Evangelista"
            ],
            "Catania": [
                "Fiandaca Di Acireale",
                "San Cono"
            ],
            "Chieti": [
                "Altino",
                "Archi",
                "Ari",
                "Arielli",
                "Atessa",
                "Bomba",
                "Borrello",
                "Bucchianico",
                "Canosa Sannita",
                "Carpineto Sinello",
                "Carunchio",
                "Casacanditella",
                "Casalanguida",
                "Casalbordino",
                "Casalincontrada",
                "Casoli",
                "Castel Frentano",
                "Castelguidone",
                "Castiglione Messer Marino",
                "Celenza sul Trigno",
                "Chieti",
                "Civitaluparella",
                "Civitella Messer Raimondo",
                "Colledimacine",
                "Colledimezzo",
                "Crecchio",
                "Cupello",
                "Dogliola",
                "Fallo",
                "Fara Filiorum Petri",
                "Fara San Martino",
                "Filetto",
                "Fossacesia",
                "Fraine",
                "Francavilla al Mare",
                "Fresagrandinaria",
                "Frisa",
                "Furci",
                "Gamberale",
                "Gessopalena",
                "Gissi",
                "Giuliano Teatino",
                "Guardiagrele",
                "Guilmi",
                "Lama dei Peligni",
                "Lanciano",
                "Lentella",
                "Lettopalena",
                "Liscia",
                "Miglianico",
                "Montazzoli",
                "Montebello sul Sangro",
                "Monteferrante",
                "Montelapiano",
                "Montenerodomo",
                "Monteodorisio",
                "Mozzagrogna",
                "Orsogna",
                "Ortona",
                "Paglieta",
                "Palena",
                "Palmoli",
                "Palombaro",
                "Pennadomo",
                "Pennapiedimonte",
                "Perano",
                "Pietraferrazzana",
                "Pizzoferrato",
                "Poggiofiorito",
                "Pollutri",
                "Pretoro",
                "Quadri",
                "Rapino",
                "Ripa Teatina",
                "Rocca San Giovanni",
                "Roccamontepiano",
                "Roccascalegna",
                "Roccaspinalveti",
                "Roio del Sangro",
                "Rosello",
                "San Buono",
                "San Giovanni Lipioni",
                "San Giovanni Teatino",
                "San Martino sulla Marrucina",
                "San Salvo",
                "San Vito Chietino",
                "Sant Eusanio del Sangro",
                "Santa Maria Imbaro",
                "Scerni",
                "Schiavi di Abruzzo",
                "Taranta Peligna",
                "Tollo",
                "Torino di Sangro",
                "Tornareccio",
                "Torrebruna",
                "Torrevecchia Teatina",
                "Torricella Peligna",
                "Treglio",
                "Tufillo",
                "Vacri",
                "Vasto",
                "Villa Santa Maria",
                "Villalfonsina",
                "Villamagna"
            ],
            "Como": [
                "Albavilla",
                "Cadorago",
                "Carimate",
                "Castelmarte",
                "Cavaria",
                "Cernobbio",
                "Comocrea",
                "Dongo",
                "Gironico",
                "Grandate",
                "Lurago dErba",
                "Mozzate",
                "Novedrate",
                "Orsenigo",
                "Turate",
                "Uggiate"
            ],
            "Cosenza": [
                "Corso del Tirreno",
                "Mangone"
            ],
            "Cremona": [
                "Casalbuttano",
                "Casalmaggiore",
                "Castelverde",
                "Madignano",
                "Pieve San Giacomo"
            ],
            "Cuneo": [
                "Bandito",
                "Bra",
                "Casalgrasso",
                "Cossano Belbo",
                "Magliano Alpi",
                "Mondovi",
                "Roddi",
                "Santa Vittoria d\\'Alba",
                "Verduno"
            ],
            "Emilia-Romagna": [
                "Alfonsine",
                "Argelato",
                "Argenta",
                "Bagnacavallo",
                "Bagnolo in Piano",
                "Bellaria-Igea Marina",
                "Bertinoro",
                "Bologna",
                "Bondeno",
                "Budrio",
                "Calderara di Reno",
                "Carpi",
                "Casalecchio di Reno",
                "Casalgrande",
                "Castel Bolognese",
                "Castel Maggiore",
                "Castel San Giovanni",
                "Castel San Pietro Terme",
                "Castelfranco Emilia",
                "Castellarano",
                "Castelnovo ne\\' Monti",
                "Castelnuovo Rangone",
                "Castelvetro di Modena",
                "Castenaso",
                "Cattolica",
                "Cavriago",
                "Cento",
                "Cervia",
                "Cesena",
                "Cesenatico",
                "Codigoro",
                "Collecchio",
                "Comacchio",
                "Concordia sulla Secchia",
                "Conselice",
                "Copparo",
                "Coriano",
                "Correggio",
                "Crespellano",
                "Crevalcore",
                "Faenza",
                "Ferrara",
                "Fidenza",
                "Finale Emilia",
                "Fiorano Modenese",
                "Fiorenzuola d\\'Arda",
                "Forli",
                "Forlimpopoli",
                "Formigine",
                "Gambettola",
                "Granarolo dell\\'Emilia",
                "Guastalla",
                "Imola",
                "Langhirano",
                "Lugo",
                "Luzzara",
                "Maranello",
                "Massa Lombarda",
                "Medesano",
                "Medicina",
                "Meldola",
                "Mirandola",
                "Misano Adriatico",
                "Modena",
                "Molinella",
                "Monte San Pietro",
                "Montecchio Emilia",
                "Montechiarugolo",
                "Noceto",
                "Nonantola",
                "Novellara",
                "Novi di Modena",
                "Ozzano dell\\'Emilia",
                "Parma",
                "Pavullo nel Frignano",
                "Piacenza",
                "Pianoro",
                "Ponticino",
                "Portomaggiore",
                "Quattro Castella",
                "Ravenna",
                "Reggio nell\\'Emilia",
                "Reggiolo",
                "Riccione",
                "Rimini",
                "Rottofreno",
                "Rubiera",
                "Russi",
                "Salsomaggiore Terme",
                "San Felice sul Panaro",
                "San Giovanni in Persiceto",
                "San Lazzaro di Savena",
                "San Mauro Pascoli",
                "San Pietro in Casale",
                "Santarcangelo di Romagna",
                "Sant\\'Ilario d\\'Enza",
                "Sasso Marconi",
                "Sassuolo",
                "Savignano sul Panaro",
                "Savignano sul Rubicone",
                "Scandiano",
                "Soliera",
                "Sorbolo",
                "Spilamberto",
                "Verucchio",
                "Vignola",
                "Zola Predosa"
            ],
            "Ferrara": [
                "Saint Agostino"
            ],
            "Firenze": [
                "Capalle",
                "Firenze",
                "Pelago",
                "San Donnino",
                "Scarperia"
            ],
            "Florence": [
                "Scandicci",
                "Sesto Fiorentino"
            ],
            "Forli-Cesena ": [
                "Scandicci",
                "Sesto Fiorentino"
            ],
            "Friuli-Venezia Giulia": [
                "Scandicci",
                "Sesto Fiorentino"
            ],
            "Frosinone": [
                "Casalvieri",
                "Frosinone",
                "Sgurgola"
            ],
            "Genoa": [
                "Genoa",
                "Moneglia"
            ],
            "Gorizia": [
                "Romans d\\'Isonzo",
                "Savogna d\\'Isonzo"
            ],
            "Lazio": [
                "Alatri",
                "Albano Laziale",
                "Anagni",
                "Anguillara Sabazia",
                "Anzio",
                "Aprilia",
                "Ardea",
                "Ariccia",
                "Artena",
                "Boville Ernica",
                "Bracciano",
                "Campagnano di Roma",
                "Cassino",
                "Cave",
                "Ceccano",
                "Ceprano",
                "Cerveteri",
                "Ciampino",
                "Cisterna",
                "Civita Castellana",
                "Civitavecchia",
                "Colleferro",
                "Cori",
                "Fara in Sabina",
                "Ferentino",
                "Fiano Romano",
                "Fiuggi",
                "Fiumicino",
                "Fondi",
                "Fonte Nuova",
                "Formello",
                "Formia",
                "Frascati",
                "Frosinone",
                "Gaeta",
                "Genzano di Roma",
                "Grottaferrata",
                "Guidonia",
                "Isola del Liri",
                "Itri",
                "Ladispoli",
                "Lanuvio",
                "Lariano",
                "Latina",
                "Marino",
                "Mentana",
                "Minturno",
                "Monte San Giovanni Campano",
                "Montefiascone",
                "Monterotondo",
                "Nettuno",
                "Palestrina",
                "Palombara Sabina",
                "Pomezia",
                "Pontecorvo",
                "Pontinia",
                "Priverno",
                "Rieti",
                "Rocca di Papa",
                "Rocca Priora",
                "Roma",
                "Rome",
                "Sabaudia",
                "San Cesareo",
                "Santa Marinella",
                "Segni",
                "Sezze",
                "Sora",
                "Soriano nel Cimino",
                "Subiaco",
                "Tarquinia",
                "Terracina",
                "Tivoli",
                "Valmontone",
                "Velletri",
                "Veroli",
                "Vetralla",
                "Viterbo",
                "Zagarolo"
            ],
            "Lecce": [
                "Acquarica del Capo"
            ],
            "Lecco": [
                "Airuno",
                "Bosisio Parini",
                "Lecco",
                "Margno",
                "Osnago",
                "Sirone"
            ],
            "Lecco Province": [
                "Airuno",
                "Bosisio Parini",
                "Lecco",
                "Margno",
                "Osnago",
                "Sirone"
            ],
            "Liguria": [
                "Alassio",
                "Albenga",
                "Albisola Superiore",
                "Arcola",
                "Arenzano",
                "Bordighera",
                "Borgonuovo",
                "Cairo Montenotte",
                "Chiavari",
                "Cogoleto",
                "Finale Ligure",
                "Genova",
                "Imperia",
                "La Spezia",
                "Lavagna",
                "Lerici",
                "Loano",
                "Ortonovo",
                "Pietra Ligure",
                "Rapallo",
                "Recco",
                "San Remo",
                "Santa Margherita Ligure",
                "Santo Stefano di Magra",
                "Sarzana",
                "Savona",
                "Sestri Levante",
                "Taggia",
                "Varazze",
                "Ventimiglia"
            ],
            "Lodi": [
                "Somaglia"
            ],
            "Lombardia": [
                "Abbiategrasso",
                "Agrate Brianza",
                "Albiate",
                "Albino",
                "Albizzate",
                "Alzano Lombardo",
                "Arcisate",
                "Arconate",
                "Arcore",
                "Arese",
                "Arluno",
                "Asola",
                "Bagnolo Mella",
                "Ballabio",
                "Bareggio",
                "Basiglio",
                "Bedizzole",
                "Bergamo",
                "Bernareggio",
                "Besana in Brianza",
                "Besozzo",
                "Biassono",
                "Bienate",
                "Bollate",
                "Botticino",
                "Bovisio-Masciago",
                "Brembilla",
                "Brescia",
                "Bresso",
                "Broni",
                "Brugherio",
                "Buccinasco",
                "Bussero",
                "Busto Arsizio",
                "Busto Garolfo",
                "Cairate",
                "Calcinato",
                "Calolziocorte",
                "Calusco d\\'Adda",
                "Canegrate",
                "Cantu",
                "Capriolo",
                "Carate Brianza",
                "Caravaggio",
                "Cardano al Campo",
                "Caronno Pertusella",
                "Carpenedolo",
                "Carugate",
                "Carvico",
                "Casalmaggiore",
                "Casalpusterlengo",
                "Casatenovo",
                "Casazza",
                "Casnigo",
                "Cassano d\\'Adda",
                "Cassano Magnago",
                "Cassina de\\' Pecchi",
                "Castano Primo",
                "Castel Goffredo",
                "Castel Mella",
                "Castellanza",
                "Castelleone",
                "Castelli Calepio",
                "Castenedolo",
                "Castiglione delle Stiviere",
                "Cazzago San Martino",
                "Cene",
                "Cermenate",
                "Cernusco sul Naviglio",
                "Cerro Maggiore",
                "Cesano Boscone",
                "Cesano Maderno",
                "Cesate",
                "Chiari",
                "Cilavegna",
                "Cinisello Balsamo",
                "Cislago",
                "Clusone",
                "Codogno",
                "Cologno al Serio",
                "Cologno Monzese",
                "Como",
                "Concesio",
                "Concorezzo",
                "Corbetta",
                "Cormano",
                "Cornaredo",
                "Cornate d\\'Adda",
                "Corsico",
                "Corte dei Cortesi",
                "Costa Volpino",
                "Crema",
                "Cremona",
                "Crocetta",
                "Curtatone",
                "Cusano Milanino",
                "Dalmine",
                "Darfo",
                "Desenzano del Garda",
                "Desio",
                "Erba",
                "Fagnano Olona",
                "Fino Mornasco",
                "Gaggiano",
                "Galbiate",
                "Gallarate",
                "Gambolo",
                "Garbagnate Milanese",
                "Gardone Val Trompia",
                "Garlasco",
                "Gavardo",
                "Gavirate",
                "Gerenzano",
                "Ghedi",
                "Giussano",
                "Goito",
                "Gonzaga",
                "Gorgonzola",
                "Gussago",
                "Gussola",
                "Induno Olona",
                "Inveruno",
                "Inzago",
                "Iseo",
                "Isola Dovarese",
                "Lacchiarella",
                "Lainate",
                "Laveno-Mombello",
                "Lecco",
                "Leffe",
                "Legnano",
                "Leno",
                "Lentate sul Seveso",
                "Limbiate",
                "Lissone",
                "Locate di Triulzi",
                "Lodi",
                "Lomazzo",
                "Lonate Pozzolo",
                "Lonato",
                "Luino",
                "Lumezzane",
                "Lurate Caccivio",
                "Magenta",
                "Malnate",
                "Mandello del Lario",
                "Manerbio",
                "Mantova",
                "Mariano Comense",
                "Martinengo",
                "Mazzano",
                "Meda",
                "Mediglia",
                "Melegnano",
                "Melzo",
                "Merate",
                "Milano",
                "Molteno",
                "Montichiari",
                "Monza",
                "Morbegno",
                "Mornago",
                "Mortara",
                "Muggio",
                "Nave",
                "Nembro",
                "Nerviano",
                "Nova Milanese",
                "Novate Milanese",
                "Olgiate Comasco",
                "Olgiate Olona",
                "Opera",
                "Orzinuovi",
                "Osio Sotto",
                "Ospitaletto",
                "Paderno Dugnano",
                "Palazzolo sull\\'Oglio",
                "Pandino",
                "Parabiago",
                "Paullo",
                "Pavia",
                "Pero",
                "Peschiera Borromeo",
                "Pessano con Bornago",
                "Pieve Emanuele",
                "Pioltello",
                "Ponte Nossa",
                "Ponte San Pietro",
                "Porto Mantovano",
                "Pozzolengo",
                "Rescaldina",
                "Rezzato",
                "Rho",
                "Rivarolo Mantovano",
                "Rodano",
                "Romano di Lombardia",
                "Rovato",
                "Rozzano",
                "Saletto",
                "Salo",
                "Samarate",
                "San Donato Milanese",
                "San Giuliano Milanese",
                "Sant\\'Angelo Lodigiano",
                "Sarezzo",
                "Saronno",
                "Scanzorosciate",
                "Sedriano",
                "Segrate",
                "Senago",
                "Seregno",
                "Seriate",
                "Sesto Calende",
                "Sesto San Giovanni",
                "Settimo Milanese",
                "Seveso",
                "Sirmione",
                "Solaro",
                "Somma Lombardo",
                "Sondrio",
                "Soresina",
                "Sorisole",
                "Stezzano",
                "Stradella",
                "Suzzara",
                "Tirano",
                "Tornata",
                "Tradate",
                "Travagliato",
                "Treviglio",
                "Treviolo",
                "Trezzano sul Naviglio",
                "Trezzo",
                "Tromello",
                "Uboldo",
                "Urgnano",
                "Usmate Velate",
                "Valmadrera",
                "Varedo",
                "Varese",
                "Verano Brianza",
                "Vergiate",
                "Viadana",
                "Vigevano",
                "Vignate",
                "Villa Carcina",
                "Villa Guardia",
                "Villasanta",
                "Vimercate",
                "Vimodrone",
                "Virgilio",
                "Voghera",
                "Zibido San Giacomo",
                "Zogno"
            ],
            "Lombardy": [
                "Barasso",
                "Bergamo",
                "Bolladello",
                "Capergnanica",
                "Costa Masnaga",
                "Medolago",
                "milan",
                "Nibionno",
                "Rodano",
                "Sordio",
                "Torre d\\'Isola",
                "Varese",
                "Villongo"
            ],
            "L\\'Aquila": [
                "Magliano de Marsi"
            ],
            "Macerata": [
                "Colmurano",
                "Monte San Giusto"
            ],
            "Mantova": [
                "Castel",
                "Gazoldo",
                "Marmirolo",
                "Monzambano",
                "Ostiglia",
                "Pegognaga",
                "Poggio Rusco",
                "Quistello",
                "Roverbella",
                "Suzzara"
            ],
            "Marche": [
                "Ancona",
                "Ascoli Piceno",
                "Barchi",
                "Cagli",
                "Castelfidardo",
                "Chiaravalle",
                "Cingoli",
                "Civitanova Marche",
                "Corridonia",
                "Fabriano",
                "Falconara Marittima",
                "Fano",
                "Fermo",
                "Filottrano",
                "Folignano",
                "Fossombrone",
                "Grottammare",
                "Jesi",
                "Loreto",
                "Macerata",
                "Matelica",
                "Mondavio",
                "Mondolfo",
                "Montappone",
                "Montecosaro",
                "Montegranaro",
                "Montemarciano",
                "Monteprandone",
                "Morrovalle",
                "Osimo",
                "Pesaro",
                "Polverigi",
                "Porto Recanati",
                "Porto San Giorgio",
                "Porto Sant\\'Elpidio",
                "Potenza Picena",
                "Recanati",
                "San Benedetto del Tronto",
                "San Severino Marche",
                "Sant\\'Elpidio a Mare",
                "Senigallia",
                "Tolentino",
                "Treia",
                "Urbino"
            ],
            "Messina": [
                "Cumiana",
                "Giammoro"
            ],
            "Milan": [
                "Assago",
                "Besana in Brianza",
                "Biassono",
                "Burago Molgora",
                "Cernusco Sul Naviglio",
                "Cologno Monzese",
                "Concorezzo",
                "Cornaredo",
                "Cuggiono",
                "Cusago",
                "Foro Buonaparte",
                "Gessate",
                "Gorgonzola",
                "Liscate",
                "Magenta",
                "Milan",
                "Noviglio",
                "Passirana Di Rho",
                "Pregnana Milane",
                "San Donato Milanese",
                "Seregno",
                "Trezzo Sull\\'adda",
                "Tribiano",
                "Vaprio d\\'Adda",
                "Vermezzo"
            ],
            "Modena": [
                "Bomporto",
                "Campogalliano",
                "Cavezzo",
                "Medolla",
                "Nonantola",
                "San Possidonio",
                "Sassuolo",
                "Spilamberto"
            ],
            "Molise": [
                "Bojano",
                "Campobasso",
                "Cantalupo",
                "Isernia",
                "Termoli",
                "Venafro"
            ],
            "Molteno": [
                "Bojano",
                "Campobasso",
                "Cantalupo",
                "Isernia",
                "Termoli",
                "Venafro"
            ],
            "Montenegro": [
                "Montenegro"
            ],
            "Monza and Brianza": [
                "Montenegro"
            ],
            "Naples": [
                "Forio d\\'Ischia"
            ],
            "Novara": [
                "Bogogno",
                "Invorio",
                "Pombia"
            ],
            "Padova": [
                "Bagnoli di Sopra",
                "Bovolenta",
                "Casale Di Scodosia",
                "Cervarese Santa Croce",
                "Fontaniva",
                "Galliera Veneta",
                "Legnaro",
                "Limena",
                "Loreggia",
                "Massanzago",
                "Onara",
                "Ponso",
                "Portogallo",
                "Tribano"
            ],
            "Parma": [
                "Baganzola",
                "Busseto",
                "Casale Di Mezzani",
                "Fontevivo",
                "Solignano",
                "Torrile"
            ],
            "Pavia": [
                "Codevilla",
                "Marcignago",
                "Pavia",
                "Siziano"
            ],
            "Perugia": [
                "Pianello",
                "Ponte Felcino",
                "Zanica"
            ],
            "Pesaro-Urbino": [
                "Gradara",
                "Monte Porzio",
                "Pergola",
                "Tavullia"
            ],
            "Piacenza": [
                "Alseno",
                "Gossolengo",
                "Vigolzone"
            ],
            "Piedmont": [
                "Armeno",
                "Bergamasco",
                "Caselette",
                "Rosta",
                "San Damiano",
                "Spinetta Marengo"
            ],
            "Piemonte": [
                "Acqui Terme",
                "Alba",
                "Alessandria",
                "Alpignano",
                "Andezeno",
                "Andonno",
                "Arona",
                "Asti",
                "Avigliana",
                "Baveno",
                "Beinasco",
                "Bellinzago Novarese",
                "Biella",
                "Borgaro Torinese",
                "Borgo San Dalmazzo",
                "Borgomanero",
                "Borgosesia",
                "Boves",
                "Bra",
                "Busca",
                "Cameri",
                "Canelli",
                "Carignano",
                "Carmagnola",
                "Casale Monferrato",
                "Caselle Torinese",
                "Castellamonte",
                "Castelletto sopra Ticino",
                "Chieri",
                "Chivasso",
                "Cirie",
                "Collegno",
                "Cossato",
                "Cuneo",
                "Cuorgne",
                "Domodossola",
                "Druento",
                "Fossano",
                "Galliate",
                "Gassino Torinese",
                "Gattinara",
                "Giaveno",
                "Grugliasco",
                "Ivrea",
                "Leini",
                "Lusigli\u00c3\u0192\u00c2\u0192\u00c3\u2020\u00c2\u2019\u00c3\u0192\u00c2\u201a\u00c3\u201a\u00c2\u00a8",
                "Marano Ticino",
                "Mergozzo",
                "Moncalieri",
                "Mondovi",
                "Mongrando",
                "Nichelino",
                "Nizza Monferrato",
                "Novara",
                "Novi Ligure",
                "Oleggio",
                "Omegna",
                "Orbassano",
                "Ovada",
                "Pianezza",
                "Pinerolo",
                "Pino Torinese",
                "Piossasco",
                "Poirino",
                "Racconigi",
                "Rivalta di Torino",
                "Rivarolo Canavese",
                "Rivoli",
                "Saluzzo",
                "San Maurizio",
                "San Mauro Torinese",
                "Sandigliano",
                "Santena",
                "Santhia",
                "Savigliano",
                "Settimo Torinese",
                "Torino",
                "Tortona",
                "Trecate",
                "Trofarello",
                "Valduggia",
                "Valenza",
                "Venaria Reale",
                "Verbania",
                "Vercelli",
                "Vigliano Biellese",
                "Vinovo",
                "Volpiano"
            ],
            "Pisa": [
                "Perignano",
                "Ponte a Egola"
            ],
            "Pordenone": [
                "San Quirino"
            ],
            "Potenza": [
                "Latronico"
            ],
            "Puglia": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Reggio Emilia": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Rimini": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Roma": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Salerno": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Sardegna": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Sassari": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Savona": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Sicilia": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Siena": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Sondrio": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "South Tyrol": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Taranto": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Teramo": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Torino": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Toscana": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Trapani": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Trentino-Alto Adige": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Trento": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Treviso": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Udine": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Umbria": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Valle d\\'Aosta": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Varese": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Veneto": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Venezia": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Verbano-Cusio-Ossola": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Vercelli": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Verona": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Vicenza": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Viterbo": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 108,
        "name": "Jamaica",
        "iso3": "JAM",
        "iso2": "JM",
        "phone_code": "+1-876",
        "capital": "Kingston",
        "currency": "JMD",
        "states": {
            "Buxoro Viloyati": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Clarendon": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Hanover": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kingston": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Manchester": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Portland": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Saint Andrews": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Saint Ann": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Saint Catherine": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Saint Elizabeth": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Saint James": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Saint Mary": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Saint Thomas": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Trelawney": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Westmoreland": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 109,
        "name": "Japan",
        "iso3": "JPN",
        "iso2": "JP",
        "phone_code": "81",
        "capital": "Tokyo",
        "currency": "JPY",
        "states": {
            "Aichi": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Akita": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Aomori": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Chiba": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Ehime": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Fukui": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Fukuoka": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Fukushima": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Gifu": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Gumma": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Hiroshima": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Hokkaido": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Hyogo": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Ibaraki": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Ishikawa": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Iwate": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kagawa": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kagoshima": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kanagawa": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kanto": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kochi": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kumamoto": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kyoto": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Mie": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Miyagi": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Miyazaki": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Nagano": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Nagasaki": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Nara": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Niigata": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Oita": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Okayama": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Okinawa": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Osaka": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Saga": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Saitama": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Shiga": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Shimane": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Shizuoka": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Tochigi": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Tokushima": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Tokyo": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Tottori": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Toyama": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Wakayama": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Yamagata": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Yamaguchi": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Yamanashi": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 110,
        "name": "Jersey",
        "iso3": "JEY",
        "iso2": "JE",
        "phone_code": "+44-1534",
        "capital": "Saint Helier",
        "currency": "GBP",
        "states": {
            "Grouville": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Saint Brelade": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Saint Clement": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Saint Helier": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Saint John": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Saint Lawrence": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Saint Martin": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Saint Mary": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Saint Peter": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Saint Saviour": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Trinity": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 111,
        "name": "Jordan",
        "iso3": "JOR",
        "iso2": "JO",
        "phone_code": "962",
        "capital": "Amman",
        "currency": "JOD",
        "states": {
            "al-Balqa\\'": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "al-Karak": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "al-Mafraq": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "al-\\'Aqabah": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Amman": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "at-Tafilah": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "az-Zarqa\\'": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Irbid": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Jarash": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Madaba": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Ma\\'an": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "\\'Ajlun": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 112,
        "name": "Kazakhstan",
        "iso3": "KAZ",
        "iso2": "KZ",
        "phone_code": "7",
        "capital": "Astana",
        "currency": "KZT",
        "states": {
            "Akmecet": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Akmola": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Aktobe": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Almati": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Atirau": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Batis Kazakstan": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Burlinsky Region": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Karagandi": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kostanay": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Mankistau": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Ontustik Kazakstan": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Pavlodar": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Sigis Kazakstan": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Soltustik Kazakstan": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Taraz": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 113,
        "name": "Kenya",
        "iso3": "KEN",
        "iso2": "KE",
        "phone_code": "254",
        "capital": "Nairobi",
        "currency": "KES",
        "states": {
            "Central": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Coast": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Eastern": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Nairobi": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "North Eastern": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Nyanza": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Rift Valley": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Western": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 114,
        "name": "Kiribati",
        "iso3": "KIR",
        "iso2": "KI",
        "phone_code": "686",
        "capital": "Tarawa",
        "currency": "AUD",
        "states": {
            "Abaiang": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Abemana": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Aranuka": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Arorae": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Banaba": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Beru": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Butaritari": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kiritimati": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kuria": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Maiana": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Makin": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Marakei": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Nikunau": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Nonouti": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Onotoa": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Phoenix Islands": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Tabiteuea North": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Tabiteuea South": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Tabuaeran": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Tamana": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Tarawa North": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Tarawa South": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Teraina": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 115,
        "name": "Korea North\n",
        "iso3": "PRK",
        "iso2": "KP",
        "phone_code": "850",
        "capital": "Pyongyang",
        "currency": "KPW"
    },
    {
        "id": 116,
        "name": "Korea South",
        "iso3": "KOR",
        "iso2": "KR",
        "phone_code": "82",
        "capital": "Seoul",
        "currency": "KRW",
        "states": {
            "Busan": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Cheju": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Chollabuk": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Chollanam": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Chungbuk": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Chungcheongbuk": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Chungcheongnam": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Chungnam": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Daegu": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Gangwon-do": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Goyang-si": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "gwangyoksi": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Gyeonggi-do": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Gyeongsang ": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Gyeongsangnam-do": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Incheon": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Jeju-Si": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Jeonbuk": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kangweon": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kwangju": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kyeonggi": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kyeongsangbuk": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kyeongsangnam": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kyonggi-do": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kyungbuk-Do": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kyunggi-Do": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Pusan": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Seoul": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Sudogwon": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Taegu": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Taejeon": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Taejon-gwangyoksi": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Ulsan": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Wonju": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 117,
        "name": "Kuwait",
        "iso3": "KWT",
        "iso2": "KW",
        "phone_code": "965",
        "capital": "Kuwait City",
        "currency": "KWD",
        "states": {
            "Al Asimah": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "al-Ahmadi": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "al-Farwaniyah": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "al-Jahra": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "al-Kuwayt": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Hawalli": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Mishref": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Qadesiya": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Safat": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Salmiya": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 118,
        "name": "Kyrgyzstan",
        "iso3": "KGZ",
        "iso2": "KG",
        "phone_code": "996",
        "capital": "Bishkek",
        "currency": "KGS",
        "states": {
            "Batken": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Bishkek": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Chui": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Issyk-Kul": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Jalal-Abad": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Naryn": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Osh": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Talas": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 119,
        "name": "Laos",
        "iso3": "LAO",
        "iso2": "LA",
        "phone_code": "856",
        "capital": "Vientiane",
        "currency": "LAK",
        "states": {
            "Attopu": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Bokeo": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Bolikhamsay": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Champasak": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Houaphanh": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Khammouane": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Luang Nam Tha": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Luang Prabang": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Oudomxay": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Phongsaly": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Saravan": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Savannakhet": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Sekong": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Viangchan Prefecture": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Viangchan Province": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Xaignabury": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Xiang Khuang": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 120,
        "name": "Latvia",
        "iso3": "LVA",
        "iso2": "LV",
        "phone_code": "371",
        "capital": "Riga",
        "currency": "EUR",
        "states": {
            "Aizkraukles": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Aluksnes": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Balvu": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Bauskas": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Cesu": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Daugavpils": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Daugavpils City": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Dobeles": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Gulbenes": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Jekabspils": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Jelgava": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Jelgavas": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Jurmala City": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kraslavas": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kuldigas": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Liepaja": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Liepajas": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Limbazhu": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Ludzas": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Madonas": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Ogres": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Preilu": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Rezekne": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Rezeknes": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Riga": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Rigas": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Saldus": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Talsu": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Tukuma": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Valkas": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Valmieras": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Ventspils": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Ventspils City": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 121,
        "name": "Lebanon",
        "iso3": "LBN",
        "iso2": "LB",
        "phone_code": "961",
        "capital": "Beirut",
        "currency": "LBP",
        "states": {
            "al-Biqa": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "al-Janub": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "an-Nabatiyah": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "ash-Shamal": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Beirut": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Jabal Lubnan": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Mohafazat Liban-Nord": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Mohafazat Mont-Liban": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Sidon": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 122,
        "name": "Lesotho",
        "iso3": "LSO",
        "iso2": "LS",
        "phone_code": "266",
        "capital": "Maseru",
        "currency": "LSL",
        "states": {
            "Berea": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Butha-Buthe": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Leribe": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Mafeteng": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Maseru": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Mohale\\'s Hoek": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Mokhotlong": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Qacha\\'s Nek": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Quthing": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Thaba-Tseka": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 123,
        "name": "Liberia",
        "iso3": "LBR",
        "iso2": "LR",
        "phone_code": "231",
        "capital": "Monrovia",
        "currency": "LRD",
        "states": {
            "Bomi": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Bong": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Grand Bassa": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Grand Cape Mount": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Grand Gedeh": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Loffa": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Margibi": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Maryland and Grand Kru": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Montserrado": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Nimba": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Rivercess": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Sinoe": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 124,
        "name": "Libya",
        "iso3": "LBY",
        "iso2": "LY",
        "phone_code": "218",
        "capital": "Tripolis",
        "currency": "LYD",
        "states": {
            "Ajdabiya": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "al-Fatih": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "al-Jabal al Akhdar": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "al-Jufrah": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "al-Khums": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "al-Kufrah": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "al-\\'Aziziyah": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "an-Nuqat al-Khams": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "ash-Shati\\'": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "az-Zawiyah": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Banghazi": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Darnah": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Ghadamis": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Gharyan": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Misratah": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Murzuq": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Sabha": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Sawfajjin": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Surt": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Tarabulus": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Tarhunah": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Tripolitania": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Tubruq": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Yafran": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Zlitan": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 125,
        "name": "Liechtenstein",
        "iso3": "LIE",
        "iso2": "LI",
        "phone_code": "423",
        "capital": "Vaduz",
        "currency": "CHF",
        "states": {
            "Balzers": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Eschen": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Gamprin": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Mauren": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Planken": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Ruggell": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Schaan": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Schellenberg": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Triesen": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Triesenberg": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Vaduz": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 126,
        "name": "Lithuania",
        "iso3": "LTU",
        "iso2": "LT",
        "phone_code": "370",
        "capital": "Vilnius",
        "currency": "LTL",
        "states": {
            "Alytaus": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Anyksciai": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kauno": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Klaipedos": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Marijampoles": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Panevezhio": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Panevezys": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Shiauliu": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Taurages": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Telshiu": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Telsiai": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Utenos": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Vilniaus": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 127,
        "name": "Luxembourg",
        "iso3": "LUX",
        "iso2": "LU",
        "phone_code": "352",
        "capital": "Luxembourg",
        "currency": "EUR",
        "states": {
            "Capellen": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Clervaux": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Diekirch": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Echternach": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Esch-sur-Alzette": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Grevenmacher": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Luxembourg": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Mersch": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Redange": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Remich": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Vianden": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Wiltz": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 128,
        "name": "Macau S.A.R.",
        "iso3": "MAC",
        "iso2": "MO",
        "phone_code": "853",
        "capital": "Macao",
        "currency": "MOP",
        "states": {
            "Macau": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 129,
        "name": "Macedonia",
        "iso3": "MKD",
        "iso2": "MK",
        "phone_code": "389",
        "capital": "Skopje",
        "currency": "MKD",
        "states": {
            "Berovo": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Bitola": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Brod": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Debar": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Delchevo": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Demir Hisar": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Gevgelija": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Gostivar": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kavadarci": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kichevo": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kochani": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kratovo": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kriva Palanka": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Krushevo": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kumanovo": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Negotino": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Ohrid": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Prilep": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Probishtip": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Radovish": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Resen": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Shtip": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Skopje": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Struga": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Strumica": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Sveti Nikole": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Tetovo": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Valandovo": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Veles": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Vinica": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 130,
        "name": "Madagascar",
        "iso3": "MDG",
        "iso2": "MG",
        "phone_code": "261",
        "capital": "Antananarivo",
        "currency": "MGA",
        "states": {
            "Antananarivo": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Antsiranana": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Fianarantsoa": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Mahajanga": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Toamasina": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Toliary": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 131,
        "name": "Malawi",
        "iso3": "MWI",
        "iso2": "MW",
        "phone_code": "265",
        "capital": "Lilongwe",
        "currency": "MWK",
        "states": {
            "Balaka": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Blantyre City": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Chikwawa": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Chiradzulu": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Chitipa": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Dedza": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Dowa": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Karonga": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kasungu": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Lilongwe City": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Machinga": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Mangochi": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Mchinji": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Mulanje": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Mwanza": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Mzimba": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Mzuzu City": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Nkhata Bay": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Nkhotakota": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Nsanje": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Ntcheu": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Ntchisi": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Phalombe": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Rumphi": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Salima": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Thyolo": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Zomba Municipality": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 132,
        "name": "Malaysia",
        "iso3": "MYS",
        "iso2": "MY",
        "phone_code": "60",
        "capital": "Kuala Lumpur",
        "currency": "MYR",
        "states": {
            "Johor": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kedah": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kelantan": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kuala Lumpur": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Labuan": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Melaka": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Negeri Johor": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Negeri Sembilan": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Pahang": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Penang": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Perak": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Perlis": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Pulau Pinang": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Sabah": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Sarawak": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Selangor": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Sembilan": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Terengganu": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 133,
        "name": "Maldives",
        "iso3": "MDV",
        "iso2": "MV",
        "phone_code": "960",
        "capital": "Male",
        "currency": "MVR",
        "states": {
            "Alif Alif": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Alif Dhaal": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Baa": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Dhaal": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Faaf": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Gaaf Alif": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Gaaf Dhaal": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Ghaviyani": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Haa Alif": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Haa Dhaal": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kaaf": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Laam": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Lhaviyani": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Male": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Miim": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Nuun": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Raa": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Shaviyani": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Siin": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Thaa": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Vaav": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 134,
        "name": "Mali",
        "iso3": "MLI",
        "iso2": "ML",
        "phone_code": "223",
        "capital": "Bamako",
        "currency": "XOF",
        "states": {
            "Bamako": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Gao": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kayes": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kidal": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Koulikoro": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Mopti": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Segou": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Sikasso": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Tombouctou": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 135,
        "name": "Malta",
        "iso3": "MLT",
        "iso2": "MT",
        "phone_code": "356",
        "capital": "Valletta",
        "currency": "EUR",
        "states": {
            "Gozo and Comino": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Inner Harbour": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Northern": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Outer Harbour": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "South Eastern": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Valletta": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Western": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 136,
        "name": "Man (Isle of)",
        "iso3": "IMN",
        "iso2": "IM",
        "phone_code": "+44-1624",
        "capital": "Isle of Man",
        "currency": "GBP",
        "states": {
            "Castletown": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Douglas": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Laxey": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Onchan": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Peel": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Port Erin": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Port Saint Mary": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Ramsey": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 137,
        "name": "Marshall Islands",
        "iso3": "MHL",
        "iso2": "MH",
        "phone_code": "692",
        "capital": "Majuro",
        "currency": "USD",
        "states": {
            "Ailinlaplap": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Ailuk": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Arno": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Aur": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Bikini": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Ebon": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Enewetak": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Jabat": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Jaluit": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kili": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kwajalein": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Lae": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Lib": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Likiep": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Majuro": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Maloelap": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Mejit": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Mili": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Namorik": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Namu": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Rongelap": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Ujae": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Utrik": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Wotho": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Wotje": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 138,
        "name": "Martinique",
        "iso3": "MTQ",
        "iso2": "MQ",
        "phone_code": "596",
        "capital": "Fort-de-France",
        "currency": "EUR",
        "states": {
            "Fort-de-France": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "La Trinite": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Le Marin": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Saint-Pierre": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 139,
        "name": "Mauritania",
        "iso3": "MRT",
        "iso2": "MR",
        "phone_code": "222",
        "capital": "Nouakchott",
        "currency": "MRO",
        "states": {
            "Adrar": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Assaba": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Brakna": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Dhakhlat Nawadibu": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Hudh-al-Gharbi": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Hudh-ash-Sharqi": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Inshiri": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Nawakshut": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Qidimagha": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Qurqul": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Taqant": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Tiris Zammur": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Trarza": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 140,
        "name": "Mauritius",
        "iso3": "MUS",
        "iso2": "MU",
        "phone_code": "230",
        "capital": "Port Louis",
        "currency": "MUR",
        "states": {
            "Black River": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Eau Coulee": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Flacq": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Floreal": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Grand Port": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Moka": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Pamplempousses": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Plaines Wilhelm": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Port Louis": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Riviere du Rempart": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Rodrigues": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Rose Hill": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Savanne": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 141,
        "name": "Mayotte",
        "iso3": "MYT",
        "iso2": "YT",
        "phone_code": "262",
        "capital": "Mamoudzou",
        "currency": "EUR",
        "states": {
            "Mayotte": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Pamanzi": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 142,
        "name": "Mexico",
        "iso3": "MEX",
        "iso2": "MX",
        "phone_code": "52",
        "capital": "Mexico City",
        "currency": "MXN",
        "states": {
            "Aguascalientes": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Baja California": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Baja California Sur": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Campeche": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Chiapas": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Chihuahua": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Coahuila": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Colima": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Distrito Federal": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Durango": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Estado de Mexico": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Guanajuato": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Guerrero": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Hidalgo": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Jalisco": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Mexico": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Michoacan": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Morelos": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Nayarit": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Nuevo Leon": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Oaxaca": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Puebla": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Queretaro": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Quintana Roo": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "San Luis Potosi": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Sinaloa": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Sonora": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Tabasco": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Tamaulipas": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Tlaxcala": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Veracruz": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Yucatan": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Zacatecas": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 143,
        "name": "Micronesia",
        "iso3": "FSM",
        "iso2": "FM",
        "phone_code": "691",
        "capital": "Palikir",
        "currency": "USD",
        "states": {
            "Chuuk": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kusaie": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Pohnpei": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Yap": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 144,
        "name": "Moldova",
        "iso3": "MDA",
        "iso2": "MD",
        "phone_code": "373",
        "capital": "Chisinau",
        "currency": "MDL",
        "states": {
            "Balti": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Cahul": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Chisinau": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Chisinau Oras": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Edinet": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Gagauzia": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Lapusna": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Orhei": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Soroca": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Taraclia": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Tighina": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Transnistria": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Ungheni": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 145,
        "name": "Monaco",
        "iso3": "MCO",
        "iso2": "MC",
        "phone_code": "377",
        "capital": "Monaco",
        "currency": "EUR",
        "states": {
            "Fontvieille": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "La Condamine": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Monaco-Ville": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Monte Carlo": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 146,
        "name": "Mongolia",
        "iso3": "MNG",
        "iso2": "MN",
        "phone_code": "976",
        "capital": "Ulan Bator",
        "currency": "MNT",
        "states": {
            "Arhangaj": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Bajan-Olgij": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Bajanhongor": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Bulgan": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Darhan-Uul": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Dornod": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Dornogovi": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Dundgovi": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Govi-Altaj": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Govisumber": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Hentij": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Hovd": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Hovsgol": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Omnogovi": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Orhon": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Ovorhangaj": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Selenge": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Suhbaatar": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Tov": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Ulaanbaatar": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Uvs": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Zavhan": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 147,
        "name": "Montenegro",
        "iso3": "MNE",
        "iso2": "ME",
        "phone_code": "382",
        "capital": "Podgorica",
        "currency": "EUR"
    },
    {
        "id": 148,
        "name": "Montserrat",
        "iso3": "MSR",
        "iso2": "MS",
        "phone_code": "+1-664",
        "capital": "Plymouth",
        "currency": "XCD",
        "states": {
            "Montserrat": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 149,
        "name": "Morocco",
        "iso3": "MAR",
        "iso2": "MA",
        "phone_code": "212",
        "capital": "Rabat",
        "currency": "MAD",
        "states": {
            "Agadir": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Casablanca": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Chaouia-Ouardigha": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Doukkala-Abda": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Fes-Boulemane": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Gharb-Chrarda-Beni Hssen": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Guelmim": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kenitra": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Marrakech-Tensift-Al Haouz": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Meknes-Tafilalet": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Oriental": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Oujda": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Province de Tanger": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Rabat-Sale-Zammour-Zaer": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Sala Al Jadida": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Settat": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Souss Massa-Draa": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Tadla-Azilal": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Tangier-Tetouan": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Taza-Al Hoceima-Taounate": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Wilaya de Casablanca": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Wilaya de Rabat-Sale": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 150,
        "name": "Mozambique",
        "iso3": "MOZ",
        "iso2": "MZ",
        "phone_code": "258",
        "capital": "Maputo",
        "currency": "MZN",
        "states": {
            "Cabo Delgado": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Gaza": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Inhambane": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Manica": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Maputo": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Maputo Provincia": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Nampula": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Niassa": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Sofala": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Tete": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Zambezia": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 151,
        "name": "Myanmar",
        "iso3": "MMR",
        "iso2": "MM",
        "phone_code": "95",
        "capital": "Nay Pyi Taw",
        "currency": "MMK",
        "states": {
            "Ayeyarwady": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Bago": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Chin": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kachin": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kayah": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kayin": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Magway": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Mandalay": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Mon": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Nay Pyi Taw": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Rakhine": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Sagaing": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Shan": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Tanintharyi": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Yangon": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 152,
        "name": "Namibia",
        "iso3": "NAM",
        "iso2": "NA",
        "phone_code": "264",
        "capital": "Windhoek",
        "currency": "NAD",
        "states": {
            "Caprivi": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Erongo": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Hardap": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Karas": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kavango": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Khomas": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kunene": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Ohangwena": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Omaheke": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Omusati": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Oshana": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Oshikoto": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Otjozondjupa": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 153,
        "name": "Nauru",
        "iso3": "NRU",
        "iso2": "NR",
        "phone_code": "674",
        "capital": "Yaren",
        "currency": "AUD",
        "states": {
            "Yaren": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 154,
        "name": "Nepal",
        "iso3": "NPL",
        "iso2": "NP",
        "phone_code": "977",
        "capital": "Kathmandu",
        "currency": "NPR",
        "states": {
            "Bagmati": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Bheri": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Dhawalagiri": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Gandaki": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Janakpur": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Karnali": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Koshi": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Lumbini": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Mahakali": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Mechi": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Narayani": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Rapti": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Sagarmatha": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Seti": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 155,
        "name": "Netherlands Antilles",
        "iso3": "ANT",
        "iso2": "AN",
        "phone_code": "",
        "capital": "",
        "currency": "",
        "states": {
            "Bonaire": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Curacao": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Saba": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Sint Eustatius": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Sint Maarten": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 156,
        "name": "Netherlands The",
        "iso3": "NLD",
        "iso2": "NL",
        "phone_code": "31",
        "capital": "Amsterdam",
        "currency": "EUR",
        "states": {
            "Amsterdam": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Benelux": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Drenthe": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Flevoland": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Friesland": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Gelderland": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Groningen": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Limburg": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Noord-Brabant": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Noord-Holland": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Overijssel": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "South Holland": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Utrecht": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Zeeland": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Zuid-Holland": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 157,
        "name": "New Caledonia",
        "iso3": "NCL",
        "iso2": "NC",
        "phone_code": "687",
        "capital": "Noumea",
        "currency": "XPF",
        "states": {
            "Iles": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Nord": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Sud": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 158,
        "name": "New Zealand",
        "iso3": "NZL",
        "iso2": "NZ",
        "phone_code": "64",
        "capital": "Wellington",
        "currency": "NZD",
        "states": {
            "Area Outside Region": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Auckland": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Bay of Plenty": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Canterbury": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Christchurch": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Gisborne": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Hawke\\'s Bay": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Manawatu-Wanganui": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Marlborough": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Nelson": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Northland": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Otago": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Rodney": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Southland": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Taranaki": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Tasman": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Waikato": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Wellington": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "West Coast": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 159,
        "name": "Nicaragua",
        "iso3": "NIC",
        "iso2": "NI",
        "phone_code": "505",
        "capital": "Managua",
        "currency": "NIO",
        "states": {
            "Atlantico Norte": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Atlantico Sur": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Boaco": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Carazo": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Chinandega": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Chontales": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Esteli": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Granada": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Jinotega": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Leon": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Madriz": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Managua": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Masaya": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Matagalpa": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Nueva Segovia": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Rio San Juan": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Rivas": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 160,
        "name": "Niger",
        "iso3": "NER",
        "iso2": "NE",
        "phone_code": "227",
        "capital": "Niamey",
        "currency": "XOF",
        "states": {
            "Agadez": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Diffa": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Dosso": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Maradi": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Niamey": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Tahoua": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Tillabery": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Zinder": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 161,
        "name": "Nigeria",
        "iso3": "NGA",
        "iso2": "NG",
        "phone_code": "234",
        "capital": "Abuja",
        "currency": "NGN",
        "states": {
            "Abia": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Abuja Federal Capital Territor": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Adamawa": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Akwa Ibom": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Anambra": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Bauchi": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Bayelsa": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Benue": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Borno": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Cross River": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Delta": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Ebonyi": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Edo": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Ekiti": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Enugu": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Gombe": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Imo": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Jigawa": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kaduna": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kano": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Katsina": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kebbi": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kogi": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Kwara": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Lagos": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Nassarawa": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Niger": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Ogun": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Ondo": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Osun": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Oyo": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Plateau": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Rivers": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Sokoto": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Taraba": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Yobe": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Zamfara": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 162,
        "name": "Niue",
        "iso3": "NIU",
        "iso2": "NU",
        "phone_code": "683",
        "capital": "Alofi",
        "currency": "NZD",
        "states": {
            "Niue": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 163,
        "name": "Norfolk Island",
        "iso3": "NFK",
        "iso2": "NF",
        "phone_code": "672",
        "capital": "Kingston",
        "currency": "AUD",
        "states": {
            "Norfolk Island": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 164,
        "name": "Northern Mariana Islands",
        "iso3": "MNP",
        "iso2": "MP",
        "phone_code": "+1-670",
        "capital": "Saipan",
        "currency": "USD",
        "states": {
            "Northern Islands": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Rota": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Saipan": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Tinian": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 165,
        "name": "Norway",
        "iso3": "NOR",
        "iso2": "NO",
        "phone_code": "47",
        "capital": "Oslo",
        "currency": "NOK",
        "states": {
            "Akershus": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Aust Agder": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "\u00c3\u0192\u00c2\u0192\u00c3\u2020\u00c2\u2019\u00c3\u0192\u00c2\u201a\u00c3\u2039\u00c2\u0153stfold": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Bergen": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Buskerud": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Finnmark": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Hedmark": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Hordaland": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Moere og Romsdal": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Nord Trondelag": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Nordland": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Oestfold": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Oppland": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Oslo": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Rogaland": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Soer Troendelag": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Sogn og Fjordane": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Stavern": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Sykkylven": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Telemark": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Troms": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Vest Agder": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Vestfold": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 166,
        "name": "Oman",
        "iso3": "OMN",
        "iso2": "OM",
        "phone_code": "968",
        "capital": "Muscat",
        "currency": "OMR",
        "states": {
            "ad-Dakhiliyah": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "adh-Dhahirah": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Al Buraimi": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "al-Batinah": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "ash-Sharqiyah": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Dhufar": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Masqat": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Musandam": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Rusayl": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Wadi Kabir": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ]
        }
    },
    {
        "id": 167,
        "name": "Pakistan",
        "iso3": "PAK",
        "iso2": "PK",
        "phone_code": "92",
        "capital": "Islamabad",
        "currency": "PKR",
        "states": {
            "Baluchistan": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Federal Capital Area": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Federally administered Tribal ": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "North-West Frontier": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Northern Areas": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Punjab": [
                "Acquaviva delle Fonti",
                "Adelfia",
                "Alberobello",
                "Altamura",
                "Andria",
                "Apricena",
                "Aradeo",
                "Bari",
                "Barletta",
                "Bisceglie",
                "Bitetto",
                "Bitonto",
                "Bitritto",
                "Brindisi",
                "Campi Salentina",
                "Canosa di Puglia",
                "Capurso",
                "Carmiano",
                "Carovigno",
                "Casamassima",
                "Casarano",
                "Cassano delle Murge",
                "Castellana Grotte",
                "Castellaneta",
                "Cavallino",
                "Ceglie Messapica",
                "Cerignola",
                "Cisternino",
                "Conversano",
                "Copertino",
                "Corato",
                "Crispiano",
                "Cutrofiano",
                "Erchie",
                "Fasano",
                "Foggia",
                "Francavilla Fontana",
                "Galatina",
                "Galatone",
                "Gallipoli",
                "Ginosa",
                "Gioia del Colle",
                "Giovinazzo",
                "Gravina in Puglia",
                "Grottaglie",
                "Grumo Appula",
                "Laterza",
                "Latiano",
                "Lecce",
                "Leverano",
                "Lizzanello",
                "Lizzano",
                "Locorotondo",
                "Lucera",
                "Maglie",
                "Manduria",
                "Manfredonia",
                "Margherita di Savoia",
                "Martano",
                "Martina Franca",
                "Massafra",
                "Matino",
                "Melendugno",
                "Mesagne",
                "Minervino Murge",
                "Modugno",
                "Mola di Bari",
                "Molfetta",
                "Monopoli",
                "Monte Sant\\'Angelo",
                "Monteroni di Lecce",
                "Mottola",
                "Nardo",
                "Neviano",
                "Noci",
                "Noicattaro",
                "Novoli",
                "Oria",
                "Orta Nova",
                "Ostuni",
                "Palagiano",
                "Palo del Colle",
                "Parabita",
                "Polignano a Mare",
                "Pulsano",
                "Putignano"
            ],
            "Sind": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 168,
        "name": "Palau",
        "iso3": "PLW",
        "iso2": "PW",
        "phone_code": "680",
        "capital": "Melekeok",
        "currency": "USD",
        "states": {
            "Aimeliik": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Airai": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Angaur": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Hatobohei": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kayangel": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Koror": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Melekeok": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ngaraard": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ngardmau": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ngaremlengui": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ngatpang": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ngchesar": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ngerchelong": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ngiwal": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Peleliu": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Sonsorol": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 169,
        "name": "Palestinian Territory Occupied",
        "iso3": "PSE",
        "iso2": "PS",
        "phone_code": "970",
        "capital": "East Jerusalem",
        "currency": "ILS",
        "states": {
            "al-Khalil": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "al-Quds": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ariha": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Bayt Lahm": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Bethlehem": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Dayr-al-Balah": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ghazzah": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ghazzah ash-Shamaliyah": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Janin": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Khan Yunis": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Nabulus": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Qalqilyah": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Rafah": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ram Allah wal-Birah": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Salfit": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Tubas": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Tulkarm": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 170,
        "name": "Panama",
        "iso3": "PAN",
        "iso2": "PA",
        "phone_code": "507",
        "capital": "Panama City",
        "currency": "PAB",
        "states": {
            "Bocas del Toro": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Chiriqui": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Cocle": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Colon": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Darien": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Embera": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Herrera": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kuna Yala": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Los Santos": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ngobe Bugle": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Panama": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Veraguas": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 171,
        "name": "Papua new Guinea",
        "iso3": "PNG",
        "iso2": "PG",
        "phone_code": "675",
        "capital": "Port Moresby",
        "currency": "PGK",
        "states": {
            "East New Britain": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "East Sepik": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Eastern Highlands": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Enga": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Fly River": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Gulf": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Madang": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Manus": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Milne Bay": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Morobe": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "National Capital District": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "New Ireland": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "North Solomons": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Oro": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Sandaun": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Simbu": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Southern Highlands": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "West New Britain": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Western Highlands": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 172,
        "name": "Paraguay",
        "iso3": "PRY",
        "iso2": "PY",
        "phone_code": "595",
        "capital": "Asuncion",
        "currency": "PYG",
        "states": {
            "Alto Paraguay": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Alto Parana": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Amambay": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Asuncion": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Boqueron": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Caaguazu": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Caazapa": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Canendiyu": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Central": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Concepcion": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Cordillera": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Guaira": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Itapua": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Misiones": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Neembucu": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Paraguari": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Presidente Hayes": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "San Pedro": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 173,
        "name": "Peru",
        "iso3": "PER",
        "iso2": "PE",
        "phone_code": "51",
        "capital": "Lima",
        "currency": "PEN",
        "states": {
            "Amazonas": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ancash": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Apurimac": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Arequipa": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ayacucho": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Cajamarca": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Cusco": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Huancavelica": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Huanuco": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ica": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Junin": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "La Libertad": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Lambayeque": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Lima y Callao": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Loreto": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Madre de Dios": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Moquegua": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Pasco": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Piura": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Puno": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "San Martin": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Tacna": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Tumbes": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ucayali": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 174,
        "name": "Philippines",
        "iso3": "PHL",
        "iso2": "PH",
        "phone_code": "63",
        "capital": "Manila",
        "currency": "PHP",
        "states": {
            "Batangas": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Bicol": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Bulacan": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Cagayan": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Caraga": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Central Luzon": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Central Mindanao": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Central Visayas": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Cordillera": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Davao": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Eastern Visayas": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Greater Metropolitan Area": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ilocos": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Laguna": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Luzon": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Mactan": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Metropolitan Manila Area": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Muslim Mindanao": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Northern Mindanao": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Southern Mindanao": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Southern Tagalog": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Western Mindanao": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Western Visayas": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 175,
        "name": "Pitcairn Island",
        "iso3": "PCN",
        "iso2": "PN",
        "phone_code": "870",
        "capital": "Adamstown",
        "currency": "NZD",
        "states": {
            "Pitcairn Island": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 176,
        "name": "Poland",
        "iso3": "POL",
        "iso2": "PL",
        "phone_code": "48",
        "capital": "Warsaw",
        "currency": "PLN",
        "states": {
            "Biale Blota": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Dobroszyce": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Dolnoslaskie": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Dziekanow Lesny": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Hopowo": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kartuzy": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Koscian": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Krakow": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kujawsko-Pomorskie": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Lodzkie": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Lubelskie": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Lubuskie": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Malomice": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Malopolskie": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Mazowieckie": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Mirkow": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Opolskie": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ostrowiec": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Podkarpackie": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Podlaskie": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Polska": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Pomorskie": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Poznan": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Pruszkow": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Rymanowska": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Rzeszow": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Slaskie": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Stare Pole": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Swietokrzyskie": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Warminsko-Mazurskie": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Warsaw": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Wejherowo": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Wielkopolskie": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Wroclaw": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Zachodnio-Pomorskie": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Zukowo": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 177,
        "name": "Portugal",
        "iso3": "PRT",
        "iso2": "PT",
        "phone_code": "351",
        "capital": "Lisbon",
        "currency": "EUR",
        "states": {
            "Abrantes": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Acores": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Alentejo": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Algarve": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Braga": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Centro": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Distrito de Leiria": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Distrito de Viana do Castelo": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Distrito de Vila Real": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Distrito do Porto": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Lisboa e Vale do Tejo": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Madeira": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Norte": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Paivas": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 178,
        "name": "Puerto Rico",
        "iso3": "PRI",
        "iso2": "PR",
        "phone_code": "+1-787 and 1-939",
        "capital": "San Juan",
        "currency": "USD",
        "states": {
            "Arecibo": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Bayamon": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Carolina": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Florida": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Guayama": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Humacao": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Mayaguez-Aguadilla": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ponce": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Salinas": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "San Juan": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 179,
        "name": "Qatar",
        "iso3": "QAT",
        "iso2": "QA",
        "phone_code": "974",
        "capital": "Doha",
        "currency": "QAR",
        "states": {
            "ad-Dawhah": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "al-Ghuwayriyah": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "al-Jumayliyah": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "al-Khawr": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "al-Wakrah": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "ar-Rayyan": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "ash-Shamal": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Doha": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Jarian-al-Batnah": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Umm Salal": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 180,
        "name": "Reunion",
        "iso3": "REU",
        "iso2": "RE",
        "phone_code": "262",
        "capital": "Saint-Denis",
        "currency": "EUR",
        "states": {
            "Saint-Benoit": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Saint-Denis": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Saint-Paul": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Saint-Pierre": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 181,
        "name": "Romania",
        "iso3": "ROU",
        "iso2": "RO",
        "phone_code": "40",
        "capital": "Bucharest",
        "currency": "RON",
        "states": {
            "Alba": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Arad": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Arges": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Bacau": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Bihor": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Bistrita-Nasaud": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Botosani": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Braila": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Brasov": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Bucuresti": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Buzau": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Calarasi": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Caras-Severin": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Cluj": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Constanta": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Covasna": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Dambovita": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Dolj": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Galati": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Giurgiu": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Gorj": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Harghita": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Hunedoara": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ialomita": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Iasi": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ilfov": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Maramures": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Mehedinti": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Mures": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Neamt": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Olt": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Prahova": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Salaj": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Satu Mare": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Sibiu": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Sondelor": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Suceava": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Teleorman": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Timis": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Tulcea": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Valcea": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Vaslui": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Vrancea": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 182,
        "name": "Russia",
        "iso3": "RUS",
        "iso2": "RU",
        "phone_code": "7",
        "capital": "Moscow",
        "currency": "RUB",
        "states": {
            "Adygeja": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Aga": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Alanija": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Altaj": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Amur": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Arhangelsk": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Astrahan": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Bashkortostan": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Belgorod": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Brjansk": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Burjatija": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Chechenija": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Cheljabinsk": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Chita": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Chukotka": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Chuvashija": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Dagestan": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Evenkija": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Gorno-Altaj": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Habarovsk": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Hakasija": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Hanty-Mansija": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ingusetija": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Irkutsk": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ivanovo": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Jamalo-Nenets": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Jaroslavl": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Jevrej": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kabardino-Balkarija": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kaliningrad": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kalmykija": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kaluga": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kamchatka": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Karachaj-Cherkessija": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Karelija": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kemerovo": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Khabarovskiy Kray": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kirov": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Komi": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Komi-Permjakija": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Korjakija": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kostroma": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Krasnodar": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Krasnojarsk": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Krasnoyarskiy Kray": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kurgan": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kursk": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Leningrad": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Lipeck": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Magadan": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Marij El": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Mordovija": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Moscow": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Moskovskaja Oblast": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Moskovskaya Oblast": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Moskva": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Murmansk": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Nenets": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Nizhnij Novgorod": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Novgorod": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Novokusnezk": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Novosibirsk": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Omsk": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Orenburg": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Orjol": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Penza": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Perm": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Primorje": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Pskov": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Pskovskaya Oblast": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Rjazan": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Rostov": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Saha": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Sahalin": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Samara": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Samarskaya": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Sankt-Peterburg": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Saratov": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Smolensk": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Stavropol": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Sverdlovsk": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Tajmyrija": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Tambov": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Tatarstan": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Tjumen": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Tomsk": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Tula": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Tver": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Tyva": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Udmurtija": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Uljanovsk": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ulyanovskaya Oblast": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ust-Orda": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Vladimir": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Volgograd": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Vologda": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Voronezh": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 183,
        "name": "Rwanda",
        "iso3": "RWA",
        "iso2": "RW",
        "phone_code": "250",
        "capital": "Kigali",
        "currency": "RWF",
        "states": {
            "Butare": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Byumba": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Cyangugu": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Gikongoro": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Gisenyi": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Gitarama": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kibungo": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kibuye": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kigali-ngali": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ruhengeri": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 184,
        "name": "Saint Helena",
        "iso3": "SHN",
        "iso2": "SH",
        "phone_code": "290",
        "capital": "Jamestown",
        "currency": "SHP",
        "states": {
            "Ascension": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Gough Island": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Saint Helena": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Tristan da Cunha": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 185,
        "name": "Saint Kitts And Nevis",
        "iso3": "KNA",
        "iso2": "KN",
        "phone_code": "+1-869",
        "capital": "Basseterre",
        "currency": "XCD",
        "states": {
            "Christ Church Nichola Town": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Saint Anne Sandy Point": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Saint George Basseterre": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Saint George Gingerland": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Saint James Windward": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Saint John Capesterre": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Saint John Figtree": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Saint Mary Cayon": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Saint Paul Capesterre": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Saint Paul Charlestown": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Saint Peter Basseterre": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Saint Thomas Lowland": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Saint Thomas Middle Island": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Trinity Palmetto Point": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 186,
        "name": "Saint Lucia",
        "iso3": "LCA",
        "iso2": "LC",
        "phone_code": "+1-758",
        "capital": "Castries",
        "currency": "XCD",
        "states": {
            "Anse-la-Raye": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Canaries": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Castries": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Choiseul": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Dennery": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Gros Inlet": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Laborie": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Micoud": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Soufriere": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Vieux Fort": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 187,
        "name": "Saint Pierre and Miquelon",
        "iso3": "SPM",
        "iso2": "PM",
        "phone_code": "508",
        "capital": "Saint-Pierre",
        "currency": "EUR",
        "states": {
            "Miquelon-Langlade": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Saint-Pierre": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 188,
        "name": "Saint Vincent And The Grenadines",
        "iso3": "VCT",
        "iso2": "VC",
        "phone_code": "+1-784",
        "capital": "Kingstown",
        "currency": "XCD",
        "states": {
            "Charlotte": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Grenadines": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Saint Andrew": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Saint David": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Saint George": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Saint Patrick": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 189,
        "name": "Saint-Barthelemy",
        "iso3": "BLM",
        "iso2": "BL",
        "phone_code": "590",
        "capital": "Gustavia",
        "currency": "EUR"
    },
    {
        "id": 190,
        "name": "Saint-Martin (French part)",
        "iso3": "MAF",
        "iso2": "MF",
        "phone_code": "590",
        "capital": "Marigot",
        "currency": "EUR"
    },
    {
        "id": 191,
        "name": "Samoa",
        "iso3": "WSM",
        "iso2": "WS",
        "phone_code": "685",
        "capital": "Apia",
        "currency": "WST",
        "states": {
            "Aiga-i-le-Tai": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Atua": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "A\\'ana": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Fa\\'asaleleaga": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Gagaifomauga": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Gaga\\'emauga": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Palauli": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Satupa\\'itea": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Tuamasaga": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Vaisigano": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Va\\'a-o-Fonoti": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 192,
        "name": "San Marino",
        "iso3": "SMR",
        "iso2": "SM",
        "phone_code": "378",
        "capital": "San Marino",
        "currency": "EUR",
        "states": {
            "Acquaviva": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Borgo Maggiore": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Chiesanuova": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Domagnano": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Faetano": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Fiorentino": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Montegiardino": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "San Marino": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Serravalle": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 193,
        "name": "Sao Tome and Principe",
        "iso3": "STP",
        "iso2": "ST",
        "phone_code": "239",
        "capital": "Sao Tome",
        "currency": "STD",
        "states": {
            "Agua Grande": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Cantagalo": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Lemba": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Lobata": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Me-Zochi": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Pague": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 194,
        "name": "Saudi Arabia",
        "iso3": "SAU",
        "iso2": "SA",
        "phone_code": "966",
        "capital": "Riyadh",
        "currency": "SAR",
        "states": {
            "Al Khobar": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "al-Bahah": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "al-Hudud-ash-Shamaliyah": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "al-Madinah": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "ar-Riyad": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Aseer": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ash Sharqiyah": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Asir": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Central Province": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Eastern Province": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ha\\'il": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Jawf": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Jizan": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Makkah": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Najran": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Qasim": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Tabuk": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Western Province": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 195,
        "name": "Senegal",
        "iso3": "SEN",
        "iso2": "SN",
        "phone_code": "221",
        "capital": "Dakar",
        "currency": "XOF",
        "states": {
            "Dakar": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Diourbel": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Fatick": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kaolack": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kolda": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Louga": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Saint-Louis": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Tambacounda": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Thies": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ziguinchor": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 196,
        "name": "Serbia",
        "iso3": "SRB",
        "iso2": "RS",
        "phone_code": "381",
        "capital": "Belgrade",
        "currency": "RSD",
        "states": {
            "Central Serbia": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kosovo and Metohija": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Vojvodina": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 197,
        "name": "Seychelles",
        "iso3": "SYC",
        "iso2": "SC",
        "phone_code": "248",
        "capital": "Victoria",
        "currency": "SCR",
        "states": {
            "Anse Boileau": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Anse Royale": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Cascade": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Takamaka": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Victoria": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 198,
        "name": "Sierra Leone",
        "iso3": "SLE",
        "iso2": "SL",
        "phone_code": "232",
        "capital": "Freetown",
        "currency": "SLL",
        "states": {
            "Eastern": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Northern": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Southern": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Western": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 199,
        "name": "Singapore",
        "iso3": "SGP",
        "iso2": "SG",
        "phone_code": "65",
        "capital": "Singapur",
        "currency": "SGD",
        "states": {
            "Singapore": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 200,
        "name": "Slovakia",
        "iso3": "SVK",
        "iso2": "SK",
        "phone_code": "421",
        "capital": "Bratislava",
        "currency": "EUR",
        "states": {
            "Banskobystricky": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Bratislavsky": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kosicky": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Nitriansky": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Presovsky": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Trenciansky": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Trnavsky": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Zilinsky": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 201,
        "name": "Slovenia",
        "iso3": "SVN",
        "iso2": "SI",
        "phone_code": "386",
        "capital": "Ljubljana",
        "currency": "EUR",
        "states": {
            "Benedikt": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Gorenjska": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Gorishka": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Jugovzhodna Slovenija": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Koroshka": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Notranjsko-krashka": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Obalno-krashka": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Obcina Domzale": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Obcina Vitanje": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Osrednjeslovenska": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Podravska": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Pomurska": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Savinjska": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Slovenian Littoral": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Spodnjeposavska": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Zasavska": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 202,
        "name": "Solomon Islands",
        "iso3": "SLB",
        "iso2": "SB",
        "phone_code": "677",
        "capital": "Honiara",
        "currency": "SBD",
        "states": {
            "Central": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Choiseul": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Guadalcanal": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Isabel": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Makira and Ulawa": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Malaita": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Rennell and Bellona": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Temotu": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Western": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 203,
        "name": "Somalia",
        "iso3": "SOM",
        "iso2": "SO",
        "phone_code": "252",
        "capital": "Mogadishu",
        "currency": "SOS",
        "states": {
            "Awdal": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Bakol": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Banadir": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Bari": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Bay": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Galgudug": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Gedo": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Hiran": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Jubbada Hose": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Jubbadha Dexe": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Mudug": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Nugal": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Sanag": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Shabellaha Dhexe": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Shabellaha Hose": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Togdher": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Woqoyi Galbed": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 204,
        "name": "South Africa",
        "iso3": "ZAF",
        "iso2": "ZA",
        "phone_code": "27",
        "capital": "Pretoria",
        "currency": "ZAR",
        "states": {
            "Eastern Cape": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Free State": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Gauteng": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kempton Park": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kramerville": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "KwaZulu Natal": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Limpopo": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Mpumalanga": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "North West": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Northern Cape": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Parow": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Table View": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Umtentweni": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Western Cape": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 205,
        "name": "South Georgia",
        "iso3": "SGS",
        "iso2": "GS",
        "phone_code": "",
        "capital": "Grytviken",
        "currency": "GBP",
        "states": {
            "South Georgia": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 206,
        "name": "South Sudan",
        "iso3": "SSD",
        "iso2": "SS",
        "phone_code": "211",
        "capital": "Juba",
        "currency": "SSP",
        "states": {
            "Central Equatoria": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 207,
        "name": "Spain",
        "iso3": "ESP",
        "iso2": "ES",
        "phone_code": "34",
        "capital": "Madrid",
        "currency": "EUR",
        "states": {
            "A Coruna": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Alacant": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Alava": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Albacete": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Almeria": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Andalucia": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Asturias": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Avila": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Badajoz": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Balears": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Barcelona": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Bertamirans": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Biscay": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Burgos": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Caceres": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Cadiz": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Cantabria": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Castello": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Catalunya": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ceuta": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ciudad Real": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Comunidad Autonoma de Canarias": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Comunidad Autonoma de Cataluna": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Comunidad Autonoma de Galicia": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Comunidad Autonoma de las Isla": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Comunidad Autonoma del Princip": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Comunidad Valenciana": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Cordoba": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Cuenca": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Gipuzkoa": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Girona": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Granada": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Guadalajara": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Guipuzcoa": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Huelva": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Huesca": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Jaen": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "La Rioja": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Las Palmas": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Leon": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Lerida": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Lleida": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Lugo": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Madrid": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Malaga": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Melilla": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Murcia": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Navarra": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ourense": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Pais Vasco": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Palencia": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Pontevedra": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Salamanca": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Santa Cruz de Tenerife": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Segovia": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Sevilla": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Soria": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Tarragona": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Tenerife": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Teruel": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Toledo": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Valencia": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Valladolid": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Vizcaya": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Zamora": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Zaragoza": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 208,
        "name": "Sri Lanka",
        "iso3": "LKA",
        "iso2": "LK",
        "phone_code": "94",
        "capital": "Colombo",
        "currency": "LKR",
        "states": {
            "Amparai": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Anuradhapuraya": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Badulla": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Boralesgamuwa": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Colombo": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Galla": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Gampaha": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Hambantota": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "kadawatha": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kalatura": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kegalla": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kilinochchi": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kurunegala": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Madakalpuwa": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Maha Nuwara": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Malwana": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Mannarama": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Matale": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Matara": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Monaragala": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Mullaitivu": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "North Eastern Province": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "North Western Province": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Nuwara Eliya": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Polonnaruwa": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Puttalama": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ratnapuraya": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Southern Province": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Tirikunamalaya": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Tuscany": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Vavuniyawa": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Western Province": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Yapanaya": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 209,
        "name": "Sudan",
        "iso3": "SDN",
        "iso2": "SD",
        "phone_code": "249",
        "capital": "Khartoum",
        "currency": "SDG",
        "states": {
            "al-Bahr-al-Ahmar": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "al-Buhayrat": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "al-Jazirah": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "al-Khartum": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "al-Qadarif": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "al-Wahdah": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "an-Nil-al-Abyad": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "an-Nil-al-Azraq": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "ash-Shamaliyah": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "A\\'ali-an-Nil": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Bahr-al-Jabal": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Central Equatoria": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Gharb Bahr-al-Ghazal": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Gharb Darfur": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Gharb Kurdufan": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Gharb-al-Istiwa\\'iyah": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Janub Darfur": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Janub Kurdufan": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Junqali": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kassala": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Nahr-an-Nil": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Shamal Bahr-al-Ghazal": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Shamal Darfur": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Shamal Kurdufan": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Sharq-al-Istiwa\\'iyah": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Sinnar": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Warab": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Wilayat al Khartum": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 210,
        "name": "Suriname",
        "iso3": "SUR",
        "iso2": "SR",
        "phone_code": "597",
        "capital": "Paramaribo",
        "currency": "SRD",
        "states": {
            "Brokopondo": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Commewijne": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Coronie": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Marowijne": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Nickerie": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Para": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Paramaribo": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Saramacca": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Wanica": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 211,
        "name": "Svalbard And Jan Mayen Islands",
        "iso3": "SJM",
        "iso2": "SJ",
        "phone_code": "47",
        "capital": "Longyearbyen",
        "currency": "NOK",
        "states": {
            "Svalbard": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 212,
        "name": "Swaziland",
        "iso3": "SWZ",
        "iso2": "SZ",
        "phone_code": "268",
        "capital": "Mbabane",
        "currency": "SZL",
        "states": {
            "Hhohho": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Lubombo": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Manzini": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Shiselweni": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 213,
        "name": "Sweden",
        "iso3": "SWE",
        "iso2": "SE",
        "phone_code": "46",
        "capital": "Stockholm",
        "currency": "SEK",
        "states": {
            "Alvsborgs Lan": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Angermanland": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Blekinge": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Bohuslan": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Dalarna": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Gavleborg": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Gaza": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Gotland": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Halland": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Jamtland": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Jonkoping": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kalmar": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kristianstads": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kronoberg": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Norrbotten": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Orebro": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ostergotland": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Saltsjo-Boo": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Skane": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Smaland": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Sodermanland": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Stockholm": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Uppsala": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Varmland": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Vasterbotten": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Vastergotland": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Vasternorrland": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Vastmanland": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Vastra Gotaland": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 214,
        "name": "Switzerland",
        "iso3": "CHE",
        "iso2": "CH",
        "phone_code": "41",
        "capital": "Berne",
        "currency": "CHF",
        "states": {
            "Aargau": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Appenzell Inner-Rhoden": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Appenzell-Ausser Rhoden": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Basel-Landschaft": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Basel-Stadt": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Bern": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Canton Ticino": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Fribourg": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Geneve": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Glarus": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Graubunden": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Heerbrugg": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Jura": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kanton Aargau": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Luzern": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Morbio Inferiore": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Muhen": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Neuchatel": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Nidwalden": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Obwalden": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Sankt Gallen": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Schaffhausen": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Schwyz": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Solothurn": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Thurgau": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ticino": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Uri": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Valais": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Vaud": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Vauffelin": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Zug": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Zurich": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 215,
        "name": "Syria",
        "iso3": "SYR",
        "iso2": "SY",
        "phone_code": "963",
        "capital": "Damascus",
        "currency": "SYP",
        "states": {
            "al-Hasakah": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "al-Ladhiqiyah": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "al-Qunaytirah": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Aleppo": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "ar-Raqqah": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "as-Suwayda": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Dar\\'a": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Dayr-az-Zawr": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Dimashq": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Halab": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Hamah": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Hims": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Idlib": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Madinat Dimashq": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Tartus": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 216,
        "name": "Taiwan",
        "iso3": "TWN",
        "iso2": "TW",
        "phone_code": "886",
        "capital": "Taipei",
        "currency": "TWD",
        "states": {
            "Changhwa": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Chiayi Hsien": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Chiayi Shih": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Eastern Taipei": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Hsinchu Hsien": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Hsinchu Shih": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Hualien": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ilan": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kaohsiung Hsien": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kaohsiung Shih": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Keelung Shih": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kinmen": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Miaoli": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Nantou": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Northern Taiwan": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Penghu": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Pingtung": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Taichung": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Taichung Hsien": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Taichung Shih": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Tainan Hsien": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Tainan Shih": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Taipei Hsien": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Taipei Shih \/ Taipei Hsien": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Taitung": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Taoyuan": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Yilan": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Yun-Lin Hsien": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Yunlin": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 217,
        "name": "Tajikistan",
        "iso3": "TJK",
        "iso2": "TJ",
        "phone_code": "992",
        "capital": "Dushanbe",
        "currency": "TJS",
        "states": {
            "Dushanbe": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Gorno-Badakhshan": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Karotegin": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Khatlon": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Sughd": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 218,
        "name": "Tanzania",
        "iso3": "TZA",
        "iso2": "TZ",
        "phone_code": "255",
        "capital": "Dodoma",
        "currency": "TZS",
        "states": {
            "Arusha": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Dar es Salaam": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Dodoma": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Iringa": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kagera": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kigoma": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kilimanjaro": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Lindi": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Mara": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Mbeya": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Morogoro": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Mtwara": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Mwanza": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Pwani": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Rukwa": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ruvuma": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Shinyanga": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Singida": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Tabora": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Tanga": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Zanzibar and Pemba": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 219,
        "name": "Thailand",
        "iso3": "THA",
        "iso2": "TH",
        "phone_code": "66",
        "capital": "Bangkok",
        "currency": "THB",
        "states": {
            "Amnat Charoen": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ang Thong": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Bangkok": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Buri Ram": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Chachoengsao": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Chai Nat": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Chaiyaphum": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Changwat Chaiyaphum": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Chanthaburi": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Chiang Mai": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Chiang Rai": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Chon Buri": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Chumphon": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kalasin": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kamphaeng Phet": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kanchanaburi": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Khon Kaen": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Krabi": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Krung Thep": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Lampang": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Lamphun": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Loei": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Lop Buri": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Mae Hong Son": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Maha Sarakham": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Mukdahan": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Nakhon Nayok": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Nakhon Pathom": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Nakhon Phanom": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Nakhon Ratchasima": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Nakhon Sawan": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Nakhon Si Thammarat": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Nan": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Narathiwat": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Nong Bua Lam Phu": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Nong Khai": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Nonthaburi": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Pathum Thani": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Pattani": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Phangnga": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Phatthalung": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Phayao": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Phetchabun": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Phetchaburi": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Phichit": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Phitsanulok": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Phra Nakhon Si Ayutthaya": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Phrae": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Phuket": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Prachin Buri": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Prachuap Khiri Khan": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ranong": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ratchaburi": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Rayong": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Roi Et": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Sa Kaeo": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Sakon Nakhon": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Samut Prakan": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Samut Sakhon": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Samut Songkhran": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Saraburi": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Satun": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Si Sa Ket": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Sing Buri": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Songkhla": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Sukhothai": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Suphan Buri": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Surat Thani": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Surin": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Tak": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Trang": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Trat": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ubon Ratchathani": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Udon Thani": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Uthai Thani": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Uttaradit": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Yala": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Yasothon": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 220,
        "name": "Togo",
        "iso3": "TGO",
        "iso2": "TG",
        "phone_code": "228",
        "capital": "Lome",
        "currency": "XOF",
        "states": {
            "Centre": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kara": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Maritime": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Plateaux": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Savanes": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 221,
        "name": "Tokelau",
        "iso3": "TKL",
        "iso2": "TK",
        "phone_code": "690",
        "capital": "",
        "currency": "NZD",
        "states": {
            "Atafu": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Fakaofo": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Nukunonu": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 222,
        "name": "Tonga",
        "iso3": "TON",
        "iso2": "TO",
        "phone_code": "676",
        "capital": "Nuku'alofa",
        "currency": "TOP",
        "states": {
            "Eua": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ha\\'apai": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Niuas": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Tongatapu": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Vava\\'u": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 223,
        "name": "Trinidad And Tobago",
        "iso3": "TTO",
        "iso2": "TT",
        "phone_code": "+1-868",
        "capital": "Port of Spain",
        "currency": "TTD",
        "states": {
            "Arima-Tunapuna-Piarco": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Caroni": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Chaguanas": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Couva-Tabaquite-Talparo": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Diego Martin": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Glencoe": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Penal Debe": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Point Fortin": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Port of Spain": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Princes Town": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Saint George": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "San Fernando": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "San Juan": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Sangre Grande": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Siparia": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Tobago": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 224,
        "name": "Tunisia",
        "iso3": "TUN",
        "iso2": "TN",
        "phone_code": "216",
        "capital": "Tunis",
        "currency": "TND",
        "states": {
            "al-Kaf": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "al-Mahdiyah": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "al-Munastir": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "al-Qasrayn": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "al-Qayrawan": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Aryanah": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Bajah": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Bin \\'Arus": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Binzart": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Gouvernorat de Ariana": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Gouvernorat de Nabeul": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Gouvernorat de Sousse": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Hammamet Yasmine": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Jundubah": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Madaniyin": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Manubah": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Monastir": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Nabul": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Qabis": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Qafsah": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Qibili": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Safaqis": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Sfax": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Sidi Bu Zayd": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Silyanah": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Susah": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Tatawin": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Tawzar": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Tunis": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Zaghwan": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 225,
        "name": "Turkey",
        "iso3": "TUR",
        "iso2": "TR",
        "phone_code": "90",
        "capital": "Ankara",
        "currency": "TRY",
        "states": {
            "Adana": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Adiyaman": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Afyon": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Agri": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Aksaray": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Amasya": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ankara": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Antalya": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ardahan": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Artvin": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Aydin": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Balikesir": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Bartin": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Batman": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Bayburt": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Bilecik": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Bingol": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Bitlis": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Bolu": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Burdur": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Bursa": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Canakkale": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Cankiri": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Corum": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Denizli": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Diyarbakir": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Duzce": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Edirne": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Elazig": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Erzincan": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Erzurum": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Eskisehir": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Gaziantep": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Giresun": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Gumushane": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Hakkari": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Hatay": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Icel": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Igdir": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Isparta": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Istanbul": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Izmir": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kahramanmaras": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Karabuk": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Karaman": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kars": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Karsiyaka": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kastamonu": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kayseri": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kilis": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kirikkale": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kirklareli": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kirsehir": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kocaeli": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Konya": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kutahya": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Lefkosa": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Malatya": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Manisa": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Mardin": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Mugla": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Mus": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Nevsehir": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Nigde": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ordu": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Osmaniye": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Rize": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Sakarya": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Samsun": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Sanliurfa": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Siirt": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Sinop": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Sirnak": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Sivas": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Tekirdag": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Tokat": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Trabzon": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Tunceli": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Usak": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Van": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Yalova": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Yozgat": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Zonguldak": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 226,
        "name": "Turkmenistan",
        "iso3": "TKM",
        "iso2": "TM",
        "phone_code": "993",
        "capital": "Ashgabat",
        "currency": "TMT",
        "states": {
            "Ahal": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Asgabat": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Balkan": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Dasoguz": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Lebap": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Mari": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 227,
        "name": "Turks And Caicos Islands",
        "iso3": "TCA",
        "iso2": "TC",
        "phone_code": "+1-649",
        "capital": "Cockburn Town",
        "currency": "USD",
        "states": {
            "Grand Turk": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "South Caicos and East Caicos": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 228,
        "name": "Tuvalu",
        "iso3": "TUV",
        "iso2": "TV",
        "phone_code": "688",
        "capital": "Funafuti",
        "currency": "AUD",
        "states": {
            "Funafuti": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Nanumanga": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Nanumea": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Niutao": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Nui": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Nukufetau": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Nukulaelae": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Vaitupu": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 229,
        "name": "Uganda",
        "iso3": "UGA",
        "iso2": "UG",
        "phone_code": "256",
        "capital": "Kampala",
        "currency": "UGX",
        "states": {
            "Central": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Eastern": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Northern": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Western": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 230,
        "name": "Ukraine",
        "iso3": "UKR",
        "iso2": "UA",
        "phone_code": "380",
        "capital": "Kiev",
        "currency": "UAH",
        "states": {
            "Cherkas\\'ka": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Chernihivs\\'ka": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Chernivets\\'ka": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Crimea": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Dnipropetrovska": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Donets\\'ka": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ivano-Frankivs\\'ka": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kharkiv": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kharkov": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Khersonska": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Khmel\\'nyts\\'ka": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kirovohrad": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Krym": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kyyiv": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kyyivs\\'ka": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Luhans\\'ka": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "L\\'vivs\\'ka": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Mykolayivs\\'ka": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Odessa": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Odes\\'ka": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Poltavs\\'ka": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Rivnens\\'ka": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Sevastopol\\'": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Sums\\'ka": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ternopil\\'s\\'ka": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Volyns\\'ka": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Vynnyts\\'ka": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Zakarpats\\'ka": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Zaporizhia": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Zhytomyrs\\'ka": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 231,
        "name": "United Arab Emirates",
        "iso3": "ARE",
        "iso2": "AE",
        "phone_code": "971",
        "capital": "Abu Dhabi",
        "currency": "AED",
        "states": {
            "Abu Zabi": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ajman": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "al-Fujayrah": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "ash-Shariqah": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Dubai": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ras al-Khaymah": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Sharjah": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Sharjha": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Umm al Qaywayn": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 232,
        "name": "United Kingdom",
        "iso3": "GBR",
        "iso2": "GB",
        "phone_code": "44",
        "capital": "London",
        "currency": "GBP",
        "states": {
            "Aberdeen": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Aberdeenshire": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Argyll": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Armagh": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Bedfordshire": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Belfast": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Berkshire": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Birmingham": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Brechin": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Bridgnorth": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Bristol": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Buckinghamshire": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Cambridge": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Cambridgeshire": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Channel Islands": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Cheshire": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Cleveland": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Co Fermanagh": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Conwy": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Cornwall": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Coventry": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Craven Arms": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Cumbria": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Denbighshire": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Derby": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Derbyshire": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Devon": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Dial Code Dungannon": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Didcot": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Dorset": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Dunbartonshire": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Durham": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "East Dunbartonshire": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "East Lothian": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "East Midlands": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "East Sussex": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "East Yorkshire": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "England": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Essex": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Fermanagh": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Fife": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Flintshire": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Fulham": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Gainsborough": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Glocestershire": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Gwent": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Hampshire": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Hants": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Herefordshire": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Hertfordshire": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ireland": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Isle Of Man": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Isle of Wight": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kenford": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kent": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Kilmarnock": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Lanarkshire": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Lancashire": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Leicestershire": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Lincolnshire": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Llanymynech": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "London": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Ludlow": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Manchester": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Mayfair": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Merseyside": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Mid Glamorgan": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Middlesex": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Mildenhall": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Monmouthshire": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Newton Stewart": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Norfolk": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "North Humberside": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "North Yorkshire": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Northamptonshire": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Northants": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Northern Ireland": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Northumberland": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Nottinghamshire": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Oxford": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Powys": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Roos-shire": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Sark": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Scotland": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Scottish Borders": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Shropshire": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Somerset": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "South Glamorgan": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "South Wales": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "South Yorkshire": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Southwell": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Staffordshire": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Strabane": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Suffolk": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Surrey": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "SUSSEX": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Twickenham": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Tyne and Wear": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Tyrone": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Utah": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Wales": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Warwickshire": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "West Lothian": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "West Midlands": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "West Sussex": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "West Yorkshire": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Whissendine": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Wiltshire": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Wokingham": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Worcestershire": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Wrexham": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Wurttemberg": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ],
            "Yorkshire": [
                "Abohar",
                "Adampur",
                "Ahmedgarh",
                "Ajnala",
                "Akalgarh",
                "Alawalpur",
                "Amloh",
                "Amritsar",
                "Amritsar Cantonment",
                "Anandpur Sahib",
                "Badhni Kalan",
                "Bagh Purana",
                "Balachaur",
                "Banaur",
                "Banga",
                "Banur",
                "Baretta",
                "Bariwala",
                "Barnala",
                "Bassi Pathana",
                "Batala",
                "Bathinda",
                "Begowal",
                "Behrampur",
                "Bhabat",
                "Bhadur",
                "Bhankharpur",
                "Bharoli Kalan",
                "Bhawanigarh",
                "Bhikhi",
                "Bhikhiwind",
                "Bhisiana",
                "Bhogpur",
                "Bhuch",
                "Bhulath",
                "Budha Theh",
                "Budhlada",
                "Chima",
                "Chohal",
                "Dasuya",
                "Daulatpur",
                "Dera Baba Nanak",
                "Dera Bassi",
                "Dhanaula",
                "Dharam Kot",
                "Dhariwal",
                "Dhilwan",
                "Dhuri",
                "Dinanagar",
                "Dirba",
                "Doraha",
                "Faridkot",
                "Fateh Nangal",
                "Fatehgarh Churian",
                "Fatehgarh Sahib",
                "Fazilka",
                "Firozpur",
                "Firozpur Cantonment",
                "Gardhiwala",
                "Garhshankar",
                "Ghagga",
                "Ghanaur",
                "Giddarbaha",
                "Gobindgarh",
                "Goniana",
                "Goraya",
                "Gurdaspur",
                "Guru Har Sahai",
                "Hajipur",
                "Handiaya",
                "Hariana",
                "Hoshiarpur",
                "Hussainpur",
                "Jagraon",
                "Jaitu",
                "Jalalabad",
                "Jalandhar",
                "Jalandhar Cantonment",
                "Jandiala",
                "Jugial",
                "Kalanaur",
                "Kapurthala",
                "Karoran",
                "Kartarpur",
                "Khamanon",
                "Khanauri",
                "Khanna",
                "Kharar",
                "Khem Karan",
                "Kot Fatta",
                "Kot Isa Khan",
                "Kot Kapura",
                "Kotkapura",
                "Kurali",
                "Lalru",
                "Lehra Gaga",
                "Lodhian Khas",
                "Longowal",
                "Ludhiana",
                "Machhiwara",
                "Mahilpur",
                "Majitha",
                "Makhu",
                "Malaut",
                "Malerkotla",
                "Maloud",
                "Mandi Gobindgarh",
                "Mansa",
                "Maur",
                "Moga",
                "Mohali",
                "Moonak",
                "Morinda",
                "Mukerian",
                "Muktsar",
                "Mullanpur Dakha",
                "Mullanpur Garibdas",
                "Munak",
                "Muradpura",
                "Nabha",
                "Nakodar",
                "Nangal",
                "Nawashahr",
                "Naya Nangal",
                "Nehon",
                "Nurmahal",
                "Pathankot",
                "Patiala",
                "Patti",
                "Pattran",
                "Payal",
                "Phagwara",
                "Phillaur",
                "Qadian",
                "Rahon",
                "Raikot",
                "Raja Sansi",
                "Rajpura",
                "Ram Das",
                "Raman",
                "Rampura",
                "Rayya",
                "Rupnagar",
                "Rurki Kasba",
                "Sahnewal",
                "Samana",
                "Samrala",
                "Sanaur",
                "Sangat",
                "Sangrur",
                "Sansarpur",
                "Sardulgarh",
                "Shahkot",
                "Sham Churasi",
                "Shekhpura",
                "Sirhind",
                "Sri Hargobindpur",
                "Sujanpur",
                "Sultanpur Lodhi",
                "Sunam",
                "Talwandi Bhai",
                "Talwara",
                "Tappa",
                "Tarn Taran",
                "Urmar Tanda",
                "Zira",
                "Zirakpur"
            ]
        }
    },
    {
        "id": 233,
        "name": "United States",
        "iso3": "USA",
        "iso2": "US",
        "phone_code": "1",
        "capital": "Washington",
        "currency": "USD",
        "states": {
            "Alabama": [
                " Branchville ",
                " Brent ",
                " Brewton ",
                "Abbeville",
                "Adamsville",
                "Addison",
                "Akron",
                "Alabaster",
                "Albertville",
                "Alexander City",
                "Alexandria",
                "Aliceville",
                "Allgood",
                "Altoona",
                "Andalusia",
                "Anderson",
                "Anniston",
                "Arab",
                "Ardmore",
                "Argo",
                "Ariton",
                "Arley",
                "Ashford",
                "Ashland",
                "Ashville",
                "Athens",
                "Atmore",
                "Attalla",
                "Auburn",
                "Autaugaville",
                "Avon",
                "Babbie",
                "Baileyton",
                "Banks",
                "Bay Minette",
                "Bayou La Batre",
                "Bear Creek",
                "Beatrice",
                "Beaverton",
                "Belk",
                "Benton",
                "Berry",
                "Bessemer",
                "Billingsley",
                "Birmingham",
                "Black",
                "Blountsville",
                "Blue Mountain",
                "Blue Ridge ",
                "Blue Springs ",
                "Boaz ",
                "Boligee ",
                "Bon Air ",
                "Brantley ",
                "Bridgeport",
                "Brighton",
                "Brilliant",
                "Brookside",
                "Brookwood",
                "Brundidge",
                "Butler",
                "Bynum",
                "Cahaba Heights",
                "Calera",
                "Camden",
                "Camp Hill",
                "Carbon Hill",
                "Cardiff",
                "Carolina",
                "Carrollton",
                "Castleberry",
                "Cedar Bluff",
                "Center Point",
                "Centre",
                "Centreville",
                "Chalkville",
                "Chatom",
                "Chelsea",
                "Cherokee",
                "Chickasaw",
                "Childersburg",
                "Citronelle",
                "Clanton",
                "Clay",
                "Clayhatchee",
                "Clayton",
                "Cleveland",
                "Clio",
                "Coaling",
                "Coffee Springs",
                "Coffeeville",
                "Coker",
                "Collinsville",
                "Colony",
                "Columbia",
                "Columbiana",
                "Concord",
                "Coosada",
                "Cordova",
                "Cottonwood",
                "County Line",
                "Courtland",
                "Cowarts",
                "Creola",
                "Crossville",
                "Cuba",
                "Cullman",
                "Dadeville",
                "Daleville",
                "Daphne",
                "Dauphin Island",
                "Daviston",
                "Dayton",
                "Deatsville",
                "Decatur",
                "Demopolis",
                "Detroit",
                "Dodge City",
                "Dora",
                "Dothan",
                "Double Springs",
                "Douglas",
                "Dozier",
                "Dutton",
                "East Brewton",
                "Eclectic",
                "Edgewater",
                "Edwardsville",
                "Elba",
                "Elberta",
                "Eldridge",
                "Elkmont",
                "Elmore",
                "Emelle",
                "Enterprise",
                "Epes",
                "Ethelsville",
                "Eufaula",
                "Eunola",
                "Eutaw",
                "Eva",
                "Evergreen",
                "Excel",
                "Fairfield",
                "Fairhope",
                "Fairview",
                "Falkville",
                "Faunsdale",
                "Fayette",
                "Five Points",
                "Flomaton",
                "Florala",
                "Florence",
                "Foley",
                "Forestdale",
                "Forkland",
                "Fort Deposit",
                "Fort Payne",
                "Fort Rucker",
                "Franklin",
                "Frisco City",
                "Fruithurst",
                "Fulton",
                "Fultondale",
                "Fyffe",
                "Gadsden",
                "Gainesville",
                "Gantt",
                "Gantts Quarry",
                "Garden City",
                "Gardendale",
                "Gaylesville",
                "Geiger",
                "Geneva",
                "Georgiana",
                "Geraldine",
                "Gilbertown",
                "Glen Allen",
                "Glencoe",
                "Glenwood",
                "Goldville",
                "Good Hope",
                "Goodwater",
                "Gordo",
                "Gordon",
                "Gordonville",
                "Goshen",
                "Grand Bay",
                "Grant",
                "Grayson Valley",
                "Graysville",
                "Greensboro",
                "Greenville",
                "Grimes",
                "Grove Hill",
                "Gu-win",
                "Guin",
                "Gulf Shores",
                "Guntersville",
                "Gurley",
                "Hackleburg",
                "Haleburg",
                "Haleyville",
                "Hamilton",
                "Hammondville",
                "Hanceville",
                "Harpersville",
                "Hartford",
                "Hartselle",
                "Harvest",
                "Hayden",
                "Hayneville",
                "Hazel Green",
                "Headland",
                "Heath",
                "Heflin",
                "Helena",
                "Henagar",
                "Highland Lake",
                "Hillsboro",
                "Hobson City",
                "Hodges",
                "Hokes Bluff",
                "Holly Pond",
                "Hollywood",
                "Holt",
                "Homewood",
                "Hoover",
                "Horn Hill",
                "Hueytown",
                "Huguley",
                "Huntsville",
                "Hurtsboro",
                "Hytop",
                "Ider",
                "Indian Springs Village",
                "Irondale",
                "Jackson",
                "Jacksons Gap",
                "Jacksonville",
                "Jasper",
                "Jemison",
                "Kansas",
                "Kennedy",
                "Killen",
                "Kimberly",
                "Kinsey",
                "Kinston",
                "La Fayette",
                "Ladonia",
                "Lake Purdy",
                "Lake View",
                "Lakeview",
                "Lanett",
                "Langston",
                "Leeds",
                "Leesburg",
                "Leighton",
                "Lester",
                "Level Plains",
                "Lexington",
                "Libertyville",
                "Lincoln",
                "Linden",
                "Lineville",
                "Lipscomb",
                "Lisman",
                "Littleville",
                "Livingston",
                "Loachapoka",
                "Lockhart",
                "Locust Fork",
                "Louisville",
                "Lowndesboro",
                "Loxley",
                "Luverne",
                "Lynn",
                "Macedonia",
                "Madison",
                "Madrid",
                "Malvern",
                "Maplesville",
                "Margaret",
                "Marion",
                "Maytown",
                "Mcdonald Chapel",
                "Mcintosh",
                "Mckenzie",
                "Mcmullen",
                "Meadowbrook",
                "Memphis",
                "Mentone",
                "Meridianville",
                "Midfield",
                "Midland City",
                "Midway",
                "Mignon",
                "Millbrook",
                "Millport",
                "Millry",
                "Minor",
                "Mobile",
                "Monroeville",
                "Montevallo",
                "Montgomery",
                "Moody",
                "Moores Mill",
                "Mooresville",
                "Morris",
                "Mosses",
                "Moulton",
                "Moundville",
                "Mount Olive",
                "Mount Vernon",
                "Mountain Brook",
                "Mountainboro",
                "Mulga",
                "Munford",
                "Muscle Shoals",
                "Myrtlewood",
                "Napier Field",
                "Natural Bridge",
                "Nauvoo",
                "Nectar",
                "Needham",
                "New Brockton",
                "New Hope",
                "New Market",
                "New Site",
                "Newbern",
                "Newton",
                "Newville",
                "North Bibb",
                "North Courtland",
                "North Johns",
                "Northport",
                "Notasulga",
                "Oak Grove",
                "Oak Hill",
                "Oakman",
                "Odenville",
                "Ohatchee",
                "Oneonta",
                "Onycha",
                "Opelika",
                "Opp",
                "Orange Beach",
                "Orrville",
                "Owens Cross Roads",
                "Oxford",
                "Ozark",
                "Paint Rock",
                "Parrish",
                "Pelham",
                "Pell City",
                "Pennington",
                "Petrey",
                "Phenix City",
                "Phil Campbell",
                "Pickensville",
                "Piedmont",
                "Pike Road",
                "Pinckard",
                "Pine Apple",
                "Pine Hill",
                "Pine Ridge",
                "Pinson",
                "Pisgah",
                "Pleasant Grove",
                "Pleasant Groves",
                "Point Clear",
                "Pollard",
                "Powell",
                "Prattville",
                "Priceville",
                "Prichard",
                "Providence",
                "Ragland",
                "Rainbow City",
                "Rainsville",
                "Ranburne",
                "Red Bay",
                "Red Level",
                "Redstone Arsenal",
                "Reece City",
                "Reform",
                "Rehobeth",
                "Repton",
                "Ridgeville",
                "River Falls",
                "Riverside",
                "Riverview",
                "Roanoke",
                "Robertsdale",
                "Rock Creek",
                "Rock Mills",
                "Rockford",
                "Rogersville",
                "Rosa",
                "Russellville",
                "Rutledge",
                "Saks",
                "Samson",
                "Sand Rock",
                "Sanford",
                "Saraland",
                "Sardis City",
                "Satsuma",
                "Scottsboro",
                "Section",
                "Selma",
                "Selmont-west Selmont",
                "Sheffield",
                "Shiloh",
                "Shorter",
                "Silas",
                "Silverhill",
                "Sipsey",
                "Skyline",
                "Slocomb",
                "Smiths",
                "Smoke Rise",
                "Snead",
                "Somerville",
                "South Vinemont",
                "Southside",
                "Spanish Fort",
                "Springville",
                "St. Florian",
                "Steele",
                "Stevenson",
                "Sulligent",
                "Sumiton",
                "Summerdale",
                "Susan Moore",
                "Sweet Water",
                "Sylacauga",
                "Sylvan Springs",
                "Sylvania",
                "Talladega",
                "Talladega Springs",
                "Tallassee",
                "Tarrant",
                "Taylor",
                "Theodore",
                "Thomaston",
                "Thomasville",
                "Thorsby",
                "Tillmans Corner",
                "Town Creek",
                "Toxey",
                "Trafford",
                "Triana",
                "Trinity",
                "Troy",
                "Trussville",
                "Tuscaloosa",
                "Tuscumbia",
                "Tuskegee",
                "Underwood-petersville",
                "Union",
                "Union Grove",
                "Union Springs",
                "Uniontown",
                "Valley",
                "Valley Head",
                "Vance",
                "Vernon",
                "Vestavia Hills",
                "Vina",
                "Vincent",
                "Vredenburgh",
                "Wadley",
                "Waldo",
                "Walnut Grove",
                "Warrior",
                "Waterloo",
                "Waverly",
                "Weaver",
                "Webb",
                "Wedowee",
                "West Blocton",
                "West End-cobb Town",
                "West Jefferson",
                "West Point",
                "Wetumpka",
                "White Hall",
                "Wilsonville",
                "Wilton",
                "Winfield",
                "Woodland",
                "Woodville",
                "Yellow Bluff",
                "York"
            ],
            "Alaska": [
                "Adak",
                "Akhiok",
                "Akiachak",
                "Akiak",
                "Akutan",
                "Alakanuk",
                "Alatna",
                "Alcan Border",
                "Aleknagik",
                "Aleneva",
                "Allakaket",
                "Alpine",
                "Ambler",
                "Anaktuvuk Pass",
                "Anchor Point",
                "Anchorage",
                "Anderson",
                "Angoon",
                "Aniak",
                "Anvik",
                "Arctic Village",
                "Atka",
                "Atmautluak",
                "Atqasuk",
                "Attu Station",
                "Barrow",
                "Bear Creek",
                "Beaver",
                "Beluga",
                "Bethel",
                "Bettles",
                "Big Delta",
                "Big Lake",
                "Birch Creek",
                "Brevig Mission",
                "Buckland",
                "Buffalo Soapstone",
                "Butte",
                "Cantwell",
                "Central",
                "Chalkyitsik",
                "Chase",
                "Chefornak",
                "Chenega",
                "Chevak",
                "Chickaloon",
                "Chicken",
                "Chignik",
                "Chignik Lagoon",
                "Chignik Lake",
                "Chiniak",
                "Chisana",
                "Chistochina",
                "Chitina",
                "Chuathbaluk",
                "Circle",
                "Clam Gulch",
                "Clark S Point",
                "Coffman Cove",
                "Cohoe",
                "Cold Bay",
                "Coldfoot",
                "College",
                "Cooper Landing",
                "Copper Center",
                "Copperville",
                "Cordova",
                "Covenant Life",
                "Craig",
                "Crooked Creek",
                "Crown Point",
                "Cube Cove",
                "Deering",
                "Delta Junction",
                "Deltana",
                "Diamond Ridge",
                "Dillingham",
                "Diomede",
                "Dot Lake",
                "Dot Lake Village",
                "Dry Creek",
                "Eagle",
                "Eagle Village",
                "Edna Bay",
                "Eek",
                "Egegik",
                "Eielson Afb",
                "Ekwok",
                "Elfin Cove",
                "Elim",
                "Emmonak",
                "Ester",
                "Evansville",
                "Excursion Inlet",
                "Fairbanks",
                "False Pass",
                "Farm Loop",
                "Ferry",
                "Fishhook",
                "Flat",
                "Fort Greely",
                "Fort Yukon",
                "Four Mile Road",
                "Fox",
                "Fox River",
                "Fritz Creek",
                "Funny River",
                "Gakona",
                "Galena",
                "Gambell",
                "Game Creek",
                "Gateway",
                "Glacier View",
                "Glennallen",
                "Golovin",
                "Goodnews Bay",
                "Grayling",
                "Gulkana",
                "Gustavus",
                "Haines",
                "Halibut Cove",
                "Happy Valley",
                "Harding-birch Lakes",
                "Healy",
                "Healy Lake",
                "Hobart Bay",
                "Hollis",
                "Holy Cross",
                "Homer",
                "Hoonah",
                "Hooper Bay",
                "Hope",
                "Houston",
                "Hughes",
                "Huslia",
                "Hydaburg",
                "Hyder",
                "Igiugig",
                "Iliamna",
                "Ivanof Bay",
                "Juneau City And",
                "Kachemak",
                "Kake",
                "Kaktovik",
                "Kalifornsky",
                "Kaltag",
                "Karluk",
                "Kasaan",
                "Kasigluk",
                "Kasilof",
                "Kenai",
                "Kenny Lake",
                "Ketchikan",
                "Kiana",
                "King Cove",
                "King Salmon",
                "Kipnuk",
                "Kivalina",
                "Klawock",
                "Klukwan",
                "Knik River",
                "Knik-fairview",
                "Kobuk",
                "Kodiak",
                "Kodiak Station",
                "Kokhanok",
                "Koliganek",
                "Kongiganak",
                "Kotlik",
                "Kotzebue",
                "Koyuk",
                "Koyukuk",
                "Kupreanof",
                "Kwethluk",
                "Kwigillingok",
                "Lake Louise",
                "Lake Minchumina",
                "Lakes",
                "Larsen Bay",
                "Lazy Mountain",
                "Levelock",
                "Lime Village",
                "Livengood",
                "Lowell Point",
                "Lower Kalskag",
                "Lutak",
                "Manley Hot Springs",
                "Manokotak",
                "Marshall",
                "Mccarthy",
                "Mcgrath",
                "Mckinley Park",
                "Meadow Lakes",
                "Mekoryuk",
                "Mendeltna",
                "Mentasta Lake",
                "Metlakatla",
                "Meyers Chuck",
                "Miller Landing",
                "Minto",
                "Moose Creek",
                "Moose Pass",
                "Mosquito Lake",
                "Mountain Village",
                "Mud Bay",
                "Naknek",
                "Nanwalek",
                "Napakiak",
                "Napaskiak",
                "Naukati Bay",
                "Nelchina",
                "Nelson Lagoon",
                "Nenana",
                "New Allakaket",
                "New Stuyahok",
                "Newhalen",
                "Newtok",
                "Nightmute",
                "Nikiski",
                "Nikolaevsk",
                "Nikolai",
                "Nikolski",
                "Ninilchik",
                "Noatak",
                "Nome",
                "Nondalton",
                "Noorvik",
                "North Pole",
                "Northway",
                "Northway Junction",
                "Northway Village",
                "Nuiqsut",
                "Nulato",
                "Nunapitchuk",
                "Old Harbor",
                "Oscarville",
                "Ouzinkie",
                "Palmer",
                "Paxson",
                "Pedro Bay",
                "Pelican",
                "Perryville",
                "Petersburg",
                "Petersville",
                "Pilot Point",
                "Pilot Station",
                "Pitkas Point",
                "Platinum",
                "Pleasant Valley",
                "Point Baker",
                "Point Hope",
                "Point Lay",
                "Point Mackenzie",
                "Pope-vannoy Landing",
                "Port Alexander",
                "Port Alsworth",
                "Port Clarence",
                "Port Graham",
                "Port Heiden",
                "Port Lions",
                "Port Protection",
                "Portage Creek",
                "Primrose",
                "Prudhoe Bay",
                "Quinhagak",
                "Rampart",
                "Red Devil",
                "Red Dog Mine",
                "Ridgeway",
                "Ruby",
                "Russian Mission",
                "Salamatof",
                "Salcha",
                "Sand Point",
                "Savoonga",
                "Saxman",
                "Scammon Bay",
                "Selawik",
                "Seldovia",
                "Seldovia Village",
                "Seward",
                "Shageluk",
                "Shaktoolik",
                "Sheldon Point (nunam Iqua)",
                "Shishmaref",
                "Shungnak",
                "Silver Springs",
                "Sitka City And",
                "Skagway",
                "Skwentna",
                "Slana",
                "Sleetmute",
                "Soldotna",
                "South Naknek",
                "St. George",
                "St. Marys",
                "St. Michael",
                "St. Paul",
                "Stebbins",
                "Sterling",
                "Stevens Village",
                "Stony River",
                "Sunrise",
                "Susitna",
                "Sutton-alpine",
                "Takotna",
                "Talkeetna",
                "Tanacross",
                "Tanaina",
                "Tanana",
                "Tatitlek",
                "Tazlina",
                "Teller",
                "Tenakee Springs",
                "Tetlin",
                "Thoms Place",
                "Thorne Bay",
                "Togiak",
                "Tok",
                "Toksook Bay",
                "Tolsona",
                "Tonsina",
                "Trapper Creek",
                "Tuluksak",
                "Tuntutuliak",
                "Tununak",
                "Twin Hills",
                "Two Rivers",
                "Tyonek",
                "Ugashik",
                "Unalakleet",
                "Unalaska",
                "Upper Kalskag",
                "Valdez",
                "Venetie",
                "Wainwright",
                "Wales",
                "Wasilla",
                "Whale Pass",
                "White Mountain",
                "Whitestone Logging Camp",
                "Whittier",
                "Willow",
                "Willow Creek",
                "Wiseman",
                "Womens Bay",
                "Wrangell",
                "Y",
                "Yakutat"
            ],
            "American Samoa": [
                "American Samoa"
            ],
            "Arizona": [
                "Ajo",
                "Ak-chin Village",
                "Amado",
                "Apache Junction",
                "Ari",
                "Ash Fork",
                "Avondale",
                "Avra Valley",
                "Bagdad",
                "Benson",
                "Big Park",
                "Bisbee",
                "Bitter Springs",
                "Black Canyon City",
                "Blackwater",
                "Bluewater",
                "Bouse",
                "Buckeye",
                "Bullhead City",
                "Burnside",
                "Cameron",
                "Camp Verde",
                "Canyon Day",
                "Carefree",
                "Casa Grande",
                "Casas Adobes",
                "Catalina",
                "Catalina Foothills",
                "Cave Creek",
                "Central Heights-midland City",
                "Chandler",
                "Chilchinbito",
                "Chinle",
                "Chino Valley",
                "Chuichu",
                "Cibecue",
                "Cibola",
                "Clarkdale",
                "Claypool",
                "Clifton",
                "Colorado City",
                "Congress",
                "Coolidge",
                "Cordes Lakes",
                "Cornville",
                "Corona De Tucson",
                "Cottonwood",
                "Cottonwood-verde Village",
                "Dennehotso",
                "Desert Hills",
                "Dewey-humboldt",
                "Dilkon",
                "Dolan Springs",
                "Douglas",
                "Drexel Heights",
                "Drexel-alvernon",
                "Dudleyville",
                "Duncan",
                "Eagar",
                "East Fork",
                "East Sahuarita",
                "Ehrenberg",
                "El Mirage",
                "Elgin",
                "Eloy",
                "First Mesa",
                "Flagstaff",
                "Florence",
                "Flowing Wells",
                "Fort Defiance",
                "Fortuna Foothills",
                "Fountain Hills",
                "Fredonia",
                "Gadsden",
                "Ganado",
                "Gila Bend",
                "Gilbert",
                "Gisela",
                "Glendale",
                "Globe",
                "Gold Camp",
                "Golden Valley",
                "Goodyear",
                "Grand Canyon Village",
                "Greasewood",
                "Green Valley",
                "Guadalupe",
                "Hayden",
                "Heber-overgaard",
                "Holbrook",
                "Hotevilla-bacavi",
                "Houck",
                "Huachuca City",
                "Jeddito",
                "Jerome",
                "Kachina Village",
                "Kaibab",
                "Kaibito",
                "Kayenta",
                "Keams Canyon",
                "Kearny",
                "Kingman",
                "Kykotsmovi Village",
                "Lake Havasu City",
                "Lake Montezuma",
                "Lechee",
                "Leupp",
                "Litchfield Park",
                "Littletown",
                "Lukachukai",
                "Mammoth",
                "Many Farms",
                "Marana",
                "Maricopa",
                "Mayer",
                "Mcnary",
                "Mesa",
                "Mesquite Creek",
                "Miami",
                "Moenkopi",
                "Mohave Valley",
                "Mojave Ranch Estates",
                "Morenci",
                "Mountainaire",
                "Munds Park",
                "Naco",
                "Nazlini",
                "New Kingman-butler",
                "New River",
                "Nogales",
                "Oljato-monument Valley",
                "Oracle",
                "Oro Valley",
                "Page",
                "Paradise Valley",
                "Parker",
                "Parker Strip",
                "Parks",
                "Patagonia",
                "Paulden",
                "Payson",
                "Peach Springs",
                "Peeples Valley",
                "Peoria",
                "Peridot",
                "Phoenix",
                "Picture Rocks",
                "Pima",
                "Pine",
                "Pinetop-lakeside",
                "Pinon",
                "Pirtleville",
                "Pisinemo",
                "Poston",
                "Prescott",
                "Prescott Valley",
                "Quartzsite",
                "Queen Creek",
                "Queen Valley",
                "Red Mesa",
                "Rio Rico Northeast",
                "Rio Rico Northwest",
                "Rio Rico Southeast",
                "Rio Rico Southwest",
                "Rio Verde",
                "Rock Point",
                "Rough Rock",
                "Round Rock",
                "Sacaton",
                "Safford",
                "Sahuarita",
                "Salome",
                "San Carlos",
                "San Luis",
                "San Manuel",
                "Santa Rosa",
                "Santan",
                "Sawmill",
                "Scottsdale",
                "Second Mesa",
                "Sedona",
                "Seligman",
                "Sells",
                "Shongopovi",
                "Shonto",
                "Show Low",
                "Sierra Vista",
                "Sierra Vista Southeast",
                "Snowflake",
                "Somerton",
                "Sonoita",
                "South Tucson",
                "Spring Valley",
                "Springerville",
                "St. David",
                "St. Johns",
                "St. Michaels",
                "Stanfield",
                "Steamboat",
                "Strawberry",
                "Summit",
                "Sun City",
                "Sun City West",
                "Sun Lakes",
                "Sun Valley",
                "Supai",
                "Superior",
                "Surprise",
                "Swift Trail Junction",
                "Tacna",
                "Tanque Verde",
                "Taylor",
                "Teec Nos Pos",
                "Tempe",
                "Thatcher",
                "Three Points",
                "Tolleson",
                "Tombstone",
                "Tonalea",
                "Tonto Basin",
                "Top-of-the-world",
                "Tortolita",
                "Tsaile",
                "Tuba City",
                "Tubac",
                "Tucson",
                "Tucson Estates",
                "Tumacacori-carmen",
                "Tusayan",
                "Vail",
                "Valencia West",
                "Wellton",
                "Wenden",
                "Whetstone",
                "Whiteriver",
                "Wickenburg",
                "Wilhoit",
                "Willcox",
                "Williams",
                "Williamson",
                "Willow Valley",
                "Window Rock",
                "Winkelman",
                "Winslow",
                "Winslow West",
                "Yarnell",
                "Young",
                "Youngtown",
                "Yuma"
            ],
            "Arkansas": [
                "Adona",
                "Alexander",
                "Alicia",
                "Allport",
                "Alma",
                "Almyra",
                "Alpena",
                "Altheimer",
                "Altus",
                "Amagon",
                "Amity",
                "Anthonyville",
                "Antoine",
                "Arkadelphia",
                "Arkansas City",
                "Ash Flat",
                "Ashdown",
                "Atkins",
                "Aubrey",
                "Augusta",
                "Austin",
                "Avoca",
                "Bald Knob",
                "Banks",
                "Barling",
                "Bassett",
                "Batesville",
                "Bauxite",
                "Bay",
                "Bearden",
                "Beaver",
                "Beebe",
                "Beedeville",
                "Bella Vista",
                "Bellefonte",
                "Belleville",
                "Ben Lomond",
                "Benton",
                "Bentonville",
                "Bergman",
                "Berryville",
                "Bethel Heights",
                "Big Flat",
                "Bigelow",
                "Biggers",
                "Birdsong",
                "Black Oak",
                "Black Rock",
                "Black Springs",
                "Blevins",
                "Blue Eye",
                "Blue Mountain",
                "Bluff City",
                "Blytheville",
                "Bodcaw",
                "Bonanza",
                "Bono",
                "Booneville",
                "Bradford",
                "Bradley",
                "Branch",
                "Briarcliff",
                "Brinkley",
                "Brookland",
                "Bryant",
                "Buckner",
                "Bull Shoals",
                "Burdette",
                "Cabot",
                "Caddo Valley",
                "Caldwell",
                "Cale",
                "Calico Rock",
                "Calion",
                "Camden",
                "Cammack Village",
                "Campbell Station",
                "Caraway",
                "Carlisle",
                "Carthage",
                "Casa",
                "Cash",
                "Caulksville",
                "Cave City",
                "Cave Springs",
                "Cedarville",
                "Centerton",
                "Central City",
                "Charleston",
                "Cherokee Village",
                "Cherry Valley",
                "Chester",
                "Chidester",
                "Clarendon",
                "Clarksville",
                "Clinton",
                "Coal Hill",
                "College City",
                "College Station",
                "Colt",
                "Concord",
                "Conway",
                "Corinth",
                "Corning",
                "Cotter",
                "Cotton Plant",
                "Cove",
                "Coy",
                "Crawfordsville",
                "Crossett",
                "Cushman",
                "Daisy",
                "Damascus",
                "Danville",
                "Dardanelle",
                "Datto",
                "De Queen",
                "De Valls Bluff",
                "De Witt",
                "Decatur",
                "Delaplaine",
                "Delight",
                "Dell",
                "Denning",
                "Dermott",
                "Des Arc",
                "Diamond City",
                "Diaz",
                "Dierks",
                "Donaldson",
                "Dover",
                "Dumas",
                "Dyer",
                "Dyess",
                "Earle",
                "East Camden",
                "East End",
                "Edmondson",
                "Egypt",
                "El Dorado",
                "Elaine",
                "Elkins",
                "Elm Springs",
                "Emerson",
                "Emmet",
                "England",
                "Enola",
                "Etowah",
                "Eudora",
                "Eureka Springs",
                "Evening Shade",
                "Everton",
                "Fairfield Bay",
                "Fargo",
                "Farmington",
                "Fayetteville",
                "Felsenthal",
                "Fifty-six",
                "Fisher",
                "Flippin",
                "Fordyce",
                "Foreman",
                "Forrest City",
                "Fort Smith",
                "Fouke",
                "Fountain Hill",
                "Fountain Lake",
                "Fourche",
                "Franklin",
                "Fredonia (biscoe)",
                "Friendship",
                "Fulton",
                "Garfield",
                "Garland",
                "Garner",
                "Gassville",
                "Gateway",
                "Gentry",
                "Georgetown",
                "Gibson",
                "Gilbert",
                "Gillett",
                "Gillham",
                "Gilmore",
                "Glenwood",
                "Goshen",
                "Gosnell",
                "Gould",
                "Grady",
                "Grannis",
                "Gravel Ridge",
                "Gravette",
                "Green Forest",
                "Greenbrier",
                "Greenland",
                "Greenway",
                "Greenwood",
                "Greers Ferry",
                "Griffithville",
                "Grubbs",
                "Guion",
                "Gum Springs",
                "Gurdon",
                "Guy",
                "Hackett",
                "Hamburg",
                "Hampton",
                "Hardy",
                "Harrell",
                "Harrisburg",
                "Harrison",
                "Hartford",
                "Hartman",
                "Haskell",
                "Hatfield",
                "Havana",
                "Haynes",
                "Hazen",
                "Heber Springs",
                "Hector",
                "Helena",
                "Hensley",
                "Hermitage",
                "Hickory Ridge",
                "Higden",
                "Higginson",
                "Highfill",
                "Highland",
                "Hindsville",
                "Holland",
                "Holly Grove",
                "Hope",
                "Horatio",
                "Horseshoe Bend",
                "Horseshoe Lake",
                "Hot Springs",
                "Hot Springs Village",
                "Houston",
                "Hoxie",
                "Hughes",
                "Humnoke",
                "Humphrey",
                "Hunter",
                "Huntington",
                "Huntsville",
                "Huttig",
                "Imboden",
                "Jacksonport",
                "Jacksonville",
                "Jasper",
                "Jennette",
                "Jericho",
                "Jerome",
                "Johnson",
                "Joiner",
                "Jonesboro",
                "Judsonia",
                "Junction City",
                "Keiser",
                "Kensett",
                "Keo",
                "Kibler",
                "Kingsland",
                "Knobel",
                "Knoxville",
                "Lafe",
                "Lagrange",
                "Lake City",
                "Lake Hamilton",
                "Lake View",
                "Lake Village",
                "Lakeview",
                "Lamar",
                "Lavaca",
                "Leachville",
                "Lead Hill",
                "Leola",
                "Lepanto",
                "Leslie",
                "Letona",
                "Lewisville",
                "Lexa",
                "Lincoln",
                "Little Flock",
                "Little Rock",
                "Lockesburg",
                "London",
                "Lonoke",
                "Lonsdale",
                "Louann",
                "Lowell",
                "Luxora",
                "Lynn",
                "Madison",
                "Magazine",
                "Magness",
                "Magnolia",
                "Malvern",
                "Mammoth Spring",
                "Manila",
                "Mansfield",
                "Marianna",
                "Marie",
                "Marion",
                "Marked Tree",
                "Marmaduke",
                "Marshall",
                "Marvell",
                "Maumelle",
                "Mayflower",
                "Maynard",
                "Mcalmont",
                "Mccaskill",
                "Mccrory",
                "Mcdougal",
                "Mcgehee",
                "Mcnab",
                "Mcneil",
                "Mcrae",
                "Melbourne",
                "Mena",
                "Menifee",
                "Midland",
                "Mineral Springs",
                "Minturn",
                "Mitchellville",
                "Monette",
                "Monticello",
                "Montrose",
                "Moorefield",
                "Moro",
                "Morrilton",
                "Morrison Bluff",
                "Mount Ida",
                "Mount Pleasant",
                "Mount Vernon",
                "Mountain Home",
                "Mountain Pine",
                "Mountain View",
                "Mountainburg",
                "Mulberry",
                "Murfreesboro",
                "Nashville",
                "Newark",
                "Newport",
                "Nimmons",
                "Norfork",
                "Norman",
                "Norphlet",
                "North Crossett",
                "North Little Rock",
                "O Kean",
                "Oak Grove",
                "Oak Grove Heights",
                "Oakhaven",
                "Oden",
                "Ogden",
                "Oil Trough",
                "Okolona",
                "Ola",
                "Omaha",
                "Oppelo",
                "Osceola",
                "Oxford",
                "Ozan",
                "Ozark",
                "Palestine",
                "Pangburn",
                "Paragould",
                "Paris",
                "Parkdale",
                "Parkers-iron Springs",
                "Parkin",
                "Patmos",
                "Patterson",
                "Pea Ridge",
                "Peach Orchard",
                "Perla",
                "Perry",
                "Perrytown",
                "Perryville",
                "Piggott",
                "Pindall",
                "Pine Bluff",
                "Pineville",
                "Piney",
                "Plainview",
                "Pleasant Plains",
                "Plumerville",
                "Pocahontas",
                "Pollard",
                "Portia",
                "Portland",
                "Pottsville",
                "Powhatan",
                "Poyen",
                "Prairie Creek",
                "Prairie Grove",
                "Prattsville",
                "Prescott",
                "Pyatt",
                "Quitman",
                "Ratcliff",
                "Ravenden",
                "Ravenden Springs",
                "Reader",
                "Rector",
                "Redfield",
                "Reed",
                "Reyno",
                "Rison",
                "Rockport",
                "Rockwell",
                "Roe",
                "Rogers",
                "Rondo",
                "Rose Bud",
                "Rosston",
                "Rudy",
                "Russell",
                "Russellville",
                "Salem",
                "Salesville",
                "Scott",
                "Scranton",
                "Searcy",
                "Sedgwick",
                "Shannon Hills",
                "Sheridan",
                "Sherrill",
                "Sherwood",
                "Shirley",
                "Sidney",
                "Siloam Springs",
                "Smackover",
                "Smithville",
                "South Lead Hill",
                "Sparkman",
                "Springdale",
                "Springtown",
                "St. Charles",
                "St. Francis",
                "St. Joe",
                "St. Paul",
                "Stamps",
                "Star City",
                "Stephens",
                "Strawberry",
                "Strong",
                "Stuttgart",
                "Subiaco",
                "Success",
                "Sulphur Rock",
                "Sulphur Springs",
                "Summit",
                "Sunset",
                "Sweet Home",
                "Swifton",
                "Taylor",
                "Texarkana",
                "Thornton",
                "Tillar",
                "Tinsman",
                "Tollette",
                "Tontitown",
                "Traskwood",
                "Trumann",
                "Tuckerman",
                "Tull",
                "Tupelo",
                "Turrell",
                "Twin Groves",
                "Tyronza",
                "Ulm",
                "Valley Springs",
                "Van Buren",
                "Vandervoort",
                "Victoria",
                "Vilonia",
                "Viola",
                "Wabbaseka",
                "Waldenburg",
                "Waldo",
                "Waldron",
                "Walnut Ridge",
                "Ward",
                "Warren",
                "Washington",
                "Watson",
                "Weiner",
                "Weldon",
                "West Crossett",
                "West Fork",
                "West Helena",
                "West Memphis",
                "West Point",
                "Western Grove",
                "Wheatley",
                "Whelen Springs",
                "White Hall",
                "Wickes",
                "Widener",
                "Wiederkehr Village",
                "Williford",
                "Willisville",
                "Wilmar",
                "Wilmot",
                "Wilson",
                "Wilton",
                "Winchester",
                "Winslow",
                "Winthrop",
                "Woodson",
                "Wooster",
                "Wrightsville",
                "Wynne",
                "Yellville",
                "Zinc"
            ],
            "Byram": [
                "Adona",
                "Alexander",
                "Alicia",
                "Allport",
                "Alma",
                "Almyra",
                "Alpena",
                "Altheimer",
                "Altus",
                "Amagon",
                "Amity",
                "Anthonyville",
                "Antoine",
                "Arkadelphia",
                "Arkansas City",
                "Ash Flat",
                "Ashdown",
                "Atkins",
                "Aubrey",
                "Augusta",
                "Austin",
                "Avoca",
                "Bald Knob",
                "Banks",
                "Barling",
                "Bassett",
                "Batesville",
                "Bauxite",
                "Bay",
                "Bearden",
                "Beaver",
                "Beebe",
                "Beedeville",
                "Bella Vista",
                "Bellefonte",
                "Belleville",
                "Ben Lomond",
                "Benton",
                "Bentonville",
                "Bergman",
                "Berryville",
                "Bethel Heights",
                "Big Flat",
                "Bigelow",
                "Biggers",
                "Birdsong",
                "Black Oak",
                "Black Rock",
                "Black Springs",
                "Blevins",
                "Blue Eye",
                "Blue Mountain",
                "Bluff City",
                "Blytheville",
                "Bodcaw",
                "Bonanza",
                "Bono",
                "Booneville",
                "Bradford",
                "Bradley",
                "Branch",
                "Briarcliff",
                "Brinkley",
                "Brookland",
                "Bryant",
                "Buckner",
                "Bull Shoals",
                "Burdette",
                "Cabot",
                "Caddo Valley",
                "Caldwell",
                "Cale",
                "Calico Rock",
                "Calion",
                "Camden",
                "Cammack Village",
                "Campbell Station",
                "Caraway",
                "Carlisle",
                "Carthage",
                "Casa",
                "Cash",
                "Caulksville",
                "Cave City",
                "Cave Springs",
                "Cedarville",
                "Centerton",
                "Central City",
                "Charleston",
                "Cherokee Village",
                "Cherry Valley",
                "Chester",
                "Chidester",
                "Clarendon",
                "Clarksville",
                "Clinton",
                "Coal Hill",
                "College City",
                "College Station",
                "Colt",
                "Concord",
                "Conway",
                "Corinth",
                "Corning",
                "Cotter",
                "Cotton Plant",
                "Cove",
                "Coy",
                "Crawfordsville",
                "Crossett",
                "Cushman",
                "Daisy",
                "Damascus",
                "Danville",
                "Dardanelle",
                "Datto",
                "De Queen",
                "De Valls Bluff",
                "De Witt",
                "Decatur",
                "Delaplaine",
                "Delight",
                "Dell",
                "Denning",
                "Dermott",
                "Des Arc",
                "Diamond City",
                "Diaz",
                "Dierks",
                "Donaldson",
                "Dover",
                "Dumas",
                "Dyer",
                "Dyess",
                "Earle",
                "East Camden",
                "East End",
                "Edmondson",
                "Egypt",
                "El Dorado",
                "Elaine",
                "Elkins",
                "Elm Springs",
                "Emerson",
                "Emmet",
                "England",
                "Enola",
                "Etowah",
                "Eudora",
                "Eureka Springs",
                "Evening Shade",
                "Everton",
                "Fairfield Bay",
                "Fargo",
                "Farmington",
                "Fayetteville",
                "Felsenthal",
                "Fifty-six",
                "Fisher",
                "Flippin",
                "Fordyce",
                "Foreman",
                "Forrest City",
                "Fort Smith",
                "Fouke",
                "Fountain Hill",
                "Fountain Lake",
                "Fourche",
                "Franklin",
                "Fredonia (biscoe)",
                "Friendship",
                "Fulton",
                "Garfield",
                "Garland",
                "Garner",
                "Gassville",
                "Gateway",
                "Gentry",
                "Georgetown",
                "Gibson",
                "Gilbert",
                "Gillett",
                "Gillham",
                "Gilmore",
                "Glenwood",
                "Goshen",
                "Gosnell",
                "Gould",
                "Grady",
                "Grannis",
                "Gravel Ridge",
                "Gravette",
                "Green Forest",
                "Greenbrier",
                "Greenland",
                "Greenway",
                "Greenwood",
                "Greers Ferry",
                "Griffithville",
                "Grubbs",
                "Guion",
                "Gum Springs",
                "Gurdon",
                "Guy",
                "Hackett",
                "Hamburg",
                "Hampton",
                "Hardy",
                "Harrell",
                "Harrisburg",
                "Harrison",
                "Hartford",
                "Hartman",
                "Haskell",
                "Hatfield",
                "Havana",
                "Haynes",
                "Hazen",
                "Heber Springs",
                "Hector",
                "Helena",
                "Hensley",
                "Hermitage",
                "Hickory Ridge",
                "Higden",
                "Higginson",
                "Highfill",
                "Highland",
                "Hindsville",
                "Holland",
                "Holly Grove",
                "Hope",
                "Horatio",
                "Horseshoe Bend",
                "Horseshoe Lake",
                "Hot Springs",
                "Hot Springs Village",
                "Houston",
                "Hoxie",
                "Hughes",
                "Humnoke",
                "Humphrey",
                "Hunter",
                "Huntington",
                "Huntsville",
                "Huttig",
                "Imboden",
                "Jacksonport",
                "Jacksonville",
                "Jasper",
                "Jennette",
                "Jericho",
                "Jerome",
                "Johnson",
                "Joiner",
                "Jonesboro",
                "Judsonia",
                "Junction City",
                "Keiser",
                "Kensett",
                "Keo",
                "Kibler",
                "Kingsland",
                "Knobel",
                "Knoxville",
                "Lafe",
                "Lagrange",
                "Lake City",
                "Lake Hamilton",
                "Lake View",
                "Lake Village",
                "Lakeview",
                "Lamar",
                "Lavaca",
                "Leachville",
                "Lead Hill",
                "Leola",
                "Lepanto",
                "Leslie",
                "Letona",
                "Lewisville",
                "Lexa",
                "Lincoln",
                "Little Flock",
                "Little Rock",
                "Lockesburg",
                "London",
                "Lonoke",
                "Lonsdale",
                "Louann",
                "Lowell",
                "Luxora",
                "Lynn",
                "Madison",
                "Magazine",
                "Magness",
                "Magnolia",
                "Malvern",
                "Mammoth Spring",
                "Manila",
                "Mansfield",
                "Marianna",
                "Marie",
                "Marion",
                "Marked Tree",
                "Marmaduke",
                "Marshall",
                "Marvell",
                "Maumelle",
                "Mayflower",
                "Maynard",
                "Mcalmont",
                "Mccaskill",
                "Mccrory",
                "Mcdougal",
                "Mcgehee",
                "Mcnab",
                "Mcneil",
                "Mcrae",
                "Melbourne",
                "Mena",
                "Menifee",
                "Midland",
                "Mineral Springs",
                "Minturn",
                "Mitchellville",
                "Monette",
                "Monticello",
                "Montrose",
                "Moorefield",
                "Moro",
                "Morrilton",
                "Morrison Bluff",
                "Mount Ida",
                "Mount Pleasant",
                "Mount Vernon",
                "Mountain Home",
                "Mountain Pine",
                "Mountain View",
                "Mountainburg",
                "Mulberry",
                "Murfreesboro",
                "Nashville",
                "Newark",
                "Newport",
                "Nimmons",
                "Norfork",
                "Norman",
                "Norphlet",
                "North Crossett",
                "North Little Rock",
                "O Kean",
                "Oak Grove",
                "Oak Grove Heights",
                "Oakhaven",
                "Oden",
                "Ogden",
                "Oil Trough",
                "Okolona",
                "Ola",
                "Omaha",
                "Oppelo",
                "Osceola",
                "Oxford",
                "Ozan",
                "Ozark",
                "Palestine",
                "Pangburn",
                "Paragould",
                "Paris",
                "Parkdale",
                "Parkers-iron Springs",
                "Parkin",
                "Patmos",
                "Patterson",
                "Pea Ridge",
                "Peach Orchard",
                "Perla",
                "Perry",
                "Perrytown",
                "Perryville",
                "Piggott",
                "Pindall",
                "Pine Bluff",
                "Pineville",
                "Piney",
                "Plainview",
                "Pleasant Plains",
                "Plumerville",
                "Pocahontas",
                "Pollard",
                "Portia",
                "Portland",
                "Pottsville",
                "Powhatan",
                "Poyen",
                "Prairie Creek",
                "Prairie Grove",
                "Prattsville",
                "Prescott",
                "Pyatt",
                "Quitman",
                "Ratcliff",
                "Ravenden",
                "Ravenden Springs",
                "Reader",
                "Rector",
                "Redfield",
                "Reed",
                "Reyno",
                "Rison",
                "Rockport",
                "Rockwell",
                "Roe",
                "Rogers",
                "Rondo",
                "Rose Bud",
                "Rosston",
                "Rudy",
                "Russell",
                "Russellville",
                "Salem",
                "Salesville",
                "Scott",
                "Scranton",
                "Searcy",
                "Sedgwick",
                "Shannon Hills",
                "Sheridan",
                "Sherrill",
                "Sherwood",
                "Shirley",
                "Sidney",
                "Siloam Springs",
                "Smackover",
                "Smithville",
                "South Lead Hill",
                "Sparkman",
                "Springdale",
                "Springtown",
                "St. Charles",
                "St. Francis",
                "St. Joe",
                "St. Paul",
                "Stamps",
                "Star City",
                "Stephens",
                "Strawberry",
                "Strong",
                "Stuttgart",
                "Subiaco",
                "Success",
                "Sulphur Rock",
                "Sulphur Springs",
                "Summit",
                "Sunset",
                "Sweet Home",
                "Swifton",
                "Taylor",
                "Texarkana",
                "Thornton",
                "Tillar",
                "Tinsman",
                "Tollette",
                "Tontitown",
                "Traskwood",
                "Trumann",
                "Tuckerman",
                "Tull",
                "Tupelo",
                "Turrell",
                "Twin Groves",
                "Tyronza",
                "Ulm",
                "Valley Springs",
                "Van Buren",
                "Vandervoort",
                "Victoria",
                "Vilonia",
                "Viola",
                "Wabbaseka",
                "Waldenburg",
                "Waldo",
                "Waldron",
                "Walnut Ridge",
                "Ward",
                "Warren",
                "Washington",
                "Watson",
                "Weiner",
                "Weldon",
                "West Crossett",
                "West Fork",
                "West Helena",
                "West Memphis",
                "West Point",
                "Western Grove",
                "Wheatley",
                "Whelen Springs",
                "White Hall",
                "Wickes",
                "Widener",
                "Wiederkehr Village",
                "Williford",
                "Willisville",
                "Wilmar",
                "Wilmot",
                "Wilson",
                "Wilton",
                "Winchester",
                "Winslow",
                "Winthrop",
                "Woodson",
                "Wooster",
                "Wrightsville",
                "Wynne",
                "Yellville",
                "Zinc"
            ],
            "California": [
                "Acton",
                "Adelanto",
                "Agoura Hills",
                "Alameda",
                "Alamo",
                "Albany",
                "Alhambra",
                "Aliso Viejo",
                "Almanor",
                "Alondra Park",
                "Alpaugh",
                "Alpine",
                "Alpine Village",
                "Alta Sierra",
                "Altadena",
                "Alturas",
                "Alum Rock",
                "Amador City",
                "American Canyon",
                "Amesti",
                "Anaheim",
                "Anderson",
                "Angels City",
                "Angwin",
                "Antioch",
                "Apple Valley",
                "Aptos",
                "Aptos Hills-larkin Valley",
                "Arbuckle",
                "Arcadia",
                "Arcata",
                "Arden-arcade",
                "Armona",
                "Arnold",
                "Aromas",
                "Arroyo Grande",
                "Artesia",
                "Arvin",
                "Ashland",
                "Atascadero",
                "Atherton",
                "Atwater",
                "Auberry",
                "Auburn",
                "August",
                "Avalon",
                "Avenal",
                "Avery",
                "Avocado Heights",
                "Azusa",
                "Bakersfield",
                "Baldwin Park",
                "Banning",
                "Barstow",
                "Bay Point",
                "Bayview",
                "Bayview-montalvin",
                "Baywood-los Osos",
                "Beale Afb",
                "Bear Valley",
                "Bear Valley Springs",
                "Beaumont",
                "Beckwourth",
                "Belden",
                "Bell",
                "Bell Gardens",
                "Bellflower",
                "Belmont",
                "Belvedere",
                "Ben Lomond",
                "Benicia",
                "Berkeley",
                "Bermuda Dunes",
                "Bertsch-oceanview",
                "Bethel Island",
                "Beverly Hills",
                "Big Bear City",
                "Big Bear Lake",
                "Big Bend",
                "Big Pine",
                "Big River",
                "Biggs",
                "Biola",
                "Bishop",
                "Black Point-green Point",
                "Blackhawk-camino Tassajara",
                "Blairsden",
                "Bloomington",
                "Blue Lake",
                "Bluewater",
                "Blythe",
                "Bodega Bay",
                "Bodfish",
                "Bolinas",
                "Bombay Beach",
                "Bonadelle Ranchos-madera Ranchos",
                "Bonita",
                "Bonsall",
                "Bootjack",
                "Boron",
                "Boronda",
                "Borrego Springs",
                "Bostonia",
                "Boulder Creek",
                "Bowles",
                "Boyes Hot Springs",
                "Bradbury",
                "Bradley",
                "Brawley",
                "Brea",
                "Brentwood",
                "Bret Harte",
                "Brisbane",
                "Broadmoor",
                "Bucks Lake",
                "Buellton",
                "Buena Park",
                "Buena Vista",
                "Burbank",
                "Burlingame",
                "Burney",
                "Buttonwillow",
                "Byron",
                "Bystrom",
                "C-road",
                "Cabazon",
                "Calabasas",
                "Calexico",
                "California City",
                "Calimesa",
                "Calipatria",
                "Calistoga",
                "Calwa",
                "Camarillo",
                "Cambria",
                "Cambrian Park",
                "Cameron Park",
                "Camp Pendleton North",
                "Camp Pendleton South",
                "Campbell",
                "Cantua Creek",
                "Canyon Lake",
                "Canyondam",
                "Capitola",
                "Caribou",
                "Carlsbad",
                "Carmel Valley Village",
                "Carmel-by-the-sea",
                "Carmichael",
                "Carpinteria",
                "Carrick",
                "Carson",
                "Cartago",
                "Caruthers",
                "Casa Conejo",
                "Casa De Oro-mount Helix",
                "Castro Valley",
                "Castroville",
                "Cathedral City",
                "Cayucos",
                "Ceres",
                "Cerritos",
                "Challenge-brownsville",
                "Channel Islands Beach",
                "Charter Oak",
                "Cherry Valley",
                "Cherryland",
                "Chester",
                "Chico",
                "Chilcoot-vinton",
                "China Lake Acres",
                "Chinese Camp",
                "Chino",
                "Chino Hills",
                "Chowchilla",
                "Chualar",
                "Chula Vista",
                "Citrus",
                "Citrus Heights",
                "Claremont",
                "Clayton",
                "Clearlake",
                "Clearlake Oaks",
                "Clio",
                "Cloverdale",
                "Clovis",
                "Clyde",
                "Coachella",
                "Coalinga",
                "Cobb",
                "Colfax",
                "Colma",
                "Colton",
                "Columbia",
                "Colusa",
                "Commerce",
                "Compton",
                "Concord",
                "Concow",
                "Copperopolis",
                "Corcoran",
                "Corning",
                "Corona",
                "Coronado",
                "Corralitos",
                "Corte Madera",
                "Costa Mesa",
                "Cotati",
                "Coto De Caza",
                "Cottonwood",
                "Country Club",
                "Covelo",
                "Covina",
                "Crescent City",
                "Crescent City North",
                "Crescent Mills",
                "Crest",
                "Crestline",
                "Crockett",
                "Cromberg",
                "Cudahy",
                "Culver City",
                "Cupertino",
                "Cutler",
                "Cutten",
                "Cypress",
                "Daly City",
                "Dana Point",
                "Danville",
                "Darwin",
                "Davis",
                "Day Valley",
                "Deer Park",
                "Del Aire",
                "Del Mar",
                "Del Monte Forest",
                "Del Rey",
                "Del Rey Oaks",
                "Del Rio",
                "Delano",
                "Delhi",
                "Delleker",
                "Denair",
                "Derby Acres",
                "Desert Hot Springs",
                "Desert Shores",
                "Desert View Highlands",
                "Diablo",
                "Diamond Bar",
                "Diamond Springs",
                "Dillon Beach",
                "Dinuba",
                "Discovery Bay",
                "Dixon",
                "Dixon Lane-meadow Creek",
                "Dollar Point",
                "Dorrington",
                "Dorris",
                "Dos Palos",
                "Downey",
                "Duarte",
                "Dublin",
                "Ducor",
                "Dunsmuir",
                "Durham",
                "Dustin Acres",
                "Earlimart",
                "East Blythe",
                "East Compton",
                "East Foothills",
                "East Hemet",
                "East La Mirada",
                "East Los Angeles",
                "East Oakdale",
                "East Orosi",
                "East Palo Alto",
                "East Pasadena",
                "East Porterville",
                "East Quincy",
                "East Richmond Heights",
                "East San Gabriel",
                "East Shore",
                "East Sonora",
                "Easton",
                "Edgewood",
                "Edwards Afb",
                "El Cajon",
                "El Centro",
                "El Cerrito",
                "El Dorado Hills",
                "El Granada",
                "El Monte",
                "El Paso De Robles (paso Robles)",
                "El Rio",
                "El Segundo",
                "El Sobrante",
                "El Verano",
                "Eldridge",
                "Elk Grove",
                "Elkhorn",
                "Elmira",
                "Emerald Lake Hills",
                "Emeryville",
                "Empire",
                "Encinitas",
                "Escalon",
                "Escondido",
                "Esparto",
                "Etna",
                "Eureka",
                "Exeter",
                "Fair Oaks",
                "Fairbanks Ranch",
                "Fairfax",
                "Fairfield",
                "Fairview",
                "Fall River Mills",
                "Fallbrook",
                "Farmersville",
                "Farmington",
                "Fellows",
                "Felton",
                "Ferndale",
                "Fetters Hot Springs-agua Caliente",
                "Fillmore",
                "Firebaugh",
                "Florence-graham",
                "Florin",
                "Folsom",
                "Fontana",
                "Foothill Farms",
                "Foothill Ranch",
                "Ford City",
                "Forest Meadows",
                "Foresthill",
                "Forestville",
                "Fort Bragg",
                "Fort Jones",
                "Fortuna",
                "Foster City",
                "Fountain Valley",
                "Fowler",
                "Frazier Park",
                "Freedom",
                "Fremont",
                "French Camp",
                "French Gulch",
                "Fresno",
                "Friant",
                "Fruitdale",
                "Fullerton",
                "Furnace Creek",
                "Galt",
                "Garden Acres",
                "Garden Grove",
                "Gardena",
                "Gazelle",
                "Georgetown",
                "Gerber-las Flores",
                "Gilroy",
                "Glen Avon",
                "Glen Ellen",
                "Glendale",
                "Glendora",
                "Gold River",
                "Golden Hills",
                "Goleta",
                "Gonzales",
                "Goshen",
                "Graeagle",
                "Grand Terrace",
                "Granite Bay",
                "Granite Hills",
                "Grass Valley",
                "Graton",
                "Grayson",
                "Green Valley",
                "Greenfield",
                "Greenhorn",
                "Greenview",
                "Greenville",
                "Grenada",
                "Gridley",
                "Groveland-big Oak Flat",
                "Grover Beach",
                "Guadalupe",
                "Guerneville",
                "Gustine",
                "Hacienda Heights",
                "Half Moon Bay",
                "Hamilton Branch",
                "Hamilton City",
                "Hanford",
                "Harbison Canyon",
                "Hawaiian Gardens",
                "Hawthorne",
                "Hayfork",
                "Hayward",
                "Healdsburg",
                "Heber",
                "Hemet",
                "Hercules",
                "Hermosa Beach",
                "Hesperia",
                "Hickman",
                "Hidden Hills",
                "Hidden Meadows",
                "Hidden Valley Lake",
                "Highgrove",
                "Highland",
                "Highlands-baywood Park",
                "Hillsborough",
                "Hilmar-irwin",
                "Hollister",
                "Holtville",
                "Home Garden",
                "Home Gardens",
                "Homeland",
                "Homewood Canyon-valley Wells",
                "Hornbrook",
                "Hughson",
                "Humboldt Hill",
                "Huntington Beach",
                "Huntington Park",
                "Huron",
                "Hydesville",
                "Idyllwild-pine Cove",
                "Imperial",
                "Imperial Beach",
                "Independence",
                "Indian Falls",
                "Indian Wells",
                "Indio",
                "Industry",
                "Inglewood",
                "Interlaken",
                "Inverness",
                "Inyokern",
                "Ione",
                "Iron Horse",
                "Irvine",
                "Irwindale",
                "Isla Vista",
                "Isleton",
                "Ivanhoe",
                "Jackson",
                "Jamestown",
                "Jamul",
                "Johannesburg",
                "Johnsville",
                "Joshua Tree",
                "Julian",
                "Keddie",
                "Keeler",
                "Keene",
                "Kelseyville",
                "Kennedy",
                "Kensington",
                "Kentfield",
                "Kerman",
                "Kernville",
                "Kettleman City",
                "Keyes",
                "King City",
                "Kings Beach",
                "Kingsburg",
                "Kirkwood",
                "Klamath",
                "Knightsen",
                "La Canada Flintridge",
                "La Crescenta-montrose",
                "La Habra",
                "La Habra Heights",
                "La Mesa",
                "La Mirada",
                "La Palma",
                "La Porte",
                "La Presa",
                "La Puente",
                "La Quinta",
                "La Riviera",
                "La Verne",
                "Ladera Heights",
                "Lafayette",
                "Laguna",
                "Laguna Beach",
                "Laguna Hills",
                "Laguna Niguel",
                "Laguna West-lakeside",
                "Laguna Woods",
                "Lagunitas-forest Knolls",
                "Lake Almanor Country Club",
                "Lake Almanor Peninsula",
                "Lake Almanor West",
                "Lake Arrowhead",
                "Lake Davis",
                "Lake Elsinore",
                "Lake Forest",
                "Lake Isabella",
                "Lake Los Angeles",
                "Lake Nacimiento",
                "Lake Of The Pines",
                "Lake Of The Woods",
                "Lake San Marcos",
                "Lake Wildwood",
                "Lakehead-lakeshore",
                "Lakeland Village",
                "Lakeport",
                "Lakeside",
                "Lakeview",
                "Lakewood",
                "Lamont",
                "Lanare",
                "Lancaster",
                "Larkfield-wikiup",
                "Larkspur",
                "Las Flores",
                "Las Lomas",
                "Lathrop",
                "Laton",
                "Lawndale",
                "Laytonville",
                "Le Grand",
                "Lebec",
                "Lemon Cove",
                "Lemon Grove",
                "Lemoore",
                "Lemoore Station",
                "Lennox",
                "Lenwood",
                "Lewiston",
                "Lexington Hills",
                "Lincoln",
                "Lincoln Village",
                "Linda",
                "Linden",
                "Lindsay",
                "Little Grass Valley",
                "Littlerock",
                "Live Oak",
                "Livermore",
                "Livingston",
                "Lockeford",
                "Lodi",
                "Loma Linda",
                "Loma Rica",
                "Lomita",
                "Lompoc",
                "London",
                "Lone Pine",
                "Long Beach",
                "Loomis",
                "Los Alamitos",
                "Los Alamos",
                "Los Altos",
                "Los Altos Hills",
                "Los Angeles",
                "Los Banos",
                "Los Gatos",
                "Los Molinos",
                "Lost Hills",
                "Lower Lake",
                "Loyalton",
                "Loyola",
                "Lucas Valley-marinwood",
                "Lucerne",
                "Lynwood",
                "Macdoel",
                "Madera",
                "Madera Acres",
                "Magalia",
                "Malibu",
                "Mammoth Lakes",
                "Manhattan Beach",
                "Manteca",
                "Manton",
                "March Afb",
                "Maricopa",
                "Marina",
                "Marina Del Rey",
                "Mariposa",
                "Markleeville",
                "Martinez",
                "Marysville",
                "Mayflower Village",
                "Maywood",
                "Mcarthur",
                "Mccloud",
                "Mcfarland",
                "Mckinleyville",
                "Mckittrick",
                "Meadow Valley",
                "Meadow Vista",
                "Mecca",
                "Meiners Oaks",
                "Mendocino",
                "Mendota",
                "Menlo Park",
                "Mentone",
                "Merced",
                "Mesa",
                "Mesa Vista",
                "Mettler",
                "Mi-wuk Village",
                "Middletown",
                "Mill Valley",
                "Millbrae",
                "Millville",
                "Milpitas",
                "Mineral",
                "Mira Loma",
                "Mira Monte",
                "Mission Canyon",
                "Mission Hills",
                "Mission Viejo",
                "Modesto",
                "Mohawk Vista",
                "Mojave",
                "Mokelumne Hill",
                "Mono Vista",
                "Monrovia",
                "Montague",
                "Montara",
                "Montclair",
                "Monte Rio",
                "Monte Sereno",
                "Montebello",
                "Montecito",
                "Monterey",
                "Monterey Park",
                "Montgomery Creek",
                "Moorpark",
                "Morada",
                "Moraga",
                "Moreno Valley",
                "Morgan Hill",
                "Morongo Valley",
                "Morro Bay",
                "Moss Beach",
                "Moss Landing",
                "Mount Hebron",
                "Mount Shasta",
                "Mountain Mesa",
                "Mountain Ranch",
                "Mountain View",
                "Mountain View Acres",
                "Muir Beach",
                "Murphys",
                "Murrieta",
                "Murrieta Hot Springs",
                "Muscoy",
                "Myrtletown",
                "Napa",
                "National City",
                "Nebo Center",
                "Needles",
                "Nevada City",
                "Newark",
                "Newman",
                "Newport Beach",
                "Newport Coast",
                "Nice",
                "Niland",
                "Nipomo",
                "Norco",
                "North Auburn",
                "North Edwards",
                "North El Monte",
                "North Fair Oaks",
                "North Highlands",
                "North Lakeport",
                "North Woodbridge",
                "Norwalk",
                "Novato",
                "Nuevo",
                "Oak Park",
                "Oak View",
                "Oakdale",
                "Oakhurst",
                "Oakland",
                "Oakley",
                "Occidental",
                "Oceano",
                "Oceanside",
                "Ocotillo",
                "Oildale",
                "Ojai",
                "Olancha",
                "Olivehurst",
                "Ontario",
                "Onyx",
                "Opal Cliffs",
                "Orange",
                "Orange Cove",
                "Orangevale",
                "Orcutt",
                "Orinda",
                "Orland",
                "Orosi",
                "Oroville",
                "Oroville East",
                "Oxnard",
                "Pacheco",
                "Pacific Grove",
                "Pacifica",
                "Pajaro",
                "Palermo",
                "Palm Desert",
                "Palm Springs",
                "Palmdale",
                "Palo Alto",
                "Palo Cedro",
                "Palo Verde",
                "Palos Verdes Estates",
                "Paradise",
                "Paramount",
                "Parksdale",
                "Parkway-south Sacramento",
                "Parkwood",
                "Parlier",
                "Pasadena",
                "Patterson",
                "Paxton",
                "Pearsonville",
                "Pedley",
                "Penn Valley",
                "Perris",
                "Petaluma",
                "Phoenix Lake-cedar Ridge",
                "Pico Rivera",
                "Piedmont",
                "Pine Hills",
                "Pine Mountain Club",
                "Pine Valley",
                "Pinole",
                "Piru",
                "Pismo Beach",
                "Pittsburg",
                "Pixley",
                "Placentia",
                "Placerville",
                "Planada",
                "Pleasant Hill",
                "Pleasanton",
                "Plumas Eureka",
                "Plymouth",
                "Point Arena",
                "Point Reyes Station",
                "Pollock Pines",
                "Pomona",
                "Poplar-cotton Center",
                "Port Costa",
                "Port Hueneme",
                "Porterville",
                "Portola",
                "Portola Hills",
                "Portola Valley",
                "Poway",
                "Prattville",
                "Prunedale",
                "Quail Valley",
                "Quartz Hill",
                "Quincy",
                "Rail Road Flat",
                "Rainbow",
                "Raisin City",
                "Ramona",
                "Rancho Calaveras",
                "Rancho Cordova",
                "Rancho Cucamonga",
                "Rancho Mirage",
                "Rancho Murieta",
                "Rancho Palos Verdes",
                "Rancho San Diego",
                "Rancho Santa Fe",
                "Rancho Santa Margarita",
                "Rancho Tehama Reserve",
                "Randsburg",
                "Red Bluff",
                "Redding",
                "Redlands",
                "Redondo Beach",
                "Redway",
                "Redwood City",
                "Reedley",
                "Rialto",
                "Richgrove",
                "Richmond",
                "Ridgecrest",
                "Ridgemark",
                "Rio Del Mar",
                "Rio Dell",
                "Rio Linda",
                "Rio Vista",
                "Ripon",
                "Riverbank",
                "Riverdale",
                "Riverdale Park",
                "Riverside",
                "Rocklin",
                "Rodeo",
                "Rohnert Park",
                "Rolling Hills",
                "Rolling Hills Estates",
                "Rollingwood",
                "Romoland",
                "Rosamond",
                "Rosedale",
                "Roseland",
                "Rosemead",
                "Rosemont",
                "Roseville",
                "Ross",
                "Rossmoor",
                "Round Mountain",
                "Round Valley",
                "Rowland Heights",
                "Rubidoux",
                "Running Springs",
                "Sacramento",
                "Salida",
                "Salinas",
                "Salton City",
                "Salton Sea Beach",
                "San Andreas",
                "San Anselmo",
                "San Antonio Heights",
                "San Ardo",
                "San Bernardino",
                "San Bruno",
                "San Buenaventura (ventura)",
                "San Carlos",
                "San Clemente",
                "San Diego",
                "San Diego Country Estates",
                "San Dimas",
                "San Fernando",
                "San Francisco",
                "San Gabriel",
                "San Geronimo",
                "San Jacinto",
                "San Joaquin",
                "San Joaquin Hills",
                "San Jose",
                "San Juan Bautista",
                "San Juan Capistrano",
                "San Leandro",
                "San Lorenzo",
                "San Lucas",
                "San Luis Obispo",
                "San Marcos",
                "San Marino",
                "San Martin",
                "San Mateo",
                "San Miguel",
                "San Pablo",
                "San Rafael",
                "San Ramon",
                "Sand City",
                "Sanger",
                "Santa Ana",
                "Santa Barbara",
                "Santa Clara",
                "Santa Clarita",
                "Santa Cruz",
                "Santa Fe Springs",
                "Santa Maria",
                "Santa Monica",
                "Santa Paula",
                "Santa Rosa",
                "Santa Venetia",
                "Santa Ynez",
                "Santee",
                "Saratoga",
                "Sausalito",
                "Scotts Valley",
                "Seal Beach",
                "Searles Valley",
                "Seaside",
                "Sebastopol",
                "Sedco Hills",
                "Seeley",
                "Selma",
                "Seven Trees",
                "Shackelford",
                "Shafter",
                "Shandon",
                "Shasta Lake",
                "Shaver Lake",
                "Shingle Springs",
                "Shingletown",
                "Shoshone",
                "Sierra Madre",
                "Signal Hill",
                "Simi Valley",
                "Solana Beach",
                "Soledad",
                "Solvang",
                "Sonoma",
                "Sonora",
                "Soquel",
                "Soulsbyville",
                "South Dos Palos",
                "South El Monte",
                "South Gate",
                "South Lake Tahoe",
                "South Oroville",
                "South Pasadena",
                "South San Francisco",
                "South San Gabriel",
                "South San Jose Hills",
                "South Taft",
                "South Whittier",
                "South Woodbridge",
                "South Yuba City",
                "Spreckels",
                "Spring Garden",
                "Spring Valley",
                "Springville",
                "Squaw Valley",
                "Squirrel Mountain Valley",
                "St. Helena",
                "Stallion Springs",
                "Stanford",
                "Stanton",
                "Stinson Beach",
                "Stockton",
                "Storrie",
                "Stratford",
                "Strathmore",
                "Strawberry",
                "Suisun City",
                "Summerland",
                "Sun City",
                "Sunnyside-tahoe City",
                "Sunnyslope",
                "Sunnyvale",
                "Sunol",
                "Sunol-midtown",
                "Susanville",
                "Sutter",
                "Sutter Creek",
                "Taft",
                "Taft Heights",
                "Taft Mosswood",
                "Tahoe Vista",
                "Talmage",
                "Tamalpais-homestead Valley",
                "Tara Hills",
                "Taylorsville",
                "Tecopa",
                "Tehachapi",
                "Tehama",
                "Temecula",
                "Temelec",
                "Temple City",
                "Templeton",
                "Tennant",
                "Terra Bella",
                "Thermalito",
                "Thousand Oaks",
                "Thousand Palms",
                "Three Rivers",
                "Tiburon",
                "Tierra Buena",
                "Tipton",
                "Tobin",
                "Tomales",
                "Toro Canyon",
                "Torrance",
                "Tracy",
                "Tranquillity",
                "Traver",
                "Trinidad",
                "Truckee",
                "Tulare",
                "Tulelake",
                "Tuolumne City",
                "Tupman",
                "Turlock",
                "Tustin",
                "Tustin Foothills",
                "Twain",
                "Twain Harte",
                "Twentynine Palms",
                "Twentynine Palms Base",
                "Twin Lakes",
                "Ukiah",
                "Union City",
                "Upland",
                "Upper Lake",
                "Vacaville",
                "Val Verde",
                "Valinda",
                "Valle Vista",
                "Vallecito",
                "Vallejo",
                "Valley Acres",
                "Valley Center",
                "Valley Ranch",
                "Valley Springs",
                "Vandenberg Afb",
                "Vandenberg Village",
                "Vernon",
                "Victorville",
                "View Park-windsor Hills",
                "Villa Park",
                "Vincent",
                "Vine Hill",
                "Vineyard",
                "Visalia",
                "Vista",
                "Waldon",
                "Wallace",
                "Walnut",
                "Walnut Creek",
                "Walnut Grove",
                "Walnut Park",
                "Wasco",
                "Waterford",
                "Watsonville",
                "Weaverville",
                "Weed",
                "Weedpatch",
                "Weldon",
                "West Athens",
                "West Bishop",
                "West Carson",
                "West Compton",
                "West Covina",
                "West Hollywood",
                "West Menlo Park",
                "West Modesto",
                "West Point",
                "West Puente Valley",
                "West Sacramento",
                "West Whittier-los Nietos",
                "Westhaven-moonstone",
                "Westlake Village",
                "Westley",
                "Westminster",
                "Westmont",
                "Westmorland",
                "Westwood",
                "Wheatland",
                "Whitehawk",
                "Whittier",
                "Wildomar",
                "Wilkerson",
                "Williams",
                "Willits",
                "Willow Creek",
                "Willowbrook",
                "Willows",
                "Wilton",
                "Winchester",
                "Windsor",
                "Winter Gardens",
                "Winterhaven",
                "Winters",
                "Winton",
                "Wofford Heights",
                "Woodacre",
                "Woodcrest",
                "Woodlake",
                "Woodland",
                "Woodside",
                "Woodville",
                "Wrightwood",
                "Yorba Linda",
                "Yosemite Lakes",
                "Yosemite Valley",
                "Yountville",
                "Yreka",
                "Yuba City",
                "Yucaipa",
                "Yucca Valley"
            ],
            "Cokato": [
                "Acton",
                "Adelanto",
                "Agoura Hills",
                "Alameda",
                "Alamo",
                "Albany",
                "Alhambra",
                "Aliso Viejo",
                "Almanor",
                "Alondra Park",
                "Alpaugh",
                "Alpine",
                "Alpine Village",
                "Alta Sierra",
                "Altadena",
                "Alturas",
                "Alum Rock",
                "Amador City",
                "American Canyon",
                "Amesti",
                "Anaheim",
                "Anderson",
                "Angels City",
                "Angwin",
                "Antioch",
                "Apple Valley",
                "Aptos",
                "Aptos Hills-larkin Valley",
                "Arbuckle",
                "Arcadia",
                "Arcata",
                "Arden-arcade",
                "Armona",
                "Arnold",
                "Aromas",
                "Arroyo Grande",
                "Artesia",
                "Arvin",
                "Ashland",
                "Atascadero",
                "Atherton",
                "Atwater",
                "Auberry",
                "Auburn",
                "August",
                "Avalon",
                "Avenal",
                "Avery",
                "Avocado Heights",
                "Azusa",
                "Bakersfield",
                "Baldwin Park",
                "Banning",
                "Barstow",
                "Bay Point",
                "Bayview",
                "Bayview-montalvin",
                "Baywood-los Osos",
                "Beale Afb",
                "Bear Valley",
                "Bear Valley Springs",
                "Beaumont",
                "Beckwourth",
                "Belden",
                "Bell",
                "Bell Gardens",
                "Bellflower",
                "Belmont",
                "Belvedere",
                "Ben Lomond",
                "Benicia",
                "Berkeley",
                "Bermuda Dunes",
                "Bertsch-oceanview",
                "Bethel Island",
                "Beverly Hills",
                "Big Bear City",
                "Big Bear Lake",
                "Big Bend",
                "Big Pine",
                "Big River",
                "Biggs",
                "Biola",
                "Bishop",
                "Black Point-green Point",
                "Blackhawk-camino Tassajara",
                "Blairsden",
                "Bloomington",
                "Blue Lake",
                "Bluewater",
                "Blythe",
                "Bodega Bay",
                "Bodfish",
                "Bolinas",
                "Bombay Beach",
                "Bonadelle Ranchos-madera Ranchos",
                "Bonita",
                "Bonsall",
                "Bootjack",
                "Boron",
                "Boronda",
                "Borrego Springs",
                "Bostonia",
                "Boulder Creek",
                "Bowles",
                "Boyes Hot Springs",
                "Bradbury",
                "Bradley",
                "Brawley",
                "Brea",
                "Brentwood",
                "Bret Harte",
                "Brisbane",
                "Broadmoor",
                "Bucks Lake",
                "Buellton",
                "Buena Park",
                "Buena Vista",
                "Burbank",
                "Burlingame",
                "Burney",
                "Buttonwillow",
                "Byron",
                "Bystrom",
                "C-road",
                "Cabazon",
                "Calabasas",
                "Calexico",
                "California City",
                "Calimesa",
                "Calipatria",
                "Calistoga",
                "Calwa",
                "Camarillo",
                "Cambria",
                "Cambrian Park",
                "Cameron Park",
                "Camp Pendleton North",
                "Camp Pendleton South",
                "Campbell",
                "Cantua Creek",
                "Canyon Lake",
                "Canyondam",
                "Capitola",
                "Caribou",
                "Carlsbad",
                "Carmel Valley Village",
                "Carmel-by-the-sea",
                "Carmichael",
                "Carpinteria",
                "Carrick",
                "Carson",
                "Cartago",
                "Caruthers",
                "Casa Conejo",
                "Casa De Oro-mount Helix",
                "Castro Valley",
                "Castroville",
                "Cathedral City",
                "Cayucos",
                "Ceres",
                "Cerritos",
                "Challenge-brownsville",
                "Channel Islands Beach",
                "Charter Oak",
                "Cherry Valley",
                "Cherryland",
                "Chester",
                "Chico",
                "Chilcoot-vinton",
                "China Lake Acres",
                "Chinese Camp",
                "Chino",
                "Chino Hills",
                "Chowchilla",
                "Chualar",
                "Chula Vista",
                "Citrus",
                "Citrus Heights",
                "Claremont",
                "Clayton",
                "Clearlake",
                "Clearlake Oaks",
                "Clio",
                "Cloverdale",
                "Clovis",
                "Clyde",
                "Coachella",
                "Coalinga",
                "Cobb",
                "Colfax",
                "Colma",
                "Colton",
                "Columbia",
                "Colusa",
                "Commerce",
                "Compton",
                "Concord",
                "Concow",
                "Copperopolis",
                "Corcoran",
                "Corning",
                "Corona",
                "Coronado",
                "Corralitos",
                "Corte Madera",
                "Costa Mesa",
                "Cotati",
                "Coto De Caza",
                "Cottonwood",
                "Country Club",
                "Covelo",
                "Covina",
                "Crescent City",
                "Crescent City North",
                "Crescent Mills",
                "Crest",
                "Crestline",
                "Crockett",
                "Cromberg",
                "Cudahy",
                "Culver City",
                "Cupertino",
                "Cutler",
                "Cutten",
                "Cypress",
                "Daly City",
                "Dana Point",
                "Danville",
                "Darwin",
                "Davis",
                "Day Valley",
                "Deer Park",
                "Del Aire",
                "Del Mar",
                "Del Monte Forest",
                "Del Rey",
                "Del Rey Oaks",
                "Del Rio",
                "Delano",
                "Delhi",
                "Delleker",
                "Denair",
                "Derby Acres",
                "Desert Hot Springs",
                "Desert Shores",
                "Desert View Highlands",
                "Diablo",
                "Diamond Bar",
                "Diamond Springs",
                "Dillon Beach",
                "Dinuba",
                "Discovery Bay",
                "Dixon",
                "Dixon Lane-meadow Creek",
                "Dollar Point",
                "Dorrington",
                "Dorris",
                "Dos Palos",
                "Downey",
                "Duarte",
                "Dublin",
                "Ducor",
                "Dunsmuir",
                "Durham",
                "Dustin Acres",
                "Earlimart",
                "East Blythe",
                "East Compton",
                "East Foothills",
                "East Hemet",
                "East La Mirada",
                "East Los Angeles",
                "East Oakdale",
                "East Orosi",
                "East Palo Alto",
                "East Pasadena",
                "East Porterville",
                "East Quincy",
                "East Richmond Heights",
                "East San Gabriel",
                "East Shore",
                "East Sonora",
                "Easton",
                "Edgewood",
                "Edwards Afb",
                "El Cajon",
                "El Centro",
                "El Cerrito",
                "El Dorado Hills",
                "El Granada",
                "El Monte",
                "El Paso De Robles (paso Robles)",
                "El Rio",
                "El Segundo",
                "El Sobrante",
                "El Verano",
                "Eldridge",
                "Elk Grove",
                "Elkhorn",
                "Elmira",
                "Emerald Lake Hills",
                "Emeryville",
                "Empire",
                "Encinitas",
                "Escalon",
                "Escondido",
                "Esparto",
                "Etna",
                "Eureka",
                "Exeter",
                "Fair Oaks",
                "Fairbanks Ranch",
                "Fairfax",
                "Fairfield",
                "Fairview",
                "Fall River Mills",
                "Fallbrook",
                "Farmersville",
                "Farmington",
                "Fellows",
                "Felton",
                "Ferndale",
                "Fetters Hot Springs-agua Caliente",
                "Fillmore",
                "Firebaugh",
                "Florence-graham",
                "Florin",
                "Folsom",
                "Fontana",
                "Foothill Farms",
                "Foothill Ranch",
                "Ford City",
                "Forest Meadows",
                "Foresthill",
                "Forestville",
                "Fort Bragg",
                "Fort Jones",
                "Fortuna",
                "Foster City",
                "Fountain Valley",
                "Fowler",
                "Frazier Park",
                "Freedom",
                "Fremont",
                "French Camp",
                "French Gulch",
                "Fresno",
                "Friant",
                "Fruitdale",
                "Fullerton",
                "Furnace Creek",
                "Galt",
                "Garden Acres",
                "Garden Grove",
                "Gardena",
                "Gazelle",
                "Georgetown",
                "Gerber-las Flores",
                "Gilroy",
                "Glen Avon",
                "Glen Ellen",
                "Glendale",
                "Glendora",
                "Gold River",
                "Golden Hills",
                "Goleta",
                "Gonzales",
                "Goshen",
                "Graeagle",
                "Grand Terrace",
                "Granite Bay",
                "Granite Hills",
                "Grass Valley",
                "Graton",
                "Grayson",
                "Green Valley",
                "Greenfield",
                "Greenhorn",
                "Greenview",
                "Greenville",
                "Grenada",
                "Gridley",
                "Groveland-big Oak Flat",
                "Grover Beach",
                "Guadalupe",
                "Guerneville",
                "Gustine",
                "Hacienda Heights",
                "Half Moon Bay",
                "Hamilton Branch",
                "Hamilton City",
                "Hanford",
                "Harbison Canyon",
                "Hawaiian Gardens",
                "Hawthorne",
                "Hayfork",
                "Hayward",
                "Healdsburg",
                "Heber",
                "Hemet",
                "Hercules",
                "Hermosa Beach",
                "Hesperia",
                "Hickman",
                "Hidden Hills",
                "Hidden Meadows",
                "Hidden Valley Lake",
                "Highgrove",
                "Highland",
                "Highlands-baywood Park",
                "Hillsborough",
                "Hilmar-irwin",
                "Hollister",
                "Holtville",
                "Home Garden",
                "Home Gardens",
                "Homeland",
                "Homewood Canyon-valley Wells",
                "Hornbrook",
                "Hughson",
                "Humboldt Hill",
                "Huntington Beach",
                "Huntington Park",
                "Huron",
                "Hydesville",
                "Idyllwild-pine Cove",
                "Imperial",
                "Imperial Beach",
                "Independence",
                "Indian Falls",
                "Indian Wells",
                "Indio",
                "Industry",
                "Inglewood",
                "Interlaken",
                "Inverness",
                "Inyokern",
                "Ione",
                "Iron Horse",
                "Irvine",
                "Irwindale",
                "Isla Vista",
                "Isleton",
                "Ivanhoe",
                "Jackson",
                "Jamestown",
                "Jamul",
                "Johannesburg",
                "Johnsville",
                "Joshua Tree",
                "Julian",
                "Keddie",
                "Keeler",
                "Keene",
                "Kelseyville",
                "Kennedy",
                "Kensington",
                "Kentfield",
                "Kerman",
                "Kernville",
                "Kettleman City",
                "Keyes",
                "King City",
                "Kings Beach",
                "Kingsburg",
                "Kirkwood",
                "Klamath",
                "Knightsen",
                "La Canada Flintridge",
                "La Crescenta-montrose",
                "La Habra",
                "La Habra Heights",
                "La Mesa",
                "La Mirada",
                "La Palma",
                "La Porte",
                "La Presa",
                "La Puente",
                "La Quinta",
                "La Riviera",
                "La Verne",
                "Ladera Heights",
                "Lafayette",
                "Laguna",
                "Laguna Beach",
                "Laguna Hills",
                "Laguna Niguel",
                "Laguna West-lakeside",
                "Laguna Woods",
                "Lagunitas-forest Knolls",
                "Lake Almanor Country Club",
                "Lake Almanor Peninsula",
                "Lake Almanor West",
                "Lake Arrowhead",
                "Lake Davis",
                "Lake Elsinore",
                "Lake Forest",
                "Lake Isabella",
                "Lake Los Angeles",
                "Lake Nacimiento",
                "Lake Of The Pines",
                "Lake Of The Woods",
                "Lake San Marcos",
                "Lake Wildwood",
                "Lakehead-lakeshore",
                "Lakeland Village",
                "Lakeport",
                "Lakeside",
                "Lakeview",
                "Lakewood",
                "Lamont",
                "Lanare",
                "Lancaster",
                "Larkfield-wikiup",
                "Larkspur",
                "Las Flores",
                "Las Lomas",
                "Lathrop",
                "Laton",
                "Lawndale",
                "Laytonville",
                "Le Grand",
                "Lebec",
                "Lemon Cove",
                "Lemon Grove",
                "Lemoore",
                "Lemoore Station",
                "Lennox",
                "Lenwood",
                "Lewiston",
                "Lexington Hills",
                "Lincoln",
                "Lincoln Village",
                "Linda",
                "Linden",
                "Lindsay",
                "Little Grass Valley",
                "Littlerock",
                "Live Oak",
                "Livermore",
                "Livingston",
                "Lockeford",
                "Lodi",
                "Loma Linda",
                "Loma Rica",
                "Lomita",
                "Lompoc",
                "London",
                "Lone Pine",
                "Long Beach",
                "Loomis",
                "Los Alamitos",
                "Los Alamos",
                "Los Altos",
                "Los Altos Hills",
                "Los Angeles",
                "Los Banos",
                "Los Gatos",
                "Los Molinos",
                "Lost Hills",
                "Lower Lake",
                "Loyalton",
                "Loyola",
                "Lucas Valley-marinwood",
                "Lucerne",
                "Lynwood",
                "Macdoel",
                "Madera",
                "Madera Acres",
                "Magalia",
                "Malibu",
                "Mammoth Lakes",
                "Manhattan Beach",
                "Manteca",
                "Manton",
                "March Afb",
                "Maricopa",
                "Marina",
                "Marina Del Rey",
                "Mariposa",
                "Markleeville",
                "Martinez",
                "Marysville",
                "Mayflower Village",
                "Maywood",
                "Mcarthur",
                "Mccloud",
                "Mcfarland",
                "Mckinleyville",
                "Mckittrick",
                "Meadow Valley",
                "Meadow Vista",
                "Mecca",
                "Meiners Oaks",
                "Mendocino",
                "Mendota",
                "Menlo Park",
                "Mentone",
                "Merced",
                "Mesa",
                "Mesa Vista",
                "Mettler",
                "Mi-wuk Village",
                "Middletown",
                "Mill Valley",
                "Millbrae",
                "Millville",
                "Milpitas",
                "Mineral",
                "Mira Loma",
                "Mira Monte",
                "Mission Canyon",
                "Mission Hills",
                "Mission Viejo",
                "Modesto",
                "Mohawk Vista",
                "Mojave",
                "Mokelumne Hill",
                "Mono Vista",
                "Monrovia",
                "Montague",
                "Montara",
                "Montclair",
                "Monte Rio",
                "Monte Sereno",
                "Montebello",
                "Montecito",
                "Monterey",
                "Monterey Park",
                "Montgomery Creek",
                "Moorpark",
                "Morada",
                "Moraga",
                "Moreno Valley",
                "Morgan Hill",
                "Morongo Valley",
                "Morro Bay",
                "Moss Beach",
                "Moss Landing",
                "Mount Hebron",
                "Mount Shasta",
                "Mountain Mesa",
                "Mountain Ranch",
                "Mountain View",
                "Mountain View Acres",
                "Muir Beach",
                "Murphys",
                "Murrieta",
                "Murrieta Hot Springs",
                "Muscoy",
                "Myrtletown",
                "Napa",
                "National City",
                "Nebo Center",
                "Needles",
                "Nevada City",
                "Newark",
                "Newman",
                "Newport Beach",
                "Newport Coast",
                "Nice",
                "Niland",
                "Nipomo",
                "Norco",
                "North Auburn",
                "North Edwards",
                "North El Monte",
                "North Fair Oaks",
                "North Highlands",
                "North Lakeport",
                "North Woodbridge",
                "Norwalk",
                "Novato",
                "Nuevo",
                "Oak Park",
                "Oak View",
                "Oakdale",
                "Oakhurst",
                "Oakland",
                "Oakley",
                "Occidental",
                "Oceano",
                "Oceanside",
                "Ocotillo",
                "Oildale",
                "Ojai",
                "Olancha",
                "Olivehurst",
                "Ontario",
                "Onyx",
                "Opal Cliffs",
                "Orange",
                "Orange Cove",
                "Orangevale",
                "Orcutt",
                "Orinda",
                "Orland",
                "Orosi",
                "Oroville",
                "Oroville East",
                "Oxnard",
                "Pacheco",
                "Pacific Grove",
                "Pacifica",
                "Pajaro",
                "Palermo",
                "Palm Desert",
                "Palm Springs",
                "Palmdale",
                "Palo Alto",
                "Palo Cedro",
                "Palo Verde",
                "Palos Verdes Estates",
                "Paradise",
                "Paramount",
                "Parksdale",
                "Parkway-south Sacramento",
                "Parkwood",
                "Parlier",
                "Pasadena",
                "Patterson",
                "Paxton",
                "Pearsonville",
                "Pedley",
                "Penn Valley",
                "Perris",
                "Petaluma",
                "Phoenix Lake-cedar Ridge",
                "Pico Rivera",
                "Piedmont",
                "Pine Hills",
                "Pine Mountain Club",
                "Pine Valley",
                "Pinole",
                "Piru",
                "Pismo Beach",
                "Pittsburg",
                "Pixley",
                "Placentia",
                "Placerville",
                "Planada",
                "Pleasant Hill",
                "Pleasanton",
                "Plumas Eureka",
                "Plymouth",
                "Point Arena",
                "Point Reyes Station",
                "Pollock Pines",
                "Pomona",
                "Poplar-cotton Center",
                "Port Costa",
                "Port Hueneme",
                "Porterville",
                "Portola",
                "Portola Hills",
                "Portola Valley",
                "Poway",
                "Prattville",
                "Prunedale",
                "Quail Valley",
                "Quartz Hill",
                "Quincy",
                "Rail Road Flat",
                "Rainbow",
                "Raisin City",
                "Ramona",
                "Rancho Calaveras",
                "Rancho Cordova",
                "Rancho Cucamonga",
                "Rancho Mirage",
                "Rancho Murieta",
                "Rancho Palos Verdes",
                "Rancho San Diego",
                "Rancho Santa Fe",
                "Rancho Santa Margarita",
                "Rancho Tehama Reserve",
                "Randsburg",
                "Red Bluff",
                "Redding",
                "Redlands",
                "Redondo Beach",
                "Redway",
                "Redwood City",
                "Reedley",
                "Rialto",
                "Richgrove",
                "Richmond",
                "Ridgecrest",
                "Ridgemark",
                "Rio Del Mar",
                "Rio Dell",
                "Rio Linda",
                "Rio Vista",
                "Ripon",
                "Riverbank",
                "Riverdale",
                "Riverdale Park",
                "Riverside",
                "Rocklin",
                "Rodeo",
                "Rohnert Park",
                "Rolling Hills",
                "Rolling Hills Estates",
                "Rollingwood",
                "Romoland",
                "Rosamond",
                "Rosedale",
                "Roseland",
                "Rosemead",
                "Rosemont",
                "Roseville",
                "Ross",
                "Rossmoor",
                "Round Mountain",
                "Round Valley",
                "Rowland Heights",
                "Rubidoux",
                "Running Springs",
                "Sacramento",
                "Salida",
                "Salinas",
                "Salton City",
                "Salton Sea Beach",
                "San Andreas",
                "San Anselmo",
                "San Antonio Heights",
                "San Ardo",
                "San Bernardino",
                "San Bruno",
                "San Buenaventura (ventura)",
                "San Carlos",
                "San Clemente",
                "San Diego",
                "San Diego Country Estates",
                "San Dimas",
                "San Fernando",
                "San Francisco",
                "San Gabriel",
                "San Geronimo",
                "San Jacinto",
                "San Joaquin",
                "San Joaquin Hills",
                "San Jose",
                "San Juan Bautista",
                "San Juan Capistrano",
                "San Leandro",
                "San Lorenzo",
                "San Lucas",
                "San Luis Obispo",
                "San Marcos",
                "San Marino",
                "San Martin",
                "San Mateo",
                "San Miguel",
                "San Pablo",
                "San Rafael",
                "San Ramon",
                "Sand City",
                "Sanger",
                "Santa Ana",
                "Santa Barbara",
                "Santa Clara",
                "Santa Clarita",
                "Santa Cruz",
                "Santa Fe Springs",
                "Santa Maria",
                "Santa Monica",
                "Santa Paula",
                "Santa Rosa",
                "Santa Venetia",
                "Santa Ynez",
                "Santee",
                "Saratoga",
                "Sausalito",
                "Scotts Valley",
                "Seal Beach",
                "Searles Valley",
                "Seaside",
                "Sebastopol",
                "Sedco Hills",
                "Seeley",
                "Selma",
                "Seven Trees",
                "Shackelford",
                "Shafter",
                "Shandon",
                "Shasta Lake",
                "Shaver Lake",
                "Shingle Springs",
                "Shingletown",
                "Shoshone",
                "Sierra Madre",
                "Signal Hill",
                "Simi Valley",
                "Solana Beach",
                "Soledad",
                "Solvang",
                "Sonoma",
                "Sonora",
                "Soquel",
                "Soulsbyville",
                "South Dos Palos",
                "South El Monte",
                "South Gate",
                "South Lake Tahoe",
                "South Oroville",
                "South Pasadena",
                "South San Francisco",
                "South San Gabriel",
                "South San Jose Hills",
                "South Taft",
                "South Whittier",
                "South Woodbridge",
                "South Yuba City",
                "Spreckels",
                "Spring Garden",
                "Spring Valley",
                "Springville",
                "Squaw Valley",
                "Squirrel Mountain Valley",
                "St. Helena",
                "Stallion Springs",
                "Stanford",
                "Stanton",
                "Stinson Beach",
                "Stockton",
                "Storrie",
                "Stratford",
                "Strathmore",
                "Strawberry",
                "Suisun City",
                "Summerland",
                "Sun City",
                "Sunnyside-tahoe City",
                "Sunnyslope",
                "Sunnyvale",
                "Sunol",
                "Sunol-midtown",
                "Susanville",
                "Sutter",
                "Sutter Creek",
                "Taft",
                "Taft Heights",
                "Taft Mosswood",
                "Tahoe Vista",
                "Talmage",
                "Tamalpais-homestead Valley",
                "Tara Hills",
                "Taylorsville",
                "Tecopa",
                "Tehachapi",
                "Tehama",
                "Temecula",
                "Temelec",
                "Temple City",
                "Templeton",
                "Tennant",
                "Terra Bella",
                "Thermalito",
                "Thousand Oaks",
                "Thousand Palms",
                "Three Rivers",
                "Tiburon",
                "Tierra Buena",
                "Tipton",
                "Tobin",
                "Tomales",
                "Toro Canyon",
                "Torrance",
                "Tracy",
                "Tranquillity",
                "Traver",
                "Trinidad",
                "Truckee",
                "Tulare",
                "Tulelake",
                "Tuolumne City",
                "Tupman",
                "Turlock",
                "Tustin",
                "Tustin Foothills",
                "Twain",
                "Twain Harte",
                "Twentynine Palms",
                "Twentynine Palms Base",
                "Twin Lakes",
                "Ukiah",
                "Union City",
                "Upland",
                "Upper Lake",
                "Vacaville",
                "Val Verde",
                "Valinda",
                "Valle Vista",
                "Vallecito",
                "Vallejo",
                "Valley Acres",
                "Valley Center",
                "Valley Ranch",
                "Valley Springs",
                "Vandenberg Afb",
                "Vandenberg Village",
                "Vernon",
                "Victorville",
                "View Park-windsor Hills",
                "Villa Park",
                "Vincent",
                "Vine Hill",
                "Vineyard",
                "Visalia",
                "Vista",
                "Waldon",
                "Wallace",
                "Walnut",
                "Walnut Creek",
                "Walnut Grove",
                "Walnut Park",
                "Wasco",
                "Waterford",
                "Watsonville",
                "Weaverville",
                "Weed",
                "Weedpatch",
                "Weldon",
                "West Athens",
                "West Bishop",
                "West Carson",
                "West Compton",
                "West Covina",
                "West Hollywood",
                "West Menlo Park",
                "West Modesto",
                "West Point",
                "West Puente Valley",
                "West Sacramento",
                "West Whittier-los Nietos",
                "Westhaven-moonstone",
                "Westlake Village",
                "Westley",
                "Westminster",
                "Westmont",
                "Westmorland",
                "Westwood",
                "Wheatland",
                "Whitehawk",
                "Whittier",
                "Wildomar",
                "Wilkerson",
                "Williams",
                "Willits",
                "Willow Creek",
                "Willowbrook",
                "Willows",
                "Wilton",
                "Winchester",
                "Windsor",
                "Winter Gardens",
                "Winterhaven",
                "Winters",
                "Winton",
                "Wofford Heights",
                "Woodacre",
                "Woodcrest",
                "Woodlake",
                "Woodland",
                "Woodside",
                "Woodville",
                "Wrightwood",
                "Yorba Linda",
                "Yosemite Lakes",
                "Yosemite Valley",
                "Yountville",
                "Yreka",
                "Yuba City",
                "Yucaipa",
                "Yucca Valley"
            ],
            "Colorado": [
                "Acres Green",
                "Aguilar",
                "Air Force Academy",
                "Akron",
                "Alamosa",
                "Alamosa East",
                "Allenspark",
                "Alma",
                "Antonito",
                "Applewood",
                "Arboles",
                "Aristocrat Ranchettes",
                "Arriba",
                "Arvada",
                "Aspen",
                "Aspen Park",
                "Atwood",
                "Ault",
                "Aurora",
                "Avon",
                "Avondale",
                "Basalt",
                "Battlement Mesa",
                "Bayfield",
                "Bennett",
                "Berkley",
                "Berthoud",
                "Bethune",
                "Beulah Valley",
                "Black Forest",
                "Black Hawk",
                "Blanca",
                "Blue River",
                "Bonanza",
                "Boone",
                "Boulder",
                "Bow Mar",
                "Branson",
                "Breckenridge",
                "Brighton",
                "Brookside",
                "Broomfield",
                "Brush",
                "Buena Vista",
                "Burlington",
                "Byers",
                "Calhan",
                "Campion",
                "Campo",
                "Canon City",
                "Carbondale",
                "Carriage Club",
                "Cascade-chipita Park",
                "Castle Pines",
                "Castle Rock",
                "Castlewood",
                "Cedaredge",
                "Center",
                "Central City",
                "Cheraw",
                "Cherry Hills Village",
                "Cheyenne Wells",
                "Cimarron Hills",
                "Clifton",
                "Coal Creek",
                "Cokedale",
                "Collbran",
                "Colorado City",
                "Colorado Springs",
                "Columbine",
                "Columbine Valley",
                "Commerce City",
                "Cortez",
                "Cottonwood",
                "Craig",
                "Crawford",
                "Creede",
                "Crested Butte",
                "Crestone",
                "Cripple Creek",
                "Crook",
                "Crowley",
                "Dacono",
                "De Beque",
                "Deer Trail",
                "Del Norte",
                "Delta",
                "Denver",
                "Derby",
                "Dillon",
                "Dinosaur",
                "Dolores",
                "Dove Creek",
                "Downieville-lawson-dumont",
                "Durango",
                "Eads",
                "Eagle",
                "Eagle-vail",
                "East Pleasant View",
                "Eaton",
                "Eckley",
                "Edgewater",
                "Edwards",
                "El Jebel",
                "Eldora",
                "Eldorado Springs",
                "Elizabeth",
                "Empire",
                "Englewood",
                "Erie",
                "Estes Park",
                "Evans",
                "Evergreen",
                "Fairplay",
                "Federal Heights",
                "Firestone",
                "Flagler",
                "Fleming",
                "Florence",
                "Fort Carson",
                "Fort Collins",
                "Fort Garland",
                "Fort Lupton",
                "Fort Morgan",
                "Fountain",
                "Fowler",
                "Foxfield",
                "Franktown",
                "Fraser",
                "Frederick",
                "Frisco",
                "Fruita",
                "Fruitvale",
                "Garden City",
                "Genesee",
                "Genoa",
                "Georgetown",
                "Gilcrest",
                "Glendale",
                "Gleneagle",
                "Glenwood Springs",
                "Gold Hill",
                "Golden",
                "Granada",
                "Granby",
                "Grand Junction",
                "Grand Lake",
                "Grand View Estates",
                "Greeley",
                "Green Mountain Falls",
                "Greenwood Village",
                "Grover",
                "Gunbarrel",
                "Gunnison",
                "Gypsum",
                "Hartman",
                "Haswell",
                "Haxtun",
                "Hayden",
                "Heritage Hills",
                "Highlands Ranch",
                "Hillrose",
                "Holly",
                "Holyoke",
                "Hooper",
                "Hot Sulphur Springs",
                "Hotchkiss",
                "Hudson",
                "Hugo",
                "Idaho Springs",
                "Ignacio",
                "Iliff",
                "Indian Hills",
                "Jamestown",
                "Johnstown",
                "Julesburg",
                "Keenesburg",
                "Ken Caryl",
                "Kersey",
                "Keystone",
                "Kim",
                "Kiowa",
                "Kit Carson",
                "Kittredge",
                "Kremmling",
                "La Jara",
                "La Junta",
                "La Salle",
                "La Veta",
                "Lafayette",
                "Lake City",
                "Lakeside",
                "Lakewood",
                "Lamar",
                "Laporte",
                "Larkspur",
                "Las Animas",
                "Leadville",
                "Leadville North",
                "Limon",
                "Lincoln Park",
                "Littleton",
                "Lochbuie",
                "Log Lane Village",
                "Loghill Village",
                "Lone Tree",
                "Longmont",
                "Louisville",
                "Louviers",
                "Loveland",
                "Lyons",
                "Manassa",
                "Mancos",
                "Manitou Springs",
                "Manzanola",
                "Marble",
                "Mead",
                "Meeker",
                "Meridian",
                "Merino",
                "Milliken",
                "Minturn",
                "Moffat",
                "Monte Vista",
                "Montezuma",
                "Montrose",
                "Monument",
                "Morrison",
                "Mount Crested Butte",
                "Mountain View",
                "Mountain Village",
                "Naturita",
                "Nederland",
                "New Castle",
                "Niwot",
                "North Washington",
                "Northglenn",
                "Norwood",
                "Nucla",
                "Nunn",
                "Oak Creek",
                "Olathe",
                "Olney Springs",
                "Ophir",
                "Orchard City",
                "Orchard Mesa",
                "Ordway",
                "Otis",
                "Ouray",
                "Ovid",
                "Padroni",
                "Pagosa Springs",
                "Palisade",
                "Palmer Lake",
                "Paoli",
                "Paonia",
                "Parachute",
                "Parker",
                "Peetz",
                "Penrose",
                "Perry Park",
                "Pierce",
                "Pitkin",
                "Platteville",
                "Poncha Springs",
                "Ponderosa Park",
                "Pritchett",
                "Pueblo",
                "Pueblo West",
                "Ramah",
                "Rangely",
                "Raymer",
                "Red Cliff",
                "Red Feather Lakes",
                "Redlands",
                "Rico",
                "Ridgway",
                "Rifle",
                "Rockvale",
                "Rocky Ford",
                "Romeo",
                "Roxborough Park",
                "Rye",
                "Saguache",
                "Salida",
                "Salt Creek",
                "San Luis",
                "Sanford",
                "Sawpit",
                "Security-widefield",
                "Sedalia",
                "Sedgwick",
                "Seibert",
                "Severance",
                "Sheridan",
                "Sheridan Lake",
                "Sherrelwood",
                "Silt",
                "Silver Cliff",
                "Silver Plume",
                "Silverthorne",
                "Silverton",
                "Simla",
                "Snowmass Village",
                "South Fork",
                "Southglenn",
                "Springfield",
                "St. Marys",
                "Starkville",
                "Steamboat Springs",
                "Sterling",
                "Stonegate",
                "Strasburg",
                "Stratmoor",
                "Stratton",
                "Sugar City",
                "Superior",
                "Swink",
                "Tabernash",
                "Telluride",
                "The Pinery",
                "Thornton",
                "Timnath",
                "Todd Creek",
                "Towaoc",
                "Trinidad",
                "Twin Lakes",
                "Two Buttes",
                "Vail",
                "Victor",
                "Vilas",
                "Vona",
                "Walden",
                "Walsenburg",
                "Walsh",
                "Ward",
                "Welby",
                "Wellington",
                "West Pleasant View",
                "Westcliffe",
                "Westcreek",
                "Westminster",
                "Wheat Ridge",
                "Wiggins",
                "Wiley",
                "Williamsburg",
                "Windsor",
                "Winter Park",
                "Woodland Park",
                "Woodmoor",
                "Wray",
                "Yampa",
                "Yuma"
            ],
            "Connecticut": [
                "Ansonia",
                "Bantam",
                "Bethel",
                "Bethlehem Village",
                "Blue Hills",
                "Branford Center",
                "Bridgeport",
                "Bristol",
                "Broad Brook",
                "Canaan",
                "Canton Valley",
                "Central Manchester",
                "Central Somers",
                "Central Waterford",
                "Cheshire Village",
                "Chester Center",
                "Clinton",
                "Collinsville",
                "Conning Towers-nautilus Park",
                "Coventry Lake",
                "Crystal Lake",
                "Danbury",
                "Danielson",
                "Darien",
                "Deep River Center",
                "Derby",
                "Durham",
                "East Brooklyn",
                "East Hampton",
                "East Hartford",
                "East Haven",
                "Essex Village",
                "Fenwick",
                "Georgetown",
                "Glastonbury Center",
                "Groton",
                "Groton Long Point",
                "Guilford Center",
                "Hartford",
                "Hazardville",
                "Heritage Village",
                "Higganum",
                "Jewett City",
                "Kensington",
                "Lake Pocotopaug",
                "Litchfield",
                "Long Hill",
                "Madison Center",
                "Mansfield Center",
                "Meriden",
                "Middletown",
                "Milford City",
                "Moodus",
                "Moosup",
                "Mystic",
                "Naugatuck",
                "New Britain",
                "New Hartford Center",
                "New Haven",
                "New London",
                "New Milford",
                "New Preston",
                "Newington",
                "Newtown",
                "Niantic",
                "Noank",
                "North Granby",
                "North Grosvenor Dale",
                "North Haven",
                "Northwest Harwinton",
                "Norwalk",
                "Norwich",
                "Oakville",
                "Old Mystic",
                "Old Saybrook Center",
                "Orange",
                "Oxoboxo River",
                "Pawcatuck",
                "Plainfield Village",
                "Poquonock Bridge",
                "Portland",
                "Putnam District",
                "Quinebaug",
                "Ridgefield",
                "Rockville",
                "Salmon Brook",
                "Saybrook Manor",
                "Shelton",
                "Sherwood Manor",
                "Simsbury Center",
                "South Coventry",
                "South Windham",
                "South Woodstock",
                "Southwood Acres",
                "Stamford",
                "Stonington",
                "Storrs",
                "Stratford",
                "Suffield Depot",
                "Tariffville",
                "Terramuggus",
                "Terryville",
                "Thompsonville",
                "Torrington",
                "Trumbull",
                "Wallingford Center",
                "Waterbury",
                "Wauregan",
                "Weatogue",
                "West Hartford",
                "West Haven",
                "West Simsbury",
                "Westbrook Center",
                "Westport",
                "Wethersfield",
                "Willimantic",
                "Windsor Locks",
                "Winsted",
                "Woodbury Center",
                "Woodmont"
            ],
            "Delaware": [
                "Arden",
                "Ardencroft",
                "Ardentown",
                "Beach",
                "Bear",
                "Bellefonte",
                "Bethany",
                "Bethel",
                "Blades",
                "Bowers",
                "Bridgeville",
                "Brookside",
                "Camden",
                "Cheswold",
                "Claymont",
                "Clayton",
                "Dagsboro",
                "Delaware City",
                "Delmar",
                "Dewey Beach",
                "Dover",
                "Dover Base Housing",
                "Edgemoor",
                "Ellendale",
                "Elsmere",
                "Farmington",
                "Felton",
                "Fenwick Island",
                "Frankford",
                "Frederica",
                "Georgetown",
                "Glasgow",
                "Greenville",
                "Greenwood",
                "Harrington",
                "Hartly",
                "Henlopen Acres",
                "Highland Acres",
                "Hockessin",
                "Houston",
                "Kent Acres",
                "Kenton",
                "Laurel",
                "Leipsic",
                "Lewes",
                "Little Creek",
                "Long Neck",
                "Magnolia",
                "Middletown",
                "Milford",
                "Millsboro",
                "Millville",
                "Milton",
                "New Castle",
                "Newark",
                "Newport",
                "North Star",
                "Ocean View",
                "Odessa",
                "Pike Creek",
                "Rehoboth Beach",
                "Rising Sun-lebanon",
                "Riverview",
                "Rodney Village",
                "Seaford",
                "Selbyville",
                "Slaughter Beach",
                "Smyrna",
                "South Bethany",
                "Townsend",
                "Viola",
                "Wilmington",
                "Wilmington Manor",
                "Woodside",
                "Woodside East",
                "Wyoming"
            ],
            "District of Columbia": [
                "Washington"
            ],
            "Federated States Of Micronesia": [
                "Fsm"
            ],
            "Florida": [
                "Alachua",
                "Alford",
                "Altamonte Springs",
                "Altha",
                "Altoona",
                "Alva",
                "Andover",
                "Andrews",
                "Anna Maria",
                "Apalachicola",
                "Apollo Beach",
                "Apopka",
                "Arcadia",
                "Archer",
                "Asbury Lake",
                "Astatula",
                "Astor",
                "Atlantic Beach",
                "Atlantis",
                "Auburndale",
                "Aventura",
                "Avon Park",
                "Azalea Park",
                "Babson Park",
                "Bagdad",
                "Bal Harbour",
                "Baldwin",
                "Bartow",
                "Bascom",
                "Bay Harbor Islands",
                "Bay Hill",
                "Bay Lake",
                "Bay Pines",
                "Bayonet Point",
                "Bayport",
                "Bayshore Gardens",
                "Beacon Square",
                "Bee Ridge",
                "Bell",
                "Bellair-meadowbrook Terrace",
                "Belle Glade",
                "Belle Glade Camp",
                "Belle Isle",
                "Belleair",
                "Belleair Beach",
                "Belleair Bluffs",
                "Belleair Shore",
                "Belleview",
                "Bellview",
                "Beverly Beach",
                "Beverly Hills",
                "Big Coppitt Key",
                "Big Pine Key",
                "Biscayne Park",
                "Bithlo",
                "Black Diamond",
                "Bloomingdale",
                "Blountstown",
                "Boca Del Mar",
                "Boca Pointe",
                "Boca Raton",
                "Bokeelia",
                "Bonifay",
                "Bonita Springs",
                "Bonnie Lock-woodsetter North",
                "Boulevard Gardens",
                "Bowling Green",
                "Boyette",
                "Boynton Beach",
                "Bradenton",
                "Bradenton Beach",
                "Brandon",
                "Branford",
                "Brent",
                "Briny Breezes",
                "Bristol",
                "Broadview Park",
                "Broadview-pompano Park",
                "Bronson",
                "Brooker",
                "Brookridge",
                "Brooksville",
                "Broward Estates",
                "Brownsville",
                "Buckhead Ridge",
                "Buckingham",
                "Bunche Park",
                "Bunnell",
                "Burnt Store Marina",
                "Bushnell",
                "Butler Beach",
                "Callahan",
                "Callaway",
                "Campbell",
                "Campbellton",
                "Canal Point",
                "Cape Canaveral",
                "Cape Coral",
                "Captiva",
                "Carol City",
                "Carrabelle",
                "Carver Ranches",
                "Caryville",
                "Casselberry",
                "Cedar Grove",
                "Cedar Key",
                "Celebration",
                "Center Hill",
                "Century",
                "Century Village",
                "Chambers Estates",
                "Charleston Park",
                "Charlotte Harbor",
                "Charlotte Park",
                "Chattahoochee",
                "Cheval",
                "Chiefland",
                "Chipley",
                "Chokoloskee",
                "Christmas",
                "Chula Vista",
                "Chuluota",
                "Cinco Bayou",
                "Citrus Hills",
                "Citrus Park",
                "Citrus Ridge",
                "Citrus Springs",
                "Clearwater",
                "Clermont",
                "Cleveland",
                "Clewiston",
                "Cloud Lake",
                "Cocoa",
                "Cocoa Beach",
                "Cocoa West",
                "Coconut Creek",
                "Coleman",
                "Collier Manor-cresthaven",
                "Combee Settlement",
                "Conway",
                "Cooper City",
                "Coral Gables",
                "Coral Springs",
                "Coral Terrace",
                "Cortez",
                "Cottondale",
                "Country Club",
                "Country Estates",
                "Country Walk",
                "Crescent Beach",
                "Crescent City",
                "Crestview",
                "Crooked Lake Park",
                "Cross City",
                "Crystal Lake",
                "Crystal River",
                "Crystal Springs",
                "Cudjoe Key",
                "Cutler",
                "Cutler Ridge",
                "Cypress Gardens",
                "Cypress Lake",
                "Cypress Lakes",
                "Cypress Quarters",
                "Dade City",
                "Dade City North",
                "Dania Beach",
                "Davenport",
                "Davie",
                "Daytona Beach",
                "Daytona Beach Shores",
                "De Bary",
                "De Funiak Springs",
                "De Land",
                "De Land Southwest",
                "De Leon Springs",
                "Deerfield Beach",
                "Delray Beach",
                "Deltona",
                "Desoto Lakes",
                "Destin",
                "Doctor Phillips",
                "Doral",
                "Dover",
                "Duck Key",
                "Dundee",
                "Dunedin",
                "Dunes Road",
                "Dunnellon",
                "Eagle Lake",
                "East Bronson",
                "East Dunbar",
                "East Lake",
                "East Lake-orient Park",
                "East Palatka",
                "East Perrine",
                "East Williston",
                "Eastpoint",
                "Eatonville",
                "Ebro",
                "Edgewater",
                "Edgewood",
                "Eglin Afb",
                "Egypt Lake-leto",
                "El Portal",
                "Elfers",
                "Ellenton",
                "Englewood",
                "Ensley",
                "Estates Of Fort Lauderdale",
                "Estero",
                "Esto",
                "Eustis",
                "Everglades",
                "Fairview Shores",
                "Fanning Springs",
                "Feather Sound",
                "Fellsmere",
                "Fern Park",
                "Fernandina Beach",
                "Ferndale",
                "Ferry Pass",
                "Fish Hawk",
                "Fisher Island",
                "Five Points",
                "Flagler Beach",
                "Floral City",
                "Florida City",
                "Florida Ridge",
                "Forest City",
                "Fort Lauderdale",
                "Fort Meade",
                "Fort Myers",
                "Fort Myers Beach",
                "Fort Myers Shores",
                "Fort Pierce",
                "Fort Pierce North",
                "Fort Pierce South",
                "Fort Walton Beach",
                "Fort White",
                "Fountainbleau",
                "Franklin Park",
                "Freeport",
                "Fremd Village-padgett Island",
                "Frostproof",
                "Fruit Cove",
                "Fruitland Park",
                "Fruitville",
                "Fussels Corner",
                "Gainesville",
                "Gandy",
                "Gateway",
                "Geneva",
                "Gibsonia",
                "Gibsonton",
                "Gifford",
                "Gladeview",
                "Glen Ridge",
                "Glen St. Mary",
                "Glencoe",
                "Glenvar Heights",
                "Godfrey Road",
                "Golden Beach",
                "Golden Gate",
                "Golden Glades",
                "Golden Heights",
                "Golden Lakes",
                "Goldenrod",
                "Golf",
                "Gonzalez",
                "Goodland",
                "Gotha",
                "Goulding",
                "Goulds",
                "Graceville",
                "Grand Ridge",
                "Greater Carrollwood",
                "Greater Northdale",
                "Greater Sun Center",
                "Green Cove Springs",
                "Green Meadow",
                "Greenacres",
                "Greensboro",
                "Greenville",
                "Greenwood",
                "Gretna",
                "Grove City",
                "Groveland",
                "Gulf Breeze",
                "Gulf Gate Estates",
                "Gulf Stream",
                "Gulfport",
                "Gun Club Estates",
                "Haines City",
                "Hallandale",
                "Hampton",
                "Hamptons At Boca Raton",
                "Harbor Bluffs",
                "Harbour Heights",
                "Harlem",
                "Harlem Heights",
                "Hastings",
                "Havana",
                "Haverhill",
                "Hawthorne",
                "Heathrow",
                "Hernando",
                "Hernando Beach",
                "Hialeah",
                "Hialeah Gardens",
                "High Point",
                "High Springs",
                "Highland Beach",
                "Highland City",
                "Highland Park",
                "Hiland Park",
                "Hill N Dale",
                "Hillcrest Heights",
                "Hilliard",
                "Hillsboro Beach",
                "Hillsboro Pines",
                "Hillsboro Ranches",
                "Hobe Sound",
                "Holden Heights",
                "Holiday",
                "Holly Hill",
                "Hollywood",
                "Holmes Beach",
                "Homestead",
                "Homestead Base",
                "Homosassa",
                "Homosassa Springs",
                "Horseshoe Beach",
                "Howey-in-the-hills",
                "Hudson",
                "Hunters Creek",
                "Hutchinson Island South",
                "Hypoluxo",
                "Immokalee",
                "Indialantic",
                "Indian Creek",
                "Indian Harbour Beach",
                "Indian River Estates",
                "Indian River Shores",
                "Indian Rocks Beach",
                "Indian Shores",
                "Indiantown",
                "Inglis",
                "Interlachen",
                "Inverness",
                "Inverness Highlands North",
                "Inverness Highlands South",
                "Inwood",
                "Iona",
                "Islamorada",
                "Islandia",
                "Istachatta",
                "Ivanhoe Estates",
                "Ives Estates",
                "Jacksonville",
                "Jacksonville Beach",
                "Jacob City",
                "Jan Phyl Village",
                "Jasmine Estates",
                "Jasper",
                "Jay",
                "Jennings",
                "Jensen Beach",
                "June Park",
                "Juno Beach",
                "Juno Ridge",
                "Jupiter",
                "Jupiter Inlet Colony",
                "Jupiter Island",
                "Kathleen",
                "Kendale Lakes",
                "Kendall",
                "Kendall Green",
                "Kendall West",
                "Kenneth City",
                "Kensington Park",
                "Key Biscayne",
                "Key Colony Beach",
                "Key Largo",
                "Key West",
                "Keystone",
                "Keystone Heights",
                "Kings Point",
                "Kissimmee",
                "La Crosse",
                "Labelle",
                "Lacoochee",
                "Lady Lake",
                "Laguna Beach",
                "Lake Alfred",
                "Lake Belvedere Estates",
                "Lake Buena Vista",
                "Lake Butler",
                "Lake Butter",
                "Lake City",
                "Lake Clarke Shores",
                "Lake Forest",
                "Lake Hamilton",
                "Lake Harbor",
                "Lake Hart",
                "Lake Helen",
                "Lake Kathryn",
                "Lake Lindsey",
                "Lake Lorraine",
                "Lake Lucerne",
                "Lake Mack-forest Hills",
                "Lake Magdalene",
                "Lake Mary",
                "Lake Panasoffkee",
                "Lake Park",
                "Lake Placid",
                "Lake Sarasota",
                "Lake Wales",
                "Lake Worth",
                "Lake Worth Corridor",
                "Lakeland",
                "Lakeland Highlands",
                "Lakes By The Bay",
                "Lakeside",
                "Lakeside Green",
                "Lakewood Park",
                "Land O Lakes",
                "Lantana",
                "Largo",
                "Lauderdale Lakes",
                "Lauderdale-by-the-sea",
                "Lauderhill",
                "Laurel",
                "Laurel Hill",
                "Lawtey",
                "Layton",
                "Lazy Lake",
                "Lecanto",
                "Lee",
                "Leesburg",
                "Lehigh Acres",
                "Leisure City",
                "Leisureville",
                "Lely",
                "Lely Resort",
                "Lighthouse Point",
                "Limestone Creek",
                "Lisbon",
                "Live Oak",
                "Loch Lomond",
                "Lochmoor Waterway Estates",
                "Lockhart",
                "Longboat Key",
                "Longwood",
                "Loughman",
                "Lower Grand Lagoon",
                "Lutz",
                "Lynn Haven",
                "Macclenny",
                "Madeira Beach",
                "Madison",
                "Maitland",
                "Malabar",
                "Malone",
                "Manalapan",
                "Manasota Key",
                "Manattee Road",
                "Mango",
                "Mangonia Park",
                "Marathon",
                "Marco Island",
                "Margate",
                "Marianna",
                "Marineland",
                "Mary Esther",
                "Masaryktown",
                "Mascotte",
                "Matlacha",
                "Matlacha Isles-matlacha Shores",
                "Mayo",
                "Mcgregor",
                "Mcintosh",
                "Meadow Woods",
                "Medley",
                "Medulla",
                "Melbourne",
                "Melbourne Beach",
                "Melbourne Village",
                "Melrose Park",
                "Memphis",
                "Merritt Island",
                "Mexico Beach",
                "Miami",
                "Miami Beach",
                "Miami Gardens",
                "Miami Lakes",
                "Miami Shores",
                "Miami Springs",
                "Micanopy",
                "Micco",
                "Middleburg",
                "Midway",
                "Milton",
                "Mims",
                "Minneola",
                "Miramar",
                "Miramar Beach",
                "Mission Bay",
                "Molino",
                "Monticello",
                "Montverde",
                "Moore Haven",
                "Mount Dora",
                "Mount Plymouth",
                "Mulberry",
                "Myrtle Grove",
                "Naples",
                "Naples Manor",
                "Naples Park",
                "Naranja",
                "Nassau Village-ratliff",
                "Neptune Beach",
                "New Port Richey",
                "New Port Richey East",
                "New Smyrna Beach",
                "Newberry",
                "Niceville",
                "Nobleton",
                "Nokomis",
                "Noma",
                "Norland",
                "North Andrews Gardens",
                "North Bay Village",
                "North Beach",
                "North Brooksville",
                "North De Land",
                "North Fort Myers",
                "North Key Largo",
                "North Lauderdale",
                "North Miami",
                "North Miami Beach",
                "North Palm Beach",
                "North Port",
                "North Redington Beach",
                "North River Shores",
                "North Sarasota",
                "North Weeki Wachee",
                "Oak Hill",
                "Oak Point",
                "Oak Ridge",
                "Oakland",
                "Oakland Park",
                "Ocala",
                "Ocean Breeze Park",
                "Ocean City",
                "Ocean Ridge",
                "Ocoee",
                "Odessa",
                "Ojus",
                "Okahumpka",
                "Okeechobee",
                "Oldsmar",
                "Olga",
                "Olympia Heights",
                "Opa-locka",
                "Opa-locka North",
                "Orange City",
                "Orange Park",
                "Orangetree",
                "Orchid",
                "Orlando",
                "Orlovista",
                "Ormond Beach",
                "Ormond-by-the-sea",
                "Osprey",
                "Otter Creek",
                "Oviedo",
                "Pace",
                "Page Park",
                "Pahokee",
                "Paisley",
                "Palatka",
                "Palm Aire",
                "Palm Bay",
                "Palm Beach",
                "Palm Beach Gardens",
                "Palm Beach Shores",
                "Palm City",
                "Palm Coast",
                "Palm Harbor",
                "Palm River-clair Mel",
                "Palm Shores",
                "Palm Springs",
                "Palm Springs North",
                "Palm Valley",
                "Palmetto",
                "Palmetto Estates",
                "Palmona Park",
                "Panama City",
                "Panama City Beach",
                "Paradise Heights",
                "Parker",
                "Parkland",
                "Paxton",
                "Pebble Creek",
                "Pelican Bay",
                "Pembroke Park",
                "Pembroke Pines",
                "Penney Farms",
                "Pensacola",
                "Perry",
                "Pierson",
                "Pine Castle",
                "Pine Hills",
                "Pine Island",
                "Pine Island Center",
                "Pine Island Ridge",
                "Pine Lakes",
                "Pine Manor",
                "Pine Ridge",
                "Pinecrest",
                "Pineland",
                "Pinellas Park",
                "Pinewood",
                "Pittman",
                "Placid Lakes",
                "Plant City",
                "Plantation",
                "Plantation Island",
                "Plantation Mobile Home Park",
                "Poinciana",
                "Polk City",
                "Pomona Park",
                "Pompano Beach",
                "Pompano Beach Highlands",
                "Pompano Estates",
                "Ponce De Leon",
                "Ponce Inlet",
                "Port Charlotte",
                "Port La Belle",
                "Port Orange",
                "Port Richey",
                "Port Salerno",
                "Port St. Joe",
                "Port St. John",
                "Port St. Lucie",
                "Port St. Lucie-river Park",
                "Pretty Bayou",
                "Princeton",
                "Progress Village",
                "Punta Gorda",
                "Punta Rassa",
                "Quincy",
                "Raiford",
                "Ramblewood East",
                "Ravenswood Estates",
                "Reddick",
                "Redington Beach",
                "Redington Shores",
                "Richmond Heights",
                "Richmond West",
                "Ridge Manor",
                "Ridge Wood Heights",
                "Ridgecrest",
                "Rio",
                "Riverland Village",
                "Riverview",
                "Riviera Beach",
                "Rock Island",
                "Rockledge",
                "Rolling Oaks",
                "Roosevelt Gardens",
                "Roseland",
                "Rotonda",
                "Royal Palm Beach",
                "Royal Palm Estates",
                "Royal Palm Ranches",
                "Ruskin",
                "Safety Harbor",
                "Samoset",
                "Samsula-spruce Creek",
                "San Antonio",
                "San Carlos Park",
                "Sandalfoot Cove",
                "Sanford",
                "Sanibel",
                "Sarasota",
                "Sarasota Springs",
                "Satellite Beach",
                "Sawgrass",
                "Schall Circle",
                "Scott Lake",
                "Sea Ranch Lakes",
                "Sebastian",
                "Sebring",
                "Seffner",
                "Seminole",
                "Seminole Manor",
                "Sewall S Point",
                "Shady Hills",
                "Shalimar",
                "Sharpes",
                "Siesta Key",
                "Silver Lake",
                "Silver Springs Shores",
                "Sky Lake",
                "Sneads",
                "Solana",
                "Sopchoppy",
                "Sorrento",
                "South Apopka",
                "South Bay",
                "South Beach",
                "South Bradenton",
                "South Brooksville",
                "South Daytona",
                "South Gate Ridge",
                "South Highpoint",
                "South Miami",
                "South Miami Heights",
                "South Palm Beach",
                "South Pasadena",
                "South Patrick Shores",
                "South Sarasota",
                "South Venice",
                "Southchase",
                "Southeast Arcadia",
                "Southgate",
                "Spring Hill",
                "Spring Lake",
                "Springfield",
                "St. Augustine",
                "St. Augustine Beach",
                "St. Augustine Shores",
                "St. Augustine South",
                "St. Cloud",
                "St. George",
                "St. James City",
                "St. Leo",
                "St. Lucie",
                "St. Marks",
                "St. Pete Beach",
                "St. Petersburg",
                "Stacey Street",
                "Starke",
                "Stock Island",
                "Stuart",
                "Sugarmill Woods",
                "Suncoast Estates",
                "Sunny Isles Beach",
                "Sunrise",
                "Sunset",
                "Sunshine Acres",
                "Sunshine Ranches",
                "Surfside",
                "Sweetwater",
                "Sylvan Shores",
                "Taft",
                "Tallahassee",
                "Tamarac",
                "Tamiami",
                "Tampa",
                "Tangelo Park",
                "Tangerine",
                "Tarpon Springs",
                "Tavares",
                "Tavernier",
                "Taylor Creek",
                "Tedder",
                "Temple Terrace",
                "Tequesta",
                "Terra Mar",
                "The Crossings",
                "The Hammocks",
                "The Meadows",
                "The Villages",
                "Thonotosassa",
                "Three Lakes",
                "Three Oaks",
                "Tice",
                "Tierra Verde",
                "Tildenville",
                "Timber Pines",
                "Titusville",
                "Town N Country",
                "Treasure Island",
                "Trenton",
                "Trinity",
                "Twin Lakes",
                "Tyndall Afb",
                "Umatilla",
                "Union Park",
                "University",
                "University Park",
                "Upper Grand Lagoon",
                "Utopia",
                "Valparaiso",
                "Valrico",
                "Vamo",
                "Venice",
                "Venice Gardens",
                "Vernon",
                "Vero Beach",
                "Vero Beach South",
                "Village Park",
                "Villages Of Oriole",
                "Villano Beach",
                "Villas",
                "Vineyards",
                "Virginia Gardens",
                "Wabasso",
                "Wabasso Beach",
                "Wahneta",
                "Waldo",
                "Warm Mineral Springs",
                "Warrington",
                "Washington Park",
                "Watertown",
                "Wauchula",
                "Wausau",
                "Waverly",
                "Webster",
                "Wedgefield",
                "Weeki Wachee",
                "Weeki Wachee Gardens",
                "Wekiwa Springs",
                "Welaka",
                "Wellington",
                "Wesley Chapel",
                "Wesley Chapel South",
                "West And East Lealman",
                "West Bradenton",
                "West De Land",
                "West Ken-lark",
                "West Little River",
                "West Melbourne",
                "West Miami",
                "West Palm Beach",
                "West Pensacola",
                "West Perrine",
                "West Samoset",
                "West Vero Corridor",
                "Westchase",
                "Westchester",
                "Westgate-belvedere Homes",
                "Weston",
                "Westview",
                "Westville",
                "Westwood Lakes",
                "Wewahitchka",
                "Whiskey Creek",
                "Whisper Walk",
                "White City",
                "White Springs",
                "Whitfield",
                "Wildwood",
                "Williamsburg",
                "Williston",
                "Williston Highlands",
                "Willow Oak",
                "Wilton Manors",
                "Wimauma",
                "Windermere",
                "Winston",
                "Winter Beach",
                "Winter Garden",
                "Winter Haven",
                "Winter Park",
                "Winter Springs",
                "Woodville",
                "Worthington Springs",
                "Wright",
                "Yalaha",
                "Yankeetown",
                "Yeehaw Junction",
                "Yulee",
                "Zellwood",
                "Zephyrhills",
                "Zephyrhills North",
                "Zephyrhills South",
                "Zephyrhills West",
                "Zolfo Springs"
            ],
            "Georgia": [
                "Abbeville",
                "Acworth",
                "Adairsville",
                "Adel",
                "Adrian",
                "Ailey",
                "Alamo",
                "Alapaha",
                "Albany",
                "Aldora",
                "Allenhurst",
                "Allentown",
                "Alma",
                "Alpharetta",
                "Alston",
                "Alto",
                "Ambrose",
                "Americus",
                "Andersonville",
                "Arabi",
                "Aragon",
                "Arcade",
                "Argyle",
                "Arlington",
                "Arnoldsville",
                "Ashburn",
                "Athens-clarke County",
                "Atlanta",
                "Attapulgus",
                "Auburn",
                "Augusta-richmond County",
                "Austell",
                "Avalon",
                "Avera",
                "Avondale Estates",
                "Baconton",
                "Bainbridge",
                "Baldwin",
                "Ball Ground",
                "Barnesville",
                "Bartow",
                "Barwick",
                "Baxley",
                "Bellville",
                "Belvedere Park",
                "Berkeley Lake",
                "Berlin",
                "Bethlehem",
                "Between",
                "Bibb City",
                "Bishop",
                "Blackshear",
                "Blacksville",
                "Blairsville",
                "Blakely",
                "Bloomingdale",
                "Blue Ridge",
                "Bluffton",
                "Blythe",
                "Bogart",
                "Bonanza",
                "Boston",
                "Bostwick",
                "Bowdon",
                "Bowersville",
                "Bowman",
                "Braselton",
                "Braswell",
                "Bremen",
                "Brinson",
                "Bronwood",
                "Brooklet",
                "Brooks",
                "Broxton",
                "Brunswick",
                "Buchanan",
                "Buckhead",
                "Buena Vista",
                "Buford",
                "Butler",
                "Byromville",
                "Byron",
                "Cadwell",
                "Cairo",
                "Calhoun",
                "Camak",
                "Camilla",
                "Candler-mcafee",
                "Canon",
                "Canton",
                "Carl",
                "Carlton",
                "Carnesville",
                "Carrollton",
                "Cartersville",
                "Cave Spring",
                "Cecil",
                "Cedartown",
                "Centerville",
                "Centralhatchee",
                "Chamblee",
                "Chatsworth",
                "Chattanooga Valley",
                "Chauncey",
                "Chester",
                "Chickamauga",
                "Clarkesville",
                "Clarkston",
                "Claxton",
                "Clayton",
                "Clermont",
                "Cleveland",
                "Climax",
                "Cobbtown",
                "Cochran",
                "Cohutta",
                "Colbert",
                "Coleman",
                "College Park",
                "Collins",
                "Colquitt",
                "Columbus City",
                "Comer",
                "Commerce",
                "Concord",
                "Conley",
                "Conyers",
                "Coolidge",
                "Cordele",
                "Corinth",
                "Cornelia",
                "Country Club Estates",
                "Covington",
                "Crawford",
                "Crawfordville",
                "Culloden",
                "Cumming",
                "Cusseta",
                "Cuthbert",
                "Dacula",
                "Dahlonega",
                "Daisy",
                "Dallas",
                "Dalton",
                "Damascus",
                "Danielsville",
                "Danville",
                "Darien",
                "Dasher",
                "Davisboro",
                "Dawson",
                "Dawsonville",
                "De Soto",
                "Dearing",
                "Decatur",
                "Deenwood",
                "Deepstep",
                "Demorest",
                "Denton",
                "Dexter",
                "Dillard",
                "Dock Junction",
                "Doerun",
                "Donalsonville",
                "Dooling",
                "Doraville",
                "Douglas",
                "Douglasville",
                "Druid Hills",
                "Du Pont",
                "Dublin",
                "Dudley",
                "Duluth",
                "Dunwoody",
                "East Dublin",
                "East Ellijay",
                "East Griffin",
                "East Newnan",
                "East Point",
                "Eastman",
                "Eatonton",
                "Edge Hill",
                "Edison",
                "Elberton",
                "Ellaville",
                "Ellenton",
                "Ellijay",
                "Emerson",
                "Enigma",
                "Ephesus",
                "Eton",
                "Euharlee",
                "Evans",
                "Experiment",
                "Fair Oaks",
                "Fairburn",
                "Fairmount",
                "Fairview",
                "Fargo",
                "Fayetteville",
                "Fitzgerald",
                "Flemington",
                "Flovilla",
                "Flowery Branch",
                "Folkston",
                "Forest Park",
                "Forsyth",
                "Fort Benning South",
                "Fort Gaines",
                "Fort Oglethorpe",
                "Fort Stewart",
                "Fort Valley",
                "Franklin",
                "Franklin Springs",
                "Funston",
                "Gainesville",
                "Garden City",
                "Garfield",
                "Gay",
                "Geneva",
                "Georgetown",
                "Gibson",
                "Gillsville",
                "Girard",
                "Glennville",
                "Glenwood",
                "Good Hope",
                "Gordon",
                "Graham",
                "Grantville",
                "Gray",
                "Grayson",
                "Greensboro",
                "Greenville",
                "Gresham Park",
                "Griffin",
                "Grovetown",
                "Gumbranch",
                "Gumlog",
                "Guyton",
                "Hagan",
                "Hahira",
                "Hamilton",
                "Hampton",
                "Hannahs Mill",
                "Hapeville",
                "Haralson",
                "Harlem",
                "Harrison",
                "Hartwell",
                "Hawkinsville",
                "Hazlehurst",
                "Helen",
                "Helena",
                "Hephzibah",
                "Hiawassee",
                "Higgston",
                "Hilltop",
                "Hiltonia",
                "Hinesville",
                "Hiram",
                "Hoboken",
                "Hogansville",
                "Holly Springs",
                "Homeland",
                "Homer",
                "Homerville",
                "Hoschton",
                "Hull",
                "Ideal",
                "Ila",
                "Indian Springs",
                "Iron City",
                "Irondale",
                "Irwinton",
                "Isle Of Hope",
                "Ivey",
                "Jackson",
                "Jacksonville",
                "Jakin",
                "Jasper",
                "Jefferson",
                "Jeffersonville",
                "Jenkinsburg",
                "Jersey",
                "Jesup",
                "Jonesboro",
                "Junction City",
                "Kennesaw",
                "Keysville",
                "Kings Bay Base",
                "Kingsland",
                "Kingston",
                "Kite",
                "La Fayette",
                "Lagrange",
                "Lake City",
                "Lake Park",
                "Lakeland",
                "Lakeview",
                "Lakeview Estates",
                "Lavonia",
                "Lawrenceville",
                "Leary",
                "Leesburg",
                "Lenox",
                "Leslie",
                "Lexington",
                "Lilburn",
                "Lilly",
                "Lincoln Park",
                "Lincolnton",
                "Lindale",
                "Lithia Springs",
                "Lithonia",
                "Locust Grove",
                "Loganville",
                "Lone Oak",
                "Lookout Mountain",
                "Louisville",
                "Lovejoy",
                "Ludowici",
                "Lula",
                "Lumber City",
                "Lumpkin",
                "Luthersville",
                "Lyerly",
                "Lyons",
                "Mableton",
                "Macon",
                "Madison",
                "Manassas",
                "Manchester",
                "Mansfield",
                "Marietta",
                "Marshallville",
                "Martin",
                "Martinez",
                "Maxeys",
                "Maysville",
                "Mccaysville",
                "Mcdonough",
                "Mcintyre",
                "Mcrae",
                "Meansville",
                "Meigs",
                "Menlo",
                "Metter",
                "Midville",
                "Midway",
                "Midway-hardwick",
                "Milan",
                "Milledgeville",
                "Millen",
                "Milner",
                "Mitchell",
                "Molena",
                "Monroe",
                "Montezuma",
                "Montgomery",
                "Monticello",
                "Montrose",
                "Moody Afb",
                "Moreland",
                "Morgan",
                "Morganton",
                "Morrow",
                "Morven",
                "Moultrie",
                "Mount Airy",
                "Mount Vernon",
                "Mount Zion",
                "Mountain City",
                "Mountain Park",
                "Nahunta",
                "Nashville",
                "Nelson",
                "Newborn",
                "Newington",
                "Newnan",
                "Newton",
                "Nicholls",
                "Nicholson",
                "Norcross",
                "Norman Park",
                "North Atlanta",
                "North Decatur",
                "North Druid Hills",
                "North High Shoals",
                "Norwood",
                "Nunez",
                "Oak Park",
                "Oakwood",
                "Ochlocknee",
                "Ocilla",
                "Oconee",
                "Odum",
                "Offerman",
                "Oglethorpe",
                "Oliver",
                "Omega",
                "Orchard Hill",
                "Oxford",
                "Palmetto",
                "Panthersville",
                "Parrott",
                "Patterson",
                "Pavo",
                "Payne",
                "Peachtree City",
                "Pearson",
                "Pelham",
                "Pembroke",
                "Pendergrass",
                "Perry",
                "Phillipsburg",
                "Pine Lake",
                "Pine Mountain",
                "Pinehurst",
                "Pineview",
                "Pitts",
                "Plains",
                "Plainville",
                "Pooler",
                "Port Wentworth",
                "Portal",
                "Porterdale",
                "Poulan",
                "Powder Springs",
                "Preston",
                "Pulaski",
                "Putney",
                "Quitman",
                "Ranger",
                "Raoul",
                "Ray City",
                "Rayle",
                "Rebecca",
                "Redan",
                "Reed Creek",
                "Register",
                "Reidsville",
                "Remerton",
                "Rentz",
                "Resaca",
                "Rest Haven",
                "Reynolds",
                "Rhine",
                "Riceboro",
                "Richland",
                "Richmond Hill",
                "Riddleville",
                "Rincon",
                "Ringgold",
                "Riverdale",
                "Riverside",
                "Roberta",
                "Robins Afb",
                "Rochelle",
                "Rockmart",
                "Rocky Ford",
                "Rome",
                "Roopville",
                "Rossville",
                "Roswell",
                "Royston",
                "Rutledge",
                "Sale City",
                "Salem",
                "Sandersville",
                "Sandy Springs",
                "Santa Claus",
                "Sardis",
                "Sasser",
                "Savannah",
                "Scotland",
                "Scottdale",
                "Screven",
                "Senoia",
                "Shady Dale",
                "Shannon",
                "Sharon",
                "Sharpsburg",
                "Shellman",
                "Shiloh",
                "Siloam",
                "Skidaway Island",
                "Sky Valley",
                "Smithville",
                "Smyrna",
                "Snellville",
                "Social Circle",
                "Soperton",
                "Sparks",
                "Sparta",
                "Springfield",
                "St. Marys",
                "St. Simons",
                "Stapleton",
                "Statesboro",
                "Statham",
                "Stillmore",
                "Stockbridge",
                "Stone Mountain",
                "Sugar Hill",
                "Summertown",
                "Summerville",
                "Sumner",
                "Sunny Side",
                "Sunnyside",
                "Sunset Village",
                "Surrency",
                "Suwanee",
                "Swainsboro",
                "Sycamore",
                "Sylvania",
                "Sylvester",
                "Talbotton",
                "Talking Rock",
                "Tallapoosa",
                "Tallulah Falls",
                "Talmo",
                "Tarrytown",
                "Taylorsville",
                "Temple",
                "Tennille",
                "Thomaston",
                "Thomasville",
                "Thomson",
                "Thunderbolt",
                "Tifton",
                "Tiger",
                "Tignall",
                "Toccoa",
                "Toomsboro",
                "Trenton",
                "Trion",
                "Tucker",
                "Tunnel Hill",
                "Turin",
                "Twin City",
                "Ty Ty",
                "Tybee Island",
                "Tyrone",
                "Unadilla",
                "Union City",
                "Union Point",
                "Unionville",
                "Uvalda",
                "Valdosta",
                "Varnell",
                "Vernonburg",
                "Vidalia",
                "Vidette",
                "Vienna",
                "Villa Rica",
                "Vinings",
                "Waco",
                "Wadley",
                "Waleska",
                "Walnut Grove",
                "Walthourville",
                "Warm Springs",
                "Warner Robins",
                "Warrenton",
                "Warwick",
                "Washington",
                "Watkinsville",
                "Waverly Hall",
                "Waycross",
                "Waynesboro",
                "West Point",
                "Weston",
                "Whigham",
                "White",
                "White Plains",
                "Whitemarsh Island",
                "Whitesburg",
                "Willacoochee",
                "Williamson",
                "Wilmington Island",
                "Winder",
                "Winterville",
                "Woodbine",
                "Woodbury",
                "Woodland",
                "Woodstock",
                "Woodville",
                "Woolsey",
                "Wrens",
                "Wrightsville",
                "Yatesville",
                "Young Harris",
                "Zebulon"
            ],
            "Guam": [
                "Guam"
            ],
            "Hawaii": [
                "Ahuimanu",
                "Aiea",
                "Ainaloa",
                "Anahola",
                "Barbers Point Housing",
                "Captain Cook",
                "Eden Roc",
                "Eleele",
                "Ewa Beach",
                "Ewa Gentry",
                "Ewa Villages",
                "Fern Acres",
                "Fern Forest",
                "Haiku-pauwela",
                "Halaula",
                "Halawa",
                "Haleiwa",
                "Haliimaile",
                "Hana",
                "Hanalei",
                "Hanamaulu",
                "Hanapepe",
                "Hauula",
                "Hawaiian Acres",
                "Hawaiian Beaches",
                "Hawaiian Ocean View",
                "Hawaiian Paradise Park",
                "Hawi",
                "Heeia",
                "Hickam Housing",
                "Hilo",
                "Holualoa",
                "Honalo",
                "Honaunau-napoopoo",
                "Honokaa",
                "Honolulu",
                "Honomu",
                "Iroquois Point",
                "Kaaawa",
                "Kaanapali",
                "Kahaluu",
                "Kahaluu-keauhou",
                "Kahuku",
                "Kahului",
                "Kailua",
                "Kalaheo",
                "Kalaoa",
                "Kalihiwai",
                "Kaneohe",
                "Kaneohe Station",
                "Kapaa",
                "Kapaau",
                "Kapalua",
                "Kaumakani",
                "Kaunakakai",
                "Kawela Bay",
                "Keaau",
                "Kealakekua",
                "Kekaha",
                "Kihei",
                "Kilauea",
                "Koloa",
                "Kualapuu",
                "Kukuihaele",
                "Kurtistown",
                "Lahaina",
                "Laie",
                "Lanai City",
                "Laupahoehoe",
                "Lawai",
                "Leilani Estates",
                "Lihue",
                "Maalaea",
                "Maili",
                "Makaha",
                "Makaha Valley",
                "Makakilo City",
                "Makawao",
                "Maunaloa",
                "Maunawili",
                "Mililani Town",
                "Mokuleia",
                "Mountain View",
                "Naalehu",
                "Nanakuli",
                "Nanawale Estates",
                "Napili-honokowai",
                "Omao",
                "Orchidlands Estates",
                "Paauilo",
                "Pahala",
                "Pahoa",
                "Paia",
                "Pakala Village",
                "Papaikou",
                "Paukaa",
                "Pearl City",
                "Pepeekeo",
                "Poipu",
                "Princeville",
                "Puako",
                "Puhi",
                "Pukalani",
                "Punaluu",
                "Pupukea",
                "Schofield Barracks",
                "Village Park",
                "Volcano",
                "Wahiawa",
                "Waialua",
                "Waianae",
                "Waihee-waiehu",
                "Waikane",
                "Waikapu",
                "Waikoloa Village",
                "Wailea-makena",
                "Wailua",
                "Wailua Homesteads",
                "Wailuku",
                "Waimalu",
                "Waimanalo",
                "Waimanalo Beach",
                "Waimea",
                "Wainaku",
                "Waipahu",
                "Waipio",
                "Waipio Acres",
                "Wheeler Afb",
                "Whitmore Village"
            ],
            "Idaho": [
                "Aberdeen",
                "Acequia",
                "Albion",
                "American Falls",
                "Ammon",
                "Arbon Valley",
                "Arco",
                "Arimo",
                "Ashton",
                "Athol",
                "Atomic City",
                "Bancroft",
                "Basalt",
                "Bellevue",
                "Blackfoot",
                "Bliss",
                "Bloomington",
                "Boise City",
                "Bonners Ferry",
                "Bovill",
                "Buhl",
                "Burley",
                "Butte City",
                "Caldwell",
                "Cambridge",
                "Carey",
                "Cascade",
                "Castleford",
                "Challis",
                "Chubbuck",
                "Clark Fork",
                "Clayton",
                "Clifton",
                "Coeur D Alene",
                "Cottonwood",
                "Council",
                "Craigmont",
                "Crouch",
                "Culdesac",
                "Dalton Gardens",
                "Dayton",
                "Deary",
                "Declo",
                "Dietrich",
                "Donnelly",
                "Dover",
                "Downey",
                "Driggs",
                "Drummond",
                "Dubois",
                "Eagle",
                "East Hope",
                "Eden",
                "Elk River",
                "Emmett",
                "Fairfield",
                "Ferdinand",
                "Fernan Lake Village",
                "Filer",
                "Firth",
                "Fort Hall",
                "Franklin",
                "Fruitland",
                "Garden City",
                "Genesee",
                "Georgetown",
                "Glenns Ferry",
                "Gooding",
                "Grace",
                "Grand View",
                "Grangeville",
                "Greenleaf",
                "Hagerman",
                "Hailey",
                "Hamer",
                "Hansen",
                "Harrison",
                "Hauser",
                "Hayden",
                "Hayden Lake",
                "Hazelton",
                "Heyburn",
                "Hollister",
                "Homedale",
                "Hope",
                "Horseshoe Bend",
                "Huetter",
                "Idaho City",
                "Idaho Falls",
                "Inkom",
                "Iona",
                "Irwin",
                "Island Park",
                "Jerome",
                "Juliaetta",
                "Kamiah",
                "Kellogg",
                "Kendrick",
                "Ketchum",
                "Kimberly",
                "Kooskia",
                "Kootenai",
                "Kuna",
                "Lapwai",
                "Lava Hot Springs",
                "Leadore",
                "Lewiston",
                "Lewisville",
                "Lost River",
                "Mackay",
                "Malad City",
                "Malta",
                "Marsing",
                "Mccall",
                "Mccammon",
                "Melba",
                "Menan",
                "Meridian",
                "Middleton",
                "Midvale",
                "Minidoka",
                "Montpelier",
                "Moore",
                "Moscow",
                "Mountain Home",
                "Mountain Home Afb",
                "Moyie Springs",
                "Mud Lake",
                "Mullan",
                "Murtaugh",
                "Nampa",
                "New Meadows",
                "New Plymouth",
                "Newdale",
                "Nezperce",
                "Notus",
                "Oakley",
                "Oldtown",
                "Onaway",
                "Orofino",
                "Osburn",
                "Oxford",
                "Paris",
                "Parker",
                "Parkline",
                "Parma",
                "Paul",
                "Payette",
                "Peck",
                "Pierce",
                "Pinehurst",
                "Placerville",
                "Plummer",
                "Pocatello",
                "Ponderay",
                "Post Falls",
                "Potlatch",
                "Preston",
                "Priest River",
                "Rathdrum",
                "Reubens",
                "Rexburg",
                "Richfield",
                "Rigby",
                "Riggins",
                "Ririe",
                "Roberts",
                "Rockland",
                "Rupert",
                "Salmon",
                "Sandpoint",
                "Shelley",
                "Shoshone",
                "Smelterville",
                "Soda Springs",
                "Spencer",
                "Spirit Lake",
                "St. Anthony",
                "St. Charles",
                "St. Maries",
                "Stanley",
                "Star",
                "State Line",
                "Stites",
                "Sugar City",
                "Sun Valley",
                "Swan Valley",
                "Tensed",
                "Teton",
                "Tetonia",
                "Troy",
                "Twin Falls",
                "Ucon",
                "Victor",
                "Wallace",
                "Wardner",
                "Warm River",
                "Weippe",
                "Weiser",
                "Wendell",
                "Weston",
                "White Bird",
                "Wilder",
                "Winchester",
                "Worley"
            ],
            "Illinois": [
                "Abingdon",
                "Addieville",
                "Addison",
                "Adeline",
                "Albany",
                "Albers",
                "Albion",
                "Aledo",
                "Alexis",
                "Algonquin",
                "Alhambra",
                "Allendale",
                "Allenville",
                "Allerton",
                "Alma",
                "Alorton",
                "Alpha",
                "Alsey",
                "Alsip",
                "Altamont",
                "Alto Pass",
                "Alton",
                "Altona",
                "Alvin",
                "Amboy",
                "Anchor",
                "Andalusia",
                "Andover",
                "Anna",
                "Annawan",
                "Antioch",
                "Apple River",
                "Arcola",
                "Arenzville",
                "Argenta",
                "Arlington",
                "Arlington Heights",
                "Armington",
                "Aroma Park",
                "Arrowsmith",
                "Arthur",
                "Ashkum",
                "Ashland",
                "Ashley",
                "Ashmore",
                "Ashton",
                "Assumption",
                "Astoria",
                "Athens",
                "Atkinson",
                "Atlanta",
                "Atwood",
                "Auburn",
                "Augusta",
                "Aurora",
                "Ava",
                "Aviston",
                "Avon",
                "Baldwin",
                "Banner",
                "Bannockburn",
                "Bardolph",
                "Barrington",
                "Barrington Hills",
                "Barry",
                "Bartelso",
                "Bartlett",
                "Bartonville",
                "Basco",
                "Batavia",
                "Batchtown",
                "Bath",
                "Bay View Gardens",
                "Baylis",
                "Beach Park",
                "Beardstown",
                "Beaverville",
                "Beckemeyer",
                "Bedford Park",
                "Beecher",
                "Beecher City",
                "Belgium",
                "Belknap",
                "Belle Prairie City",
                "Belle Rive",
                "Belleville",
                "Bellevue",
                "Bellflower",
                "Bellmont",
                "Bellwood",
                "Belvidere",
                "Bement",
                "Benld",
                "Bensenville",
                "Benson",
                "Bentley",
                "Benton",
                "Berkeley",
                "Berlin",
                "Berwyn",
                "Bethalto",
                "Bethany",
                "Biggsville",
                "Bingham",
                "Birds",
                "Bishop Hill",
                "Bismarck",
                "Blandinsville",
                "Bloomingdale",
                "Bloomington",
                "Blue Island",
                "Blue Mound",
                "Bluffs",
                "Bluford",
                "Bolingbrook",
                "Bondville",
                "Bone Gap",
                "Bonfield",
                "Bonnie",
                "Boulder Hill",
                "Bourbonnais",
                "Bowen",
                "Braceville",
                "Bradford",
                "Bradley",
                "Braidwood",
                "Breese",
                "Bridgeport",
                "Bridgeview",
                "Brighton",
                "Brimfield",
                "Broadlands",
                "Broadview",
                "Broadwell",
                "Brocton",
                "Brookfield",
                "Brooklyn",
                "Brookport",
                "Broughton",
                "Browning",
                "Browns",
                "Brownstown",
                "Brussels",
                "Bryant",
                "Buckingham",
                "Buckley",
                "Buckner",
                "Buda",
                "Buffalo",
                "Buffalo Grove",
                "Bull Valley",
                "Bulpitt",
                "Buncombe",
                "Bunker Hill",
                "Burbank",
                "Bureau Junction",
                "Burlington",
                "Burnham",
                "Burnt Prairie",
                "Burr Ridge",
                "Bush",
                "Bushnell",
                "Butler",
                "Byron",
                "Cabery",
                "Cahokia",
                "Cairo",
                "Caledonia",
                "Calhoun",
                "Calumet City",
                "Calumet Park",
                "Camargo",
                "Cambria",
                "Cambridge",
                "Camden",
                "Camp Point",
                "Campbell Hill",
                "Campus",
                "Canton",
                "Cantrall",
                "Capron",
                "Carbon Cliff",
                "Carbon Hill",
                "Carbondale",
                "Carlinville",
                "Carlock",
                "Carlyle",
                "Carmi",
                "Carol Stream",
                "Carpentersville",
                "Carrier Mills",
                "Carrollton",
                "Carterville",
                "Carthage",
                "Cary",
                "Casey",
                "Caseyville",
                "Catlin",
                "Cave-in-rock",
                "Cedar Point",
                "Cedarville",
                "Central City",
                "Centralia",
                "Centreville",
                "Cerro Gordo",
                "Chadwick",
                "Champaign",
                "Chandlerville",
                "Channahon",
                "Channel Lake",
                "Chapin",
                "Charleston",
                "Chatham",
                "Chatsworth",
                "Chebanse",
                "Chenoa",
                "Cherry",
                "Cherry Valley",
                "Chester",
                "Chesterfield",
                "Chicago",
                "Chicago Heights",
                "Chicago Ridge",
                "Chillicothe",
                "Chrisman",
                "Christopher",
                "Cicero",
                "Cisco",
                "Cisne",
                "Cissna Park",
                "Claremont",
                "Clarendon Hills",
                "Clay City",
                "Clayton",
                "Clear Lake",
                "Cleveland",
                "Clifton",
                "Clinton",
                "Coal City",
                "Coal Valley",
                "Coalton",
                "Coatsburg",
                "Cobden",
                "Coffeen",
                "Colchester",
                "Coleta",
                "Colfax",
                "Collinsville",
                "Colona",
                "Colp",
                "Columbia",
                "Columbus",
                "Compton",
                "Concord",
                "Congerville",
                "Cooksville",
                "Cordova",
                "Cornell",
                "Cortland",
                "Coulterville",
                "Country Club Hills",
                "Countryside",
                "Cowden",
                "Coyne Center",
                "Crainville",
                "Creal Springs",
                "Crescent City",
                "Crest Hill",
                "Creston",
                "Crestwood",
                "Crete",
                "Creve Coeur",
                "Crossville",
                "Crystal Lake",
                "Crystal Lawns",
                "Cuba",
                "Cullom",
                "Cutler",
                "Cypress",
                "Dahlgren",
                "Dakota",
                "Dallas City",
                "Dalton City",
                "Dalzell",
                "Damiansville",
                "Dana",
                "Danforth",
                "Danvers",
                "Danville",
                "Darien",
                "Davis",
                "Davis Junction",
                "Dawson",
                "De Land",
                "De Pue",
                "De Soto",
                "De Witt",
                "Decatur",
                "Deer Creek",
                "Deer Grove",
                "Deer Park",
                "Deerfield",
                "Dekalb",
                "Delavan",
                "Des Plaines",
                "Detroit",
                "Diamond",
                "Dieterich",
                "Divernon",
                "Dix",
                "Dixmoor",
                "Dixon",
                "Dolton",
                "Dongola",
                "Donnellson",
                "Donovan",
                "Dorchester",
                "Dover",
                "Dowell",
                "Downers Grove",
                "Downs",
                "Du Bois",
                "Du Quoin",
                "Dunfermline",
                "Dunlap",
                "Dupo",
                "Durand",
                "Dwight",
                "Eagarville",
                "Earlville",
                "East Alton",
                "East Brooklyn",
                "East Cape Girardeau",
                "East Carondelet",
                "East Dubuque",
                "East Dundee",
                "East Galesburg",
                "East Gillespie",
                "East Hazel Crest",
                "East Moline",
                "East Peoria",
                "East St. Louis",
                "Easton",
                "Eddyville",
                "Edgewood",
                "Edinburg",
                "Edwardsville",
                "Effingham",
                "El Dara",
                "El Paso",
                "Elburn",
                "Eldorado",
                "Eldred",
                "Elgin",
                "Elizabeth",
                "Elizabethtown",
                "Elk Grove Village",
                "Elkhart",
                "Elkville",
                "Elliott",
                "Ellis Grove",
                "Ellisville",
                "Ellsworth",
                "Elmhurst",
                "Elmwood",
                "Elmwood Park",
                "Elsah",
                "Elvaston",
                "Elwood",
                "Emden",
                "Emington",
                "Energy",
                "Enfield",
                "Equality",
                "Erie",
                "Essex",
                "Eureka",
                "Evanston",
                "Evansville",
                "Evergreen Park",
                "Ewing",
                "Exeter",
                "Fairbury",
                "Fairfield",
                "Fairmont",
                "Fairmont City",
                "Fairmount",
                "Fairview",
                "Fairview Heights",
                "Farina",
                "Farmer City",
                "Farmersville",
                "Farmington",
                "Fayetteville",
                "Ferris",
                "Fidelity",
                "Fieldon",
                "Fillmore",
                "Findlay",
                "Fisher",
                "Fithian",
                "Flanagan",
                "Flat Rock",
                "Flora",
                "Florence",
                "Flossmoor",
                "Foosland",
                "Ford Heights",
                "Forest City",
                "Forest Lake",
                "Forest Park",
                "Forest View",
                "Forrest",
                "Forreston",
                "Forsyth",
                "Fox Lake",
                "Fox Lake Hills",
                "Fox River Grove",
                "Fox River Valley Gardens",
                "Frankfort",
                "Frankfort Square",
                "Franklin",
                "Franklin Grove",
                "Franklin Park",
                "Freeburg",
                "Freeman Spur",
                "Freeport",
                "Fulton",
                "Fults",
                "Gages Lake",
                "Galatia",
                "Galena",
                "Galesburg",
                "Galva",
                "Gardner",
                "Garrett",
                "Gays",
                "Geneseo",
                "Geneva",
                "Genoa",
                "Georgetown",
                "German Valley",
                "Germantown",
                "Germantown Hills",
                "Gibson",
                "Gifford",
                "Gilberts",
                "Gillespie",
                "Gilman",
                "Girard",
                "Gladstone",
                "Glasford",
                "Glasgow",
                "Glen Carbon",
                "Glen Ellyn",
                "Glencoe",
                "Glendale Heights",
                "Glenview",
                "Glenwood",
                "Godfrey",
                "Godley",
                "Golconda",
                "Golden",
                "Golden Gate",
                "Golf",
                "Good Hope",
                "Goodfield",
                "Goodings Grove",
                "Goreville",
                "Gorham",
                "Grafton",
                "Grand Ridge",
                "Grand Tower",
                "Grandview",
                "Grandwood Park",
                "Granite City",
                "Grant Park",
                "Grantfork",
                "Granville",
                "Grayslake",
                "Grayville",
                "Green Oaks",
                "Green Valley",
                "Greenfield",
                "Greenup",
                "Greenview",
                "Greenville",
                "Greenwood",
                "Gridley",
                "Griggsville",
                "Gulf Port",
                "Gurnee",
                "Hainesville",
                "Hamburg",
                "Hamel",
                "Hamilton",
                "Hammond",
                "Hampshire",
                "Hampton",
                "Hanaford",
                "Hanna City",
                "Hanover",
                "Hanover Park",
                "Hardin",
                "Harmon",
                "Harrisburg",
                "Harristown",
                "Hartford",
                "Hartsburg",
                "Harvard",
                "Harvel",
                "Harvey",
                "Harwood Heights",
                "Havana",
                "Hawthorn Woods",
                "Hazel Crest",
                "Hebron",
                "Hecker",
                "Henderson",
                "Hennepin",
                "Henning",
                "Henry",
                "Herrick",
                "Herrin",
                "Herscher",
                "Hettick",
                "Heyworth",
                "Hickory Hills",
                "Hidalgo",
                "Highland",
                "Highland Park",
                "Highwood",
                "Hillcrest",
                "Hillsboro",
                "Hillsdale",
                "Hillside",
                "Hillview",
                "Hinckley",
                "Hindsboro",
                "Hinsdale",
                "Hodgkins",
                "Hoffman",
                "Hoffman Estates",
                "Holiday Hills",
                "Hollowayville",
                "Homer",
                "Hometown",
                "Homewood",
                "Hoopeston",
                "Hooppole",
                "Hopedale",
                "Hopewell",
                "Hopkins Park",
                "Hoyleton",
                "Hudson",
                "Huey",
                "Hull",
                "Humboldt",
                "Hume",
                "Huntley",
                "Hurst",
                "Hutsonville",
                "Illiopolis",
                "Ina",
                "Indian Creek",
                "Indian Head Park",
                "Indianola",
                "Industry",
                "Ingalls Park",
                "Inverness",
                "Iola",
                "Ipava",
                "Iroquois",
                "Irving",
                "Irvington",
                "Irwin",
                "Island Lake",
                "Itasca",
                "Iuka",
                "Ivesdale",
                "Jacksonville",
                "Jeffersonville",
                "Jeisyville",
                "Jerome",
                "Jerseyville",
                "Jewett",
                "Johnsburg",
                "Johnsonville",
                "Johnston City",
                "Joliet",
                "Jonesboro",
                "Joppa",
                "Joy",
                "Junction",
                "Junction City",
                "Justice",
                "Kampsville",
                "Kane",
                "Kangley",
                "Kankakee",
                "Kansas",
                "Kappa",
                "Karnak",
                "Kaskaskia",
                "Keenes",
                "Keensburg",
                "Keithsburg",
                "Kell",
                "Kempton",
                "Kenilworth",
                "Kenney",
                "Kewanee",
                "Keyesport",
                "Kilbourne",
                "Kildeer",
                "Kincaid",
                "Kinderhook",
                "Kingston",
                "Kingston Mines",
                "Kinmundy",
                "Kinsman",
                "Kirkland",
                "Kirkwood",
                "Knoxville",
                "La Fayette",
                "La Grange",
                "La Grange Park",
                "La Harpe",
                "La Moille",
                "La Prairie",
                "La Rose",
                "La Salle",
                "Lacon",
                "Ladd",
                "Lake Barrington",
                "Lake Bluff",
                "Lake Catherine",
                "Lake Forest",
                "Lake In The Hills",
                "Lake Of The Woods",
                "Lake Summerset",
                "Lake Villa",
                "Lake Zurich",
                "Lakemoor",
                "Lakewood",
                "Lakewood Shores",
                "Lanark",
                "Lansing",
                "Latham",
                "Lawrenceville",
                "Le Roy",
                "Leaf River",
                "Lebanon",
                "Lee",
                "Leland",
                "Leland Grove",
                "Lemont",
                "Lena",
                "Lenzburg",
                "Leonore",
                "Lerna",
                "Lewistown",
                "Lexington",
                "Liberty",
                "Libertyville",
                "Lily Lake",
                "Lima",
                "Lincoln",
                "Lincolnshire",
                "Lincolnwood",
                "Lindenhurst",
                "Lisbon",
                "Lisle",
                "Litchfield",
                "Little York",
                "Littleton",
                "Liverpool",
                "Livingston",
                "Loami",
                "Lockport",
                "Loda",
                "Lomax",
                "Lombard",
                "London Mills",
                "Long Creek",
                "Long Grove",
                "Long Lake",
                "Long Point",
                "Longview",
                "Loraine",
                "Lostant",
                "Louisville",
                "Loves Park",
                "Lovington",
                "Ludlow",
                "Lyndon",
                "Lynnville",
                "Lynwood",
                "Lyons",
                "Macedonia",
                "Machesney Park",
                "Mackinaw",
                "Macomb",
                "Macon",
                "Madison",
                "Maeystown",
                "Magnolia",
                "Mahomet",
                "Makanda",
                "Malden",
                "Malta",
                "Manchester",
                "Manhattan",
                "Manito",
                "Manlius",
                "Mansfield",
                "Manteno",
                "Maple Park",
                "Mapleton",
                "Maquon",
                "Marengo",
                "Marietta",
                "Marine",
                "Marion",
                "Marissa",
                "Mark",
                "Markham",
                "Maroa",
                "Marquette Heights",
                "Marseilles",
                "Marshall",
                "Martinsville",
                "Martinton",
                "Maryville",
                "Mascoutah",
                "Mason",
                "Mason City",
                "Matherville",
                "Matteson",
                "Mattoon",
                "Maunie",
                "Maywood",
                "Mazon",
                "Mccook",
                "Mccullom Lake",
                "Mchenry",
                "Mclean",
                "Mcleansboro",
                "Mcnabb",
                "Mechanicsburg",
                "Media",
                "Medora",
                "Melrose Park",
                "Melvin",
                "Mendon",
                "Mendota",
                "Menominee",
                "Meredosia",
                "Merrionette Park",
                "Metamora",
                "Metcalf",
                "Metropolis",
                "Mettawa",
                "Middletown",
                "Midlothian",
                "Milan",
                "Milford",
                "Mill Creek",
                "Mill Shoals",
                "Milledgeville",
                "Millington",
                "Millstadt",
                "Milton",
                "Mineral",
                "Minier",
                "Minonk",
                "Minooka",
                "Modesto",
                "Mokena",
                "Moline",
                "Momence",
                "Monee",
                "Monmouth",
                "Montgomery",
                "Monticello",
                "Montrose",
                "Morris",
                "Morrison",
                "Morrisonville",
                "Morton",
                "Morton Grove",
                "Mound City",
                "Mound Station",
                "Mounds",
                "Mount Auburn",
                "Mount Carmel",
                "Mount Carroll",
                "Mount Clare",
                "Mount Erie",
                "Mount Morris",
                "Mount Olive",
                "Mount Prospect",
                "Mount Pulaski",
                "Mount Sterling",
                "Mount Vernon",
                "Mount Zion",
                "Moweaqua",
                "Muddy",
                "Mulberry Grove",
                "Muncie",
                "Mundelein",
                "Murphysboro",
                "Murrayville",
                "Naperville",
                "Naplate",
                "Naples",
                "Nashville",
                "Nason",
                "Nauvoo",
                "Nebo",
                "Nelson",
                "Neoga",
                "Neponset",
                "New Athens",
                "New Baden",
                "New Bedford",
                "New Berlin",
                "New Boston",
                "New Burnside",
                "New Canton",
                "New Douglas",
                "New Grand Chain",
                "New Haven",
                "New Holland",
                "New Lenox",
                "New Millford",
                "New Minden",
                "New Salem",
                "Newark",
                "Newman",
                "Newton",
                "Niantic",
                "Niles",
                "Nilwood",
                "Noble",
                "Nokomis",
                "Nora",
                "Normal",
                "Norridge",
                "Norris",
                "Norris City",
                "North Aurora",
                "North Barrington",
                "North Chicago",
                "North City",
                "North Henderson",
                "North Pekin",
                "North Riverside",
                "North Utica",
                "Northbrook",
                "Northfield",
                "Northlake",
                "Norwood",
                "O Fallon",
                "Oak Brook",
                "Oak Forest",
                "Oak Grove",
                "Oak Lawn",
                "Oak Park",
                "Oakbrook Terrace",
                "Oakdale",
                "Oakford",
                "Oakland",
                "Oakwood",
                "Oakwood Hills",
                "Oblong",
                "Oconee",
                "Odell",
                "Odin",
                "Ogden",
                "Oglesby",
                "Ohio",
                "Ohlman",
                "Okawville",
                "Old Mill Creek",
                "Old Ripley",
                "Old Shawneetown",
                "Olmsted",
                "Olney",
                "Olympia Fields",
                "Omaha",
                "Onarga",
                "Oneida",
                "Oquawka",
                "Orangeville",
                "Oreana",
                "Oregon",
                "Orient",
                "Orion",
                "Orland Hills",
                "Orland Park",
                "Oswego",
                "Ottawa",
                "Otterville",
                "Owaneco",
                "Palatine",
                "Palestine",
                "Palmer",
                "Palmyra",
                "Palos Heights",
                "Palos Hills",
                "Palos Park",
                "Pana",
                "Panama",
                "Panola",
                "Papineau",
                "Paris",
                "Park City",
                "Park Forest",
                "Park Ridge",
                "Parkersburg",
                "Patoka",
                "Paw Paw",
                "Pawnee",
                "Paxton",
                "Payson",
                "Pearl",
                "Pearl City",
                "Pecatonica",
                "Pekin",
                "Peoria",
                "Peoria Heights",
                "Peotone",
                "Percy",
                "Perry",
                "Peru",
                "Pesotum",
                "Petersburg",
                "Phillipstown",
                "Philo",
                "Phoenix",
                "Pierron",
                "Pinckneyville",
                "Pingree Grove",
                "Piper City",
                "Pistakee Highlands",
                "Pittsburg",
                "Pittsfield",
                "Plainfield",
                "Plainville",
                "Plano",
                "Pleasant Hill",
                "Pleasant Plains",
                "Plymouth",
                "Pocahontas",
                "Polo",
                "Pontiac",
                "Pontoon Beach",
                "Pontoosuc",
                "Poplar Grove",
                "Port Byron",
                "Posen",
                "Potomac",
                "Prairie City",
                "Prairie Du Rocher",
                "Prairie Grove",
                "Preston Heights",
                "Princeton",
                "Princeville",
                "Prophetstown",
                "Prospect Heights",
                "Pulaski",
                "Quincy",
                "Radom",
                "Raleigh",
                "Ramsey",
                "Rankin",
                "Ransom",
                "Rantoul",
                "Rapids City",
                "Raritan",
                "Raymond",
                "Red Bud",
                "Reddick",
                "Redmon",
                "Reynolds",
                "Richmond",
                "Richton Park",
                "Richview",
                "Ridge Farm",
                "Ridgway",
                "Ridott",
                "Ringwood",
                "Rio",
                "Ripley",
                "River Forest",
                "River Grove",
                "Riverdale",
                "Riverside",
                "Riverton",
                "Riverwoods",
                "Roanoke",
                "Robbins",
                "Roberts",
                "Robinson",
                "Rochelle",
                "Rochester",
                "Rock City",
                "Rock Falls",
                "Rock Island",
                "Rock Island Arsenal",
                "Rockbridge",
                "Rockdale",
                "Rockford",
                "Rockton",
                "Rockwood",
                "Rolling Meadows",
                "Rome",
                "Romeoville",
                "Roodhouse",
                "Roscoe",
                "Rose Hill",
                "Roselle",
                "Rosemont",
                "Roseville",
                "Rosewood Heights",
                "Rosiclare",
                "Rossville",
                "Round Lake",
                "Round Lake Beach",
                "Round Lake Heights",
                "Round Lake Park",
                "Roxana",
                "Royal",
                "Royal Lakes",
                "Royalton",
                "Ruma",
                "Rushville",
                "Russellville",
                "Rutland",
                "Sadorus",
                "Sailor Springs",
                "Salem",
                "San Jose",
                "Sandoval",
                "Sandwich",
                "Sauget",
                "Sauk Village",
                "Saunemin",
                "Savanna",
                "Savoy",
                "Sawyerville",
                "Saybrook",
                "Scales Mound",
                "Schaumburg",
                "Schiller Park",
                "Schram City",
                "Sciota",
                "Scott Afb",
                "Scottville",
                "Seaton",
                "Seatonville",
                "Secor",
                "Seneca",
                "Sesser",
                "Shabbona",
                "Shannon",
                "Shawneetown",
                "Sheffield",
                "Shelbyville",
                "Sheldon",
                "Sheridan",
                "Sherman",
                "Sherrard",
                "Shiloh",
                "Shipman",
                "Shorewood",
                "Shumway",
                "Sibley",
                "Sidell",
                "Sidney",
                "Sigel",
                "Silvis",
                "Simpson",
                "Sims",
                "Skokie",
                "Sleepy Hollow",
                "Smithboro",
                "Smithfield",
                "Smithton",
                "Somonauk",
                "Sorento",
                "South Barrington",
                "South Beloit",
                "South Chicago Heights",
                "South Elgin",
                "South Holland",
                "South Jacksonville",
                "South Pekin",
                "South Roxana",
                "South Wilmington",
                "Southern View",
                "Sparland",
                "Sparta",
                "Spaulding",
                "Spillertown",
                "Spring Bay",
                "Spring Grove",
                "Spring Valley",
                "Springerton",
                "Springfield",
                "St. Anne",
                "St. Augustine",
                "St. Charles",
                "St. David",
                "St. Elmo",
                "St. Francisville",
                "St. Jacob",
                "St. Johns",
                "St. Joseph",
                "St. Libory",
                "St. Peter",
                "Standard",
                "Standard City",
                "Stanford",
                "Staunton",
                "Ste. Marie",
                "Steeleville",
                "Steger",
                "Sterling",
                "Steward",
                "Stewardson",
                "Stickney",
                "Stillman Valley",
                "Stockton",
                "Stone Park",
                "Stonefort",
                "Stonington",
                "Stoy",
                "Strasburg",
                "Strawn",
                "Streamwood",
                "Streator",
                "Stronghurst",
                "Sublette",
                "Sugar Grove",
                "Sullivan",
                "Summerfield",
                "Summit",
                "Sumner",
                "Sun River Terrace",
                "Swansea",
                "Sycamore",
                "Symerton",
                "Table Grove",
                "Tallula",
                "Tamaroa",
                "Tamms",
                "Tampico",
                "Taylor Springs",
                "Taylorville",
                "Tennessee",
                "Teutopolis",
                "Thawville",
                "Thayer",
                "Thebes",
                "Third Lake",
                "Thomasboro",
                "Thompsonville",
                "Thomson",
                "Thornton",
                "Tilden",
                "Tilton",
                "Timberlane",
                "Time",
                "Tinley Park",
                "Tiskilwa",
                "Toledo",
                "Tolono",
                "Toluca",
                "Tonica",
                "Topeka",
                "Toulon",
                "Tovey",
                "Towanda",
                "Tower Hill",
                "Tower Lakes",
                "Tremont",
                "Trenton",
                "Trout Valley",
                "Troy",
                "Troy Grove",
                "Tuscola",
                "Ullin",
                "Union",
                "Union Hill",
                "University Park",
                "Urbana",
                "Ursa",
                "Valier",
                "Valley City",
                "Valmeyer",
                "Vandalia",
                "Varna",
                "Venedy",
                "Venetian Village",
                "Venice",
                "Vergennes",
                "Vermilion",
                "Vermont",
                "Vernon",
                "Vernon Hills",
                "Verona",
                "Versailles",
                "Victoria",
                "Vienna",
                "Villa Grove",
                "Villa Park",
                "Viola",
                "Virden",
                "Virgil",
                "Virginia",
                "Volo",
                "Wadsworth",
                "Waggoner",
                "Walnut",
                "Walnut Hill",
                "Walshville",
                "Waltonville",
                "Wamac",
                "Wapella",
                "Warren",
                "Warrensburg",
                "Warrenville",
                "Warsaw",
                "Washburn",
                "Washington",
                "Washington Park",
                "Wataga",
                "Waterloo",
                "Waterman",
                "Watseka",
                "Watson",
                "Wauconda",
                "Waukegan",
                "Waverly",
                "Wayne",
                "Wayne City",
                "Waynesville",
                "Weldon",
                "Wellington",
                "Wenona",
                "Wenonah",
                "West Brooklyn",
                "West Chicago",
                "West City",
                "West Dundee",
                "West Frankfort",
                "West Peoria",
                "West Point",
                "West Salem",
                "Westchester",
                "Western Springs",
                "Westfield",
                "Westmont",
                "Westville",
                "Wheaton",
                "Wheeler",
                "Wheeling",
                "White City",
                "White Hall",
                "Whiteash",
                "Williamsfield",
                "Williamson",
                "Williamsville",
                "Willisville",
                "Willow Hill",
                "Willow Springs",
                "Willowbrook",
                "Wilmette",
                "Wilmington",
                "Wilsonville",
                "Winchester",
                "Windsor",
                "Winfield",
                "Winnebago",
                "Winnetka",
                "Winslow",
                "Winthrop Harbor",
                "Witt",
                "Wonder Lake",
                "Wood Dale",
                "Wood River",
                "Woodhull",
                "Woodland",
                "Woodlawn",
                "Woodridge",
                "Woodson",
                "Woodstock",
                "Worden",
                "Worth",
                "Wyanet",
                "Wyoming",
                "Xenia",
                "Yale",
                "Yates City",
                "Yorkville",
                "Zeigler",
                "Zion"
            ],
            "Indiana": [
                "Advance",
                "Akron",
                "Alamo",
                "Albany",
                "Albion",
                "Alexandria",
                "Alfordsville",
                "Alton",
                "Altona",
                "Ambia",
                "Amboy",
                "Amo",
                "Anderson",
                "Andrews",
                "Angola",
                "Arcadia",
                "Argos",
                "Ashley",
                "Atlanta",
                "Attica",
                "Auburn",
                "Aurora",
                "Austin",
                "Avilla",
                "Avon",
                "Bainbridge",
                "Bargersville",
                "Bass Lake",
                "Batesville",
                "Battle Ground",
                "Bedford",
                "Beech Grove",
                "Berne",
                "Bethany",
                "Beverly Shores",
                "Bicknell",
                "Birdseye",
                "Bloomfield",
                "Bloomingdale",
                "Bloomington",
                "Blountsville",
                "Bluffton",
                "Boonville",
                "Borden",
                "Boston",
                "Boswell",
                "Bourbon",
                "Brazil",
                "Bremen",
                "Bright",
                "Bristol",
                "Brook",
                "Brooklyn",
                "Brooksburg",
                "Brookston",
                "Brookville",
                "Brownsburg",
                "Brownstown",
                "Bruceville",
                "Bryant",
                "Buffalo",
                "Bunker Hill",
                "Burket",
                "Burlington",
                "Burnettsville",
                "Burns Harbor",
                "Butler",
                "Cadiz",
                "Cambridge City",
                "Camden",
                "Campbellsburg",
                "Cannelburg",
                "Cannelton",
                "Carbon",
                "Carlisle",
                "Carmel",
                "Carthage",
                "Cayuga",
                "Cedar Grove",
                "Cedar Lake",
                "Center Point",
                "Centerville",
                "Chalmers",
                "Chandler",
                "Charlestown",
                "Chesterfield",
                "Chesterton",
                "Chrisney",
                "Churubusco",
                "Cicero",
                "Clarks Hill",
                "Clarksville",
                "Clay City",
                "Claypool",
                "Clayton",
                "Clear Lake",
                "Clermont",
                "Clifford",
                "Clinton",
                "Cloverdale",
                "Coatesville",
                "Colfax",
                "Collegeville",
                "Columbia City",
                "Columbus",
                "Connersville",
                "Converse",
                "Corunna",
                "Corydon",
                "Country Club Heights",
                "Covington",
                "Crandall",
                "Crane",
                "Crawfordsville",
                "Cromwell",
                "Crothersville",
                "Crown Point",
                "Crows Nest",
                "Culver",
                "Cumberland",
                "Cynthiana",
                "Dale",
                "Daleville",
                "Dana",
                "Danville",
                "Darlington",
                "Darmstadt",
                "Dayton",
                "De Motte",
                "Decatur",
                "Decker",
                "Delphi",
                "Denver",
                "Dillsboro",
                "Dublin",
                "Dugger",
                "Dune Acres",
                "Dunkirk",
                "Dunlap",
                "Dunreith",
                "Dupont",
                "Dyer",
                "Earl Park",
                "East Chicago",
                "East Germantown",
                "Eaton",
                "Economy",
                "Edgewood",
                "Edinburgh",
                "Edwardsport",
                "Elberfeld",
                "Elizabeth",
                "Elizabethtown",
                "Elkhart",
                "Ellettsville",
                "Elnora",
                "Elwood",
                "English",
                "Etna Green",
                "Evansville",
                "Fairland",
                "Fairmount",
                "Fairview Park",
                "Farmersburg",
                "Farmland",
                "Ferdinand",
                "Fillmore",
                "Fishers",
                "Flora",
                "Fort Branch",
                "Fort Wayne",
                "Fortville",
                "Fountain City",
                "Fowler",
                "Fowlerton",
                "Francesville",
                "Francisco",
                "Frankfort",
                "Franklin",
                "Frankton",
                "Fredericksburg",
                "Fremont",
                "French Lick",
                "Fulton",
                "Galena",
                "Galveston",
                "Garrett",
                "Gary",
                "Gas City",
                "Gaston",
                "Geneva",
                "Gentryville",
                "Georgetown",
                "Glenwood",
                "Goodland",
                "Goshen",
                "Gosport",
                "Grabill",
                "Grandview",
                "Granger",
                "Greencastle",
                "Greendale",
                "Greenfield",
                "Greens Fork",
                "Greensboro",
                "Greensburg",
                "Greentown",
                "Greenville",
                "Greenwood",
                "Griffin",
                "Griffith",
                "Grissom Afb",
                "Gulivoire Park",
                "Hagerstown",
                "Hamilton",
                "Hamlet",
                "Hammond",
                "Hanover",
                "Hardinsburg",
                "Harmony",
                "Hartford City",
                "Hartsville",
                "Haubstadt",
                "Hazleton",
                "Hebron",
                "Henryville",
                "Hidden Valley",
                "Highland",
                "Hillsboro",
                "Hobart",
                "Holland",
                "Holton",
                "Homecroft",
                "Hope",
                "Hudson",
                "Huntertown",
                "Huntingburg",
                "Huntington",
                "Hymera",
                "Indian Heights",
                "Indian Village",
                "Indianapolis City",
                "Ingalls",
                "Jamestown",
                "Jasonville",
                "Jasper",
                "Jeffersonville",
                "Jonesboro",
                "Jonesville",
                "Kempton",
                "Kendallville",
                "Kennard",
                "Kentland",
                "Kewanna",
                "Kingman",
                "Kingsbury",
                "Kingsford Heights",
                "Kirklin",
                "Knightstown",
                "Knightsville",
                "Knox",
                "Kokomo",
                "Koontz Lake",
                "Kouts",
                "La Crosse",
                "La Fontaine",
                "La Paz",
                "La Porte",
                "Laconia",
                "Ladoga",
                "Lafayette",
                "Lagrange",
                "Lagro",
                "Lake Dalecarlia",
                "Lake Station",
                "Lake Village",
                "Lakes Of The Four Seasons",
                "Lakeville",
                "Lanesville",
                "Lapel",
                "Larwill",
                "Laurel",
                "Lawrence",
                "Lawrenceburg",
                "Leavenworth",
                "Lebanon",
                "Leesburg",
                "Leo-cedarville",
                "Lewisville",
                "Liberty",
                "Ligonier",
                "Linden",
                "Linton",
                "Little York",
                "Livonia",
                "Lizton",
                "Logansport",
                "Long Beach",
                "Loogootee",
                "Losantville",
                "Lowell",
                "Lynn",
                "Lynnville",
                "Lyons",
                "Mackey",
                "Macy",
                "Madison",
                "Marengo",
                "Marion",
                "Markle",
                "Markleville",
                "Marshall",
                "Martinsville",
                "Matthews",
                "Mauckport",
                "Mccordsville",
                "Mecca",
                "Medaryville",
                "Medora",
                "Mellott",
                "Melody Hill",
                "Memphis",
                "Mentone",
                "Meridian Hills",
                "Merom",
                "Merrillville",
                "Mexico",
                "Michiana Shores",
                "Michigan City",
                "Michigantown",
                "Middlebury",
                "Middletown",
                "Milan",
                "Milford",
                "Millersburg",
                "Millhousen",
                "Milltown",
                "Milton",
                "Mishawaka",
                "Mitchell",
                "Modoc",
                "Monon",
                "Monroe",
                "Monroe City",
                "Monroeville",
                "Monrovia",
                "Monterey",
                "Montezuma",
                "Montgomery",
                "Monticello",
                "Montpelier",
                "Mooreland",
                "Moores Hill",
                "Mooresville",
                "Morgantown",
                "Morocco",
                "Morristown",
                "Mount Auburn",
                "Mount Ayr",
                "Mount Carmel",
                "Mount Etna",
                "Mount Summit",
                "Mount Vernon",
                "Mulberry",
                "Muncie",
                "Munster",
                "Napoleon",
                "Nappanee",
                "Nashville",
                "New Albany",
                "New Amsterdam",
                "New Carlisle",
                "New Castle",
                "New Chicago",
                "New Harmony",
                "New Haven",
                "New Market",
                "New Middletown",
                "New Palestine",
                "New Paris",
                "New Pekin",
                "New Richmond",
                "New Ross",
                "New Washington",
                "New Whiteland",
                "Newberry",
                "Newburgh",
                "Newpoint",
                "Newport",
                "Newtown",
                "Noblesville",
                "North Crows Nest",
                "North Judson",
                "North Liberty",
                "North Manchester",
                "North Salem",
                "North Terre Haute",
                "North Vernon",
                "North Webster",
                "Norway",
                "Oak Park",
                "Oakland City",
                "Oaktown",
                "Odon",
                "Ogden Dunes",
                "Oldenburg",
                "Onward",
                "Oolitic",
                "Orestes",
                "Orland",
                "Orleans",
                "Osceola",
                "Osgood",
                "Ossian",
                "Otterbein",
                "Owensville",
                "Oxford",
                "Palmyra",
                "Paoli",
                "Paragon",
                "Parker City",
                "Patoka",
                "Patriot",
                "Pendleton",
                "Pennville",
                "Perrysville",
                "Peru",
                "Petersburg",
                "Pierceton",
                "Pine Village",
                "Pittsboro",
                "Plainfield",
                "Plainville",
                "Plymouth",
                "Poneto",
                "Portage",
                "Porter",
                "Portland",
                "Poseyville",
                "Pottawattamie Park",
                "Princes Lakes",
                "Princeton",
                "Redkey",
                "Remington",
                "Rensselaer",
                "Reynolds",
                "Richmond",
                "Ridgeville",
                "Riley",
                "Rising Sun",
                "River Forest",
                "Roachdale",
                "Roann",
                "Roanoke",
                "Rochester",
                "Rockport",
                "Rockville",
                "Rocky Ripple",
                "Rome City",
                "Rosedale",
                "Roseland",
                "Roselawn",
                "Rossville",
                "Royal Center",
                "Rushville",
                "Russellville",
                "Russiaville",
                "Salamonia",
                "Salem",
                "Saltillo",
                "San Pierre",
                "Sandborn",
                "Santa Claus",
                "Saratoga",
                "Schererville",
                "Schneider",
                "Scottsburg",
                "Seelyville",
                "Sellersburg",
                "Selma",
                "Seymour",
                "Shadeland",
                "Shamrock Lakes",
                "Sharpsville",
                "Shelburn",
                "Shelbyville",
                "Sheridan",
                "Shipshewana",
                "Shirley",
                "Shoals",
                "Sidney",
                "Silver Lake",
                "Simonton Lake",
                "Somerville",
                "South Bend",
                "South Haven",
                "South Whitley",
                "Southport",
                "Speedway",
                "Spencer",
                "Spiceland",
                "Spring Grove",
                "Spring Hill",
                "Spring Lake",
                "Springport",
                "Spurgeon",
                "St. Joe",
                "St. John",
                "St. Leon",
                "St. Paul",
                "Star City",
                "State Line City",
                "Staunton",
                "Stilesville",
                "Stinesville",
                "Straughn",
                "Sullivan",
                "Sulphur Springs",
                "Summitville",
                "Sunman",
                "Swayzee",
                "Sweetser",
                "Switz City",
                "Syracuse",
                "Taylorsville",
                "Tell City",
                "Tennyson",
                "Terre Haute",
                "Thorntown",
                "Tipton",
                "Topeka",
                "Town Of Pines",
                "Trafalgar",
                "Trail Creek",
                "Tri-lakes",
                "Troy",
                "Ulen",
                "Union City",
                "Uniondale",
                "Universal",
                "Upland",
                "Utica",
                "Valparaiso",
                "Van Buren",
                "Veedersburg",
                "Vera Cruz",
                "Vernon",
                "Versailles",
                "Vevay",
                "Vincennes",
                "Wabash",
                "Wakarusa",
                "Walkerton",
                "Wallace",
                "Walton",
                "Wanatah",
                "Warren",
                "Warren Park",
                "Warsaw",
                "Washington",
                "Waterloo",
                "Waveland",
                "Waynetown",
                "West Baden Springs",
                "West College Corner",
                "West Harrison",
                "West Lafayette",
                "West Lebanon",
                "West Terre Haute",
                "Westfield",
                "Westport",
                "Westville",
                "Wheatfield",
                "Wheatland",
                "Whiteland",
                "Whitestown",
                "Whitewater",
                "Whiting",
                "Wilkinson",
                "Williams Creek",
                "Williamsport",
                "Winamac",
                "Winchester",
                "Windfall City",
                "Winfield",
                "Wingate",
                "Winona Lake",
                "Winslow",
                "Wolcott",
                "Wolcottville",
                "Woodburn",
                "Woodlawn Heights",
                "Worthington",
                "Wynnedale",
                "Yeoman",
                "Yorktown",
                "Zanesville",
                "Zionsville"
            ],
            "Iowa": [
                "Ackley",
                "Ackworth",
                "Adair",
                "Adel",
                "Afton",
                "Agency",
                "Ainsworth",
                "Akron",
                "Albert City",
                "Albia",
                "Albion",
                "Alburnett",
                "Alden",
                "Alexander",
                "Algona",
                "Alleman",
                "Allerton",
                "Allison",
                "Alta",
                "Alta Vista",
                "Alton",
                "Altoona",
                "Alvord",
                "Ames",
                "Anamosa",
                "Andover",
                "Andrew",
                "Anita",
                "Ankeny",
                "Anthon",
                "Aplington",
                "Arcadia",
                "Archer",
                "Aredale",
                "Arion",
                "Arispe",
                "Arlington",
                "Armstrong",
                "Arnolds Park",
                "Arthur",
                "Asbury",
                "Ashton",
                "Aspinwall",
                "Atalissa",
                "Athelstan",
                "Atkins",
                "Atlantic",
                "Auburn",
                "Audubon",
                "Aurelia",
                "Aurora",
                "Avoca",
                "Ayrshire",
                "Badger",
                "Bagley",
                "Baldwin",
                "Balltown",
                "Bancroft",
                "Bankston",
                "Barnes City",
                "Barnum",
                "Bassett",
                "Batavia",
                "Battle Creek",
                "Baxter",
                "Bayard",
                "Beacon",
                "Beaconsfield",
                "Beaman",
                "Beaver",
                "Bedford",
                "Belle Plaine",
                "Bellevue",
                "Belmond",
                "Bennett",
                "Benton",
                "Berkley",
                "Bernard",
                "Bertram",
                "Bettendorf",
                "Bevington",
                "Birmingham",
                "Blairsburg",
                "Blairstown",
                "Blakesburg",
                "Blanchard",
                "Blencoe",
                "Blockton",
                "Bloomfield",
                "Blue Grass",
                "Bode",
                "Bonaparte",
                "Bondurant",
                "Boone",
                "Bouton",
                "Boxholm",
                "Boyden",
                "Braddyville",
                "Bradgate",
                "Brandon",
                "Brayton",
                "Breda",
                "Bridgewater",
                "Brighton",
                "Bristow",
                "Britt",
                "Bronson",
                "Brooklyn",
                "Brunsville",
                "Buck Grove",
                "Buckeye",
                "Buffalo",
                "Buffalo Center",
                "Burlington",
                "Burt",
                "Bussey",
                "Calamus",
                "Callender",
                "Calmar",
                "Calumet",
                "Camanche",
                "Cambridge",
                "Cantril",
                "Carbon",
                "Carlisle",
                "Carpenter",
                "Carroll",
                "Carson",
                "Carter Lake",
                "Cascade",
                "Casey",
                "Castalia",
                "Castana",
                "Cedar Falls",
                "Cedar Rapids",
                "Center Junction",
                "Center Point",
                "Centerville",
                "Central City",
                "Centralia",
                "Chariton",
                "Charles City",
                "Charlotte",
                "Charter Oak",
                "Chatsworth",
                "Chelsea",
                "Cherokee",
                "Chester",
                "Chillicothe",
                "Churdan",
                "Cincinnati",
                "Clare",
                "Clarence",
                "Clarinda",
                "Clarion",
                "Clarksville",
                "Clayton",
                "Clear Lake",
                "Clearfield",
                "Cleghorn",
                "Clemons",
                "Clermont",
                "Clinton",
                "Clio",
                "Clive",
                "Clutier",
                "Coalville",
                "Coburg",
                "Coggon",
                "Coin",
                "Colesburg",
                "Colfax",
                "College Springs",
                "Collins",
                "Colo",
                "Columbus City",
                "Columbus Junction",
                "Colwell",
                "Conesville",
                "Conrad",
                "Conway",
                "Coon Rapids",
                "Coppock",
                "Coralville",
                "Corning",
                "Correctionville",
                "Corwith",
                "Corydon",
                "Cotter",
                "Coulter",
                "Council Bluffs",
                "Craig",
                "Crawfordsville",
                "Crescent",
                "Cresco",
                "Creston",
                "Cromwell",
                "Crystal Lake",
                "Cumberland",
                "Cumming",
                "Curlew",
                "Cushing",
                "Cylinder",
                "Dakota City",
                "Dallas Center",
                "Dana",
                "Danbury",
                "Danville",
                "Davenport",
                "Davis City",
                "Dawson",
                "Dayton",
                "De Soto",
                "De Witt",
                "Decatur City",
                "Decorah",
                "Dedham",
                "Deep River",
                "Defiance",
                "Delaware",
                "Delhi",
                "Delmar",
                "Deloit",
                "Delphos",
                "Delta",
                "Denison",
                "Denver",
                "Derby",
                "Des Moines",
                "Dexter",
                "Diagonal",
                "Dickens",
                "Dike",
                "Dixon",
                "Dolliver",
                "Donahue",
                "Donnellson",
                "Doon",
                "Douds",
                "Dougherty",
                "Dow City",
                "Dows",
                "Drakesville",
                "Dubuque",
                "Dumont",
                "Duncombe",
                "Dundee",
                "Dunkerton",
                "Dunlap",
                "Durango",
                "Durant",
                "Dyersville",
                "Dysart",
                "Eagle Grove",
                "Earlham",
                "Earling",
                "Earlville",
                "Early",
                "East Peru",
                "Eddyville",
                "Edgewood",
                "Elberon",
                "Eldon",
                "Eldora",
                "Eldridge",
                "Elgin",
                "Elk Horn",
                "Elk Run Heights",
                "Elkader",
                "Elkhart",
                "Elkport",
                "Elliott",
                "Ellston",
                "Ellsworth",
                "Elma",
                "Ely",
                "Emerson",
                "Emmetsburg",
                "Epworth",
                "Essex",
                "Estherville",
                "Evansdale",
                "Everly",
                "Exira",
                "Exline",
                "Fairbank",
                "Fairfax",
                "Fairfield",
                "Farley",
                "Farmersburg",
                "Farmington",
                "Farnhamville",
                "Farragut",
                "Fayette",
                "Fenton",
                "Ferguson",
                "Fertile",
                "Floris",
                "Floyd",
                "Fonda",
                "Fontanelle",
                "Forest City",
                "Fort Atkinson",
                "Fort Dodge",
                "Fort Madison",
                "Fostoria",
                "Franklin",
                "Fraser",
                "Fredericksburg",
                "Frederika",
                "Fredonia",
                "Fremont",
                "Fruitland",
                "Galt",
                "Galva",
                "Garber",
                "Garden Grove",
                "Garnavillo",
                "Garner",
                "Garrison",
                "Garwin",
                "Geneva",
                "George",
                "Gibson",
                "Gilbert",
                "Gilbertville",
                "Gillett Grove",
                "Gilman",
                "Gilmore City",
                "Gladbrook",
                "Glenwood",
                "Glidden",
                "Goldfield",
                "Goodell",
                "Goose Lake",
                "Gowrie",
                "Graettinger",
                "Graf",
                "Grafton",
                "Grand Junction",
                "Grand Mound",
                "Grand River",
                "Grandview",
                "Granger",
                "Grant",
                "Granville",
                "Gravity",
                "Gray",
                "Greeley",
                "Greene",
                "Greenfield",
                "Greenville",
                "Grimes",
                "Grinnell",
                "Griswold",
                "Grundy Center",
                "Gruver",
                "Guernsey",
                "Guthrie Center",
                "Guttenberg",
                "Halbur",
                "Hamburg",
                "Hamilton",
                "Hampton",
                "Hancock",
                "Hanlontown",
                "Hansell",
                "Harcourt",
                "Hardy",
                "Harlan",
                "Harper",
                "Harpers Ferry",
                "Harris",
                "Hartford",
                "Hartley",
                "Hartwick",
                "Harvey",
                "Hastings",
                "Havelock",
                "Haverhill",
                "Hawarden",
                "Hawkeye",
                "Hayesville",
                "Hazleton",
                "Hedrick",
                "Henderson",
                "Hepburn",
                "Hiawatha",
                "Hills",
                "Hillsboro",
                "Hinton",
                "Holland",
                "Holstein",
                "Holy Cross",
                "Hopkinton",
                "Hornick",
                "Hospers",
                "Houghton",
                "Hubbard",
                "Hudson",
                "Hull",
                "Humboldt",
                "Humeston",
                "Huxley",
                "Ida Grove",
                "Imogene",
                "Independence",
                "Indianola",
                "Inwood",
                "Ionia",
                "Iowa City",
                "Iowa Falls",
                "Ireton",
                "Irwin",
                "Jackson Junction",
                "Jamaica",
                "Janesville",
                "Jefferson",
                "Jesup",
                "Jewell Junction",
                "Johnston",
                "Joice",
                "Jolley",
                "Kalona",
                "Kamrar",
                "Kanawha",
                "Kellerton",
                "Kelley",
                "Kellogg",
                "Kensett",
                "Kent",
                "Keokuk",
                "Keomah Village",
                "Keosauqua",
                "Keota",
                "Keswick",
                "Keystone",
                "Kimballton",
                "Kingsley",
                "Kinross",
                "Kirkman",
                "Kirkville",
                "Kiron",
                "Klemme",
                "Knierim",
                "Knoxville",
                "La Motte",
                "La Porte City",
                "Lacona",
                "Ladora",
                "Lake City",
                "Lake Mills",
                "Lake Park",
                "Lake View",
                "Lakeside",
                "Lakota",
                "Lambs Grove",
                "Lamoni",
                "Lamont",
                "Lanesboro",
                "Lansing",
                "Larchwood",
                "Larrabee",
                "Latimer",
                "Laurel",
                "Laurens",
                "Lawler",
                "Lawton",
                "Le Claire",
                "Le Grand",
                "Le Mars",
                "Le Roy",
                "Leando",
                "Ledyard",
                "Lehigh",
                "Leighton",
                "Leland",
                "Lenox",
                "Leon",
                "Lester",
                "Letts",
                "Lewis",
                "Libertyville",
                "Lidderdale",
                "Lime Springs",
                "Lincoln",
                "Linden",
                "Lineville",
                "Linn Grove",
                "Lisbon",
                "Liscomb",
                "Little Rock",
                "Little Sioux",
                "Littleport",
                "Livermore",
                "Lockridge",
                "Logan",
                "Lohrville",
                "Lone Rock",
                "Lone Tree",
                "Long Grove",
                "Lorimor",
                "Lost Nation",
                "Lovilia",
                "Low Moor",
                "Lowden",
                "Lu Verne",
                "Luana",
                "Lucas",
                "Luther",
                "Luxemburg",
                "Luzerne",
                "Lynnville",
                "Lytton",
                "Macedonia",
                "Macksburg",
                "Madrid",
                "Magnolia",
                "Malcom",
                "Mallard",
                "Maloy",
                "Malvern",
                "Manchester",
                "Manilla",
                "Manly",
                "Manning",
                "Manson",
                "Mapleton",
                "Maquoketa",
                "Marathon",
                "Marble Rock",
                "Marcus",
                "Marengo",
                "Marion",
                "Marne",
                "Marquette",
                "Marshalltown",
                "Martelle",
                "Martensdale",
                "Martinsburg",
                "Marysville",
                "Mason City",
                "Masonville",
                "Massena",
                "Matlock",
                "Maurice",
                "Maxwell",
                "Maynard",
                "Maysville",
                "Mccallsburg",
                "Mccausland",
                "Mcclelland",
                "Mcgregor",
                "Mcintire",
                "Mechanicsville",
                "Mediapolis",
                "Melbourne",
                "Melcher-dallas",
                "Melrose",
                "Melvin",
                "Menlo",
                "Meriden",
                "Merrill",
                "Meservey",
                "Middletown",
                "Miles",
                "Milford",
                "Millersburg",
                "Millerton",
                "Millville",
                "Milo",
                "Milton",
                "Minburn",
                "Minden",
                "Mingo",
                "Missouri Valley",
                "Mitchell",
                "Mitchellville",
                "Modale",
                "Mondamin",
                "Monmouth",
                "Monona",
                "Monroe",
                "Montezuma",
                "Monticello",
                "Montour",
                "Montrose",
                "Moorhead",
                "Moorland",
                "Moravia",
                "Morley",
                "Morning Sun",
                "Morrison",
                "Moulton",
                "Mount Auburn",
                "Mount Ayr",
                "Mount Pleasant",
                "Mount Sterling",
                "Mount Union",
                "Mount Vernon",
                "Moville",
                "Murray",
                "Muscatine",
                "Mystic",
                "Nashua",
                "Nemaha",
                "Neola",
                "Nevada",
                "New Albin",
                "New Hampton",
                "New Hartford",
                "New Liberty",
                "New London",
                "New Market",
                "New Providence",
                "New Sharon",
                "New Vienna",
                "New Virginia",
                "Newell",
                "Newhall",
                "Newton",
                "Nichols",
                "Nodaway",
                "Nora Springs",
                "North Buena Vista",
                "North English",
                "North Liberty",
                "North Washington",
                "Northboro",
                "Northwood",
                "Norwalk",
                "Norway",
                "Numa",
                "Oakland",
                "Oakland Acres",
                "Oakville",
                "Ocheyedan",
                "Odebolt",
                "Oelwein",
                "Ogden",
                "Okoboji",
                "Olds",
                "Olin",
                "Ollie",
                "Onawa",
                "Onslow",
                "Orange City",
                "Orchard",
                "Orient",
                "Orleans",
                "Osage",
                "Osceola",
                "Oskaloosa",
                "Ossian",
                "Osterdock",
                "Otho",
                "Oto",
                "Ottosen",
                "Ottumwa",
                "Owasa",
                "Oxford",
                "Oxford Junction",
                "Oyens",
                "Pacific Junction",
                "Packwood",
                "Palmer",
                "Palo",
                "Panama",
                "Panora",
                "Panorama Park",
                "Park View",
                "Parkersburg",
                "Parnell",
                "Paton",
                "Patterson",
                "Paullina",
                "Pella",
                "Peosta",
                "Perry",
                "Persia",
                "Peterson",
                "Pierson",
                "Pilot Mound",
                "Pioneer",
                "Pisgah",
                "Plainfield",
                "Plano",
                "Pleasant Hill",
                "Pleasant Plain",
                "Pleasanton",
                "Pleasantville",
                "Plover",
                "Plymouth",
                "Pocahontas",
                "Polk City",
                "Pomeroy",
                "Popejoy",
                "Portsmouth",
                "Postville",
                "Prairie City",
                "Prairieburg",
                "Prescott",
                "Preston",
                "Primghar",
                "Princeton",
                "Promise City",
                "Protivin",
                "Pulaski",
                "Quasqueton",
                "Quimby",
                "Radcliffe",
                "Rake",
                "Ralston",
                "Randalia",
                "Randall",
                "Randolph",
                "Rathbun",
                "Raymond",
                "Readlyn",
                "Reasnor",
                "Red Oak",
                "Redding",
                "Redfield",
                "Reinbeck",
                "Rembrandt",
                "Remsen",
                "Renwick",
                "Rhodes",
                "Riceville",
                "Richland",
                "Rickardsville",
                "Ricketts",
                "Ridgeway",
                "Rinard",
                "Ringsted",
                "Rippey",
                "Riverdale",
                "Riverside",
                "Riverton",
                "Robins",
                "Rock Falls",
                "Rock Rapids",
                "Rock Valley",
                "Rockford",
                "Rockwell",
                "Rockwell City",
                "Rodman",
                "Rodney",
                "Roland",
                "Rolfe",
                "Rome",
                "Rose Hill",
                "Rossie",
                "Rowan",
                "Rowley",
                "Royal",
                "Rudd",
                "Runnells",
                "Russell",
                "Ruthven",
                "Rutland",
                "Ryan",
                "Sabula",
                "Sac City",
                "Sageville",
                "Salem",
                "Salix",
                "Sanborn",
                "Sandyville",
                "Saylorville",
                "Scarville",
                "Schaller",
                "Schleswig",
                "Scranton",
                "Searsboro",
                "Sergeant Bluff",
                "Seymour",
                "Shambaugh",
                "Shannon City",
                "Sharpsburg",
                "Sheffield",
                "Shelby",
                "Sheldahl",
                "Sheldon",
                "Shell Rock",
                "Shellsburg",
                "Shenandoah",
                "Sherrill",
                "Shueyville",
                "Sibley",
                "Sidney",
                "Sigourney",
                "Silver City",
                "Sioux Center",
                "Sioux City",
                "Sioux Rapids",
                "Slater",
                "Sloan",
                "Smithland",
                "Soldier",
                "Solon",
                "Somers",
                "South English",
                "Spencer",
                "Spillville",
                "Spirit Lake",
                "Spragueville",
                "Spring Hill",
                "Springbrook",
                "Springville",
                "St. Ansgar",
                "St. Anthony",
                "St. Charles",
                "St. Donatus",
                "St. Lucas",
                "St. Marys",
                "St. Olaf",
                "St. Paul",
                "Stacyville",
                "Stanhope",
                "Stanley",
                "Stanton",
                "Stanwood",
                "State Center",
                "Steamboat Rock",
                "Stockport",
                "Stockton",
                "Storm Lake",
                "Story City",
                "Stout",
                "Stratford",
                "Strawberry Point",
                "Struble",
                "Stuart",
                "Sully",
                "Sumner",
                "Superior",
                "Sutherland",
                "Swaledale",
                "Swan",
                "Swea City",
                "Swisher",
                "Tabor",
                "Tama",
                "Templeton",
                "Tennant",
                "Terril",
                "Thayer",
                "Thompson",
                "Thor",
                "Thornburg",
                "Thornton",
                "Thurman",
                "Tiffin",
                "Tingley",
                "Tipton",
                "Titonka",
                "Toledo",
                "Toronto",
                "Traer",
                "Treynor",
                "Tripoli",
                "Truesdale",
                "Truro",
                "Turin",
                "Udell",
                "Underwood",
                "Union",
                "Unionville",
                "University Heights",
                "University Park",
                "Urbana",
                "Urbandale",
                "Ute",
                "Vail",
                "Valeria",
                "Van Horne",
                "Van Meter",
                "Van Wert",
                "Varina",
                "Ventura",
                "Victor",
                "Villisca",
                "Vincent",
                "Vining",
                "Vinton",
                "Volga",
                "Wadena",
                "Wahpeton",
                "Walcott",
                "Walford",
                "Walker",
                "Wall Lake",
                "Wallingford",
                "Walnut",
                "Wapello",
                "Washington",
                "Washta",
                "Waterloo",
                "Waterville",
                "Waucoma",
                "Waukee",
                "Waukon",
                "Waverly",
                "Wayland",
                "Webb",
                "Webster",
                "Webster City",
                "Weldon",
                "Wellman",
                "Wellsburg",
                "Welton",
                "Wesley",
                "West Bend",
                "West Branch",
                "West Burlington",
                "West Chester",
                "West Des Moines",
                "West Liberty",
                "West Okoboji",
                "West Point",
                "West Union",
                "Westfield",
                "Westgate",
                "Westphalia",
                "Westside",
                "Westwood",
                "What Cheer",
                "Wheatland",
                "Whiting",
                "Whittemore",
                "Whitten",
                "Willey",
                "Williams",
                "Williamsburg",
                "Williamson",
                "Wilton",
                "Windsor Heights",
                "Winfield",
                "Winterset",
                "Winthrop",
                "Wiota",
                "Woden",
                "Woodbine",
                "Woodburn",
                "Woodward",
                "Woolstock",
                "Worthington",
                "Wyoming",
                "Yale",
                "Yetter",
                "Yorktown",
                "Zearing",
                "Zwingle"
            ],
            "Kansas": [
                "Abbyville",
                "Abilene",
                "Admire",
                "Agenda",
                "Agra",
                "Albert",
                "Alden",
                "Alexander",
                "Allen",
                "Alma",
                "Almena",
                "Alta Vista",
                "Altamont",
                "Alton",
                "Altoona",
                "Americus",
                "Andale",
                "Andover",
                "Anthony",
                "Arcadia",
                "Argonia",
                "Arkansas City",
                "Arlington",
                "Arma",
                "Ashland",
                "Assaria",
                "Atchison",
                "Athol",
                "Atlanta",
                "Attica",
                "Atwood",
                "Auburn",
                "Augusta",
                "Aurora",
                "Axtell",
                "Baldwin City",
                "Barnard",
                "Barnes",
                "Bartlett",
                "Basehor",
                "Bassett",
                "Baxter Springs",
                "Bazine",
                "Beattie",
                "Bel Aire",
                "Belle Plaine",
                "Belleville",
                "Beloit",
                "Belpre",
                "Belvue",
                "Benedict",
                "Bennington",
                "Bentley",
                "Benton",
                "Bern",
                "Beverly",
                "Bird City",
                "Bison",
                "Blue Mound",
                "Blue Rapids",
                "Bluff City",
                "Bogue",
                "Bonner Springs",
                "Brewster",
                "Bronson",
                "Brookville",
                "Brownell",
                "Bucklin",
                "Buffalo",
                "Buhler",
                "Bunker Hill",
                "Burden",
                "Burdett",
                "Burlingame",
                "Burlington",
                "Burns",
                "Burr Oak",
                "Burrton",
                "Bushong",
                "Bushton",
                "Byers",
                "Caldwell",
                "Cambridge",
                "Caney",
                "Canton",
                "Carbondale",
                "Carlton",
                "Cassoday",
                "Cawker City",
                "Cedar",
                "Cedar Point",
                "Cedar Vale",
                "Centralia",
                "Chanute",
                "Chapman",
                "Chase",
                "Chautauqua",
                "Cheney",
                "Cherokee",
                "Cherryvale",
                "Chetopa",
                "Cimarron",
                "Circleville",
                "Claflin",
                "Clay Center",
                "Clayton",
                "Clearwater",
                "Clifton",
                "Climax",
                "Clyde",
                "Coats",
                "Coffeyville",
                "Colby",
                "Coldwater",
                "Collyer",
                "Colony",
                "Columbus",
                "Colwich",
                "Concordia",
                "Conway Springs",
                "Coolidge",
                "Copeland",
                "Corning",
                "Cottonwood Falls",
                "Council Grove",
                "Countryside",
                "Courtland",
                "Coyville",
                "Cuba",
                "Cullison",
                "Culver",
                "Cunningham",
                "Damar",
                "Danville",
                "De Soto",
                "Dearing",
                "Deerfield",
                "Delia",
                "Delphos",
                "Denison",
                "Denton",
                "Derby",
                "Dexter",
                "Dighton",
                "Dodge City",
                "Dorrance",
                "Douglass",
                "Downs",
                "Dresden",
                "Dunlap",
                "Durham",
                "Dwight",
                "Earlton",
                "Eastborough",
                "Easton",
                "Edgerton",
                "Edmond",
                "Edna",
                "Edwardsville",
                "Effingham",
                "El Dorado",
                "Elbing",
                "Elgin",
                "Elk City",
                "Elk Falls",
                "Elkhart",
                "Ellinwood",
                "Ellis",
                "Ellsworth",
                "Elmdale",
                "Elsmore",
                "Elwood",
                "Emmett",
                "Emporia",
                "Englewood",
                "Ensign",
                "Enterprise",
                "Erie",
                "Esbon",
                "Eskridge",
                "Eudora",
                "Eureka",
                "Everest",
                "Fairview",
                "Fairway",
                "Fall River",
                "Florence",
                "Fontana",
                "Ford",
                "Formoso",
                "Fort Riley North",
                "Fort Riley-camp Whiteside",
                "Fort Scott",
                "Fowler",
                "Frankfort",
                "Frederick",
                "Fredonia",
                "Freeport",
                "Frontenac",
                "Fulton",
                "Galatia",
                "Galena",
                "Galesburg",
                "Galva",
                "Garden City",
                "Garden Plain",
                "Gardner",
                "Garfield",
                "Garnett",
                "Gas",
                "Gaylord",
                "Gem",
                "Geneseo",
                "Geuda Springs",
                "Girard",
                "Glade",
                "Glasco",
                "Glen Elder",
                "Goddard",
                "Goessel",
                "Goff",
                "Goodland",
                "Gorham",
                "Gove City",
                "Grainfield",
                "Grandview Plaza",
                "Great Bend",
                "Greeley",
                "Green",
                "Greenleaf",
                "Greensburg",
                "Grenola",
                "Gridley",
                "Grinnell",
                "Gypsum",
                "Haddam",
                "Halstead",
                "Hamilton",
                "Hamlin",
                "Hanover",
                "Hanston",
                "Hardtner",
                "Harper",
                "Harris",
                "Hartford",
                "Harveyville",
                "Havana",
                "Haven",
                "Havensville",
                "Haviland",
                "Hays",
                "Haysville",
                "Hazelton",
                "Hepler",
                "Herington",
                "Herndon",
                "Hesston",
                "Hiawatha",
                "Highland",
                "Hill City",
                "Hillsboro",
                "Hoisington",
                "Holcomb",
                "Hollenberg",
                "Holton",
                "Holyrood",
                "Hope",
                "Horace",
                "Horton",
                "Howard",
                "Hoxie",
                "Hoyt",
                "Hudson",
                "Hugoton",
                "Humboldt",
                "Hunnewell",
                "Hunter",
                "Huron",
                "Hutchinson",
                "Independence",
                "Ingalls",
                "Inman",
                "Iola",
                "Isabel",
                "Iuka",
                "Jamestown",
                "Jennings",
                "Jetmore",
                "Jewell",
                "Johnson City",
                "Junction City",
                "Kanopolis",
                "Kanorado",
                "Kansas City",
                "Kechi",
                "Kensington",
                "Kincaid",
                "Kingman",
                "Kinsley",
                "Kiowa",
                "Kirwin",
                "Kismet",
                "La Crosse",
                "La Cygne",
                "La Harpe",
                "Labette",
                "Lake Quivira",
                "Lakin",
                "Lancaster",
                "Lane",
                "Langdon",
                "Lansing",
                "Larned",
                "Latham",
                "Latimer",
                "Lawrence",
                "Le Roy",
                "Leavenworth",
                "Leawood",
                "Lebanon",
                "Lebo",
                "Lecompton",
                "Lehigh",
                "Lenexa",
                "Lenora",
                "Leon",
                "Leona",
                "Leonardville",
                "Leoti",
                "Lewis",
                "Liberal",
                "Liberty",
                "Liebenthal",
                "Lincoln Center",
                "Lincolnville",
                "Lindsborg",
                "Linn",
                "Linn Valley",
                "Linwood",
                "Little River",
                "Logan",
                "Lone Elm",
                "Long Island",
                "Longford",
                "Longton",
                "Lorraine",
                "Lost Springs",
                "Louisburg",
                "Louisville",
                "Lucas",
                "Luray",
                "Lyndon",
                "Lyons",
                "Macksville",
                "Madison",
                "Mahaska",
                "Maize",
                "Manchester",
                "Manhattan",
                "Mankato",
                "Manter",
                "Maple Hill",
                "Mapleton",
                "Marion",
                "Marquette",
                "Marysville",
                "Matfield Green",
                "Mayetta",
                "Mayfield",
                "Mccracken",
                "Mccune",
                "Mcdonald",
                "Mcfarland",
                "Mclouth",
                "Mcpherson",
                "Meade",
                "Medicine Lodge",
                "Melvern",
                "Menlo",
                "Meriden",
                "Merriam",
                "Milan",
                "Mildred",
                "Milford",
                "Miltonvale",
                "Minneapolis",
                "Minneola",
                "Mission",
                "Mission Hills",
                "Mission Woods",
                "Moline",
                "Montezuma",
                "Moran",
                "Morganville",
                "Morland",
                "Morrill",
                "Morrowville",
                "Moscow",
                "Mound City",
                "Mound Valley",
                "Moundridge",
                "Mount Hope",
                "Mulberry",
                "Mullinville",
                "Mulvane",
                "Munden",
                "Muscotah",
                "Narka",
                "Nashville",
                "Natoma",
                "Neodesha",
                "Neosho Falls",
                "Neosho Rapids",
                "Ness City",
                "Netawaka",
                "New Albany",
                "New Cambria",
                "New Strawn",
                "Newton",
                "Nickerson",
                "Niotaze",
                "Norcatur",
                "North Newton",
                "Norton",
                "Nortonville",
                "Norwich",
                "Oak Hill",
                "Oaklawn-sunview",
                "Oakley",
                "Oberlin",
                "Offerle",
                "Ogden",
                "Oketo",
                "Olathe",
                "Olivet",
                "Olmitz",
                "Olpe",
                "Olsburg",
                "Onaga",
                "Oneida",
                "Osage City",
                "Osawatomie",
                "Osborne",
                "Oskaloosa",
                "Oswego",
                "Otis",
                "Ottawa",
                "Overbrook",
                "Overland Park",
                "Oxford",
                "Ozawkie",
                "Palco",
                "Palmer",
                "Paola",
                "Paradise",
                "Park",
                "Park City",
                "Parker",
                "Parkerville",
                "Parsons",
                "Partridge",
                "Pawnee Rock",
                "Paxico",
                "Peabody",
                "Penalosa",
                "Perry",
                "Peru",
                "Phillipsburg",
                "Pittsburg",
                "Plains",
                "Plainville",
                "Pleasanton",
                "Plevna",
                "Pomona",
                "Portis",
                "Potwin",
                "Powhattan",
                "Prairie View",
                "Prairie Village",
                "Pratt",
                "Prescott",
                "Preston",
                "Pretty Prairie",
                "Princeton",
                "Protection",
                "Quenemo",
                "Quinter",
                "Radium",
                "Ramona",
                "Randall",
                "Randolph",
                "Ransom",
                "Rantoul",
                "Raymond",
                "Reading",
                "Redfield",
                "Republic",
                "Reserve",
                "Rexford",
                "Richfield",
                "Richmond",
                "Riley",
                "Robinson",
                "Roeland Park",
                "Rolla",
                "Rose Hill",
                "Roseland",
                "Rossville",
                "Rozel",
                "Rush Center",
                "Russell",
                "Russell Springs",
                "Sabetha",
                "Salina",
                "Satanta",
                "Savonburg",
                "Sawyer",
                "Scammon",
                "Scandia",
                "Schoenchen",
                "Scott City",
                "Scottsville",
                "Scranton",
                "Sedan",
                "Sedgwick",
                "Selden",
                "Seneca",
                "Severance",
                "Severy",
                "Seward",
                "Sharon",
                "Sharon Springs",
                "Shawnee",
                "Silver Lake",
                "Simpson",
                "Smith Center",
                "Smolan",
                "Soldier",
                "Solomon",
                "South Haven",
                "South Hutchinson",
                "Spearville",
                "Speed",
                "Spivey",
                "Spring Hill",
                "St. Francis",
                "St. George",
                "St. John",
                "St. Marys",
                "St. Paul",
                "Stafford",
                "Stark",
                "Sterling",
                "Stockton",
                "Strong City",
                "Sublette",
                "Summerfield",
                "Sun City",
                "Susank",
                "Sylvan Grove",
                "Sylvia",
                "Syracuse",
                "Tampa",
                "Tescott",
                "Thayer",
                "Timken",
                "Tipton",
                "Tonganoxie",
                "Topeka",
                "Toronto",
                "Towanda",
                "Treece",
                "Tribune",
                "Troy",
                "Turon",
                "Tyro",
                "Udall",
                "Ulysses",
                "Uniontown",
                "Utica",
                "Valley Center",
                "Valley Falls",
                "Vermillion",
                "Victoria",
                "Vining",
                "Viola",
                "Virgil",
                "Wakeeney",
                "Wakefield",
                "Waldo",
                "Waldron",
                "Wallace",
                "Walnut",
                "Walton",
                "Wamego",
                "Washington",
                "Waterville",
                "Wathena",
                "Waverly",
                "Webber",
                "Weir",
                "Wellington",
                "Wellsville",
                "West Mineral",
                "Westmoreland",
                "Westphalia",
                "Westwood",
                "Westwood Hills",
                "Wetmore",
                "Wheaton",
                "White City",
                "White Cloud",
                "Whitewater",
                "Whiting",
                "Wichita",
                "Willard",
                "Williamsburg",
                "Willis",
                "Willowbrook",
                "Wilmore",
                "Wilsey",
                "Wilson",
                "Winchester",
                "Windom",
                "Winfield",
                "Winona",
                "Woodbine",
                "Woodston",
                "Yates Center",
                "Zenda",
                "Zurich"
            ],
            "Kentucky": [
                "Adairville",
                "Albany",
                "Alexandria",
                "Allen",
                "Allensville",
                "Anchorage",
                "Annville",
                "Arlington",
                "Ashland",
                "Auburn",
                "Audubon Park",
                "Augusta",
                "Bancroft",
                "Barbourmeade",
                "Barbourville",
                "Bardstown",
                "Bardwell",
                "Barlow",
                "Beattyville",
                "Beaver Dam",
                "Bedford",
                "Beechwood Village",
                "Bellefonte",
                "Bellemeade",
                "Bellevue",
                "Bellewood",
                "Benham",
                "Benton",
                "Berea",
                "Berry",
                "Blackey",
                "Blaine",
                "Blandville",
                "Bloomfield",
                "Blue Ridge Manor",
                "Bonnieville",
                "Booneville",
                "Bowling Green",
                "Bradfordsville",
                "Brandenburg",
                "Breckinridge Center",
                "Bremen",
                "Briarwood",
                "Broad Fields",
                "Brodhead",
                "Broeck Pointe",
                "Bromley",
                "Brooks",
                "Brooksville",
                "Brownsboro Farm",
                "Brownsboro Village",
                "Brownsville",
                "Buckhorn",
                "Buckner",
                "Buechel",
                "Burgin",
                "Burkesville",
                "Burlington",
                "Burnside",
                "Butler",
                "Cadiz",
                "Calhoun",
                "California",
                "Calvert City",
                "Camargo",
                "Cambridge",
                "Campbellsburg",
                "Campbellsville",
                "Campton",
                "Caneyville",
                "Carlisle",
                "Carrollton",
                "Carrsville",
                "Catlettsburg",
                "Cave City",
                "Cedarville",
                "Centertown",
                "Central City",
                "Cherrywood Village",
                "Clarkson",
                "Claryville",
                "Clay",
                "Clay City",
                "Clinton",
                "Cloverport",
                "Coal Run Village",
                "Cold Spring",
                "Coldstream",
                "Columbia",
                "Columbus",
                "Concord",
                "Corbin",
                "Corinth",
                "Corydon",
                "Covington",
                "Crab Orchard",
                "Creekside",
                "Crescent Springs",
                "Crestview",
                "Crestview Hills",
                "Crestwood",
                "Crittenden",
                "Crofton",
                "Crossgate",
                "Cumberland",
                "Cynthiana",
                "Danville",
                "Dawson Springs",
                "Dayton",
                "Dixon",
                "Douglass Hills",
                "Dover",
                "Drakesboro",
                "Druid Hills",
                "Dry Ridge",
                "Dycusburg",
                "Earlington",
                "East Bernstadt",
                "Eddyville",
                "Edgewood",
                "Edmonton",
                "Ekron",
                "Elizabethtown",
                "Elkhorn City",
                "Elkton",
                "Elsmere",
                "Eminence",
                "Erlanger",
                "Eubank",
                "Evarts",
                "Ewing",
                "Fairdale",
                "Fairfield",
                "Fairmeade",
                "Fairview",
                "Falmouth",
                "Ferguson",
                "Fern Creek",
                "Fincastle",
                "Flatwoods",
                "Fleming-neon",
                "Flemingsburg",
                "Florence",
                "Fordsville",
                "Forest Hills",
                "Fort Campbell North",
                "Fort Knox",
                "Fort Mitchell",
                "Fort Thomas",
                "Fort Wright",
                "Fountain Run",
                "Fox Chase",
                "Frankfort",
                "Franklin",
                "Fredonia",
                "Frenchburg",
                "Fulton",
                "Gamaliel",
                "Georgetown",
                "Germantown",
                "Ghent",
                "Glasgow",
                "Glencoe",
                "Glenview",
                "Glenview Hills",
                "Glenview Manor",
                "Goose Creek",
                "Goshen",
                "Grand Rivers",
                "Gratz",
                "Graymoor-devondale",
                "Grayson",
                "Green Spring",
                "Greensburg",
                "Greenup",
                "Greenville",
                "Guthrie",
                "Hanson",
                "Hardin",
                "Hardinsburg",
                "Harlan",
                "Harrodsburg",
                "Hartford",
                "Hawesville",
                "Hazard",
                "Hazel",
                "Hebron Estates",
                "Henderson",
                "Hendron",
                "Hickman",
                "Hickory Hill",
                "Highland Heights",
                "Highview",
                "Hills And Dales",
                "Hillview",
                "Hindman",
                "Hiseville",
                "Hodgenville",
                "Hollow Creek",
                "Hollyvilla",
                "Hopkinsville",
                "Horse Cave",
                "Houston Acres",
                "Hunters Hollow",
                "Hurstbourne",
                "Hurstbourne Acres",
                "Hustonville",
                "Hyden",
                "Independence",
                "Indian Hills",
                "Inez",
                "Irvine",
                "Irvington",
                "Island",
                "Jackson",
                "Jamestown",
                "Jeffersontown",
                "Jeffersonville",
                "Jenkins",
                "Junction City",
                "Keeneland",
                "Kenton Vale",
                "Kevil",
                "Kingsley",
                "Kuttawa",
                "La Center",
                "La Grange",
                "Lafayette",
                "Lakeside Park",
                "Lakeview Heights",
                "Lancaster",
                "Langdon Place",
                "Latonia Lakes",
                "Lawrenceburg",
                "Lebanon",
                "Lebanon Junction",
                "Ledbetter",
                "Leitchfield",
                "Lewisburg",
                "Lewisport",
                "Liberty",
                "Lincolnshire",
                "Livermore",
                "Livingston",
                "London",
                "Lone Oak",
                "Loretto",
                "Louisa",
                "Louisville",
                "Loyall",
                "Ludlow",
                "Lynch",
                "Lyndon",
                "Lynnview",
                "Mackville",
                "Madisonville",
                "Manchester",
                "Manor Creek",
                "Marion",
                "Martin",
                "Maryhill Estates",
                "Masonville",
                "Massac",
                "Mayfield",
                "Maysville",
                "Mchenry",
                "Mckee",
                "Mcroberts",
                "Meadow Vale",
                "Meadowbrook Farm",
                "Meadowview Estates",
                "Melbourne",
                "Mentor",
                "Middlesborough",
                "Middletown",
                "Midway",
                "Millersburg",
                "Milton",
                "Minor Lane Heights",
                "Mockingbird Valley",
                "Monterey",
                "Monticello",
                "Moorland",
                "Morehead",
                "Morganfield",
                "Morgantown",
                "Mortons Gap",
                "Mount Olivet",
                "Mount Sterling",
                "Mount Vernon",
                "Mount Washington",
                "Muldraugh",
                "Munfordville",
                "Murray",
                "Murray Hill",
                "Nebo",
                "New Castle",
                "New Haven",
                "Newburg",
                "Newport",
                "Nicholasville",
                "Norbourne Estates",
                "North Corbin",
                "North Middletown",
                "Northfield",
                "Nortonville",
                "Norwood",
                "Oak Grove",
                "Oakbrook",
                "Oakland",
                "Okolona",
                "Old Brownsboro Place",
                "Olive Hill",
                "Orchard Grass Hills",
                "Owensboro",
                "Owenton",
                "Owingsville",
                "Paducah",
                "Paintsville",
                "Paris",
                "Park City",
                "Park Hills",
                "Park Lake",
                "Parkway Village",
                "Pembroke",
                "Perryville",
                "Pewee Valley",
                "Phelps",
                "Pikeville",
                "Pine Knot",
                "Pineville",
                "Pioneer Village",
                "Pippa Passes",
                "Plantation",
                "Pleasure Ridge Park",
                "Pleasureville",
                "Plum Springs",
                "Plymouth Village",
                "Poplar Hills",
                "Powderly",
                "Prestonsburg",
                "Prestonville",
                "Princeton",
                "Prospect",
                "Providence",
                "Raceland",
                "Radcliff",
                "Ravenna",
                "Raywick",
                "Reidland",
                "Richlawn",
                "Richmond",
                "River Bluff",
                "Riverwood",
                "Robards",
                "Rochester",
                "Rockport",
                "Rolling Fields",
                "Rolling Hills",
                "Russell",
                "Russell Springs",
                "Russellville",
                "Ryland Heights",
                "Sacramento",
                "Sadieville",
                "Salem",
                "Salt Lick",
                "Salyersville",
                "Sanders",
                "Sandy Hook",
                "Sardis",
                "Science Hill",
                "Scottsville",
                "Sebree",
                "Seneca Gardens",
                "Sharpsburg",
                "Shelbyville",
                "Shepherdsville",
                "Shively",
                "Silver Grove",
                "Simpsonville",
                "Slaughters",
                "Smithfield",
                "Smithland",
                "Smiths Grove",
                "Somerset",
                "Sonora",
                "South Carrollton",
                "South Park View",
                "South Shore",
                "South Wallins",
                "Southgate",
                "Sparta",
                "Spring Mill",
                "Spring Valley",
                "Springfield",
                "Springlee",
                "St. Charles",
                "St. Dennis",
                "St. Matthews",
                "St. Regis Park",
                "Stamping Ground",
                "Stanford",
                "Stanton",
                "Stearns",
                "Strathmoor Manor",
                "Strathmoor Village",
                "Sturgis",
                "Sycamore",
                "Taylor Mill",
                "Taylorsville",
                "Ten Broeck",
                "Thornhill",
                "Tompkinsville",
                "Trenton",
                "Union",
                "Uniontown",
                "Upton",
                "Valley Station",
                "Vanceburg",
                "Versailles",
                "Vicco",
                "Villa Hills",
                "Vine Grove",
                "Visalia",
                "Wallins Creek",
                "Walton",
                "Warfield",
                "Warsaw",
                "Water Valley",
                "Watterson Park",
                "Waverly",
                "Wayland",
                "Wellington",
                "West Buechel",
                "West Liberty",
                "West Point",
                "Westwood",
                "Wheatcroft",
                "Wheelwright",
                "Whipps Millgate",
                "White Plains",
                "Whitesburg",
                "Whitesville",
                "Whitley City",
                "Wickliffe",
                "Wilder",
                "Wildwood",
                "Williamsburg",
                "Williamstown",
                "Willisburg",
                "Wilmore",
                "Winchester",
                "Windy Hills",
                "Wingo",
                "Woodburn",
                "Woodbury",
                "Woodland Hills",
                "Woodlawn",
                "Woodlawn Park",
                "Woodlawn-oakdale",
                "Worthington",
                "Worthington Hills",
                "Worthville",
                "Wurtland"
            ],
            "Louisiana": [
                "Abbeville",
                "Abita Springs",
                "Addis",
                "Albany",
                "Alexandria",
                "Ama",
                "Amelia",
                "Amite City",
                "Anacoco",
                "Angie",
                "Arabi",
                "Arcadia",
                "Arnaudville",
                "Ashland",
                "Athens",
                "Atlanta",
                "Avondale",
                "Baker",
                "Baldwin",
                "Ball",
                "Barataria",
                "Basile",
                "Baskin",
                "Bastrop",
                "Baton Rouge",
                "Bayou Cane",
                "Bayou Gauche",
                "Bayou Vista",
                "Belcher",
                "Belle Chasse",
                "Belle Rose",
                "Benton",
                "Bernice",
                "Berwick",
                "Bienville",
                "Blanchard",
                "Bogalusa",
                "Bonita",
                "Boothville-venice",
                "Bossier City",
                "Boutte",
                "Boyce",
                "Breaux Bridge",
                "Bridge City",
                "Broussard",
                "Brownfields",
                "Brownsville-bawcomville",
                "Brusly",
                "Bryceland",
                "Bunkie",
                "Buras-triumph",
                "Calvin",
                "Cameron",
                "Campti",
                "Cankton",
                "Carencro",
                "Carlyss",
                "Castor",
                "Cecilia",
                "Chackbay",
                "Chalmette",
                "Charenton",
                "Chataignier",
                "Chatham",
                "Chauvin",
                "Cheneyville",
                "Choudrant",
                "Church Point",
                "Claiborne",
                "Clarence",
                "Clarks",
                "Clayton",
                "Clinton",
                "Colfax",
                "Collinston",
                "Columbia",
                "Converse",
                "Cotton Valley",
                "Cottonport",
                "Coushatta",
                "Covington",
                "Crowley",
                "Cullen",
                "Cut Off",
                "De Ridder",
                "Delcambre",
                "Delhi",
                "Delta",
                "Denham Springs",
                "Dequincy",
                "Des Allemands",
                "Destrehan",
                "Deville",
                "Dixie Inn",
                "Dodson",
                "Donaldsonville",
                "Downsville",
                "Doyline",
                "Dry Prong",
                "Dubach",
                "Dubberly",
                "Dulac",
                "Duson",
                "East Hodge",
                "Eastwood",
                "Eden Isle",
                "Edgard",
                "Edgefield",
                "Elizabeth",
                "Elmwood",
                "Elton",
                "Empire",
                "Epps",
                "Erath",
                "Eros",
                "Estelle",
                "Estherwood",
                "Eunice",
                "Evergreen",
                "Farmerville",
                "Fenton",
                "Ferriday",
                "Fisher",
                "Florien",
                "Folsom",
                "Fordoche",
                "Forest",
                "Forest Hill",
                "Fort Polk North",
                "Fort Polk South",
                "Franklin",
                "Franklinton",
                "French Settlement",
                "Galliano",
                "Gardere",
                "Garyville",
                "Georgetown",
                "Gibsland",
                "Gilbert",
                "Gilliam",
                "Glenmora",
                "Golden Meadow",
                "Goldonna",
                "Gonzales",
                "Grambling",
                "Gramercy",
                "Grand Cane",
                "Grand Coteau",
                "Grand Isle",
                "Gray",
                "Grayson",
                "Greensburg",
                "Greenwood",
                "Gretna",
                "Grosse Tete",
                "Gueydan",
                "Hackberry",
                "Hahnville",
                "Hall Summit",
                "Hammond",
                "Harahan",
                "Harrisonburg",
                "Harvey",
                "Haughton",
                "Haynesville",
                "Heflin",
                "Henderson",
                "Hessmer",
                "Hodge",
                "Homer",
                "Hornbeck",
                "Hosston",
                "Houma",
                "Ida",
                "Independence",
                "Inniswold",
                "Iota",
                "Iowa",
                "Jackson",
                "Jamestown",
                "Jean Lafitte",
                "Jeanerette",
                "Jefferson",
                "Jena",
                "Jennings",
                "Jonesboro",
                "Jonesville",
                "Junction City",
                "Kaplan",
                "Keachi",
                "Kenner",
                "Kentwood",
                "Kilbourne",
                "Killian",
                "Killona",
                "Kinder",
                "Krotz Springs",
                "Labadieville",
                "Lacombe",
                "Lafayette",
                "Lafitte",
                "Lake Arthur",
                "Lake Charles",
                "Lake Providence",
                "Laplace",
                "Larose",
                "Lecompte",
                "Leesville",
                "Leonville",
                "Lillie",
                "Lisbon",
                "Livingston",
                "Livonia",
                "Lockport",
                "Logansport",
                "Longstreet",
                "Loreauville",
                "Lucky",
                "Luling",
                "Lutcher",
                "Lydia",
                "Madisonville",
                "Mamou",
                "Mandeville",
                "Mangham",
                "Mansfield",
                "Mansura",
                "Many",
                "Maringouin",
                "Marion",
                "Marksville",
                "Marrero",
                "Martin",
                "Mathews",
                "Maurice",
                "Mcnary",
                "Melville",
                "Mer Rouge",
                "Meraux",
                "Mermentau",
                "Merrydale",
                "Merryville",
                "Metairie",
                "Midway",
                "Minden",
                "Monroe",
                "Montegut",
                "Montgomery",
                "Monticello",
                "Montpelier",
                "Montz",
                "Mooringsport",
                "Moreauville",
                "Morgan City",
                "Morganza",
                "Morse",
                "Moss Bluff",
                "Mound",
                "Mount Lebanon",
                "Napoleonville",
                "Natalbany",
                "Natchez",
                "Natchitoches",
                "New Iberia",
                "New Llano",
                "New Orleans",
                "New Roads",
                "New Sarpy",
                "Newellton",
                "Noble",
                "Norco",
                "North Hodge",
                "North Vacherie",
                "Norwood",
                "Oak Grove",
                "Oak Hills Place",
                "Oak Ridge",
                "Oakdale",
                "Oberlin",
                "Oil City",
                "Old Jefferson",
                "Olla",
                "Opelousas",
                "Paincourtville",
                "Palmetto",
                "Paradis",
                "Parks",
                "Patterson",
                "Pearl River",
                "Pierre Part",
                "Pine Prairie",
                "Pineville",
                "Pioneer",
                "Plain Dealing",
                "Plaquemine",
                "Plaucheville",
                "Pleasant Hill",
                "Pollock",
                "Ponchatoula",
                "Port Allen",
                "Port Barre",
                "Port Sulphur",
                "Port Vincent",
                "Powhatan",
                "Poydras",
                "Prien",
                "Provencal",
                "Quitman",
                "Raceland",
                "Rayne",
                "Rayville",
                "Red Chute",
                "Reeves",
                "Reserve",
                "Richmond",
                "Richwood",
                "Ridgecrest",
                "Ringgold",
                "River Ridge",
                "Robeline",
                "Rodessa",
                "Rosedale",
                "Roseland",
                "Rosepine",
                "Ruston",
                "Saline",
                "Sarepta",
                "Schriever",
                "Scott",
                "Shenandoah",
                "Shongaloo",
                "Shreveport",
                "Sibley",
                "Sicily Island",
                "Sikes",
                "Simmesport",
                "Simpson",
                "Simsboro",
                "Slaughter",
                "Slidell",
                "Sorrento",
                "South Mansfield",
                "South Vacherie",
                "Spearsville",
                "Springfield",
                "Springhill",
                "St. Francisville",
                "St. Gabriel",
                "St. Joseph",
                "St. Martinville",
                "St. Rose",
                "Stanley",
                "Sterlington",
                "Stonewall",
                "Sulphur",
                "Sun",
                "Sunset",
                "Supreme",
                "Swartz",
                "Taft",
                "Tallulah",
                "Tangipahoa",
                "Terrytown",
                "Thibodaux",
                "Tickfaw",
                "Timberlane",
                "Tullos",
                "Turkey Creek",
                "Urania",
                "Varnado",
                "Vidalia",
                "Vienna",
                "Village St. George",
                "Ville Platte",
                "Vinton",
                "Violet",
                "Vivian",
                "Waggaman",
                "Walker",
                "Wallace",
                "Washington",
                "Waterproof",
                "Welsh",
                "West Ferriday",
                "West Monroe",
                "Westlake",
                "Westminster",
                "Westwego",
                "White Castle",
                "Wilson",
                "Winnfield",
                "Winnsboro",
                "Wisner",
                "Woodmere",
                "Woodworth",
                "Youngsville",
                "Zachary",
                "Zwolle"
            ],
            "Lowa": [
                "Abbeville",
                "Abita Springs",
                "Addis",
                "Albany",
                "Alexandria",
                "Ama",
                "Amelia",
                "Amite City",
                "Anacoco",
                "Angie",
                "Arabi",
                "Arcadia",
                "Arnaudville",
                "Ashland",
                "Athens",
                "Atlanta",
                "Avondale",
                "Baker",
                "Baldwin",
                "Ball",
                "Barataria",
                "Basile",
                "Baskin",
                "Bastrop",
                "Baton Rouge",
                "Bayou Cane",
                "Bayou Gauche",
                "Bayou Vista",
                "Belcher",
                "Belle Chasse",
                "Belle Rose",
                "Benton",
                "Bernice",
                "Berwick",
                "Bienville",
                "Blanchard",
                "Bogalusa",
                "Bonita",
                "Boothville-venice",
                "Bossier City",
                "Boutte",
                "Boyce",
                "Breaux Bridge",
                "Bridge City",
                "Broussard",
                "Brownfields",
                "Brownsville-bawcomville",
                "Brusly",
                "Bryceland",
                "Bunkie",
                "Buras-triumph",
                "Calvin",
                "Cameron",
                "Campti",
                "Cankton",
                "Carencro",
                "Carlyss",
                "Castor",
                "Cecilia",
                "Chackbay",
                "Chalmette",
                "Charenton",
                "Chataignier",
                "Chatham",
                "Chauvin",
                "Cheneyville",
                "Choudrant",
                "Church Point",
                "Claiborne",
                "Clarence",
                "Clarks",
                "Clayton",
                "Clinton",
                "Colfax",
                "Collinston",
                "Columbia",
                "Converse",
                "Cotton Valley",
                "Cottonport",
                "Coushatta",
                "Covington",
                "Crowley",
                "Cullen",
                "Cut Off",
                "De Ridder",
                "Delcambre",
                "Delhi",
                "Delta",
                "Denham Springs",
                "Dequincy",
                "Des Allemands",
                "Destrehan",
                "Deville",
                "Dixie Inn",
                "Dodson",
                "Donaldsonville",
                "Downsville",
                "Doyline",
                "Dry Prong",
                "Dubach",
                "Dubberly",
                "Dulac",
                "Duson",
                "East Hodge",
                "Eastwood",
                "Eden Isle",
                "Edgard",
                "Edgefield",
                "Elizabeth",
                "Elmwood",
                "Elton",
                "Empire",
                "Epps",
                "Erath",
                "Eros",
                "Estelle",
                "Estherwood",
                "Eunice",
                "Evergreen",
                "Farmerville",
                "Fenton",
                "Ferriday",
                "Fisher",
                "Florien",
                "Folsom",
                "Fordoche",
                "Forest",
                "Forest Hill",
                "Fort Polk North",
                "Fort Polk South",
                "Franklin",
                "Franklinton",
                "French Settlement",
                "Galliano",
                "Gardere",
                "Garyville",
                "Georgetown",
                "Gibsland",
                "Gilbert",
                "Gilliam",
                "Glenmora",
                "Golden Meadow",
                "Goldonna",
                "Gonzales",
                "Grambling",
                "Gramercy",
                "Grand Cane",
                "Grand Coteau",
                "Grand Isle",
                "Gray",
                "Grayson",
                "Greensburg",
                "Greenwood",
                "Gretna",
                "Grosse Tete",
                "Gueydan",
                "Hackberry",
                "Hahnville",
                "Hall Summit",
                "Hammond",
                "Harahan",
                "Harrisonburg",
                "Harvey",
                "Haughton",
                "Haynesville",
                "Heflin",
                "Henderson",
                "Hessmer",
                "Hodge",
                "Homer",
                "Hornbeck",
                "Hosston",
                "Houma",
                "Ida",
                "Independence",
                "Inniswold",
                "Iota",
                "Iowa",
                "Jackson",
                "Jamestown",
                "Jean Lafitte",
                "Jeanerette",
                "Jefferson",
                "Jena",
                "Jennings",
                "Jonesboro",
                "Jonesville",
                "Junction City",
                "Kaplan",
                "Keachi",
                "Kenner",
                "Kentwood",
                "Kilbourne",
                "Killian",
                "Killona",
                "Kinder",
                "Krotz Springs",
                "Labadieville",
                "Lacombe",
                "Lafayette",
                "Lafitte",
                "Lake Arthur",
                "Lake Charles",
                "Lake Providence",
                "Laplace",
                "Larose",
                "Lecompte",
                "Leesville",
                "Leonville",
                "Lillie",
                "Lisbon",
                "Livingston",
                "Livonia",
                "Lockport",
                "Logansport",
                "Longstreet",
                "Loreauville",
                "Lucky",
                "Luling",
                "Lutcher",
                "Lydia",
                "Madisonville",
                "Mamou",
                "Mandeville",
                "Mangham",
                "Mansfield",
                "Mansura",
                "Many",
                "Maringouin",
                "Marion",
                "Marksville",
                "Marrero",
                "Martin",
                "Mathews",
                "Maurice",
                "Mcnary",
                "Melville",
                "Mer Rouge",
                "Meraux",
                "Mermentau",
                "Merrydale",
                "Merryville",
                "Metairie",
                "Midway",
                "Minden",
                "Monroe",
                "Montegut",
                "Montgomery",
                "Monticello",
                "Montpelier",
                "Montz",
                "Mooringsport",
                "Moreauville",
                "Morgan City",
                "Morganza",
                "Morse",
                "Moss Bluff",
                "Mound",
                "Mount Lebanon",
                "Napoleonville",
                "Natalbany",
                "Natchez",
                "Natchitoches",
                "New Iberia",
                "New Llano",
                "New Orleans",
                "New Roads",
                "New Sarpy",
                "Newellton",
                "Noble",
                "Norco",
                "North Hodge",
                "North Vacherie",
                "Norwood",
                "Oak Grove",
                "Oak Hills Place",
                "Oak Ridge",
                "Oakdale",
                "Oberlin",
                "Oil City",
                "Old Jefferson",
                "Olla",
                "Opelousas",
                "Paincourtville",
                "Palmetto",
                "Paradis",
                "Parks",
                "Patterson",
                "Pearl River",
                "Pierre Part",
                "Pine Prairie",
                "Pineville",
                "Pioneer",
                "Plain Dealing",
                "Plaquemine",
                "Plaucheville",
                "Pleasant Hill",
                "Pollock",
                "Ponchatoula",
                "Port Allen",
                "Port Barre",
                "Port Sulphur",
                "Port Vincent",
                "Powhatan",
                "Poydras",
                "Prien",
                "Provencal",
                "Quitman",
                "Raceland",
                "Rayne",
                "Rayville",
                "Red Chute",
                "Reeves",
                "Reserve",
                "Richmond",
                "Richwood",
                "Ridgecrest",
                "Ringgold",
                "River Ridge",
                "Robeline",
                "Rodessa",
                "Rosedale",
                "Roseland",
                "Rosepine",
                "Ruston",
                "Saline",
                "Sarepta",
                "Schriever",
                "Scott",
                "Shenandoah",
                "Shongaloo",
                "Shreveport",
                "Sibley",
                "Sicily Island",
                "Sikes",
                "Simmesport",
                "Simpson",
                "Simsboro",
                "Slaughter",
                "Slidell",
                "Sorrento",
                "South Mansfield",
                "South Vacherie",
                "Spearsville",
                "Springfield",
                "Springhill",
                "St. Francisville",
                "St. Gabriel",
                "St. Joseph",
                "St. Martinville",
                "St. Rose",
                "Stanley",
                "Sterlington",
                "Stonewall",
                "Sulphur",
                "Sun",
                "Sunset",
                "Supreme",
                "Swartz",
                "Taft",
                "Tallulah",
                "Tangipahoa",
                "Terrytown",
                "Thibodaux",
                "Tickfaw",
                "Timberlane",
                "Tullos",
                "Turkey Creek",
                "Urania",
                "Varnado",
                "Vidalia",
                "Vienna",
                "Village St. George",
                "Ville Platte",
                "Vinton",
                "Violet",
                "Vivian",
                "Waggaman",
                "Walker",
                "Wallace",
                "Washington",
                "Waterproof",
                "Welsh",
                "West Ferriday",
                "West Monroe",
                "Westlake",
                "Westminster",
                "Westwego",
                "White Castle",
                "Wilson",
                "Winnfield",
                "Winnsboro",
                "Wisner",
                "Woodmere",
                "Woodworth",
                "Youngsville",
                "Zachary",
                "Zwolle"
            ],
            "Maine": [
                "Anson",
                "Auburn",
                "Augusta",
                "Bangor",
                "Bar Harbor",
                "Bath",
                "Belfast",
                "Berwick",
                "Biddeford",
                "Bingham",
                "Boothbay Harbor",
                "Brewer",
                "Bridgton",
                "Brunswick",
                "Brunswick Station",
                "Bucksport",
                "Calais",
                "Camden",
                "Cape Neddick",
                "Caribou",
                "Chisholm",
                "Clinton",
                "Cumberland Center",
                "Damariscotta-newcastle",
                "Dexter",
                "Dixfield",
                "Dover-foxcroft",
                "East Millinocket",
                "Eastport",
                "Ellsworth",
                "Fairfield",
                "Falmouth Foreside",
                "Farmingdale",
                "Farmington",
                "Fort Fairfield",
                "Fort Kent",
                "Freeport",
                "Fryeburg",
                "Gardiner",
                "Gorham",
                "Greenville",
                "Guilford",
                "Hallowell",
                "Hampden",
                "Hartland",
                "Houlton",
                "Howland",
                "Kennebunk",
                "Kennebunkport",
                "Kittery",
                "Kittery Point",
                "Lake Arrowhead",
                "Lewiston",
                "Limestone",
                "Lincoln",
                "Lisbon Falls",
                "Little Falls-south Windham",
                "Livermore Falls",
                "Loring Afb",
                "Machias",
                "Madawaska",
                "Madison",
                "Mars Hill-blaine",
                "Mechanic Falls",
                "Mexico",
                "Milford",
                "Millinocket",
                "Milo",
                "Newport",
                "Norridgewock",
                "North Berwick",
                "North Windham",
                "Norway",
                "Oakland",
                "Old Orchard Beach",
                "Old Town",
                "Orono",
                "Oxford",
                "Pittsfield",
                "Portland",
                "Presque Isle",
                "Randolph",
                "Richmond",
                "Rockland",
                "Rumford",
                "Saco",
                "Sanford",
                "Scarborough",
                "Searsport",
                "Skowhegan",
                "South Eliot",
                "South Paris",
                "South Portland",
                "South Sanford",
                "Springvale",
                "Thomaston",
                "Topsham",
                "Unity",
                "Van Buren",
                "Waldoboro",
                "Waterville",
                "West Kennebunk",
                "Westbrook",
                "Wilton",
                "Winslow",
                "Winterport",
                "Winthrop",
                "Wiscasset",
                "Woodland",
                "Yarmouth",
                "York Harbor"
            ],
            "Marshall Islands": [
                "Marshall Islands"
            ],
            "Maryland": [
                "Aberdeen",
                "Aberdeen Proving Ground",
                "Accident",
                "Accokeek",
                "Adelphi",
                "Algonquin",
                "Andrews Afb",
                "Annapolis",
                "Arbutus",
                "Arden-on-the-severn",
                "Arnold",
                "Ashton-sandy Spring",
                "Aspen Hill",
                "Ballenger Creek",
                "Baltimore",
                "Barclay",
                "Barnesville",
                "Barton",
                "Bel Air",
                "Bel Air North",
                "Bel Air South",
                "Beltsville",
                "Bennsville",
                "Berlin",
                "Berwyn Heights",
                "Bethesda",
                "Betterton",
                "Bladensburg",
                "Boonsboro",
                "Bowie",
                "Bowleys Quarters",
                "Braddock Heights",
                "Brandywine",
                "Brentwood",
                "Brookeville",
                "Brooklyn Park",
                "Brookmont",
                "Brookview",
                "Brunswick",
                "Bryans Road",
                "Burkittsville",
                "Burtonsville",
                "Cabin John",
                "California",
                "Calvert Beach-long Beach",
                "Calverton",
                "Cambridge",
                "Camp Springs",
                "Cape St. Claire",
                "Capitol Heights",
                "Carmody Hills-pepper Mill Village",
                "Carney",
                "Catonsville",
                "Cavetown",
                "Cecilton",
                "Centreville",
                "Chance",
                "Charlestown",
                "Charlotte Hall",
                "Chesapeake Beach",
                "Chesapeake City",
                "Chesapeake Ranch Estates-drum Point",
                "Chester",
                "Chestertown",
                "Cheverly",
                "Chevy Chase",
                "Chevy Chase Section Five",
                "Chevy Chase Section Three",
                "Chevy Chase View",
                "Chevy Chase Village",
                "Chewsville",
                "Chillum",
                "Church Creek",
                "Church Hill",
                "Clarksburg",
                "Clear Spring",
                "Clinton",
                "Clover Hill",
                "Cloverly",
                "Cockeysville",
                "Colesville",
                "College Park",
                "Colmar Manor",
                "Columbia",
                "Coral Hills",
                "Cordova",
                "Cottage City",
                "Cresaptown-bel Air",
                "Crisfield",
                "Crofton",
                "Crownsville",
                "Cumberland",
                "Damascus",
                "Dames Quarter",
                "Darnestown",
                "Deal Island",
                "Deale",
                "Deer Park",
                "Delmar",
                "Denton",
                "Discovery-spring Garden",
                "District Heights",
                "Dundalk",
                "Dunkirk",
                "Eagle Harbor",
                "East New Market",
                "East Riverdale",
                "Easton",
                "Eden",
                "Edgemere",
                "Edgewood",
                "Edmonston",
                "Eldersburg",
                "Eldorado",
                "Elkridge",
                "Elkton",
                "Ellicott City",
                "Emmitsburg",
                "Essex",
                "Fairland",
                "Fairmount",
                "Fairmount Heights",
                "Fallston",
                "Federalsburg",
                "Ferndale",
                "Forest Glen",
                "Forest Heights",
                "Forestville",
                "Fort Meade",
                "Fort Ritchie",
                "Fort Washington",
                "Fountainhead-orchard Hills",
                "Frederick",
                "Frenchtown-rumbly",
                "Friendly",
                "Friendship Village",
                "Friendsville",
                "Frostburg",
                "Fruitland",
                "Funkstown",
                "Gaithersburg",
                "Galena",
                "Galestown",
                "Garrett Park",
                "Garrison",
                "Germantown",
                "Girdletree",
                "Glen Burnie",
                "Glen Echo",
                "Glenarden",
                "Glenn Dale",
                "Goddard",
                "Golden Beach",
                "Goldsboro",
                "Grantsville",
                "Grasonville",
                "Greater Landover",
                "Greater Upper Marlboro",
                "Green Haven",
                "Green Valley",
                "Greenbelt",
                "Greensboro",
                "Hagerstown",
                "Halfway",
                "Hampstead",
                "Hampton",
                "Hancock",
                "Havre De Grace",
                "Hebron",
                "Henderson",
                "Herald Harbor",
                "Highfield-cascade",
                "Highland Beach",
                "Hillandale",
                "Hillcrest Heights",
                "Hillsboro",
                "Hillsmere Shores",
                "Hughesville",
                "Huntingtown",
                "Hurlock",
                "Hyattsville",
                "Indian Head",
                "Jarrettsville",
                "Jessup",
                "Joppatowne",
                "Keedysville",
                "Kemp Mill",
                "Kensington",
                "Kent Narrows",
                "Kettering",
                "Kingstown",
                "Kingsville",
                "Kitzmiller",
                "La Plata",
                "La Vale",
                "Lake Arbor",
                "Lake Shore",
                "Landover Hills",
                "Langley Park",
                "Lanham-seabrook",
                "Lansdowne-baltimore Highlands",
                "Largo",
                "Laurel",
                "Laytonsville",
                "Leitersburg",
                "Leonardtown",
                "Lexington Park",
                "Linganore-bartonsville",
                "Linthicum",
                "Loch Lynn Heights",
                "Lochearn",
                "Lonaconing",
                "Londontowne",
                "Luke",
                "Lusby",
                "Lutherville-timonium",
                "Manchester",
                "Mardela Springs",
                "Marlow Heights",
                "Marlton",
                "Martin S Additions",
                "Marydel",
                "Maryland City",
                "Maugansville",
                "Mayo",
                "Mays Chapel",
                "Middle River",
                "Middletown",
                "Midland",
                "Milford Mill",
                "Millington",
                "Mitchellville",
                "Montgomery Village",
                "Morningside",
                "Mount Aetna",
                "Mount Airy",
                "Mount Lena",
                "Mount Rainier",
                "Mount Vernon",
                "Mountain Lake Park",
                "Myersville",
                "Naval Academy",
                "New Carrollton",
                "New Market",
                "New Windsor",
                "Newark",
                "North Beach",
                "North Bethesda",
                "North Brentwood",
                "North Chevy Chase",
                "North East",
                "North Kensington",
                "North Laurel",
                "North Potomac",
                "Oakland",
                "Ocean City",
                "Ocean Pines",
                "Odenton",
                "Olney",
                "Overlea",
                "Owings",
                "Owings Mills",
                "Oxford",
                "Oxon Hill-glassmanor",
                "Paramount-long Meadow",
                "Parkville",
                "Parole",
                "Pasadena",
                "Perry Hall",
                "Perryman",
                "Perryville",
                "Pikesville",
                "Pittsville",
                "Pleasant Hills",
                "Pocomoke City",
                "Poolesville",
                "Port Deposit",
                "Port Tobacco Village",
                "Potomac",
                "Potomac Heights",
                "Preston",
                "Prince Frederick",
                "Princess Anne",
                "Pumphrey",
                "Queen Anne",
                "Queenstown",
                "Randallstown",
                "Redland",
                "Reisterstown",
                "Ridgely",
                "Rising Sun",
                "Riva",
                "Riverdale Park",
                "Riverside",
                "Riviera Beach",
                "Robinwood",
                "Rock Hall",
                "Rockville",
                "Rohrersville",
                "Rosaryville",
                "Rosedale",
                "Rosemont",
                "Rossmoor",
                "Rossville",
                "Salisbury",
                "San Mar",
                "Savage-guilford",
                "Seat Pleasant",
                "Secretary",
                "Selby-on-the-bay",
                "Severn",
                "Severna Park",
                "Shady Side",
                "Sharpsburg",
                "Sharptown",
                "Silver Spring",
                "Smith Island",
                "Smithsburg",
                "Snow Hill",
                "Solomons",
                "Somerset",
                "South Gate",
                "South Kensington",
                "South Laurel",
                "Springdale",
                "St. Charles",
                "St. James",
                "St. Leonard",
                "St. Michaels",
                "Stevensville",
                "Stockton",
                "Sudlersville",
                "Suitland-silver Hill",
                "Sykesville",
                "Takoma Park",
                "Taneytown",
                "Temple Hills",
                "Templeville",
                "Thurmont",
                "Tilghman Island",
                "Towson",
                "Trappe",
                "Travilah",
                "Union Bridge",
                "University Park",
                "Upper Marlboro",
                "Vienna",
                "Waldorf",
                "Walker Mill",
                "Walkersville",
                "Washington Grove",
                "West Laurel",
                "West Ocean City",
                "West Pocomoke",
                "Westernport",
                "Westminster",
                "Whaleyville",
                "Wheaton-glenmont",
                "White Marsh",
                "White Oak",
                "Willards",
                "Williamsport",
                "Wilson-conococheague",
                "Woodlawn",
                "Woodmore",
                "Woodsboro"
            ],
            "Massachusetts": [
                "Abington",
                "Acushnet Center",
                "Adams",
                "Agawam",
                "Amesbury",
                "Amherst Center",
                "Andover",
                "Arlington",
                "Athol",
                "Attleboro",
                "Ayer",
                "Baldwinville",
                "Barnstable Town",
                "Barre",
                "Belchertown",
                "Bellingham",
                "Belmont",
                "Beverly",
                "Bliss Corner",
                "Bondsville",
                "Boston",
                "Bourne",
                "Boxford",
                "Braintree",
                "Brewster",
                "Bridgewater",
                "Brockton",
                "Brookline",
                "Burlington",
                "Buzzards Bay",
                "Cambridge",
                "Chatham",
                "Chelsea",
                "Chicopee",
                "Clinton",
                "Cochituate",
                "Cordaville",
                "Danvers",
                "Dedham",
                "Dennis",
                "Dennis Port",
                "Dover",
                "Duxbury",
                "East Brookfield",
                "East Dennis",
                "East Douglas",
                "East Falmouth",
                "East Harwich",
                "East Pepperell",
                "East Sandwich",
                "Easthampton",
                "Essex",
                "Everett",
                "Fall River",
                "Falmouth",
                "Fiskdale",
                "Fitchburg",
                "Forestdale",
                "Fort Devens",
                "Foxborough",
                "Framingham",
                "Franklin",
                "Gardner",
                "Gloucester",
                "Granby",
                "Great Barrington",
                "Green Harbor-cedar Crest",
                "Greenfield",
                "Groton",
                "Hanson",
                "Harwich Center",
                "Harwich Port",
                "Hatfield",
                "Haverhill",
                "Hingham",
                "Holbrook",
                "Holland",
                "Holyoke",
                "Hopedale",
                "Hopkinton",
                "Housatonic",
                "Hudson",
                "Hull",
                "Ipswich",
                "Kingston",
                "Lawrence",
                "Lee",
                "Lenox",
                "Leominster",
                "Lexington",
                "Littleton Common",
                "Longmeadow",
                "Lowell",
                "Lunenburg",
                "Lynn",
                "Lynnfield",
                "Malden",
                "Mansfield Center",
                "Marblehead",
                "Marion Center",
                "Marlborough",
                "Marshfield",
                "Marshfield Hills",
                "Mashpee Neck",
                "Mattapoisett Center",
                "Maynard",
                "Medfield",
                "Medford",
                "Melrose",
                "Methuen",
                "Middleborough Center",
                "Milford",
                "Millers Falls",
                "Millis-clicquot",
                "Milton",
                "Monomoscoy Island",
                "Monson Center",
                "Monument Beach",
                "Nahant",
                "Nantucket",
                "Needham",
                "New Bedford",
                "New Seabury",
                "Newburyport",
                "Newton",
                "North Adams",
                "North Amherst",
                "North Attleborough Center",
                "North Brookfield",
                "North Eastham",
                "North Falmouth",
                "North Lakeville",
                "North Pembroke",
                "North Plymouth",
                "North Scituate",
                "North Seekonk",
                "North Westport",
                "Northampton",
                "Northborough",
                "Northfield",
                "Northwest Harwich",
                "Norton Center",
                "Norwood",
                "Ocean Bluff-brant Rock",
                "Ocean Grove",
                "Onset",
                "Orange",
                "Orleans",
                "Oxford",
                "Palmer",
                "Peabody",
                "Pepperell",
                "Pinehurst",
                "Pittsfield",
                "Plymouth",
                "Pocasset",
                "Popponesset",
                "Popponesset Island",
                "Provincetown",
                "Quincy",
                "Randolph",
                "Raynham Center",
                "Reading",
                "Revere",
                "Rockport",
                "Rowley",
                "Rutland",
                "Sagamore",
                "Salem",
                "Salisbury",
                "Sandwich",
                "Saugus",
                "Scituate",
                "Seabrook",
                "Seconsett Island",
                "Sharon",
                "Shelburne Falls",
                "Shirley",
                "Smith Mills",
                "Somerset",
                "Somerville",
                "South Amherst",
                "South Ashburnham",
                "South Deerfield",
                "South Dennis",
                "South Duxbury",
                "South Lancaster",
                "South Yarmouth",
                "Southbridge",
                "Spencer",
                "Springfield",
                "Stoneham",
                "Sturbridge",
                "Swampscott",
                "Taunton",
                "Teaticket",
                "Three Rivers",
                "Topsfield",
                "Townsend",
                "Turners Falls",
                "Upton-west Upton",
                "Vineyard Haven",
                "Wakefield",
                "Walpole",
                "Waltham",
                "Ware",
                "Wareham Center",
                "Warren",
                "Watertown",
                "Webster",
                "Wellesley",
                "West Brookfield",
                "West Chatham",
                "West Concord",
                "West Dennis",
                "West Falmouth",
                "West Springfield",
                "West Wareham",
                "West Yarmouth",
                "Westborough",
                "Westfield",
                "Weweantic",
                "Weymouth",
                "White Island Shores",
                "Whitinsville",
                "Wilbraham",
                "Williamstown",
                "Wilmington",
                "Winchendon",
                "Winchester",
                "Winthrop",
                "Woburn",
                "Woods Hole",
                "Worcester",
                "Yarmouth Port"
            ],
            "Medfield": [
                "Abington",
                "Acushnet Center",
                "Adams",
                "Agawam",
                "Amesbury",
                "Amherst Center",
                "Andover",
                "Arlington",
                "Athol",
                "Attleboro",
                "Ayer",
                "Baldwinville",
                "Barnstable Town",
                "Barre",
                "Belchertown",
                "Bellingham",
                "Belmont",
                "Beverly",
                "Bliss Corner",
                "Bondsville",
                "Boston",
                "Bourne",
                "Boxford",
                "Braintree",
                "Brewster",
                "Bridgewater",
                "Brockton",
                "Brookline",
                "Burlington",
                "Buzzards Bay",
                "Cambridge",
                "Chatham",
                "Chelsea",
                "Chicopee",
                "Clinton",
                "Cochituate",
                "Cordaville",
                "Danvers",
                "Dedham",
                "Dennis",
                "Dennis Port",
                "Dover",
                "Duxbury",
                "East Brookfield",
                "East Dennis",
                "East Douglas",
                "East Falmouth",
                "East Harwich",
                "East Pepperell",
                "East Sandwich",
                "Easthampton",
                "Essex",
                "Everett",
                "Fall River",
                "Falmouth",
                "Fiskdale",
                "Fitchburg",
                "Forestdale",
                "Fort Devens",
                "Foxborough",
                "Framingham",
                "Franklin",
                "Gardner",
                "Gloucester",
                "Granby",
                "Great Barrington",
                "Green Harbor-cedar Crest",
                "Greenfield",
                "Groton",
                "Hanson",
                "Harwich Center",
                "Harwich Port",
                "Hatfield",
                "Haverhill",
                "Hingham",
                "Holbrook",
                "Holland",
                "Holyoke",
                "Hopedale",
                "Hopkinton",
                "Housatonic",
                "Hudson",
                "Hull",
                "Ipswich",
                "Kingston",
                "Lawrence",
                "Lee",
                "Lenox",
                "Leominster",
                "Lexington",
                "Littleton Common",
                "Longmeadow",
                "Lowell",
                "Lunenburg",
                "Lynn",
                "Lynnfield",
                "Malden",
                "Mansfield Center",
                "Marblehead",
                "Marion Center",
                "Marlborough",
                "Marshfield",
                "Marshfield Hills",
                "Mashpee Neck",
                "Mattapoisett Center",
                "Maynard",
                "Medfield",
                "Medford",
                "Melrose",
                "Methuen",
                "Middleborough Center",
                "Milford",
                "Millers Falls",
                "Millis-clicquot",
                "Milton",
                "Monomoscoy Island",
                "Monson Center",
                "Monument Beach",
                "Nahant",
                "Nantucket",
                "Needham",
                "New Bedford",
                "New Seabury",
                "Newburyport",
                "Newton",
                "North Adams",
                "North Amherst",
                "North Attleborough Center",
                "North Brookfield",
                "North Eastham",
                "North Falmouth",
                "North Lakeville",
                "North Pembroke",
                "North Plymouth",
                "North Scituate",
                "North Seekonk",
                "North Westport",
                "Northampton",
                "Northborough",
                "Northfield",
                "Northwest Harwich",
                "Norton Center",
                "Norwood",
                "Ocean Bluff-brant Rock",
                "Ocean Grove",
                "Onset",
                "Orange",
                "Orleans",
                "Oxford",
                "Palmer",
                "Peabody",
                "Pepperell",
                "Pinehurst",
                "Pittsfield",
                "Plymouth",
                "Pocasset",
                "Popponesset",
                "Popponesset Island",
                "Provincetown",
                "Quincy",
                "Randolph",
                "Raynham Center",
                "Reading",
                "Revere",
                "Rockport",
                "Rowley",
                "Rutland",
                "Sagamore",
                "Salem",
                "Salisbury",
                "Sandwich",
                "Saugus",
                "Scituate",
                "Seabrook",
                "Seconsett Island",
                "Sharon",
                "Shelburne Falls",
                "Shirley",
                "Smith Mills",
                "Somerset",
                "Somerville",
                "South Amherst",
                "South Ashburnham",
                "South Deerfield",
                "South Dennis",
                "South Duxbury",
                "South Lancaster",
                "South Yarmouth",
                "Southbridge",
                "Spencer",
                "Springfield",
                "Stoneham",
                "Sturbridge",
                "Swampscott",
                "Taunton",
                "Teaticket",
                "Three Rivers",
                "Topsfield",
                "Townsend",
                "Turners Falls",
                "Upton-west Upton",
                "Vineyard Haven",
                "Wakefield",
                "Walpole",
                "Waltham",
                "Ware",
                "Wareham Center",
                "Warren",
                "Watertown",
                "Webster",
                "Wellesley",
                "West Brookfield",
                "West Chatham",
                "West Concord",
                "West Dennis",
                "West Falmouth",
                "West Springfield",
                "West Wareham",
                "West Yarmouth",
                "Westborough",
                "Westfield",
                "Weweantic",
                "Weymouth",
                "White Island Shores",
                "Whitinsville",
                "Wilbraham",
                "Williamstown",
                "Wilmington",
                "Winchendon",
                "Winchester",
                "Winthrop",
                "Woburn",
                "Woods Hole",
                "Worcester",
                "Yarmouth Port"
            ],
            "Michigan": [
                "Addison",
                "Adrian",
                "Ahmeek",
                "Akron",
                "Alanson",
                "Albion",
                "Algonac",
                "Allegan",
                "Allen",
                "Allen Park",
                "Allendale",
                "Alma",
                "Almont",
                "Alpena",
                "Alpha",
                "Ann Arbor",
                "Applegate",
                "Argentine",
                "Armada",
                "Ashley",
                "Athens",
                "Atlanta",
                "Au Gres",
                "Au Sable",
                "Auburn",
                "Auburn Hills",
                "Augusta",
                "Bad Axe",
                "Baldwin",
                "Bancroft",
                "Bangor",
                "Baraga",
                "Barnes Lake-millers Lake",
                "Baroda",
                "Barryton",
                "Barton Hills",
                "Battle Creek",
                "Bay City",
                "Beal City",
                "Bear Lake",
                "Beaverton",
                "Beecher",
                "Beechwood",
                "Belding",
                "Bellaire",
                "Belleville",
                "Bellevue",
                "Benton Harbor",
                "Benton Heights",
                "Benzonia",
                "Berkley",
                "Berrien Springs",
                "Bessemer",
                "Beulah",
                "Beverly Hills",
                "Big Bay",
                "Big Rapids",
                "Bingham Farms",
                "Birch Run",
                "Birmingham",
                "Blissfield",
                "Bloomfield Hills",
                "Bloomfield Township",
                "Bloomingdale",
                "Boyne City",
                "Boyne Falls",
                "Breckenridge",
                "Breedsville",
                "Bridgeport",
                "Bridgman",
                "Brighton",
                "Britton",
                "Bronson",
                "Brooklyn",
                "Brown City",
                "Brownlee Park",
                "Buchanan",
                "Buckley",
                "Buena Vista",
                "Burlington",
                "Burr Oak",
                "Burt",
                "Burton",
                "Byron",
                "Byron Center",
                "Cadillac",
                "Caledonia",
                "Calumet",
                "Camden",
                "Canada Creek Ranch",
                "Canadian Lakes",
                "Canton",
                "Capac",
                "Carleton",
                "Carney",
                "Caro",
                "Carrollton",
                "Carson City",
                "Carsonville",
                "Caseville",
                "Casnovia",
                "Caspian",
                "Cass City",
                "Cassopolis",
                "Cedar Springs",
                "Cement City",
                "Center Line",
                "Central Lake",
                "Centreville",
                "Charlevoix",
                "Charlotte",
                "Chatham",
                "Cheboygan",
                "Chelsea",
                "Chesaning",
                "Clare",
                "Clarksville",
                "Clawson",
                "Clayton",
                "Clifford",
                "Climax",
                "Clinton",
                "Clio",
                "Coldwater",
                "Coleman",
                "Coloma",
                "Colon",
                "Columbiaville",
                "Comstock Northwest",
                "Comstock Park",
                "Concord",
                "Constantine",
                "Coopersville",
                "Copemish",
                "Copper City",
                "Corunna",
                "Croswell",
                "Crystal Falls",
                "Custer",
                "Cutlerville",
                "Daggett",
                "Dansville",
                "Davison",
                "De Tour Village",
                "De Witt",
                "Dearborn",
                "Dearborn Heights",
                "Decatur",
                "Deckerville",
                "Deerfield",
                "Detroit",
                "Detroit Beach",
                "Dexter",
                "Dimondale",
                "Douglas",
                "Dowagiac",
                "Dryden",
                "Dundee",
                "Durand",
                "Eagle",
                "East Grand Rapids",
                "East Jordan",
                "East Lansing",
                "East Tawas",
                "Eastlake",
                "Eastpointe",
                "Eastwood",
                "Eaton Rapids",
                "Eau Claire",
                "Ecorse",
                "Edgemont Park",
                "Edmore",
                "Edwardsburg",
                "Elberta",
                "Elk Rapids",
                "Elkton",
                "Ellsworth",
                "Elsie",
                "Emmett",
                "Empire",
                "Escanaba",
                "Essexville",
                "Estral Beach",
                "Evart",
                "Fair Plain",
                "Fairgrove",
                "Farmington",
                "Farmington Hills",
                "Farwell",
                "Fennville",
                "Fenton",
                "Ferndale",
                "Ferrysburg",
                "Fife Lake",
                "Flat Rock",
                "Flint",
                "Flushing",
                "Forest Hills",
                "Forestville",
                "Fountain",
                "Fowler",
                "Fowlerville",
                "Frankenmuth",
                "Frankfort",
                "Franklin",
                "Fraser",
                "Free Soil",
                "Freeland",
                "Freeport",
                "Fremont",
                "Fruitport",
                "Gaastra",
                "Gagetown",
                "Gaines",
                "Galesburg",
                "Galien",
                "Garden",
                "Garden City",
                "Gaylord",
                "Gibraltar",
                "Gladstone",
                "Gladwin",
                "Gobles",
                "Goodrich",
                "Grand Beach",
                "Grand Blanc",
                "Grand Haven",
                "Grand Ledge",
                "Grand Rapids",
                "Grandville",
                "Grant",
                "Grass Lake",
                "Grayling",
                "Greater Galesburg",
                "Greenville",
                "Greilickville",
                "Grosse Ile",
                "Grosse Pointe",
                "Grosse Pointe Farms",
                "Grosse Pointe Park",
                "Grosse Pointe Shores",
                "Grosse Pointe Woods",
                "Gwinn",
                "Hamtramck",
                "Hancock",
                "Hanover",
                "Harbor Beach",
                "Harbor Springs",
                "Harper Woods",
                "Harrietta",
                "Harrison",
                "Harrisville",
                "Hart",
                "Hartford",
                "Harvey",
                "Haslett",
                "Hastings",
                "Hazel Park",
                "Hemlock",
                "Hersey",
                "Hesperia",
                "Highland Park",
                "Hillman",
                "Hillsdale",
                "Holland",
                "Holly",
                "Holt",
                "Homer",
                "Honor",
                "Hopkins",
                "Houghton",
                "Houghton Lake",
                "Howard City",
                "Howell",
                "Hubbard Lake",
                "Hubbardston",
                "Hubbell",
                "Hudson",
                "Hudsonville",
                "Huntington Woods",
                "Imlay City",
                "Indian River",
                "Inkster",
                "Ionia",
                "Iron Mountain",
                "Iron River",
                "Ironwood",
                "Ishpeming",
                "Ithaca",
                "Jackson",
                "Jenison",
                "Jonesville",
                "K. I. Sawyer Afb",
                "Kalamazoo",
                "Kaleva",
                "Kalkaska",
                "Keego Harbor",
                "Kent City",
                "Kentwood",
                "Kinde",
                "Kingsford",
                "Kingsley",
                "Kingston",
                "L Anse",
                "Laingsburg",
                "Lake Angelus",
                "Lake Ann",
                "Lake City",
                "Lake Fenton",
                "Lake Linden",
                "Lake Michigan Beach",
                "Lake Odessa",
                "Lake Orion",
                "Lakeview",
                "Lakewood Club",
                "Lambertville",
                "Lansing",
                "Lapeer",
                "Lathrup Village",
                "Laurium",
                "Lawrence",
                "Lawton",
                "Le Roy",
                "Lennon",
                "Leonard",
                "Leslie",
                "Level Park-oak Park",
                "Lewiston",
                "Lexington",
                "Lincoln",
                "Lincoln Park",
                "Linden",
                "Litchfield",
                "Livonia",
                "Lost Lake Woods",
                "Lowell",
                "Ludington",
                "Luna Pier",
                "Luther",
                "Lyons",
                "Mackinac Island",
                "Mackinaw City",
                "Madison Heights",
                "Mancelona",
                "Manchester",
                "Manistee",
                "Manistique",
                "Manitou Beach-devils Lake",
                "Manton",
                "Maple Rapids",
                "Marcellus",
                "Marine City",
                "Marion",
                "Marlette",
                "Marquette",
                "Marshall",
                "Martin",
                "Marysville",
                "Mason",
                "Mattawan",
                "Maybee",
                "Mayville",
                "Mcbain",
                "Mcbride",
                "Mecosta",
                "Melvin",
                "Melvindale",
                "Memphis",
                "Mendon",
                "Menominee",
                "Merrill",
                "Mesick",
                "Metamora",
                "Michiana",
                "Michigamme",
                "Michigan Center",
                "Middletown",
                "Middleville",
                "Midland",
                "Milan",
                "Milford",
                "Millersburg",
                "Millington",
                "Minden City",
                "Mineral Hills",
                "Mio",
                "Monroe",
                "Montague",
                "Montgomery",
                "Montrose",
                "Morenci",
                "Morley",
                "Morrice",
                "Mount Clemens",
                "Mount Morris",
                "Mount Pleasant",
                "Muir",
                "Mulliken",
                "Munising",
                "Muskegon",
                "Muskegon Heights",
                "Napoleon",
                "Nashville",
                "Negaunee",
                "New Baltimore",
                "New Buffalo",
                "New Era",
                "New Haven",
                "New Lothrop",
                "Newaygo",
                "Newberry",
                "Niles",
                "North Adams",
                "North Branch",
                "North Muskegon",
                "Northport",
                "Northview",
                "Northville",
                "Norton Shores",
                "Norway",
                "Novi",
                "Oak Park",
                "Oakley",
                "Okemos",
                "Olivet",
                "Omer",
                "Onaway",
                "Onekama",
                "Onsted",
                "Ontonagon",
                "Orchard Lake Village",
                "Ortonville",
                "Oscoda",
                "Ossineke",
                "Otisville",
                "Otsego",
                "Otter Lake",
                "Ovid",
                "Owendale",
                "Owosso",
                "Oxford",
                "Palmer",
                "Parchment",
                "Parma",
                "Paw Paw",
                "Paw Paw Lake",
                "Pearl Beach",
                "Peck",
                "Pellston",
                "Pentwater",
                "Perrinton",
                "Perry",
                "Petersburg",
                "Petoskey",
                "Pewamo",
                "Pierson",
                "Pigeon",
                "Pinckney",
                "Pinconning",
                "Plainwell",
                "Pleasant Ridge",
                "Plymouth",
                "Plymouth Township",
                "Pontiac",
                "Port Austin",
                "Port Hope",
                "Port Huron",
                "Port Sanilac",
                "Portage",
                "Portland",
                "Posen",
                "Potterville",
                "Powers",
                "Prescott",
                "Prudenville",
                "Quincy",
                "Quinnesec",
                "Ravenna",
                "Reading",
                "Redford",
                "Reed City",
                "Reese",
                "Republic",
                "Richland",
                "Richmond",
                "River Rouge",
                "Riverview",
                "Robin Glen-indiantown",
                "Rochester",
                "Rochester Hills",
                "Rockford",
                "Rockwood",
                "Rogers City",
                "Romeo",
                "Romulus",
                "Roosevelt Park",
                "Roscommon",
                "Rose City",
                "Rosebush",
                "Roseville",
                "Rothbury",
                "Royal Oak",
                "Saginaw",
                "Saginaw Township North",
                "Saginaw Township South",
                "Saline",
                "Sand Lake",
                "Sandusky",
                "Sanford",
                "Saranac",
                "Saugatuck",
                "Sault Ste. Marie",
                "Schoolcraft",
                "Scottville",
                "Sebewaing",
                "Shelby",
                "Shepherd",
                "Sheridan",
                "Sherwood",
                "Shields",
                "Shoreham",
                "Shorewood-tower Hills-harbert",
                "Skidway Lake",
                "South Gull Lake",
                "South Haven",
                "South Lyon",
                "South Monroe",
                "South Range",
                "South Rockwood",
                "Southfield",
                "Southgate",
                "Sparta",
                "Spring Arbor",
                "Spring Lake",
                "Springfield",
                "Springport",
                "St. Charles",
                "St. Clair",
                "St. Clair Shores",
                "St. Helen",
                "St. Ignace",
                "St. Johns",
                "St. Joseph",
                "St. Louis",
                "Stambaugh",
                "Standish",
                "Stanton",
                "Stanwood",
                "Stephenson",
                "Sterling",
                "Sterling Heights",
                "Stevensville",
                "Stockbridge",
                "Stony Point",
                "Sturgis",
                "Sunfield",
                "Suttons Bay",
                "Swartz Creek",
                "Sylvan Lake",
                "Tawas City",
                "Taylor",
                "Tecumseh",
                "Tekonsha",
                "Temperance",
                "Thompsonville",
                "Three Oaks",
                "Three Rivers",
                "Traverse City",
                "Trenton",
                "Trowbridge Park",
                "Troy",
                "Turner",
                "Tustin",
                "Twin Lake",
                "Twining",
                "Ubly",
                "Union City",
                "Unionville",
                "Utica",
                "Vandalia",
                "Vanderbilt",
                "Vandercook Lake",
                "Vassar",
                "Vermontville",
                "Vernon",
                "Vicksburg",
                "Village Of Clarkston",
                "Village Of Lake Isabella",
                "Wakefield",
                "Waldron",
                "Walker",
                "Walkerville",
                "Walled Lake",
                "Warren",
                "Waterford",
                "Watervliet",
                "Waverly",
                "Wayland",
                "Wayne",
                "Webberville",
                "Weidman",
                "West Bloomfield Township",
                "West Branch",
                "West Ishpeming",
                "West Monroe",
                "Westland",
                "Westphalia",
                "Westwood",
                "White Cloud",
                "White Pigeon",
                "Whitehall",
                "Whitmore Lake",
                "Whittemore",
                "Williamston",
                "Wixom",
                "Wolf Lake",
                "Wolverine",
                "Wolverine Lake",
                "Woodhaven",
                "Woodland",
                "Woodland Beach",
                "Wyandotte",
                "Wyoming",
                "Yale",
                "Ypsilanti",
                "Zeeland",
                "Zilwaukee"
            ],
            "Minnesota": [
                "Ada",
                "Adams",
                "Adrian",
                "Afton",
                "Aitkin",
                "Akeley",
                "Albany",
                "Albert Lea",
                "Alberta",
                "Albertville",
                "Alden",
                "Aldrich",
                "Alexandria",
                "Alpha",
                "Altura",
                "Alvarado",
                "Amboy",
                "Andover",
                "Annandale",
                "Anoka",
                "Apple Valley",
                "Appleton",
                "Arco",
                "Arden Hills",
                "Argyle",
                "Arlington",
                "Arnold",
                "Ashby",
                "Askov",
                "Atwater",
                "Audubon",
                "Aurora",
                "Austin",
                "Avoca",
                "Avon",
                "Babbitt",
                "Backus",
                "Badger",
                "Bagley",
                "Balaton",
                "Barnesville",
                "Barnum",
                "Barrett",
                "Barry",
                "Battle Lake",
                "Baudette",
                "Baxter",
                "Bayport",
                "Beardsley",
                "Beaver Bay",
                "Beaver Creek",
                "Becker",
                "Bejou",
                "Belgrade",
                "Belle Plaine",
                "Bellechester",
                "Bellingham",
                "Beltrami",
                "Belview",
                "Bemidji",
                "Bena",
                "Benson",
                "Bertha",
                "Bethel",
                "Big Falls",
                "Big Lake",
                "Bigelow",
                "Bigfork",
                "Bingham Lake",
                "Birchwood Village",
                "Bird Island",
                "Biscay",
                "Biwabik",
                "Blackduck",
                "Blaine",
                "Blomkest",
                "Blooming Prairie",
                "Bloomington",
                "Blue Earth",
                "Bluffton",
                "Bock",
                "Borup",
                "Bovey",
                "Bowlus",
                "Boy River",
                "Boyd",
                "Braham",
                "Brainerd",
                "Brandon",
                "Breckenridge",
                "Breezy Point",
                "Brewster",
                "Bricelyn",
                "Brook Park",
                "Brooklyn Center",
                "Brooklyn Park",
                "Brooks",
                "Brookston",
                "Brooten",
                "Browerville",
                "Browns Valley",
                "Brownsdale",
                "Brownsville",
                "Brownton",
                "Bruno",
                "Buckman",
                "Buffalo",
                "Buffalo Lake",
                "Buhl",
                "Burnsville",
                "Burtrum",
                "Butterfield",
                "Byron",
                "Caledonia",
                "Callaway",
                "Calumet",
                "Cambridge",
                "Campbell",
                "Canby",
                "Cannon Falls",
                "Canton",
                "Carlos",
                "Carlton",
                "Carver",
                "Cass Lake",
                "Cedar Mills",
                "Center City",
                "Centerville",
                "Ceylon",
                "Champlin",
                "Chandler",
                "Chanhassen",
                "Chaska",
                "Chatfield",
                "Chickamaw Beach",
                "Chisago City",
                "Chisholm",
                "Chokio",
                "Circle Pines",
                "Clara City",
                "Claremont",
                "Clarissa",
                "Clarkfield",
                "Clarks Grove",
                "Clear Lake",
                "Clearbrook",
                "Clearwater",
                "Clements",
                "Cleveland",
                "Climax",
                "Clinton",
                "Clitherall",
                "Clontarf",
                "Cloquet",
                "Coates",
                "Cobden",
                "Cohasset",
                "Cokato",
                "Cold Spring",
                "Coleraine",
                "Cologne",
                "Columbia Heights",
                "Comfrey",
                "Comstock",
                "Conger",
                "Cook",
                "Coon Rapids",
                "Corcoran",
                "Correll",
                "Cosmos",
                "Cottage Grove",
                "Cottonwood",
                "Courtland",
                "Cromwell",
                "Crookston",
                "Crosby",
                "Crosslake",
                "Crystal",
                "Currie",
                "Cuyuna",
                "Cyrus",
                "Dakota",
                "Dalton",
                "Danube",
                "Danvers",
                "Darfur",
                "Darwin",
                "Dassel",
                "Dawson",
                "Dayton",
                "De Graff",
                "Deephaven",
                "Deer Creek",
                "Deer River",
                "Deerwood",
                "Delano",
                "Delavan",
                "Delhi",
                "Dellwood",
                "Denham",
                "Dennison",
                "Dent",
                "Detroit Lakes",
                "Dexter",
                "Dilworth",
                "Dodge Center",
                "Donaldson",
                "Donnelly",
                "Doran",
                "Dover",
                "Dovray",
                "Duluth",
                "Dumont",
                "Dundas",
                "Dundee",
                "Dunnell",
                "Eagan",
                "Eagle Bend",
                "Eagle Lake",
                "East Bethel",
                "East Grand Forks",
                "East Gull Lake",
                "Easton",
                "Echo",
                "Eden Prairie",
                "Eden Valley",
                "Edgerton",
                "Edina",
                "Effie",
                "Eitzen",
                "Elba",
                "Elbow Lake",
                "Elgin",
                "Elizabeth",
                "Elk River",
                "Elko",
                "Elkton",
                "Ellendale",
                "Ellsworth",
                "Elmdale",
                "Elmore",
                "Elrosa",
                "Ely",
                "Elysian",
                "Emily",
                "Emmons",
                "Erhard",
                "Erskine",
                "Evan",
                "Evansville",
                "Eveleth",
                "Excelsior",
                "Eyota",
                "Fairfax",
                "Fairmont",
                "Falcon Heights",
                "Faribault",
                "Farmington",
                "Farwell",
                "Federal Dam",
                "Felton",
                "Fergus Falls",
                "Fertile",
                "Fifty Lakes",
                "Finlayson",
                "Fisher",
                "Flensburg",
                "Floodwood",
                "Florence",
                "Foley",
                "Forada",
                "Forest Lake",
                "Foreston",
                "Fort Ripley",
                "Fosston",
                "Fountain",
                "Foxhome",
                "Franklin",
                "Frazee",
                "Freeborn",
                "Freeport",
                "Fridley",
                "Frost",
                "Fulda",
                "Funkley",
                "Garfield",
                "Garrison",
                "Garvin",
                "Gary",
                "Gaylord",
                "Gem Lake",
                "Geneva",
                "Genola",
                "Georgetown",
                "Ghent",
                "Gibbon",
                "Gilbert",
                "Gilman",
                "Glencoe",
                "Glenville",
                "Glenwood",
                "Glyndon",
                "Golden Valley",
                "Gonvick",
                "Good Thunder",
                "Goodhue",
                "Goodridge",
                "Goodview",
                "Graceville",
                "Granada",
                "Grand Marais",
                "Grand Meadow",
                "Grand Rapids",
                "Granite Falls",
                "Grant",
                "Grasston",
                "Green Isle",
                "Greenbush",
                "Greenfield",
                "Greenwald",
                "Greenwood",
                "Grey Eagle",
                "Grove City",
                "Grygla",
                "Gully",
                "Hackensack",
                "Hadley",
                "Hallock",
                "Halma",
                "Halstad",
                "Ham Lake",
                "Hamburg",
                "Hammond",
                "Hampton",
                "Hancock",
                "Hanley Falls",
                "Hanover",
                "Hanska",
                "Harding",
                "Hardwick",
                "Harmony",
                "Harris",
                "Hartland",
                "Hastings",
                "Hatfield",
                "Hawley",
                "Hayfield",
                "Hayward",
                "Hazel Run",
                "Hector",
                "Heidelberg",
                "Henderson",
                "Hendricks",
                "Hendrum",
                "Henning",
                "Henriette",
                "Herman",
                "Hermantown",
                "Heron Lake",
                "Hewitt",
                "Hibbing",
                "Hill City",
                "Hillman",
                "Hills",
                "Hilltop",
                "Hinckley",
                "Hitterdal",
                "Hoffman",
                "Hokah",
                "Holdingford",
                "Holland",
                "Hollandale",
                "Holloway",
                "Holt",
                "Hopkins",
                "Houston",
                "Howard Lake",
                "Hoyt Lakes",
                "Hugo",
                "Humboldt",
                "Hutchinson",
                "Ihlen",
                "Independence",
                "International Falls",
                "Inver Grove Heights",
                "Iona",
                "Iron Junction",
                "Ironton",
                "Isanti",
                "Isle",
                "Ivanhoe",
                "Jackson",
                "Janesville",
                "Jasper",
                "Jeffers",
                "Jenkins",
                "Johnson",
                "Jordan",
                "Kandiyohi",
                "Karlstad",
                "Kasota",
                "Kasson",
                "Keewatin",
                "Kelliher",
                "Kellogg",
                "Kennedy",
                "Kenneth",
                "Kensington",
                "Kent",
                "Kenyon",
                "Kerkhoven",
                "Kerrick",
                "Kettle River",
                "Kiester",
                "Kilkenny",
                "Kimball",
                "Kinbrae",
                "Kingston",
                "Kinney",
                "La Crescent",
                "La Prairie",
                "La Salle",
                "Lafayette",
                "Lake Benton",
                "Lake Bronson",
                "Lake City",
                "Lake Crystal",
                "Lake Elmo",
                "Lake Henry",
                "Lake Lillian",
                "Lake Park",
                "Lake Shore",
                "Lake St. Croix Beach",
                "Lake Wilson",
                "Lakefield",
                "Lakeland",
                "Lakeland Shores",
                "Lakeville",
                "Lamberton",
                "Lancaster",
                "Landfall",
                "Lanesboro",
                "Laporte",
                "Lastrup",
                "Lauderdale",
                "Le Center",
                "Le Roy",
                "Le Sueur",
                "Lengby",
                "Leonard",
                "Leonidas",
                "Leota",
                "Lester Prairie",
                "Lewiston",
                "Lewisville",
                "Lexington",
                "Lilydale",
                "Lindstrom",
                "Lino Lakes",
                "Lismore",
                "Litchfield",
                "Little Canada",
                "Little Falls",
                "Little Rock",
                "Littlefork",
                "Long Beach",
                "Long Lake",
                "Long Prairie",
                "Longville",
                "Lonsdale",
                "Loretto",
                "Louisburg",
                "Lowry",
                "Lucan",
                "Luverne",
                "Lyle",
                "Lynd",
                "Mabel",
                "Madelia",
                "Madison",
                "Madison Lake",
                "Magnolia",
                "Mahnomen",
                "Mahtomedi",
                "Manchester",
                "Manhattan Beach",
                "Mankato",
                "Mantorville",
                "Maple Grove",
                "Maple Lake",
                "Maple Plain",
                "Mapleton",
                "Mapleview",
                "Maplewood",
                "Marble",
                "Marietta",
                "Marine On St. Croix",
                "Marshall",
                "Mayer",
                "Maynard",
                "Mazeppa",
                "Mcgrath",
                "Mcgregor",
                "Mcintosh",
                "Mckinley",
                "Meadowlands",
                "Medford",
                "Medicine Lake",
                "Medina",
                "Meire Grove",
                "Melrose",
                "Menahga",
                "Mendota",
                "Mendota Heights",
                "Mentor",
                "Middle River",
                "Miesville",
                "Milaca",
                "Milan",
                "Millerville",
                "Millville",
                "Milroy",
                "Miltona",
                "Minneapolis",
                "Minneiska",
                "Minneota",
                "Minnesota City",
                "Minnesota Lake",
                "Minnetonka",
                "Minnetonka Beach",
                "Minnetrista",
                "Mizpah",
                "Montevideo",
                "Montgomery",
                "Monticello",
                "Montrose",
                "Moorhead",
                "Moose Lake",
                "Mora",
                "Morgan",
                "Morris",
                "Morristown",
                "Morton",
                "Motley",
                "Mound",
                "Mounds View",
                "Mountain Iron",
                "Mountain Lake",
                "Murdock",
                "Myrtle",
                "Nashua",
                "Nashwauk",
                "Nassau",
                "Naytahwaush",
                "Nelson",
                "Nerstrand",
                "Nevis",
                "New Auburn",
                "New Brighton",
                "New Germany",
                "New Hope",
                "New London",
                "New Market",
                "New Munich",
                "New Prague",
                "New Richland",
                "New Trier",
                "New Ulm",
                "New York Mills",
                "Newfolden",
                "Newport",
                "Nicollet",
                "Nielsville",
                "Nimrod",
                "Nisswa",
                "Norcross",
                "North Branch",
                "North Mankato",
                "North Oaks",
                "North St. Paul",
                "Northfield",
                "Northome",
                "Northrop",
                "Norwood Young America",
                "Oak Grove",
                "Oak Park Heights",
                "Oakdale",
                "Oakport",
                "Odessa",
                "Odin",
                "Ogema",
                "Ogilvie",
                "Okabena",
                "Oklee",
                "Olivia",
                "Onamia",
                "Ormsby",
                "Orono",
                "Oronoco",
                "Orr",
                "Ortonville",
                "Osakis",
                "Oslo",
                "Osseo",
                "Ostrander",
                "Otsego",
                "Ottertail",
                "Owatonna",
                "Palisade",
                "Park Rapids",
                "Parkers Prairie",
                "Paynesville",
                "Pease",
                "Pelican Rapids",
                "Pemberton",
                "Pennock",
                "Pequot Lakes",
                "Perham",
                "Perley",
                "Peterson",
                "Pierz",
                "Pillager",
                "Pine City",
                "Pine Island",
                "Pine Point",
                "Pine River",
                "Pine Springs",
                "Pipestone",
                "Plainview",
                "Plato",
                "Pleasant Lake",
                "Plummer",
                "Plymouth",
                "Ponemah",
                "Porter",
                "Preston",
                "Princeton",
                "Prinsburg",
                "Prior Lake",
                "Proctor",
                "Quamba",
                "Racine",
                "Ramsey",
                "Randall",
                "Randolph",
                "Ranier",
                "Raymond",
                "Red Lake",
                "Red Lake Falls",
                "Red Wing",
                "Redby",
                "Redwood Falls",
                "Regal",
                "Remer",
                "Renville",
                "Revere",
                "Rice",
                "Rice Lake",
                "Richfield",
                "Richmond",
                "Richville",
                "Riverton",
                "Robbinsdale",
                "Rochester",
                "Rock Creek",
                "Rockford",
                "Rockville",
                "Rogers",
                "Rollingstone",
                "Ronneby",
                "Roosevelt",
                "Roscoe",
                "Rose Creek",
                "Roseau",
                "Rosemount",
                "Roseville",
                "Rothsay",
                "Round Lake",
                "Royalton",
                "Rush City",
                "Rushford",
                "Rushford Village",
                "Rushmore",
                "Russell",
                "Ruthton",
                "Rutledge",
                "Sabin",
                "Sacred Heart",
                "Sanborn",
                "Sandstone",
                "Sargeant",
                "Sartell",
                "Sauk Centre",
                "Sauk Rapids",
                "Savage",
                "Scanlon",
                "Seaforth",
                "Sebeka",
                "Sedan",
                "Shafer",
                "Shakopee",
                "Shelly",
                "Sherburn",
                "Shevlin",
                "Shoreview",
                "Shorewood",
                "Silver Bay",
                "Silver Lake",
                "Skyline",
                "Slayton",
                "Sleepy Eye",
                "Sobieski",
                "Solway",
                "South Haven",
                "South St. Paul",
                "Spicer",
                "Spring Grove",
                "Spring Hill",
                "Spring Lake Park",
                "Spring Park",
                "Spring Valley",
                "Springfield",
                "Squaw Lake",
                "St. Anthony",
                "St. Bonifacius",
                "St. Charles",
                "St. Clair",
                "St. Cloud",
                "St. Francis",
                "St. Hilaire",
                "St. James",
                "St. Joseph",
                "St. Leo",
                "St. Louis Park",
                "St. Martin",
                "St. Marys Point",
                "St. Michael",
                "St. Paul",
                "St. Paul Park",
                "St. Peter",
                "St. Rosa",
                "St. Stephen",
                "St. Vincent",
                "Stacy",
                "Staples",
                "Starbuck",
                "Steen",
                "Stephen",
                "Stewart",
                "Stewartville",
                "Stillwater",
                "Stockton",
                "Storden",
                "Strandquist",
                "Strathcona",
                "Sturgeon Lake",
                "Sunburg",
                "Sunfish Lake",
                "Swanville",
                "Taconite",
                "Tamarack",
                "Taopi",
                "Taunton",
                "Taylors Falls",
                "Tenney",
                "Tenstrike",
                "The Lakes",
                "Thief River Falls",
                "Thomson",
                "Tintah",
                "Tonka Bay",
                "Tower",
                "Tracy",
                "Trail",
                "Trimont",
                "Trommald",
                "Trosky",
                "Truman",
                "Turtle River",
                "Twin Lakes",
                "Twin Valley",
                "Two Harbors",
                "Tyler",
                "Ulen",
                "Underwood",
                "Upsala",
                "Urbank",
                "Utica",
                "Vadnais Heights",
                "Vergas",
                "Vermillion",
                "Verndale",
                "Vernon Center",
                "Vesta",
                "Victoria",
                "Viking",
                "Villard",
                "Vineland",
                "Vining",
                "Virginia",
                "Wabasha",
                "Wabasso",
                "Waconia",
                "Wadena",
                "Wahkon",
                "Waite Park",
                "Waldorf",
                "Walker",
                "Walnut Grove",
                "Walters",
                "Waltham",
                "Wanamingo",
                "Wanda",
                "Warba",
                "Warren",
                "Warroad",
                "Waseca",
                "Watertown",
                "Waterville",
                "Watkins",
                "Watson",
                "Waubun",
                "Waverly",
                "Wayzata",
                "Welcome",
                "Wells",
                "Wendell",
                "West Concord",
                "West St. Paul",
                "West Union",
                "Westbrook",
                "Westport",
                "Whalan",
                "Wheaton",
                "White Bear Lake",
                "White Earth",
                "Wilder",
                "Willernie",
                "Williams",
                "Willmar",
                "Willow River",
                "Wilmont",
                "Wilton",
                "Windom",
                "Winger",
                "Winnebago",
                "Winona",
                "Winsted",
                "Winthrop",
                "Winton",
                "Wolf Lake",
                "Wolverton",
                "Wood Lake",
                "Woodbury",
                "Woodland",
                "Woodstock",
                "Worthington",
                "Wrenshall",
                "Wright",
                "Wykoff",
                "Wyoming",
                "Zemple",
                "Zimmerman",
                "Zumbro Falls",
                "Zumbrota"
            ],
            "Mississippi": [
                "Abbeville",
                "Aberdeen",
                "Ackerman",
                "Algoma",
                "Alligator",
                "Amory",
                "Anguilla",
                "Arcola",
                "Artesia",
                "Ashland",
                "Baldwyn",
                "Bassfield",
                "Batesville",
                "Bay Springs",
                "Bay St. Louis",
                "Beaumont",
                "Beauregard",
                "Belmont",
                "Belzoni",
                "Benoit",
                "Bentonia",
                "Beulah",
                "Big Creek",
                "Big Point",
                "Biloxi",
                "Blue Mountain",
                "Blue Springs",
                "Bogue Chitto",
                "Bolton",
                "Booneville",
                "Boyle",
                "Brandon",
                "Braxton",
                "Brookhaven",
                "Brooksville",
                "Bruce",
                "Bude",
                "Burnsville",
                "Byhalia",
                "Byram",
                "Caledonia",
                "Calhoun City",
                "Canton",
                "Carrollton",
                "Carthage",
                "Cary",
                "Centreville",
                "Charleston",
                "Chunky",
                "Clarksdale",
                "Cleveland",
                "Clinton",
                "Coahoma",
                "Coffeeville",
                "Coldwater",
                "Collins",
                "Collinsville",
                "Columbia",
                "Columbus",
                "Columbus Afb",
                "Como",
                "Conehatta",
                "Corinth",
                "Courtland",
                "Crawford",
                "Crenshaw",
                "Crosby",
                "Crowder",
                "Cruger",
                "Crystal Springs",
                "D Iberville",
                "D Lo",
                "De Kalb",
                "Decatur",
                "Derma",
                "Diamondhead",
                "Doddsville",
                "Drew",
                "Duck Hill",
                "Dumas",
                "Duncan",
                "Durant",
                "Ecru",
                "Eden",
                "Edwards",
                "Ellisville",
                "Enterprise",
                "Escatawpa",
                "Ethel",
                "Eupora",
                "Falcon",
                "Falkner",
                "Farmington",
                "Fayette",
                "Flora",
                "Florence",
                "Flowood",
                "Forest",
                "French Camp",
                "Friars Point",
                "Fulton",
                "Gattman",
                "Gautier",
                "Georgetown",
                "Glen",
                "Glendora",
                "Gloster",
                "Golden",
                "Goodman",
                "Greenville",
                "Greenwood",
                "Grenada",
                "Gulf Hills",
                "Gulf Park Estates",
                "Gulfport",
                "Gunnison",
                "Guntown",
                "Hatley",
                "Hattiesburg",
                "Hazlehurst",
                "Heidelberg",
                "Helena",
                "Hernando",
                "Hickory",
                "Hickory Flat",
                "Hickory Hills",
                "Hollandale",
                "Holly Springs",
                "Horn Lake",
                "Houston",
                "Hurley",
                "Indianola",
                "Inverness",
                "Isola",
                "Itta Bena",
                "Iuka",
                "Jackson",
                "Jonestown",
                "Jumpertown",
                "Kilmichael",
                "Kiln",
                "Kosciusko",
                "Kossuth",
                "Lake",
                "Lambert",
                "Latimer",
                "Laurel",
                "Leakesville",
                "Learned",
                "Leland",
                "Lena",
                "Lexington",
                "Liberty",
                "Long Beach",
                "Louin",
                "Louise",
                "Louisville",
                "Lucedale",
                "Lula",
                "Lumberton",
                "Lyman",
                "Lynchburg",
                "Lyon",
                "Maben",
                "Macon",
                "Madison",
                "Magee",
                "Magnolia",
                "Mantachie",
                "Mantee",
                "Marietta",
                "Marion",
                "Marks",
                "Mathiston",
                "Mayersville",
                "Mccomb",
                "Mccool",
                "Mclain",
                "Meadville",
                "Memphis",
                "Mendenhall",
                "Meridian",
                "Meridian Station",
                "Merigold",
                "Metcalfe",
                "Mize",
                "Monticello",
                "Montrose",
                "Moorhead",
                "Morgan City",
                "Morton",
                "Moss Point",
                "Mound Bayou",
                "Mount Olive",
                "Myrtle",
                "Natchez",
                "Nellieburg",
                "Nettleton",
                "New Albany",
                "New Augusta",
                "New Hebron",
                "New Hope",
                "New Houlka",
                "Newton",
                "North Carrollton",
                "North Tunica",
                "Noxapater",
                "Oakland",
                "Ocean Springs",
                "Okolona",
                "Olive Branch",
                "Osyka",
                "Oxford",
                "Pace",
                "Pachuta",
                "Paden",
                "Pascagoula",
                "Pass Christian",
                "Pearl",
                "Pearl River",
                "Pearlington",
                "Pelahatchie",
                "Petal",
                "Philadelphia",
                "Picayune",
                "Pickens",
                "Pittsboro",
                "Plantersville",
                "Polkville",
                "Pontotoc",
                "Pope",
                "Poplarville",
                "Port Gibson",
                "Potts Camp",
                "Prentiss",
                "Puckett",
                "Purvis",
                "Quitman",
                "Raleigh",
                "Raymond",
                "Redwater",
                "Renova",
                "Richland",
                "Richton",
                "Ridgeland",
                "Rienzi",
                "Ripley",
                "Rolling Fork",
                "Rosedale",
                "Roxie",
                "Ruleville",
                "Sallis",
                "Saltillo",
                "Sandersville",
                "Sardis",
                "Satartia",
                "Saucier",
                "Schlater",
                "Scooba",
                "Sebastopol",
                "Seminary",
                "Senatobia",
                "Shannon",
                "Shaw",
                "Shelby",
                "Sherman",
                "Shoreline Park",
                "Shubuta",
                "Shuqualak",
                "Sidon",
                "Silver City",
                "Silver Creek",
                "Slate Springs",
                "Sledge",
                "Smithville",
                "Snow Lake Shores",
                "Soso",
                "Southaven",
                "St. Martin",
                "Standing Pine",
                "Starkville",
                "State Line",
                "Stonewall",
                "Sturgis",
                "Summit",
                "Sumner",
                "Sumrall",
                "Sunflower",
                "Sylvarena",
                "Taylor",
                "Taylorsville",
                "Tchula",
                "Terry",
                "Thaxton",
                "Tillatoba",
                "Tishomingo",
                "Toccopola",
                "Tremont",
                "Tucker",
                "Tunica",
                "Tupelo",
                "Tutwiler",
                "Tylertown",
                "Union",
                "Utica",
                "Vaiden",
                "Vancleave",
                "Vardaman",
                "Verona",
                "Vicksburg",
                "Wade",
                "Walnut",
                "Walnut Grove",
                "Walthall",
                "Water Valley",
                "Waveland",
                "Waynesboro",
                "Webb",
                "Weir",
                "Wesson",
                "West",
                "West Hattiesburg",
                "West Point",
                "Wiggins",
                "Winona",
                "Winstonville",
                "Woodland",
                "Woodville",
                "Yazoo City"
            ],
            "Missouri": [
                "Adrian",
                "Advance",
                "Affton",
                "Agency",
                "Airport Drive",
                "Alba",
                "Albany",
                "Aldrich",
                "Alexandria",
                "Allendale",
                "Allenville",
                "Alma",
                "Altamont",
                "Altenburg",
                "Alton",
                "Amazonia",
                "Amity",
                "Amoret",
                "Amsterdam",
                "Anderson",
                "Annada",
                "Annapolis",
                "Anniston",
                "Appleton City",
                "Arbela",
                "Arbyrd",
                "Arcadia",
                "Archie",
                "Arcola",
                "Argyle",
                "Arkoe",
                "Armstrong",
                "Arnold",
                "Arrow Point",
                "Arrow Rock",
                "Asbury",
                "Ash Grove",
                "Ashburn",
                "Ashland",
                "Atlanta",
                "Augusta",
                "Aullville",
                "Aurora",
                "Auxvasse",
                "Ava",
                "Avilla",
                "Avondale",
                "Bagnell",
                "Baker",
                "Bakersfield",
                "Baldwin Park",
                "Ballwin",
                "Baring",
                "Barnard",
                "Barnett",
                "Barnhart",
                "Bates City",
                "Battlefield",
                "Bel-nor",
                "Bel-ridge",
                "Bell City",
                "Bella Villa",
                "Belle",
                "Bellefontaine Neighbors",
                "Bellerive",
                "Bellflower",
                "Belton",
                "Benton",
                "Benton City",
                "Berger",
                "Berkeley",
                "Bernie",
                "Bertrand",
                "Bethany",
                "Bethel",
                "Beverly Hills",
                "Bevier",
                "Biehle",
                "Big Lake",
                "Bigelow",
                "Billings",
                "Birch Tree",
                "Birmingham",
                "Bismarck",
                "Black Jack",
                "Blackburn",
                "Blackwater",
                "Blairstown",
                "Bland",
                "Blodgett",
                "Bloomfield",
                "Bloomsdale",
                "Blue Eye",
                "Blue Springs",
                "Blythedale",
                "Bogard",
                "Bolckow",
                "Bolivar",
                "Bonne Terre",
                "Boonville",
                "Bosworth",
                "Bourbon",
                "Bowling Green",
                "Bragg City",
                "Brandsville",
                "Branson",
                "Branson West",
                "Brashear",
                "Braymer",
                "Breckenridge",
                "Breckenridge Hills",
                "Brentwood",
                "Bridgeton",
                "Brimson",
                "Bronaugh",
                "Brookfield",
                "Brookline",
                "Brooklyn Heights",
                "Browning",
                "Brownington",
                "Brumley",
                "Brunswick",
                "Bucklin",
                "Buckner",
                "Buffalo",
                "Bull Creek",
                "Bunceton",
                "Bunker",
                "Burgess",
                "Burlington Junction",
                "Butler",
                "Butterfield",
                "Byrnes Mill",
                "Cabool",
                "Cainsville",
                "Cairo",
                "Caledonia",
                "Calhoun",
                "California",
                "Callao",
                "Calverton Park",
                "Camden",
                "Camden Point",
                "Camdenton",
                "Cameron",
                "Campbell",
                "Canalou",
                "Canton",
                "Cape Girardeau",
                "Cardwell",
                "Carl Junction",
                "Carrollton",
                "Carterville",
                "Carthage",
                "Caruthersville",
                "Carytown",
                "Cassville",
                "Castle Point",
                "Catron",
                "Cave",
                "Cedar Hill",
                "Cedar Hill Lakes",
                "Center",
                "Centertown",
                "Centerview",
                "Centerville",
                "Centralia",
                "Chaffee",
                "Chain Of Rocks",
                "Chain-o-lakes",
                "Chamois",
                "Champ",
                "Charlack",
                "Charleston",
                "Chesterfield",
                "Chilhowee",
                "Chillicothe",
                "Chula",
                "Clarence",
                "Clark",
                "Clarksburg",
                "Clarksdale",
                "Clarkson Valley",
                "Clarksville",
                "Clarkton",
                "Claycomo",
                "Clayton",
                "Clearmont",
                "Cleveland",
                "Clever",
                "Cliff Village",
                "Clifton Hill",
                "Climax Springs",
                "Clinton",
                "Clyde",
                "Cobalt",
                "Coffey",
                "Cole Camp",
                "Collins",
                "Columbia",
                "Commerce",
                "Conception Junction",
                "Concord",
                "Concordia",
                "Coney Island",
                "Conway",
                "Cool Valley",
                "Cooter",
                "Corder",
                "Corning",
                "Cosby",
                "Cottleville",
                "Country Club",
                "Country Club Hills",
                "Country Life Acres",
                "Cowgill",
                "Craig",
                "Crane",
                "Creighton",
                "Crestwood",
                "Creve Coeur",
                "Crocker",
                "Cross Timbers",
                "Crystal City",
                "Crystal Lake Park",
                "Crystal Lakes",
                "Cuba",
                "Curryville",
                "Dadeville",
                "Dalton",
                "Dardenne Prairie",
                "Darlington",
                "De Kalb",
                "De Soto",
                "De Witt",
                "Dearborn",
                "Deepwater",
                "Deerfield",
                "Dellwood",
                "Delta",
                "Dennis Acres",
                "Denver",
                "Des Arc",
                "Des Peres",
                "Desloge",
                "Dexter",
                "Diamond",
                "Diehlstadt",
                "Diggins",
                "Dixon",
                "Doniphan",
                "Doolittle",
                "Dover",
                "Downing",
                "Drexel",
                "Dudley",
                "Duenweg",
                "Duquesne",
                "Dutchtown",
                "Eagleville",
                "East Lynne",
                "East Prairie",
                "Easton",
                "Edgar Springs",
                "Edgerton",
                "Edina",
                "Edmundson",
                "El Dorado Springs",
                "Eldon",
                "Ellington",
                "Ellisville",
                "Ellsinore",
                "Elmer",
                "Elmira",
                "Elmo",
                "Elsberry",
                "Emerald Beach",
                "Eminence",
                "Emma",
                "Eolia",
                "Essex",
                "Ethel",
                "Eureka",
                "Everton",
                "Ewing",
                "Excelsior Estates",
                "Excelsior Springs",
                "Exeter",
                "Fair Grove",
                "Fair Play",
                "Fairfax",
                "Fairview",
                "Farber",
                "Farley",
                "Farmington",
                "Fayette",
                "Fenton",
                "Ferguson",
                "Ferrelview",
                "Festus",
                "Fidelity",
                "Fillmore",
                "Fisk",
                "Fleming",
                "Flemington",
                "Flint Hill",
                "Flordell Hills",
                "Florida",
                "Florissant",
                "Foley",
                "Fordland",
                "Forest City",
                "Foristell",
                "Forsyth",
                "Fort Leonard Wood",
                "Fortescue",
                "Foster",
                "Fountain N Lakes",
                "Frankford",
                "Franklin",
                "Fredericktown",
                "Freeburg",
                "Freeman",
                "Freistatt",
                "Fremont Hills",
                "Frohna",
                "Frontenac",
                "Fulton",
                "Gainesville",
                "Galena",
                "Gallatin",
                "Galt",
                "Garden City",
                "Gasconade",
                "Gentry",
                "Gerald",
                "Gerster",
                "Gibbs",
                "Gideon",
                "Gilliam",
                "Gilman City",
                "Gladstone",
                "Glasgow",
                "Glasgow Village",
                "Glen Allen",
                "Glen Echo Park",
                "Glenaire",
                "Glendale",
                "Glenwood",
                "Golden City",
                "Goodman",
                "Gordonville",
                "Gower",
                "Graham",
                "Grain Valley",
                "Granby",
                "Grand Falls Plaza",
                "Grand Pass",
                "Grandin",
                "Grandview",
                "Granger",
                "Grant City",
                "Grantwood Village",
                "Gravois Mills",
                "Gray Summit",
                "Green City",
                "Green Park",
                "Green Ridge",
                "Greencastle",
                "Greendale",
                "Greenfield",
                "Greentop",
                "Greenville",
                "Greenwood",
                "Guilford",
                "Gunn City",
                "Hale",
                "Halfway",
                "Hallsville",
                "Halltown",
                "Hamilton",
                "Hanley Hills",
                "Hannibal",
                "Hardin",
                "Harris",
                "Harrisburg",
                "Harrisonville",
                "Hartsburg",
                "Hartville",
                "Hartwell",
                "Harwood",
                "Hawk Point",
                "Hayti",
                "Hayti Heights",
                "Hayward",
                "Haywood City",
                "Hazelwood",
                "Henrietta",
                "Herculaneum",
                "Hermann",
                "Hermitage",
                "Higbee",
                "Higginsville",
                "High Hill",
                "High Ridge",
                "Highlandville",
                "Hillsboro",
                "Hillsdale",
                "Hoberg",
                "Holcomb",
                "Holden",
                "Holland",
                "Holliday",
                "Hollister",
                "Holt",
                "Holts Summit",
                "Homestead",
                "Homestown",
                "Hopkins",
                "Horine",
                "Hornersville",
                "Houston",
                "Houston Lake",
                "Houstonia",
                "Howardville",
                "Hughesville",
                "Humansville",
                "Hume",
                "Humphreys",
                "Hunnewell",
                "Huntleigh",
                "Huntsville",
                "Hurdland",
                "Hurley",
                "Iatan",
                "Iberia",
                "Imperial",
                "Independence",
                "Indian Point",
                "Innsbrook",
                "Ionia",
                "Irena",
                "Iron Mountain Lake",
                "Irondale",
                "Ironton",
                "Jackson",
                "Jacksonville",
                "Jameson",
                "Jamesport",
                "Jamestown",
                "Jasper",
                "Jefferson City",
                "Jennings",
                "Jerico Springs",
                "Jonesburg",
                "Joplin",
                "Josephville",
                "Junction City",
                "Kahoka",
                "Kansas City",
                "Kearney",
                "Kelso",
                "Kennett",
                "Keytesville",
                "Kidder",
                "Kimberling City",
                "Kimmswick",
                "King City",
                "Kingdom City",
                "Kingston",
                "Kingsville",
                "Kinloch",
                "Kirksville",
                "Kirkwood",
                "Knob Noster",
                "Knox City",
                "Koshkonong",
                "La Belle",
                "La Due",
                "La Grange",
                "La Monte",
                "La Plata",
                "La Russell",
                "La Tour",
                "Laclede",
                "Laddonia",
                "Ladue",
                "Lake Annette",
                "Lake Lafayette",
                "Lake Lotawana",
                "Lake Mykee Town",
                "Lake Ozark",
                "Lake St. Louis",
                "Lake Tapawingo",
                "Lake Waukomis",
                "Lake Winnebago",
                "Lakeshire",
                "Lakeside",
                "Lamar",
                "Lamar Heights",
                "Lambert",
                "Lanagan",
                "Lancaster",
                "Laredo",
                "Lathrop",
                "Laurie",
                "Lawson",
                "Leadington",
                "Leadwood",
                "Leasburg",
                "Leawood",
                "Lebanon",
                "Lee S Summit",
                "Leeton",
                "Lemay",
                "Leonard",
                "Leslie",
                "Levasy",
                "Lewis And Clark Village",
                "Lewistown",
                "Lexington",
                "Liberal",
                "Liberty",
                "Licking",
                "Lilbourn",
                "Lincoln",
                "Linn",
                "Linn Creek",
                "Linneus",
                "Lithium",
                "Livonia",
                "Lock Springs",
                "Lockwood",
                "Lohman",
                "Loma Linda",
                "Lone Jack",
                "Longtown",
                "Louisburg",
                "Louisiana",
                "Lowry City",
                "Lucerne",
                "Ludlow",
                "Lupus",
                "Luray",
                "Mackenzie",
                "Macks Creek",
                "Macon",
                "Madison",
                "Maitland",
                "Malden",
                "Malta Bend",
                "Manchester",
                "Mansfield",
                "Maplewood",
                "Marble Hill",
                "Marceline",
                "Marionville",
                "Marlborough",
                "Marquand",
                "Marshall",
                "Marshfield",
                "Marston",
                "Marthasville",
                "Martinsburg",
                "Maryland Heights",
                "Maryville",
                "Matthews",
                "Maysville",
                "Mayview",
                "Mcbaine",
                "Mccord Bend",
                "Mcfall",
                "Mckittrick",
                "Meadville",
                "Mehlville",
                "Memphis",
                "Mendon",
                "Mercer",
                "Merriam Woods",
                "Merwin",
                "Meta",
                "Metz",
                "Mexico",
                "Miami",
                "Middletown",
                "Milan",
                "Milford",
                "Mill Spring",
                "Millard",
                "Miller",
                "Milo",
                "Mindenmines",
                "Miner",
                "Mineral Point",
                "Miramiguoa Park",
                "Missouri City",
                "Moberly",
                "Mokane",
                "Moline Acres",
                "Monett",
                "Monroe City",
                "Montgomery City",
                "Monticello",
                "Montrose",
                "Mooresville",
                "Morehouse",
                "Morley",
                "Morrison",
                "Morrisville",
                "Mosby",
                "Moscow Mills",
                "Mound City",
                "Moundville",
                "Mount Leonard",
                "Mount Moriah",
                "Mount Vernon",
                "Mountain Grove",
                "Mountain View",
                "Murphy",
                "Napoleon",
                "Naylor",
                "Neck City",
                "Neelyville",
                "Nelson",
                "Neosho",
                "Nevada",
                "New Bloomfield",
                "New Cambria",
                "New Florence",
                "New Franklin",
                "New Hampton",
                "New Haven",
                "New London",
                "New Madrid",
                "New Melle",
                "Newark",
                "Newburg",
                "Newtonia",
                "Newtown",
                "Niangua",
                "Nixa",
                "Noel",
                "Norborne",
                "Normandy",
                "North Kansas City",
                "North Lilbourn",
                "North Wardell",
                "Northmoor",
                "Northwoods",
                "Norwood",
                "Norwood Court",
                "Novelty",
                "Novinger",
                "O Fallon",
                "Oak Grove",
                "Oak Ridge",
                "Oakland",
                "Oaks",
                "Oakview",
                "Oakville",
                "Oakwood",
                "Oakwood Park",
                "Odessa",
                "Old Appleton",
                "Old Monroe",
                "Olean",
                "Olivette",
                "Olympian Village",
                "Oran",
                "Oregon",
                "Oronogo",
                "Orrick",
                "Osage Beach",
                "Osborn",
                "Osceola",
                "Osgood",
                "Otterville",
                "Overland",
                "Owensville",
                "Ozark",
                "Pacific",
                "Pagedale",
                "Palmyra",
                "Paris",
                "Park Hills",
                "Parkdale",
                "Parkville",
                "Parkway",
                "Parma",
                "Parnell",
                "Pasadena Hills",
                "Pasadena Park",
                "Pascola",
                "Passaic",
                "Pattonsburg",
                "Paynesville",
                "Peculiar",
                "Penermon",
                "Perry",
                "Perryville",
                "Pevely",
                "Phillipsburg",
                "Pickering",
                "Piedmont",
                "Pierce City",
                "Pilot Grove",
                "Pilot Knob",
                "Pine Lawn",
                "Pineville",
                "Pinhook",
                "Platte City",
                "Platte Woods",
                "Plattsburg",
                "Pleasant Hill",
                "Pleasant Hope",
                "Pleasant Valley",
                "Pocahontas",
                "Pollock",
                "Polo",
                "Poplar Bluff",
                "Portage Des Sioux",
                "Portageville",
                "Potosi",
                "Powersville",
                "Prairie Home",
                "Prathersville",
                "Preston",
                "Princeton",
                "Purcell",
                "Purdin",
                "Purdy",
                "Puxico",
                "Queen City",
                "Quitman",
                "Qulin",
                "Randolph",
                "Ravenwood",
                "Raymondville",
                "Raymore",
                "Raytown",
                "Rayville",
                "Rea",
                "Redings Mill",
                "Reeds",
                "Reeds Spring",
                "Renick",
                "Rensselaer",
                "Republic",
                "Revere",
                "Rhineland",
                "Rich Hill",
                "Richards",
                "Richland",
                "Richmond",
                "Richmond Heights",
                "Ridgely",
                "Ridgeway",
                "Risco",
                "Ritchey",
                "River Bend",
                "Riverside",
                "Riverview",
                "Rives",
                "Rocheport",
                "Rock Hill",
                "Rock Port",
                "Rockaway Beach",
                "Rockville",
                "Rogersville",
                "Rolla",
                "Roscoe",
                "Rosebud",
                "Rosendale",
                "Rothville",
                "Rush Hill",
                "Rushville",
                "Russellville",
                "Rutledge",
                "Saginaw",
                "Salem",
                "Salisbury",
                "Sappington",
                "Sarcoxie",
                "Savannah",
                "Schell City",
                "Scotsdale",
                "Scott City",
                "Sedalia",
                "Sedgewickville",
                "Seligman",
                "Senath",
                "Seneca",
                "Seymour",
                "Shelbina",
                "Shelbyville",
                "Sheldon",
                "Shell Knob",
                "Sheridan",
                "Shoal Creek Drive",
                "Shoal Creek Estates",
                "Shrewsbury",
                "Sibley",
                "Sikeston",
                "Silex",
                "Silver Creek",
                "Skidmore",
                "Slater",
                "Smithton",
                "Smithville",
                "South Gifford",
                "South Gorin",
                "South Greenfield",
                "South Lineville",
                "South West City",
                "Spanish Lake",
                "Sparta",
                "Spickard",
                "Spokane",
                "Springfield",
                "St. Ann",
                "St. Charles",
                "St. Clair",
                "St. Cloud",
                "St. Elizabeth",
                "St. George",
                "St. James",
                "St. John",
                "St. Joseph",
                "St. Louis",
                "St. Martins",
                "St. Mary",
                "St. Paul",
                "St. Peters",
                "St. Robert",
                "St. Thomas",
                "Stanberry",
                "Stark City",
                "Ste. Genevieve",
                "Steele",
                "Steelville",
                "Stella",
                "Stewartsville",
                "Stockton",
                "Stotesbury",
                "Stotts City",
                "Stoutland",
                "Stoutsville",
                "Stover",
                "Strafford",
                "Strasburg",
                "Sturgeon",
                "Sugar Creek",
                "Sullivan",
                "Summersville",
                "Sumner",
                "Sundown",
                "Sunrise Beach",
                "Sunset Hills",
                "Sweet Springs",
                "Sycamore Hills",
                "Syracuse",
                "Table Rock",
                "Tallapoosa",
                "Taneyville",
                "Taos",
                "Tarkio",
                "Tarrants",
                "Thayer",
                "Theodosia",
                "Tightwad",
                "Tina",
                "Tindall",
                "Tipton",
                "Town And Country",
                "Tracy",
                "Trenton",
                "Trimble",
                "Triplett",
                "Troy",
                "Truesdale",
                "Truxton",
                "Turney",
                "Tuscumbia",
                "Twin Bridges",
                "Twin Oaks",
                "Umber View Heights",
                "Union",
                "Union Star",
                "Unionville",
                "Unity Village",
                "University City",
                "Uplands Park",
                "Urbana",
                "Urich",
                "Utica",
                "Valley Park",
                "Van Buren",
                "Vandalia",
                "Vandiver",
                "Vanduser",
                "Velda City",
                "Velda Village Hills",
                "Verona",
                "Versailles",
                "Viburnum",
                "Vienna",
                "Villa Ridge",
                "Village Of Four Seasons",
                "Vinita Park",
                "Vinita Terrace",
                "Vista",
                "Waco",
                "Walker",
                "Walnut Grove",
                "Wardell",
                "Wardsville",
                "Warrensburg",
                "Warrenton",
                "Warsaw",
                "Warson Woods",
                "Washburn",
                "Washington",
                "Watson",
                "Waverly",
                "Wayland",
                "Waynesville",
                "Weatherby",
                "Weatherby Lake",
                "Weaubleau",
                "Webb City",
                "Webster Groves",
                "Weldon Spring",
                "Weldon Spring Heights",
                "Wellington",
                "Wellston",
                "Wellsville",
                "Wentworth",
                "Wentzville",
                "West Alton",
                "West Line",
                "West Plains",
                "Westboro",
                "Weston",
                "Westphalia",
                "Westwood",
                "Wheatland",
                "Wheaton",
                "Wheeling",
                "Whiteman Afb",
                "Whiteside",
                "Whitewater",
                "Wilbur Park",
                "Wildwood",
                "Willard",
                "Williamsville",
                "Willow Springs",
                "Wilson City",
                "Winchester",
                "Windsor",
                "Winfield",
                "Winona",
                "Winston",
                "Woods Heights",
                "Woodson Terrace",
                "Wooldridge",
                "Worth",
                "Worthington",
                "Wright City",
                "Wyaconda",
                "Wyatt",
                "Zalma"
            ],
            "Montana": [
                "Absarokee",
                "Agency",
                "Alberton",
                "Alder",
                "Amsterdam-churchill",
                "Anaconda-deer Lodge",
                "Antelope",
                "Arlee",
                "Ashland",
                "Augusta",
                "Avon",
                "Azure",
                "Bainville",
                "Baker",
                "Ballantine",
                "Basin",
                "Bearcreek",
                "Beaver Creek",
                "Belfry",
                "Belgrade",
                "Belt",
                "Big Arm",
                "Big Sandy",
                "Big Sky",
                "Big Timber",
                "Bigfork",
                "Billings",
                "Birney",
                "Black Eagle",
                "Boneau",
                "Bonner-west Riverside",
                "Boulder",
                "Box Elder",
                "Bozeman",
                "Bridger",
                "Broadus",
                "Broadview",
                "Brockton",
                "Browning",
                "Busby",
                "Butte-silver Bow",
                "Camp Three",
                "Cardwell",
                "Carter",
                "Cascade",
                "Charlo",
                "Chester",
                "Chinook",
                "Choteau",
                "Circle",
                "Clancy",
                "Clinton",
                "Clyde Park",
                "Colstrip",
                "Columbia Falls",
                "Columbus",
                "Conrad",
                "Cooke City-silver Gate",
                "Coram",
                "Corvallis",
                "Crow Agency",
                "Culbertson",
                "Custer",
                "Cut Bank",
                "Darby",
                "Dayton",
                "De Borgia",
                "Deer Lodge",
                "Denton",
                "Dillon",
                "Dixon",
                "Dodson",
                "Drummond",
                "Dutton",
                "East Glacier Park Village",
                "East Helena",
                "East Missoula",
                "Ekalaka",
                "Elliston",
                "Elmo",
                "Ennis",
                "Eureka",
                "Evaro",
                "Evergreen",
                "Fairfield",
                "Fairview",
                "Fallon",
                "Finley Point",
                "Flaxville",
                "Florence",
                "Forsyth",
                "Fort Belknap Agency",
                "Fort Benton",
                "Fort Peck",
                "Fort Shaw",
                "Fort Smith",
                "Fortine",
                "Four Corners",
                "Fox Lake",
                "Frazer",
                "Frenchtown",
                "Froid",
                "Fromberg",
                "Gardiner",
                "Garrison",
                "Geraldine",
                "Gildford",
                "Glasgow",
                "Glendive",
                "Grass Range",
                "Great Falls",
                "Greycliff",
                "Hamilton",
                "Hardin",
                "Harlem",
                "Harlowton",
                "Harrison",
                "Havre",
                "Havre North",
                "Hays",
                "Heart Butte",
                "Helena",
                "Helena Valley Northeast",
                "Helena Valley Northwest",
                "Helena Valley Southeast",
                "Helena Valley West Central",
                "Helena West Side",
                "Heron",
                "Herron",
                "Highwood",
                "Hingham",
                "Hobson",
                "Hot Springs",
                "Hungry Horse",
                "Huntley",
                "Hysham",
                "Inverness",
                "Ismay",
                "Jefferson City",
                "Jette",
                "Joliet",
                "Joplin",
                "Jordan",
                "Judith Gap",
                "Kalispell",
                "Kerr",
                "Kevin",
                "Kicking Horse",
                "Kings Point",
                "Klein",
                "Knife River",
                "Kremlin",
                "Lakeside",
                "Lame Deer",
                "Laurel",
                "Lavina",
                "Lewistown",
                "Lewistown Heights",
                "Libby",
                "Lima",
                "Lincoln",
                "Livingston",
                "Lockwood",
                "Lodge Grass",
                "Lodge Pole",
                "Lolo",
                "Loma",
                "Lonepine",
                "Malmstrom Afb",
                "Malta",
                "Manhattan",
                "Martin City",
                "Medicine Lake",
                "Melstone",
                "Miles City",
                "Missoula",
                "Montana City",
                "Moore",
                "Muddy",
                "Musselshell",
                "Nashua",
                "Neihart",
                "Niarada",
                "North Browning",
                "Noxon",
                "Old Agency",
                "Opheim",
                "Orchard Homes",
                "Outlook",
                "Ovando",
                "Pablo",
                "Paradise",
                "Park City",
                "Parker School",
                "Philipsburg",
                "Pinesdale",
                "Plains",
                "Plentywood",
                "Plevna",
                "Polson",
                "Poplar",
                "Power",
                "Pryor",
                "Radersburg",
                "Ravalli",
                "Red Lodge",
                "Reed Point",
                "Reserve",
                "Rexford",
                "Richey",
                "Riverbend",
                "Rocky Point",
                "Rollins",
                "Ronan",
                "Roundup",
                "Rudyard",
                "Ryegate",
                "Saco",
                "Saddle Butte",
                "Sangrey",
                "Scobey",
                "Seeley Lake",
                "Shelby",
                "Shepherd",
                "Sheridan",
                "Sidney",
                "Simms",
                "Somers",
                "South Browning",
                "St. Ignatius",
                "St. Marie",
                "St. Pierre",
                "St. Regis",
                "St. Xavier",
                "Stanford",
                "Starr School",
                "Stevensville",
                "Sun Prairie",
                "Sun River",
                "Sunburst",
                "Superior",
                "Terry",
                "Thompson Falls",
                "Three Forks",
                "Toston",
                "Townsend",
                "Trout Creek",
                "Troy",
                "Turtle Lake",
                "Twin Bridges",
                "Ulm",
                "Valier",
                "Vaughn",
                "Victor",
                "Virginia City",
                "Walkerville",
                "West Glendive",
                "West Havre",
                "West Yellowstone",
                "Westby",
                "White Sulphur Springs",
                "Whitefish",
                "Whitehall",
                "Wibaux",
                "Willow Creek",
                "Wilsall",
                "Winifred",
                "Winnett",
                "Winston",
                "Wisdom",
                "Wolf Point",
                "Woods Bay",
                "Worden",
                "Wye",
                "Wyola"
            ],
            "Nebraska": [
                "Abie",
                "Adams",
                "Ainsworth",
                "Albion",
                "Alda",
                "Alexandria",
                "Allen",
                "Alliance",
                "Alma",
                "Alvo",
                "Amherst",
                "Anoka",
                "Anselmo",
                "Ansley",
                "Arapahoe",
                "Arcadia",
                "Arlington",
                "Arnold",
                "Arthur",
                "Ashland",
                "Ashton",
                "Atkinson",
                "Atlanta",
                "Auburn",
                "Aurora",
                "Avoca",
                "Axtell",
                "Ayr",
                "Bancroft",
                "Barada",
                "Barneston",
                "Bartlett",
                "Bartley",
                "Bassett",
                "Battle Creek",
                "Bayard",
                "Bazile Mills",
                "Beatrice",
                "Beaver City",
                "Beaver Crossing",
                "Bee",
                "Beemer",
                "Belden",
                "Belgrade",
                "Bellevue",
                "Bellwood",
                "Belvidere",
                "Benedict",
                "Benkelman",
                "Bennet",
                "Bennington",
                "Bertrand",
                "Berwyn",
                "Big Springs",
                "Bladen",
                "Blair",
                "Bloomfield",
                "Bloomington",
                "Blue Hill",
                "Blue Springs",
                "Boys Town",
                "Bradshaw",
                "Brady",
                "Brainard",
                "Brewster",
                "Bridgeport",
                "Bristow",
                "Broadwater",
                "Brock",
                "Broken Bow",
                "Brownville",
                "Brule",
                "Bruning",
                "Bruno",
                "Brunswick",
                "Burchard",
                "Burr",
                "Burton",
                "Burwell",
                "Bushnell",
                "Butte",
                "Byron",
                "Cairo",
                "Callaway",
                "Cambridge",
                "Campbell",
                "Carleton",
                "Carroll",
                "Cedar Bluffs",
                "Cedar Creek",
                "Cedar Rapids",
                "Center",
                "Central City",
                "Ceresco",
                "Chadron",
                "Chalco",
                "Chambers",
                "Chapman",
                "Chappell",
                "Chester",
                "Cisco",
                "Clarks",
                "Clarkson",
                "Clatonia",
                "Clay Center",
                "Clearwater",
                "Clinton",
                "Cody",
                "Coleridge",
                "Colon",
                "Columbus",
                "Comstock",
                "Concord",
                "Cook",
                "Cordova",
                "Cornlea",
                "Cortland",
                "Cotesfield",
                "Cowles",
                "Cozad",
                "Crab Orchard",
                "Craig",
                "Crawford",
                "Creighton",
                "Creston",
                "Crete",
                "Crofton",
                "Crookston",
                "Culbertson",
                "Curtis",
                "Cushing",
                "Dakota City",
                "Dalton",
                "Danbury",
                "Dannebrog",
                "Davenport",
                "Davey",
                "David City",
                "Dawson",
                "Daykin",
                "De Witt",
                "Decatur",
                "Denton",
                "Deshler",
                "Deweese",
                "Diller",
                "Dix",
                "Dixon",
                "Dodge",
                "Doniphan",
                "Dorchester",
                "Douglas",
                "Du Bois",
                "Dunbar",
                "Duncan",
                "Dunning",
                "Dwight",
                "Eagle",
                "Eddyville",
                "Edgar",
                "Edison",
                "Elba",
                "Elgin",
                "Elk Creek",
                "Elkhorn",
                "Elm Creek",
                "Elmwood",
                "Elsie",
                "Elwood",
                "Elyria",
                "Emerson",
                "Emmet",
                "Endicott",
                "Ericson",
                "Eustis",
                "Ewing",
                "Exeter",
                "Fairbury",
                "Fairfield",
                "Fairmont",
                "Falls City",
                "Farnam",
                "Farwell",
                "Filley",
                "Firth",
                "Fordyce",
                "Fort Calhoun",
                "Foster",
                "Franklin",
                "Fremont",
                "Friend",
                "Fullerton",
                "Funk",
                "Gandy",
                "Garland",
                "Garrison",
                "Geneva",
                "Genoa",
                "Gering",
                "Gibbon",
                "Gilead",
                "Giltner",
                "Glenvil",
                "Goehner",
                "Gordon",
                "Gothenburg",
                "Grafton",
                "Grand Island",
                "Grant",
                "Greeley Center",
                "Greenwood",
                "Gresham",
                "Gretna",
                "Gross",
                "Guide Rock",
                "Gurley",
                "Hadar",
                "Haigler",
                "Hallam",
                "Halsey",
                "Hamlet",
                "Hampton",
                "Harbine",
                "Hardy",
                "Harrisburg",
                "Harrison",
                "Hartington",
                "Harvard",
                "Hastings",
                "Hay Springs",
                "Hayes Center",
                "Hazard",
                "Heartwell",
                "Hebron",
                "Hemingford",
                "Henderson",
                "Hendley",
                "Henry",
                "Herman",
                "Hershey",
                "Hickman",
                "Hildreth",
                "Holbrook",
                "Holdrege",
                "Holstein",
                "Homer",
                "Hooper",
                "Hordville",
                "Hoskins",
                "Howard City",
                "Howells",
                "Hubbard",
                "Hubbell",
                "Humboldt",
                "Humphrey",
                "Huntley",
                "Hyannis",
                "Imperial",
                "Indianola",
                "Inglewood",
                "Inman",
                "Ithaca",
                "Jackson",
                "Jansen",
                "Johnson",
                "Johnstown",
                "Julian",
                "Juniata",
                "Kearney",
                "Kenesaw",
                "Kennard",
                "Kilgore",
                "Kimball",
                "La Vista",
                "Lamar",
                "Laurel",
                "Lawrence",
                "Lebanon",
                "Leigh",
                "Leshara",
                "Lewellen",
                "Lewiston",
                "Lexington",
                "Liberty",
                "Lincoln",
                "Lindsay",
                "Linwood",
                "Litchfield",
                "Lodgepole",
                "Long Pine",
                "Loomis",
                "Lorton",
                "Louisville",
                "Loup City",
                "Lushton",
                "Lyman",
                "Lynch",
                "Lyons",
                "Macy",
                "Madison",
                "Madrid",
                "Magnet",
                "Malcolm",
                "Malmo",
                "Manley",
                "Marquette",
                "Martinsburg",
                "Maskell",
                "Mason City",
                "Maxwell",
                "Maywood",
                "Mccook",
                "Mccool Junction",
                "Mcgrew",
                "Mclean",
                "Mead",
                "Meadow Grove",
                "Melbeta",
                "Memphis",
                "Merna",
                "Merriman",
                "Milford",
                "Miller",
                "Milligan",
                "Minatare",
                "Minden",
                "Mitchell",
                "Monowi",
                "Monroe",
                "Moorefield",
                "Morrill",
                "Morse Bluff",
                "Mullen",
                "Murdock",
                "Murray",
                "Naper",
                "Naponee",
                "Nebraska City",
                "Nehawka",
                "Neligh",
                "Nelson",
                "Nemaha",
                "Nenzel",
                "Newcastle",
                "Newman Grove",
                "Newport",
                "Nickerson",
                "Niobrara",
                "Nora",
                "Norfolk",
                "Norman",
                "North Bend",
                "North Loup",
                "North Platte",
                "O Neill",
                "Oak",
                "Oakdale",
                "Oakland",
                "Obert",
                "Oconto",
                "Octavia",
                "Odell",
                "Offutt Afb",
                "Ogallala",
                "Ohiowa",
                "Omaha",
                "Ong",
                "Orchard",
                "Ord",
                "Orleans",
                "Osceola",
                "Oshkosh",
                "Osmond",
                "Otoe",
                "Overton",
                "Oxford",
                "Page",
                "Palisade",
                "Palmer",
                "Palmyra",
                "Panama",
                "Papillion",
                "Pawnee City",
                "Paxton",
                "Pender",
                "Peru",
                "Petersburg",
                "Phillips",
                "Pickrell",
                "Pierce",
                "Pilger",
                "Pine Ridge",
                "Plainview",
                "Platte Center",
                "Plattsmouth",
                "Pleasant Dale",
                "Pleasanton",
                "Plymouth",
                "Polk",
                "Ponca",
                "Potter",
                "Prague",
                "Preston",
                "Primrose",
                "Prosser",
                "Ragan",
                "Ralston",
                "Randolph",
                "Ravenna",
                "Raymond",
                "Red Cloud",
                "Republican City",
                "Reynolds",
                "Richland",
                "Rising City",
                "Riverdale",
                "Riverton",
                "Roca",
                "Rockville",
                "Rogers",
                "Rosalie",
                "Roseland",
                "Royal",
                "Rulo",
                "Rushville",
                "Ruskin",
                "Salem",
                "Santee",
                "Sargent",
                "Saronville",
                "Schuyler",
                "Scotia",
                "Scottsbluff",
                "Scribner",
                "Seneca",
                "Seward",
                "Shelby",
                "Shelton",
                "Shickley",
                "Sholes",
                "Shubert",
                "Sidney",
                "Silver Creek",
                "Smithfield",
                "Snyder",
                "South Bend",
                "South Sioux City",
                "Spalding",
                "Spencer",
                "Sprague",
                "Springfield",
                "Springview",
                "St. Edward",
                "St. Helena",
                "St. Paul",
                "Stamford",
                "Stanton",
                "Staplehurst",
                "Stapleton",
                "Steele City",
                "Steinauer",
                "Stella",
                "Sterling",
                "Stockham",
                "Stockville",
                "Strang",
                "Stratton",
                "Stromsburg",
                "Stuart",
                "Sumner",
                "Superior",
                "Surprise",
                "Sutherland",
                "Sutton",
                "Swanton",
                "Syracuse",
                "Table Rock",
                "Talmage",
                "Tarnov",
                "Taylor",
                "Tecumseh",
                "Tekamah",
                "Terrytown",
                "Thayer",
                "Thedford",
                "Thurston",
                "Tilden",
                "Tobias",
                "Trenton",
                "Trumbull",
                "Uehling",
                "Ulysses",
                "Unadilla",
                "Union",
                "Upland",
                "Utica",
                "Valentine",
                "Valley",
                "Valparaiso",
                "Venango",
                "Verdel",
                "Verdigre",
                "Verdon",
                "Virginia",
                "Waco",
                "Wahoo",
                "Wakefield",
                "Wallace",
                "Walthill",
                "Washington",
                "Waterbury",
                "Waterloo",
                "Wauneta",
                "Wausa",
                "Waverly",
                "Wayne",
                "Weeping Water",
                "Wellfleet",
                "West Point",
                "Western",
                "Weston",
                "Whitney",
                "Wilber",
                "Wilcox",
                "Wilsonville",
                "Winnebago",
                "Winnetoon",
                "Winside",
                "Winslow",
                "Wisner",
                "Wolbach",
                "Wood Lake",
                "Wood River",
                "Wymore",
                "Wynot",
                "York",
                "Yutan"
            ],
            "Nevada": [
                "Battle Mountain",
                "Beatty",
                "Blue Diamond",
                "Boulder City",
                "Bunkerville",
                "Cal-nev-ari",
                "Caliente",
                "Carlin",
                "Carson",
                "Cold Springs",
                "Dayton",
                "Elko",
                "Ely",
                "Enterprise",
                "Fallon",
                "Fallon Station",
                "Fernley",
                "Gabbs",
                "Gardnerville",
                "Gardnerville Ranchos",
                "Gerlach-empire",
                "Goodsprings",
                "Hawthorne",
                "Henderson",
                "Incline Village-crystal Bay",
                "Indian Hills",
                "Indian Springs",
                "Johnson Lane",
                "Kingsbury",
                "Las Vegas",
                "Laughlin",
                "Lemmon Valley-golden Valley",
                "Lovelock",
                "Mcdermitt",
                "Mcgill",
                "Mesquite",
                "Minden",
                "Moapa Town",
                "Moapa Valley",
                "Mount Charleston",
                "Nellis Afb",
                "Nixon",
                "North Las Vegas",
                "Owyhee",
                "Pahrump",
                "Paradise",
                "Reno",
                "Sandy Valley",
                "Schurz",
                "Searchlight",
                "Silver Springs",
                "Smith Valley",
                "Spanish Springs",
                "Sparks",
                "Spring Creek",
                "Spring Valley",
                "Stateline",
                "Summerlin South",
                "Sun Valley",
                "Sunrise Manor",
                "Sutcliffe",
                "Tonopah",
                "Verdi-mogul",
                "Wadsworth",
                "Wells",
                "West Wendover",
                "Whitney",
                "Winchester",
                "Winnemucca",
                "Yerington",
                "Zephyr Cove-round Hill Village"
            ],
            "New Hampshire": [
                "Antrim",
                "Berlin",
                "Bristol",
                "Charlestown",
                "Claremont",
                "Concord",
                "Contoocook",
                "Conway",
                "Derry",
                "Dover",
                "Durham",
                "East Merrimack",
                "Enfield",
                "Epping",
                "Exeter",
                "Farmington",
                "Franklin",
                "Gorham",
                "Greenville",
                "Groveton",
                "Hampton",
                "Hanover",
                "Henniker",
                "Hillsborough",
                "Hinsdale",
                "Hooksett",
                "Hudson",
                "Jaffrey",
                "Keene",
                "Laconia",
                "Lancaster",
                "Lebanon",
                "Lisbon",
                "Littleton",
                "Londonderry",
                "Manchester",
                "Marlborough",
                "Meredith",
                "Milford",
                "Nashua",
                "Newmarket",
                "Newport",
                "North Conway",
                "Peterborough",
                "Pinardville",
                "Pittsfield",
                "Plymouth",
                "Portsmouth",
                "Raymond",
                "Rochester",
                "Somersworth",
                "South Hooksett",
                "Suncook",
                "Tilton-northfield",
                "West Swanzey",
                "Whitefield",
                "Wilton",
                "Winchester",
                "Wolfeboro",
                "Woodsville"
            ],
            "New Jersey": [
                "Absecon",
                "Allamuchy-panther Valley",
                "Allendale",
                "Allenhurst",
                "Allentown",
                "Allenwood",
                "Alloway",
                "Alpha",
                "Alpine",
                "Andover",
                "Annandale",
                "Asbury Park",
                "Ashland",
                "Atlantic City",
                "Atlantic Highlands",
                "Audubon",
                "Audubon Park",
                "Avalon",
                "Avenel",
                "Avon-by-the-sea",
                "Barclay-kingston",
                "Barnegat",
                "Barnegat Light",
                "Barrington",
                "Bay Head",
                "Bayonne",
                "Beach Haven",
                "Beach Haven West",
                "Beachwood",
                "Beatyestown",
                "Beckett",
                "Belford",
                "Belleville",
                "Bellmawr",
                "Belmar",
                "Belvidere",
                "Bergenfield",
                "Berkeley Heights",
                "Berlin",
                "Bernardsville",
                "Beverly",
                "Blackwood",
                "Bloomfield",
                "Bloomingdale",
                "Bloomsbury",
                "Bogota",
                "Boonton",
                "Bordentown",
                "Bound Brook",
                "Bradley Beach",
                "Branchville",
                "Brass Castle",
                "Bridgeton",
                "Brielle",
                "Brigantine",
                "Brooklawn",
                "Browns Mills",
                "Brownville",
                "Budd Lake",
                "Buena",
                "Burlington",
                "Butler",
                "Caldwell",
                "Califon",
                "Camden",
                "Cape May",
                "Cape May Court House",
                "Cape May Point",
                "Carlstadt",
                "Carneys Point",
                "Carteret",
                "Cedar Glen Lakes",
                "Cedar Glen West",
                "Cedar Grove",
                "Cedarville",
                "Chatham",
                "Cherry Hill Mall",
                "Chesilhurst",
                "Chester",
                "Clark",
                "Clayton",
                "Clearbrook Park",
                "Clementon",
                "Cliffside Park",
                "Cliffwood Beach",
                "Clifton",
                "Clinton",
                "Closter",
                "Collings Lakes",
                "Collingswood",
                "Colonia",
                "Concordia",
                "Corbin City",
                "Country Lake Estates",
                "Cranbury",
                "Crandon Lakes",
                "Cranford",
                "Cresskill",
                "Crestwood Village",
                "Dayton",
                "Deal",
                "Demarest",
                "Diamond Beach",
                "Dover",
                "Dover Beaches North",
                "Dover Beaches South",
                "Dumont",
                "Dunellen",
                "East Brunswick",
                "East Freehold",
                "East Newark",
                "East Orange",
                "East Rutherford",
                "Eatontown",
                "Echelon",
                "Edgewater",
                "Edison",
                "Egg Harbor City",
                "Elizabeth",
                "Elmer",
                "Elmwood Park",
                "Elwood-magnolia",
                "Emerson",
                "Englewood",
                "Englewood Cliffs",
                "Englishtown",
                "Erlton-ellisburg",
                "Erma",
                "Essex Fells",
                "Estell Manor",
                "Ewing",
                "Fair Haven",
                "Fair Lawn",
                "Fairfield",
                "Fairton",
                "Fairview",
                "Fanwood",
                "Far Hills",
                "Farmingdale",
                "Fieldsboro",
                "Flemington",
                "Florence-roebling",
                "Florham Park",
                "Folsom",
                "Fords",
                "Forked River",
                "Fort Dix",
                "Fort Lee",
                "Franklin",
                "Franklin Lakes",
                "Freehold",
                "Frenchtown",
                "Garfield",
                "Garwood",
                "Gibbsboro",
                "Gibbstown",
                "Glassboro",
                "Glen Gardner",
                "Glen Ridge",
                "Glen Rock",
                "Glendora",
                "Gloucester City",
                "Golden Triangle",
                "Great Meadows-vienna",
                "Greentree",
                "Guttenberg",
                "Hackensack",
                "Hackettstown",
                "Haddon Heights",
                "Haddonfield",
                "Haledon",
                "Hamburg",
                "Hammonton",
                "Hampton",
                "Harrington Park",
                "Harrison",
                "Harvey Cedars",
                "Hasbrouck Heights",
                "Haworth",
                "Hawthorne",
                "Heathcote",
                "Helmetta",
                "Hi-nella",
                "High Bridge",
                "Highland Lake",
                "Highland Park",
                "Highlands",
                "Hightstown",
                "Hillsdale",
                "Hillside",
                "Ho-ho-kus",
                "Hoboken",
                "Holiday City South",
                "Holiday City-berkeley",
                "Holiday Heights",
                "Hopatcong",
                "Hopewell",
                "Interlaken",
                "Irvington",
                "Iselin",
                "Island Heights",
                "Jamesburg",
                "Jersey City",
                "Keansburg",
                "Kearny",
                "Kendall Park",
                "Kenilworth",
                "Keyport",
                "Kingston",
                "Kinnelon",
                "Lake Mohawk",
                "Lake Telemark",
                "Lakehurst",
                "Lakewood",
                "Lambertville",
                "Laurel Lake",
                "Laurel Springs",
                "Laurence Harbor",
                "Lavallette",
                "Lawnside",
                "Lawrenceville",
                "Lebanon",
                "Leisure Knoll",
                "Leisure Village",
                "Leisure Village East",
                "Leisure Village West-pine Lake Park",
                "Leisuretowne",
                "Leonardo",
                "Leonia",
                "Lincoln Park",
                "Lincroft",
                "Linden",
                "Lindenwold",
                "Linwood",
                "Little Falls",
                "Little Ferry",
                "Little Silver",
                "Livingston",
                "Loch Arbour",
                "Lodi",
                "Long Branch",
                "Long Valley",
                "Longport",
                "Lyndhurst",
                "Madison",
                "Madison Park",
                "Magnolia",
                "Manahawkin",
                "Manasquan",
                "Mantoloking",
                "Manville",
                "Maplewood",
                "Margate City",
                "Marlton",
                "Matawan",
                "Mays Landing",
                "Maywood",
                "Mcguire Afb",
                "Medford Lakes",
                "Mendham",
                "Mercerville-hamilton Square",
                "Merchantville",
                "Metuchen",
                "Middlesex",
                "Midland Park",
                "Milford",
                "Millburn",
                "Millstone",
                "Milltown",
                "Millville",
                "Monmouth Beach",
                "Monmouth Junction",
                "Montclair",
                "Montvale",
                "Moonachie",
                "Moorestown-lenola",
                "Morganville",
                "Morris Plains",
                "Morristown",
                "Mount Arlington",
                "Mount Ephraim",
                "Mountain Lakes",
                "Mountainside",
                "Mullica Hill",
                "Mystic Island",
                "National Park",
                "Navesink",
                "Neptune City",
                "Netcong",
                "New Brunswick",
                "New Egypt",
                "New Milford",
                "New Providence",
                "Newark",
                "Newfield",
                "Newton",
                "North Arlington",
                "North Beach Haven",
                "North Brunswick Township",
                "North Caldwell",
                "North Cape May",
                "North Haledon",
                "North Middletown",
                "North Plainfield",
                "North Wildwood",
                "Northfield",
                "Northvale",
                "Norwood",
                "Nutley",
                "Oak Valley",
                "Oakhurst",
                "Oakland",
                "Oaklyn",
                "Ocean Acres",
                "Ocean City",
                "Ocean Gate",
                "Ocean Grove",
                "Oceanport",
                "Ogdensburg",
                "Old Bridge",
                "Old Tappan",
                "Olivet",
                "Oradell",
                "Orange",
                "Oxford",
                "Palisades Park",
                "Palmyra",
                "Paramus",
                "Park Ridge",
                "Passaic",
                "Paterson",
                "Paulsboro",
                "Peapack And Gladstone",
                "Pemberton",
                "Pemberton Heights",
                "Pennington",
                "Penns Grove",
                "Pennsauken",
                "Pennsville",
                "Perth Amboy",
                "Phillipsburg",
                "Pine Beach",
                "Pine Hill",
                "Pine Ridge At Crestwood",
                "Pine Valley",
                "Pitman",
                "Plainfield",
                "Plainsboro Center",
                "Pleasantville",
                "Point Pleasant",
                "Point Pleasant Beach",
                "Pomona",
                "Pompton Lakes",
                "Port Monmouth",
                "Port Norris",
                "Port Reading",
                "Port Republic",
                "Presidential Lakes Estates",
                "Princeton",
                "Princeton Junction",
                "Princeton Meadows",
                "Princeton North",
                "Prospect Park",
                "Rahway",
                "Ramblewood",
                "Ramsey",
                "Ramtown",
                "Raritan",
                "Red Bank",
                "Ridgefield",
                "Ridgefield Park",
                "Ridgewood",
                "Ringwood",
                "Rio Grande",
                "River Edge",
                "River Vale",
                "Riverdale",
                "Riverton",
                "Rochelle Park",
                "Rockaway",
                "Rockleigh",
                "Rocky Hill",
                "Roosevelt",
                "Roseland",
                "Roselle",
                "Roselle Park",
                "Rosenhayn",
                "Rossmoor",
                "Rumson",
                "Runnemede",
                "Rutherford",
                "Saddle Brook",
                "Saddle River",
                "Salem",
                "Sayreville",
                "Scotch Plains",
                "Sea Bright",
                "Sea Girt",
                "Sea Isle City",
                "Seabrook Farms",
                "Seaside Heights",
                "Seaside Park",
                "Secaucus",
                "Sewaren",
                "Shark River Hills",
                "Shiloh",
                "Ship Bottom",
                "Shrewsbury",
                "Silver Ridge",
                "Society Hill",
                "Somerdale",
                "Somers Point",
                "Somerset",
                "Somerville",
                "South Amboy",
                "South Belmar",
                "South Bound Brook",
                "South Orange",
                "South Plainfield",
                "South River",
                "South Toms River",
                "Spotswood",
                "Spring Lake",
                "Spring Lake Heights",
                "Springdale",
                "Springfield",
                "Stanhope",
                "Stockton",
                "Stone Harbor",
                "Stratford",
                "Strathmere",
                "Strathmore",
                "Succasunna-kenvil",
                "Summit",
                "Surf City",
                "Sussex",
                "Swedesboro",
                "Tavistock",
                "Teaneck",
                "Tenafly",
                "Teterboro",
                "Tinton Falls",
                "Toms River",
                "Totowa",
                "Trenton",
                "Tuckerton",
                "Turnersville",
                "Twin Rivers",
                "Union",
                "Union Beach",
                "Union City",
                "Upper Saddle River",
                "Ventnor City",
                "Vernon Valley",
                "Verona",
                "Victory Gardens",
                "Victory Lakes",
                "Villas",
                "Vineland",
                "Vista Center",
                "Waldwick",
                "Wallington",
                "Wanamassa",
                "Wanaque",
                "Waretown",
                "Washington",
                "Washington Township",
                "Watchung",
                "Wayne",
                "Wenonah",
                "West Belmar",
                "West Caldwell",
                "West Cape May",
                "West Freehold",
                "West Long Branch",
                "West Milford",
                "West New York",
                "West Orange",
                "West Paterson",
                "West Wildwood",
                "Westfield",
                "Westville",
                "Westwood",
                "Wharton",
                "White Horse",
                "White House Station",
                "White Meadow Lake",
                "Whitesboro-burleigh",
                "Whittingham",
                "Wildwood",
                "Wildwood Crest",
                "Williamstown",
                "Wood-ridge",
                "Woodbine",
                "Woodbridge",
                "Woodbury",
                "Woodbury Heights",
                "Woodcliff Lake",
                "Woodlynne",
                "Woodstown",
                "Wrightstown",
                "Wyckoff",
                "Yardville-groveville",
                "Yorketown"
            ],
            "New Jersy": [
                "Absecon",
                "Allamuchy-panther Valley",
                "Allendale",
                "Allenhurst",
                "Allentown",
                "Allenwood",
                "Alloway",
                "Alpha",
                "Alpine",
                "Andover",
                "Annandale",
                "Asbury Park",
                "Ashland",
                "Atlantic City",
                "Atlantic Highlands",
                "Audubon",
                "Audubon Park",
                "Avalon",
                "Avenel",
                "Avon-by-the-sea",
                "Barclay-kingston",
                "Barnegat",
                "Barnegat Light",
                "Barrington",
                "Bay Head",
                "Bayonne",
                "Beach Haven",
                "Beach Haven West",
                "Beachwood",
                "Beatyestown",
                "Beckett",
                "Belford",
                "Belleville",
                "Bellmawr",
                "Belmar",
                "Belvidere",
                "Bergenfield",
                "Berkeley Heights",
                "Berlin",
                "Bernardsville",
                "Beverly",
                "Blackwood",
                "Bloomfield",
                "Bloomingdale",
                "Bloomsbury",
                "Bogota",
                "Boonton",
                "Bordentown",
                "Bound Brook",
                "Bradley Beach",
                "Branchville",
                "Brass Castle",
                "Bridgeton",
                "Brielle",
                "Brigantine",
                "Brooklawn",
                "Browns Mills",
                "Brownville",
                "Budd Lake",
                "Buena",
                "Burlington",
                "Butler",
                "Caldwell",
                "Califon",
                "Camden",
                "Cape May",
                "Cape May Court House",
                "Cape May Point",
                "Carlstadt",
                "Carneys Point",
                "Carteret",
                "Cedar Glen Lakes",
                "Cedar Glen West",
                "Cedar Grove",
                "Cedarville",
                "Chatham",
                "Cherry Hill Mall",
                "Chesilhurst",
                "Chester",
                "Clark",
                "Clayton",
                "Clearbrook Park",
                "Clementon",
                "Cliffside Park",
                "Cliffwood Beach",
                "Clifton",
                "Clinton",
                "Closter",
                "Collings Lakes",
                "Collingswood",
                "Colonia",
                "Concordia",
                "Corbin City",
                "Country Lake Estates",
                "Cranbury",
                "Crandon Lakes",
                "Cranford",
                "Cresskill",
                "Crestwood Village",
                "Dayton",
                "Deal",
                "Demarest",
                "Diamond Beach",
                "Dover",
                "Dover Beaches North",
                "Dover Beaches South",
                "Dumont",
                "Dunellen",
                "East Brunswick",
                "East Freehold",
                "East Newark",
                "East Orange",
                "East Rutherford",
                "Eatontown",
                "Echelon",
                "Edgewater",
                "Edison",
                "Egg Harbor City",
                "Elizabeth",
                "Elmer",
                "Elmwood Park",
                "Elwood-magnolia",
                "Emerson",
                "Englewood",
                "Englewood Cliffs",
                "Englishtown",
                "Erlton-ellisburg",
                "Erma",
                "Essex Fells",
                "Estell Manor",
                "Ewing",
                "Fair Haven",
                "Fair Lawn",
                "Fairfield",
                "Fairton",
                "Fairview",
                "Fanwood",
                "Far Hills",
                "Farmingdale",
                "Fieldsboro",
                "Flemington",
                "Florence-roebling",
                "Florham Park",
                "Folsom",
                "Fords",
                "Forked River",
                "Fort Dix",
                "Fort Lee",
                "Franklin",
                "Franklin Lakes",
                "Freehold",
                "Frenchtown",
                "Garfield",
                "Garwood",
                "Gibbsboro",
                "Gibbstown",
                "Glassboro",
                "Glen Gardner",
                "Glen Ridge",
                "Glen Rock",
                "Glendora",
                "Gloucester City",
                "Golden Triangle",
                "Great Meadows-vienna",
                "Greentree",
                "Guttenberg",
                "Hackensack",
                "Hackettstown",
                "Haddon Heights",
                "Haddonfield",
                "Haledon",
                "Hamburg",
                "Hammonton",
                "Hampton",
                "Harrington Park",
                "Harrison",
                "Harvey Cedars",
                "Hasbrouck Heights",
                "Haworth",
                "Hawthorne",
                "Heathcote",
                "Helmetta",
                "Hi-nella",
                "High Bridge",
                "Highland Lake",
                "Highland Park",
                "Highlands",
                "Hightstown",
                "Hillsdale",
                "Hillside",
                "Ho-ho-kus",
                "Hoboken",
                "Holiday City South",
                "Holiday City-berkeley",
                "Holiday Heights",
                "Hopatcong",
                "Hopewell",
                "Interlaken",
                "Irvington",
                "Iselin",
                "Island Heights",
                "Jamesburg",
                "Jersey City",
                "Keansburg",
                "Kearny",
                "Kendall Park",
                "Kenilworth",
                "Keyport",
                "Kingston",
                "Kinnelon",
                "Lake Mohawk",
                "Lake Telemark",
                "Lakehurst",
                "Lakewood",
                "Lambertville",
                "Laurel Lake",
                "Laurel Springs",
                "Laurence Harbor",
                "Lavallette",
                "Lawnside",
                "Lawrenceville",
                "Lebanon",
                "Leisure Knoll",
                "Leisure Village",
                "Leisure Village East",
                "Leisure Village West-pine Lake Park",
                "Leisuretowne",
                "Leonardo",
                "Leonia",
                "Lincoln Park",
                "Lincroft",
                "Linden",
                "Lindenwold",
                "Linwood",
                "Little Falls",
                "Little Ferry",
                "Little Silver",
                "Livingston",
                "Loch Arbour",
                "Lodi",
                "Long Branch",
                "Long Valley",
                "Longport",
                "Lyndhurst",
                "Madison",
                "Madison Park",
                "Magnolia",
                "Manahawkin",
                "Manasquan",
                "Mantoloking",
                "Manville",
                "Maplewood",
                "Margate City",
                "Marlton",
                "Matawan",
                "Mays Landing",
                "Maywood",
                "Mcguire Afb",
                "Medford Lakes",
                "Mendham",
                "Mercerville-hamilton Square",
                "Merchantville",
                "Metuchen",
                "Middlesex",
                "Midland Park",
                "Milford",
                "Millburn",
                "Millstone",
                "Milltown",
                "Millville",
                "Monmouth Beach",
                "Monmouth Junction",
                "Montclair",
                "Montvale",
                "Moonachie",
                "Moorestown-lenola",
                "Morganville",
                "Morris Plains",
                "Morristown",
                "Mount Arlington",
                "Mount Ephraim",
                "Mountain Lakes",
                "Mountainside",
                "Mullica Hill",
                "Mystic Island",
                "National Park",
                "Navesink",
                "Neptune City",
                "Netcong",
                "New Brunswick",
                "New Egypt",
                "New Milford",
                "New Providence",
                "Newark",
                "Newfield",
                "Newton",
                "North Arlington",
                "North Beach Haven",
                "North Brunswick Township",
                "North Caldwell",
                "North Cape May",
                "North Haledon",
                "North Middletown",
                "North Plainfield",
                "North Wildwood",
                "Northfield",
                "Northvale",
                "Norwood",
                "Nutley",
                "Oak Valley",
                "Oakhurst",
                "Oakland",
                "Oaklyn",
                "Ocean Acres",
                "Ocean City",
                "Ocean Gate",
                "Ocean Grove",
                "Oceanport",
                "Ogdensburg",
                "Old Bridge",
                "Old Tappan",
                "Olivet",
                "Oradell",
                "Orange",
                "Oxford",
                "Palisades Park",
                "Palmyra",
                "Paramus",
                "Park Ridge",
                "Passaic",
                "Paterson",
                "Paulsboro",
                "Peapack And Gladstone",
                "Pemberton",
                "Pemberton Heights",
                "Pennington",
                "Penns Grove",
                "Pennsauken",
                "Pennsville",
                "Perth Amboy",
                "Phillipsburg",
                "Pine Beach",
                "Pine Hill",
                "Pine Ridge At Crestwood",
                "Pine Valley",
                "Pitman",
                "Plainfield",
                "Plainsboro Center",
                "Pleasantville",
                "Point Pleasant",
                "Point Pleasant Beach",
                "Pomona",
                "Pompton Lakes",
                "Port Monmouth",
                "Port Norris",
                "Port Reading",
                "Port Republic",
                "Presidential Lakes Estates",
                "Princeton",
                "Princeton Junction",
                "Princeton Meadows",
                "Princeton North",
                "Prospect Park",
                "Rahway",
                "Ramblewood",
                "Ramsey",
                "Ramtown",
                "Raritan",
                "Red Bank",
                "Ridgefield",
                "Ridgefield Park",
                "Ridgewood",
                "Ringwood",
                "Rio Grande",
                "River Edge",
                "River Vale",
                "Riverdale",
                "Riverton",
                "Rochelle Park",
                "Rockaway",
                "Rockleigh",
                "Rocky Hill",
                "Roosevelt",
                "Roseland",
                "Roselle",
                "Roselle Park",
                "Rosenhayn",
                "Rossmoor",
                "Rumson",
                "Runnemede",
                "Rutherford",
                "Saddle Brook",
                "Saddle River",
                "Salem",
                "Sayreville",
                "Scotch Plains",
                "Sea Bright",
                "Sea Girt",
                "Sea Isle City",
                "Seabrook Farms",
                "Seaside Heights",
                "Seaside Park",
                "Secaucus",
                "Sewaren",
                "Shark River Hills",
                "Shiloh",
                "Ship Bottom",
                "Shrewsbury",
                "Silver Ridge",
                "Society Hill",
                "Somerdale",
                "Somers Point",
                "Somerset",
                "Somerville",
                "South Amboy",
                "South Belmar",
                "South Bound Brook",
                "South Orange",
                "South Plainfield",
                "South River",
                "South Toms River",
                "Spotswood",
                "Spring Lake",
                "Spring Lake Heights",
                "Springdale",
                "Springfield",
                "Stanhope",
                "Stockton",
                "Stone Harbor",
                "Stratford",
                "Strathmere",
                "Strathmore",
                "Succasunna-kenvil",
                "Summit",
                "Surf City",
                "Sussex",
                "Swedesboro",
                "Tavistock",
                "Teaneck",
                "Tenafly",
                "Teterboro",
                "Tinton Falls",
                "Toms River",
                "Totowa",
                "Trenton",
                "Tuckerton",
                "Turnersville",
                "Twin Rivers",
                "Union",
                "Union Beach",
                "Union City",
                "Upper Saddle River",
                "Ventnor City",
                "Vernon Valley",
                "Verona",
                "Victory Gardens",
                "Victory Lakes",
                "Villas",
                "Vineland",
                "Vista Center",
                "Waldwick",
                "Wallington",
                "Wanamassa",
                "Wanaque",
                "Waretown",
                "Washington",
                "Washington Township",
                "Watchung",
                "Wayne",
                "Wenonah",
                "West Belmar",
                "West Caldwell",
                "West Cape May",
                "West Freehold",
                "West Long Branch",
                "West Milford",
                "West New York",
                "West Orange",
                "West Paterson",
                "West Wildwood",
                "Westfield",
                "Westville",
                "Westwood",
                "Wharton",
                "White Horse",
                "White House Station",
                "White Meadow Lake",
                "Whitesboro-burleigh",
                "Whittingham",
                "Wildwood",
                "Wildwood Crest",
                "Williamstown",
                "Wood-ridge",
                "Woodbine",
                "Woodbridge",
                "Woodbury",
                "Woodbury Heights",
                "Woodcliff Lake",
                "Woodlynne",
                "Woodstown",
                "Wrightstown",
                "Wyckoff",
                "Yardville-groveville",
                "Yorketown"
            ],
            "New Mexico": [
                "Acomita Lake",
                "Agua Fria",
                "Alamo",
                "Alamogordo",
                "Albuquerque",
                "Alcalde",
                "Algodones",
                "Angel Fire",
                "Anthony",
                "Artesia",
                "Aztec",
                "Bayard",
                "Beclabito",
                "Belen",
                "Bernalillo",
                "Black Rock",
                "Bloomfield",
                "Boles Acres",
                "Bosque Farms",
                "Brimhall Nizhoni",
                "Canada De Los Alamos",
                "Cannon Afb",
                "Capitan",
                "Carlsbad",
                "Carlsbad North",
                "Carnuel",
                "Carrizozo",
                "Casa Colorada",
                "Causey",
                "Cedar Crest",
                "Cedar Grove",
                "Chama",
                "Chamisal",
                "Chaparral",
                "Chilili",
                "Chimayo",
                "Chupadero",
                "Church Rock",
                "Cimarron",
                "Clayton",
                "Cloudcroft",
                "Clovis",
                "Cochiti",
                "Columbus",
                "Corona",
                "Corrales",
                "Crownpoint",
                "Crystal",
                "Cuartelez",
                "Cuba",
                "Cundiyo",
                "Cuyamungue",
                "Deming",
                "Des Moines",
                "Dexter",
                "Dona Ana",
                "Dora",
                "Dulce",
                "Eagle Nest",
                "Edgewood",
                "El Cerro-monterey Park",
                "El Rancho",
                "El Valle De Arroyo Seco",
                "Eldorado At Santa Fe",
                "Elephant Butte",
                "Elida",
                "Encinal",
                "Encino",
                "Espanola",
                "Estancia",
                "Eunice",
                "Farmington",
                "Flora Vista",
                "Floyd",
                "Folsom",
                "Fort Sumner",
                "Galisteo",
                "Gallup",
                "Glorieta",
                "Grady",
                "Grants",
                "Grenville",
                "Hagerman",
                "Hatch",
                "Hobbs",
                "Holloman Afb",
                "Hope",
                "House",
                "Huerfano",
                "Hurley",
                "Isleta Village Proper",
                "Jaconita",
                "Jal",
                "Jarales",
                "Jemez Pueblo",
                "Jemez Springs",
                "Kirtland",
                "La Cienega",
                "La Jara",
                "La Luz",
                "La Puebla",
                "Laguna",
                "Lake Arthur",
                "Lake Sumner",
                "Lamy",
                "Las Cruces",
                "Las Vegas",
                "Logan",
                "Lordsburg",
                "Los Alamos",
                "Los Cerrillos",
                "Los Chaves",
                "Los Lunas",
                "Los Ranchos De Albuquerque",
                "Los Trujillos-gabaldon",
                "Loving",
                "Lovington",
                "Madrid",
                "Magdalena",
                "Manzano",
                "Maxwell",
                "Meadow Lake",
                "Melrose",
                "Mescalero",
                "Mesilla",
                "Mesita",
                "Mesquite",
                "Milan",
                "Moriarty",
                "Mosquero",
                "Mountainair",
                "Nageezi",
                "Nakaibito",
                "Napi Hq",
                "Naschitti",
                "Navajo",
                "Nenahnezad",
                "Newcomb",
                "North Acomita Village",
                "North Valley",
                "Ojo Amarillo",
                "Paguate",
                "Paraje",
                "Pecos",
                "Pena Blanca",
                "Penasco",
                "Peralta",
                "Picuris Pueblo",
                "Pinehill",
                "Placitas",
                "Pojoaque",
                "Ponderosa",
                "Portales",
                "Pueblo Of Sandia Village",
                "Pueblo Pintado",
                "Questa",
                "Radium Springs",
                "Ramah",
                "Ranchos De Taos",
                "Raton",
                "Red River",
                "Regina",
                "Reserve",
                "Rincon",
                "Rio Chiquito",
                "Rio Communities",
                "Rio Communities North",
                "Rio En Medio",
                "Rio Lucio",
                "Rio Rancho",
                "Rock Springs",
                "Roswell",
                "Roy",
                "Ruidoso",
                "Ruidoso Downs",
                "Salem",
                "San Felipe Pueblo",
                "San Ildefonso Pueblo",
                "San Jon",
                "San Juan",
                "San Ysidro",
                "Sanostee",
                "Santa Ana Pueblo",
                "Santa Clara",
                "Santa Clara Pueblo",
                "Santa Cruz",
                "Santa Fe",
                "Santa Rosa",
                "Santa Teresa",
                "Santo Domingo Pueblo",
                "Seama",
                "Sheep Springs",
                "Shiprock",
                "Silver City",
                "Skyline-ganipa",
                "Socorro",
                "Sombrillo",
                "South Valley",
                "Springer",
                "Sunland Park",
                "Tajique",
                "Taos",
                "Taos Pueblo",
                "Taos Ski Valley",
                "Tatum",
                "Tesuque",
                "Texico",
                "Thoreau",
                "Tijeras",
                "Timberon",
                "Tohatchi",
                "Tome-adelino",
                "Torreon",
                "Truth Or Consequences",
                "Tse Bonito",
                "Tucumcari",
                "Tularosa",
                "Twin Lakes",
                "University Park",
                "Upper Fruitland",
                "Vadito",
                "Vado",
                "Valencia",
                "Vaughn",
                "Virden",
                "Wagon Mound",
                "White Rock",
                "White Sands",
                "Willard",
                "Williamsburg",
                "Yah-ta-hey",
                "Zia Pueblo",
                "Zuni Pueblo"
            ],
            "New York": [
                "Accord",
                "Adams",
                "Adams Center",
                "Addison",
                "Afton",
                "Airmont",
                "Akron",
                "Albany",
                "Albertson",
                "Albion",
                "Alden",
                "Alexander",
                "Alexandria Bay",
                "Alfred",
                "Allegany",
                "Almond",
                "Altamont",
                "Altmar",
                "Altona",
                "Amagansett",
                "Amenia",
                "Ames",
                "Amityville",
                "Amsterdam",
                "Andes",
                "Andover",
                "Angelica",
                "Angola",
                "Angola On The Lake",
                "Antwerp",
                "Apalachin",
                "Aquebogue",
                "Arcade",
                "Ardsley",
                "Argyle",
                "Arkport",
                "Arlington",
                "Armonk",
                "Asharoken",
                "Athens",
                "Atlantic Beach",
                "Attica",
                "Au Sable Forks",
                "Auburn",
                "Aurora",
                "Averill Park",
                "Avoca",
                "Avon",
                "Babylon",
                "Bainbridge",
                "Baiting Hollow",
                "Baldwin",
                "Baldwin Harbor",
                "Baldwinsville",
                "Ballston Spa",
                "Balmville",
                "Bardonia",
                "Barker",
                "Barneveld",
                "Barnum Island",
                "Batavia",
                "Bath",
                "Baxter Estates",
                "Bay Park",
                "Bay Shore",
                "Bayport",
                "Bayville",
                "Baywood",
                "Beacon",
                "Beaverdam Lake-salisbury Mills",
                "Bedford",
                "Belle Terre",
                "Bellerose",
                "Bellerose Terrace",
                "Bellmore",
                "Bellport",
                "Belmont",
                "Bemus Point",
                "Bergen",
                "Bethpage",
                "Big Flats",
                "Big Flats Airport",
                "Billington Heights",
                "Binghamton",
                "Black River",
                "Blasdell",
                "Blauvelt",
                "Bloomfield",
                "Bloomingburg",
                "Blue Point",
                "Bohemia",
                "Bolivar",
                "Boonville",
                "Brasher Falls-winthrop",
                "Brentwood",
                "Brewerton",
                "Brewster",
                "Brewster Hill",
                "Briarcliff Manor",
                "Bridgehampton",
                "Bridgeport",
                "Bridgewater",
                "Brighton",
                "Brightwaters",
                "Brinckerhoff",
                "Broadalbin",
                "Brockport",
                "Brocton",
                "Bronxville",
                "Brookhaven",
                "Brookville",
                "Brownville",
                "Brushton",
                "Buchanan",
                "Buffalo",
                "Burdett",
                "Burke",
                "Cairo",
                "Calcium",
                "Caledonia",
                "Callicoon",
                "Calverton",
                "Cambridge",
                "Camden",
                "Camillus",
                "Canajoharie",
                "Canandaigua",
                "Canaseraga",
                "Canastota",
                "Candor",
                "Canisteo",
                "Canton",
                "Cape Vincent",
                "Carle Place",
                "Carmel Hamlet",
                "Carthage",
                "Cassadaga",
                "Castile",
                "Castleton-on-hudson",
                "Castorland",
                "Cato",
                "Catskill",
                "Cattaraugus",
                "Cayuga",
                "Cayuga Heights",
                "Cazenovia",
                "Cedarhurst",
                "Celoron",
                "Center Moriches",
                "Centereach",
                "Centerport",
                "Central Islip",
                "Central Square",
                "Central Valley",
                "Centre Island",
                "Champlain",
                "Chappaqua",
                "Chateaugay",
                "Chatham",
                "Chaumont",
                "Cheektowaga",
                "Cherry Creek",
                "Cherry Valley",
                "Chester",
                "Chestnut Ridge",
                "Chittenango",
                "Churchville",
                "Clarence Center",
                "Clark Mills",
                "Claverack-red Mills",
                "Clayton",
                "Clayville",
                "Cleveland",
                "Clifton Springs",
                "Clinton",
                "Clintondale",
                "Clyde",
                "Cobleskill",
                "Coeymans",
                "Cohocton",
                "Cohoes",
                "Cold Brook",
                "Cold Spring",
                "Cold Spring Harbor",
                "Colonie",
                "Commack",
                "Congers",
                "Constableville",
                "Constantia",
                "Cooperstown",
                "Copake Lake",
                "Copenhagen",
                "Copiague",
                "Coram",
                "Corfu",
                "Corinth",
                "Corning",
                "Cornwall On Hudson",
                "Cortland",
                "Cortland West",
                "Country Knolls",
                "Cove Neck",
                "Coxsackie",
                "Cragsmoor",
                "Croghan",
                "Crompond",
                "Croton-on-hudson",
                "Crown Heights",
                "Crugers",
                "Cuba",
                "Cumberland Head",
                "Cutchogue",
                "Dannemora",
                "Dansville",
                "Deer Park",
                "Deferiet",
                "Delanson",
                "Delevan",
                "Delhi",
                "Delmar",
                "Depauville",
                "Depew",
                "Deposit",
                "Dering Harbor",
                "Deruyter",
                "Dexter",
                "Dix Hills",
                "Dobbs Ferry",
                "Dolgeville",
                "Dover Plains",
                "Dresden",
                "Dryden",
                "Duane Lake",
                "Duanesburg",
                "Dundee",
                "Dunkirk",
                "Earlville",
                "East Atlantic Beach",
                "East Aurora",
                "East Farmingdale",
                "East Garden City",
                "East Glenville",
                "East Greenbush",
                "East Hampton",
                "East Hampton North",
                "East Hills",
                "East Islip",
                "East Ithaca",
                "East Kingston",
                "East Marion",
                "East Massapequa",
                "East Meadow",
                "East Moriches",
                "East Nassau",
                "East Northport",
                "East Norwich",
                "East Patchogue",
                "East Quogue",
                "East Randolph",
                "East Rochester",
                "East Rockaway",
                "East Shoreham",
                "East Syracuse",
                "East Williston",
                "Eastchester",
                "Eastport",
                "Eatons Neck",
                "Eden",
                "Edwards",
                "Elba",
                "Elbridge",
                "Ellenville",
                "Ellicottville",
                "Ellisburg",
                "Elma Center",
                "Elmira",
                "Elmira Heights",
                "Elmont",
                "Elmsford",
                "Elwood",
                "Endicott",
                "Endwell",
                "Esperance",
                "Evans Mills",
                "Fabius",
                "Fair Haven",
                "Fairmount",
                "Fairport",
                "Fairview",
                "Falconer",
                "Farmingdale",
                "Farmingville",
                "Farnham",
                "Fayetteville",
                "Fire Island",
                "Firthcliffe",
                "Fishers Island",
                "Fishkill",
                "Flanders",
                "Fleischmanns",
                "Floral Park",
                "Florida",
                "Flower Hill",
                "Fonda",
                "Forest Home",
                "Forestville",
                "Fort Ann",
                "Fort Drum",
                "Fort Edward",
                "Fort Johnson",
                "Fort Montgomery",
                "Fort Plain",
                "Fort Salonga",
                "Frankfort",
                "Franklin",
                "Franklin Square",
                "Franklinville",
                "Fredonia",
                "Freeport",
                "Freeville",
                "Frewsburg",
                "Friendship",
                "Fulton",
                "Fultonville",
                "Gainesville",
                "Galeville",
                "Galway",
                "Gang Mills",
                "Garden City",
                "Garden City Park",
                "Garden City South",
                "Gardiner",
                "Gardnertown",
                "Gasport",
                "Gates-north Gates",
                "Geneseo",
                "Geneva",
                "Germantown",
                "Ghent",
                "Gilbertsville",
                "Gilgo-oak Beach-captree",
                "Glasco",
                "Glen Cove",
                "Glen Head",
                "Glen Park",
                "Glens Falls",
                "Glens Falls North",
                "Glenwood Landing",
                "Gloversville",
                "Golden S Bridge",
                "Gordon Heights",
                "Goshen",
                "Gouverneur",
                "Gowanda",
                "Grand View-on-hudson",
                "Granville",
                "Great Bend",
                "Great Neck",
                "Great Neck Estates",
                "Great Neck Gardens",
                "Great Neck Plaza",
                "Great River",
                "Greece",
                "Green Island",
                "Greene",
                "Greenlawn",
                "Greenport",
                "Greenport West",
                "Greenvale",
                "Greenville",
                "Greenwich",
                "Greenwood Lake",
                "Groton",
                "Hagaman",
                "Halesite",
                "Hamburg",
                "Hamilton",
                "Hammond",
                "Hammondsport",
                "Hampton Bays",
                "Hampton Manor",
                "Hancock",
                "Hannibal",
                "Harbor Hills",
                "Harbor Isle",
                "Harriman",
                "Harris Hill",
                "Harrison",
                "Harrisville",
                "Hartsdale",
                "Hastings-on-hudson",
                "Hauppauge",
                "Haverstraw",
                "Haviland",
                "Hawthorne",
                "Head Of The Harbor",
                "Hempstead",
                "Heritage Hills",
                "Herkimer",
                "Hermon",
                "Herricks",
                "Herrings",
                "Heuvelton",
                "Hewlett",
                "Hewlett Bay Park",
                "Hewlett Harbor",
                "Hewlett Neck",
                "Hicksville",
                "High Falls",
                "Highland",
                "Highland Falls",
                "Highland Mills",
                "Hillburn",
                "Hillcrest",
                "Hillside",
                "Hillside Lake",
                "Hilton",
                "Hobart",
                "Holbrook",
                "Holland",
                "Holland Patent",
                "Holley",
                "Holtsville",
                "Homer",
                "Honeoye Falls",
                "Hoosick Falls",
                "Hopewell Junction",
                "Hornell",
                "Horseheads",
                "Horseheads North",
                "Houghton",
                "Hudson",
                "Hudson Falls",
                "Hunter",
                "Huntington",
                "Huntington Bay",
                "Huntington Station",
                "Hurley",
                "Ilion",
                "Interlaken",
                "Inwood",
                "Irondequoit",
                "Irvington",
                "Island Park",
                "Islandia",
                "Islip",
                "Islip Terrace",
                "Ithaca",
                "Jamesport",
                "Jamestown",
                "Jamestown West",
                "Jefferson Heights",
                "Jefferson Valley-yorktown",
                "Jeffersonville",
                "Jericho",
                "Johnson City",
                "Johnstown",
                "Jordan",
                "Kaser",
                "Keeseville",
                "Kenmore",
                "Kensington",
                "Kerhonkson",
                "Kinderhook",
                "Kings Park",
                "Kings Point",
                "Kingston",
                "Kiryas Joel",
                "La Fargeville",
                "Lackawanna",
                "Lacona",
                "Lake Carmel",
                "Lake Erie Beach",
                "Lake George",
                "Lake Grove",
                "Lake Katrine",
                "Lake Luzerne-hadley",
                "Lake Mohegan",
                "Lake Placid",
                "Lake Ronkonkoma",
                "Lake Success",
                "Lakeland",
                "Lakeview",
                "Lakewood",
                "Lancaster",
                "Lansing",
                "Larchmont",
                "Lattingtown",
                "Laurel",
                "Laurel Hollow",
                "Laurens",
                "Lawrence",
                "Le Roy",
                "Leeds",
                "Leicester",
                "Levittown",
                "Lewiston",
                "Liberty",
                "Lido Beach",
                "Lima",
                "Lime Lake-machias",
                "Limestone",
                "Lincoln Park",
                "Lincolndale",
                "Lindenhurst",
                "Lisle",
                "Little Falls",
                "Little Valley",
                "Liverpool",
                "Livingston Manor",
                "Livonia",
                "Lloyd Harbor",
                "Lockport",
                "Locust Valley",
                "Lodi",
                "Long Beach",
                "Lorenz Park",
                "Lowville",
                "Lynbrook",
                "Lyncourt",
                "Lyndonville",
                "Lyon Mountain",
                "Lyons",
                "Lyons Falls",
                "Macedon",
                "Madison",
                "Mahopac",
                "Malden",
                "Malone",
                "Malverne",
                "Malverne Park Oaks",
                "Mamaroneck",
                "Manchester",
                "Manhasset",
                "Manhasset Hills",
                "Manlius",
                "Mannsville",
                "Manorhaven",
                "Manorville",
                "Marathon",
                "Marcellus",
                "Margaretville",
                "Mariaville Lake",
                "Marlboro",
                "Massapequa",
                "Massapequa Park",
                "Massena",
                "Mastic",
                "Mastic Beach",
                "Matinecock",
                "Mattituck",
                "Mattydale",
                "Maybrook",
                "Mayfield",
                "Mayville",
                "Mcgraw",
                "Mechanicstown",
                "Mechanicville",
                "Medford",
                "Medina",
                "Medusa",
                "Melrose Park",
                "Melville",
                "Menands",
                "Meridian",
                "Merrick",
                "Mexico",
                "Middle Island",
                "Middleburgh",
                "Middleport",
                "Middletown",
                "Middleville",
                "Milford",
                "Mill Neck",
                "Millbrook",
                "Miller Place",
                "Millerton",
                "Millport",
                "Milton",
                "Mineola",
                "Minetto",
                "Mineville-witherbee",
                "Minoa",
                "Mohawk",
                "Monroe",
                "Monsey",
                "Montauk",
                "Montebello",
                "Montgomery",
                "Monticello",
                "Montour Falls",
                "Mooers",
                "Moravia",
                "Moriches",
                "Morris",
                "Morrisonville",
                "Morristown",
                "Morrisville",
                "Mount Ivy",
                "Mount Kisco",
                "Mount Morris",
                "Mount Sinai",
                "Mount Vernon",
                "Munnsville",
                "Munsey Park",
                "Munsons Corners",
                "Muttontown",
                "Myers Corner",
                "Nanuet",
                "Napanoch",
                "Napeague",
                "Naples",
                "Narrowsburg",
                "Nassau",
                "Natural Bridge",
                "Nedrow",
                "Nelliston",
                "Nelsonville",
                "Nesconset",
                "New Berlin",
                "New Cassel",
                "New City",
                "New Hartford",
                "New Hempstead",
                "New Hyde Park",
                "New Paltz",
                "New Rochelle",
                "New Square",
                "New Suffolk",
                "New Windsor",
                "New York",
                "New York Mills",
                "Newark",
                "Newark Valley",
                "Newburgh",
                "Newfane",
                "Newfield Hamlet",
                "Newport",
                "Niagara Falls",
                "Nichols",
                "Niskayuna",
                "Nissequogue",
                "Niverville",
                "Norfolk",
                "North Amityville",
                "North Babylon",
                "North Ballston Spa",
                "North Bay Shore",
                "North Bellmore",
                "North Bellport",
                "North Boston",
                "North Collins",
                "North Great River",
                "North Haven",
                "North Hills",
                "North Hornell",
                "North Lindenhurst",
                "North Lynbrook",
                "North Massapequa",
                "North Merrick",
                "North New Hyde Park",
                "North Patchogue",
                "North Sea",
                "North Syracuse",
                "North Tonawanda",
                "North Valley Stream",
                "North Wantagh",
                "Northampton",
                "Northeast Ithaca",
                "Northport",
                "Northville",
                "Northwest Harbor",
                "Northwest Ithaca",
                "Norwich",
                "Norwood",
                "Noyack",
                "Nunda",
                "Nyack",
                "Oakdale",
                "Oakfield",
                "Ocean Beach",
                "Oceanside",
                "Odessa",
                "Ogdensburg",
                "Olcott",
                "Old Bethpage",
                "Old Brookville",
                "Old Field",
                "Old Westbury",
                "Olean",
                "Oneida",
                "Oneida Castle",
                "Oneonta",
                "Orange Lake",
                "Orangeburg",
                "Orchard Park",
                "Orient",
                "Oriskany",
                "Oriskany Falls",
                "Ossining",
                "Oswego",
                "Otego",
                "Otisville",
                "Ovid",
                "Owego",
                "Oxford",
                "Oyster Bay",
                "Oyster Bay Cove",
                "Painted Post",
                "Palatine Bridge",
                "Palenville",
                "Palmyra",
                "Panama",
                "Parc",
                "Parish",
                "Patchogue",
                "Pattersonville-rotterdam Junction",
                "Pawling",
                "Peach Lake",
                "Pearl River",
                "Peconic",
                "Peekskill",
                "Pelham",
                "Pelham Manor",
                "Penn Yan",
                "Perry",
                "Perrysburg",
                "Peru",
                "Phelps",
                "Philadelphia",
                "Philmont",
                "Phoenicia",
                "Phoenix",
                "Piermont",
                "Pike",
                "Pine Bush",
                "Pine Hill",
                "Pine Plains",
                "Pittsford",
                "Plainedge",
                "Plainview",
                "Plandome",
                "Plandome Heights",
                "Plandome Manor",
                "Plattekill",
                "Plattsburgh",
                "Plattsburgh West",
                "Pleasant Valley",
                "Pleasantville",
                "Poestenkill",
                "Point Lookout",
                "Poland",
                "Pomona",
                "Poquott",
                "Port Byron",
                "Port Chester",
                "Port Dickinson",
                "Port Ewen",
                "Port Henry",
                "Port Jefferson",
                "Port Jefferson Station",
                "Port Jervis",
                "Port Leyden",
                "Port Washington",
                "Port Washington North",
                "Portville",
                "Potsdam",
                "Poughkeepsie",
                "Preston-potter Hollow",
                "Prospect",
                "Pulaski",
                "Putnam Lake",
                "Quioque",
                "Quogue",
                "Randolph",
                "Ransomville",
                "Rapids",
                "Ravena",
                "Red Creek",
                "Red Hook",
                "Red Oaks Mill",
                "Redford",
                "Redwood",
                "Remsen",
                "Remsenburg-speonk",
                "Rensselaer",
                "Rensselaer Falls",
                "Rhinebeck",
                "Richburg",
                "Richfield Springs",
                "Richmondville",
                "Richville",
                "Ridge",
                "Rifton",
                "Ripley",
                "Riverhead",
                "Riverside",
                "Rochester",
                "Rock Hill",
                "Rockville Centre",
                "Rocky Point",
                "Rome",
                "Ronkonkoma",
                "Roosevelt",
                "Roscoe",
                "Rosendale Village",
                "Roslyn",
                "Roslyn Estates",
                "Roslyn Harbor",
                "Roslyn Heights",
                "Rotterdam",
                "Round Lake",
                "Rouses Point",
                "Rushville",
                "Russell Gardens",
                "Rye",
                "Rye Brook",
                "Sackets Harbor",
                "Saddle Rock",
                "Saddle Rock Estates",
                "Sag Harbor",
                "Sagaponack",
                "Salamanca",
                "Salem",
                "Salisbury",
                "Saltaire",
                "Sand Ridge",
                "Sands Point",
                "Sandy Creek",
                "Saranac Lake",
                "Saratoga Springs",
                "Saugerties",
                "Saugerties South",
                "Savona",
                "Sayville",
                "Scarsdale",
                "Schaghticoke",
                "Schenectady",
                "Schoharie",
                "Schuylerville",
                "Scotchtown",
                "Scotia",
                "Scotts Corners",
                "Scottsville",
                "Sea Cliff",
                "Seaford",
                "Searingtown",
                "Selden",
                "Seneca Falls",
                "Seneca Knolls",
                "Setauket-east Setauket",
                "Sharon Springs",
                "Shelter Island",
                "Shelter Island Heights",
                "Shenorock",
                "Sherburne",
                "Sherman",
                "Sherrill",
                "Shinnecock Hills",
                "Shirley",
                "Shokan",
                "Shoreham",
                "Shortsville",
                "Shrub Oak",
                "Sidney",
                "Silver Creek",
                "Silver Springs",
                "Sinclairville",
                "Skaneateles",
                "Sleepy Hollow",
                "Sloan",
                "Sloatsburg",
                "Smallwood",
                "Smithtown",
                "Smyrna",
                "Sodus",
                "Sodus Point",
                "Solvay",
                "Sound Beach",
                "South Corning",
                "South Dayton",
                "South Fallsburg",
                "South Farmingdale",
                "South Floral Park",
                "South Glens Falls",
                "South Hempstead",
                "South Hill",
                "South Huntington",
                "South Lockport",
                "South Nyack",
                "South Valley Stream",
                "Southampton",
                "Southold",
                "Southport",
                "Spackenkill",
                "Speculator",
                "Spencer",
                "Spencerport",
                "Spring Valley",
                "Springs",
                "Springville",
                "St. Bonaventure",
                "St. James",
                "St. Johnsville",
                "Staatsburg",
                "Stamford",
                "Stannards",
                "Star Lake",
                "Stewart Manor",
                "Stillwater",
                "Stone Ridge",
                "Stony Brook",
                "Stony Point",
                "Stottville",
                "Suffern",
                "Sylvan Beach",
                "Syosset",
                "Syracuse",
                "Tannersville",
                "Tappan",
                "Tarrytown",
                "Terryville",
                "Theresa",
                "Thiells",
                "Thomaston",
                "Thornwood",
                "Tillson",
                "Tivoli",
                "Tonawanda",
                "Town Line",
                "Tribes Hill",
                "Troy",
                "Trumansburg",
                "Tuckahoe",
                "Tully",
                "Tupper Lake",
                "Turin",
                "Tuxedo Park",
                "Unadilla",
                "Union Springs",
                "Uniondale",
                "Unionville",
                "University Gardens",
                "Upper Brookville",
                "Upper Nyack",
                "Utica",
                "Vails Gate",
                "Valatie",
                "Valhalla",
                "Valley Cottage",
                "Valley Falls",
                "Valley Stream",
                "Van Etten",
                "Vernon",
                "Verplanck",
                "Victor",
                "Victory",
                "Village Green",
                "Village Of The Branch",
                "Viola",
                "Voorheesville",
                "Waddington",
                "Wading River",
                "Wainscott",
                "Walden",
                "Walker Valley",
                "Wallkill",
                "Walton",
                "Walton Park",
                "Wampsville",
                "Wantagh",
                "Wappingers Falls",
                "Warrensburg",
                "Warsaw",
                "Warwick",
                "Washington Heights",
                "Washingtonville",
                "Waterford",
                "Waterloo",
                "Watermill",
                "Watertown",
                "Waterville",
                "Watervliet",
                "Watkins Glen",
                "Waverly",
                "Wayland",
                "Webster",
                "Weedsport",
                "Wellsburg",
                "Wellsville",
                "Wesley Hills",
                "West Babylon",
                "West Bay Shore",
                "West Carthage",
                "West Elmira",
                "West End",
                "West Glens Falls",
                "West Hampton Dunes",
                "West Haverstraw",
                "West Hempstead",
                "West Hills",
                "West Hurley",
                "West Islip",
                "West Nyack",
                "West Point",
                "West Sand Lake",
                "West Sayville",
                "West Seneca",
                "West Winfield",
                "Westbury",
                "Westfield",
                "Westhampton",
                "Westhampton Beach",
                "Westmere",
                "Weston Mills",
                "Westvale",
                "Wheatley Heights",
                "White Plains",
                "Whitehall",
                "Whitesboro",
                "Whitney Point",
                "Williamsville",
                "Williston Park",
                "Wilson",
                "Windham",
                "Windsor",
                "Wolcott",
                "Woodbury",
                "Woodmere",
                "Woodridge",
                "Woodsburgh",
                "Woodstock",
                "Wurtsboro",
                "Wyandanch",
                "Wynantskill",
                "Wyoming",
                "Yaphank",
                "Yonkers",
                "Yorkshire",
                "Yorktown Heights",
                "Yorkville",
                "Youngstown",
                "Zena"
            ],
            "North Carolina": [
                "Aberdeen",
                "Ahoskie",
                "Alamance",
                "Albemarle",
                "Alliance",
                "Altamahaw-ossipee",
                "Andrews",
                "Angier",
                "Ansonville",
                "Apex",
                "Arapahoe",
                "Archdale",
                "Arlington",
                "Asheboro",
                "Asheville",
                "Ashley Heights",
                "Askewville",
                "Atkinson",
                "Atlantic Beach",
                "Aulander",
                "Aurora",
                "Autryville",
                "Avery Creek",
                "Ayden",
                "Badin",
                "Bailey",
                "Bakersville",
                "Bald Head Island",
                "Balfour",
                "Banner Elk",
                "Barker Heights",
                "Barker Ten Mile",
                "Bath",
                "Bayboro",
                "Bayshore",
                "Beargrass",
                "Beaufort",
                "Beech Mountain",
                "Belhaven",
                "Belmont",
                "Belville",
                "Belwood",
                "Benson",
                "Bent Creek",
                "Bermuda Run",
                "Bessemer City",
                "Bethania",
                "Bethel",
                "Bethlehem",
                "Beulaville",
                "Biltmore Forest",
                "Biscoe",
                "Black Creek",
                "Black Mountain",
                "Bladenboro",
                "Blowing Rock",
                "Boardman",
                "Boger City",
                "Bogue",
                "Boiling Spring Lakes",
                "Boiling Springs",
                "Bolivia",
                "Bolton",
                "Bonnetsville",
                "Boone",
                "Boonville",
                "Bostic",
                "Bowmore",
                "Brevard",
                "Brices Creek",
                "Bridgeton",
                "Broadway",
                "Brogden",
                "Brookford",
                "Brunswick",
                "Bryson City",
                "Buies Creek",
                "Bunn",
                "Burgaw",
                "Burlington",
                "Burnsville",
                "Butner",
                "Butters",
                "Cajah S Mountain",
                "Calabash",
                "Calypso",
                "Cameron",
                "Candor",
                "Canton",
                "Cape Carteret",
                "Carolina Beach",
                "Carolina Shores",
                "Carrboro",
                "Carthage",
                "Cary",
                "Casar",
                "Cashiers",
                "Castalia",
                "Castle Hayne",
                "Caswell Beach",
                "Catawba",
                "Cedar Point",
                "Cedar Rock",
                "Centerville",
                "Cerro Gordo",
                "Chadbourn",
                "Chapel Hill",
                "Charlotte",
                "Cherryville",
                "Chimney Rock",
                "China Grove",
                "Chocowinity",
                "Claremont",
                "Clarkton",
                "Clayton",
                "Clemmons",
                "Cleveland",
                "Clinton",
                "Clyde",
                "Coats",
                "Cofield",
                "Colerain",
                "Columbia",
                "Columbus",
                "Como",
                "Concord",
                "Conetoe",
                "Connelly Springs",
                "Conover",
                "Conway",
                "Cooleemee",
                "Cornelius",
                "Cove City",
                "Cramerton",
                "Creedmoor",
                "Creswell",
                "Cricket",
                "Crossnore",
                "Cullowhee",
                "Dallas",
                "Danbury",
                "Davidson",
                "Delway",
                "Denton",
                "Dillsboro",
                "Dobbins Heights",
                "Dobson",
                "Dortches",
                "Dover",
                "Drexel",
                "Dublin",
                "Dundarrach",
                "Dunn",
                "Durham",
                "Earl",
                "East Arcadia",
                "East Bend",
                "East Flat Rock",
                "East Laurinburg",
                "East Rockingham",
                "East Spencer",
                "Eastover",
                "Eden",
                "Edenton",
                "Elizabeth City",
                "Elizabethtown",
                "Elk Park",
                "Elkin",
                "Ellenboro",
                "Ellerbe",
                "Elm City",
                "Elon College",
                "Elrod",
                "Elroy",
                "Emerald Isle",
                "Enfield",
                "Enochville",
                "Erwin",
                "Etowah",
                "Eureka",
                "Everetts",
                "Fair Bluff",
                "Fairfield Harbour",
                "Fairmont",
                "Fairplains",
                "Fairview",
                "Faison",
                "Faith",
                "Falcon",
                "Falkland",
                "Fallston",
                "Farmville",
                "Fayetteville",
                "Fearrington",
                "Five Points",
                "Flat Rock",
                "Fletcher",
                "Forest City",
                "Forest Hills",
                "Forest Oaks",
                "Fort Bragg",
                "Fountain",
                "Four Oaks",
                "Foxfire",
                "Franklin",
                "Franklinton",
                "Franklinville",
                "Fremont",
                "Fuquay-varina",
                "Gamewell",
                "Garland",
                "Garner",
                "Garysburg",
                "Gaston",
                "Gastonia",
                "Gatesville",
                "Gibson",
                "Gibsonville",
                "Glen Alpine",
                "Glen Raven",
                "Godwin",
                "Goldsboro",
                "Goldston",
                "Gorman",
                "Graham",
                "Grandfather",
                "Granite Falls",
                "Granite Quarry",
                "Green Level",
                "Greenevers",
                "Greensboro",
                "Greenville",
                "Grifton",
                "Grimesland",
                "Grover",
                "Half Moon",
                "Halifax",
                "Hamilton",
                "Hamlet",
                "Harkers Island",
                "Harmony",
                "Harrells",
                "Harrellsville",
                "Harrisburg",
                "Hassell",
                "Havelock",
                "Haw River",
                "Hayesville",
                "Hays",
                "Hemby Bridge",
                "Henderson",
                "Hendersonville",
                "Hertford",
                "Hickory",
                "High Point",
                "High Shoals",
                "Highlands",
                "Hightsville",
                "Hildebran",
                "Hillsborough",
                "Hobgood",
                "Hoffman",
                "Holden Beach",
                "Holly Ridge",
                "Holly Springs",
                "Hookerton",
                "Hope Mills",
                "Hot Springs",
                "Hudson",
                "Huntersville",
                "Icard",
                "Indian Beach",
                "Indian Trail",
                "Ingold",
                "Ivanhoe",
                "Jaars",
                "Jackson",
                "Jacksonville",
                "James City",
                "Jamestown",
                "Jamesville",
                "Jefferson",
                "Jonesville",
                "Kannapolis",
                "Keener",
                "Kelford",
                "Kelly",
                "Kenansville",
                "Kenly",
                "Kernersville",
                "Kill Devil Hills",
                "King",
                "Kings Grant",
                "Kings Mountain",
                "Kingstown",
                "Kinston",
                "Kirkland",
                "Kittrell",
                "Kitty Hawk",
                "Knightdale",
                "Kure Beach",
                "La Grange",
                "Lake Junaluska",
                "Lake Lure",
                "Lake Norman Of Catawba",
                "Lake Park",
                "Lake Santeetlah",
                "Lake Waccamaw",
                "Landis",
                "Lansing",
                "Lasker",
                "Lattimore",
                "Laurel Park",
                "Laurinburg",
                "Lawndale",
                "Leggett",
                "Leland",
                "Lenoir",
                "Lewiston Woodville",
                "Lewisville",
                "Lexington",
                "Liberty",
                "Light Oak",
                "Lilesville",
                "Lillington",
                "Lincolnton",
                "Linden",
                "Littleton",
                "Locust",
                "Long View",
                "Louisburg",
                "Love Valley",
                "Lowell",
                "Lowesville",
                "Lucama",
                "Lumber Bridge",
                "Lumberton",
                "Macclesfield",
                "Macon",
                "Madison",
                "Maggie Valley",
                "Magnolia",
                "Maiden",
                "Manteo",
                "Mar-mac",
                "Marietta",
                "Marion",
                "Mars Hill",
                "Marshall",
                "Marshville",
                "Marvin",
                "Masonboro",
                "Matthews",
                "Maxton",
                "Mayodan",
                "Maysville",
                "Mcadenville",
                "Mcdonald",
                "Mcfarlan",
                "Mcleansville",
                "Mebane",
                "Mesic",
                "Micro",
                "Middleburg",
                "Middlesex",
                "Millers Creek",
                "Milton",
                "Mineral Springs",
                "Minnesott Beach",
                "Mint Hill",
                "Mocksville",
                "Momeyer",
                "Monroe",
                "Montreat",
                "Mooresboro",
                "Mooresville",
                "Moravian Falls",
                "Morehead City",
                "Morganton",
                "Morrisville",
                "Morven",
                "Mount Airy",
                "Mount Gilead",
                "Mount Holly",
                "Mount Olive",
                "Mount Pleasant",
                "Mountain Home",
                "Mountain View",
                "Mulberry",
                "Murfreesboro",
                "Murphy",
                "Murraysville",
                "Myrtle Grove",
                "Nags Head",
                "Nashville",
                "Navassa",
                "Neuse Forest",
                "New Bern",
                "New London",
                "Newland",
                "Newport",
                "Newton",
                "Newton Grove",
                "Norlina",
                "Norman",
                "North Topsail Beach",
                "North Wilkesboro",
                "Northlakes",
                "Northwest",
                "Norwood",
                "Oak City",
                "Oak Island",
                "Oak Ridge",
                "Oakboro",
                "Ocean Isle Beach",
                "Ocracoke",
                "Ogden",
                "Old Fort",
                "Oriental",
                "Orrum",
                "Oxford",
                "Pantego",
                "Parkton",
                "Parmele",
                "Patterson Springs",
                "Peachland",
                "Peletier",
                "Pembroke",
                "Pikeville",
                "Pilot Mountain",
                "Pine Knoll Shores",
                "Pine Level",
                "Pinebluff",
                "Pinehurst",
                "Pinetops",
                "Pineville",
                "Piney Green",
                "Pink Hill",
                "Pittsboro",
                "Plain View",
                "Pleasant Garden",
                "Pleasant Hill",
                "Plymouth",
                "Polkton",
                "Polkville",
                "Pollocksville",
                "Pope Afb",
                "Powellsville",
                "Princeton",
                "Princeville",
                "Proctorville",
                "Prospect",
                "Pumpkin Center",
                "Raeford",
                "Raemon",
                "Raleigh",
                "Ramseur",
                "Randleman",
                "Ranlo",
                "Raynham",
                "Red Oak",
                "Red Springs",
                "Reidsville",
                "Rennert",
                "Rex",
                "Rhodhiss",
                "Rich Square",
                "Richfield",
                "Richlands",
                "River Bend",
                "River Road",
                "Roanoke Rapids",
                "Robbins",
                "Robbinsville",
                "Robersonville",
                "Rockfish",
                "Rockingham",
                "Rockwell",
                "Rocky Mount",
                "Rolesville",
                "Ronda",
                "Roper",
                "Rose Hill",
                "Roseboro",
                "Rosman",
                "Rowland",
                "Roxboro",
                "Roxobel",
                "Royal Pines",
                "Rural Hall",
                "Ruth",
                "Rutherford College",
                "Rutherfordton",
                "Salem",
                "Salemburg",
                "Salisbury",
                "Saluda",
                "Sandy Creek",
                "Sandyfield",
                "Sanford",
                "Saratoga",
                "Sawmills",
                "Saxapahaw",
                "Scotland Neck",
                "Sea Breeze",
                "Seaboard",
                "Seagate",
                "Seagrove",
                "Sedalia",
                "Selma",
                "Seven Devils",
                "Seven Lakes",
                "Seven Springs",
                "Severn",
                "Shallotte",
                "Shannon",
                "Sharpsburg",
                "Shelby",
                "Sherrills Ford",
                "Siler City",
                "Silver City",
                "Silver Lake",
                "Simpson",
                "Sims",
                "Skippers Corner",
                "Smithfield",
                "Sneads Ferry",
                "Snow Hill",
                "South Gastonia",
                "South Henderson",
                "South Rosemary",
                "South Weldon",
                "Southern Pines",
                "Southern Shores",
                "Southport",
                "Sparta",
                "Speed",
                "Spencer",
                "Spencer Mountain",
                "Spindale",
                "Spiveys Corner",
                "Spring Hope",
                "Spring Lake",
                "Spruce Pine",
                "St. Helena",
                "St. James",
                "St. Pauls",
                "St. Stephens",
                "Staley",
                "Stallings",
                "Stanfield",
                "Stanley",
                "Stantonsburg",
                "Star",
                "Statesville",
                "Stedman",
                "Stem",
                "Stokesdale",
                "Stoneville",
                "Stonewall",
                "Stony Point",
                "Stovall",
                "Sugar Mountain",
                "Summerfield",
                "Sunset Beach",
                "Surf City",
                "Swannanoa",
                "Swansboro",
                "Swepsonville",
                "Sylva",
                "Tabor City",
                "Tar Heel",
                "Tarboro",
                "Taylorsville",
                "Taylortown",
                "Teachey",
                "Thomasville",
                "Toast",
                "Tobaccoville",
                "Topsail Beach",
                "Trent Woods",
                "Trenton",
                "Trinity",
                "Troutman",
                "Troy",
                "Tryon",
                "Turkey",
                "Unionville",
                "Valdese",
                "Valley Hill",
                "Vanceboro",
                "Vandemere",
                "Vander",
                "Vann Crossroads",
                "Varnamtown",
                "Vass",
                "Waco",
                "Wade",
                "Wadesboro",
                "Wagram",
                "Wake Forest",
                "Walkertown",
                "Wallace",
                "Walnut Cove",
                "Walnut Creek",
                "Walstonburg",
                "Wanchese",
                "Warrenton",
                "Warsaw",
                "Washington",
                "Washington Park",
                "Watha",
                "Waxhaw",
                "Waynesville",
                "Weaverville",
                "Webster",
                "Weddington",
                "Welcome",
                "Weldon",
                "Wendell",
                "Wentworth",
                "Wesley Chapel",
                "West Canton",
                "West Jefferson",
                "West Marion",
                "West Smithfield",
                "Westport",
                "Whispering Pines",
                "Whitakers",
                "White Lake",
                "White Oak",
                "White Plains",
                "Whiteville",
                "Whitsett",
                "Wilkesboro",
                "Williamston",
                "Wilmington",
                "Wilson",
                "Wilson S Mills",
                "Windsor",
                "Winfall",
                "Wingate",
                "Winston-salem",
                "Winterville",
                "Winton",
                "Woodfin",
                "Woodland",
                "Woodlawn",
                "Wrightsboro",
                "Wrightsville Beach",
                "Yadkinville",
                "Yanceyville",
                "Youngsville",
                "Zebulon"
            ],
            "North Dakota": [
                "Abercrombie",
                "Adams",
                "Alamo",
                "Alexander",
                "Alice",
                "Almont",
                "Alsen",
                "Ambrose",
                "Amenia",
                "Amidon",
                "Anamoose",
                "Aneta",
                "Antler",
                "Ardoch",
                "Argusville",
                "Arnegard",
                "Arthur",
                "Ashley",
                "Ayr",
                "Balfour",
                "Balta",
                "Bantry",
                "Barney",
                "Bathgate",
                "Beach",
                "Belcourt",
                "Belfield",
                "Benedict",
                "Bergen",
                "Berlin",
                "Berthold",
                "Beulah",
                "Binford",
                "Bisbee",
                "Bismarck",
                "Bottineau",
                "Bowbells",
                "Bowdon",
                "Bowman",
                "Braddock",
                "Briarwood",
                "Brinsmade",
                "Brocket",
                "Buchanan",
                "Bucyrus",
                "Buffalo",
                "Burlington",
                "Butte",
                "Buxton",
                "Calio",
                "Calvin",
                "Cando",
                "Cannon Ball",
                "Canton City",
                "Carpio",
                "Carrington",
                "Carson",
                "Casselton",
                "Cathay",
                "Cavalier",
                "Cayuga",
                "Center",
                "Christine",
                "Churchs Ferry",
                "Cleveland",
                "Clifford",
                "Cogswell",
                "Coleharbor",
                "Colfax",
                "Columbus",
                "Conway",
                "Cooperstown",
                "Courtenay",
                "Crary",
                "Crosby",
                "Crystal",
                "Davenport",
                "Dawson",
                "Dazey",
                "Deering",
                "Des Lacs",
                "Devils Lake",
                "Dickey",
                "Dickinson",
                "Dodge",
                "Donnybrook",
                "Douglas",
                "Drake",
                "Drayton",
                "Dunn Center",
                "Dunseith",
                "Dwight",
                "East Dunseith",
                "Edgeley",
                "Edinburg",
                "Edmore",
                "Egeland",
                "Elgin",
                "Ellendale",
                "Elliott",
                "Emerado",
                "Enderlin",
                "Epping",
                "Erie",
                "Esmond",
                "Fairdale",
                "Fairmount",
                "Fargo",
                "Fessenden",
                "Fingal",
                "Finley",
                "Flasher",
                "Flaxton",
                "Forbes",
                "Fordville",
                "Forest River",
                "Forman",
                "Fort Ransom",
                "Fort Totten",
                "Fort Yates",
                "Fortuna",
                "Four Bears Village",
                "Fredonia",
                "Frontier",
                "Fullerton",
                "Gackle",
                "Galesburg",
                "Gardena",
                "Gardner",
                "Garrison",
                "Gascoyne",
                "Gilby",
                "Gladstone",
                "Glen Ullin",
                "Glenburn",
                "Glenfield",
                "Golden Valley",
                "Golva",
                "Goodrich",
                "Grace City",
                "Grafton",
                "Grand Forks",
                "Grand Forks Afb",
                "Grandin",
                "Grano",
                "Granville",
                "Great Bend",
                "Grenora",
                "Gwinner",
                "Hague",
                "Halliday",
                "Hamberg",
                "Hamilton",
                "Hampden",
                "Hankinson",
                "Hannaford",
                "Hannah",
                "Hansboro",
                "Harvey",
                "Harwood",
                "Hatton",
                "Havana",
                "Haynes",
                "Hazelton",
                "Hazen",
                "Hebron",
                "Hettinger",
                "Hillsboro",
                "Hoople",
                "Hope",
                "Horace",
                "Hove Mobile Park",
                "Hunter",
                "Hurdsfield",
                "Inkster",
                "Jamestown",
                "Jud",
                "Karlsruhe",
                "Kathryn",
                "Kenmare",
                "Kensal",
                "Kief",
                "Killdeer",
                "Kindred",
                "Knox",
                "Kramer",
                "Kulm",
                "Lakota",
                "Lamoure",
                "Landa",
                "Langdon",
                "Lankin",
                "Lansford",
                "Larimore",
                "Larson",
                "Lawton",
                "Leal",
                "Leeds",
                "Lehr",
                "Leith",
                "Leonard",
                "Lidgerwood",
                "Lignite",
                "Lincoln",
                "Linton",
                "Lisbon",
                "Litchville",
                "Loma",
                "Loraine",
                "Ludden",
                "Luverne",
                "Maddock",
                "Makoti",
                "Mandan",
                "Mandaree",
                "Mantador",
                "Manvel",
                "Mapleton",
                "Marion",
                "Marmarth",
                "Martin",
                "Max",
                "Maxbass",
                "Mayville",
                "Maza",
                "Mcclusky",
                "Mchenry",
                "Mcville",
                "Medina",
                "Medora",
                "Mercer",
                "Michigan City",
                "Milnor",
                "Milton",
                "Minnewaukan",
                "Minot",
                "Minot Afb",
                "Minto",
                "Mohall",
                "Monango",
                "Montpelier",
                "Mooreton",
                "Mott",
                "Mountain",
                "Munich",
                "Mylo",
                "Napoleon",
                "Neche",
                "Nekoma",
                "New England",
                "New Leipzig",
                "New Rockford",
                "New Salem",
                "New Town",
                "Newburg",
                "Niagara",
                "Nome",
                "Noonan",
                "North River",
                "Northwood",
                "Oakes",
                "Oberon",
                "Oriska",
                "Osnabrock",
                "Overly",
                "Oxbow",
                "Page",
                "Palermo",
                "Park River",
                "Parshall",
                "Pekin",
                "Pembina",
                "Perth",
                "Petersburg",
                "Pettibone",
                "Pick City",
                "Pillsbury",
                "Pingree",
                "Pisek",
                "Plaza",
                "Portal",
                "Portland",
                "Powers Lake",
                "Prairie Rose",
                "Rawson",
                "Ray",
                "Reeder",
                "Regan",
                "Regent",
                "Reile S Acres",
                "Reynolds",
                "Rhame",
                "Richardton",
                "Riverdale",
                "Robinson",
                "Rocklake",
                "Rogers",
                "Rolette",
                "Rolla",
                "Ross",
                "Rugby",
                "Ruso",
                "Rutland",
                "Ryder",
                "Sanborn",
                "Sarles",
                "Sawyer",
                "Scranton",
                "Selfridge",
                "Sentinel Butte",
                "Sharon",
                "Sheldon",
                "Shell Valley",
                "Sherwood",
                "Sheyenne",
                "Sibley",
                "Solen",
                "Souris",
                "South Heart",
                "Spiritwood Lake",
                "Springbrook",
                "St. John",
                "St. Thomas",
                "Stanley",
                "Stanton",
                "Starkweather",
                "Steele",
                "Strasburg",
                "Streeter",
                "Surrey",
                "Sykeston",
                "Tappen",
                "Taylor",
                "Thompson",
                "Tioga",
                "Tolley",
                "Tolna",
                "Tower City",
                "Towner",
                "Turtle Lake",
                "Tuttle",
                "Underwood",
                "Upham",
                "Valley City",
                "Velva",
                "Venturia",
                "Verona",
                "Voltaire",
                "Wahpeton",
                "Walcott",
                "Wales",
                "Walhalla",
                "Warwick",
                "Washburn",
                "Watford City",
                "West Fargo",
                "Westhope",
                "Wheatland",
                "White Earth",
                "White Shield",
                "Wildrose",
                "Williston",
                "Willow City",
                "Wilton",
                "Wimbledon",
                "Wing",
                "Wishek",
                "Wolford",
                "Woodworth",
                "Wyndmere",
                "York",
                "Zap",
                "Zeeland"
            ],
            "Northern Mariana Islands": [
                "Northern Mariana Islands"
            ],
            "Ohio": [
                "Aberdeen",
                "Ada",
                "Adamsville",
                "Addyston",
                "Adelphi",
                "Adena",
                "Akron",
                "Albany",
                "Alexandria",
                "Alger",
                "Alliance",
                "Alvordton",
                "Amanda",
                "Amberley",
                "Amelia",
                "Amesville",
                "Amherst",
                "Amsterdam",
                "Andover",
                "Anna",
                "Ansonia",
                "Antioch",
                "Antwerp",
                "Apple Creek",
                "Aquilla",
                "Arcadia",
                "Arcanum",
                "Archbold",
                "Arlington",
                "Arlington Heights",
                "Ashland",
                "Ashley",
                "Ashtabula",
                "Ashville",
                "Athalia",
                "Athens",
                "Attica",
                "Aurora",
                "Austintown",
                "Avon",
                "Avon Lake",
                "Bailey Lakes",
                "Bainbridge",
                "Bairdstown",
                "Ballville",
                "Baltic",
                "Baltimore",
                "Barberton",
                "Barnesville",
                "Barnhill",
                "Batavia",
                "Batesville",
                "Bay View",
                "Bay Village",
                "Beach City",
                "Beachwood",
                "Beallsville",
                "Beaver",
                "Beavercreek",
                "Beaverdam",
                "Beckett Ridge",
                "Bedford",
                "Bedford Heights",
                "Beechwood Trails",
                "Bellaire",
                "Bellbrook",
                "Belle Center",
                "Belle Valley",
                "Bellefontaine",
                "Bellevue",
                "Bellville",
                "Belmont",
                "Belmore",
                "Beloit",
                "Belpre",
                "Bentleyville",
                "Benton Ridge",
                "Berea",
                "Bergholz",
                "Berkey",
                "Berlin Heights",
                "Bethel",
                "Bethesda",
                "Bettsville",
                "Beverly",
                "Bexley",
                "Blacklick Estates",
                "Blakeslee",
                "Blanchester",
                "Bloomdale",
                "Bloomingburg",
                "Bloomingdale",
                "Bloomville",
                "Blue Ash",
                "Bluffton",
                "Boardman",
                "Bolindale",
                "Bolivar",
                "Boston Heights",
                "Botkins",
                "Bowerston",
                "Bowersville",
                "Bowling Green",
                "Bradford",
                "Bradner",
                "Brady Lake",
                "Bratenahl",
                "Brecksville",
                "Bremen",
                "Brewster",
                "Brice",
                "Bridgeport",
                "Bridgetown North",
                "Brimfield",
                "Broadview Heights",
                "Brook Park",
                "Brookfield Center",
                "Brooklyn",
                "Brooklyn Heights",
                "Brookside",
                "Brookville",
                "Broughton",
                "Brunswick",
                "Bryan",
                "Buchtel",
                "Buckeye Lake",
                "Buckland",
                "Bucyrus",
                "Burbank",
                "Burgoon",
                "Burkettsville",
                "Burlington",
                "Burton",
                "Butler",
                "Butlerville",
                "Byesville",
                "Cadiz",
                "Cairo",
                "Calcutta",
                "Caldwell",
                "Caledonia",
                "Cambridge",
                "Camden",
                "Campbell",
                "Canal Fulton",
                "Canal Winchester",
                "Canfield",
                "Canton",
                "Cardington",
                "Carey",
                "Carlisle",
                "Carroll",
                "Carrollton",
                "Casstown",
                "Castalia",
                "Castine",
                "Catawba",
                "Cecil",
                "Cedarville",
                "Celina",
                "Centerburg",
                "Centerville",
                "Chagrin Falls",
                "Champion Heights",
                "Chardon",
                "Chatfield",
                "Chauncey",
                "Cherry Fork",
                "Cherry Grove",
                "Chesapeake",
                "Cheshire",
                "Chesterhill",
                "Chesterland",
                "Chesterville",
                "Cheviot",
                "Chickasaw",
                "Chillicothe",
                "Chilo",
                "Chippewa Lake",
                "Choctaw Lake",
                "Christiansburg",
                "Churchill",
                "Cincinnati",
                "Circleville",
                "Clarington",
                "Clarksburg",
                "Clarksville",
                "Clay Center",
                "Clayton",
                "Cleveland",
                "Cleveland Heights",
                "Cleves",
                "Clifton",
                "Clinton",
                "Cloverdale",
                "Clyde",
                "Coal Grove",
                "Coalton",
                "Coldwater",
                "College Corner",
                "Columbiana",
                "Columbus",
                "Columbus Grove",
                "Commercial Point",
                "Conesville",
                "Congress",
                "Conneaut",
                "Continental",
                "Convoy",
                "Coolville",
                "Corning",
                "Cortland",
                "Corwin",
                "Coshocton",
                "Covedale",
                "Covington",
                "Craig Beach",
                "Crestline",
                "Creston",
                "Cridersville",
                "Crooksville",
                "Crown City",
                "Crystal Lakes",
                "Cumberland",
                "Custar",
                "Cuyahoga Falls",
                "Cuyahoga Heights",
                "Cygnet",
                "Dalton",
                "Danville",
                "Darbyville",
                "Day Heights",
                "Dayton",
                "De Graff",
                "Deer Park",
                "Deersville",
                "Defiance",
                "Delaware",
                "Dellroy",
                "Delphos",
                "Delta",
                "Dennison",
                "Dent",
                "Deshler",
                "Devola",
                "Dexter City",
                "Dillonvale",
                "Donnelsville",
                "Dover",
                "Doylestown",
                "Dresden",
                "Drexel",
                "Dry Run",
                "Dublin",
                "Dunkirk",
                "Dupont",
                "East Canton",
                "East Cleveland",
                "East Liverpool",
                "East Palestine",
                "East Sparta",
                "Eastlake",
                "Eaton",
                "Eaton Estates",
                "Edgerton",
                "Edgewood",
                "Edison",
                "Edon",
                "Eldorado",
                "Elgin",
                "Elida",
                "Elmore",
                "Elmwood Place",
                "Elyria",
                "Empire",
                "Englewood",
                "Enon",
                "Euclid",
                "Evendale",
                "Fairborn",
                "Fairfax",
                "Fairfield",
                "Fairfield Beach",
                "Fairlawn",
                "Fairport Harbor",
                "Fairview",
                "Fairview Lanes",
                "Fairview Park",
                "Farmersville",
                "Fayette",
                "Fayetteville",
                "Felicity",
                "Findlay",
                "Finneytown",
                "Five Points",
                "Fletcher",
                "Florida",
                "Flushing",
                "Forest",
                "Forest Park",
                "Forestville",
                "Fort Jennings",
                "Fort Loramie",
                "Fort Mckinley",
                "Fort Recovery",
                "Fort Shawnee",
                "Fostoria",
                "Frankfort",
                "Franklin",
                "Franklin Furnace",
                "Frazeysburg",
                "Fredericksburg",
                "Fredericktown",
                "Freeport",
                "Fremont",
                "Fruit Hill",
                "Fulton",
                "Fultonham",
                "Gahanna",
                "Galena",
                "Galion",
                "Gallipolis",
                "Gambier",
                "Gann",
                "Garfield Heights",
                "Garrettsville",
                "Gates Mills",
                "Geneva",
                "Geneva-on-the-lake",
                "Genoa",
                "Georgetown",
                "Germantown",
                "Gettysburg",
                "Gibsonburg",
                "Gilboa",
                "Girard",
                "Glandorf",
                "Glendale",
                "Glenford",
                "Glenmont",
                "Glenmoor",
                "Glenwillow",
                "Gloria Glens Park",
                "Glouster",
                "Gnadenhutten",
                "Golf Manor",
                "Gordon",
                "Grafton",
                "Grand Rapids",
                "Grand River",
                "Grandview",
                "Grandview Heights",
                "Granville",
                "Granville South",
                "Gratiot",
                "Gratis",
                "Graysville",
                "Green",
                "Green Camp",
                "Green Meadows",
                "Green Springs",
                "Greenfield",
                "Greenhills",
                "Greentown",
                "Greenville",
                "Greenwich",
                "Groesbeck",
                "Grove City",
                "Groveport",
                "Grover Hill",
                "Hamden",
                "Hamersville",
                "Hamilton",
                "Hamler",
                "Hanging Rock",
                "Hanover",
                "Hanoverton",
                "Harbor Hills",
                "Harbor View",
                "Harpster",
                "Harrisburg",
                "Harrison",
                "Harrisville",
                "Harrod",
                "Hartford",
                "Hartville",
                "Harveysburg",
                "Haskins",
                "Haviland",
                "Hayesville",
                "Heath",
                "Hebron",
                "Helena",
                "Hemlock",
                "Hicksville",
                "Higginsport",
                "Highland",
                "Highland Heights",
                "Highland Hills",
                "Hilliard",
                "Hills And Dales",
                "Hillsboro",
                "Hilltop",
                "Hiram",
                "Holgate",
                "Holiday City",
                "Holiday Valley",
                "Holland",
                "Hollansburg",
                "Holloway",
                "Holmesville",
                "Hopedale",
                "Howland Center",
                "Hoytville",
                "Hubbard",
                "Huber Heights",
                "Huber Ridge",
                "Hudson",
                "Hunter",
                "Hunting Valley",
                "Huntsville",
                "Huron",
                "Independence",
                "Irondale",
                "Ironton",
                "Ithaca",
                "Jackson",
                "Jackson Center",
                "Jacksonburg",
                "Jacksonville",
                "Jamestown",
                "Jefferson",
                "Jeffersonville",
                "Jenera",
                "Jeromesville",
                "Jerry City",
                "Jerusalem",
                "Jewett",
                "Johnstown",
                "Junction City",
                "Kalida",
                "Kelleys Island",
                "Kent",
                "Kenton",
                "Kenwood",
                "Kettering",
                "Kettlersville",
                "Killbuck",
                "Kimbolton",
                "Kingston",
                "Kipton",
                "Kirby",
                "Kirkersville",
                "Kirtland",
                "Kirtland Hills",
                "La Croft",
                "La Rue",
                "Lafayette",
                "Lagrange",
                "Lake Darby",
                "Lakeline",
                "Lakemore",
                "Lakeview",
                "Lakewood",
                "Lancaster",
                "Landen",
                "Latty",
                "Laura",
                "Laurelville",
                "Lawrenceville",
                "Leavittsburg",
                "Lebanon",
                "Leesburg",
                "Leesville",
                "Leetonia",
                "Leipsic",
                "Lewisburg",
                "Lewisville",
                "Lexington",
                "Liberty Center",
                "Lima",
                "Limaville",
                "Lincoln Heights",
                "Lincoln Village",
                "Lindsey",
                "Linndale",
                "Lisbon",
                "Lithopolis",
                "Lockbourne",
                "Lockington",
                "Lockland",
                "Lodi",
                "Logan",
                "Logan Elm Village",
                "London",
                "Lorain",
                "Lordstown",
                "Lore City",
                "Loudonville",
                "Louisville",
                "Loveland",
                "Loveland Park",
                "Lowell",
                "Lowellville",
                "Lower Salem",
                "Lucas",
                "Lucasville",
                "Luckey",
                "Ludlow Falls",
                "Lynchburg",
                "Lyndhurst",
                "Lyons",
                "Macedonia",
                "Mack North",
                "Mack South",
                "Macksburg",
                "Madeira",
                "Madison",
                "Magnetic Springs",
                "Magnolia",
                "Maineville",
                "Malinta",
                "Malta",
                "Malvern",
                "Manchester",
                "Mansfield",
                "Mantua",
                "Maple Heights",
                "Maple Ridge",
                "Maplewood Park",
                "Marble Cliff",
                "Marblehead",
                "Marengo",
                "Mariemont",
                "Marietta",
                "Marion",
                "Marseilles",
                "Marshallville",
                "Martins Ferry",
                "Martinsburg",
                "Martinsville",
                "Marysville",
                "Mason",
                "Massillon",
                "Masury",
                "Matamoras",
                "Maumee",
                "Mayfield",
                "Mayfield Heights",
                "Mcarthur",
                "Mcclure",
                "Mccomb",
                "Mcconnelsville",
                "Mcdonald",
                "Mcguffey",
                "Mechanicsburg",
                "Medina",
                "Melrose",
                "Mendon",
                "Mentor",
                "Mentor-on-the-lake",
                "Metamora",
                "Meyers Lake",
                "Miamisburg",
                "Middle Point",
                "Middleburg Heights",
                "Middlefield",
                "Middleport",
                "Middletown",
                "Midland",
                "Midvale",
                "Midway",
                "Mifflin",
                "Milan",
                "Milford",
                "Milford Center",
                "Millbury",
                "Milledgeville",
                "Miller City",
                "Millersburg",
                "Millersport",
                "Millville",
                "Milton Center",
                "Miltonsburg",
                "Mineral City",
                "Mineral Ridge",
                "Minerva",
                "Minerva Park",
                "Mingo Junction",
                "Minster",
                "Mogadore",
                "Monfort Heights East",
                "Monfort Heights South",
                "Monroe",
                "Monroeville",
                "Montezuma",
                "Montgomery",
                "Montpelier",
                "Montrose-ghent",
                "Moraine",
                "Moreland Hills",
                "Morral",
                "Morristown",
                "Morrow",
                "Moscow",
                "Mount Blanchard",
                "Mount Carmel",
                "Mount Cory",
                "Mount Eaton",
                "Mount Gilead",
                "Mount Healthy",
                "Mount Healthy Heights",
                "Mount Orab",
                "Mount Pleasant",
                "Mount Repose",
                "Mount Sterling",
                "Mount Vernon",
                "Mount Victory",
                "Mowrystown",
                "Mulberry",
                "Munroe Falls",
                "Murray City",
                "Mutual",
                "Napoleon",
                "Nashville",
                "Navarre",
                "Neffs",
                "Nellie",
                "Nelsonville",
                "Nevada",
                "Neville",
                "New Albany",
                "New Alexandria",
                "New Athens",
                "New Bavaria",
                "New Bloomington",
                "New Boston",
                "New Bremen",
                "New Carlisle",
                "New Concord",
                "New Franklin",
                "New Holland",
                "New Knoxville",
                "New Lebanon",
                "New Lexington",
                "New London",
                "New Madison",
                "New Miami",
                "New Middletown",
                "New Paris",
                "New Philadelphia",
                "New Richmond",
                "New Riegel",
                "New Rome",
                "New Straitsville",
                "New Vienna",
                "New Washington",
                "New Waterford",
                "New Weston",
                "Newark",
                "Newburgh Heights",
                "Newcomerstown",
                "Newton Falls",
                "Newtonsville",
                "Newtown",
                "Ney",
                "Niles",
                "North Baltimore",
                "North Bend",
                "North Canton",
                "North College Hill",
                "North Fairfield",
                "North Fork Village",
                "North Hampton",
                "North Kingsville",
                "North Lewisburg",
                "North Madison",
                "North Olmsted",
                "North Perry",
                "North Randall",
                "North Ridgeville",
                "North Robinson",
                "North Royalton",
                "North Star",
                "North Zanesville",
                "Northbrook",
                "Northfield",
                "Northgate",
                "Northridge",
                "Northwood",
                "Norton",
                "Norwalk",
                "Norwich",
                "Norwood",
                "Oak Harbor",
                "Oak Hill",
                "Oakwood",
                "Oberlin",
                "Obetz",
                "Octa",
                "Ohio City",
                "Old Washington",
                "Olde West Chester",
                "Olmsted Falls",
                "Ontario",
                "Orange",
                "Orangeville",
                "Oregon",
                "Orient",
                "Orrville",
                "Orwell",
                "Osgood",
                "Ostrander",
                "Ottawa",
                "Ottawa Hills",
                "Ottoville",
                "Otway",
                "Owensville",
                "Oxford",
                "Painesville",
                "Palestine",
                "Pandora",
                "Park Layne",
                "Parma",
                "Parma Heights",
                "Parral",
                "Pataskala",
                "Patterson",
                "Paulding",
                "Payne",
                "Peebles",
                "Pemberville",
                "Peninsula",
                "Pepper Pike",
                "Perry",
                "Perry Heights",
                "Perrysburg",
                "Perrysville",
                "Phillipsburg",
                "Philo",
                "Pickerington",
                "Pigeon Creek",
                "Piketon",
                "Pioneer",
                "Piqua",
                "Pitsburg",
                "Plain City",
                "Plainfield",
                "Pleasant City",
                "Pleasant Grove",
                "Pleasant Hill",
                "Pleasant Plain",
                "Pleasant Run",
                "Pleasant Run Farm",
                "Pleasantville",
                "Plymouth",
                "Poland",
                "Polk",
                "Pomeroy",
                "Port Clinton",
                "Port Jefferson",
                "Port Washington",
                "Port William",
                "Portage",
                "Portage Lakes",
                "Portsmouth",
                "Potsdam",
                "Powell",
                "Powhatan Point",
                "Proctorville",
                "Prospect",
                "Put-in-bay",
                "Quaker City",
                "Quincy",
                "Racine",
                "Rarden",
                "Ravenna",
                "Rawson",
                "Rayland",
                "Reading",
                "Reminderville",
                "Rendville",
                "Republic",
                "Reynoldsburg",
                "Richfield",
                "Richmond",
                "Richmond Heights",
                "Richwood",
                "Ridgeway",
                "Rio Grande",
                "Ripley",
                "Risingsun",
                "Rittman",
                "Riverlea",
                "Riverside",
                "Roaming Shores",
                "Rochester",
                "Rock Creek",
                "Rockford",
                "Rocky Ridge",
                "Rocky River",
                "Rogers",
                "Rome",
                "Rosemount",
                "Roseville",
                "Ross",
                "Rossburg",
                "Rossford",
                "Roswell",
                "Rushsylvania",
                "Rushville",
                "Russells Point",
                "Russellville",
                "Russia",
                "Rutland",
                "Sabina",
                "Salem",
                "Salesville",
                "Salineville",
                "Sandusky",
                "Sandusky South",
                "Sarahsville",
                "Sardinia",
                "Savannah",
                "Scio",
                "Sciotodale",
                "Scott",
                "Seaman",
                "Sebring",
                "Senecaville",
                "Seven Hills",
                "Seven Mile",
                "Seville",
                "Shadyside",
                "Shaker Heights",
                "Sharonville",
                "Shawnee",
                "Shawnee Hills",
                "Sheffield",
                "Sheffield Lake",
                "Shelby",
                "Sherrodsville",
                "Sherwood",
                "Shiloh",
                "Shreve",
                "Sidney",
                "Silver Lake",
                "Silverton",
                "Sinking Spring",
                "Smithfield",
                "Smithville",
                "Solon",
                "Somerset",
                "Somerville",
                "South Amherst",
                "South Bloomfield",
                "South Canal",
                "South Charleston",
                "South Euclid",
                "South Lebanon",
                "South Middletown",
                "South Point",
                "South Russell",
                "South Salem",
                "South Solon",
                "South Vienna",
                "South Webster",
                "South Zanesville",
                "Sparta",
                "Spencer",
                "Spencerville",
                "Spring Valley",
                "Springboro",
                "Springdale",
                "Springfield",
                "St. Bernard",
                "St. Clairsville",
                "St. Henry",
                "St. Louisville",
                "St. Martin",
                "St. Marys",
                "St. Paris",
                "Stafford",
                "Steubenville",
                "Stockport",
                "Stone Creek",
                "Stony Prairie",
                "Stoutsville",
                "Stow",
                "Strasburg",
                "Stratton",
                "Streetsboro",
                "Strongsville",
                "Struthers",
                "Stryker",
                "Sugar Bush Knolls",
                "Sugar Grove",
                "Sugarcreek",
                "Summerfield",
                "Summerside",
                "Summitville",
                "Sunbury",
                "Swanton",
                "Sycamore",
                "Sylvania",
                "Syracuse",
                "Tallmadge",
                "Tarlton",
                "Terrace Park",
                "The Plains",
                "The Village Of Indian Hill",
                "Thornville",
                "Thurston",
                "Tiffin",
                "Tiltonsville",
                "Timberlake",
                "Tipp City",
                "Tiro",
                "Toledo",
                "Tontogany",
                "Toronto",
                "Tremont City",
                "Trenton",
                "Trimble",
                "Trotwood",
                "Troy",
                "Turpin Hills",
                "Tuscarawas",
                "Twinsburg",
                "Uhrichsville",
                "Union",
                "Union City",
                "Uniontown",
                "Unionville Center",
                "Uniopolis",
                "University Heights",
                "Upper Arlington",
                "Upper Sandusky",
                "Urbana",
                "Urbancrest",
                "Utica",
                "Valley Hi",
                "Valley View",
                "Valleyview",
                "Van Buren",
                "Van Wert",
                "Vandalia",
                "Vanlue",
                "Venedocia",
                "Vermilion",
                "Verona",
                "Versailles",
                "Vienna Center",
                "Vinton",
                "Wadsworth",
                "Waite Hill",
                "Wakeman",
                "Walbridge",
                "Waldo",
                "Walton Hills",
                "Wapakoneta",
                "Warren",
                "Warrensville Heights",
                "Warsaw",
                "Washington",
                "Washingtonville",
                "Waterville",
                "Wauseon",
                "Waverly City",
                "Wayne",
                "Wayne Lakes",
                "Waynesburg",
                "Waynesfield",
                "Waynesville",
                "Wellington",
                "Wellston",
                "Wellsville",
                "West Alexandria",
                "West Carrollton City",
                "West Elkton",
                "West Farmington",
                "West Hill",
                "West Jefferson",
                "West Lafayette",
                "West Leipsic",
                "West Liberty",
                "West Manchester",
                "West Mansfield",
                "West Millgrove",
                "West Milton",
                "West Portsmouth",
                "West Rushville",
                "West Salem",
                "West Union",
                "West Unity",
                "Westerville",
                "Westfield Center",
                "Westlake",
                "Weston",
                "Wetherington",
                "Wharton",
                "Wheelersburg",
                "White Oak",
                "White Oak East",
                "White Oak West",
                "Whitehall",
                "Whitehouse",
                "Wickliffe",
                "Wilberforce",
                "Wilkesville",
                "Willard",
                "Williamsburg",
                "Williamsport",
                "Willoughby",
                "Willoughby Hills",
                "Willowick",
                "Willshire",
                "Wilmington",
                "Wilmot",
                "Wilson",
                "Winchester",
                "Windham",
                "Wintersville",
                "Withamsville",
                "Woodbourne-hyde Park",
                "Woodlawn",
                "Woodmere",
                "Woodsfield",
                "Woodstock",
                "Woodville",
                "Wooster",
                "Worthington",
                "Wren",
                "Wright-patterson Afb",
                "Wyoming",
                "Xenia",
                "Yankee Lake",
                "Yellow Springs",
                "Yorkshire",
                "Yorkville",
                "Youngstown",
                "Zaleski",
                "Zanesfield",
                "Zanesville",
                "Zoar"
            ],
            "Oklahoma": [
                "Achille",
                "Ada",
                "Adair",
                "Addington",
                "Afton",
                "Agra",
                "Akins",
                "Albion",
                "Alderson",
                "Alex",
                "Aline",
                "Allen",
                "Altus",
                "Alva",
                "Amber",
                "Ames",
                "Amorita",
                "Anadarko",
                "Antlers",
                "Apache",
                "Arapaho",
                "Arcadia",
                "Ardmore",
                "Arkoma",
                "Armstrong",
                "Arnett",
                "Asher",
                "Ashland",
                "Atoka",
                "Atwood",
                "Avant",
                "Avard",
                "Ballou",
                "Barnsdall",
                "Bartlesville",
                "Bearden",
                "Beaver",
                "Beggs",
                "Belfonte",
                "Bell",
                "Bennington",
                "Bernice",
                "Bessie",
                "Bethany",
                "Bethel Acres",
                "Big Cabin",
                "Billings",
                "Binger",
                "Bixby",
                "Blackburn",
                "Blackwell",
                "Blair",
                "Blanchard",
                "Bluejacket",
                "Boise City",
                "Bokchito",
                "Bokoshe",
                "Boley",
                "Boswell",
                "Bowlegs",
                "Boynton",
                "Bradley",
                "Braggs",
                "Braman",
                "Bray",
                "Breckenridge",
                "Brent",
                "Bridgeport",
                "Briggs",
                "Bristow",
                "Broken Arrow",
                "Broken Bow",
                "Bromide",
                "Brooksville",
                "Brush Creek",
                "Brushy",
                "Buffalo",
                "Bull Hollow",
                "Burbank",
                "Burlington",
                "Burns Flat",
                "Bushyhead",
                "Butler",
                "Byars",
                "Byng",
                "Byron",
                "Cache",
                "Caddo",
                "Calera",
                "Calumet",
                "Calvin",
                "Camargo",
                "Cameron",
                "Canadian",
                "Caney",
                "Canton",
                "Canute",
                "Capron",
                "Cardin",
                "Carlile",
                "Carmen",
                "Carnegie",
                "Carney",
                "Carrier",
                "Carter",
                "Cashion",
                "Castle",
                "Catoosa",
                "Cayuga",
                "Cedar Crest",
                "Cedar Valley",
                "Cement",
                "Centrahoma",
                "Central High",
                "Chandler",
                "Chattanooga",
                "Checotah",
                "Chelsea",
                "Cherokee",
                "Cherry Tree",
                "Chewey",
                "Cheyenne",
                "Chickasha",
                "Choctaw",
                "Chouteau",
                "Christie",
                "Cimarron City",
                "Claremore",
                "Clayton",
                "Clearview",
                "Cleo Springs",
                "Cleora",
                "Cleveland",
                "Clinton",
                "Cloud Creek",
                "Coalgate",
                "Colbert",
                "Colcord",
                "Cole",
                "Collinsville",
                "Colony",
                "Comanche",
                "Commerce",
                "Cooperton",
                "Copan",
                "Copeland",
                "Corn",
                "Cornish",
                "Council Hill",
                "Covington",
                "Coweta",
                "Cowlington",
                "Coyle",
                "Crescent",
                "Cromwell",
                "Crowder",
                "Cushing",
                "Custer City",
                "Cyril",
                "Dacoma",
                "Davenport",
                "Davidson",
                "Davis",
                "Deer Creek",
                "Del City",
                "Delaware",
                "Dennis",
                "Depew",
                "Devol",
                "Dewar",
                "Dewey",
                "Dibble",
                "Dickson",
                "Dill City",
                "Disney",
                "Dodge",
                "Dotyville",
                "Dougherty",
                "Douglas",
                "Dover",
                "Dripping Springs",
                "Drummond",
                "Drumright",
                "Dry Creek",
                "Duchess Landing",
                "Duncan",
                "Durant",
                "Dustin",
                "Dwight Mission",
                "Eakly",
                "Earlsboro",
                "East Duke",
                "Edmond",
                "El Reno",
                "Eldon",
                "Eldorado",
                "Elgin",
                "Elk City",
                "Elmer",
                "Elmore City",
                "Empire City",
                "Enid",
                "Erick",
                "Erin Springs",
                "Etowah",
                "Eufaula",
                "Evening Shade",
                "Fair Oaks",
                "Fairfax",
                "Fairfield",
                "Fairland",
                "Fairmont",
                "Fairview",
                "Fallis",
                "Fanshawe",
                "Fargo",
                "Faxon",
                "Fitzhugh",
                "Fletcher",
                "Flint Creek",
                "Flute Springs",
                "Foraker",
                "Forest Park",
                "Forgan",
                "Fort Cobb",
                "Fort Coffee",
                "Fort Gibson",
                "Fort Supply",
                "Fort Towson",
                "Foss",
                "Foyil",
                "Francis",
                "Frederick",
                "Freedom",
                "Gage",
                "Gans",
                "Garber",
                "Garvin",
                "Gate",
                "Geary",
                "Gene Autry",
                "Geronimo",
                "Gerty",
                "Glencoe",
                "Glenpool",
                "Goldsby",
                "Goltry",
                "Goodwell",
                "Gore",
                "Gotebo",
                "Gould",
                "Gracemont",
                "Grainola",
                "Grand Lake Towne",
                "Grandfield",
                "Granite",
                "Grayson",
                "Greasy",
                "Greenfield",
                "Gregory",
                "Grove",
                "Guthrie",
                "Guymon",
                "Haileyville",
                "Hall Park",
                "Hallett",
                "Hammon",
                "Hanna",
                "Hardesty",
                "Harrah",
                "Hartshorne",
                "Haskell",
                "Hastings",
                "Haworth",
                "Headrick",
                "Healdton",
                "Heavener",
                "Helena",
                "Hendrix",
                "Hennessey",
                "Henryetta",
                "Hickory",
                "Hillsdale",
                "Hinton",
                "Hitchcock",
                "Hitchita",
                "Hobart",
                "Hoffman",
                "Holdenville",
                "Hollis",
                "Hollister",
                "Hominy",
                "Hooker",
                "Hoot Owl",
                "Horntown",
                "Howe",
                "Hugo",
                "Hulbert",
                "Hunter",
                "Hydro",
                "Idabel",
                "Indiahoma",
                "Indianola",
                "Inola",
                "Iron Post",
                "Jamestown",
                "Jay",
                "Jefferson",
                "Jenks",
                "Jennings",
                "Jet",
                "Johnson",
                "Jones",
                "Justice",
                "Kansas",
                "Kaw City",
                "Kellyville",
                "Kemp",
                "Kendrick",
                "Kenefic",
                "Keota",
                "Ketchum",
                "Keyes",
                "Keys",
                "Kiefer",
                "Kildare",
                "Kingfisher",
                "Kingston",
                "Kinta",
                "Kiowa",
                "Knowles",
                "Konawa",
                "Krebs",
                "Kremlin",
                "Lahoma",
                "Lake Aluma",
                "Lamar",
                "Lambert",
                "Lamont",
                "Langley",
                "Langston",
                "Laverne",
                "Lawrence Creek",
                "Lawton",
                "Le Flore",
                "Leach",
                "Leedey",
                "Lehigh",
                "Lenapah",
                "Leon",
                "Lexington",
                "Liberty",
                "Lima",
                "Limestone",
                "Lindsay",
                "Loco",
                "Locust Grove",
                "Lone Grove",
                "Lone Wolf",
                "Long",
                "Longdale",
                "Longtown",
                "Lookeba",
                "Lost City",
                "Lotsee",
                "Loveland",
                "Loyal",
                "Luther",
                "Lyons Switch",
                "Macomb",
                "Madill",
                "Manchester",
                "Mangum",
                "Manitou",
                "Mannford",
                "Mannsville",
                "Maramec",
                "Marble City",
                "Marble City Community",
                "Marietta",
                "Marland",
                "Marlow",
                "Marshall",
                "Martha",
                "Maryetta",
                "Maud",
                "May",
                "Maysville",
                "Mazie",
                "Mcalester",
                "Mccord",
                "Mccurtain",
                "Mckey",
                "Mcloud",
                "Mead",
                "Medford",
                "Medicine Park",
                "Meeker",
                "Meno",
                "Meridian",
                "Miami",
                "Midwest City",
                "Milburn",
                "Mill Creek",
                "Millerton",
                "Minco",
                "Moffett",
                "Moore",
                "Mooreland",
                "Morris",
                "Morrison",
                "Mounds",
                "Mountain Park",
                "Mountain View",
                "Muldrow",
                "Mule Barn",
                "Mulhall",
                "Murphy",
                "Muskogee",
                "Mustang",
                "Mutual",
                "Narcissa",
                "Nash",
                "New Alluwe",
                "New Cordell",
                "New Eucha",
                "New Tulsa",
                "Newcastle",
                "Newkirk",
                "Nichols Hills",
                "Nicoma Park",
                "Ninnekah",
                "Noble",
                "Norge",
                "Norman",
                "North Enid",
                "North Miami",
                "Notchietown",
                "Nowata",
                "Oak Grove",
                "Oakhurst",
                "Oakland",
                "Oaks",
                "Oakwood",
                "Ochelata",
                "Oilton",
                "Okarche",
                "Okay",
                "Okeene",
                "Okemah",
                "Oklahoma City",
                "Okmulgee",
                "Oktaha",
                "Old Eucha",
                "Olustee",
                "Oologah",
                "Optima",
                "Orlando",
                "Osage",
                "Owasso",
                "Paden",
                "Panama",
                "Paoli",
                "Paradise Hill",
                "Park Hill",
                "Pauls Valley",
                "Pawhuska",
                "Pawnee",
                "Peavine",
                "Pensacola",
                "Peoria",
                "Perkins",
                "Perry",
                "Pettit",
                "Phillips",
                "Picher",
                "Piedmont",
                "Pin Oak Acres",
                "Pinhook Corners",
                "Pink",
                "Pittsburg",
                "Pocasset",
                "Pocola",
                "Ponca City",
                "Pond Creek",
                "Porter",
                "Porum",
                "Poteau",
                "Prague",
                "Prue",
                "Pryor Creek",
                "Pump Back",
                "Purcell",
                "Putnam",
                "Quapaw",
                "Quay",
                "Quinton",
                "Ralston",
                "Ramona",
                "Randlett",
                "Ratliff City",
                "Rattan",
                "Ravia",
                "Red Oak",
                "Red Rock",
                "Redbird",
                "Redbird Smith",
                "Remy",
                "Renfrow",
                "Rentiesville",
                "Reydon",
                "Ringling",
                "Ringwood",
                "Ripley",
                "River Bottom",
                "Rock Island",
                "Rocky",
                "Rocky Ford",
                "Rocky Mountain",
                "Roff",
                "Roland",
                "Roosevelt",
                "Rosedale",
                "Rosston",
                "Rush Springs",
                "Ryan",
                "Salem",
                "Salina",
                "Sallisaw",
                "Sams Corner",
                "Sand Hills",
                "Sand Springs",
                "Sapulpa",
                "Sasakwa",
                "Savanna",
                "Sawyer",
                "Sayre",
                "Schulter",
                "Scraper",
                "Seiling",
                "Seminole",
                "Sentinel",
                "Sequoyah",
                "Shady Grove",
                "Shady Point",
                "Shamrock",
                "Sharon",
                "Shattuck",
                "Shawnee",
                "Shidler",
                "Short",
                "Silo",
                "Simms",
                "Skedee",
                "Skiatook",
                "Slaughterville",
                "Slick",
                "Smith Village",
                "Smithville",
                "Snake Creek",
                "Snyder",
                "Soper",
                "Sour John",
                "South Coffeyville",
                "Sparks",
                "Spaulding",
                "Spavinaw",
                "Spencer",
                "Sperry",
                "Spiro",
                "Sportsmen Acres",
                "Sportsmen Acres Community",
                "Springer",
                "St. Louis",
                "Sterling",
                "Stidham",
                "Stigler",
                "Stillwater",
                "Stilwell",
                "Stonewall",
                "Stony Point",
                "Strang",
                "Stratford",
                "Stringtown",
                "Strong City",
                "Stroud",
                "Stuart",
                "Sugden",
                "Sulphur",
                "Summit",
                "Swink",
                "Sycamore",
                "Taft",
                "Tagg Flats",
                "Tahlequah",
                "Taiwah",
                "Talala",
                "Talihina",
                "Taloga",
                "Tamaha",
                "Tatums",
                "Tecumseh",
                "Temple",
                "Tenkiller",
                "Terlton",
                "Terral",
                "Texanna",
                "Texhoma",
                "Texola",
                "Thackerville",
                "The Village",
                "Thomas",
                "Tipton",
                "Tishomingo",
                "Tonkawa",
                "Tribbey",
                "Tryon",
                "Tullahassee",
                "Tulsa",
                "Tupelo",
                "Turley",
                "Tushka",
                "Tuttle",
                "Twin Oaks",
                "Tyrone",
                "Union City",
                "Valley Brook",
                "Valley Park",
                "Valliant",
                "Velma",
                "Vera",
                "Verden",
                "Vian",
                "Vici",
                "Vinita",
                "Wagoner",
                "Wainwright",
                "Wakita",
                "Walters",
                "Wanette",
                "Wann",
                "Wapanucka",
                "Warner",
                "Warr Acres",
                "Warwick",
                "Washington",
                "Watonga",
                "Watts",
                "Watts Community",
                "Waukomis",
                "Waurika",
                "Wayne",
                "Waynoka",
                "Weatherford",
                "Webb City",
                "Webbers Falls",
                "Welch",
                "Weleetka",
                "Welling",
                "Wellston",
                "West Peavine",
                "West Siloam Springs",
                "Westport",
                "Westville",
                "Wetumka",
                "Wewoka",
                "Whitefield",
                "Wickliffe",
                "Wilburton",
                "Willow",
                "Wilson",
                "Winchester",
                "Wister",
                "Woodall",
                "Woodlawn Park",
                "Woodville",
                "Woodward",
                "Wright City",
                "Wyandotte",
                "Wynnewood",
                "Wynona",
                "Yale",
                "Yeager",
                "Yukon",
                "Zeb",
                "Zena",
                "Zion"
            ],
            "Ontario": [
                "Achille",
                "Ada",
                "Adair",
                "Addington",
                "Afton",
                "Agra",
                "Akins",
                "Albion",
                "Alderson",
                "Alex",
                "Aline",
                "Allen",
                "Altus",
                "Alva",
                "Amber",
                "Ames",
                "Amorita",
                "Anadarko",
                "Antlers",
                "Apache",
                "Arapaho",
                "Arcadia",
                "Ardmore",
                "Arkoma",
                "Armstrong",
                "Arnett",
                "Asher",
                "Ashland",
                "Atoka",
                "Atwood",
                "Avant",
                "Avard",
                "Ballou",
                "Barnsdall",
                "Bartlesville",
                "Bearden",
                "Beaver",
                "Beggs",
                "Belfonte",
                "Bell",
                "Bennington",
                "Bernice",
                "Bessie",
                "Bethany",
                "Bethel Acres",
                "Big Cabin",
                "Billings",
                "Binger",
                "Bixby",
                "Blackburn",
                "Blackwell",
                "Blair",
                "Blanchard",
                "Bluejacket",
                "Boise City",
                "Bokchito",
                "Bokoshe",
                "Boley",
                "Boswell",
                "Bowlegs",
                "Boynton",
                "Bradley",
                "Braggs",
                "Braman",
                "Bray",
                "Breckenridge",
                "Brent",
                "Bridgeport",
                "Briggs",
                "Bristow",
                "Broken Arrow",
                "Broken Bow",
                "Bromide",
                "Brooksville",
                "Brush Creek",
                "Brushy",
                "Buffalo",
                "Bull Hollow",
                "Burbank",
                "Burlington",
                "Burns Flat",
                "Bushyhead",
                "Butler",
                "Byars",
                "Byng",
                "Byron",
                "Cache",
                "Caddo",
                "Calera",
                "Calumet",
                "Calvin",
                "Camargo",
                "Cameron",
                "Canadian",
                "Caney",
                "Canton",
                "Canute",
                "Capron",
                "Cardin",
                "Carlile",
                "Carmen",
                "Carnegie",
                "Carney",
                "Carrier",
                "Carter",
                "Cashion",
                "Castle",
                "Catoosa",
                "Cayuga",
                "Cedar Crest",
                "Cedar Valley",
                "Cement",
                "Centrahoma",
                "Central High",
                "Chandler",
                "Chattanooga",
                "Checotah",
                "Chelsea",
                "Cherokee",
                "Cherry Tree",
                "Chewey",
                "Cheyenne",
                "Chickasha",
                "Choctaw",
                "Chouteau",
                "Christie",
                "Cimarron City",
                "Claremore",
                "Clayton",
                "Clearview",
                "Cleo Springs",
                "Cleora",
                "Cleveland",
                "Clinton",
                "Cloud Creek",
                "Coalgate",
                "Colbert",
                "Colcord",
                "Cole",
                "Collinsville",
                "Colony",
                "Comanche",
                "Commerce",
                "Cooperton",
                "Copan",
                "Copeland",
                "Corn",
                "Cornish",
                "Council Hill",
                "Covington",
                "Coweta",
                "Cowlington",
                "Coyle",
                "Crescent",
                "Cromwell",
                "Crowder",
                "Cushing",
                "Custer City",
                "Cyril",
                "Dacoma",
                "Davenport",
                "Davidson",
                "Davis",
                "Deer Creek",
                "Del City",
                "Delaware",
                "Dennis",
                "Depew",
                "Devol",
                "Dewar",
                "Dewey",
                "Dibble",
                "Dickson",
                "Dill City",
                "Disney",
                "Dodge",
                "Dotyville",
                "Dougherty",
                "Douglas",
                "Dover",
                "Dripping Springs",
                "Drummond",
                "Drumright",
                "Dry Creek",
                "Duchess Landing",
                "Duncan",
                "Durant",
                "Dustin",
                "Dwight Mission",
                "Eakly",
                "Earlsboro",
                "East Duke",
                "Edmond",
                "El Reno",
                "Eldon",
                "Eldorado",
                "Elgin",
                "Elk City",
                "Elmer",
                "Elmore City",
                "Empire City",
                "Enid",
                "Erick",
                "Erin Springs",
                "Etowah",
                "Eufaula",
                "Evening Shade",
                "Fair Oaks",
                "Fairfax",
                "Fairfield",
                "Fairland",
                "Fairmont",
                "Fairview",
                "Fallis",
                "Fanshawe",
                "Fargo",
                "Faxon",
                "Fitzhugh",
                "Fletcher",
                "Flint Creek",
                "Flute Springs",
                "Foraker",
                "Forest Park",
                "Forgan",
                "Fort Cobb",
                "Fort Coffee",
                "Fort Gibson",
                "Fort Supply",
                "Fort Towson",
                "Foss",
                "Foyil",
                "Francis",
                "Frederick",
                "Freedom",
                "Gage",
                "Gans",
                "Garber",
                "Garvin",
                "Gate",
                "Geary",
                "Gene Autry",
                "Geronimo",
                "Gerty",
                "Glencoe",
                "Glenpool",
                "Goldsby",
                "Goltry",
                "Goodwell",
                "Gore",
                "Gotebo",
                "Gould",
                "Gracemont",
                "Grainola",
                "Grand Lake Towne",
                "Grandfield",
                "Granite",
                "Grayson",
                "Greasy",
                "Greenfield",
                "Gregory",
                "Grove",
                "Guthrie",
                "Guymon",
                "Haileyville",
                "Hall Park",
                "Hallett",
                "Hammon",
                "Hanna",
                "Hardesty",
                "Harrah",
                "Hartshorne",
                "Haskell",
                "Hastings",
                "Haworth",
                "Headrick",
                "Healdton",
                "Heavener",
                "Helena",
                "Hendrix",
                "Hennessey",
                "Henryetta",
                "Hickory",
                "Hillsdale",
                "Hinton",
                "Hitchcock",
                "Hitchita",
                "Hobart",
                "Hoffman",
                "Holdenville",
                "Hollis",
                "Hollister",
                "Hominy",
                "Hooker",
                "Hoot Owl",
                "Horntown",
                "Howe",
                "Hugo",
                "Hulbert",
                "Hunter",
                "Hydro",
                "Idabel",
                "Indiahoma",
                "Indianola",
                "Inola",
                "Iron Post",
                "Jamestown",
                "Jay",
                "Jefferson",
                "Jenks",
                "Jennings",
                "Jet",
                "Johnson",
                "Jones",
                "Justice",
                "Kansas",
                "Kaw City",
                "Kellyville",
                "Kemp",
                "Kendrick",
                "Kenefic",
                "Keota",
                "Ketchum",
                "Keyes",
                "Keys",
                "Kiefer",
                "Kildare",
                "Kingfisher",
                "Kingston",
                "Kinta",
                "Kiowa",
                "Knowles",
                "Konawa",
                "Krebs",
                "Kremlin",
                "Lahoma",
                "Lake Aluma",
                "Lamar",
                "Lambert",
                "Lamont",
                "Langley",
                "Langston",
                "Laverne",
                "Lawrence Creek",
                "Lawton",
                "Le Flore",
                "Leach",
                "Leedey",
                "Lehigh",
                "Lenapah",
                "Leon",
                "Lexington",
                "Liberty",
                "Lima",
                "Limestone",
                "Lindsay",
                "Loco",
                "Locust Grove",
                "Lone Grove",
                "Lone Wolf",
                "Long",
                "Longdale",
                "Longtown",
                "Lookeba",
                "Lost City",
                "Lotsee",
                "Loveland",
                "Loyal",
                "Luther",
                "Lyons Switch",
                "Macomb",
                "Madill",
                "Manchester",
                "Mangum",
                "Manitou",
                "Mannford",
                "Mannsville",
                "Maramec",
                "Marble City",
                "Marble City Community",
                "Marietta",
                "Marland",
                "Marlow",
                "Marshall",
                "Martha",
                "Maryetta",
                "Maud",
                "May",
                "Maysville",
                "Mazie",
                "Mcalester",
                "Mccord",
                "Mccurtain",
                "Mckey",
                "Mcloud",
                "Mead",
                "Medford",
                "Medicine Park",
                "Meeker",
                "Meno",
                "Meridian",
                "Miami",
                "Midwest City",
                "Milburn",
                "Mill Creek",
                "Millerton",
                "Minco",
                "Moffett",
                "Moore",
                "Mooreland",
                "Morris",
                "Morrison",
                "Mounds",
                "Mountain Park",
                "Mountain View",
                "Muldrow",
                "Mule Barn",
                "Mulhall",
                "Murphy",
                "Muskogee",
                "Mustang",
                "Mutual",
                "Narcissa",
                "Nash",
                "New Alluwe",
                "New Cordell",
                "New Eucha",
                "New Tulsa",
                "Newcastle",
                "Newkirk",
                "Nichols Hills",
                "Nicoma Park",
                "Ninnekah",
                "Noble",
                "Norge",
                "Norman",
                "North Enid",
                "North Miami",
                "Notchietown",
                "Nowata",
                "Oak Grove",
                "Oakhurst",
                "Oakland",
                "Oaks",
                "Oakwood",
                "Ochelata",
                "Oilton",
                "Okarche",
                "Okay",
                "Okeene",
                "Okemah",
                "Oklahoma City",
                "Okmulgee",
                "Oktaha",
                "Old Eucha",
                "Olustee",
                "Oologah",
                "Optima",
                "Orlando",
                "Osage",
                "Owasso",
                "Paden",
                "Panama",
                "Paoli",
                "Paradise Hill",
                "Park Hill",
                "Pauls Valley",
                "Pawhuska",
                "Pawnee",
                "Peavine",
                "Pensacola",
                "Peoria",
                "Perkins",
                "Perry",
                "Pettit",
                "Phillips",
                "Picher",
                "Piedmont",
                "Pin Oak Acres",
                "Pinhook Corners",
                "Pink",
                "Pittsburg",
                "Pocasset",
                "Pocola",
                "Ponca City",
                "Pond Creek",
                "Porter",
                "Porum",
                "Poteau",
                "Prague",
                "Prue",
                "Pryor Creek",
                "Pump Back",
                "Purcell",
                "Putnam",
                "Quapaw",
                "Quay",
                "Quinton",
                "Ralston",
                "Ramona",
                "Randlett",
                "Ratliff City",
                "Rattan",
                "Ravia",
                "Red Oak",
                "Red Rock",
                "Redbird",
                "Redbird Smith",
                "Remy",
                "Renfrow",
                "Rentiesville",
                "Reydon",
                "Ringling",
                "Ringwood",
                "Ripley",
                "River Bottom",
                "Rock Island",
                "Rocky",
                "Rocky Ford",
                "Rocky Mountain",
                "Roff",
                "Roland",
                "Roosevelt",
                "Rosedale",
                "Rosston",
                "Rush Springs",
                "Ryan",
                "Salem",
                "Salina",
                "Sallisaw",
                "Sams Corner",
                "Sand Hills",
                "Sand Springs",
                "Sapulpa",
                "Sasakwa",
                "Savanna",
                "Sawyer",
                "Sayre",
                "Schulter",
                "Scraper",
                "Seiling",
                "Seminole",
                "Sentinel",
                "Sequoyah",
                "Shady Grove",
                "Shady Point",
                "Shamrock",
                "Sharon",
                "Shattuck",
                "Shawnee",
                "Shidler",
                "Short",
                "Silo",
                "Simms",
                "Skedee",
                "Skiatook",
                "Slaughterville",
                "Slick",
                "Smith Village",
                "Smithville",
                "Snake Creek",
                "Snyder",
                "Soper",
                "Sour John",
                "South Coffeyville",
                "Sparks",
                "Spaulding",
                "Spavinaw",
                "Spencer",
                "Sperry",
                "Spiro",
                "Sportsmen Acres",
                "Sportsmen Acres Community",
                "Springer",
                "St. Louis",
                "Sterling",
                "Stidham",
                "Stigler",
                "Stillwater",
                "Stilwell",
                "Stonewall",
                "Stony Point",
                "Strang",
                "Stratford",
                "Stringtown",
                "Strong City",
                "Stroud",
                "Stuart",
                "Sugden",
                "Sulphur",
                "Summit",
                "Swink",
                "Sycamore",
                "Taft",
                "Tagg Flats",
                "Tahlequah",
                "Taiwah",
                "Talala",
                "Talihina",
                "Taloga",
                "Tamaha",
                "Tatums",
                "Tecumseh",
                "Temple",
                "Tenkiller",
                "Terlton",
                "Terral",
                "Texanna",
                "Texhoma",
                "Texola",
                "Thackerville",
                "The Village",
                "Thomas",
                "Tipton",
                "Tishomingo",
                "Tonkawa",
                "Tribbey",
                "Tryon",
                "Tullahassee",
                "Tulsa",
                "Tupelo",
                "Turley",
                "Tushka",
                "Tuttle",
                "Twin Oaks",
                "Tyrone",
                "Union City",
                "Valley Brook",
                "Valley Park",
                "Valliant",
                "Velma",
                "Vera",
                "Verden",
                "Vian",
                "Vici",
                "Vinita",
                "Wagoner",
                "Wainwright",
                "Wakita",
                "Walters",
                "Wanette",
                "Wann",
                "Wapanucka",
                "Warner",
                "Warr Acres",
                "Warwick",
                "Washington",
                "Watonga",
                "Watts",
                "Watts Community",
                "Waukomis",
                "Waurika",
                "Wayne",
                "Waynoka",
                "Weatherford",
                "Webb City",
                "Webbers Falls",
                "Welch",
                "Weleetka",
                "Welling",
                "Wellston",
                "West Peavine",
                "West Siloam Springs",
                "Westport",
                "Westville",
                "Wetumka",
                "Wewoka",
                "Whitefield",
                "Wickliffe",
                "Wilburton",
                "Willow",
                "Wilson",
                "Winchester",
                "Wister",
                "Woodall",
                "Woodlawn Park",
                "Woodville",
                "Woodward",
                "Wright City",
                "Wyandotte",
                "Wynnewood",
                "Wynona",
                "Yale",
                "Yeager",
                "Yukon",
                "Zeb",
                "Zena",
                "Zion"
            ],
            "Oregon": [
                "Adair Village",
                "Adams",
                "Adrian",
                "Albany",
                "Aloha",
                "Altamont",
                "Amity",
                "Antelope",
                "Arlington",
                "Ashland",
                "Astoria",
                "Athena",
                "Aumsville",
                "Aurora",
                "Baker City",
                "Bandon",
                "Banks",
                "Barlow",
                "Barview",
                "Bay City",
                "Beaver",
                "Beaverton",
                "Bend",
                "Biggs Junction",
                "Boardman",
                "Bonanza",
                "Brookings",
                "Brooks",
                "Brownsville",
                "Bunker Hill",
                "Burns",
                "Butte Falls",
                "Butteville",
                "Canby",
                "Cannon Beach",
                "Canyon City",
                "Canyonville",
                "Cape Meares",
                "Carlton",
                "Cascade Locks",
                "Cave Junction",
                "Cayuse",
                "Cedar Hills",
                "Cedar Mill",
                "Central Point",
                "Chenoweth",
                "Chiloquin",
                "City Of The Dalles",
                "Clackamas",
                "Clatskanie",
                "Cloverdale",
                "Coburg",
                "Columbia City",
                "Condon",
                "Coos Bay",
                "Coquille",
                "Cornelius",
                "Corvallis",
                "Cottage Grove",
                "Cove",
                "Creswell",
                "Culver",
                "Dallas",
                "Dayton",
                "Dayville",
                "Depoe Bay",
                "Deschutes River Woods",
                "Detroit",
                "Donald",
                "Drain",
                "Dufur",
                "Dundee",
                "Dunes City",
                "Durham",
                "Eagle Point",
                "Echo",
                "Elgin",
                "Elkton",
                "Enterprise",
                "Eola",
                "Estacada",
                "Eugene",
                "Fairview",
                "Falls City",
                "Florence",
                "Forest Grove",
                "Fossil",
                "Four Corners",
                "Garden Home-whitford",
                "Garibaldi",
                "Gaston",
                "Gates",
                "Gearhart",
                "Gervais",
                "Gladstone",
                "Glendale",
                "Glide",
                "Gold Beach",
                "Gold Hill",
                "Gopher Flats",
                "Grand Ronde",
                "Granite",
                "Grants Pass",
                "Grass Valley",
                "Green",
                "Greenhorn",
                "Gresham",
                "Haines",
                "Halfway",
                "Halsey",
                "Happy Valley",
                "Harbeck-fruitdale",
                "Harbor",
                "Harrisburg",
                "Hayesville",
                "Hebo",
                "Helix",
                "Heppner",
                "Hermiston",
                "Hillsboro",
                "Hines",
                "Hood River",
                "Hubbard",
                "Huntington",
                "Idanha",
                "Imbler",
                "Independence",
                "Ione",
                "Irrigon",
                "Island City",
                "Jacksonville",
                "Jefferson",
                "Jennings Lodge",
                "John Day",
                "Johnson City",
                "Jordan Valley",
                "Joseph",
                "Junction City",
                "Keizer",
                "King City",
                "Kirkpatrick",
                "Klamath Falls",
                "La Grande",
                "La Pine",
                "Labish Village",
                "Lafayette",
                "Lake Oswego",
                "Lakeside",
                "Lakeview",
                "Lebanon",
                "Lexington",
                "Lincoln Beach",
                "Lincoln City",
                "Lonerock",
                "Long Creek",
                "Lostine",
                "Lowell",
                "Lyons",
                "Madras",
                "Malin",
                "Manzanita",
                "Marion",
                "Maupin",
                "Maywood Park",
                "Mcminnville",
                "Medford",
                "Mehama",
                "Merrill",
                "Metolius",
                "Metzger",
                "Mill City",
                "Millersburg",
                "Milton-freewater",
                "Milwaukie",
                "Mission",
                "Mitchell",
                "Molalla",
                "Monmouth",
                "Monroe",
                "Monument",
                "Moro",
                "Mosier",
                "Mount Angel",
                "Mount Hood Village",
                "Mount Vernon",
                "Myrtle Creek",
                "Myrtle Point",
                "Nehalem",
                "Neskowin",
                "Netarts",
                "Newberg",
                "Newport",
                "North Bend",
                "North Plains",
                "North Powder",
                "Nyssa",
                "Oak Grove",
                "Oak Hills",
                "Oakland",
                "Oakridge",
                "Oatfield",
                "Oceanside",
                "Odell",
                "Ontario",
                "Oregon City",
                "Pacific City",
                "Paisley",
                "Parkdale",
                "Pendleton",
                "Philomath",
                "Phoenix",
                "Pilot Rock",
                "Pine Grove",
                "Pine Hollow",
                "Port Orford",
                "Portland",
                "Powers",
                "Prairie City",
                "Prescott",
                "Prineville",
                "Rainier",
                "Raleigh Hills",
                "Redmond",
                "Redwood",
                "Reedsport",
                "Richland",
                "Rickreall",
                "Riddle",
                "Rivergrove",
                "Riverside",
                "Rockaway Beach",
                "Rockcreek",
                "Rogue River",
                "Rose Lodge",
                "Roseburg",
                "Roseburg North",
                "Rowena",
                "Rufus",
                "Salem",
                "Sandy",
                "Scappoose",
                "Scio",
                "Scotts Mills",
                "Seaside",
                "Seneca",
                "Shady Cove",
                "Shaniko",
                "Sheridan",
                "Sherwood",
                "Siletz",
                "Silverton",
                "Sisters",
                "Sodaville",
                "South Lebanon",
                "Spray",
                "Springfield",
                "St. Helens",
                "St. Paul",
                "Stanfield",
                "Stayton",
                "Sublimity",
                "Summerville",
                "Sumpter",
                "Sunnyside",
                "Sutherlin",
                "Sweet Home",
                "Talent",
                "Tangent",
                "Terrebonne",
                "Three Rivers",
                "Tigard",
                "Tillamook",
                "Toledo",
                "Tri-city",
                "Troutdale",
                "Tualatin",
                "Turner",
                "Tutuilla",
                "Tygh Valley",
                "Ukiah",
                "Umatilla",
                "Union",
                "Unity",
                "Vale",
                "Veneta",
                "Vernonia",
                "Waldport",
                "Wallowa",
                "Wamic",
                "Warm Springs",
                "Warrenton",
                "Wasco",
                "Waterloo",
                "West Haven-sylvan",
                "West Linn",
                "West Slope",
                "Westfir",
                "Weston",
                "Wheeler",
                "White City",
                "Willamina",
                "Wilsonville",
                "Winchester Bay",
                "Winston",
                "Wood Village",
                "Woodburn",
                "Yachats",
                "Yamhill",
                "Yoncalla"
            ],
            "Palau": [
                "Palau"
            ],
            "Pennsylvania": [
                "Aaronsburg",
                "Abbottstown",
                "Adamsburg",
                "Adamstown",
                "Adamsville",
                "Addison",
                "Akron",
                "Alba",
                "Albion",
                "Alburtis",
                "Aldan",
                "Alexandria",
                "Aliquippa",
                "Allenport",
                "Allentown",
                "Almedia",
                "Altamont",
                "Altoona",
                "Ambler",
                "Ambridge",
                "Amity Gardens",
                "Ancient Oaks",
                "Annville",
                "Apollo",
                "Applewold",
                "Archbald",
                "Ardmore",
                "Arendtsville",
                "Aristes",
                "Arlington Heights",
                "Armagh",
                "Arnold",
                "Arona",
                "Ashland",
                "Ashley",
                "Ashville",
                "Aspinwall",
                "Atglen",
                "Athens",
                "Atlantic",
                "Atwood",
                "Auburn",
                "Audubon",
                "Austin",
                "Avalon",
                "Avis",
                "Avoca",
                "Avon",
                "Avondale",
                "Avonia",
                "Avonmore",
                "Back Mountain",
                "Baden",
                "Baidland",
                "Baldwin",
                "Bally",
                "Bangor",
                "Barkeyville",
                "Bath",
                "Beallsville",
                "Bear Creek Village",
                "Bear Lake",
                "Beaver",
                "Beaver Falls",
                "Beaver Meadows",
                "Beaver Springs",
                "Beaverdale-lloydell",
                "Beavertown",
                "Bechtelsville",
                "Bedford",
                "Beech Creek",
                "Belfast",
                "Bell Acres",
                "Belle Vernon",
                "Bellefonte",
                "Belleville",
                "Bellevue",
                "Bellwood",
                "Belmont",
                "Ben Avon",
                "Ben Avon Heights",
                "Bendersville",
                "Bendersville Station-aspers",
                "Benson",
                "Bentleyville",
                "Benton",
                "Berlin",
                "Bernville",
                "Berrysburg",
                "Berwick",
                "Bessemer",
                "Bethany",
                "Bethel Park",
                "Bethlehem",
                "Beurys Lake",
                "Big Beaver",
                "Big Run",
                "Biglerville",
                "Birdsboro",
                "Birmingham",
                "Black Lick",
                "Blain",
                "Blairsville",
                "Blakely",
                "Blanchard",
                "Blawnox",
                "Bloomfield",
                "Blooming Valley",
                "Bloomsburg",
                "Blossburg",
                "Blue Bell",
                "Boalsburg",
                "Boiling Springs",
                "Bolivar",
                "Bonneauville",
                "Boothwyn",
                "Boswell",
                "Bowmanstown",
                "Boyertown",
                "Brackenridge",
                "Braddock",
                "Braddock Hills",
                "Bradford",
                "Bradfordwoods",
                "Branchdale",
                "Brandonville",
                "Brentwood",
                "Bressler-enhaut-oberlin",
                "Briar Creek",
                "Brickerville",
                "Bridgeport",
                "Bridgeville",
                "Bridgewater",
                "Brisbin",
                "Bristol",
                "Brittany Farms-highlands",
                "Broad Top City",
                "Brockway",
                "Brodheadsville",
                "Brookhaven",
                "Brookville",
                "Broomall",
                "Brownstown",
                "Brownsville",
                "Bruin",
                "Bryn Athyn",
                "Bryn Mawr",
                "Buck Run",
                "Buckhorn",
                "Burgettstown",
                "Burlington",
                "Burnham",
                "Burnside",
                "Butler",
                "California",
                "Callensburg",
                "Callery",
                "Callimont",
                "Calumet-norvelt",
                "Cambridge Springs",
                "Camp Hill",
                "Campbelltown",
                "Canadohta Lake",
                "Canonsburg",
                "Canton",
                "Carbondale",
                "Carlisle",
                "Carmichaels",
                "Carnegie",
                "Carnot-moon",
                "Carroll Valley",
                "Carrolltown",
                "Cashtown-mcknightstown",
                "Cassandra",
                "Casselman",
                "Cassville",
                "Castanea",
                "Castle Shannon",
                "Catasauqua",
                "Catawissa",
                "Cecil-bishop",
                "Centerport",
                "Centerville",
                "Central City",
                "Centralia",
                "Centre Hall",
                "Chalfant",
                "Chalfont",
                "Chambersburg",
                "Chapman",
                "Charleroi",
                "Cherry Tree",
                "Cherry Valley",
                "Chest Springs",
                "Chester",
                "Chester Heights",
                "Chester Hill",
                "Chester Township",
                "Chesterbrook",
                "Cheswick",
                "Chevy Chase Heights",
                "Chicora",
                "Christiana",
                "Churchill",
                "Churchville",
                "Clairton",
                "Clarence",
                "Clarendon",
                "Clarion",
                "Clark",
                "Clarks Green",
                "Clarks Summit",
                "Clarksville",
                "Claysburg",
                "Claysville",
                "Clearfield",
                "Cleona",
                "Clifton Heights",
                "Clintonville",
                "Clymer",
                "Coal Center",
                "Coaldale",
                "Coalmont",
                "Coalport",
                "Coatesville",
                "Coburn",
                "Cochranton",
                "Cokeburg",
                "Collegeville",
                "Collingdale",
                "Colonial Park",
                "Columbia",
                "Colver",
                "Colwyn",
                "Commodore",
                "Confluence",
                "Conneaut Lake",
                "Conneaut Lakeshore",
                "Conneautville",
                "Connellsville",
                "Connoquenessing",
                "Conshohocken",
                "Conway",
                "Conyngham",
                "Coopersburg",
                "Cooperstown",
                "Coplay",
                "Coraopolis",
                "Cornwall",
                "Cornwells Heights-eddington",
                "Corry",
                "Corsica",
                "Coudersport",
                "Courtdale",
                "Crabtree",
                "Crafton",
                "Cranesville",
                "Creekside",
                "Cresson",
                "Cressona",
                "Cross Roads",
                "Croydon",
                "Curtisville",
                "Curwensville",
                "Daisytown",
                "Dale",
                "Dallas",
                "Dallastown",
                "Dalton",
                "Danville",
                "Darby",
                "Darby Township",
                "Darlington",
                "Dauphin",
                "Davidsville",
                "Dawson",
                "Dayton",
                "Deemston",
                "Deer Lake",
                "Delano",
                "Delaware Water Gap",
                "Delmont",
                "Delta",
                "Denver",
                "Derry",
                "Devon-berwyn",
                "Dickson City",
                "Dicksonville",
                "Dillsburg",
                "Donaldson",
                "Donegal",
                "Donora",
                "Dormont",
                "Dover",
                "Downingtown",
                "Doylestown",
                "Dravosburg",
                "Drexel Hill",
                "Driftwood",
                "Dublin",
                "Dubois",
                "Duboistown",
                "Dudley",
                "Dunbar",
                "Duncannon",
                "Duncansville",
                "Dunlevy",
                "Dunmore",
                "Dunnstown",
                "Dupont",
                "Duquesne",
                "Duryea",
                "Dushore",
                "Eagles Mere",
                "Eagleville",
                "East Bangor",
                "East Berlin",
                "East Berwick",
                "East Brady",
                "East Butler",
                "East Conemaugh",
                "East Greenville",
                "East Lansdowne",
                "East Mckeesport",
                "East Norriton",
                "East Petersburg",
                "East Pittsburgh",
                "East Prospect",
                "East Rochester",
                "East Rutherford",
                "East Side",
                "East Stroudsburg",
                "East Uniontown",
                "East Vandergrift",
                "East Washington",
                "East York",
                "Eastlawn Gardens",
                "Easton",
                "Eastvale",
                "Eau Claire",
                "Ebensburg",
                "Economy",
                "Eddystone",
                "Edgewood",
                "Edgeworth",
                "Edinboro",
                "Edwardsville",
                "Ehrenfeld",
                "Elco",
                "Elderton",
                "Eldred",
                "Elgin",
                "Elim",
                "Elizabeth",
                "Elizabethtown",
                "Elizabethville",
                "Elkland",
                "Ellport",
                "Ellsworth",
                "Ellwood City",
                "Elverson",
                "Elysburg",
                "Emigsville",
                "Emlenton",
                "Emmaus",
                "Emporium",
                "Emsworth",
                "Englewood",
                "Enola",
                "Enon Valley",
                "Ephrata",
                "Erie",
                "Ernest",
                "Espy",
                "Etna",
                "Evans City",
                "Evansburg",
                "Everett",
                "Everson",
                "Exeter",
                "Export",
                "Exton",
                "Eyers Grove",
                "Factoryville",
                "Fairchance",
                "Fairdale",
                "Fairfield",
                "Fairless Hills",
                "Fairview",
                "Fairview-ferndale",
                "Falls Creek",
                "Fallston",
                "Farrell",
                "Fawn Grove",
                "Fayette City",
                "Fayetteville",
                "Feasterville-trevose",
                "Felton",
                "Ferndale",
                "Fernville",
                "Fernway",
                "Finleyville",
                "Fleetwood",
                "Flemington",
                "Flourtown",
                "Flying Hills",
                "Folcroft",
                "Folsom",
                "Ford City",
                "Ford Cliff",
                "Forest City",
                "Forest Hills",
                "Forksville",
                "Forrestville",
                "Fort Indiantown Gap",
                "Fort Washington",
                "Forty Fort",
                "Foundryville",
                "Fountain Hill",
                "Fountain Springs",
                "Fox Chapel",
                "Fox Run",
                "Foxburg",
                "Frackville",
                "Frankfort Springs",
                "Franklin",
                "Franklin Park",
                "Franklintown",
                "Fredericksburg",
                "Fredericktown-millsboro",
                "Fredonia",
                "Freeburg",
                "Freedom",
                "Freeland",
                "Freemansburg",
                "Freeport",
                "Friedens",
                "Friedensburg",
                "Friendsville",
                "Fullerton",
                "Galeton",
                "Gallitzin",
                "Gap",
                "Garden View",
                "Garrett",
                "Gastonville",
                "Geistown",
                "Geneva",
                "Georgetown",
                "Gettysburg",
                "Gilberton",
                "Gilbertsville",
                "Girard",
                "Girardville",
                "Glasgow",
                "Glassport",
                "Glen Campbell",
                "Glen Hope",
                "Glen Lyon",
                "Glen Rock",
                "Glenburn",
                "Glendon",
                "Glenfield",
                "Glenolden",
                "Glenside",
                "Goldsboro",
                "Gordon",
                "Grampian",
                "Grantley",
                "Grapeville",
                "Gratz",
                "Great Bend",
                "Green Hills",
                "Green Lane",
                "Green Tree",
                "Greencastle",
                "Greensboro",
                "Greensburg",
                "Greenville",
                "Grier City-park Crest",
                "Grindstone-rowes Run",
                "Grove City",
                "Guilford",
                "Guys Mills",
                "Halfway House",
                "Halifax",
                "Hallam",
                "Hallstead",
                "Hamburg",
                "Hampton",
                "Hampton Township",
                "Hanover",
                "Harleysville",
                "Harmonsburg",
                "Harmony",
                "Harmony Township",
                "Harrisburg",
                "Harrison City",
                "Harrison Township",
                "Harrisville",
                "Hartleton",
                "Hartstown",
                "Harveys Lake",
                "Hasson Heights",
                "Hastings",
                "Hatboro",
                "Hatfield",
                "Hawley",
                "Hawthorn",
                "Haysville",
                "Hazleton",
                "Heckscherville",
                "Heidelberg",
                "Heilwood",
                "Hellertown",
                "Herminie",
                "Hermitage",
                "Herndon",
                "Hershey",
                "Highland Park",
                "Highspire",
                "Hiller",
                "Hokendauqua",
                "Hollidaysburg",
                "Homeacre-lyndora",
                "Homer City",
                "Homestead",
                "Hometown",
                "Homewood",
                "Honesdale",
                "Honey Brook",
                "Hookstown",
                "Hooversville",
                "Hop Bottom",
                "Hopewell",
                "Hopwood",
                "Horsham",
                "Houserville",
                "Houston",
                "Houtzdale",
                "Howard",
                "Hughestown",
                "Hughesville",
                "Hulmeville",
                "Hummels Wharf",
                "Hummelstown",
                "Hunker",
                "Huntingdon",
                "Hyde",
                "Hyde Park",
                "Hydetown",
                "Hyndman",
                "Imperial-enlow",
                "Indian Lake",
                "Indiana",
                "Industry",
                "Ingram",
                "Iola",
                "Irvona",
                "Irwin",
                "Ivyland",
                "Jackson Center",
                "Jacksonville",
                "Jacobus",
                "Jamestown",
                "Jamison City",
                "Jeannette",
                "Jeddo",
                "Jefferson",
                "Jefferson Hills",
                "Jenkintown",
                "Jennerstown",
                "Jermyn",
                "Jerome",
                "Jersey Shore",
                "Jerseytown",
                "Jessup",
                "Jim Thorpe",
                "Johnsonburg",
                "Johnstown",
                "Jonestown",
                "Julian",
                "Juniata Terrace",
                "Kane",
                "Karns City",
                "Kenhorst",
                "Kenilworth",
                "Kennedy Township",
                "Kennett Square",
                "King Of Prussia",
                "Kingston",
                "Kistler",
                "Kittanning",
                "Klingerstown",
                "Knox",
                "Knoxville",
                "Koppel",
                "Kratzerville",
                "Kreamer",
                "Kulpmont",
                "Kulpsville",
                "Kutztown",
                "Laceyville",
                "Laflin",
                "Lake City",
                "Lake Heritage",
                "Lake Meade",
                "Lake Wynonah",
                "Lancaster",
                "Landingville",
                "Landisburg",
                "Lanesboro",
                "Langhorne",
                "Langhorne Manor",
                "Lansdale",
                "Lansdowne",
                "Lansford",
                "Laporte",
                "Larksville",
                "Latrobe",
                "Laurel Mountain",
                "Laurel Run",
                "Laureldale",
                "Lavelle-locustdale",
                "Lawnton",
                "Lawrence Park",
                "Lawrenceville",
                "Lawson Heights",
                "Le Raysville",
                "Leacock-leola-bareville",
                "Lebanon",
                "Lebanon South",
                "Leechburg",
                "Leesport",
                "Leetsdale",
                "Lehighton",
                "Leith-hatfield",
                "Lemont",
                "Lemoyne",
                "Lenape Heights",
                "Lenhartsville",
                "Levittown",
                "Lewis Run",
                "Lewisberry",
                "Lewisburg",
                "Lewistown",
                "Liberty",
                "Lightstreet",
                "Ligonier",
                "Lilly",
                "Lima",
                "Lime Ridge",
                "Lincoln",
                "Lincolnville",
                "Linesville",
                "Linglestown",
                "Linntown",
                "Linwood",
                "Lionville-marchwood",
                "Lititz",
                "Little Meadows",
                "Littlestown",
                "Liverpool",
                "Lock Haven",
                "Locustdale",
                "Loganton",
                "Loganville",
                "Long Branch",
                "Lorain",
                "Lorane",
                "Loretto",
                "Lower Allen",
                "Lower Burrell",
                "Lucerne Mines",
                "Lumber City",
                "Luzerne",
                "Lykens",
                "Lynnwood-pricedale",
                "Lyons",
                "Macungie",
                "Madison",
                "Madisonburg",
                "Mahaffey",
                "Mahanoy City",
                "Mainville",
                "Malvern",
                "Manchester",
                "Manheim",
                "Manns Choice",
                "Manor",
                "Manorville",
                "Mansfield",
                "Maple Glen",
                "Mapleton",
                "Marcus Hook",
                "Marianna",
                "Marietta",
                "Marion Center",
                "Marion Heights",
                "Marklesburg",
                "Markleysburg",
                "Marlin",
                "Mars",
                "Marshallton",
                "Martinsburg",
                "Marysville",
                "Masontown",
                "Matamoras",
                "Mayfield",
                "Maytown",
                "Mcadoo",
                "Mcalisterville",
                "Mccandless Township",
                "Mcchesneytown-loyalhanna",
                "Mcclure",
                "Mcconnellsburg",
                "Mcdonald",
                "Mcewensville",
                "Mcgovern",
                "Mckean",
                "Mckeansburg",
                "Mckees Rocks",
                "Mckeesport",
                "Mcmurray",
                "Mcsherrystown",
                "Mcveytown",
                "Meadowood",
                "Meadville",
                "Mechanicsburg",
                "Mechanicsville",
                "Media",
                "Mercer",
                "Mercersburg",
                "Meridian",
                "Meshoppen",
                "Mexico",
                "Meyersdale",
                "Middleburg",
                "Middleport",
                "Middletown",
                "Midland",
                "Midway",
                "Mifflin",
                "Mifflinburg",
                "Mifflintown",
                "Mifflinville",
                "Milesburg",
                "Milford",
                "Mill Creek",
                "Mill Hall",
                "Mill Village",
                "Millbourne",
                "Millersburg",
                "Millerstown",
                "Millersville",
                "Millheim",
                "Millvale",
                "Millville",
                "Milroy",
                "Milton",
                "Minersville",
                "Modena",
                "Mohnton",
                "Monaca",
                "Monessen",
                "Monongahela",
                "Monroe",
                "Mont Alto",
                "Montgomery",
                "Montgomeryville",
                "Montoursville",
                "Montrose",
                "Monument",
                "Moosic",
                "Morrisville",
                "Morton",
                "Moscow",
                "Mount Carbon",
                "Mount Carmel",
                "Mount Cobb",
                "Mount Gretna",
                "Mount Gretna Heights",
                "Mount Holly Springs",
                "Mount Jewett",
                "Mount Joy",
                "Mount Lebanon",
                "Mount Oliver",
                "Mount Penn",
                "Mount Pleasant",
                "Mount Pleasant Mills",
                "Mount Pocono",
                "Mount Union",
                "Mount Wolf",
                "Mountain Top",
                "Mountainhome",
                "Mountville",
                "Muncy",
                "Munhall",
                "Municipality Of Monroeville",
                "Municipality Of Murrysville",
                "Myerstown",
                "Nanticoke",
                "Nanty-glo",
                "Narberth",
                "Nazareth",
                "Nemacolin",
                "Nescopeck",
                "Nesquehoning",
                "Nether Providence Township",
                "New Albany",
                "New Alexandria",
                "New Baltimore",
                "New Beaver",
                "New Berlin",
                "New Bethlehem",
                "New Boston-morea",
                "New Brighton",
                "New Britain",
                "New Buffalo",
                "New Castle",
                "New Castle Northwest",
                "New Centerville",
                "New Columbus",
                "New Cumberland",
                "New Eagle",
                "New Florence",
                "New Freedom",
                "New Galilee",
                "New Holland",
                "New Hope",
                "New Kensington",
                "New Kingstown",
                "New Lebanon",
                "New Milford",
                "New Morgan",
                "New Oxford",
                "New Paris",
                "New Philadelphia",
                "New Ringgold",
                "New Salem",
                "New Salem-buffington",
                "New Stanton",
                "New Washington",
                "New Wilmington",
                "Newburg",
                "Newell",
                "Newmanstown",
                "Newport",
                "Newry",
                "Newton Hamilton",
                "Newtown",
                "Newtown Grant",
                "Newville",
                "Nicholson",
                "Nixon",
                "Norristown",
                "North Apollo",
                "North Belle Vernon",
                "North Braddock",
                "North Catasauqua",
                "North Charleroi",
                "North East",
                "North Irwin",
                "North Philipsburg",
                "North Vandergrift-pleasant View",
                "North Versailles",
                "North Wales",
                "North York",
                "Northampton",
                "Northern Cambria",
                "Northumberland",
                "Northwest Harborcreek",
                "Norwood",
                "Nuangola",
                "Numidia",
                "Nuremberg",
                "O Hara Township",
                "Oak Hills",
                "Oakdale",
                "Oakland",
                "Oakmont",
                "Oakwood",
                "Ohiopyle",
                "Ohioville",
                "Oil City",
                "Oklahoma",
                "Old Forge",
                "Old Orchard",
                "Oliver",
                "Olyphant",
                "Oneida",
                "Orangeville",
                "Orbisonia",
                "Orchard Hills",
                "Oreland",
                "Orrstown",
                "Orrtanna",
                "Orwigsburg",
                "Osborne",
                "Osceola Mills",
                "Oswayo",
                "Oxford",
                "Paint",
                "Palmer Heights",
                "Palmerton",
                "Palmyra",
                "Palo Alto",
                "Paoli",
                "Paradise",
                "Park Forest Village",
                "Parker",
                "Parkesburg",
                "Parkside",
                "Parkville",
                "Parryville",
                "Patterson Heights",
                "Patterson Township",
                "Patton",
                "Paxtang",
                "Paxtonia",
                "Paxtonville",
                "Pen Argyl",
                "Penbrook",
                "Penn",
                "Penn Hills",
                "Penn Lake Park",
                "Penn Wynne",
                "Penndel",
                "Penns Creek",
                "Pennsburg",
                "Pennsbury Village",
                "Pennville",
                "Perkasie",
                "Perryopolis",
                "Petersburg",
                "Petrolia",
                "Philadelphia",
                "Philipsburg",
                "Phoenixville",
                "Picture Rocks",
                "Pillow",
                "Pine Glen",
                "Pine Grove",
                "Pine Grove Mills",
                "Pitcairn",
                "Pittsburgh",
                "Pittston",
                "Plainfield",
                "Platea",
                "Pleasant Gap",
                "Pleasant Hill",
                "Pleasant Hills",
                "Pleasantville",
                "Plum",
                "Plumville",
                "Plymouth",
                "Plymouth Meeting",
                "Plymptonville",
                "Pocono Pines",
                "Point Marion",
                "Polk",
                "Port Allegany",
                "Port Carbon",
                "Port Clinton",
                "Port Matilda",
                "Port Royal",
                "Port Trevorton",
                "Port Vue",
                "Portage",
                "Portersville",
                "Portland",
                "Pottsgrove",
                "Pottstown",
                "Pottsville",
                "Pringle",
                "Progress",
                "Prompton",
                "Prospect",
                "Prospect Park",
                "Punxsutawney",
                "Pymatuning Central",
                "Pymatuning North",
                "Pymatuning South",
                "Quakertown",
                "Quarryville",
                "Quentin",
                "Radnor Township",
                "Railroad",
                "Rainsburg",
                "Ramblewood",
                "Ramey",
                "Rankin",
                "Ravine",
                "Reading",
                "Reamstown",
                "Rebersburg",
                "Red Hill",
                "Red Lion",
                "Reedsville",
                "Reiffton",
                "Reinerton-orwin-muir",
                "Renningers",
                "Renovo",
                "Republic",
                "Reserve Township",
                "Reynoldsville",
                "Rheems",
                "Rices Landing",
                "Riceville",
                "Richboro",
                "Richfield",
                "Richland",
                "Richlandtown",
                "Ridgway",
                "Ridley Park",
                "Riegelsville",
                "Rimersburg",
                "Ringtown",
                "Riverside",
                "Roaring Spring",
                "Robesonia",
                "Robinson Township",
                "Rochester",
                "Rockhill Furnace",
                "Rockledge",
                "Rockwood",
                "Rohrsburg",
                "Rome",
                "Roscoe",
                "Rose Valley",
                "Roseto",
                "Roseville",
                "Ross Township",
                "Rossiter",
                "Rosslyn Farms",
                "Rothsville",
                "Rouseville",
                "Rouzerville",
                "Royalton",
                "Royersford",
                "Rupert",
                "Rural Valley",
                "Russellton",
                "Rutherford",
                "Rutledge",
                "S.n.p.j.",
                "Saegertown",
                "Salisbury",
                "Salix-beauty Line Park",
                "Salladasburg",
                "Saltillo",
                "Saltsburg",
                "Salunga-landisville",
                "Sanatoga",
                "Sand Hill",
                "Sandy",
                "Sandy Lake",
                "Sandy Ridge",
                "Sankertown",
                "Saxonburg",
                "Saxton",
                "Sayre",
                "Scalp Level",
                "Schaefferstown",
                "Schellsburg",
                "Schlusser",
                "Schnecksville",
                "Schuylkill Haven",
                "Schwenksville",
                "Scott Township",
                "Scottdale",
                "Scranton",
                "Selinsgrove",
                "Sellersville",
                "Seltzer",
                "Seneca",
                "Seven Fields",
                "Seven Springs",
                "Seven Valleys",
                "Seward",
                "Sewickley",
                "Sewickley Heights",
                "Sewickley Hills",
                "Shade Gap",
                "Shaler Township",
                "Shamokin",
                "Shamokin Dam",
                "Shanksville",
                "Shanor-northvue",
                "Sharon",
                "Sharon Hill",
                "Sharpsburg",
                "Sharpsville",
                "Sheakleyville",
                "Sheffield",
                "Shelocta",
                "Shenandoah",
                "Shenandoah Heights",
                "Sheppton",
                "Shickshinny",
                "Shillington",
                "Shiloh",
                "Shinglehouse",
                "Shippensburg",
                "Shippenville",
                "Shippingport",
                "Shiremanstown",
                "Shirleysburg",
                "Shoemakersville",
                "Shrewsbury",
                "Silverdale",
                "Sinking Spring",
                "Skippack",
                "Skyline View",
                "Slabtown",
                "Slatington",
                "Slickville",
                "Sligo",
                "Slippery Rock",
                "Smethport",
                "Smicksburg",
                "Smithfield",
                "Smithton",
                "Snow Shoe",
                "Snydertown",
                "Somerset",
                "Souderton",
                "South Bethlehem",
                "South Coatesville",
                "South Connellsville",
                "South Fork",
                "South Greensburg",
                "South Heights",
                "South New Castle",
                "South Park Township",
                "South Philipsburg",
                "South Pottstown",
                "South Renovo",
                "South Waverly",
                "South Williamsport",
                "Southmont",
                "Southwest Greensburg",
                "Spartansburg",
                "Speers",
                "Spring City",
                "Spring Grove",
                "Spring Hill",
                "Spring House",
                "Spring Mills",
                "Spring Mount",
                "Spring Ridge",
                "Springboro",
                "Springdale",
                "Springetts Manor-yorklyn",
                "Springfield",
                "Spry",
                "St. Clair",
                "St. Clairsville",
                "St. Lawrence",
                "St. Marys",
                "St. Michael-sidman",
                "St. Petersburg",
                "Starrucca",
                "State College",
                "Steelton",
                "Stewartstown",
                "Stillwater",
                "Stockdale",
                "Stockertown",
                "Stoneboro",
                "Stonybrook-wilshire",
                "Stormstown",
                "Stowe",
                "Stowe Township",
                "Stoystown",
                "Strasburg",
                "Strattanville",
                "Strausstown",
                "Stroudsburg",
                "Sturgeon-noblestown",
                "Sugar Grove",
                "Sugar Notch",
                "Sugarcreek",
                "Summerhill",
                "Summerville",
                "Summit Hill",
                "Summit Station",
                "Sunbury",
                "Susquehanna Depot",
                "Susquehanna Trails",
                "Sutersville",
                "Swarthmore",
                "Swissvale",
                "Swoyersville",
                "Sykesville",
                "Sylvania",
                "Tamaqua",
                "Tarentum",
                "Tatamy",
                "Taylor",
                "Telford",
                "Terre Hill",
                "Thompson",
                "Thompsontown",
                "Thompsonville",
                "Thornburg",
                "Thorndale",
                "Three Springs",
                "Throop",
                "Tidioute",
                "Timber Hills",
                "Timblin",
                "Tinicum Township",
                "Tioga",
                "Tionesta",
                "Tipton",
                "Titusville",
                "Topton",
                "Toughkenamon",
                "Towanda",
                "Tower City",
                "Townville",
                "Trafford",
                "Trainer",
                "Trappe",
                "Treasure Lake",
                "Tremont",
                "Tresckow",
                "Trevorton",
                "Trooper",
                "Troutville",
                "Troxelville",
                "Troy",
                "Trumbauersville",
                "Tullytown",
                "Tunkhannock",
                "Tunnelhill",
                "Turbotville",
                "Turtle Creek",
                "Tuscarora",
                "Twilight",
                "Tyler Run-queens Gate",
                "Tyrone",
                "Ulysses",
                "Union City",
                "Union Dale",
                "Uniontown",
                "Unionville",
                "Upland",
                "Upper Providence Township",
                "Upper St. Clair",
                "Ursina",
                "Utica",
                "Valencia",
                "Valley Green",
                "Valley View",
                "Valley-hi",
                "Vanderbilt",
                "Vandergrift",
                "Vandling",
                "Venango",
                "Verona",
                "Versailles",
                "Village Green-green Ridge",
                "Village Shires",
                "Vinco",
                "Vintondale",
                "Volant",
                "Wall",
                "Wallaceton",
                "Waller",
                "Walnutport",
                "Wampum",
                "Warminster Heights",
                "Warren",
                "Warren South",
                "Warrior Run",
                "Washington",
                "Washingtonville",
                "Waterford",
                "Watsontown",
                "Wattsburg",
                "Waymart",
                "Wayne Heights",
                "Waynesboro",
                "Waynesburg",
                "Weatherly",
                "Weigelstown",
                "Weissport",
                "Weissport East",
                "Wellersburg",
                "Wellsboro",
                "Wellsville",
                "Wernersville",
                "Wesleyville",
                "West Alexander",
                "West Brownsville",
                "West Chester",
                "West Conshohocken",
                "West Easton",
                "West Elizabeth",
                "West Goshen",
                "West Grove",
                "West Hazleton",
                "West Hills",
                "West Homestead",
                "West Kittanning",
                "West Lawn",
                "West Leechburg",
                "West Liberty",
                "West Mayfield",
                "West Middlesex",
                "West Middletown",
                "West Mifflin",
                "West Newton",
                "West Norriton",
                "West Pittston",
                "West Reading",
                "West Sunbury",
                "West View",
                "West Wyoming",
                "West Wyomissing",
                "West York",
                "Westfield",
                "Westmont",
                "Westover",
                "Wheatland",
                "Whitaker",
                "White Haven",
                "White Oak",
                "Whitehall",
                "Whitfield",
                "Wickerham Manor-fisher",
                "Wilburton Number One",
                "Wilburton Number Two",
                "Wilkes-barre",
                "Wilkes-barre Township",
                "Wilkins Township",
                "Wilkinsburg",
                "Williamsburg",
                "Williamsport",
                "Williamstown",
                "Willow Grove",
                "Willow Street",
                "Wilmerding",
                "Wilmore",
                "Wilson",
                "Wind Gap",
                "Windber",
                "Windsor",
                "Winterstown",
                "Wolfdale",
                "Womelsdorf",
                "Woodbourne",
                "Woodbury",
                "Woodcock",
                "Woodland Heights",
                "Woodlyn",
                "Woodside",
                "Woodward",
                "Wormleysburg",
                "Worthington",
                "Worthville",
                "Wrightsville",
                "Wyalusing",
                "Wyncote",
                "Wyndmoor",
                "Wyoming",
                "Wyomissing",
                "Wyomissing Hills",
                "Yardley",
                "Yatesville",
                "Yeadon",
                "Yeagertown",
                "Yoe",
                "York",
                "York Haven",
                "York Springs",
                "Yorkana",
                "Youngstown",
                "Youngsville",
                "Youngwood",
                "Zelienople",
                "Zion"
            ],
            "Puerto Rico": [
                "Aceitunas",
                "Adjuntas",
                "Aguada",
                "Aguadilla",
                "Aguas Buenas",
                "Aguas Claras",
                "Aguilita",
                "Aibonito",
                "Anasco",
                "Animas",
                "Anton Ruiz",
                "Arecibo",
                "Arroyo",
                "Bairoa",
                "Bajadero",
                "Bajandas",
                "Barahona",
                "Barceloneta",
                "Barranquitas",
                "Bartolo",
                "Bayamon",
                "Benitez",
                "Betances",
                "Boqueron",
                "Brenas",
                "Buena Vista",
                "Bufalo",
                "Caban",
                "Cabo Rojo",
                "Cacao",
                "Caguas",
                "Campanilla",
                "Campo Rico",
                "Camuy",
                "Candelaria",
                "Candelaria Arenas",
                "Candelero Arriba",
                "Canovanas",
                "Capitanejo",
                "Carolina",
                "Carrizales",
                "Catano",
                "Cayey",
                "Cayuco",
                "Ceiba",
                "Celada",
                "Central Aguirre",
                "Ciales",
                "Cidra",
                "Coamo",
                "Coco",
                "Comerio",
                "Comunas",
                "Coqui",
                "Corazon",
                "Corcovado",
                "Corozal",
                "Coto Laurel",
                "Coto Norte",
                "Culebra",
                "Daguao",
                "Dorado",
                "Duque",
                "El Mango",
                "El Negro",
                "El Ojo",
                "Emajagua",
                "Esperanza",
                "Espino",
                "Fajardo",
                "Florida",
                "Franquez",
                "Fuig",
                "G. L. Garcia",
                "Galateo",
                "Garrochales",
                "Guanica",
                "Guayabal",
                "Guayama",
                "Guayanilla",
                "Guaynabo",
                "Gurabo",
                "H. Rivera Colon",
                "Hatillo",
                "Hato Arriba",
                "Hato Candal",
                "Hormigueros",
                "Humacao",
                "Imbery",
                "Indios",
                "Ingenio",
                "Isabela",
                "Jagual",
                "Jauca",
                "Jayuya",
                "Jobos",
                "Juana Diaz",
                "Juncal",
                "Juncos",
                "La Alianza",
                "La Dolores",
                "La Fermina",
                "La Luisa",
                "La Parguera",
                "La Playa",
                "La Plena",
                "Lajas",
                "Lamboglia",
                "Lares",
                "Las Marias",
                "Las Ochenta",
                "Las Ollas",
                "Las Piedras",
                "Levittown",
                "Liborio Negron Torres",
                "Lluveras",
                "Loiza",
                "Lomas",
                "Los Llanos",
                "Luis Llorens Torres",
                "Luis M. Cintron",
                "Luquillo",
                "Luyando",
                "Magas Arriba",
                "Manati",
                "Maria Antonia",
                "Mariano Colon",
                "Maricao",
                "Martorell",
                "Marueno",
                "Maunabo",
                "Mayaguez",
                "Miranda",
                "Moca",
                "Monserrate",
                "Monte Grande",
                "Mora",
                "Morovis",
                "Mucarabones",
                "Naguabo",
                "Naranjito",
                "Olimpo",
                "Orocovis",
                "Pajaros",
                "Pajonal",
                "Palmarejo",
                "Palmas",
                "Palmer",
                "Palo Seco",
                "Palomas",
                "Parcelas La Milagrosa",
                "Parcelas Nuevas",
                "Parcelas Penuelas",
                "Pastos",
                "Patillas",
                "Pena Pobre",
                "Penuelas",
                "Piedra Gorda",
                "Playa Fortuna",
                "Playita",
                "Playita Cortada",
                "Pole Ojea",
                "Ponce",
                "Potala Pastillo",
                "Pueblito Del Rio",
                "Puerto Real",
                "Punta Santiago",
                "Quebrada",
                "Quebradillas",
                "Rafael Capo",
                "Rafael Gonzalez",
                "Rafael Hernandez",
                "Ramos",
                "Rincon",
                "Rio Blanco",
                "Rio Canas Abajo",
                "Rio Grande",
                "Rio Lajas",
                "Roosevelt Roads",
                "Rosa Sanchez",
                "Sabana",
                "Sabana Eneas",
                "Sabana Grande",
                "Sabana Hoyos",
                "Sabana Seca",
                "Salinas",
                "San Antonio",
                "San German",
                "San Isidro",
                "San Jose",
                "San Juan",
                "San Lorenzo",
                "San Sebastian",
                "Santa Barbara",
                "Santa Clara",
                "Santa Isabel",
                "Santo Domingo",
                "Stella",
                "Suarez",
                "Tallaboa",
                "Tallaboa Alta",
                "Tiburones",
                "Tierras Nuevas Poniente",
                "Toa Alta",
                "Toa Baja",
                "Trujillo Alto",
                "Utuado",
                "Vazquez",
                "Vega Alta",
                "Vega Baja",
                "Vieques",
                "Villalba",
                "Yabucoa",
                "Yauco",
                "Yaurel"
            ],
            "Ramey": [
                "Aceitunas",
                "Adjuntas",
                "Aguada",
                "Aguadilla",
                "Aguas Buenas",
                "Aguas Claras",
                "Aguilita",
                "Aibonito",
                "Anasco",
                "Animas",
                "Anton Ruiz",
                "Arecibo",
                "Arroyo",
                "Bairoa",
                "Bajadero",
                "Bajandas",
                "Barahona",
                "Barceloneta",
                "Barranquitas",
                "Bartolo",
                "Bayamon",
                "Benitez",
                "Betances",
                "Boqueron",
                "Brenas",
                "Buena Vista",
                "Bufalo",
                "Caban",
                "Cabo Rojo",
                "Cacao",
                "Caguas",
                "Campanilla",
                "Campo Rico",
                "Camuy",
                "Candelaria",
                "Candelaria Arenas",
                "Candelero Arriba",
                "Canovanas",
                "Capitanejo",
                "Carolina",
                "Carrizales",
                "Catano",
                "Cayey",
                "Cayuco",
                "Ceiba",
                "Celada",
                "Central Aguirre",
                "Ciales",
                "Cidra",
                "Coamo",
                "Coco",
                "Comerio",
                "Comunas",
                "Coqui",
                "Corazon",
                "Corcovado",
                "Corozal",
                "Coto Laurel",
                "Coto Norte",
                "Culebra",
                "Daguao",
                "Dorado",
                "Duque",
                "El Mango",
                "El Negro",
                "El Ojo",
                "Emajagua",
                "Esperanza",
                "Espino",
                "Fajardo",
                "Florida",
                "Franquez",
                "Fuig",
                "G. L. Garcia",
                "Galateo",
                "Garrochales",
                "Guanica",
                "Guayabal",
                "Guayama",
                "Guayanilla",
                "Guaynabo",
                "Gurabo",
                "H. Rivera Colon",
                "Hatillo",
                "Hato Arriba",
                "Hato Candal",
                "Hormigueros",
                "Humacao",
                "Imbery",
                "Indios",
                "Ingenio",
                "Isabela",
                "Jagual",
                "Jauca",
                "Jayuya",
                "Jobos",
                "Juana Diaz",
                "Juncal",
                "Juncos",
                "La Alianza",
                "La Dolores",
                "La Fermina",
                "La Luisa",
                "La Parguera",
                "La Playa",
                "La Plena",
                "Lajas",
                "Lamboglia",
                "Lares",
                "Las Marias",
                "Las Ochenta",
                "Las Ollas",
                "Las Piedras",
                "Levittown",
                "Liborio Negron Torres",
                "Lluveras",
                "Loiza",
                "Lomas",
                "Los Llanos",
                "Luis Llorens Torres",
                "Luis M. Cintron",
                "Luquillo",
                "Luyando",
                "Magas Arriba",
                "Manati",
                "Maria Antonia",
                "Mariano Colon",
                "Maricao",
                "Martorell",
                "Marueno",
                "Maunabo",
                "Mayaguez",
                "Miranda",
                "Moca",
                "Monserrate",
                "Monte Grande",
                "Mora",
                "Morovis",
                "Mucarabones",
                "Naguabo",
                "Naranjito",
                "Olimpo",
                "Orocovis",
                "Pajaros",
                "Pajonal",
                "Palmarejo",
                "Palmas",
                "Palmer",
                "Palo Seco",
                "Palomas",
                "Parcelas La Milagrosa",
                "Parcelas Nuevas",
                "Parcelas Penuelas",
                "Pastos",
                "Patillas",
                "Pena Pobre",
                "Penuelas",
                "Piedra Gorda",
                "Playa Fortuna",
                "Playita",
                "Playita Cortada",
                "Pole Ojea",
                "Ponce",
                "Potala Pastillo",
                "Pueblito Del Rio",
                "Puerto Real",
                "Punta Santiago",
                "Quebrada",
                "Quebradillas",
                "Rafael Capo",
                "Rafael Gonzalez",
                "Rafael Hernandez",
                "Ramos",
                "Rincon",
                "Rio Blanco",
                "Rio Canas Abajo",
                "Rio Grande",
                "Rio Lajas",
                "Roosevelt Roads",
                "Rosa Sanchez",
                "Sabana",
                "Sabana Eneas",
                "Sabana Grande",
                "Sabana Hoyos",
                "Sabana Seca",
                "Salinas",
                "San Antonio",
                "San German",
                "San Isidro",
                "San Jose",
                "San Juan",
                "San Lorenzo",
                "San Sebastian",
                "Santa Barbara",
                "Santa Clara",
                "Santa Isabel",
                "Santo Domingo",
                "Stella",
                "Suarez",
                "Tallaboa",
                "Tallaboa Alta",
                "Tiburones",
                "Tierras Nuevas Poniente",
                "Toa Alta",
                "Toa Baja",
                "Trujillo Alto",
                "Utuado",
                "Vazquez",
                "Vega Alta",
                "Vega Baja",
                "Vieques",
                "Villalba",
                "Yabucoa",
                "Yauco",
                "Yaurel"
            ],
            "Rhode Island": [
                "Ashaway",
                "Barrington",
                "Bradford",
                "Bristol",
                "Central Falls",
                "Cranston",
                "Cumberland Hill",
                "East Providence",
                "Greenville",
                "Harrisville",
                "Hope Valley",
                "Kingston",
                "Melville",
                "Narragansett Pier",
                "Newport",
                "Newport East",
                "North Providence",
                "Pascoag",
                "Pawtucket",
                "Providence",
                "Tiverton",
                "Valley Falls",
                "Wakefield-peacedale",
                "Warwick",
                "West Warwick",
                "Westerly",
                "Woonsocket"
            ],
            "South Carolina": [
                "Abbeville",
                "Aiken",
                "Allendale",
                "Anderson",
                "Andrews",
                "Antreville",
                "Arcadia Lakes",
                "Arial",
                "Atlantic Beach",
                "Awendaw",
                "Aynor",
                "Bamberg",
                "Barnwell",
                "Batesburg-leesville",
                "Beaufort",
                "Belton",
                "Belvedere",
                "Bennettsville",
                "Berea",
                "Bethune",
                "Bishopville",
                "Blacksburg",
                "Blackville",
                "Blenheim",
                "Bluffton",
                "Blythewood",
                "Boiling Springs",
                "Bonneau",
                "Bowman",
                "Bradley",
                "Branchville",
                "Briarcliffe Acres",
                "Brookdale",
                "Brunson",
                "Bucksport",
                "Buffalo",
                "Burnettown",
                "Burton",
                "Calhoun Falls",
                "Camden",
                "Cameron",
                "Campobello",
                "Cane Savannah",
                "Carlisle",
                "Cayce",
                "Centerville",
                "Central",
                "Central Pacolet",
                "Chapin",
                "Charleston",
                "Cheraw",
                "Cherryvale",
                "Chesnee",
                "Chester",
                "Chesterfield",
                "City View",
                "Clarks Hill",
                "Clearwater",
                "Clemson",
                "Clinton",
                "Clio",
                "Clover",
                "Cokesbury",
                "Columbia",
                "Conway",
                "Cope",
                "Cordova",
                "Coronaca",
                "Cottageville",
                "Coward",
                "Cowpens",
                "Cross Hill",
                "Dalzell",
                "Darlington",
                "Denmark",
                "Dentsville",
                "Dillon",
                "Donalds",
                "Due West",
                "Duncan",
                "Dunean",
                "Easley",
                "East Gaffney",
                "East Sumter",
                "Eastover",
                "Edgefield",
                "Edisto",
                "Edisto Beach",
                "Ehrhardt",
                "Elgin",
                "Elko",
                "Elloree",
                "Estill",
                "Eureka Mill",
                "Eutawville",
                "Fairfax",
                "Five Forks",
                "Florence",
                "Folly Beach",
                "Forest Acres",
                "Forestbrook",
                "Fort Lawn",
                "Fort Mill",
                "Fountain Inn",
                "Furman",
                "Gaffney",
                "Gantt",
                "Garden City",
                "Gaston",
                "Gayle Mill",
                "Georgetown",
                "Gifford",
                "Gilbert",
                "Gloverville",
                "Golden Grove",
                "Goose Creek",
                "Govan",
                "Gray Court",
                "Great Falls",
                "Greeleyville",
                "Greenville",
                "Greenwood",
                "Greer",
                "Hampton",
                "Hanahan",
                "Hardeeville",
                "Harleyville",
                "Hartsville",
                "Heath Springs",
                "Hemingway",
                "Hickory Grove",
                "Hilda",
                "Hilton Head Island",
                "Hodges",
                "Holly Hill",
                "Hollywood",
                "Homeland Park",
                "Honea Path",
                "India Hook",
                "Inman",
                "Inman Mills",
                "Irmo",
                "Irwin",
                "Isle Of Palms",
                "Iva",
                "Jackson",
                "Jamestown",
                "Jefferson",
                "Joanna",
                "Johnsonville",
                "Johnston",
                "Jonesville",
                "Judson",
                "Kershaw",
                "Kiawah Island",
                "Kingstree",
                "Kline",
                "Ladson",
                "Lake City",
                "Lake Murray Of Richland",
                "Lake Secession",
                "Lake View",
                "Lake Wylie",
                "Lakewood",
                "Lamar",
                "Lancaster",
                "Lancaster Mill",
                "Landrum",
                "Lane",
                "Latta",
                "Laurel Bay",
                "Laurens",
                "Lesslie",
                "Lexington",
                "Liberty",
                "Lincolnville",
                "Little Mountain",
                "Little River",
                "Livingston",
                "Lockhart",
                "Lodge",
                "Loris",
                "Lowndesville",
                "Lowrys",
                "Lugoff",
                "Luray",
                "Lyman",
                "Lynchburg",
                "Manning",
                "Marion",
                "Mauldin",
                "Mayesville",
                "Mayo",
                "Mcbee",
                "Mcclellanville",
                "Mccoll",
                "Mcconnells",
                "Mccormick",
                "Meggett",
                "Millwood",
                "Modoc",
                "Monarch Mill",
                "Moncks Corner",
                "Monetta",
                "Mount Carmel",
                "Mount Croghan",
                "Mount Pleasant",
                "Mountville",
                "Mulberry",
                "Mullins",
                "Murphys Estates",
                "Murrells Inlet",
                "Myrtle Beach",
                "Neeses",
                "New Ellenton",
                "Newberry",
                "Newport",
                "Nichols",
                "Ninety Six",
                "Norris",
                "North",
                "North Augusta",
                "North Charleston",
                "North Hartsville",
                "North Myrtle Beach",
                "Northlake",
                "Norway",
                "Oak Grove",
                "Oakland",
                "Olanta",
                "Olar",
                "Orangeburg",
                "Oswego",
                "Pacolet",
                "Pageland",
                "Pamplico",
                "Parker",
                "Parksville",
                "Parris Island",
                "Patrick",
                "Pawleys Island",
                "Paxville",
                "Peak",
                "Pelion",
                "Pelzer",
                "Pendleton",
                "Perry",
                "Pickens",
                "Piedmont",
                "Pine Ridge",
                "Pinewood",
                "Plum Branch",
                "Pomaria",
                "Port Royal",
                "Powderville",
                "Princeton",
                "Privateer",
                "Promised Land",
                "Prosperity",
                "Quinby",
                "Ravenel",
                "Red Bank",
                "Red Hill",
                "Reevesville",
                "Reidville",
                "Rembert",
                "Richburg",
                "Ridge Spring",
                "Ridgeland",
                "Ridgeville",
                "Ridgeway",
                "Riverview",
                "Rock Hill",
                "Rockville",
                "Roebuck",
                "Rowesville",
                "Ruby",
                "Salem",
                "Salley",
                "Saluda",
                "Sans Souci",
                "Santee",
                "Saxon",
                "Scotia",
                "Scranton",
                "Seabrook Island",
                "Sellers",
                "Seneca",
                "Seven Oaks",
                "Sharon",
                "Shell Point",
                "Shiloh",
                "Silverstreet",
                "Simpsonville",
                "Six Mile",
                "Slater-marietta",
                "Smoaks",
                "Smyrna",
                "Snelling",
                "Socastee",
                "Society Hill",
                "South Congaree",
                "South Sumter",
                "Southern Shops",
                "Spartanburg",
                "Springdale",
                "Springfield",
                "St. Andrews",
                "St. George",
                "St. Matthews",
                "St. Stephen",
                "Starr",
                "Startex",
                "Stateburg",
                "Stuckey",
                "Sullivan S Island",
                "Summerton",
                "Summerville",
                "Summit",
                "Sumter",
                "Surfside Beach",
                "Swansea",
                "Sycamore",
                "Tatum",
                "Taylors",
                "Tega Cay",
                "Timmonsville",
                "Travelers Rest",
                "Trenton",
                "Troy",
                "Turbeville",
                "Ulmer",
                "Union",
                "Utica",
                "Valley Falls",
                "Vance",
                "Varnville",
                "Wade Hampton",
                "Wagener",
                "Walhalla",
                "Walterboro",
                "Ward",
                "Ware Shoals",
                "Waterloo",
                "Watts Mills",
                "Wedgewood",
                "Welcome",
                "Wellford",
                "West Columbia",
                "West Pelzer",
                "West Union",
                "Westminster",
                "Whitmire",
                "Wilkinson Heights",
                "Williams",
                "Williamston",
                "Willington",
                "Williston",
                "Windsor",
                "Winnsboro",
                "Winnsboro Mills",
                "Woodfield",
                "Woodford",
                "Woodruff",
                "Yemassee",
                "York"
            ],
            "South Dakota": [
                "Aberdeen",
                "Agar",
                "Akaska",
                "Albee",
                "Alcester",
                "Alexandria",
                "Allen",
                "Alpena",
                "Altamont",
                "Andover",
                "Antelope",
                "Arlington",
                "Armour",
                "Artas",
                "Artesian",
                "Ashland Heights",
                "Ashton",
                "Astoria",
                "Aurora",
                "Aurora Center",
                "Avon",
                "Badger",
                "Baltic",
                "Bancroft",
                "Batesland",
                "Belle Fourche",
                "Belvidere",
                "Beresford",
                "Big Stone City",
                "Bison",
                "Blackhawk",
                "Blunt",
                "Bonesteel",
                "Bowdle",
                "Box Elder",
                "Bradley",
                "Brandon",
                "Brandt",
                "Brentford",
                "Bridgewater",
                "Bristol",
                "Britton",
                "Broadland",
                "Brookings",
                "Bruce",
                "Bryant",
                "Buffalo",
                "Buffalo Gap",
                "Bullhead",
                "Burke",
                "Bushnell",
                "Butler",
                "Camp Crook",
                "Canistota",
                "Canova",
                "Canton",
                "Carthage",
                "Castlewood",
                "Cavour",
                "Centerville",
                "Central City",
                "Chamberlain",
                "Chancellor",
                "Chelsea",
                "Claire City",
                "Claremont",
                "Clark",
                "Clear Lake",
                "Colman",
                "Colome",
                "Colonial Pine Hills",
                "Colton",
                "Columbia",
                "Conde",
                "Corona",
                "Corsica",
                "Cottonwood",
                "Cresbard",
                "Crooks",
                "Custer",
                "Dallas",
                "Dante",
                "Davis",
                "De Smet",
                "Deadwood",
                "Dell Rapids",
                "Delmont",
                "Dimock",
                "Doland",
                "Dolton",
                "Draper",
                "Dupree",
                "Eagle Butte",
                "Eden",
                "Edgemont",
                "Egan",
                "Elk Point",
                "Elkton",
                "Ellsworth Afb",
                "Emery",
                "Erwin",
                "Estelline",
                "Ethan",
                "Eureka",
                "Fairburn",
                "Fairfax",
                "Fairview",
                "Faith",
                "Farmer",
                "Faulkton",
                "Flandreau",
                "Florence",
                "Fort Pierre",
                "Fort Thompson",
                "Frankfort",
                "Frederick",
                "Freeman",
                "Fruitdale",
                "Fulton",
                "Garden City",
                "Garretson",
                "Gary",
                "Gayville",
                "Geddes",
                "Gettysburg",
                "Glenham",
                "Goodwin",
                "Green Grass",
                "Green Valley",
                "Gregory",
                "Grenville",
                "Groton",
                "Hamill",
                "Harrisburg",
                "Harrison",
                "Harrold",
                "Hartford",
                "Hayti",
                "Hazel",
                "Hecla",
                "Henry",
                "Hermosa",
                "Herreid",
                "Herrick",
                "Hetland",
                "Highmore",
                "Hill City",
                "Hillsview",
                "Hitchcock",
                "Hosmer",
                "Hot Springs",
                "Hoven",
                "Howard",
                "Hudson",
                "Humboldt",
                "Hurley",
                "Huron",
                "Interior",
                "Ipswich",
                "Irene",
                "Iroquois",
                "Isabel",
                "Java",
                "Jefferson",
                "Kadoka",
                "Kaylor",
                "Kennebec",
                "Keystone",
                "Kimball",
                "Kranzburg",
                "Kyle",
                "La Bolt",
                "La Plant",
                "Lake Andes",
                "Lake City",
                "Lake Norden",
                "Lake Preston",
                "Lane",
                "Langford",
                "Lead",
                "Lebanon",
                "Lemmon",
                "Lennox",
                "Leola",
                "Lesterville",
                "Letcher",
                "Lily",
                "Little Eagle",
                "Long Lake",
                "Loomis",
                "Lower Brule",
                "Lowry",
                "Madison",
                "Manderson-white Horse Creek",
                "Marion",
                "Martin",
                "Marty",
                "Marvin",
                "Mcintosh",
                "Mclaughlin",
                "Mellette",
                "Menno",
                "Midland",
                "Milbank",
                "Miller",
                "Milltown",
                "Mission",
                "Mission Hill",
                "Mitchell",
                "Mobridge",
                "Monroe",
                "Montrose",
                "Morristown",
                "Mound City",
                "Mount Vernon",
                "Murdo",
                "Naples",
                "New Effington",
                "New Holland",
                "New Underwood",
                "New Witten",
                "Newell",
                "Nisland",
                "North Eagle Butte",
                "North Sioux City",
                "North Spearfish",
                "Northville",
                "Nunda",
                "Oacoma",
                "Oelrichs",
                "Oglala",
                "Okaton",
                "Oldham",
                "Olivet",
                "Onaka",
                "Onida",
                "Orient",
                "Ortley",
                "Parker",
                "Parkston",
                "Parmelee",
                "Peever",
                "Philip",
                "Pickstown",
                "Pierpont",
                "Pierre",
                "Pine Ridge",
                "Plankinton",
                "Platte",
                "Pollock",
                "Porcupine",
                "Presho",
                "Pringle",
                "Pukwana",
                "Quinn",
                "Ramona",
                "Rapid City",
                "Rapid Valley",
                "Ravinia",
                "Raymond",
                "Redfield",
                "Ree Heights",
                "Reliance",
                "Revillo",
                "Rockham",
                "Roscoe",
                "Rosebud",
                "Rosholt",
                "Roslyn",
                "Roswell",
                "Salem",
                "Scotland",
                "Selby",
                "Seneca",
                "Sherman",
                "Sinai",
                "Sioux Falls",
                "Sisseton",
                "South Shore",
                "Spearfish",
                "Spencer",
                "Spring Creek",
                "Springfield",
                "St. Charles",
                "St. Francis",
                "St. Lawrence",
                "Stickney",
                "Stockholm",
                "Storla",
                "Strandburg",
                "Stratford",
                "Sturgis",
                "Summit",
                "Tabor",
                "Tea",
                "Timber Lake",
                "Tolstoy",
                "Toronto",
                "Trent",
                "Tripp",
                "Tulare",
                "Turton",
                "Twin Brooks",
                "Two Strike",
                "Tyndall",
                "Utica",
                "Valley Springs",
                "Veblen",
                "Verdon",
                "Vermillion",
                "Viborg",
                "Vienna",
                "Vilas",
                "Virgil",
                "Vivian",
                "Volga",
                "Volin",
                "Wagner",
                "Wakonda",
                "Wall",
                "Wallace",
                "Wanblee",
                "Ward",
                "Warner",
                "Wasta",
                "Watertown",
                "Waubay",
                "Webster",
                "Wentworth",
                "Wessington",
                "Wessington Springs",
                "Westport",
                "Wetonka",
                "White",
                "White Horse",
                "White Lake",
                "White River",
                "White Rock",
                "Whitehorse",
                "Whitewood",
                "Willow Lake",
                "Wilmot",
                "Winner",
                "Wolsey",
                "Wood",
                "Woonsocket",
                "Worthing",
                "Wounded Knee",
                "Yale",
                "Yankton"
            ],
            "Sublimity": [
                "Aberdeen",
                "Agar",
                "Akaska",
                "Albee",
                "Alcester",
                "Alexandria",
                "Allen",
                "Alpena",
                "Altamont",
                "Andover",
                "Antelope",
                "Arlington",
                "Armour",
                "Artas",
                "Artesian",
                "Ashland Heights",
                "Ashton",
                "Astoria",
                "Aurora",
                "Aurora Center",
                "Avon",
                "Badger",
                "Baltic",
                "Bancroft",
                "Batesland",
                "Belle Fourche",
                "Belvidere",
                "Beresford",
                "Big Stone City",
                "Bison",
                "Blackhawk",
                "Blunt",
                "Bonesteel",
                "Bowdle",
                "Box Elder",
                "Bradley",
                "Brandon",
                "Brandt",
                "Brentford",
                "Bridgewater",
                "Bristol",
                "Britton",
                "Broadland",
                "Brookings",
                "Bruce",
                "Bryant",
                "Buffalo",
                "Buffalo Gap",
                "Bullhead",
                "Burke",
                "Bushnell",
                "Butler",
                "Camp Crook",
                "Canistota",
                "Canova",
                "Canton",
                "Carthage",
                "Castlewood",
                "Cavour",
                "Centerville",
                "Central City",
                "Chamberlain",
                "Chancellor",
                "Chelsea",
                "Claire City",
                "Claremont",
                "Clark",
                "Clear Lake",
                "Colman",
                "Colome",
                "Colonial Pine Hills",
                "Colton",
                "Columbia",
                "Conde",
                "Corona",
                "Corsica",
                "Cottonwood",
                "Cresbard",
                "Crooks",
                "Custer",
                "Dallas",
                "Dante",
                "Davis",
                "De Smet",
                "Deadwood",
                "Dell Rapids",
                "Delmont",
                "Dimock",
                "Doland",
                "Dolton",
                "Draper",
                "Dupree",
                "Eagle Butte",
                "Eden",
                "Edgemont",
                "Egan",
                "Elk Point",
                "Elkton",
                "Ellsworth Afb",
                "Emery",
                "Erwin",
                "Estelline",
                "Ethan",
                "Eureka",
                "Fairburn",
                "Fairfax",
                "Fairview",
                "Faith",
                "Farmer",
                "Faulkton",
                "Flandreau",
                "Florence",
                "Fort Pierre",
                "Fort Thompson",
                "Frankfort",
                "Frederick",
                "Freeman",
                "Fruitdale",
                "Fulton",
                "Garden City",
                "Garretson",
                "Gary",
                "Gayville",
                "Geddes",
                "Gettysburg",
                "Glenham",
                "Goodwin",
                "Green Grass",
                "Green Valley",
                "Gregory",
                "Grenville",
                "Groton",
                "Hamill",
                "Harrisburg",
                "Harrison",
                "Harrold",
                "Hartford",
                "Hayti",
                "Hazel",
                "Hecla",
                "Henry",
                "Hermosa",
                "Herreid",
                "Herrick",
                "Hetland",
                "Highmore",
                "Hill City",
                "Hillsview",
                "Hitchcock",
                "Hosmer",
                "Hot Springs",
                "Hoven",
                "Howard",
                "Hudson",
                "Humboldt",
                "Hurley",
                "Huron",
                "Interior",
                "Ipswich",
                "Irene",
                "Iroquois",
                "Isabel",
                "Java",
                "Jefferson",
                "Kadoka",
                "Kaylor",
                "Kennebec",
                "Keystone",
                "Kimball",
                "Kranzburg",
                "Kyle",
                "La Bolt",
                "La Plant",
                "Lake Andes",
                "Lake City",
                "Lake Norden",
                "Lake Preston",
                "Lane",
                "Langford",
                "Lead",
                "Lebanon",
                "Lemmon",
                "Lennox",
                "Leola",
                "Lesterville",
                "Letcher",
                "Lily",
                "Little Eagle",
                "Long Lake",
                "Loomis",
                "Lower Brule",
                "Lowry",
                "Madison",
                "Manderson-white Horse Creek",
                "Marion",
                "Martin",
                "Marty",
                "Marvin",
                "Mcintosh",
                "Mclaughlin",
                "Mellette",
                "Menno",
                "Midland",
                "Milbank",
                "Miller",
                "Milltown",
                "Mission",
                "Mission Hill",
                "Mitchell",
                "Mobridge",
                "Monroe",
                "Montrose",
                "Morristown",
                "Mound City",
                "Mount Vernon",
                "Murdo",
                "Naples",
                "New Effington",
                "New Holland",
                "New Underwood",
                "New Witten",
                "Newell",
                "Nisland",
                "North Eagle Butte",
                "North Sioux City",
                "North Spearfish",
                "Northville",
                "Nunda",
                "Oacoma",
                "Oelrichs",
                "Oglala",
                "Okaton",
                "Oldham",
                "Olivet",
                "Onaka",
                "Onida",
                "Orient",
                "Ortley",
                "Parker",
                "Parkston",
                "Parmelee",
                "Peever",
                "Philip",
                "Pickstown",
                "Pierpont",
                "Pierre",
                "Pine Ridge",
                "Plankinton",
                "Platte",
                "Pollock",
                "Porcupine",
                "Presho",
                "Pringle",
                "Pukwana",
                "Quinn",
                "Ramona",
                "Rapid City",
                "Rapid Valley",
                "Ravinia",
                "Raymond",
                "Redfield",
                "Ree Heights",
                "Reliance",
                "Revillo",
                "Rockham",
                "Roscoe",
                "Rosebud",
                "Rosholt",
                "Roslyn",
                "Roswell",
                "Salem",
                "Scotland",
                "Selby",
                "Seneca",
                "Sherman",
                "Sinai",
                "Sioux Falls",
                "Sisseton",
                "South Shore",
                "Spearfish",
                "Spencer",
                "Spring Creek",
                "Springfield",
                "St. Charles",
                "St. Francis",
                "St. Lawrence",
                "Stickney",
                "Stockholm",
                "Storla",
                "Strandburg",
                "Stratford",
                "Sturgis",
                "Summit",
                "Tabor",
                "Tea",
                "Timber Lake",
                "Tolstoy",
                "Toronto",
                "Trent",
                "Tripp",
                "Tulare",
                "Turton",
                "Twin Brooks",
                "Two Strike",
                "Tyndall",
                "Utica",
                "Valley Springs",
                "Veblen",
                "Verdon",
                "Vermillion",
                "Viborg",
                "Vienna",
                "Vilas",
                "Virgil",
                "Vivian",
                "Volga",
                "Volin",
                "Wagner",
                "Wakonda",
                "Wall",
                "Wallace",
                "Wanblee",
                "Ward",
                "Warner",
                "Wasta",
                "Watertown",
                "Waubay",
                "Webster",
                "Wentworth",
                "Wessington",
                "Wessington Springs",
                "Westport",
                "Wetonka",
                "White",
                "White Horse",
                "White Lake",
                "White River",
                "White Rock",
                "Whitehorse",
                "Whitewood",
                "Willow Lake",
                "Wilmot",
                "Winner",
                "Wolsey",
                "Wood",
                "Woonsocket",
                "Worthing",
                "Wounded Knee",
                "Yale",
                "Yankton"
            ],
            "Tennessee": [
                "Adams",
                "Adamsville",
                "Alamo",
                "Alcoa",
                "Alexandria",
                "Algood",
                "Allardt",
                "Altamont",
                "Ardmore",
                "Arlington",
                "Ashland City",
                "Athens",
                "Atoka",
                "Atwood",
                "Auburntown",
                "Baileyton",
                "Baneberry",
                "Banner Hill",
                "Bartlett",
                "Baxter",
                "Beersheba Springs",
                "Bell Buckle",
                "Belle Meade",
                "Bells",
                "Benton",
                "Berry Hill",
                "Bethel Springs",
                "Big Sandy",
                "Blaine",
                "Bloomingdale",
                "Blountville",
                "Bluff City",
                "Bolivar",
                "Braden",
                "Bradford",
                "Brentwood",
                "Brighton",
                "Bristol",
                "Brownsville",
                "Bruceton",
                "Bulls Gap",
                "Burlison",
                "Burns",
                "Byrdstown",
                "Calhoun",
                "Camden",
                "Carthage",
                "Caryville",
                "Cedar Hill",
                "Celina",
                "Centertown",
                "Centerville",
                "Central",
                "Chapel Hill",
                "Charleston",
                "Charlotte",
                "Chattanooga",
                "Church Hill",
                "Clarksburg",
                "Clarksville",
                "Cleveland",
                "Clifton",
                "Clinton",
                "Coalmont",
                "Collegedale",
                "Collierville",
                "Collinwood",
                "Colonial Heights",
                "Columbia",
                "Cookeville",
                "Coopertown",
                "Copperhill",
                "Cornersville",
                "Cottage Grove",
                "Covington",
                "Cowan",
                "Crab Orchard",
                "Cross Plains",
                "Crossville",
                "Crump",
                "Cumberland City",
                "Cumberland Gap",
                "Dandridge",
                "Dayton",
                "Decatur",
                "Decaturville",
                "Decherd",
                "Dickson",
                "Dover",
                "Dowelltown",
                "Doyle",
                "Dresden",
                "Ducktown",
                "Dunlap",
                "Dyer",
                "Dyersburg",
                "Eagleton Village",
                "Eagleville",
                "East Brainerd",
                "East Cleveland",
                "East Ridge",
                "Eastview",
                "Elizabethton",
                "Elkton",
                "Englewood",
                "Enville",
                "Erin",
                "Erwin",
                "Estill Springs",
                "Ethridge",
                "Etowah",
                "Fairfield Glade",
                "Fairmount",
                "Fairview",
                "Fall Branch",
                "Farragut",
                "Fayetteville",
                "Finger",
                "Forest Hills",
                "Franklin",
                "Friendship",
                "Friendsville",
                "Gadsden",
                "Gainesboro",
                "Gallatin",
                "Gallaway",
                "Garland",
                "Gates",
                "Gatlinburg",
                "Germantown",
                "Gibson",
                "Gilt Edge",
                "Gleason",
                "Goodlettsville",
                "Gordonsville",
                "Grand Junction",
                "Gray",
                "Graysville",
                "Green Hill",
                "Greenback",
                "Greenbrier",
                "Greeneville",
                "Greenfield",
                "Gruetli-laager",
                "Guys",
                "Halls",
                "Harriman",
                "Harrison",
                "Harrogate-shawanee",
                "Hartsville",
                "Helenwood",
                "Henderson",
                "Hendersonville",
                "Henning",
                "Henry",
                "Hickory Valley",
                "Hickory Withe",
                "Hohenwald",
                "Hollow Rock",
                "Hopewell",
                "Hornbeak",
                "Hornsby",
                "Humboldt",
                "Hunter",
                "Huntingdon",
                "Huntland",
                "Huntsville",
                "Iron City",
                "Jacksboro",
                "Jackson",
                "Jamestown",
                "Jasper",
                "Jefferson City",
                "Jellico",
                "Johnson City",
                "Jonesborough",
                "Kenton",
                "Kimball",
                "Kingsport",
                "Kingston",
                "Kingston Springs",
                "Knoxville",
                "La Follette",
                "La Grange",
                "La Vergne",
                "Lafayette",
                "Lake City",
                "Lake Tansi",
                "Lakeland",
                "Lakesite",
                "Lakewood",
                "Lawrenceburg",
                "Lebanon",
                "Lenoir City",
                "Lewisburg",
                "Lexington",
                "Liberty",
                "Linden",
                "Livingston",
                "Lobelville",
                "Lookout Mountain",
                "Loretto",
                "Loudon",
                "Louisville",
                "Luttrell",
                "Lynchburg",
                "Lynnville",
                "Madisonville",
                "Manchester",
                "Martin",
                "Maryville",
                "Mascot",
                "Mason",
                "Maury City",
                "Maynardville",
                "Mcewen",
                "Mckenzie",
                "Mclemoresville",
                "Mcminnville",
                "Medina",
                "Medon",
                "Memphis",
                "Michie",
                "Middle Valley",
                "Middleton",
                "Midtown",
                "Midway",
                "Milan",
                "Milledgeville",
                "Millersville",
                "Millington",
                "Minor Hill",
                "Mitchellville",
                "Monteagle",
                "Monterey",
                "Morrison",
                "Morristown",
                "Moscow",
                "Mosheim",
                "Mount Carmel",
                "Mount Juliet",
                "Mount Pleasant",
                "Mountain City",
                "Munford",
                "Murfreesboro",
                "Nashville-davidson",
                "New Hope",
                "New Johnsonville",
                "New Market",
                "New Tazewell",
                "Newbern",
                "Newport",
                "Niota",
                "Nolensville",
                "Normandy",
                "Norris",
                "Oak Grove",
                "Oak Hill",
                "Oak Ridge",
                "Oakdale",
                "Oakland",
                "Obion",
                "Oliver Springs",
                "Oneida",
                "Ooltewah",
                "Orlinda",
                "Orme",
                "Palmer",
                "Paris",
                "Parkers Crossroads",
                "Parrottsville",
                "Parsons",
                "Pegram",
                "Petersburg",
                "Philadelphia",
                "Pigeon Forge",
                "Pikeville",
                "Pine Crest",
                "Piperton",
                "Pittman Center",
                "Plainview",
                "Pleasant Hill",
                "Pleasant View",
                "Portland",
                "Powells Crossroads",
                "Pulaski",
                "Puryear",
                "Ramer",
                "Red Bank",
                "Red Boiling Springs",
                "Ridgely",
                "Ridgeside",
                "Ridgetop",
                "Ripley",
                "Rives",
                "Roan Mountain",
                "Rockford",
                "Rockwood",
                "Rogersville",
                "Rossville",
                "Rural Hill",
                "Rutherford",
                "Rutledge",
                "Saltillo",
                "Samburg",
                "Sardis",
                "Saulsbury",
                "Savannah",
                "Scotts Hill",
                "Selmer",
                "Sevierville",
                "Sewanee",
                "Seymour",
                "Sharon",
                "Shelbyville",
                "Signal Mountain",
                "Silerton",
                "Slayden",
                "Smithville",
                "Smyrna",
                "Sneedville",
                "Soddy-daisy",
                "Somerville",
                "South Carthage",
                "South Cleveland",
                "South Fulton",
                "South Pittsburg",
                "Sparta",
                "Spencer",
                "Spring City",
                "Spring Hill",
                "Springfield",
                "Spurgeon",
                "St. Joseph",
                "Stanton",
                "Stantonville",
                "Sunbright",
                "Surgoinsville",
                "Sweetwater",
                "Tazewell",
                "Tellico Plains",
                "Tennessee Ridge",
                "Thompson S Station",
                "Three Way",
                "Tiptonville",
                "Toone",
                "Townsend",
                "Tracy City",
                "Trenton",
                "Trezevant",
                "Trimble",
                "Troy",
                "Tullahoma",
                "Tusculum",
                "Unicoi",
                "Union City",
                "Vanleer",
                "Viola",
                "Vonore",
                "Walden",
                "Walnut Grove",
                "Walnut Hill",
                "Walterhill",
                "Wartburg",
                "Wartrace",
                "Watauga",
                "Watertown",
                "Waverly",
                "Waynesboro",
                "Westmoreland",
                "White Bluff",
                "White House",
                "White Pine",
                "Whiteville",
                "Whitwell",
                "Wildwood Lake",
                "Williston",
                "Winchester",
                "Winfield",
                "Woodbury",
                "Woodland Mills",
                "Yorkville"
            ],
            "Texas": [
                "Abbott",
                "Abernathy",
                "Abilene",
                "Abram-perezville",
                "Ackerly",
                "Addison",
                "Adrian",
                "Agua Dulce",
                "Airport Road Addition",
                "Alamo",
                "Alamo Heights",
                "Alba",
                "Albany",
                "Aldine",
                "Aledo",
                "Alfred-south La Paloma",
                "Alice",
                "Alice Acres",
                "Allen",
                "Alma",
                "Alpine",
                "Alto",
                "Alto Bonito",
                "Alton",
                "Alton North",
                "Alvarado",
                "Alvin",
                "Alvord",
                "Amarillo",
                "Ames",
                "Amherst",
                "Anahuac",
                "Anderson",
                "Anderson Mill",
                "Andrews",
                "Angleton",
                "Angus",
                "Anna",
                "Annetta",
                "Annetta North",
                "Annetta South",
                "Annona",
                "Anson",
                "Anthony",
                "Anton",
                "Appleby",
                "Aquilla",
                "Aransas Pass",
                "Archer City",
                "Arcola",
                "Argyle",
                "Arlington",
                "Arp",
                "Arroyo Alto",
                "Arroyo Colorado Estates",
                "Arroyo Gardens-la Tina Ranch",
                "Asherton",
                "Aspermont",
                "Atascocita",
                "Athens",
                "Atlanta",
                "Aubrey",
                "Aurora",
                "Austin",
                "Austwell",
                "Avery",
                "Avinger",
                "Azle",
                "Bacliff",
                "Bailey",
                "Bailey S Prairie",
                "Baird",
                "Balch Springs",
                "Balcones Heights",
                "Ballinger",
                "Balmorhea",
                "Bandera",
                "Bangs",
                "Bardwell",
                "Barrett",
                "Barry",
                "Barstow",
                "Bartlett",
                "Barton Creek",
                "Bartonville",
                "Bastrop",
                "Batesville",
                "Bausell And Ellis",
                "Bay City",
                "Bayou Vista",
                "Bayside",
                "Baytown",
                "Bayview",
                "Beach City",
                "Bear Creek",
                "Beasley",
                "Beaumont",
                "Beckville",
                "Bedford",
                "Bee Cave",
                "Beeville",
                "Bellaire",
                "Bellevue",
                "Bellmead",
                "Bells",
                "Bellville",
                "Belton",
                "Benavides",
                "Benbrook",
                "Benjamin",
                "Berryville",
                "Bertram",
                "Beverly Hills",
                "Bevil Oaks",
                "Big Lake",
                "Big Sandy",
                "Big Spring",
                "Big Wells",
                "Bigfoot",
                "Bishop",
                "Bishop Hills",
                "Bixby",
                "Blackwell",
                "Blanco",
                "Blanket",
                "Blessing",
                "Bloomburg",
                "Blooming Grove",
                "Bloomington",
                "Blossom",
                "Blue Berry Hill",
                "Blue Mound",
                "Blue Ridge",
                "Bluetown-iglesia Antigua",
                "Blum",
                "Boerne",
                "Bogata",
                "Boling-iago",
                "Bolivar Peninsula",
                "Bonham",
                "Bonney",
                "Booker",
                "Borger",
                "Botines",
                "Bovina",
                "Bowie",
                "Box Canyon-amistad",
                "Boyd",
                "Brackettville",
                "Brady",
                "Brazoria",
                "Breckenridge",
                "Bremond",
                "Brenham",
                "Briar",
                "Briarcliff",
                "Briaroaks",
                "Bridge City",
                "Bridgeport",
                "Broaddus",
                "Bronte",
                "Brookshire",
                "Brookside Village",
                "Browndell",
                "Brownfield",
                "Brownsboro",
                "Brownsville",
                "Brownwood",
                "Bruceville-eddy",
                "Brundage",
                "Bruni",
                "Brushy Creek",
                "Bryan",
                "Bryson",
                "Buchanan Dam",
                "Buckholts",
                "Buda",
                "Buffalo",
                "Buffalo Gap",
                "Buffalo Springs",
                "Bullard",
                "Bulverde",
                "Buna",
                "Bunker Hill Village",
                "Burkburnett",
                "Burke",
                "Burleson",
                "Burnet",
                "Burton",
                "Butterfield",
                "Byers",
                "Bynum",
                "Cactus",
                "Caddo Mills",
                "Caldwell",
                "Callisburg",
                "Calvert",
                "Cameron",
                "Cameron Park",
                "Camp Swift",
                "Camp Wood",
                "Campbell",
                "Canadian",
                "Caney City",
                "Canton",
                "Cantu Addition",
                "Canutillo",
                "Canyon",
                "Canyon Lake",
                "Carbon",
                "Carl S Corner",
                "Carmine",
                "Carrizo Hill",
                "Carrizo Springs",
                "Carrollton",
                "Carthage",
                "Castle Hills",
                "Castroville",
                "Catarina",
                "Cedar Hill",
                "Cedar Park",
                "Celeste",
                "Celina",
                "Center",
                "Centerville",
                "Central Gardens",
                "Cesar Chavez",
                "Chandler",
                "Channelview",
                "Channing",
                "Charlotte",
                "Chester",
                "Chico",
                "Childress",
                "Chillicothe",
                "China",
                "China Grove",
                "Chireno",
                "Christine",
                "Christoval",
                "Chula Vista-orason",
                "Chula Vista-river Spur",
                "Cibolo",
                "Cienegas Terrace",
                "Cinco Ranch",
                "Circle D-kc Estates",
                "Cisco",
                "Citrus City",
                "Clarendon",
                "Clarksville",
                "Clarksville City",
                "Claude",
                "Clear Lake Shores",
                "Cleburne",
                "Cleveland",
                "Clifton",
                "Clint",
                "Cloverleaf",
                "Clute",
                "Clyde",
                "Coahoma",
                "Cockrell Hill",
                "Coffee City",
                "Coldspring",
                "Coleman",
                "College Station",
                "Colleyville",
                "Collinsville",
                "Colmesneil",
                "Colorado City",
                "Columbus",
                "Comanche",
                "Combes",
                "Combine",
                "Comfort",
                "Commerce",
                "Como",
                "Concepcion",
                "Conroe",
                "Converse",
                "Cool",
                "Coolidge",
                "Cooper",
                "Coppell",
                "Copper Canyon",
                "Copperas Cove",
                "Corinth",
                "Corpus Christi",
                "Corral City",
                "Corrigan",
                "Corsicana",
                "Cottonwood",
                "Cottonwood Shores",
                "Cotulla",
                "Cove",
                "Covington",
                "Coyanosa",
                "Coyote Acres",
                "Crandall",
                "Crane",
                "Cranfills Gap",
                "Crawford",
                "Creedmoor",
                "Crockett",
                "Crosby",
                "Crosbyton",
                "Cross Mountain",
                "Cross Plains",
                "Cross Roads",
                "Cross Timber",
                "Crowell",
                "Crowley",
                "Crystal City",
                "Cuero",
                "Cuevitas",
                "Cumby",
                "Cumings",
                "Cuney",
                "Cushing",
                "Cut And Shoot",
                "Daingerfield",
                "Daisetta",
                "Dalhart",
                "Dallas",
                "Dalworthington Gardens",
                "Damon",
                "Danbury",
                "Darrouzett",
                "Dawson",
                "Dayton",
                "Dayton Lakes",
                "De Kalb",
                "De Leon",
                "Dean",
                "Decatur",
                "Deer Park",
                "Del Mar Heights",
                "Del Rio",
                "Del Sol-loma Linda",
                "Dell City",
                "Denison",
                "Denton",
                "Denver City",
                "Deport",
                "Desoto",
                "Detroit",
                "Devers",
                "Devine",
                "Deweyville",
                "Diboll",
                "Dickens",
                "Dickinson",
                "Dilley",
                "Dimmitt",
                "Dodd City",
                "Dodson",
                "Doffing",
                "Domino",
                "Donna",
                "Doolittle",
                "Dorchester",
                "Double Oak",
                "Douglassville",
                "Doyle",
                "Dripping Springs",
                "Driscoll",
                "Dublin",
                "Dumas",
                "Duncanville",
                "Eagle Lake",
                "Eagle Mountain",
                "Eagle Pass",
                "Early",
                "Earth",
                "East Bernard",
                "East Mountain",
                "East Tawakoni",
                "Eastland",
                "Easton",
                "Ector",
                "Edcouch",
                "Eden",
                "Edgecliff Village",
                "Edgewater-paisano",
                "Edgewood",
                "Edinburg",
                "Edmonson",
                "Edna",
                "Edom",
                "Edroy",
                "Eidson Road",
                "El Camino Angosto",
                "El Campo",
                "El Cenizo",
                "El Indio",
                "El Lago",
                "El Paso",
                "El Refugio",
                "Elbert",
                "Eldorado",
                "Electra",
                "Elgin",
                "Elkhart",
                "Elm Creek",
                "Elmendorf",
                "Elsa",
                "Emhouse",
                "Emory",
                "Encantada-ranchito El Calaboz",
                "Enchanted Oaks",
                "Encinal",
                "Encino",
                "Ennis",
                "Escobares",
                "Estelline",
                "Euless",
                "Eureka",
                "Eustace",
                "Evadale",
                "Evant",
                "Everman",
                "Fabens",
                "Fair Oaks Ranch",
                "Fairchilds",
                "Fairfield",
                "Fairview",
                "Falcon Heights",
                "Falcon Lake Estates",
                "Falcon Mesa",
                "Falcon Village",
                "Falfurrias",
                "Falls City",
                "Falman-county Acres",
                "Farmers Branch",
                "Farmersville",
                "Farwell",
                "Fate",
                "Fayetteville",
                "Faysville",
                "Ferris",
                "Fifth Street",
                "Flatonia",
                "Florence",
                "Floresville",
                "Flowella",
                "Flower Mound",
                "Floydada",
                "Follett",
                "Forest Hill",
                "Forney",
                "Forsan",
                "Fort Bliss",
                "Fort Davis",
                "Fort Hancock",
                "Fort Hood",
                "Fort Stockton",
                "Fort Worth",
                "Four Corners",
                "Fowlerton",
                "Franklin",
                "Frankston",
                "Fredericksburg",
                "Freeport",
                "Freer",
                "Fresno",
                "Friendswood",
                "Friona",
                "Frisco",
                "Fritch",
                "Fronton",
                "Frost",
                "Fruitvale",
                "Fulshear",
                "Fulton",
                "Gainesville",
                "Galena Park",
                "Gallatin",
                "Galveston",
                "Ganado",
                "Garceno",
                "Garden Ridge",
                "Gardendale",
                "Garfield",
                "Garland",
                "Garrett",
                "Garrison",
                "Gary City",
                "Gatesville",
                "George West",
                "Georgetown",
                "Geronimo",
                "Gholson",
                "Giddings",
                "Gilmer",
                "Girard",
                "Gladewater",
                "Glen Rose",
                "Glenn Heights",
                "Godley",
                "Goldsmith",
                "Goldthwaite",
                "Goliad",
                "Golinda",
                "Gonzales",
                "Goodlow",
                "Goodrich",
                "Gordon",
                "Goree",
                "Gorman",
                "Graford",
                "Graham",
                "Granbury",
                "Grand Acres",
                "Grand Prairie",
                "Grand Saline",
                "Grandfalls",
                "Grandview",
                "Granger",
                "Granite Shoals",
                "Granjeno",
                "Grape Creek",
                "Grapeland",
                "Grapevine",
                "Grays Prairie",
                "Greatwood",
                "Green Valley Farms",
                "Greenville",
                "Gregory",
                "Grey Forest",
                "Groesbeck",
                "Groom",
                "Groves",
                "Groveton",
                "Gruver",
                "Guerra",
                "Gun Barrel City",
                "Gunter",
                "Gustine",
                "Hackberry",
                "Hale Center",
                "Hallettsville",
                "Hallsburg",
                "Hallsville",
                "Haltom City",
                "Hamilton",
                "Hamlin",
                "Happy",
                "Hardin",
                "Harker Heights",
                "Harlingen",
                "Harper",
                "Hart",
                "Hartley",
                "Haskell",
                "Haslet",
                "Havana",
                "Hawk Cove",
                "Hawkins",
                "Hawley",
                "Hays",
                "Hearne",
                "Heath",
                "Hebbronville",
                "Hebron",
                "Hedley",
                "Hedwig Village",
                "Heidelberg",
                "Helotes",
                "Hemphill",
                "Hempstead",
                "Henderson",
                "Henrietta",
                "Hereford",
                "Hermleigh",
                "Hewitt",
                "Hickory Creek",
                "Hico",
                "Hidalgo",
                "Higgins",
                "Highland Haven",
                "Highland Park",
                "Highland Village",
                "Highlands",
                "Hill Country Village",
                "Hillcrest",
                "Hillsboro",
                "Hilltop",
                "Hilshire Village",
                "Hitchcock",
                "Holiday Lakes",
                "Holland",
                "Holliday",
                "Hollywood Park",
                "Homestead Meadows North",
                "Homestead Meadows South",
                "Hondo",
                "Honey Grove",
                "Hooks",
                "Horizon City",
                "Horseshoe Bay",
                "Houston",
                "Howardwick",
                "Howe",
                "Hubbard",
                "Hudson",
                "Hudson Bend",
                "Hudson Oaks",
                "Hughes Springs",
                "Humble",
                "Hungerford",
                "Hunters Creek Village",
                "Huntington",
                "Huntsville",
                "Hurst",
                "Hutchins",
                "Hutto",
                "Huxley",
                "Idalou",
                "Impact",
                "Imperial",
                "Indian Hills",
                "Indian Lake",
                "Industry",
                "Inez",
                "Ingleside",
                "Ingleside On The Bay",
                "Ingram",
                "Iowa Colony",
                "Iowa Park",
                "Iraan",
                "Iredell",
                "Irving",
                "Italy",
                "Itasca",
                "Jacinto City",
                "Jacksboro",
                "Jacksonville",
                "Jamaica Beach",
                "Jasper",
                "Jayton",
                "Jefferson",
                "Jersey Village",
                "Jewett",
                "Joaquin",
                "Johnson City",
                "Jolly",
                "Jollyville",
                "Jones Creek",
                "Jonestown",
                "Josephine",
                "Joshua",
                "Jourdanton",
                "Junction",
                "Justin",
                "K-bar Ranch",
                "Karnes City",
                "Katy",
                "Kaufman",
                "Keene",
                "Keller",
                "Kemah",
                "Kemp",
                "Kempner",
                "Kendleton",
                "Kenedy",
                "Kenefick",
                "Kennard",
                "Kennedale",
                "Kerens",
                "Kermit",
                "Kerrville",
                "Kilgore",
                "Killeen",
                "Kingsbury",
                "Kingsland",
                "Kingsville",
                "Kirby",
                "Kirbyville",
                "Kirvin",
                "Knippa",
                "Knollwood",
                "Knox City",
                "Kosse",
                "Kountze",
                "Kress",
                "Krugerville",
                "Krum",
                "Kyle",
                "La Blanca",
                "La Casita-garciasville",
                "La Feria",
                "La Feria North",
                "La Grange",
                "La Grulla",
                "La Homa",
                "La Joya",
                "La Marque",
                "La Paloma",
                "La Paloma-lost Creek",
                "La Porte",
                "La Presa",
                "La Pryor",
                "La Puerta",
                "La Rosita",
                "La Vernia",
                "La Victoria",
                "La Villa",
                "La Ward",
                "Lackland Afb",
                "Lacoste",
                "Lacy-lakeview",
                "Ladonia",
                "Lago",
                "Lago Vista",
                "Laguna Heights",
                "Laguna Seca",
                "Laguna Vista",
                "Lake Bridgeport",
                "Lake Brownwood",
                "Lake City",
                "Lake Dallas",
                "Lake Jackson",
                "Lake Kiowa",
                "Lake Tanglewood",
                "Lake View",
                "Lake Worth",
                "Lakehills",
                "Lakeport",
                "Lakeshore Gardens-hidden Acres",
                "Lakeside",
                "Lakeside City",
                "Lakeview",
                "Lakeway",
                "Lakewood Village",
                "Lamesa",
                "Lampasas",
                "Lancaster",
                "Laredo",
                "Laredo Ranchettes",
                "Larga Vista",
                "Las Colonias",
                "Las Lomas",
                "Las Lomitas",
                "Las Palmas-juarez",
                "Las Quintas Fronterizas",
                "Lasana",
                "Lasara",
                "Latexo",
                "Laughlin Afb",
                "Laureles",
                "Lavon",
                "Lawn",
                "League City",
                "Leakey",
                "Leander",
                "Leary",
                "Lefors",
                "Leon Valley",
                "Leona",
                "Leonard",
                "Leroy",
                "Levelland",
                "Lewisville",
                "Lexington",
                "Liberty",
                "Liberty City",
                "Liberty Hill",
                "Lincoln Park",
                "Lindale",
                "Linden",
                "Lindsay",
                "Lipan",
                "Lipscomb",
                "Little Elm",
                "Little River-academy",
                "Littlefield",
                "Live Oak",
                "Liverpool",
                "Livingston",
                "Llano",
                "Llano Grande",
                "Lockhart",
                "Lockney",
                "Log Cabin",
                "Lolita",
                "Loma Linda East",
                "Lometa",
                "Lone Oak",
                "Lone Star",
                "Longview",
                "Lopeno",
                "Lopezville",
                "Loraine",
                "Lorena",
                "Lorenzo",
                "Los Alvarez",
                "Los Angeles Subdivision",
                "Los Ebanos",
                "Los Fresnos",
                "Los Indios",
                "Los Villareales",
                "Los Ybanez",
                "Lost Creek",
                "Lott",
                "Louise",
                "Lovelady",
                "Lowry Crossing",
                "Lozano",
                "Lubbock",
                "Lucas",
                "Lueders",
                "Lufkin",
                "Luling",
                "Lumberton",
                "Lyford",
                "Lyford South",
                "Lytle",
                "Mabank",
                "Madisonville",
                "Magnolia",
                "Malakoff",
                "Malone",
                "Manor",
                "Mansfield",
                "Manvel",
                "Marathon",
                "Marble Falls",
                "Marfa",
                "Marietta",
                "Marion",
                "Markham",
                "Marlin",
                "Marquez",
                "Marshall",
                "Marshall Creek",
                "Mart",
                "Martindale",
                "Mason",
                "Matador",
                "Mathis",
                "Maud",
                "Mauriceville",
                "Maypearl",
                "Mcallen",
                "Mccamey",
                "Mcgregor",
                "Mckinney",
                "Mclean",
                "Mclendon-chisholm",
                "Mcqueeney",
                "Meadow",
                "Meadowlakes",
                "Meadows Place",
                "Medina",
                "Megargel",
                "Melissa",
                "Melvin",
                "Memphis",
                "Menard",
                "Mercedes",
                "Meridian",
                "Merkel",
                "Mertens",
                "Mertzon",
                "Mesquite",
                "Mexia",
                "Miami",
                "Midland",
                "Midlothian",
                "Midway",
                "Midway North",
                "Midway South",
                "Mila Doce",
                "Milam",
                "Milano",
                "Mildred",
                "Miles",
                "Milford",
                "Miller S Cove",
                "Millican",
                "Millsap",
                "Mineola",
                "Mineral Wells",
                "Mingus",
                "Mirando City",
                "Mission",
                "Mission Bend",
                "Missouri City",
                "Mobeetie",
                "Mobile City",
                "Monahans",
                "Mont Belvieu",
                "Monte Alto",
                "Montgomery",
                "Moody",
                "Moore",
                "Moore Station",
                "Morales-sanchez",
                "Moran",
                "Morgan",
                "Morgan Farm Area",
                "Morgan S Point Resort",
                "Morgans Point",
                "Morning Glory",
                "Morse",
                "Morton",
                "Moulton",
                "Mount Calm",
                "Mount Enterprise",
                "Mount Pleasant",
                "Mount Vernon",
                "Mountain City",
                "Muenster",
                "Muleshoe",
                "Mullin",
                "Munday",
                "Muniz",
                "Murchison",
                "Murphy",
                "Mustang",
                "Mustang Ridge",
                "Nacogdoches",
                "Naples",
                "Nash",
                "Nassau Bay",
                "Natalia",
                "Navarro",
                "Navasota",
                "Nazareth",
                "Nederland",
                "Needville",
                "Nesbitt",
                "Nevada",
                "New Berlin",
                "New Boston",
                "New Braunfels",
                "New Chapel Hill",
                "New Deal",
                "New Fairview",
                "New Falcon",
                "New Home",
                "New Hope",
                "New London",
                "New Summerfield",
                "New Territory",
                "New Waverly",
                "Newark",
                "Newcastle",
                "Newton",
                "Neylandville",
                "Niederwald",
                "Nixon",
                "Nocona",
                "Nolanville",
                "Nome",
                "Noonday",
                "Nordheim",
                "Normangee",
                "Normanna",
                "North Alamo",
                "North Cleveland",
                "North Escobares",
                "North Pearsall",
                "North Richland Hills",
                "North San Pedro",
                "Northcliff",
                "Northlake",
                "Novice",
                "Nurillo",
                "O",
                "O Brien",
                "O Donnell",
                "Oak Grove",
                "Oak Leaf",
                "Oak Point",
                "Oak Ridge",
                "Oak Ridge North",
                "Oak Trail Shores",
                "Oak Valley",
                "Oakhurst",
                "Oakwood",
                "Odem",
                "Odessa",
                "Oglesby",
                "Oilton",
                "Old River-winfree",
                "Olivarez",
                "Olmito",
                "Olmos Park",
                "Olney",
                "Olton",
                "Omaha",
                "Onalaska",
                "Onion Creek",
                "Opdyke West",
                "Orange",
                "Orange Grove",
                "Orchard",
                "Ore City",
                "Overton",
                "Ovilla",
                "Owl Ranch-amargosa",
                "Oyster Creek",
                "Paducah",
                "Paint Rock",
                "Palacios",
                "Palestine",
                "Palisades",
                "Palm Valley",
                "Palmer",
                "Palmhurst",
                "Palmview",
                "Palmview South",
                "Pampa",
                "Panhandle",
                "Panorama Village",
                "Pantego",
                "Paradise",
                "Paris",
                "Parker",
                "Pasadena",
                "Pattison",
                "Patton Village",
                "Pawnee",
                "Payne Springs",
                "Pearland",
                "Pearsall",
                "Pecan Acres",
                "Pecan Gap",
                "Pecan Grove",
                "Pecan Hill",
                "Pecan Plantation",
                "Pecos",
                "Pelican Bay",
                "Penelope",
                "Penitas",
                "Pernitas Point",
                "Perryton",
                "Petersburg",
                "Petrolia",
                "Petronila",
                "Pettus",
                "Pflugerville",
                "Pharr",
                "Pilot Point",
                "Pine Forest",
                "Pine Island",
                "Pinehurst",
                "Pineland",
                "Pinewood Estates",
                "Piney Point Village",
                "Pittsburg",
                "Plains",
                "Plainview",
                "Plano",
                "Pleak",
                "Pleasant Valley",
                "Pleasanton",
                "Plum Grove",
                "Point",
                "Point Blank",
                "Point Comfort",
                "Ponder",
                "Port Aransas",
                "Port Arthur",
                "Port Isabel",
                "Port Lavaca",
                "Port Mansfield",
                "Port Neches",
                "Porter Heights",
                "Portland",
                "Post",
                "Post Oak Bend City",
                "Poteet",
                "Poth",
                "Potosi",
                "Pottsboro",
                "Powell",
                "Poynor",
                "Prado Verde",
                "Prairie View",
                "Premont",
                "Presidio",
                "Primera",
                "Princeton",
                "Progreso",
                "Progreso Lakes",
                "Prosper",
                "Putnam",
                "Pyote",
                "Quail",
                "Quanah",
                "Queen City",
                "Quemado",
                "Quinlan",
                "Quintana",
                "Quitaque",
                "Quitman",
                "Radar Base",
                "Ralls",
                "Ranchette Estates",
                "Ranchitos Las Lomas",
                "Rancho Alegre",
                "Rancho Banquete",
                "Rancho Chico",
                "Rancho Viejo",
                "Ranchos Penitas West",
                "Ranger",
                "Rangerville",
                "Rankin",
                "Ransom Canyon",
                "Ratamosa",
                "Ravenna",
                "Raymondville",
                "Realitos",
                "Red Lick",
                "Red Oak",
                "Redford",
                "Redwater",
                "Redwood",
                "Reese Center",
                "Refugio",
                "Reid Hope King",
                "Reklaw",
                "Relampago",
                "Rendon",
                "Reno",
                "Retreat",
                "Rhome",
                "Rice",
                "Richardson",
                "Richland",
                "Richland Hills",
                "Richland Springs",
                "Richmond",
                "Richwood",
                "Riesel",
                "Rio Bravo",
                "Rio Grande City",
                "Rio Hondo",
                "Rio Vista",
                "Rising Star",
                "River Oaks",
                "Riverside",
                "Roanoke",
                "Roaring Springs",
                "Robert Lee",
                "Robinson",
                "Robstown",
                "Roby",
                "Rochester",
                "Rockdale",
                "Rockport",
                "Rocksprings",
                "Rockwall",
                "Rocky Mound",
                "Rogers",
                "Rollingwood",
                "Roma",
                "Roma Creek",
                "Roman Forest",
                "Ropesville",
                "Roscoe",
                "Rose City",
                "Rose Hill Acres",
                "Rosebud",
                "Rosenberg",
                "Rosita North",
                "Rosita South",
                "Ross",
                "Rosser",
                "Rotan",
                "Round Mountain",
                "Round Rock",
                "Round Top",
                "Rowlett",
                "Roxton",
                "Royse City",
                "Rule",
                "Runaway Bay",
                "Runge",
                "Rusk",
                "Sabinal",
                "Sachse",
                "Sadler",
                "Saginaw",
                "Salado",
                "Salineno",
                "Samnorwood",
                "San Angelo",
                "San Antonio",
                "San Augustine",
                "San Benito",
                "San Carlos",
                "San Diego",
                "San Elizario",
                "San Felipe",
                "San Ignacio",
                "San Isidro",
                "San Juan",
                "San Leanna",
                "San Leon",
                "San Manuel-linn",
                "San Marcos",
                "San Patricio",
                "San Pedro",
                "San Perlita",
                "San Saba",
                "Sanctuary",
                "Sanderson",
                "Sandia",
                "Sandy Hollow-escondidas",
                "Sanford",
                "Sanger",
                "Sansom Park",
                "Santa Anna",
                "Santa Clara",
                "Santa Cruz",
                "Santa Fe",
                "Santa Maria",
                "Santa Monica",
                "Santa Rosa",
                "Savoy",
                "Scenic Oaks",
                "Schertz",
                "Schulenburg",
                "Scissors",
                "Scotland",
                "Scottsville",
                "Seabrook",
                "Seadrift",
                "Seagoville",
                "Seagraves",
                "Sealy",
                "Sebastian",
                "Seguin",
                "Selma",
                "Seminole",
                "Serenada",
                "Seth Ward",
                "Seven Oaks",
                "Seven Points",
                "Seymour",
                "Shady Hollow",
                "Shady Shores",
                "Shallowater",
                "Shamrock",
                "Shavano Park",
                "Sheldon",
                "Shenandoah",
                "Shepherd",
                "Sherman",
                "Shiner",
                "Shoreacres",
                "Sienna Plantation",
                "Sierra Blanca",
                "Siesta Shores",
                "Silsbee",
                "Silverton",
                "Simonton",
                "Sinton",
                "Skellytown",
                "Skidmore",
                "Slaton",
                "Smiley",
                "Smithville",
                "Smyer",
                "Snook",
                "Snyder",
                "Socorro",
                "Solis",
                "Somerset",
                "Somerville",
                "Sonora",
                "Sour Lake",
                "South Alamo",
                "South Fork Estates",
                "South Houston",
                "South Mountain",
                "South Padre Island",
                "South Point",
                "South Toledo Bend",
                "Southlake",
                "Southmayd",
                "Southside Place",
                "Spade",
                "Sparks",
                "Spearman",
                "Splendora",
                "Spofford",
                "Spring",
                "Spring Garden-terra Verde",
                "Spring Valley",
                "Springlake",
                "Springtown",
                "Spur",
                "St. Hedwig",
                "St. Jo",
                "St. Paul",
                "Stafford",
                "Stagecoach",
                "Stamford",
                "Stanton",
                "Star Harbor",
                "Stephenville",
                "Sterling City",
                "Stinnett",
                "Stockdale",
                "Stonewall",
                "Stowell",
                "Stratford",
                "Strawn",
                "Streetman",
                "Study Butte-terlingua",
                "Sudan",
                "Sugar Land",
                "Sullivan City",
                "Sulphur Springs",
                "Sun Valley",
                "Sundown",
                "Sunnyvale",
                "Sunray",
                "Sunrise Beach Village",
                "Sunset",
                "Sunset Valley",
                "Surfside Beach",
                "Sweeny",
                "Sweetwater",
                "Taft",
                "Taft Southwest",
                "Tahoka",
                "Talco",
                "Talty",
                "Tatum",
                "Taylor",
                "Taylor Lake Village",
                "Teague",
                "Tehuacana",
                "Temple",
                "Tenaha",
                "Terrell",
                "Terrell Hills",
                "Texarkana",
                "Texas City",
                "Texhoma",
                "Texline",
                "The Colony",
                "The Hills",
                "The Woodlands",
                "Thompsons",
                "Thorndale",
                "Thornton",
                "Thorntonville",
                "Thrall",
                "Three Rivers",
                "Throckmorton",
                "Tierra Bonita",
                "Tierra Grande",
                "Tiki Island",
                "Timbercreek Canyon",
                "Timberwood Park",
                "Timpson",
                "Tioga",
                "Tira",
                "Toco",
                "Todd Mission",
                "Tolar",
                "Tom Bean",
                "Tomball",
                "Tool",
                "Tornillo",
                "Toyah",
                "Tradewinds",
                "Trent",
                "Trenton",
                "Trinidad",
                "Trinity",
                "Trophy Club",
                "Troup",
                "Troy",
                "Tuleta",
                "Tulia",
                "Tulsita",
                "Turkey",
                "Tuscola",
                "Tye",
                "Tyler",
                "Tynan",
                "Uhland",
                "Uncertain",
                "Union Grove",
                "Universal City",
                "University Park",
                "Utopia",
                "Uvalde",
                "Uvalde Estates",
                "Val Verde Park",
                "Valentine",
                "Valley Mills",
                "Valley View",
                "Van",
                "Van Alstyne",
                "Van Horn",
                "Van Vleck",
                "Vanderbilt",
                "Vega",
                "Venus",
                "Vernon",
                "Victoria",
                "Vidor",
                "Villa Del Sol",
                "Villa Pancho",
                "Villa Verde",
                "Vinton",
                "Waco",
                "Waelder",
                "Wake Village",
                "Waller",
                "Wallis",
                "Walnut Springs",
                "Warren City",
                "Waskom",
                "Watauga",
                "Waxahachie",
                "Weatherford",
                "Webster",
                "Weimar",
                "Weinert",
                "Weir",
                "Wellington",
                "Wellman",
                "Wells",
                "Wells Branch",
                "Weslaco",
                "West",
                "West Columbia",
                "West Lake Hills",
                "West Livingston",
                "West Odessa",
                "West Orange",
                "West Pearsall",
                "West Sharyland",
                "West Tawakoni",
                "West University Place",
                "Westbrook",
                "Westdale",
                "Westlake",
                "Westminster",
                "Weston",
                "Westover Hills",
                "Westway",
                "Westworth Village",
                "Wharton",
                "Wheeler",
                "White Deer",
                "White Oak",
                "White Settlement",
                "Whiteface",
                "Whitehouse",
                "Whitesboro",
                "Whitewright",
                "Whitney",
                "Wichita Falls",
                "Wickett",
                "Wild Peach Village",
                "Willamar",
                "Willis",
                "Willow Park",
                "Wills Point",
                "Wilmer",
                "Wilson",
                "Wimberley",
                "Windcrest",
                "Windemere",
                "Windom",
                "Windthorst",
                "Winfield",
                "Wink",
                "Winnie",
                "Winnsboro",
                "Winona",
                "Winters",
                "Wixon Valley",
                "Wolfe City",
                "Wolfforth",
                "Woodbranch",
                "Woodcreek",
                "Woodloch",
                "Woodsboro",
                "Woodson",
                "Woodville",
                "Woodway",
                "Wortham",
                "Wyldwood",
                "Wylie",
                "Yantis",
                "Yoakum",
                "Yorktown",
                "Yznaga",
                "Zapata",
                "Zapata Ranch",
                "Zavalla",
                "Zuehl"
            ],
            "Trimble": [
                "Abbott",
                "Abernathy",
                "Abilene",
                "Abram-perezville",
                "Ackerly",
                "Addison",
                "Adrian",
                "Agua Dulce",
                "Airport Road Addition",
                "Alamo",
                "Alamo Heights",
                "Alba",
                "Albany",
                "Aldine",
                "Aledo",
                "Alfred-south La Paloma",
                "Alice",
                "Alice Acres",
                "Allen",
                "Alma",
                "Alpine",
                "Alto",
                "Alto Bonito",
                "Alton",
                "Alton North",
                "Alvarado",
                "Alvin",
                "Alvord",
                "Amarillo",
                "Ames",
                "Amherst",
                "Anahuac",
                "Anderson",
                "Anderson Mill",
                "Andrews",
                "Angleton",
                "Angus",
                "Anna",
                "Annetta",
                "Annetta North",
                "Annetta South",
                "Annona",
                "Anson",
                "Anthony",
                "Anton",
                "Appleby",
                "Aquilla",
                "Aransas Pass",
                "Archer City",
                "Arcola",
                "Argyle",
                "Arlington",
                "Arp",
                "Arroyo Alto",
                "Arroyo Colorado Estates",
                "Arroyo Gardens-la Tina Ranch",
                "Asherton",
                "Aspermont",
                "Atascocita",
                "Athens",
                "Atlanta",
                "Aubrey",
                "Aurora",
                "Austin",
                "Austwell",
                "Avery",
                "Avinger",
                "Azle",
                "Bacliff",
                "Bailey",
                "Bailey S Prairie",
                "Baird",
                "Balch Springs",
                "Balcones Heights",
                "Ballinger",
                "Balmorhea",
                "Bandera",
                "Bangs",
                "Bardwell",
                "Barrett",
                "Barry",
                "Barstow",
                "Bartlett",
                "Barton Creek",
                "Bartonville",
                "Bastrop",
                "Batesville",
                "Bausell And Ellis",
                "Bay City",
                "Bayou Vista",
                "Bayside",
                "Baytown",
                "Bayview",
                "Beach City",
                "Bear Creek",
                "Beasley",
                "Beaumont",
                "Beckville",
                "Bedford",
                "Bee Cave",
                "Beeville",
                "Bellaire",
                "Bellevue",
                "Bellmead",
                "Bells",
                "Bellville",
                "Belton",
                "Benavides",
                "Benbrook",
                "Benjamin",
                "Berryville",
                "Bertram",
                "Beverly Hills",
                "Bevil Oaks",
                "Big Lake",
                "Big Sandy",
                "Big Spring",
                "Big Wells",
                "Bigfoot",
                "Bishop",
                "Bishop Hills",
                "Bixby",
                "Blackwell",
                "Blanco",
                "Blanket",
                "Blessing",
                "Bloomburg",
                "Blooming Grove",
                "Bloomington",
                "Blossom",
                "Blue Berry Hill",
                "Blue Mound",
                "Blue Ridge",
                "Bluetown-iglesia Antigua",
                "Blum",
                "Boerne",
                "Bogata",
                "Boling-iago",
                "Bolivar Peninsula",
                "Bonham",
                "Bonney",
                "Booker",
                "Borger",
                "Botines",
                "Bovina",
                "Bowie",
                "Box Canyon-amistad",
                "Boyd",
                "Brackettville",
                "Brady",
                "Brazoria",
                "Breckenridge",
                "Bremond",
                "Brenham",
                "Briar",
                "Briarcliff",
                "Briaroaks",
                "Bridge City",
                "Bridgeport",
                "Broaddus",
                "Bronte",
                "Brookshire",
                "Brookside Village",
                "Browndell",
                "Brownfield",
                "Brownsboro",
                "Brownsville",
                "Brownwood",
                "Bruceville-eddy",
                "Brundage",
                "Bruni",
                "Brushy Creek",
                "Bryan",
                "Bryson",
                "Buchanan Dam",
                "Buckholts",
                "Buda",
                "Buffalo",
                "Buffalo Gap",
                "Buffalo Springs",
                "Bullard",
                "Bulverde",
                "Buna",
                "Bunker Hill Village",
                "Burkburnett",
                "Burke",
                "Burleson",
                "Burnet",
                "Burton",
                "Butterfield",
                "Byers",
                "Bynum",
                "Cactus",
                "Caddo Mills",
                "Caldwell",
                "Callisburg",
                "Calvert",
                "Cameron",
                "Cameron Park",
                "Camp Swift",
                "Camp Wood",
                "Campbell",
                "Canadian",
                "Caney City",
                "Canton",
                "Cantu Addition",
                "Canutillo",
                "Canyon",
                "Canyon Lake",
                "Carbon",
                "Carl S Corner",
                "Carmine",
                "Carrizo Hill",
                "Carrizo Springs",
                "Carrollton",
                "Carthage",
                "Castle Hills",
                "Castroville",
                "Catarina",
                "Cedar Hill",
                "Cedar Park",
                "Celeste",
                "Celina",
                "Center",
                "Centerville",
                "Central Gardens",
                "Cesar Chavez",
                "Chandler",
                "Channelview",
                "Channing",
                "Charlotte",
                "Chester",
                "Chico",
                "Childress",
                "Chillicothe",
                "China",
                "China Grove",
                "Chireno",
                "Christine",
                "Christoval",
                "Chula Vista-orason",
                "Chula Vista-river Spur",
                "Cibolo",
                "Cienegas Terrace",
                "Cinco Ranch",
                "Circle D-kc Estates",
                "Cisco",
                "Citrus City",
                "Clarendon",
                "Clarksville",
                "Clarksville City",
                "Claude",
                "Clear Lake Shores",
                "Cleburne",
                "Cleveland",
                "Clifton",
                "Clint",
                "Cloverleaf",
                "Clute",
                "Clyde",
                "Coahoma",
                "Cockrell Hill",
                "Coffee City",
                "Coldspring",
                "Coleman",
                "College Station",
                "Colleyville",
                "Collinsville",
                "Colmesneil",
                "Colorado City",
                "Columbus",
                "Comanche",
                "Combes",
                "Combine",
                "Comfort",
                "Commerce",
                "Como",
                "Concepcion",
                "Conroe",
                "Converse",
                "Cool",
                "Coolidge",
                "Cooper",
                "Coppell",
                "Copper Canyon",
                "Copperas Cove",
                "Corinth",
                "Corpus Christi",
                "Corral City",
                "Corrigan",
                "Corsicana",
                "Cottonwood",
                "Cottonwood Shores",
                "Cotulla",
                "Cove",
                "Covington",
                "Coyanosa",
                "Coyote Acres",
                "Crandall",
                "Crane",
                "Cranfills Gap",
                "Crawford",
                "Creedmoor",
                "Crockett",
                "Crosby",
                "Crosbyton",
                "Cross Mountain",
                "Cross Plains",
                "Cross Roads",
                "Cross Timber",
                "Crowell",
                "Crowley",
                "Crystal City",
                "Cuero",
                "Cuevitas",
                "Cumby",
                "Cumings",
                "Cuney",
                "Cushing",
                "Cut And Shoot",
                "Daingerfield",
                "Daisetta",
                "Dalhart",
                "Dallas",
                "Dalworthington Gardens",
                "Damon",
                "Danbury",
                "Darrouzett",
                "Dawson",
                "Dayton",
                "Dayton Lakes",
                "De Kalb",
                "De Leon",
                "Dean",
                "Decatur",
                "Deer Park",
                "Del Mar Heights",
                "Del Rio",
                "Del Sol-loma Linda",
                "Dell City",
                "Denison",
                "Denton",
                "Denver City",
                "Deport",
                "Desoto",
                "Detroit",
                "Devers",
                "Devine",
                "Deweyville",
                "Diboll",
                "Dickens",
                "Dickinson",
                "Dilley",
                "Dimmitt",
                "Dodd City",
                "Dodson",
                "Doffing",
                "Domino",
                "Donna",
                "Doolittle",
                "Dorchester",
                "Double Oak",
                "Douglassville",
                "Doyle",
                "Dripping Springs",
                "Driscoll",
                "Dublin",
                "Dumas",
                "Duncanville",
                "Eagle Lake",
                "Eagle Mountain",
                "Eagle Pass",
                "Early",
                "Earth",
                "East Bernard",
                "East Mountain",
                "East Tawakoni",
                "Eastland",
                "Easton",
                "Ector",
                "Edcouch",
                "Eden",
                "Edgecliff Village",
                "Edgewater-paisano",
                "Edgewood",
                "Edinburg",
                "Edmonson",
                "Edna",
                "Edom",
                "Edroy",
                "Eidson Road",
                "El Camino Angosto",
                "El Campo",
                "El Cenizo",
                "El Indio",
                "El Lago",
                "El Paso",
                "El Refugio",
                "Elbert",
                "Eldorado",
                "Electra",
                "Elgin",
                "Elkhart",
                "Elm Creek",
                "Elmendorf",
                "Elsa",
                "Emhouse",
                "Emory",
                "Encantada-ranchito El Calaboz",
                "Enchanted Oaks",
                "Encinal",
                "Encino",
                "Ennis",
                "Escobares",
                "Estelline",
                "Euless",
                "Eureka",
                "Eustace",
                "Evadale",
                "Evant",
                "Everman",
                "Fabens",
                "Fair Oaks Ranch",
                "Fairchilds",
                "Fairfield",
                "Fairview",
                "Falcon Heights",
                "Falcon Lake Estates",
                "Falcon Mesa",
                "Falcon Village",
                "Falfurrias",
                "Falls City",
                "Falman-county Acres",
                "Farmers Branch",
                "Farmersville",
                "Farwell",
                "Fate",
                "Fayetteville",
                "Faysville",
                "Ferris",
                "Fifth Street",
                "Flatonia",
                "Florence",
                "Floresville",
                "Flowella",
                "Flower Mound",
                "Floydada",
                "Follett",
                "Forest Hill",
                "Forney",
                "Forsan",
                "Fort Bliss",
                "Fort Davis",
                "Fort Hancock",
                "Fort Hood",
                "Fort Stockton",
                "Fort Worth",
                "Four Corners",
                "Fowlerton",
                "Franklin",
                "Frankston",
                "Fredericksburg",
                "Freeport",
                "Freer",
                "Fresno",
                "Friendswood",
                "Friona",
                "Frisco",
                "Fritch",
                "Fronton",
                "Frost",
                "Fruitvale",
                "Fulshear",
                "Fulton",
                "Gainesville",
                "Galena Park",
                "Gallatin",
                "Galveston",
                "Ganado",
                "Garceno",
                "Garden Ridge",
                "Gardendale",
                "Garfield",
                "Garland",
                "Garrett",
                "Garrison",
                "Gary City",
                "Gatesville",
                "George West",
                "Georgetown",
                "Geronimo",
                "Gholson",
                "Giddings",
                "Gilmer",
                "Girard",
                "Gladewater",
                "Glen Rose",
                "Glenn Heights",
                "Godley",
                "Goldsmith",
                "Goldthwaite",
                "Goliad",
                "Golinda",
                "Gonzales",
                "Goodlow",
                "Goodrich",
                "Gordon",
                "Goree",
                "Gorman",
                "Graford",
                "Graham",
                "Granbury",
                "Grand Acres",
                "Grand Prairie",
                "Grand Saline",
                "Grandfalls",
                "Grandview",
                "Granger",
                "Granite Shoals",
                "Granjeno",
                "Grape Creek",
                "Grapeland",
                "Grapevine",
                "Grays Prairie",
                "Greatwood",
                "Green Valley Farms",
                "Greenville",
                "Gregory",
                "Grey Forest",
                "Groesbeck",
                "Groom",
                "Groves",
                "Groveton",
                "Gruver",
                "Guerra",
                "Gun Barrel City",
                "Gunter",
                "Gustine",
                "Hackberry",
                "Hale Center",
                "Hallettsville",
                "Hallsburg",
                "Hallsville",
                "Haltom City",
                "Hamilton",
                "Hamlin",
                "Happy",
                "Hardin",
                "Harker Heights",
                "Harlingen",
                "Harper",
                "Hart",
                "Hartley",
                "Haskell",
                "Haslet",
                "Havana",
                "Hawk Cove",
                "Hawkins",
                "Hawley",
                "Hays",
                "Hearne",
                "Heath",
                "Hebbronville",
                "Hebron",
                "Hedley",
                "Hedwig Village",
                "Heidelberg",
                "Helotes",
                "Hemphill",
                "Hempstead",
                "Henderson",
                "Henrietta",
                "Hereford",
                "Hermleigh",
                "Hewitt",
                "Hickory Creek",
                "Hico",
                "Hidalgo",
                "Higgins",
                "Highland Haven",
                "Highland Park",
                "Highland Village",
                "Highlands",
                "Hill Country Village",
                "Hillcrest",
                "Hillsboro",
                "Hilltop",
                "Hilshire Village",
                "Hitchcock",
                "Holiday Lakes",
                "Holland",
                "Holliday",
                "Hollywood Park",
                "Homestead Meadows North",
                "Homestead Meadows South",
                "Hondo",
                "Honey Grove",
                "Hooks",
                "Horizon City",
                "Horseshoe Bay",
                "Houston",
                "Howardwick",
                "Howe",
                "Hubbard",
                "Hudson",
                "Hudson Bend",
                "Hudson Oaks",
                "Hughes Springs",
                "Humble",
                "Hungerford",
                "Hunters Creek Village",
                "Huntington",
                "Huntsville",
                "Hurst",
                "Hutchins",
                "Hutto",
                "Huxley",
                "Idalou",
                "Impact",
                "Imperial",
                "Indian Hills",
                "Indian Lake",
                "Industry",
                "Inez",
                "Ingleside",
                "Ingleside On The Bay",
                "Ingram",
                "Iowa Colony",
                "Iowa Park",
                "Iraan",
                "Iredell",
                "Irving",
                "Italy",
                "Itasca",
                "Jacinto City",
                "Jacksboro",
                "Jacksonville",
                "Jamaica Beach",
                "Jasper",
                "Jayton",
                "Jefferson",
                "Jersey Village",
                "Jewett",
                "Joaquin",
                "Johnson City",
                "Jolly",
                "Jollyville",
                "Jones Creek",
                "Jonestown",
                "Josephine",
                "Joshua",
                "Jourdanton",
                "Junction",
                "Justin",
                "K-bar Ranch",
                "Karnes City",
                "Katy",
                "Kaufman",
                "Keene",
                "Keller",
                "Kemah",
                "Kemp",
                "Kempner",
                "Kendleton",
                "Kenedy",
                "Kenefick",
                "Kennard",
                "Kennedale",
                "Kerens",
                "Kermit",
                "Kerrville",
                "Kilgore",
                "Killeen",
                "Kingsbury",
                "Kingsland",
                "Kingsville",
                "Kirby",
                "Kirbyville",
                "Kirvin",
                "Knippa",
                "Knollwood",
                "Knox City",
                "Kosse",
                "Kountze",
                "Kress",
                "Krugerville",
                "Krum",
                "Kyle",
                "La Blanca",
                "La Casita-garciasville",
                "La Feria",
                "La Feria North",
                "La Grange",
                "La Grulla",
                "La Homa",
                "La Joya",
                "La Marque",
                "La Paloma",
                "La Paloma-lost Creek",
                "La Porte",
                "La Presa",
                "La Pryor",
                "La Puerta",
                "La Rosita",
                "La Vernia",
                "La Victoria",
                "La Villa",
                "La Ward",
                "Lackland Afb",
                "Lacoste",
                "Lacy-lakeview",
                "Ladonia",
                "Lago",
                "Lago Vista",
                "Laguna Heights",
                "Laguna Seca",
                "Laguna Vista",
                "Lake Bridgeport",
                "Lake Brownwood",
                "Lake City",
                "Lake Dallas",
                "Lake Jackson",
                "Lake Kiowa",
                "Lake Tanglewood",
                "Lake View",
                "Lake Worth",
                "Lakehills",
                "Lakeport",
                "Lakeshore Gardens-hidden Acres",
                "Lakeside",
                "Lakeside City",
                "Lakeview",
                "Lakeway",
                "Lakewood Village",
                "Lamesa",
                "Lampasas",
                "Lancaster",
                "Laredo",
                "Laredo Ranchettes",
                "Larga Vista",
                "Las Colonias",
                "Las Lomas",
                "Las Lomitas",
                "Las Palmas-juarez",
                "Las Quintas Fronterizas",
                "Lasana",
                "Lasara",
                "Latexo",
                "Laughlin Afb",
                "Laureles",
                "Lavon",
                "Lawn",
                "League City",
                "Leakey",
                "Leander",
                "Leary",
                "Lefors",
                "Leon Valley",
                "Leona",
                "Leonard",
                "Leroy",
                "Levelland",
                "Lewisville",
                "Lexington",
                "Liberty",
                "Liberty City",
                "Liberty Hill",
                "Lincoln Park",
                "Lindale",
                "Linden",
                "Lindsay",
                "Lipan",
                "Lipscomb",
                "Little Elm",
                "Little River-academy",
                "Littlefield",
                "Live Oak",
                "Liverpool",
                "Livingston",
                "Llano",
                "Llano Grande",
                "Lockhart",
                "Lockney",
                "Log Cabin",
                "Lolita",
                "Loma Linda East",
                "Lometa",
                "Lone Oak",
                "Lone Star",
                "Longview",
                "Lopeno",
                "Lopezville",
                "Loraine",
                "Lorena",
                "Lorenzo",
                "Los Alvarez",
                "Los Angeles Subdivision",
                "Los Ebanos",
                "Los Fresnos",
                "Los Indios",
                "Los Villareales",
                "Los Ybanez",
                "Lost Creek",
                "Lott",
                "Louise",
                "Lovelady",
                "Lowry Crossing",
                "Lozano",
                "Lubbock",
                "Lucas",
                "Lueders",
                "Lufkin",
                "Luling",
                "Lumberton",
                "Lyford",
                "Lyford South",
                "Lytle",
                "Mabank",
                "Madisonville",
                "Magnolia",
                "Malakoff",
                "Malone",
                "Manor",
                "Mansfield",
                "Manvel",
                "Marathon",
                "Marble Falls",
                "Marfa",
                "Marietta",
                "Marion",
                "Markham",
                "Marlin",
                "Marquez",
                "Marshall",
                "Marshall Creek",
                "Mart",
                "Martindale",
                "Mason",
                "Matador",
                "Mathis",
                "Maud",
                "Mauriceville",
                "Maypearl",
                "Mcallen",
                "Mccamey",
                "Mcgregor",
                "Mckinney",
                "Mclean",
                "Mclendon-chisholm",
                "Mcqueeney",
                "Meadow",
                "Meadowlakes",
                "Meadows Place",
                "Medina",
                "Megargel",
                "Melissa",
                "Melvin",
                "Memphis",
                "Menard",
                "Mercedes",
                "Meridian",
                "Merkel",
                "Mertens",
                "Mertzon",
                "Mesquite",
                "Mexia",
                "Miami",
                "Midland",
                "Midlothian",
                "Midway",
                "Midway North",
                "Midway South",
                "Mila Doce",
                "Milam",
                "Milano",
                "Mildred",
                "Miles",
                "Milford",
                "Miller S Cove",
                "Millican",
                "Millsap",
                "Mineola",
                "Mineral Wells",
                "Mingus",
                "Mirando City",
                "Mission",
                "Mission Bend",
                "Missouri City",
                "Mobeetie",
                "Mobile City",
                "Monahans",
                "Mont Belvieu",
                "Monte Alto",
                "Montgomery",
                "Moody",
                "Moore",
                "Moore Station",
                "Morales-sanchez",
                "Moran",
                "Morgan",
                "Morgan Farm Area",
                "Morgan S Point Resort",
                "Morgans Point",
                "Morning Glory",
                "Morse",
                "Morton",
                "Moulton",
                "Mount Calm",
                "Mount Enterprise",
                "Mount Pleasant",
                "Mount Vernon",
                "Mountain City",
                "Muenster",
                "Muleshoe",
                "Mullin",
                "Munday",
                "Muniz",
                "Murchison",
                "Murphy",
                "Mustang",
                "Mustang Ridge",
                "Nacogdoches",
                "Naples",
                "Nash",
                "Nassau Bay",
                "Natalia",
                "Navarro",
                "Navasota",
                "Nazareth",
                "Nederland",
                "Needville",
                "Nesbitt",
                "Nevada",
                "New Berlin",
                "New Boston",
                "New Braunfels",
                "New Chapel Hill",
                "New Deal",
                "New Fairview",
                "New Falcon",
                "New Home",
                "New Hope",
                "New London",
                "New Summerfield",
                "New Territory",
                "New Waverly",
                "Newark",
                "Newcastle",
                "Newton",
                "Neylandville",
                "Niederwald",
                "Nixon",
                "Nocona",
                "Nolanville",
                "Nome",
                "Noonday",
                "Nordheim",
                "Normangee",
                "Normanna",
                "North Alamo",
                "North Cleveland",
                "North Escobares",
                "North Pearsall",
                "North Richland Hills",
                "North San Pedro",
                "Northcliff",
                "Northlake",
                "Novice",
                "Nurillo",
                "O",
                "O Brien",
                "O Donnell",
                "Oak Grove",
                "Oak Leaf",
                "Oak Point",
                "Oak Ridge",
                "Oak Ridge North",
                "Oak Trail Shores",
                "Oak Valley",
                "Oakhurst",
                "Oakwood",
                "Odem",
                "Odessa",
                "Oglesby",
                "Oilton",
                "Old River-winfree",
                "Olivarez",
                "Olmito",
                "Olmos Park",
                "Olney",
                "Olton",
                "Omaha",
                "Onalaska",
                "Onion Creek",
                "Opdyke West",
                "Orange",
                "Orange Grove",
                "Orchard",
                "Ore City",
                "Overton",
                "Ovilla",
                "Owl Ranch-amargosa",
                "Oyster Creek",
                "Paducah",
                "Paint Rock",
                "Palacios",
                "Palestine",
                "Palisades",
                "Palm Valley",
                "Palmer",
                "Palmhurst",
                "Palmview",
                "Palmview South",
                "Pampa",
                "Panhandle",
                "Panorama Village",
                "Pantego",
                "Paradise",
                "Paris",
                "Parker",
                "Pasadena",
                "Pattison",
                "Patton Village",
                "Pawnee",
                "Payne Springs",
                "Pearland",
                "Pearsall",
                "Pecan Acres",
                "Pecan Gap",
                "Pecan Grove",
                "Pecan Hill",
                "Pecan Plantation",
                "Pecos",
                "Pelican Bay",
                "Penelope",
                "Penitas",
                "Pernitas Point",
                "Perryton",
                "Petersburg",
                "Petrolia",
                "Petronila",
                "Pettus",
                "Pflugerville",
                "Pharr",
                "Pilot Point",
                "Pine Forest",
                "Pine Island",
                "Pinehurst",
                "Pineland",
                "Pinewood Estates",
                "Piney Point Village",
                "Pittsburg",
                "Plains",
                "Plainview",
                "Plano",
                "Pleak",
                "Pleasant Valley",
                "Pleasanton",
                "Plum Grove",
                "Point",
                "Point Blank",
                "Point Comfort",
                "Ponder",
                "Port Aransas",
                "Port Arthur",
                "Port Isabel",
                "Port Lavaca",
                "Port Mansfield",
                "Port Neches",
                "Porter Heights",
                "Portland",
                "Post",
                "Post Oak Bend City",
                "Poteet",
                "Poth",
                "Potosi",
                "Pottsboro",
                "Powell",
                "Poynor",
                "Prado Verde",
                "Prairie View",
                "Premont",
                "Presidio",
                "Primera",
                "Princeton",
                "Progreso",
                "Progreso Lakes",
                "Prosper",
                "Putnam",
                "Pyote",
                "Quail",
                "Quanah",
                "Queen City",
                "Quemado",
                "Quinlan",
                "Quintana",
                "Quitaque",
                "Quitman",
                "Radar Base",
                "Ralls",
                "Ranchette Estates",
                "Ranchitos Las Lomas",
                "Rancho Alegre",
                "Rancho Banquete",
                "Rancho Chico",
                "Rancho Viejo",
                "Ranchos Penitas West",
                "Ranger",
                "Rangerville",
                "Rankin",
                "Ransom Canyon",
                "Ratamosa",
                "Ravenna",
                "Raymondville",
                "Realitos",
                "Red Lick",
                "Red Oak",
                "Redford",
                "Redwater",
                "Redwood",
                "Reese Center",
                "Refugio",
                "Reid Hope King",
                "Reklaw",
                "Relampago",
                "Rendon",
                "Reno",
                "Retreat",
                "Rhome",
                "Rice",
                "Richardson",
                "Richland",
                "Richland Hills",
                "Richland Springs",
                "Richmond",
                "Richwood",
                "Riesel",
                "Rio Bravo",
                "Rio Grande City",
                "Rio Hondo",
                "Rio Vista",
                "Rising Star",
                "River Oaks",
                "Riverside",
                "Roanoke",
                "Roaring Springs",
                "Robert Lee",
                "Robinson",
                "Robstown",
                "Roby",
                "Rochester",
                "Rockdale",
                "Rockport",
                "Rocksprings",
                "Rockwall",
                "Rocky Mound",
                "Rogers",
                "Rollingwood",
                "Roma",
                "Roma Creek",
                "Roman Forest",
                "Ropesville",
                "Roscoe",
                "Rose City",
                "Rose Hill Acres",
                "Rosebud",
                "Rosenberg",
                "Rosita North",
                "Rosita South",
                "Ross",
                "Rosser",
                "Rotan",
                "Round Mountain",
                "Round Rock",
                "Round Top",
                "Rowlett",
                "Roxton",
                "Royse City",
                "Rule",
                "Runaway Bay",
                "Runge",
                "Rusk",
                "Sabinal",
                "Sachse",
                "Sadler",
                "Saginaw",
                "Salado",
                "Salineno",
                "Samnorwood",
                "San Angelo",
                "San Antonio",
                "San Augustine",
                "San Benito",
                "San Carlos",
                "San Diego",
                "San Elizario",
                "San Felipe",
                "San Ignacio",
                "San Isidro",
                "San Juan",
                "San Leanna",
                "San Leon",
                "San Manuel-linn",
                "San Marcos",
                "San Patricio",
                "San Pedro",
                "San Perlita",
                "San Saba",
                "Sanctuary",
                "Sanderson",
                "Sandia",
                "Sandy Hollow-escondidas",
                "Sanford",
                "Sanger",
                "Sansom Park",
                "Santa Anna",
                "Santa Clara",
                "Santa Cruz",
                "Santa Fe",
                "Santa Maria",
                "Santa Monica",
                "Santa Rosa",
                "Savoy",
                "Scenic Oaks",
                "Schertz",
                "Schulenburg",
                "Scissors",
                "Scotland",
                "Scottsville",
                "Seabrook",
                "Seadrift",
                "Seagoville",
                "Seagraves",
                "Sealy",
                "Sebastian",
                "Seguin",
                "Selma",
                "Seminole",
                "Serenada",
                "Seth Ward",
                "Seven Oaks",
                "Seven Points",
                "Seymour",
                "Shady Hollow",
                "Shady Shores",
                "Shallowater",
                "Shamrock",
                "Shavano Park",
                "Sheldon",
                "Shenandoah",
                "Shepherd",
                "Sherman",
                "Shiner",
                "Shoreacres",
                "Sienna Plantation",
                "Sierra Blanca",
                "Siesta Shores",
                "Silsbee",
                "Silverton",
                "Simonton",
                "Sinton",
                "Skellytown",
                "Skidmore",
                "Slaton",
                "Smiley",
                "Smithville",
                "Smyer",
                "Snook",
                "Snyder",
                "Socorro",
                "Solis",
                "Somerset",
                "Somerville",
                "Sonora",
                "Sour Lake",
                "South Alamo",
                "South Fork Estates",
                "South Houston",
                "South Mountain",
                "South Padre Island",
                "South Point",
                "South Toledo Bend",
                "Southlake",
                "Southmayd",
                "Southside Place",
                "Spade",
                "Sparks",
                "Spearman",
                "Splendora",
                "Spofford",
                "Spring",
                "Spring Garden-terra Verde",
                "Spring Valley",
                "Springlake",
                "Springtown",
                "Spur",
                "St. Hedwig",
                "St. Jo",
                "St. Paul",
                "Stafford",
                "Stagecoach",
                "Stamford",
                "Stanton",
                "Star Harbor",
                "Stephenville",
                "Sterling City",
                "Stinnett",
                "Stockdale",
                "Stonewall",
                "Stowell",
                "Stratford",
                "Strawn",
                "Streetman",
                "Study Butte-terlingua",
                "Sudan",
                "Sugar Land",
                "Sullivan City",
                "Sulphur Springs",
                "Sun Valley",
                "Sundown",
                "Sunnyvale",
                "Sunray",
                "Sunrise Beach Village",
                "Sunset",
                "Sunset Valley",
                "Surfside Beach",
                "Sweeny",
                "Sweetwater",
                "Taft",
                "Taft Southwest",
                "Tahoka",
                "Talco",
                "Talty",
                "Tatum",
                "Taylor",
                "Taylor Lake Village",
                "Teague",
                "Tehuacana",
                "Temple",
                "Tenaha",
                "Terrell",
                "Terrell Hills",
                "Texarkana",
                "Texas City",
                "Texhoma",
                "Texline",
                "The Colony",
                "The Hills",
                "The Woodlands",
                "Thompsons",
                "Thorndale",
                "Thornton",
                "Thorntonville",
                "Thrall",
                "Three Rivers",
                "Throckmorton",
                "Tierra Bonita",
                "Tierra Grande",
                "Tiki Island",
                "Timbercreek Canyon",
                "Timberwood Park",
                "Timpson",
                "Tioga",
                "Tira",
                "Toco",
                "Todd Mission",
                "Tolar",
                "Tom Bean",
                "Tomball",
                "Tool",
                "Tornillo",
                "Toyah",
                "Tradewinds",
                "Trent",
                "Trenton",
                "Trinidad",
                "Trinity",
                "Trophy Club",
                "Troup",
                "Troy",
                "Tuleta",
                "Tulia",
                "Tulsita",
                "Turkey",
                "Tuscola",
                "Tye",
                "Tyler",
                "Tynan",
                "Uhland",
                "Uncertain",
                "Union Grove",
                "Universal City",
                "University Park",
                "Utopia",
                "Uvalde",
                "Uvalde Estates",
                "Val Verde Park",
                "Valentine",
                "Valley Mills",
                "Valley View",
                "Van",
                "Van Alstyne",
                "Van Horn",
                "Van Vleck",
                "Vanderbilt",
                "Vega",
                "Venus",
                "Vernon",
                "Victoria",
                "Vidor",
                "Villa Del Sol",
                "Villa Pancho",
                "Villa Verde",
                "Vinton",
                "Waco",
                "Waelder",
                "Wake Village",
                "Waller",
                "Wallis",
                "Walnut Springs",
                "Warren City",
                "Waskom",
                "Watauga",
                "Waxahachie",
                "Weatherford",
                "Webster",
                "Weimar",
                "Weinert",
                "Weir",
                "Wellington",
                "Wellman",
                "Wells",
                "Wells Branch",
                "Weslaco",
                "West",
                "West Columbia",
                "West Lake Hills",
                "West Livingston",
                "West Odessa",
                "West Orange",
                "West Pearsall",
                "West Sharyland",
                "West Tawakoni",
                "West University Place",
                "Westbrook",
                "Westdale",
                "Westlake",
                "Westminster",
                "Weston",
                "Westover Hills",
                "Westway",
                "Westworth Village",
                "Wharton",
                "Wheeler",
                "White Deer",
                "White Oak",
                "White Settlement",
                "Whiteface",
                "Whitehouse",
                "Whitesboro",
                "Whitewright",
                "Whitney",
                "Wichita Falls",
                "Wickett",
                "Wild Peach Village",
                "Willamar",
                "Willis",
                "Willow Park",
                "Wills Point",
                "Wilmer",
                "Wilson",
                "Wimberley",
                "Windcrest",
                "Windemere",
                "Windom",
                "Windthorst",
                "Winfield",
                "Wink",
                "Winnie",
                "Winnsboro",
                "Winona",
                "Winters",
                "Wixon Valley",
                "Wolfe City",
                "Wolfforth",
                "Woodbranch",
                "Woodcreek",
                "Woodloch",
                "Woodsboro",
                "Woodson",
                "Woodville",
                "Woodway",
                "Wortham",
                "Wyldwood",
                "Wylie",
                "Yantis",
                "Yoakum",
                "Yorktown",
                "Yznaga",
                "Zapata",
                "Zapata Ranch",
                "Zavalla",
                "Zuehl"
            ],
            "Utah": [
                "Alpine",
                "Alta",
                "Altamont",
                "Alton",
                "Amalga",
                "American Fork",
                "Aneth",
                "Annabella",
                "Antimony",
                "Aurora",
                "Avon",
                "Ballard",
                "Bear River City",
                "Beaver",
                "Benjamin",
                "Benson",
                "Bicknell",
                "Big Water",
                "Blanding",
                "Bluff",
                "Bluffdale",
                "Boulder",
                "Bountiful",
                "Brian Head",
                "Brigham City",
                "Cache",
                "Cannonville",
                "Canyon Rim",
                "Castle Dale",
                "Castle Valley",
                "Cedar City",
                "Cedar Fort",
                "Cedar Hills",
                "Centerfield",
                "Centerville",
                "Charleston",
                "Circleville",
                "Clarkston",
                "Clawson",
                "Clearfield",
                "Cleveland",
                "Clinton",
                "Coalville",
                "Corinne",
                "Cornish",
                "Cottonwood Heights",
                "Cottonwood West",
                "Cove",
                "Daniel",
                "Delta",
                "Deweyville",
                "Draper",
                "Duchesne",
                "Dugway",
                "Eagle Mountain",
                "East Carbon",
                "East Millcreek",
                "Elberta",
                "Elk Ridge",
                "Elmo",
                "Elsinore",
                "Elwood",
                "Emery",
                "Enoch",
                "Enterprise",
                "Ephraim",
                "Erda",
                "Escalante",
                "Eureka",
                "Fairview",
                "Farmington",
                "Farr West",
                "Fayette",
                "Ferron",
                "Fielding",
                "Fillmore",
                "Fort Duchesne",
                "Fountain Green",
                "Francis",
                "Fruit Heights",
                "Garden",
                "Garden City",
                "Garland",
                "Genola",
                "Glendale",
                "Glenwood",
                "Goshen",
                "Granite",
                "Grantsville",
                "Green River",
                "Gunnison",
                "Halchita",
                "Halls Crossing",
                "Harrisville",
                "Hatch",
                "Heber",
                "Helper",
                "Henefer",
                "Henrieville",
                "Herriman",
                "Highland",
                "Hildale",
                "Hinckley",
                "Holden",
                "Holladay",
                "Honeyville",
                "Hooper",
                "Howell",
                "Huntington",
                "Huntsville",
                "Hurricane",
                "Hyde Park",
                "Hyrum",
                "Ivins",
                "Joseph",
                "Junction",
                "Kamas",
                "Kanab",
                "Kanarraville",
                "Kanosh",
                "Kaysville",
                "Kearns",
                "Kingston",
                "Koosharem",
                "La Sal",
                "La Verkin",
                "Lake Shore",
                "Laketown",
                "Layton",
                "Leamington",
                "Leeds",
                "Lehi",
                "Levan",
                "Lewiston",
                "Lindon",
                "Little Cottonwood Creek Valley",
                "Loa",
                "Logan",
                "Lyman",
                "Lynndyl",
                "Maeser",
                "Magna",
                "Manila",
                "Manti",
                "Mantua",
                "Mapleton",
                "Marriott-slaterville",
                "Marysvale",
                "Mayfield",
                "Meadow",
                "Mendon",
                "Mexican Hat",
                "Midvale",
                "Midway",
                "Milford",
                "Millcreek",
                "Millville",
                "Minersville",
                "Moab",
                "Mona",
                "Monroe",
                "Montezuma Creek",
                "Monticello",
                "Morgan",
                "Moroni",
                "Mount Olympus",
                "Mount Pleasant",
                "Murray",
                "Myton",
                "Naples",
                "Navajo Mountain",
                "Neola",
                "Nephi",
                "New Harmony",
                "Newton",
                "Nibley",
                "North Logan",
                "North Ogden",
                "North Salt Lake",
                "North Snyderville Basin",
                "Oak City",
                "Oakley",
                "Ogden",
                "Oljato-monument Valley",
                "Ophir",
                "Oquirrh",
                "Orangeville",
                "Orderville",
                "Orem",
                "Palmyra",
                "Panguitch",
                "Paradise",
                "Paragonah",
                "Park City",
                "Parowan",
                "Payson",
                "Perry",
                "Peter",
                "Plain City",
                "Pleasant Grove",
                "Pleasant View",
                "Plymouth",
                "Portage",
                "Price",
                "Providence",
                "Provo",
                "Randlett",
                "Randolph",
                "Redmond",
                "Richfield",
                "Richmond",
                "River Heights",
                "Riverdale",
                "Riverside",
                "Riverton",
                "Rockville",
                "Rocky Ridge",
                "Roosevelt",
                "Roy",
                "Rush Valley",
                "Salem",
                "Salina",
                "Salt Lake City",
                "Samak",
                "Sandy",
                "Santa Clara",
                "Santaquin",
                "Saratoga Springs",
                "Scipio",
                "Scofield",
                "Sigurd",
                "Smithfield",
                "Snowville",
                "South Jordan",
                "South Ogden",
                "South Salt Lake",
                "South Snyderville Basin",
                "South Weber",
                "South Willard",
                "Spanish Fork",
                "Spanish Valley",
                "Spring City",
                "Spring Lake",
                "Springdale",
                "Springville",
                "St. George",
                "Stansbury Park",
                "Sterling",
                "Stockton",
                "Summit Park",
                "Sunnyside",
                "Sunset",
                "Syracuse",
                "Tabiona",
                "Taylorsville",
                "Timber Lakes",
                "Tooele",
                "Toquerville",
                "Torrey",
                "Tremonton",
                "Trenton",
                "Tropic",
                "Tselakai Dezza",
                "Uintah",
                "Vernal",
                "Vernon",
                "Vineyard",
                "Virgin",
                "Wales",
                "Wallsburg",
                "Washington",
                "Washington Terrace",
                "Wellington",
                "Wellsville",
                "Wendover",
                "West Bountiful",
                "West Haven",
                "West Jordan",
                "West Mountain",
                "West Point",
                "West Valley City",
                "White City",
                "White Mesa",
                "Whiterocks",
                "Willard",
                "Woodland",
                "Woodland Hills",
                "Woodruff",
                "Woods Cross"
            ],
            "Vermont": [
                "Albany",
                "Alburg",
                "Arlington",
                "Barre",
                "Barton",
                "Bellows Falls",
                "Bennington",
                "Bradford",
                "Brandon",
                "Brattleboro",
                "Burlington",
                "Cabot",
                "Cambridge",
                "Chester-chester Depot",
                "Derby Center",
                "Derby Line",
                "Enosburg Falls",
                "Essex Junction",
                "Fair Haven",
                "Graniteville-east Barre",
                "Hyde Park",
                "Island Pond",
                "Jacksonville",
                "Jeffersonville",
                "Jericho",
                "Johnson",
                "Ludlow",
                "Lyndonville",
                "Manchester",
                "Manchester Center",
                "Marshfield",
                "Middlebury",
                "Milton",
                "Montpelier",
                "Morrisville",
                "Newbury",
                "Newfane",
                "Newport",
                "North Bennington",
                "North Troy",
                "North Westminster",
                "Northfield",
                "Old Bennington",
                "Orleans",
                "Perkinsville",
                "Poultney",
                "Rutland",
                "Saxtons River",
                "South Barre",
                "South Burlington",
                "South Shaftsbury",
                "Springfield",
                "St. Albans",
                "St. Johnsbury",
                "Swanton",
                "Vergennes",
                "Wallingford",
                "Waterbury",
                "Wells River",
                "West Brattleboro",
                "West Burke",
                "West Rutland",
                "Westminster",
                "White River Junction",
                "Wilder",
                "Winooski",
                "Woodstock"
            ],
            "Virgin Islands": [
                "Virgin Islands"
            ],
            "Virginia": [
                "Abingdon",
                "Accomac",
                "Adwolf",
                "Alberta",
                "Alexandria",
                "Altavista",
                "Amherst",
                "Annandale",
                "Appalachia",
                "Appomattox",
                "Aquia Harbour",
                "Arlington",
                "Ashland",
                "Atkins",
                "Bailey S Crossroads",
                "Bassett",
                "Basye-bryce Mountain",
                "Bedford",
                "Belle Haven",
                "Bellwood",
                "Bensley",
                "Berryville",
                "Big Stone Gap",
                "Blacksburg",
                "Blackstone",
                "Bloxom",
                "Blue Ridge",
                "Bluefield",
                "Bon Air",
                "Boones Mill",
                "Bowling Green",
                "Boyce",
                "Boydton",
                "Boykins",
                "Branchville",
                "Bridgewater",
                "Bristol",
                "Broadway",
                "Brodnax",
                "Brookneal",
                "Buchanan",
                "Buena Vista",
                "Bull Run",
                "Burke",
                "Burkeville",
                "Cana",
                "Cape Charles",
                "Capron",
                "Castlewood",
                "Cave Spring",
                "Cedar Bluff",
                "Centreville",
                "Chamberlayne",
                "Chantilly",
                "Charlotte Court House",
                "Charlottesville",
                "Chase City",
                "Chatham",
                "Chatmoss",
                "Cheriton",
                "Chesapeake",
                "Chester",
                "Chesterfield Court House",
                "Chilhowie",
                "Chincoteague",
                "Christiansburg",
                "Claremont",
                "Clarksville",
                "Claypool Hill",
                "Cleveland",
                "Clifton",
                "Clifton Forge",
                "Clinchco",
                "Clinchport",
                "Clintwood",
                "Cloverdale",
                "Coeburn",
                "Collinsville",
                "Colonial Beach",
                "Colonial Heights",
                "Columbia",
                "Courtland",
                "Covington",
                "Craigsville",
                "Crewe",
                "Crimora",
                "Crozet",
                "Culpeper",
                "Dahlgren",
                "Dale City",
                "Daleville",
                "Damascus",
                "Danville",
                "Dayton",
                "Dendron",
                "Dillwyn",
                "Dooms",
                "Drakes Branch",
                "Dryden",
                "Dublin",
                "Duffield",
                "Dumbarton",
                "Dumfries",
                "Dungannon",
                "Dunn Loring",
                "East Highland Park",
                "Eastville",
                "Edinburg",
                "Elkton",
                "Elliston-lafayette",
                "Emory-meadow View",
                "Emporia",
                "Ettrick",
                "Ewing",
                "Exmore",
                "Fairfax",
                "Fairlawn",
                "Fairview Beach",
                "Falls Church",
                "Falmouth",
                "Fancy Gap",
                "Farmville",
                "Ferrum",
                "Fieldale",
                "Fincastle",
                "Fishersville",
                "Floyd",
                "Forest",
                "Fort Belvoir",
                "Fort Chiswell",
                "Fort Hunt",
                "Fort Lee",
                "Franconia",
                "Franklin",
                "Fredericksburg",
                "Fries",
                "Front Royal",
                "Gainesville",
                "Galax",
                "Gate City",
                "Glade Spring",
                "Glasgow",
                "Glen Allen",
                "Glen Lyn",
                "Gloucester Courthouse",
                "Gloucester Point",
                "Gordonsville",
                "Goshen",
                "Great Falls",
                "Greenville",
                "Gretna",
                "Grottoes",
                "Groveton",
                "Grundy",
                "Halifax",
                "Hallwood",
                "Hamilton",
                "Hampden Sydney",
                "Hampton",
                "Harrisonburg",
                "Haymarket",
                "Haysi",
                "Herndon",
                "Highland Springs",
                "Hillsboro",
                "Hillsville",
                "Hollins",
                "Honaker",
                "Hopewell",
                "Horse Pasture",
                "Huntington",
                "Hurt",
                "Hybla Valley",
                "Idylwood",
                "Independence",
                "Iron Gate",
                "Irvington",
                "Ivor",
                "Jarratt",
                "Jefferson",
                "Jolivue",
                "Jonesville",
                "Keller",
                "Kenbridge",
                "Keokee",
                "Keysville",
                "Kilmarnock",
                "La Crosse",
                "Lake Barcroft",
                "Lake Monticello",
                "Lake Ridge",
                "Lakeside",
                "Laurel",
                "Laurel Park",
                "Lawrenceville",
                "Laymantown",
                "Lebanon",
                "Leesburg",
                "Lexington",
                "Lincolnia",
                "Linton Hall",
                "Loch Lomond",
                "Lorton",
                "Louisa",
                "Lovettsville",
                "Low Moor",
                "Luray",
                "Lynchburg",
                "Lyndhurst",
                "Madison",
                "Madison Heights",
                "Manassas",
                "Manassas Park",
                "Mantua",
                "Marion",
                "Martinsville",
                "Massanutten",
                "Matoaca",
                "Max Meadows",
                "Mckenney",
                "Mclean",
                "Mechanicsville",
                "Melfa",
                "Merrifield",
                "Merrimac",
                "Middleburg",
                "Middletown",
                "Mineral",
                "Montclair",
                "Monterey",
                "Montrose",
                "Montross",
                "Mount Crawford",
                "Mount Jackson",
                "Mount Vernon",
                "Narrows",
                "Nassawadox",
                "New Castle",
                "New Market",
                "Newington",
                "Newport News",
                "Newsoms",
                "Nickelsville",
                "Nokesville",
                "Norfolk",
                "North Shore",
                "North Springfield",
                "Norton",
                "Oak Level",
                "Oakton",
                "Occoquan",
                "Onancock",
                "Onley",
                "Orange",
                "Painter",
                "Pamplin City",
                "Parksley",
                "Patrick Springs",
                "Pearisburg",
                "Pembroke",
                "Penhook",
                "Pennington Gap",
                "Petersburg",
                "Phenix",
                "Pimmit Hills",
                "Pocahontas",
                "Poquoson",
                "Port Royal",
                "Portsmouth",
                "Pound",
                "Pulaski",
                "Purcellville",
                "Quantico",
                "Quantico Station",
                "Radford",
                "Raven",
                "Remington",
                "Reston",
                "Rich Creek",
                "Richlands",
                "Richmond",
                "Ridgeway",
                "Roanoke",
                "Rocky Mount",
                "Rose Hill",
                "Round Hill",
                "Rural Retreat",
                "Rushmere",
                "Rustburg",
                "Salem",
                "Saltville",
                "Sandy Level",
                "Saxis",
                "Scottsburg",
                "Scottsville",
                "Selma",
                "Seven Corners",
                "Shawsville",
                "Shenandoah",
                "Sherando",
                "Short Pump",
                "Smithfield",
                "South Boston",
                "South Hill",
                "Spotsylvania Courthouse",
                "Springfield",
                "St. Charles",
                "St. Paul",
                "Stanardsville",
                "Stanley",
                "Stanleytown",
                "Staunton",
                "Stephens City",
                "Stony Creek",
                "Strasburg",
                "Stuart",
                "Stuarts Draft",
                "Sudley",
                "Suffolk",
                "Sugar Grove",
                "Surry",
                "Tangier",
                "Tappahannock",
                "Tazewell",
                "The Plains",
                "Timberlake",
                "Timberville",
                "Toms Brook",
                "Triangle",
                "Troutdale",
                "Troutville",
                "Tuckahoe",
                "Tysons Corner",
                "Union Hall",
                "Urbanna",
                "Vansant",
                "Verona",
                "Victoria",
                "Vienna",
                "Villa Heights",
                "Vinton",
                "Virgilina",
                "Virginia Beach",
                "Wachapreague",
                "Wakefield",
                "Warrenton",
                "Warsaw",
                "Washington",
                "Waverly",
                "Waynesboro",
                "Weber City",
                "West Gate",
                "West Point",
                "West Springfield",
                "Westlake Corner",
                "Weyers Cave",
                "White Stone",
                "Williamsburg",
                "Winchester",
                "Windsor",
                "Wise",
                "Wolf Trap",
                "Woodbridge",
                "Woodlawn",
                "Woodstock",
                "Wyndham",
                "Wytheville",
                "Yorkshire",
                "Yorktown"
            ],
            "Washington": [
                "Aberdeen",
                "Aberdeen Gardens",
                "Acme",
                "Ahtanum",
                "Airway Heights",
                "Albion",
                "Alderwood Manor",
                "Alger",
                "Algona",
                "Allyn-grapeview",
                "Almira",
                "Amboy",
                "Ames Lake",
                "Anacortes",
                "Arlington",
                "Arlington Heights",
                "Artondale",
                "Ashford",
                "Asotin",
                "Auburn",
                "Ault Field",
                "Bainbridge Island",
                "Bangor Trident Base",
                "Banks Lake South",
                "Barberton",
                "Baring",
                "Basin City",
                "Battle Ground",
                "Bay Center",
                "Bay View",
                "Beaux Arts Village",
                "Bell Hill",
                "Bellevue",
                "Bellingham",
                "Benton City",
                "Bickleton",
                "Big Lake",
                "Bingen",
                "Birch Bay",
                "Black Diamond",
                "Blaine",
                "Blyn",
                "Bonney Lake",
                "Bothell",
                "Brady",
                "Bremerton",
                "Brewster",
                "Bridgeport",
                "Brier",
                "Brinnon",
                "Brush Prairie",
                "Bryn Mawr-skyway",
                "Buckley",
                "Bucoda",
                "Burbank",
                "Burien",
                "Burlington",
                "Camano",
                "Camas",
                "Carbonado",
                "Carlsborg",
                "Carnation",
                "Carson River Valley",
                "Cascade Valley",
                "Cascade-fairwood",
                "Cashmere",
                "Castle Rock",
                "Cathan",
                "Cathcart",
                "Cathlamet",
                "Centerville",
                "Central Park",
                "Centralia",
                "Chehalis",
                "Chehalis Village",
                "Chelan",
                "Cheney",
                "Cherry Grove",
                "Chewelah",
                "Chinook",
                "Clarkston",
                "Clarkston Heights-vineland",
                "Cle Elum",
                "Clear Lake",
                "Clinton",
                "Clyde Hill",
                "Cohassett Beach",
                "Colfax",
                "College Place",
                "Colton",
                "Colville",
                "Conconully",
                "Concrete",
                "Connell",
                "Conway",
                "Copalis Beach",
                "Cosmopolis",
                "Cottage Lake",
                "Coulee City",
                "Coulee Dam",
                "Country Homes",
                "Coupeville",
                "Covington",
                "Creston",
                "Cusick",
                "Custer",
                "Dallesport",
                "Darrington",
                "Davenport",
                "Dayton",
                "Deer Park",
                "Deming",
                "Des Moines",
                "Desert Aire",
                "Dishman",
                "Dixie",
                "Dollar Corner",
                "Dupont",
                "Duvall",
                "East Cathlamet",
                "East Hill-meridian",
                "East Port Orchard",
                "East Renton Highlands",
                "East Wenatchee",
                "East Wenatchee Bench",
                "Eastgate",
                "Easton",
                "Eatonville",
                "Echo Lake",
                "Edgewood",
                "Edison",
                "Edmonds",
                "Elbe",
                "Electric City",
                "Elk Plain",
                "Ellensburg",
                "Elma",
                "Elmer City",
                "Endicott",
                "Entiat",
                "Enumclaw",
                "Ephrata",
                "Erlands Point-kitsap Lake",
                "Eschbach",
                "Esperance",
                "Everett",
                "Everson",
                "Fairchild Afb",
                "Fairfield",
                "Fairwood",
                "Fall City",
                "Farmington",
                "Federal Way",
                "Felida",
                "Ferndale",
                "Fife",
                "Finley",
                "Fircrest",
                "Five Corners",
                "Fords Prairie",
                "Forks",
                "Fort Lewis",
                "Fox Island",
                "Frederickson",
                "Freeland",
                "Friday Harbor",
                "Garfield",
                "Garrett",
                "Geneva",
                "George",
                "Gig Harbor",
                "Glacier",
                "Gleed",
                "Gold Bar",
                "Goldendale",
                "Graham",
                "Grand Coulee",
                "Grand Mound",
                "Grandview",
                "Granger",
                "Granite Falls",
                "Grayland",
                "Green Acres",
                "Greenwater",
                "Hamilton",
                "Harrah",
                "Harrington",
                "Hartline",
                "Hatton",
                "Hazel Dell North",
                "Hazel Dell South",
                "Highland",
                "Hobart",
                "Hockinson",
                "Hoquiam",
                "Humptulips",
                "Hunts Point",
                "Ilwaco",
                "Inchelium",
                "Index",
                "Indianola",
                "Inglewood-finn Hill",
                "Ione",
                "Issaquah",
                "John Sam Lake",
                "Jordan Road-canyon Creek",
                "Junction City",
                "Kahlotus",
                "Kalama",
                "Kelso",
                "Kendall",
                "Kenmore",
                "Kennewick",
                "Kent",
                "Kettle Falls",
                "Kingsgate",
                "Kingston",
                "Kirkland",
                "Kittitas",
                "Klickitat",
                "Krupp",
                "La Center",
                "La Conner",
                "La Crosse",
                "Lacey",
                "Lake Bosworth",
                "Lake Cavanaugh",
                "Lake Forest Park",
                "Lake Goodwin",
                "Lake Ketchum",
                "Lake Marcel-stillwater",
                "Lake Mcmurray",
                "Lake Morton-berrydale",
                "Lake Roesiger",
                "Lake Shore",
                "Lake Stevens",
                "Lakeland North",
                "Lakeland South",
                "Lakeview",
                "Lakewood",
                "Lamont",
                "Langley",
                "Latah",
                "Lea Hill",
                "Leavenworth",
                "Lebam",
                "Lewisville",
                "Liberty Lake",
                "Lind",
                "Lochsloy",
                "Long Beach",
                "Longview",
                "Longview Heights",
                "Lyle",
                "Lyman",
                "Lynden",
                "Lynnwood",
                "Mabton",
                "Machias",
                "Malden",
                "Malone-porter",
                "Maltby",
                "Manchester",
                "Mansfield",
                "Maple Falls",
                "Maple Heights-lake Desire",
                "Maple Valley",
                "Marblemount",
                "Marcus",
                "Marietta-alderwood",
                "Markham",
                "Marrowstone",
                "Martha Lake",
                "Maryhill",
                "Marysville",
                "Mattawa",
                "May Creek",
                "Mcchord Afb",
                "Mccleary",
                "Meadow Glade",
                "Medical Lake",
                "Medina",
                "Mercer Island",
                "Mesa",
                "Metaline",
                "Metaline Falls",
                "Midland",
                "Mill Creek",
                "Mill Plain",
                "Millwood",
                "Milton",
                "Minnehaha",
                "Mirrormont",
                "Moclips",
                "Monroe",
                "Montesano",
                "Morton",
                "Moses Lake",
                "Moses Lake North",
                "Mossyrock",
                "Mount Vernon",
                "Mount Vista",
                "Mountlake Terrace",
                "Moxee",
                "Mukilteo",
                "Naches",
                "Napavine",
                "Naselle",
                "Navy Yard City",
                "Neah Bay",
                "Neilton",
                "Nespelem",
                "Nespelem Community",
                "Newcastle",
                "Newport",
                "Nisqually Indian Community",
                "Nooksack",
                "Normandy Park",
                "North Bend",
                "North Bonneville",
                "North Creek",
                "North Marysville",
                "North Omak",
                "North Stanwood",
                "North Sultan",
                "North Yelm",
                "Northport",
                "Northwest Snohomish",
                "Oak Harbor",
                "Oakesdale",
                "Oakville",
                "Ocean City",
                "Ocean Park",
                "Ocean Shores",
                "Odessa",
                "Okanogan",
                "Olympia",
                "Omak",
                "Opportunity",
                "Orchards",
                "Oroville",
                "Orting",
                "Oso",
                "Othello",
                "Otis Orchards-east Farms",
                "Oyehut-hogans Corner",
                "Pacific",
                "Paine Field-lake Stickney",
                "Palouse",
                "Parkland",
                "Parkwood",
                "Pasco",
                "Pateros",
                "Pe Ell",
                "Peaceful Valley",
                "Picnic Point-north Lynnwood",
                "Pomeroy",
                "Port Angeles",
                "Port Angeles East",
                "Port Hadlock-irondale",
                "Port Ludlow",
                "Port Orchard",
                "Port Townsend",
                "Poulsbo",
                "Prairie Ridge",
                "Prescott",
                "Priest Point",
                "Prosser",
                "Pullman",
                "Puyallup",
                "Quilcene",
                "Quincy",
                "Rainier",
                "Ravensdale",
                "Raymond",
                "Reardan",
                "Redmond",
                "Renton",
                "Republic",
                "Richland",
                "Ridgefield",
                "Ritzville",
                "River Road",
                "Riverbend",
                "Riverside",
                "Riverton-boulevard Park",
                "Rochester",
                "Rock Island",
                "Rockford",
                "Rockport",
                "Ronald",
                "Roosevelt",
                "Rosalia",
                "Roslyn",
                "Roy",
                "Royal City",
                "Ruston",
                "Salmon Creek",
                "Sammamish",
                "Satsop",
                "Satus",
                "Seatac",
                "Seattle",
                "Seattle Hill-silver Firs",
                "Sedro-woolley",
                "Selah",
                "Sequim",
                "Shaker Church",
                "Shelton",
                "Shoreline",
                "Silvana",
                "Silverdale",
                "Skokomish",
                "Skykomish",
                "Smokey Point",
                "Snohomish",
                "Snoqualmie",
                "Snoqualmie Pass",
                "Soap Lake",
                "South Bend",
                "South Cle Elum",
                "South Hill",
                "South Prairie",
                "South Wenatchee",
                "Spanaway",
                "Spangle",
                "Spokane",
                "Sprague",
                "Springdale",
                "St. John",
                "Stanwood",
                "Starbuck",
                "Startup",
                "Steilacoom",
                "Stevenson",
                "Stimson Crossing",
                "Sudden Valley",
                "Sultan",
                "Sumas",
                "Summit",
                "Summitview",
                "Sumner",
                "Sunnyside",
                "Sunnyslope",
                "Suquamish",
                "Tacoma",
                "Taholah",
                "Tanglewilde-thompson Place",
                "Tanner",
                "Tekoa",
                "Tenino",
                "Terrace Heights",
                "Thorp",
                "Three Lakes",
                "Tieton",
                "Tokeland",
                "Toledo",
                "Tonasket",
                "Toppenish",
                "Touchet",
                "Town And Country",
                "Tracyton",
                "Trentwood",
                "Trout Lake",
                "Tukwila",
                "Tulalip Bay",
                "Tumwater",
                "Twisp",
                "Union Gap",
                "Union Hill-novelty Hill",
                "Uniontown",
                "University Place",
                "Vader",
                "Vancouver",
                "Vantage",
                "Vashon",
                "Venersborg",
                "Veradale",
                "Verlot",
                "Waitsburg",
                "Walla Walla",
                "Walla Walla East",
                "Waller",
                "Wallula",
                "Walnut Grove",
                "Wapato",
                "Warden",
                "Warm Beach",
                "Washougal",
                "Washtucna",
                "Waterville",
                "Waverly",
                "Weallup Lake",
                "Wenatchee",
                "West Clarkston-highland",
                "West Lake Sammamish",
                "West Lake Stevens",
                "West Longview",
                "West Pasco",
                "West Richland",
                "West Side Highway",
                "West Valley",
                "West Wenatchee",
                "Westport",
                "White Center",
                "White Salmon",
                "White Swan",
                "Wilbur",
                "Wilkeson",
                "Wilson Creek",
                "Winlock",
                "Winthrop",
                "Wishram",
                "Woodinville",
                "Woodland",
                "Woods Creek",
                "Woodway",
                "Yacolt",
                "Yakima",
                "Yarrow Point",
                "Yelm",
                "Zillah"
            ],
            "West Virginia": [
                "Addison (webster Springs)",
                "Albright",
                "Alderson",
                "Alum Creek",
                "Amherstdale-robinette",
                "Anawalt",
                "Anmoore",
                "Ansted",
                "Athens",
                "Auburn",
                "Bancroft",
                "Barboursville",
                "Barrackville",
                "Bath (berkeley Springs)",
                "Bayard ",
                "Beaver",
                "Beckley",
                "Beech Bottom ",
                "Belington ",
                "Belle ",
                "Belmont ",
                "Benwood ",
                "Bethany ",
                "Bethlehem",
                "Beverly",
                "Blacksville",
                "Blennerhassett",
                "Bluefield",
                "Boaz",
                "Bolivar",
                "Bradley",
                "Bradshaw",
                "Bramwell",
                "Brandonville",
                "Bridgeport",
                "Brookhaven",
                "Bruceton Mills",
                "Buckhannon",
                "Buffalo",
                "Burnsville",
                "Cairo",
                "Camden-on-gauley",
                "Cameron",
                "Capon Bridge",
                "Carpendale",
                "Cassville",
                "Cedar Grove",
                "Ceredo",
                "Chapmanville",
                "Charles Town",
                "Charleston",
                "Chattaroy",
                "Cheat Lake",
                "Chesapeake",
                "Chester",
                "Clarksburg",
                "Clay",
                "Clearview",
                "Clendenin",
                "Coal City",
                "Coal Fork",
                "Corporation Of Ranson",
                "Cowen",
                "Crab Orchard",
                "Craigsville",
                "Cross Lanes",
                "Culloden",
                "Daniels",
                "Danville",
                "Davis",
                "Davy",
                "Delbarton",
                "Despard",
                "Dunbar",
                "Durbin",
                "East Bank",
                "Eleanor",
                "Elizabeth",
                "Elk Garden",
                "Elkins",
                "Elkview",
                "Ellenboro",
                "Enterprise",
                "Fairlea",
                "Fairmont",
                "Fairview",
                "Falling Spring",
                "Farmington",
                "Fayetteville",
                "Flatwoods",
                "Flemington",
                "Follansbee",
                "Fort Ashby",
                "Fort Gay",
                "Franklin",
                "Friendly",
                "Gary",
                "Gassaway",
                "Gauley Bridge",
                "Gilbert",
                "Gilbert Creek",
                "Glasgow",
                "Glen Dale",
                "Glenville",
                "Grafton",
                "Grant Town",
                "Grantsville",
                "Granville",
                "Hambleton",
                "Hamlin",
                "Handley",
                "Harman",
                "Harpers Ferry",
                "Harrisville",
                "Hartford City",
                "Harts",
                "Hedgesville",
                "Henderson",
                "Hendricks",
                "Hillsboro",
                "Hinton",
                "Holden",
                "Hooverson Heights",
                "Hundred",
                "Huntington",
                "Hurricane",
                "Huttonsville",
                "Iaeger",
                "Inwood",
                "Jane Lew",
                "Jefferson",
                "Junior",
                "Kenova",
                "Kermit",
                "Keyser",
                "Keystone",
                "Kimball",
                "Kingwood",
                "Leon",
                "Lester",
                "Lewisburg",
                "Littleton",
                "Logan",
                "Lost Creek",
                "Lubeck",
                "Lumberport",
                "Mabscott",
                "Macarthur",
                "Madison",
                "Mallory",
                "Man",
                "Mannington",
                "Marlinton",
                "Marmet",
                "Martinsburg",
                "Mason",
                "Masontown",
                "Matewan",
                "Matoaka",
                "Mcmechen",
                "Meadow Bridge",
                "Middlebourne",
                "Mill Creek",
                "Milton",
                "Mineralwells",
                "Mitchell Heights",
                "Monongah",
                "Montcalm",
                "Montgomery",
                "Montrose",
                "Moorefield",
                "Morgantown",
                "Moundsville",
                "Mount Gay-shamrock",
                "Mount Hope",
                "Mullens",
                "New Cumberland",
                "New Haven",
                "New Martinsville",
                "Newburg",
                "Newell",
                "Nitro",
                "North Hills",
                "Northfork",
                "Nutter Fort",
                "Oak Hill",
                "Oakvale",
                "Oceana",
                "Paden City",
                "Parkersburg",
                "Parsons",
                "Paw Paw",
                "Pax",
                "Pea Ridge",
                "Pennsboro",
                "Petersburg",
                "Peterstown",
                "Philippi",
                "Piedmont",
                "Pinch",
                "Pine Grove",
                "Pineville",
                "Piney View",
                "Pleasant Valley",
                "Poca",
                "Point Pleasant",
                "Powellton",
                "Pratt",
                "Princeton",
                "Prosperity",
                "Pullman",
                "Quinwood",
                "Rainelle",
                "Ravenswood",
                "Red Jacket",
                "Reedsville",
                "Reedy",
                "Rhodell",
                "Richwood",
                "Ridgeley",
                "Ripley",
                "Rivesville",
                "Romney",
                "Ronceverte",
                "Rowlesburg",
                "Rupert",
                "Salem",
                "Sand Fork",
                "Shady Spring",
                "Shepherdstown",
                "Shinnston",
                "Sissonville",
                "Sistersville",
                "Smithers",
                "Smithfield",
                "Sophia",
                "South Charleston",
                "Spencer",
                "St. Albans",
                "St. Marys",
                "Stanaford",
                "Star City",
                "Stonewood",
                "Summersville",
                "Sutton",
                "Switzer",
                "Sylvester",
                "Teays Valley",
                "Terra Alta",
                "Thomas",
                "Thurmond",
                "Tornado",
                "Triadelphia",
                "Tunnelton",
                "Union",
                "Valley Grove",
                "Vienna",
                "War",
                "Wardensville",
                "Washington",
                "Wayne",
                "Weirton",
                "Welch",
                "Wellsburg",
                "West Hamlin",
                "West Liberty",
                "West Logan",
                "West Milford",
                "West Union",
                "Weston",
                "Westover",
                "Wheeling",
                "White Sulphur Springs",
                "Whitehall",
                "Whitesville",
                "Wiley Ford",
                "Williamson",
                "Williamstown",
                "Windsor Heights",
                "Winfield",
                "Womelsdorf (coalton)",
                "Worthington"
            ],
            "Wisconsin": [
                "Abbotsford",
                "Adams",
                "Adell",
                "Albany",
                "Algoma",
                "Allouez",
                "Alma",
                "Alma Center",
                "Almena",
                "Almond",
                "Altoona",
                "Amery",
                "Amherst",
                "Amherst Junction",
                "Aniwa",
                "Antigo",
                "Appleton",
                "Arcadia",
                "Arena",
                "Argyle",
                "Arlington",
                "Arpin",
                "Ashland",
                "Ashwaubenon",
                "Athens",
                "Auburndale",
                "Augusta",
                "Avoca",
                "Bagley",
                "Baldwin",
                "Balsam Lake",
                "Bangor",
                "Baraboo",
                "Barneveld",
                "Barron",
                "Bay City",
                "Bayfield",
                "Bayside",
                "Bear Creek",
                "Beaver Dam",
                "Belgium",
                "Bell Center",
                "Belleville",
                "Bellevue Town",
                "Belmont",
                "Beloit",
                "Benton",
                "Berlin",
                "Big Bend",
                "Big Falls",
                "Birchwood",
                "Birnamwood",
                "Biron",
                "Black Creek",
                "Black Earth",
                "Black River Falls",
                "Blair",
                "Blanchardville",
                "Bloomer",
                "Bloomington",
                "Blue Mounds",
                "Blue River",
                "Boaz",
                "Bohners Lake",
                "Bonduel",
                "Boscobel",
                "Bowler",
                "Boyceville",
                "Boyd",
                "Brandon",
                "Brice Prairie",
                "Brillion",
                "Brodhead",
                "Brokaw",
                "Brookfield",
                "Brooklyn",
                "Brown Deer",
                "Browns Lake",
                "Brownsville",
                "Browntown",
                "Bruce",
                "Buffalo City",
                "Burlington",
                "Butler",
                "Butternut",
                "Cadott",
                "Cambria",
                "Cambridge",
                "Cameron",
                "Camp Douglas",
                "Camp Lake",
                "Campbellsport",
                "Cascade",
                "Casco",
                "Cashton",
                "Cassville",
                "Catawba",
                "Cazenovia",
                "Cecil",
                "Cedar Grove",
                "Cedarburg",
                "Centuria",
                "Chain O Lakes-king",
                "Chaseburg",
                "Chenequa",
                "Chetek",
                "Chief Lake",
                "Chilton",
                "Chippewa Falls",
                "Clayton",
                "Clear Lake",
                "Cleveland",
                "Clinton",
                "Clintonville",
                "Clyman",
                "Cobb",
                "Cochrane",
                "Colby",
                "Coleman",
                "Colfax",
                "Coloma",
                "Columbus",
                "Combined Locks",
                "Como",
                "Conrath",
                "Coon Valley",
                "Cornell",
                "Cottage Grove",
                "Couderay",
                "Crandon",
                "Crivitz",
                "Cross Plains",
                "Cuba City",
                "Cudahy",
                "Cumberland",
                "Curtiss",
                "Dallas",
                "Dane",
                "Darien",
                "Darlington",
                "De Pere",
                "De Soto",
                "Deer Park",
                "Deerfield",
                "Deforest",
                "Delafield",
                "Delavan",
                "Delavan Lake",
                "Denmark",
                "Dickeyville",
                "Dodgeville",
                "Dorchester",
                "Dousman",
                "Downing",
                "Doylestown",
                "Dresser",
                "Durand",
                "Eagle",
                "Eagle Lake",
                "Eagle River",
                "East Troy",
                "Eastman",
                "Eau Claire",
                "Eden",
                "Edgar",
                "Edgerton",
                "Egg Harbor",
                "Eland",
                "Elderon",
                "Eleva",
                "Elk Mound",
                "Elkhart Lake",
                "Elkhorn",
                "Ellsworth",
                "Elm Grove",
                "Elmwood",
                "Elmwood Park",
                "Elroy",
                "Embarrass",
                "Endeavor",
                "Ephraim",
                "Ettrick",
                "Evansville",
                "Evergreen",
                "Exeland",
                "Fairchild",
                "Fairwater",
                "Fall Creek",
                "Fall River",
                "Fennimore",
                "Fenwood",
                "Ferryville",
                "Fitchburg",
                "Fond Du Lac",
                "Fontana-on-geneva Lake",
                "Footville",
                "Forestville",
                "Fort Atkinson",
                "Fountain City",
                "Fox Lake",
                "Fox Point",
                "Francis Creek",
                "Franklin",
                "Franksville",
                "Frederic",
                "Fredonia",
                "Fremont",
                "French Island",
                "Friendship",
                "Friesland",
                "Galesville",
                "Gays Mills",
                "Genoa",
                "Genoa City",
                "Germantown",
                "Gillett",
                "Gilman",
                "Glen Flora",
                "Glenbeulah",
                "Glendale",
                "Glenwood City",
                "Grafton",
                "Granton",
                "Grantsburg",
                "Gratiot",
                "Green Bay",
                "Green Lake",
                "Greendale",
                "Greenfield",
                "Greenwood",
                "Gresham",
                "Hales Corners",
                "Hammond",
                "Hancock",
                "Hartford",
                "Hartland",
                "Hatley",
                "Haugen",
                "Hawkins",
                "Hayward",
                "Hazel Green",
                "Hebron",
                "Helenville",
                "Hewitt",
                "Highland",
                "Hilbert",
                "Hillsboro",
                "Hixton",
                "Hollandale",
                "Holmen",
                "Horicon",
                "Hortonville",
                "Howard",
                "Howards Grove",
                "Hudson",
                "Hurley",
                "Hustisford",
                "Hustler",
                "Independence",
                "Ingram",
                "Iola",
                "Iron Ridge",
                "Ironton",
                "Ixonia",
                "Jackson",
                "Janesville",
                "Jefferson",
                "Johnson Creek",
                "Junction City",
                "Juneau",
                "Kaukauna",
                "Kekoskee",
                "Kellnersville",
                "Kendall",
                "Kennan",
                "Kenosha",
                "Keshena",
                "Kewaskum",
                "Kewaunee",
                "Kiel",
                "Kimberly",
                "Kingston",
                "Knapp",
                "Kohler",
                "La Crosse",
                "La Farge",
                "La Valle",
                "Lac Du Flambeau",
                "Lac La Belle",
                "Ladysmith",
                "Lake Delton",
                "Lake Geneva",
                "Lake Koshkonong",
                "Lake Lac La Belle",
                "Lake Mills",
                "Lake Nebagamon",
                "Lake Ripley",
                "Lake Shangrila",
                "Lake Wazeecha",
                "Lake Wisconsin",
                "Lake Wissota",
                "Lancaster",
                "Lannon",
                "Legend Lake",
                "Lena",
                "Lime Ridge",
                "Linden",
                "Little Chute",
                "Little Round Lake",
                "Livingston",
                "Lodi",
                "Loganville",
                "Lohrville",
                "Lomira",
                "Lone Rock",
                "Lowell",
                "Loyal",
                "Lublin",
                "Luck",
                "Luxemburg",
                "Lyndon Station",
                "Lynxville",
                "Madison",
                "Maiden Rock",
                "Manawa",
                "Manitowoc",
                "Maple Bluff",
                "Marathon City",
                "Maribel",
                "Marinette",
                "Marion",
                "Markesan",
                "Marquette",
                "Marshall",
                "Marshfield",
                "Mason",
                "Mattoon",
                "Mauston",
                "Mayville",
                "Mazomanie",
                "Mcfarland",
                "Medford",
                "Mellen",
                "Melrose",
                "Melvina",
                "Menasha",
                "Menomonee Falls",
                "Menomonie",
                "Mequon",
                "Merrill",
                "Merrillan",
                "Merrimac",
                "Merton",
                "Middle Village",
                "Middleton",
                "Milladore",
                "Milltown",
                "Milton",
                "Milwaukee",
                "Mineral Point",
                "Minong",
                "Mishicot",
                "Mondovi",
                "Monona",
                "Monroe",
                "Montello",
                "Montfort",
                "Monticello",
                "Montreal",
                "Mosinee",
                "Mount Calvary",
                "Mount Hope",
                "Mount Horeb",
                "Mount Sterling",
                "Mukwonago",
                "Muscoda",
                "Muskego",
                "Nashotah",
                "Necedah",
                "Neenah",
                "Neillsville",
                "Nekoosa",
                "Nelson",
                "Nelsonville",
                "Neopit",
                "Neosho",
                "Neshkoro",
                "New Auburn",
                "New Berlin",
                "New Glarus",
                "New Holstein",
                "New Lisbon",
                "New London",
                "New Post",
                "New Richmond",
                "Newburg",
                "Niagara",
                "Nichols",
                "North Bay",
                "North Fond Du Lac",
                "North Freedom",
                "North Hudson",
                "North Prairie",
                "Norwalk",
                "Oak Creek",
                "Oakdale",
                "Oakfield",
                "Oconomowoc",
                "Oconomowoc Lake",
                "Oconto",
                "Oconto Falls",
                "Odanah",
                "Ogdensburg",
                "Okauchee Lake",
                "Oliver",
                "Omro",
                "Onalaska",
                "Oneida",
                "Ontario",
                "Oostburg",
                "Oregon",
                "Orfordville",
                "Osceola",
                "Oshkosh",
                "Osseo",
                "Owen",
                "Oxford",
                "Paddock Lake",
                "Palmyra",
                "Pardeeville",
                "Park Falls",
                "Park Ridge",
                "Patch Grove",
                "Pell Lake",
                "Pepin",
                "Peshtigo",
                "Pewaukee",
                "Phillips",
                "Pigeon Falls",
                "Pittsville",
                "Plain",
                "Plainfield",
                "Platteville",
                "Pleasant Prairie",
                "Plover",
                "Plum City",
                "Plymouth",
                "Poplar",
                "Port Edwards",
                "Port Washington",
                "Portage",
                "Potosi",
                "Potter",
                "Potter Lake",
                "Pound",
                "Powers Lake",
                "Poynette",
                "Prairie Du Chien",
                "Prairie Du Sac",
                "Prairie Farm",
                "Prentice",
                "Prescott",
                "Princeton",
                "Pulaski",
                "Racine",
                "Radisson",
                "Randolph",
                "Random Lake",
                "Readstown",
                "Redgranite",
                "Reedsburg",
                "Reedsville",
                "Reeseville",
                "Reserve",
                "Rewey",
                "Rhinelander",
                "Rib Lake",
                "Rib Mountain",
                "Rice Lake",
                "Richland Center",
                "Ridgeland",
                "Ridgeway",
                "Rio",
                "Ripon",
                "River Falls",
                "River Hills",
                "Roberts",
                "Rochester",
                "Rock Springs",
                "Rockdale",
                "Rockland",
                "Rome",
                "Rosendale",
                "Rosholt",
                "Rothschild",
                "Rudolph",
                "Sauk City",
                "Saukville",
                "Scandinavia",
                "Schofield",
                "Seymour",
                "Sharon",
                "Shawano",
                "Sheboygan",
                "Sheboygan Falls",
                "Sheldon",
                "Shell Lake",
                "Sherwood",
                "Shiocton",
                "Shorewood",
                "Shorewood Hills",
                "Shullsburg",
                "Silver Lake",
                "Siren",
                "Sister Bay",
                "Slinger",
                "Soldiers Grove",
                "Solon Springs",
                "Somerset",
                "South Milwaukee",
                "South Wayne",
                "Sparta",
                "Spencer",
                "Spooner",
                "Spring Green",
                "Spring Valley",
                "St. Cloud",
                "St. Croix Falls",
                "St. Francis",
                "St. Nazianz",
                "Stanley",
                "Star Prairie",
                "Stetsonville",
                "Steuben",
                "Stevens Point",
                "Stockbridge",
                "Stockholm",
                "Stoddard",
                "Stoughton",
                "Stratford",
                "Strum",
                "Sturgeon Bay",
                "Sturtevant",
                "Sullivan",
                "Sun Prairie",
                "Superior",
                "Suring",
                "Sussex",
                "Tainter Lake",
                "Taylor",
                "Tennyson",
                "Theresa",
                "Thiensville",
                "Thorp",
                "Tigerton",
                "Tomah",
                "Tomahawk",
                "Tony",
                "Trempealeau",
                "Turtle Lake",
                "Twin Lakes",
                "Two Rivers",
                "Union Center",
                "Union Grove",
                "Unity",
                "Valders",
                "Verona",
                "Vesper",
                "Viola",
                "Viroqua",
                "Waldo",
                "Wales",
                "Walworth",
                "Warrens",
                "Washburn",
                "Waterford",
                "Waterford North",
                "Waterloo",
                "Watertown",
                "Waukesha",
                "Waunakee",
                "Waupaca",
                "Waupun",
                "Wausau",
                "Wausaukee",
                "Wautoma",
                "Wauwatosa",
                "Wauzeka",
                "Webster",
                "West Allis",
                "West Baraboo",
                "West Bend",
                "West Milwaukee",
                "West Salem",
                "Westby",
                "Westfield",
                "Weston",
                "Weyauwega",
                "Weyerhaeuser",
                "Wheeler",
                "White Lake",
                "Whitefish Bay",
                "Whitehall",
                "Whitelaw",
                "Whitewater",
                "Whiting",
                "Wild Rose",
                "Williams Bay",
                "Wilson",
                "Wilton",
                "Wind Lake",
                "Wind Point",
                "Windsor",
                "Winneconne",
                "Winter",
                "Wisconsin Dells",
                "Wisconsin Rapids",
                "Withee",
                "Wittenberg",
                "Wonewoc",
                "Woodman",
                "Woodville",
                "Wrightstown",
                "Wyeville",
                "Wyocena",
                "Yuba",
                "Zoar"
            ],
            "Wyoming": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ]
        }
    },
    {
        "id": 234,
        "name": "United States Minor Outlying Islands",
        "iso3": "UMI",
        "iso2": "UM",
        "phone_code": "1",
        "capital": "",
        "currency": "USD",
        "states": {
            "United States Minor Outlying I": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ]
        }
    },
    {
        "id": 235,
        "name": "Uruguay",
        "iso3": "URY",
        "iso2": "UY",
        "phone_code": "598",
        "capital": "Montevideo",
        "currency": "UYU",
        "states": {
            "Artigas": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Canelones": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Cerro Largo": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Colonia": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Durazno": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Flores": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "FLorida": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Lavalleja": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Maldonado": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Montevideo": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Paysandu": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Rio Negro": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Rivera": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Rocha": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Salto": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "San Jose": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Soriano": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Tacuarembo": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Treinta y Tres": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ]
        }
    },
    {
        "id": 236,
        "name": "Uzbekistan",
        "iso3": "UZB",
        "iso2": "UZ",
        "phone_code": "998",
        "capital": "Tashkent",
        "currency": "UZS",
        "states": {
            "Andijon": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Buhoro": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Buxoro Viloyati": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Cizah": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Fargona": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Horazm": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Kaskadar": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Korakalpogiston": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Namangan": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Navoi": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Samarkand": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Sirdare": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Surhondar": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Toskent": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ]
        }
    },
    {
        "id": 237,
        "name": "Vanuatu",
        "iso3": "VUT",
        "iso2": "VU",
        "phone_code": "678",
        "capital": "Port Vila",
        "currency": "VUV",
        "states": {
            "Malampa": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Penama": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Sanma": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Shefa": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Tafea": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Torba": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ]
        }
    },
    {
        "id": 238,
        "name": "Vatican City State (Holy See)",
        "iso3": "VAT",
        "iso2": "VA",
        "phone_code": "379",
        "capital": "Vatican City",
        "currency": "EUR",
        "states": {
            "Vatican City State (Holy See)": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ]
        }
    },
    {
        "id": 239,
        "name": "Venezuela",
        "iso3": "VEN",
        "iso2": "VE",
        "phone_code": "58",
        "capital": "Caracas",
        "currency": "VEF",
        "states": {
            "Amazonas": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Anzoategui": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Apure": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Aragua": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Barinas": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Bolivar": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Carabobo": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Cojedes": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Delta Amacuro": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Distrito Federal": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Falcon": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Guarico": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Lara": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Merida": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Miranda": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Monagas": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Nueva Esparta": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Portuguesa": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Sucre": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Tachira": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Trujillo": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Vargas": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Yaracuy": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Zulia": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ]
        }
    },
    {
        "id": 240,
        "name": "Vietnam",
        "iso3": "VNM",
        "iso2": "VN",
        "phone_code": "84",
        "capital": "Hanoi",
        "currency": "VND",
        "states": {
            "Bac Giang": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Binh Dinh": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Binh Duong": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Da Nang": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Dong Bang Song Cuu Long": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Dong Bang Song Hong": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Dong Nai": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Dong Nam Bo": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Duyen Hai Mien Trung": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Hanoi": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Hung Yen": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Khu Bon Cu": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Long An": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Mien Nui Va Trung Du": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Thai Nguyen": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Thanh Pho Ho Chi Minh": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Thu Do Ha Noi": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Tinh Can Tho": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Tinh Da Nang": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Tinh Gia Lai": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ]
        }
    },
    {
        "id": 241,
        "name": "Virgin Islands (British)",
        "iso3": "VGB",
        "iso2": "VG",
        "phone_code": "+1-284",
        "capital": "Road Town",
        "currency": "USD",
        "states": {
            "Anegada": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Jost van Dyke": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Tortola": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ]
        }
    },
    {
        "id": 242,
        "name": "Virgin Islands (US)",
        "iso3": "VIR",
        "iso2": "VI",
        "phone_code": "+1-340",
        "capital": "Charlotte Amalie",
        "currency": "USD",
        "states": {
            "Saint Croix": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Saint John": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Saint Thomas": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ]
        }
    },
    {
        "id": 243,
        "name": "Wallis And Futuna Islands",
        "iso3": "WLF",
        "iso2": "WF",
        "phone_code": "681",
        "capital": "Mata Utu",
        "currency": "XPF",
        "states": {
            "Alo": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Singave": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Wallis": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ]
        }
    },
    {
        "id": 244,
        "name": "Western Sahara",
        "iso3": "ESH",
        "iso2": "EH",
        "phone_code": "212",
        "capital": "El-Aaiun",
        "currency": "MAD",
        "states": {
            "al-\\'Ayun": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "as-Samarah": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Bu Jaydur": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Wad-adh-Dhahab": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ]
        }
    },
    {
        "id": 245,
        "name": "Yemen",
        "iso3": "YEM",
        "iso2": "YE",
        "phone_code": "967",
        "capital": "Sanaa",
        "currency": "YER",
        "states": {
            "Abyan": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "al-Bayda": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "al-Hudaydah": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "al-Jawf": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "al-Mahrah": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "al-Mahwit": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Dhamar": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Hadramaut": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Hajjah": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Hudaydah": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Ibb": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Lahij": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Madinat San\\'a": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Ma\\'rib": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Sana": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Sa\\'dah": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Shabwah": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Ta\\'izz": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "\\'Adan": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ]
        }
    },
    {
        "id": 246,
        "name": "Zambia",
        "iso3": "ZMB",
        "iso2": "ZM",
        "phone_code": "260",
        "capital": "Lusaka",
        "currency": "ZMK",
        "states": {
            "Central": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Copperbelt": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Eastern": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Luapala": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Lusaka": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "North-Western": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Northern": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Southern": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Western": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ]
        }
    },
    {
        "id": 247,
        "name": "Zimbabwe",
        "iso3": "ZWE",
        "iso2": "ZW",
        "phone_code": "263",
        "capital": "Harare",
        "currency": "ZWL",
        "states": {
            "Bulawayo": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Harare": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Manicaland": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Mashonaland Central": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Mashonaland East": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Mashonaland West": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Masvingo": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Matabeleland North": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Matabeleland South": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ],
            "Midlands": [
                "Afton",
                "Airport Road",
                "Albany",
                "Albin",
                "Alcova",
                "Alpine",
                "Alpine Northeast",
                "Alpine Northwest",
                "Alta",
                "Antelope Hills",
                "Antelope Valley-crestview",
                "Arapahoe",
                "Arrowhead Springs",
                "Arvada",
                "Atlantic City",
                "Auburn",
                "Baggs",
                "Bairoil",
                "Bar Nunn",
                "Basin",
                "Bedford",
                "Bessemer Bend",
                "Big Horn",
                "Big Piney",
                "Bondurant",
                "Boulder",
                "Boulder Flats",
                "Brookhurst",
                "Buffalo",
                "Burlington",
                "Burns",
                "Byron",
                "Calpet",
                "Carter",
                "Casper",
                "Casper Mountain",
                "Centennial",
                "Cheyenne",
                "Chugcreek",
                "Chugwater",
                "Clearmont",
                "Clearview Acres",
                "Cody",
                "Cokeville",
                "Cora",
                "Cowley",
                "Crowheart",
                "Daniel",
                "Dayton",
                "Deaver",
                "Diamondville",
                "Dixon",
                "Douglas",
                "Dubois",
                "East Thermopolis",
                "Eden",
                "Edgerton",
                "Elk Mountain",
                "Esterbrook",
                "Ethete",
                "Etna",
                "Evanston",
                "Evansville",
                "Fairview",
                "Farson",
                "Fontenelle",
                "Fort Bridger",
                "Fort Laramie",
                "Fort Washakie",
                "Fox Farm-college",
                "Frannie",
                "Garland",
                "Gillette",
                "Glendo",
                "Glenrock",
                "Grand Encampment",
                "Granger",
                "Green River",
                "Greybull",
                "Grover",
                "Guernsey",
                "Hanna",
                "Hartrandt",
                "Hartville",
                "Hawk Springs",
                "Hill View Heights",
                "Hoback",
                "Homa Hills",
                "Hudson",
                "Hulett",
                "Huntley",
                "Hyattville",
                "Jackson",
                "James Town",
                "Jeffrey City",
                "Johnstown",
                "Kaycee",
                "Kemmerer",
                "Kirby",
                "La Barge",
                "La Grange",
                "Lakeview North",
                "Lance Creek",
                "Lander",
                "Laramie",
                "Lingle",
                "Little America",
                "Lonetree",
                "Lost Springs",
                "Lovell",
                "Lucerne",
                "Lusk",
                "Lyman",
                "Manderson",
                "Manville",
                "Marbleton",
                "Mc Nutt",
                "Mckinnon",
                "Meadow Acres",
                "Meadow Lark Lake",
                "Medicine Bow",
                "Meeteetse",
                "Midwest",
                "Mills",
                "Moorcroft",
                "Moose Wilson Road",
                "Mountain View",
                "Newcastle",
                "North Rock Springs",
                "Oakley",
                "Opal",
                "Osage",
                "Owl Creek",
                "Parkman",
                "Pavillion",
                "Pine Bluffs",
                "Pine Haven",
                "Pinedale",
                "Point Of Rocks",
                "Powder River",
                "Powell",
                "Purple Sage",
                "Rafter J Ranch",
                "Ralston",
                "Ranchester",
                "Ranchettes",
                "Rawlins",
                "Red Butte",
                "Reliance",
                "Riverside",
                "Riverton",
                "Robertson",
                "Rock River",
                "Rock Springs",
                "Rolling Hills",
                "Saratoga",
                "Sheridan",
                "Shoshoni",
                "Sinclair",
                "Slater",
                "Sleepy Hollow",
                "Smoot",
                "South Flat",
                "South Greeley",
                "South Park",
                "Star Valley Ranch",
                "Story",
                "Sundance",
                "Superior",
                "Sweeney Ranch",
                "Table Rock",
                "Taylor",
                "Ten Sleep",
                "Teton Village",
                "Thayne",
                "The Buttes",
                "Thermopolis",
                "Torrington",
                "Turnerville",
                "Upton",
                "Van Tassell",
                "Veteran",
                "Vista West",
                "Wamsutter",
                "Warren Afb",
                "Washakie Ten",
                "Washam",
                "West River",
                "Westview Circle",
                "Wheatland",
                "Wilson",
                "Winchester",
                "Woods Landing-jelm",
                "Worland",
                "Wright",
                "Y-o Ranch",
                "Yoder"
            ]
        }
    }
]